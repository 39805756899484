import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { profissoes } from "@project/src/app/service/util/profissoes.service";
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { InformacoesFinanceirasDTO } from '@project/src/app/model/informacoes-financeiras-dto.model';

@Component({
  selector: 'app-stepper-informacoes-financeiras',
  templateUrl: './stepper-informacoes-financeiras.component.html',
  styleUrls: ['./stepper-informacoes-financeiras.component.less']
})
export class StepperInformacoesFinanceirasComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public form: FormGroup;

  informacoesFinanceiras: InformacoesFinanceirasDTO;
  filteredOptions: any;
  profissoes: string[] = profissoes;
  rendaMensal: any;
  aplicacoesFinanceiras: any;
  outrosRendimentos: any;
  bensMoveis: any;
  bensImoveis: any;
  ocupacao: string = '';
  codigoConvenio: string = null;

  @Output()
  public atualizarForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private autenticacao: AutenticacaoEndpointService,
  ) {
  }

  ngOnInit() {

    if (this.form) {
      this.rendaMensal = this.form.get('rendaMensal').value;
      this.aplicacoesFinanceiras = this.form.get('aplicacoesFinanceiras').value;
      this.outrosRendimentos = this.form.get('outrosRendimentos').value;
      this.bensMoveis = this.form.get('bensMoveis').value;
      this.bensImoveis = this.form.get('bensImoveis').value;
      this.ocupacao = this.form.get('ocupacao').value;
      this.codigoConvenio = this.form.get('codigoConvenio').value;
    }

    this.filteredOptions = this.form.get('ocupacao').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value;
    return this.profissoes.filter(options => options.includes(filterValue));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(800)).subscribe(val => {
      this.atualizarForm.emit({ atualizar: true });
    });
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public build() {
    const formUserData = this.form.value;

    const data = {
      rendaMensal: this.rendaMensal,
      aplicacoesFinanceiras: this.aplicacoesFinanceiras,
      outrosRendimentos: this.outrosRendimentos,
      bensMoveis: this.bensMoveis,
      bensImoveis: this.bensImoveis,
      ocupacao: this.ocupacao,
      codigoConvenio: this.codigoConvenio,
    }

    return data;
  }

  ngOnDestroy(): void {
  }

}
