export const profissoes = [
    "abastecedor de linha de produção",
    "abastecedor de máquinas",
    "acabador de embalagens",
    "acrilista",
    "acupunturista",
    "aderecista",
    "adesivador",
    "adestrador",
    "administrador de contratos",
    "administrador de dados",
    "administrador de e-commerce",
    "administrador de empresas",
    "administrador de sistemas",
    "administrador financeiro",
    "administrador hospitalar",
    "administrador predial",
    "administrador",
    "administrativo de obras",
    "advogado administrativo",
    "advogado ambientalista",
    "advogado consumerista",
    "advogado criminalista",
    "advogado cível",
    "advogado de contratos",
    "advogado de propriedade intelectual",
    "advogado eleitoral",
    "advogado empresarial",
    "advogado imobiliário",
    "advogado securitário",
    "advogado societário",
    "advogado trabalhista",
    "advogado tributarista",
    "advogado",
    "afiador de ferramentas",
    "agente de aeroporto",
    "agente de segurança",
    "agente de serviços",
    "agente de viagens",
    "agente funerário",
    "ajudante de caldeiraria",
    "ajudante de carga e descarga",
    "ajudante de carpinteiro",
    "ajudante de churrasqueiro",
    "ajudante de despachante aduaneiro",
    "ajudante de encanador",
    "ajudante de estamparia",
    "ajudante de fundição",
    "ajudante de funilaria",
    "ajudante de instalação",
    "ajudante de lubrificação",
    "ajudante de marceneiro",
    "ajudante de metalúrgico",
    "ajudante de motorista",
    "ajudante de obras",
    "ajudante de off-set",
    "ajudante de pizzaiolo",
    "ajudante de serralheiro",
    "ajudante de tapeceiro",
    "ajudante de vidraceiro",
    "ajudante geral",
    "ajudante operacional",
    "ajustador mecânico",
    "alfaiate",
    "alinhador",
    "almoxarife",
    "alpinista industrial",
    "amarrador",
    "analista administrativo de obras",
    "analista administrativo",
    "analista ambiental",
    "analista comercial",
    "analista contábil",
    "analista de almoxarifado",
    "analista de apuração de resultados",
    "analista de arquitetura",
    "analista de assistência técnica",
    "analista de assuntos regulatórios",
    "analista de atendimento",
    "analista de ativo fixo",
    "analista de auditoria",
    "analista de automação",
    "analista de back office",
    "analista de banco de dados",
    "analista de benefícios",
    "analista de business intelligence",
    "analista de cadastro",
    "analista de campo",
    "analista de captação de recursos",
    "analista de cargos e salários",
    "analista de cobrança",
    "analista de compliance",
    "analista de compras",
    "analista de comunicação e marketing",
    "analista de comunicação interna",
    "analista de comunicação",
    "analista de comércio exterior",
    "analista de contas a pagar e receber",
    "analista de contas a pagar",
    "analista de contas a receber",
    "analista de contas médicas",
    "analista de contas",
    "analista de conteúdo",
    "analista de contratos",
    "analista de control desk",
    "analista de controladoria",
    "analista de controle de qualidade",
    "analista de controles internos",
    "analista de credenciamento",
    "analista de criação",
    "analista de crm",
    "analista de crédito e cobrança",
    "analista de crédito imobiliário",
    "analista de crédito",
    "analista de customer service",
    "analista de custos",
    "analista de câmbio",
    "analista de dbm",
    "analista de departamento pessoal",
    "analista de desembaraço aduaneiro",
    "analista de desenvolvimento analítico",
    "analista de documentação",
    "analista de e-commerce",
    "analista de endomarketing",
    "analista de engenharia de produto",
    "analista de engenharia",
    "analista de estoque",
    "analista de estratégia de mercado",
    "analista de eventos",
    "analista de expatriados",
    "analista de expedição",
    "analista de facilities",
    "analista de farmacovigilância",
    "analista de faturamento",
    "analista de folha de pagamento",
    "analista de franquias",
    "analista de frota",
    "analista de fusões e aquisições",
    "analista de garantia da qualidade",
    "analista de garantia",
    "analista de geoprocessamento",
    "analista de gestão de incidentes",
    "analista de governança de ti",
    "analista de help desk",
    "analista de implantação",
    "analista de incorporação",
    "analista de indicadores",
    "analista de informação",
    "analista de informações gerenciais",
    "analista de infraestrutura",
    "analista de inteligência comercial",
    "analista de inteligência de mercado",
    "analista de inventário",
    "analista de investimentos",
    "analista de laboratório",
    "analista de legalização",
    "analista de licitação",
    "analista de logística",
    "analista de manutenção",
    "analista de marcas e patentes",
    "analista de marketing digital",
    "analista de marketing online",
    "analista de marketing",
    "analista de melhoria contínua",
    "analista de mercado",
    "analista de merchandising",
    "analista de mis",
    "analista de monitoramento",
    "analista de métricas",
    "analista de mídia online",
    "analista de mídia",
    "analista de mídias sociais",
    "analista de negócios de ti",
    "analista de negócios",
    "analista de operações",
    "analista de outsourcing",
    "analista de ouvidoria",
    "analista de patrimônio",
    "analista de pcm",
    "analista de pcp",
    "analista de pesquisa de mercado",
    "analista de planejamento estratégico",
    "analista de planejamento financeiro",
    "analista de planejamento",
    "analista de prevenção de perdas",
    "analista de pricing",
    "analista de processos e qualidade",
    "analista de processos gerenciais",
    "analista de processos industriais",
    "analista de processos",
    "analista de produto",
    "analista de produção",
    "analista de projetos",
    "analista de publicidade e propaganda",
    "analista de pós-venda",
    "analista de qsms",
    "analista de qualidade",
    "analista de recrutamento e seleção",
    "analista de recursos humanos",
    "analista de redes sociais",
    "analista de redes",
    "analista de relacionamento",
    "analista de relatórios",
    "analista de relações institucionais",
    "analista de relações internacionais",
    "analista de relações trabalhistas",
    "analista de remuneração",
    "analista de requisitos",
    "analista de responsabilidade social",
    "analista de retenção",
    "analista de risco",
    "analista de sac",
    "analista de segurança da informação",
    "analista de segurança do trabalho",
    "analista de segurança patrimonial",
    "analista de seguros",
    "analista de sem",
    "analista de seo",
    "analista de service desk",
    "analista de sinistro",
    "analista de sistema de gestão integrada",
    "analista de sistemas .net",
    "analista de sistemas cobol",
    "analista de sistemas delphi",
    "analista de sistemas erp",
    "analista de sistemas java",
    "analista de sistemas linux",
    "analista de sistemas mainframe",
    "analista de sistemas microsiga",
    "analista de sistemas oracle",
    "analista de sistemas php",
    "analista de sistemas pl sql",
    "analista de sistemas sharepoint",
    "analista de sistemas sql",
    "analista de sistemas web",
    "analista de sistemas",
    "analista de sistemas",
    "analista de subscrição",
    "analista de suporte mainframe",
    "analista de suporte middleware",
    "analista de suporte técnico",
    "analista de suporte websphere",
    "analista de suprimentos",
    "analista de sustentabilidade",
    "analista de tecnologia da informação",
    "analista de telecomunicações",
    "analista de tesouraria",
    "analista de testes",
    "analista de trade marketing",
    "analista de transportes",
    "analista de treinamento e desenvolvimento",
    "analista de usabilidade",
    "analista de validação",
    "analista de vendas",
    "analista de viagens",
    "analista de visual merchandising",
    "analista de web analytics",
    "analista de web design",
    "analista desenvolvedor mobile",
    "analista econômico financeiro",
    "analista financeiro",
    "analista fiscal",
    "analista funcional",
    "analista industrial",
    "analista jurídico",
    "analista pedagógico",
    "analista planejamento e orçamento",
    "analista programador .net",
    "analista programador advpl",
    "analista programador android",
    "analista programador asp.net",
    "analista programador c#",
    "analista programador c++",
    "analista programador cobol",
    "analista programador delphi",
    "analista programador front-end",
    "analista programador java",
    "analista programador mainframe",
    "analista programador oracle",
    "analista programador php",
    "analista programador pl sql",
    "analista programador progress",
    "analista programador protheus",
    "analista programador sharepoint",
    "analista programador sql",
    "analista programador vb6",
    "analista programador web",
    "analista programador websphere",
    "analista programador",
    "analista químico",
    "analista sap",
    "analista soa",
    "analista tributário",
    "analista técnico",
    "animador de festas",
    "animador digital",
    "antropólogo",
    "apontador de obras",
    "apontador de produção",
    "aprendiz na área administrativa",
    "aprendiz na área comercial",
    "aprendiz na área contábil",
    "aprendiz na área de atendimento",
    "aprendiz na área de cobrança",
    "aprendiz na área de construção civil",
    "aprendiz na área de cozinha",
    "aprendiz na área de departamento pessoal",
    "aprendiz na área de informática",
    "aprendiz na área de logística",
    "aprendiz na área de marketing",
    "aprendiz na área de produção",
    "aprendiz na área de recepção",
    "aprendiz na área de recursos humanos",
    "aprendiz na área de vendas",
    "aprendiz na área elétrica",
    "aprendiz na área financeira",
    "aprendiz na área jurídica",
    "aprendiz na área mecânica",
    "aprendiz na área operacional",
    "aprendiz",
    "apresentador de programa de televisão",
    "aquarista",
    "armador",
    "armazenista",
    "arqueólogo",
    "arquiteto .net",
    "arquiteto coordenador",
    "arquiteto de edificações",
    "arquiteto de informação",
    "arquiteto de interiores",
    "arquiteto de sistemas",
    "arquiteto de soluções",
    "arquiteto especificador",
    "arquiteto java",
    "arquiteto paisagista",
    "arquiteto urbanista",
    "arquiteto vendedor",
    "arquiteto",
    "arquivista",
    "arrematador",
    "arte-finalista",
    "artesão",
    "articulador social",
    "ascensorista",
    "assessor científico",
    "assessor de comunicação",
    "assessor de imprensa",
    "assessor de investimentos",
    "assessor de marketing",
    "assessor jurídico",
    "assistente administrativo de manutenção",
    "assistente administrativo",
    "assistente comercial",
    "assistente contábil",
    "assistente de alimentos e bebidas",
    "assistente de almoxarifado",
    "assistente de arquitetura",
    "assistente de arquivo",
    "assistente de arte",
    "assistente de assuntos regulatórios",
    "assistente de atendimento publicitário",
    "assistente de atendimento",
    "assistente de ativo fixo",
    "assistente de auditoria contábil",
    "assistente de auditoria",
    "assistente de back office",
    "assistente de benefícios",
    "assistente de cadastro",
    "assistente de cargos e salários",
    "assistente de cobrança",
    "assistente de compliance",
    "assistente de compras",
    "assistente de comunicação e marketing",
    "assistente de comunicação",
    "assistente de comércio exterior",
    "assistente de condomínio",
    "assistente de contas a pagar e receber",
    "assistente de contas a pagar",
    "assistente de contas a receber",
    "assistente de contas médicas",
    "assistente de conteúdo web",
    "assistente de contratos",
    "assistente de control desk",
    "assistente de controladoria",
    "assistente de controle de qualidade",
    "assistente de controles internos",
    "assistente de credenciamento",
    "assistente de criação",
    "assistente de crm",
    "assistente de crédito e cobrança",
    "assistente de crédito imobiliário",
    "assistente de customer service",
    "assistente de custos",
    "assistente de câmbio",
    "assistente de departamento pessoal",
    "assistente de desembaraço aduaneiro",
    "assistente de design gráfico",
    "assistente de design",
    "assistente de diretoria",
    "assistente de documentação",
    "assistente de e-commerce",
    "assistente de endomarketing",
    "assistente de engenharia",
    "assistente de estilo",
    "assistente de estoque",
    "assistente de eventos",
    "assistente de expedição",
    "assistente de facilities",
    "assistente de faturamento hospitalar",
    "assistente de faturamento",
    "assistente de fotógrafo",
    "assistente de franquias",
    "assistente de frota",
    "assistente de garantia da qualidade",
    "assistente de governança",
    "assistente de implantação",
    "assistente de informática",
    "assistente de infraestrutura",
    "assistente de inteligência de mercado",
    "assistente de inventário",
    "assistente de legalização",
    "assistente de licitação",
    "assistente de locação",
    "assistente de logística",
    "assistente de loja",
    "assistente de manutenção predial",
    "assistente de marketing digital",
    "assistente de marketing",
    "assistente de meio ambiente",
    "assistente de merchandising",
    "assistente de modelagem",
    "assistente de monitoramento",
    "assistente de mídia",
    "assistente de mídias sociais",
    "assistente de negócios",
    "assistente de nutrição",
    "assistente de orçamento",
    "assistente de ouvidoria",
    "assistente de patrimônio",
    "assistente de pcp",
    "assistente de pesquisa de mercado",
    "assistente de pesquisa e desenvolvimento",
    "assistente de pesquisa",
    "assistente de planejamento",
    "assistente de prevenção de perdas",
    "assistente de pricing",
    "assistente de processamento de dados",
    "assistente de processos",
    "assistente de produto",
    "assistente de produção editorial",
    "assistente de produção",
    "assistente de programação",
    "assistente de projetos",
    "assistente de publicidade e propaganda",
    "assistente de pós-venda",
    "assistente de rastreamento",
    "assistente de recebimento fiscal",
    "assistente de recrutamento e seleção",
    "assistente de recursos humanos",
    "assistente de relacionamento",
    "assistente de relações públicas",
    "assistente de repartição pública",
    "assistente de reservas",
    "assistente de risco",
    "assistente de sac",
    "assistente de secretaria escolar",
    "assistente de secretaria",
    "assistente de segurança da informação",
    "assistente de segurança do trabalho",
    "assistente de segurança patrimonial",
    "assistente de seguros",
    "assistente de service desk",
    "assistente de sinistro",
    "assistente de sistemas",
    "assistente de supervisão",
    "assistente de suporte técnico",
    "assistente de suprimentos",
    "assistente de tecnologia da informação",
    "assistente de telecomunicações",
    "assistente de telemarketing",
    "assistente de televendas",
    "assistente de tesouraria",
    "assistente de testes",
    "assistente de trade marketing",
    "assistente de transportes",
    "assistente de treinamento e desenvolvimento",
    "assistente de vendas",
    "assistente de visual merchandising",
    "assistente de web design",
    "assistente editorial",
    "assistente executivo",
    "assistente financeiro",
    "assistente financeiro",
    "assistente fiscal",
    "assistente industrial",
    "assistente jurídico",
    "assistente operacional",
    "assistente paralegal",
    "assistente pedagógico",
    "assistente social",
    "assistente societário",
    "assistente tributário",
    "assistente técnico de campo",
    "assistente técnico de edificações",
    "assistente técnico de vendas",
    "assistente técnico em eletrônica",
    "assistente técnico",
    "assoalhador",
    "atendente de assistência técnica",
    "atendente de câmbio",
    "atendente de delivery",
    "atendente de e-commerce",
    "atendente de help desk",
    "atendente de marketing",
    "atendente de nutrição",
    "atendente de pista",
    "atendente de pós-venda",
    "atendente de restaurante",
    "atendente de sac",
    "atendente de service desk",
    "atendente de sinistro",
    "atendente de suporte técnico",
    "atendente de turismo",
    "atendente",
    "atendimento publicitário",
    "ator",
    "atuário",
    "auditor contábil",
    "auditor de contas médicas",
    "auditor de estoque",
    "auditor de loja",
    "auditor de processos",
    "auditor de qualidade",
    "auditor de sistemas",
    "auditor externo",
    "auditor financeiro",
    "auditor fiscal",
    "auditor interno",
    "auditor noturno",
    "auditor trainee",
    "auditor",
    "auxiliar administrativo",
    "auxiliar comercial",
    "auxiliar contábil",
    "auxiliar de acabamento gráfico",
    "auxiliar de almoxarifado",
    "auxiliar de arquitetura",
    "auxiliar de arquivo",
    "auxiliar de assistência técnica",
    "auxiliar de atendimento",
    "auxiliar de auditoria",
    "auxiliar de açougue",
    "auxiliar de back office",
    "auxiliar de banco de dados",
    "auxiliar de banco de sangue",
    "auxiliar de barman",
    "auxiliar de benefícios",
    "auxiliar de biblioteca",
    "auxiliar de biotério",
    "auxiliar de bordado",
    "auxiliar de borracharia",
    "auxiliar de cabeleireiro",
    "auxiliar de campo",
    "auxiliar de carga e descarga",
    "auxiliar de cartório",
    "auxiliar de classe",
    "auxiliar de cobrança",
    "auxiliar de coleta",
    "auxiliar de compras",
    "auxiliar de comércio exterior",
    "auxiliar de confeitaria",
    "auxiliar de contas a pagar e receber",
    "auxiliar de contas a pagar",
    "auxiliar de contas a receber",
    "auxiliar de contas médicas",
    "auxiliar de contratos",
    "auxiliar de controle de qualidade",
    "auxiliar de corte",
    "auxiliar de costura",
    "auxiliar de cozinha",
    "auxiliar de criação",
    "auxiliar de crédito e cobrança",
    "auxiliar de crédito",
    "auxiliar de departamento pessoal",
    "auxiliar de desenhista",
    "auxiliar de desenvolvimento de produto",
    "auxiliar de designer gráfico",
    "auxiliar de despachante",
    "auxiliar de e-commerce",
    "auxiliar de eletricista",
    "auxiliar de enfermagem do trabalho",
    "auxiliar de enfermagem",
    "auxiliar de engenharia civil",
    "auxiliar de engenharia",
    "auxiliar de escritório",
    "auxiliar de estilo",
    "auxiliar de estoque",
    "auxiliar de estética",
    "auxiliar de expedição",
    "auxiliar de extrusão",
    "auxiliar de farmácia",
    "auxiliar de faturamento hospitalar",
    "auxiliar de faturamento",
    "auxiliar de folha de pagamento",
    "auxiliar de fotografia",
    "auxiliar de frota",
    "auxiliar de garantia",
    "auxiliar de governança",
    "auxiliar de impressão",
    "auxiliar de informática",
    "auxiliar de infraestrutura",
    "auxiliar de instalação",
    "auxiliar de inventário",
    "auxiliar de jardinagem",
    "auxiliar de laboratório",
    "auxiliar de lavanderia",
    "auxiliar de legalização",
    "auxiliar de limpeza",
    "auxiliar de locação",
    "auxiliar de logística",
    "auxiliar de loja",
    "auxiliar de manipulação",
    "auxiliar de manutenção elétrica",
    "auxiliar de manutenção hidráulica",
    "auxiliar de manutenção industrial",
    "auxiliar de manutenção mecânica",
    "auxiliar de manutenção predial",
    "auxiliar de manutenção",
    "auxiliar de marketing digital",
    "auxiliar de marketing",
    "auxiliar de mecânico",
    "auxiliar de merchandising",
    "auxiliar de modelagem",
    "auxiliar de modelista",
    "auxiliar de monitoramento",
    "auxiliar de montagem",
    "auxiliar de mídia",
    "auxiliar de oftalmologia",
    "auxiliar de orçamento",
    "auxiliar de ourives",
    "auxiliar de padeiro",
    "auxiliar de patrimônio",
    "auxiliar de pcp",
    "auxiliar de perecíveis",
    "auxiliar de pesagem",
    "auxiliar de pesquisa de mercado",
    "auxiliar de pintura",
    "auxiliar de processos",
    "auxiliar de produção",
    "auxiliar de prótese dentária",
    "auxiliar de publicidade e propaganda",
    "auxiliar de pátio",
    "auxiliar de pós-venda",
    "auxiliar de qualidade",
    "auxiliar de rastreamento",
    "auxiliar de recebimento fiscal",
    "auxiliar de recebimento",
    "auxiliar de recreação",
    "auxiliar de recrutamento e seleção",
    "auxiliar de recursos humanos",
    "auxiliar de refrigeração",
    "auxiliar de reposição",
    "auxiliar de restaurante",
    "auxiliar de saúde bucal",
    "auxiliar de secretaria escolar",
    "auxiliar de segurança patrimonial",
    "auxiliar de seguros",
    "auxiliar de serviços gerais",
    "auxiliar de sinistro",
    "auxiliar de soldador",
    "auxiliar de sondagem",
    "auxiliar de supervisão",
    "auxiliar de suporte técnico",
    "auxiliar de suprimentos",
    "auxiliar de sushiman",
    "auxiliar de tecelão",
    "auxiliar de tecnologia da informação",
    "auxiliar de televendas",
    "auxiliar de tesouraria",
    "auxiliar de topografia",
    "auxiliar de trade marketing",
    "auxiliar de transportes",
    "auxiliar de treinamento e desenvolvimento",
    "auxiliar de triagem",
    "auxiliar de usinagem",
    "auxiliar de vendas",
    "auxiliar de veterinário",
    "auxiliar editorial",
    "auxiliar eletrotécnico",
    "auxiliar financeiro",
    "auxiliar fiscal",
    "auxiliar industrial",
    "auxiliar operacional",
    "auxiliar pedagógico",
    "auxiliar projetista",
    "auxiliar químico",
    "auxiliar tributário",
    "auxiliar técnico de mecânica",
    "auxiliar técnico eletrônico",
    "auxiliar técnico em edificações",
    "auxiliar técnico em mecatrônica",
    "auxiliar técnico em segurança do trabalho",
    "auxiliar técnico",
    "avaliador de imóveis",
    "avaliador de veículos",
    "avaliador físico",
    "azulejista",
    "açougueiro",
    "babá",
    "back office de vendas",
    "back office",
    "balanceiro",
    "balconista",
    "bamburista",
    "barista",
    "barman",
    "berçarista",
    "bibliotecário",
    "bilheteiro",
    "biologista",
    "biomédico",
    "bioquímico",
    "biotecnólogo",
    "biólogo",
    "blaster",
    "blogueiro",
    "bloquista",
    "bombeiro civil",
    "bombeiro industrial",
    "booker",
    "bordador",
    "borracheiro",
    "business partner",
    "cabeleireiro",
    "cabista",
    "caixa de banco",
    "calceteiro",
    "calculista",
    "caldeireiro",
    "camareiro",
    "caracterizador",
    "carpinteiro",
    "cartazista",
    "carteiro",
    "caseiro",
    "cenotécnico",
    "cenógrafo",
    "ceo",
    "chapeiro",
    "chaveiro",
    "chefe de bar",
    "chefe de compras",
    "chefe de confeitaria",
    "chefe de costura",
    "chefe de cozinha",
    "chefe de departamento pessoal",
    "chefe de expedição",
    "chefe de fila",
    "chefe de plantão médico",
    "chefe de produção",
    "chefe de recepção",
    "churrasqueiro",
    "cilindrista",
    "cinegrafista",
    "classificador de grãos",
    "cliente oculto",
    "coach",
    "cobrador de ônibus",
    "codificador de dados",
    "colchoeiro",
    "coletor de amostras",
    "coletor de lixo",
    "colocador de papel de parede",
    "colorista de papel",
    "colorista têxtil",
    "colorista",
    "comissário de avarias",
    "comissário de bordo",
    "comprador de medicamentos",
    "comprador internacional",
    "comprador técnico",
    "comprador",
    "comunicador social",
    "concierge",
    "confeiteiro",
    "conferente de tesouraria",
    "conferente líder",
    "conferente",
    "conselheiro tutelar",
    "consultor abap",
    "consultor ambiental",
    "consultor contábil",
    "consultor crm",
    "consultor de atendimento",
    "consultor de beleza",
    "consultor de benefícios",
    "consultor de comunicação",
    "consultor de comércio exterior",
    "consultor de controladoria",
    "consultor de crédito imobiliário",
    "consultor de estética",
    "consultor de eventos",
    "consultor de franquias",
    "consultor de imigração",
    "consultor de implantação",
    "consultor de infraestrutura",
    "consultor de inteligência de mercado",
    "consultor de intercâmbio",
    "consultor de investimentos",
    "consultor de logística",
    "consultor de loja",
    "consultor de marketing",
    "consultor de merchandising",
    "consultor de moda",
    "consultor de negócios",
    "consultor de operações",
    "consultor de planejamento estratégico",
    "consultor de processos",
    "consultor de projetos",
    "consultor de pós-venda",
    "consultor de qualidade",
    "consultor de recrutamento e seleção",
    "consultor de recursos humanos",
    "consultor de redes",
    "consultor de relacionamento",
    "consultor de responsabilidade social",
    "consultor de segurança da informação",
    "consultor de seguros",
    "consultor de sistemas",
    "consultor de tecnologia da informação",
    "consultor de trade marketing",
    "consultor de treinamento e desenvolvimento",
    "consultor de vendas",
    "consultor educacional",
    "consultor empresarial",
    "consultor erp",
    "consultor financeiro",
    "consultor fiscal",
    "consultor funcional",
    "consultor gastronômico",
    "consultor jurídico",
    "consultor mastersaf",
    "consultor oracle",
    "consultor pedagógico",
    "consultor sap",
    "consultor siebel",
    "consultor tributário",
    "consultor técnico de veículos",
    "consultor técnico industrial",
    "consultor técnico",
    "contabilista",
    "contador gerencial",
    "contador",
    "contato publicitário",
    "conteudista",
    "contra-regra",
    "controlador de custos",
    "controlador de produção",
    "controlador de pátio",
    "controlador de qualidade",
    "controlador de tráfego aéreo",
    "controlador de tráfego",
    "controller",
    "contínuo",
    "coordenador administrativo de obras",
    "coordenador administrativo",
    "coordenador comercial",
    "coordenador contábil",
    "coordenador de alimentos e bebidas",
    "coordenador de almoxarifado",
    "coordenador de armazém",
    "coordenador de assistência técnica",
    "coordenador de assuntos regulatórios",
    "coordenador de atendimento ao cliente",
    "coordenador de auditoria",
    "coordenador de benefícios",
    "coordenador de biblioteca",
    "coordenador de business intelligence",
    "coordenador de campo",
    "coordenador de cargos e salários",
    "coordenador de cobrança",
    "coordenador de compras",
    "coordenador de comunicação",
    "coordenador de comércio exterior",
    "coordenador de contas a pagar",
    "coordenador de contas a receber",
    "coordenador de contas médicas",
    "coordenador de contas",
    "coordenador de contratos",
    "coordenador de controladoria",
    "coordenador de controles internos",
    "coordenador de credenciamento",
    "coordenador de crm",
    "coordenador de crédito e cobrança",
    "coordenador de crédito imobiliário",
    "coordenador de crédito",
    "coordenador de customer service",
    "coordenador de custos",
    "coordenador de departamento pessoal",
    "coordenador de desenvolvimento de sistemas",
    "coordenador de e-commerce",
    "coordenador de enfermagem",
    "coordenador de engenharia",
    "coordenador de estilo",
    "coordenador de estoque",
    "coordenador de eventos",
    "coordenador de expedição",
    "coordenador de facilities",
    "coordenador de faturamento",
    "coordenador de ferramentaria",
    "coordenador de filial",
    "coordenador de folha de pagamento",
    "coordenador de garantia da qualidade",
    "coordenador de help desk",
    "coordenador de hotelaria",
    "coordenador de implantação",
    "coordenador de incorporação",
    "coordenador de infraestrutura",
    "coordenador de instalações",
    "coordenador de inteligência de mercado",
    "coordenador de inventário",
    "coordenador de investimentos",
    "coordenador de laboratório",
    "coordenador de legalização",
    "coordenador de licitação",
    "coordenador de limpeza",
    "coordenador de logística",
    "coordenador de loja",
    "coordenador de manutenção automotiva",
    "coordenador de manutenção industrial",
    "coordenador de manutenção predial",
    "coordenador de manutenção",
    "coordenador de marketing digital",
    "coordenador de marketing",
    "coordenador de meio ambiente",
    "coordenador de melhoria contínua",
    "coordenador de merchandising",
    "coordenador de mídia",
    "coordenador de mídias sociais",
    "coordenador de negócios",
    "coordenador de obras",
    "coordenador de orçamento",
    "coordenador de patrimônio",
    "coordenador de pcp",
    "coordenador de pesquisa de mercado",
    "coordenador de pesquisa e desenvolvimento",
    "coordenador de planejamento",
    "coordenador de prevenção de perdas",
    "coordenador de pricing",
    "coordenador de processos",
    "coordenador de produto",
    "coordenador de produção",
    "coordenador de projeto social",
    "coordenador de projetos de construção civil",
    "coordenador de projetos",
    "coordenador de pós-venda",
    "coordenador de qualidade",
    "coordenador de recepção",
    "coordenador de recrutamento e seleção",
    "coordenador de recursos humanos",
    "coordenador de redes",
    "coordenador de reservas",
    "coordenador de responsabilidade social",
    "coordenador de restaurante",
    "coordenador de saúde, segurança e meio ambiente",
    "coordenador de segurança da informação",
    "coordenador de segurança do trabalho",
    "coordenador de segurança patrimonial",
    "coordenador de seguros",
    "coordenador de service desk",
    "coordenador de serviços gerais",
    "coordenador de sinistro",
    "coordenador de suporte técnico",
    "coordenador de suprimentos",
    "coordenador de sustentabilidade",
    "coordenador de tecnologia da informação",
    "coordenador de telecomunicações",
    "coordenador de tesouraria",
    "coordenador de topografia",
    "coordenador de trade marketing",
    "coordenador de transportes",
    "coordenador de treinamento e desenvolvimento",
    "coordenador de usinagem",
    "coordenador de vendas",
    "coordenador de visual merchandising",
    "coordenador editorial",
    "coordenador eletrônico",
    "coordenador financeiro",
    "coordenador fiscal",
    "coordenador imobiliário",
    "coordenador industrial",
    "coordenador jurídico",
    "coordenador médico",
    "coordenador operacional",
    "coordenador pedagógico",
    "coordenador tributário",
    "copeiro",
    "copiador de chapa",
    "coreógrafo",
    "corretor de imóveis",
    "corretor de seguros",
    "cortador de tecidos",
    "cortador de vidro",
    "cortador",
    "costureiro",
    "cozinheiro",
    "crepeiro",
    "cronoanalista",
    "cuidador de crianças",
    "cuidador de idosos",
    "cumim",
    "curador de arte",
    "dançarino",
    "data scientist",
    "dba oracle",
    "dba sql",
    "dba",
    "decorador",
    "dedetizador",
    "degustador",
    "dentista auditor",
    "dentista endodontista",
    "dentista estomatologista",
    "dentista implantodontista",
    "dentista odontopediatra",
    "dentista ortodontista",
    "dentista periodontista",
    "dentista protesista",
    "dentista traumatologista",
    "dentista",
    "departamento pessoal",
    "depiladora",
    "dermoconsultor",
    "desenhista cadista",
    "desenhista copista",
    "desenhista elétrico",
    "desenhista industrial",
    "desenhista mecânico",
    "desenhista orçamentista",
    "desenhista projetista civil",
    "desenhista projetista elétrico",
    "desenhista projetista hidráulico",
    "desenhista projetista mecânico",
    "desenhista projetista",
    "desenhista técnico",
    "desenhista",
    "desentupidor",
    "design",
    "designer de acessórios",
    "designer de calçados",
    "designer de embalagem",
    "designer de interação",
    "designer de interface",
    "designer de interiores",
    "designer de jogos",
    "designer de joias",
    "designer de moda",
    "designer de produto",
    "designer de sobrancelha",
    "designer digital",
    "designer gráfico",
    "designer instrucional",
    "designer multimídia",
    "designer têxtil",
    "designer",
    "despachante aduaneiro",
    "despachante imobiliário",
    "despachante operacional de voo",
    "despachante",
    "diagramador",
    "digitador de laudos",
    "digitador",
    "digitalizador",
    "diligenciador",
    "diretor administrativo",
    "diretor comercial",
    "diretor de arte",
    "diretor de cinema",
    "diretor de compras",
    "diretor de criação",
    "diretor de logística",
    "diretor de loja",
    "diretor de marketing",
    "diretor de negócios",
    "diretor de obras",
    "diretor de operações",
    "diretor de recursos humanos",
    "diretor de teatro",
    "diretor de tecnologia da informação",
    "diretor de vendas",
    "diretor financeiro",
    "diretor geral",
    "diretor hospitalar",
    "diretor industrial",
    "diretor jurídico",
    "diretor pedagógico",
    "divulgador",
    "dj",
    "documentador",
    "doméstica",
    "duteiro",
    "economista",
    "ecólogo",
    "editor de imagens",
    "editor de moda",
    "editor de texto",
    "editor de vídeo",
    "educador ambiental",
    "educador social",
    "eletricista de ar condicionado",
    "eletricista de empilhadeira",
    "eletricista de força e controle",
    "eletricista de instalações",
    "eletricista de veículos",
    "eletricista eletrônico",
    "eletricista industrial",
    "eletricista montador de painel",
    "eletricista montador",
    "eletricista predial",
    "eletricista",
    "eletromecânico de manutenção",
    "eletromecânico",
    "eletrotécnico",
    "embalador",
    "embriologista",
    "emissor de ctrc",
    "emissor de passagens",
    "empacotador",
    "encadernador",
    "encanador industrial",
    "encanador",
    "encapsulador",
    "encarregado administrativo de obras",
    "encarregado administrativo",
    "encarregado comercial",
    "encarregado contábil",
    "encarregado de almoxarifado",
    "encarregado de assistência técnica",
    "encarregado de açougue",
    "encarregado de caldeiraria",
    "encarregado de carpintaria",
    "encarregado de cobrança",
    "encarregado de compras",
    "encarregado de corte",
    "encarregado de elétrica",
    "encarregado de estacionamento",
    "encarregado de estamparia",
    "encarregado de estoque",
    "encarregado de expedição",
    "encarregado de faturamento",
    "encarregado de frota",
    "encarregado de hidráulica",
    "encarregado de injeção plástica",
    "encarregado de instalações",
    "encarregado de jardinagem",
    "encarregado de limpeza",
    "encarregado de logística",
    "encarregado de loja",
    "encarregado de manutenção predial",
    "encarregado de manutenção",
    "encarregado de marcenaria",
    "encarregado de mecânica",
    "encarregado de montagem",
    "encarregado de obras",
    "encarregado de oficina",
    "encarregado de operações",
    "encarregado de padaria",
    "encarregado de peixaria",
    "encarregado de perecíveis",
    "encarregado de prevenção de perdas",
    "encarregado de produção",
    "encarregado de pátio",
    "encarregado de serralheria",
    "encarregado de serviços gerais",
    "encarregado de solda",
    "encarregado de sondagem",
    "encarregado de transportes",
    "encarregado de usinagem",
    "encarregado financeiro",
    "encarregado industrial",
    "encarregado operacional",
    "enfermeiro auditor",
    "enfermeiro ccih",
    "enfermeiro chefe",
    "enfermeiro cme",
    "enfermeiro de centro cirúrgico",
    "enfermeiro de educação continuada",
    "enfermeiro de uti",
    "enfermeiro do trabalho",
    "enfermeiro estomaterapeuta",
    "enfermeiro nefrologista",
    "enfermeiro obstetra",
    "enfermeiro responsável técnico",
    "enfermeiro scih",
    "enfermeiro visitador",
    "enfermeiro",
    "enfestador",
    "engenheiro aeronáutico",
    "engenheiro agrimensor",
    "engenheiro agrícola",
    "engenheiro agrônomo",
    "engenheiro ambiental",
    "engenheiro automobilístico",
    "engenheiro biomédico",
    "engenheiro calculista",
    "engenheiro cartógrafo",
    "engenheiro civil",
    "engenheiro clínico",
    "engenheiro da qualidade",
    "engenheiro de alimentos",
    "engenheiro de aplicação",
    "engenheiro de ar condicionado",
    "engenheiro de assistência técnica",
    "engenheiro de automação industrial",
    "engenheiro de campo",
    "engenheiro de compras",
    "engenheiro de computação",
    "engenheiro de controle e automação",
    "engenheiro de custos",
    "engenheiro de instalações",
    "engenheiro de instrumentação",
    "engenheiro de manufatura",
    "engenheiro de manutenção predial",
    "engenheiro de manutenção",
    "engenheiro de materiais",
    "engenheiro de minas",
    "engenheiro de pesca",
    "engenheiro de petróleo",
    "engenheiro de planejamento",
    "engenheiro de processos",
    "engenheiro de produto",
    "engenheiro de produção",
    "engenheiro de projetos",
    "engenheiro de propostas",
    "engenheiro de segurança do trabalho",
    "engenheiro de serviços",
    "engenheiro de sistemas",
    "engenheiro de software",
    "engenheiro de suprimentos",
    "engenheiro de telecomunicações",
    "engenheiro de testes",
    "engenheiro de vendas",
    "engenheiro eletricista",
    "engenheiro eletroeletrônico",
    "engenheiro eletrônico",
    "engenheiro ferroviário",
    "engenheiro florestal",
    "engenheiro geotécnico",
    "engenheiro hidráulico",
    "engenheiro hídrico",
    "engenheiro industrial",
    "engenheiro mecatrônico",
    "engenheiro mecânico",
    "engenheiro metalúrgico",
    "engenheiro naval",
    "engenheiro orçamentista",
    "engenheiro químico",
    "engenheiro rodoviário",
    "engenheiro sanitarista",
    "engenheiro têxtil",
    "entregador",
    "ergonomista",
    "escrevente",
    "escriturário hospitalar",
    "escriturário",
    "especialista contábil",
    "especialista de produto",
    "especialista de projetos",
    "especialista de suprimentos",
    "especialista em compras",
    "especialista em e-commerce",
    "especialista em marketing digital",
    "especialista em marketing",
    "especialista em mídia",
    "especialista em recrutamento e seleção",
    "especialista em recursos humanos",
    "especialista em segurança da informação",
    "especialista em telecomunicações",
    "especialista em tesouraria",
    "especialista em treinamento e desenvolvimento",
    "especialista financeiro",
    "especialista fiscal",
    "especialista tributário",
    "estampador",
    "estatístico trainee",
    "estatístico",
    "esteticista",
    "estilista",
    "estofador",
    "estoquista",
    "estágio em administração de empresas",
    "estágio em administração hospitalar",
    "estágio em antropologia",
    "estágio em análise de sistemas",
    "estágio em análise e desenvolvimento de sistemas",
    "estágio em arquitetura",
    "estágio em arquivologia",
    "estágio em artes cênicas",
    "estágio em artes gráficas",
    "estágio em artes plásticas",
    "estágio em autocad",
    "estágio em banco de dados",
    "estágio em biblioteconomia",
    "estágio em biologia",
    "estágio em bioquímica",
    "estágio em cinema",
    "estágio em ciência da computação",
    "estágio em ciências aeronáuticas",
    "estágio em ciências atuariais",
    "estágio em ciências biomédicas",
    "estágio em ciências contábeis",
    "estágio em ciências sociais",
    "estágio em comunicação e marketing",
    "estágio em comunicação social",
    "estágio em comunicação",
    "estágio em comércio exterior",
    "estágio em controle de qualidade",
    "estágio em desenho industrial",
    "estágio em design de interiores",
    "estágio em design de joias",
    "estágio em design de produto",
    "estágio em design digital",
    "estágio em design gráfico",
    "estágio em design",
    "estágio em direito cível",
    "estágio em direito imobiliário",
    "estágio em direito societário",
    "estágio em direito trabalhista",
    "estágio em direito tributário",
    "estágio em direito",
    "estágio em economia",
    "estágio em educação física",
    "estágio em eletroeletrônica",
    "estágio em eletrotécnica",
    "estágio em eletrônica",
    "estágio em elétrica",
    "estágio em enfermagem",
    "estágio em engenharia aeronáutica",
    "estágio em engenharia agronômica",
    "estágio em engenharia ambiental",
    "estágio em engenharia automobilística",
    "estágio em engenharia biomédica",
    "estágio em engenharia cartográfica",
    "estágio em engenharia civil",
    "estágio em engenharia da computação",
    "estágio em engenharia da qualidade",
    "estágio em engenharia de agrimensura",
    "estágio em engenharia de alimentos",
    "estágio em engenharia de controle e automação",
    "estágio em engenharia de materiais",
    "estágio em engenharia de minas",
    "estágio em engenharia de produto",
    "estágio em engenharia de produção",
    "estágio em engenharia de segurança do trabalho",
    "estágio em engenharia de telecomunicações",
    "estágio em engenharia eletrônica",
    "estágio em engenharia elétrica e eletrônica",
    "estágio em engenharia elétrica",
    "estágio em engenharia florestal",
    "estágio em engenharia industrial",
    "estágio em engenharia mecatrônica",
    "estágio em engenharia mecânica",
    "estágio em engenharia metalúrgica",
    "estágio em engenharia naval",
    "estágio em engenharia química",
    "estágio em engenharia têxtil",
    "estágio em engenharia",
    "estágio em ensino médio",
    "estágio em estatística",
    "estágio em farmácia com ênfase em bioquímica",
    "estágio em farmácia",
    "estágio em filosofia",
    "estágio em fisioterapia",
    "estágio em fonoaudiologia",
    "estágio em fotografia",
    "estágio em física",
    "estágio em gastronomia",
    "estágio em geografia",
    "estágio em geologia",
    "estágio em gestão comercial",
    "estágio em gestão da qualidade",
    "estágio em gestão financeira",
    "estágio em gestão pública",
    "estágio em história",
    "estágio em hotelaria e turismo",
    "estágio em hotelaria",
    "estágio em informática",
    "estágio em jornalismo",
    "estágio em letras",
    "estágio em logística",
    "estágio em magistério",
    "estágio em marketing",
    "estágio em matemática",
    "estágio em medicina",
    "estágio em meio ambiente",
    "estágio em moda",
    "estágio em museologia",
    "estágio em música",
    "estágio em naturologia",
    "estágio em nutrição",
    "estágio em oceanografia",
    "estágio em odontologia",
    "estágio em pedagogia",
    "estágio em processamento de dados",
    "estágio em processos gerenciais",
    "estágio em produção editorial",
    "estágio em psicologia",
    "estágio em psicopedagogia",
    "estágio em publicidade e propaganda",
    "estágio em publicidade",
    "estágio em química",
    "estágio em radiologia",
    "estágio em recursos humanos",
    "estágio em redes de computadores",
    "estágio em relações internacionais",
    "estágio em relações públicas",
    "estágio em rádio e tv",
    "estágio em secretariado executivo",
    "estágio em secretariado",
    "estágio em serviço social",
    "estágio em sistemas de informação",
    "estágio em sociologia",
    "estágio em telecomunicações",
    "estágio em terapia ocupacional",
    "estágio em tradução",
    "estágio em transações imobiliárias",
    "estágio em turismo",
    "estágio em veterinária",
    "estágio em web design",
    "estágio em zootecnia",
    "estágio na área administrativa financeira",
    "estágio na área administrativa",
    "estágio na área audiovisual",
    "estágio na área bancária",
    "estágio na área comercial",
    "estágio na área da qualidade e meio ambiente",
    "estágio na área de alimentos e bebidas",
    "estágio na área de alimentos",
    "estágio na área de almoxarifado",
    "estágio na área de arte",
    "estágio na área de assessoria de imprensa",
    "estágio na área de assistência técnica",
    "estágio na área de assuntos regulatórios",
    "estágio na área de atendimento publicitário",
    "estágio na área de atendimento",
    "estágio na área de auditoria",
    "estágio na área de automação industrial",
    "estágio na área de back office",
    "estágio na área de benefícios",
    "estágio na área de business intelligence",
    "estágio na área de cobrança",
    "estágio na área de compliance",
    "estágio na área de compras",
    "estágio na área de contas a pagar",
    "estágio na área de contas a receber",
    "estágio na área de contratos",
    "estágio na área de controladoria",
    "estágio na área de controles internos",
    "estágio na área de criação",
    "estágio na área de crm",
    "estágio na área de crédito e cobrança",
    "estágio na área de custos",
    "estágio na área de departamento pessoal",
    "estágio na área de desenvolvimento .net",
    "estágio na área de desenvolvimento de sistemas",
    "estágio na área de desenvolvimento de software",
    "estágio na área de diagramação",
    "estágio na área de e-commerce",
    "estágio na área de edificações",
    "estágio na área de edição de imagens",
    "estágio na área de edição de vídeo",
    "estágio na área de educação infantil",
    "estágio na área de educação",
    "estágio na área de engenharia de processos",
    "estágio na área de engenharia de projetos",
    "estágio na área de estoque",
    "estágio na área de estética",
    "estágio na área de eventos",
    "estágio na área de expedição",
    "estágio na área de exportação",
    "estágio na área de facilities",
    "estágio na área de faturamento",
    "estágio na área de fusões e aquisições",
    "estágio na área de geoprocessamento",
    "estágio na área de gestão ambiental",
    "estágio na área de ginástica",
    "estágio na área de governança",
    "estágio na área de implantação de sistemas",
    "estágio na área de importação e exportação",
    "estágio na área de importação",
    "estágio na área de infraestrutura",
    "estágio na área de inteligência de mercado",
    "estágio na área de investimentos",
    "estágio na área de laboratório",
    "estágio na área de licitação",
    "estágio na área de links patrocinados",
    "estágio na área de manutenção",
    "estágio na área de marketing digital",
    "estágio na área de metrologia",
    "estágio na área de microbiologia",
    "estágio na área de musculação",
    "estágio na área de mídias digitais",
    "estágio na área de natação",
    "estágio na área de negócios",
    "estágio na área de obras",
    "estágio na área de orçamentos",
    "estágio na área de pcp",
    "estágio na área de pesquisa de mercado",
    "estágio na área de planejamento estratégico",
    "estágio na área de planejamento financeiro",
    "estágio na área de planejamento",
    "estágio na área de processos industriais",
    "estágio na área de processos",
    "estágio na área de produtos",
    "estágio na área de produção",
    "estágio na área de programação delphi",
    "estágio na área de programação java",
    "estágio na área de programação php",
    "estágio na área de programação web",
    "estágio na área de programação",
    "estágio na área de projetos",
    "estágio na área de qualidade",
    "estágio na área de recepção",
    "estágio na área de recreação",
    "estágio na área de recrutamento e seleção",
    "estágio na área de redação publicitária",
    "estágio na área de redação",
    "estágio na área de redes sociais",
    "estágio na área de redes",
    "estágio na área de relacionamento",
    "estágio na área de remuneração e benefícios",
    "estágio na área de remuneração",
    "estágio na área de reservas",
    "estágio na área de responsabilidade social",
    "estágio na área de revisão de texto",
    "estágio na área de risco",
    "estágio na área de saúde bucal",
    "estágio na área de segurança da informação",
    "estágio na área de segurança do trabalho",
    "estágio na área de seguros",
    "estágio na área de sistemas",
    "estágio na área de suporte técnico",
    "estágio na área de suprimentos",
    "estágio na área de sustentabilidade",
    "estágio na área de tecnologia da informação",
    "estágio na área de tesouraria",
    "estágio na área de testes",
    "estágio na área de trade marketing",
    "estágio na área de transportes",
    "estágio na área de treinamento e desenvolvimento",
    "estágio na área de vendas",
    "estágio na área financeira",
    "estágio na área fiscal",
    "estágio na área imobiliária",
    "estágio na área industrial",
    "estágio na área mecânica",
    "estágio na área tributária",
    "estágio na área técnica",
    "estágio técnico em administração de empresas",
    "estágio técnico em agrimensura",
    "estágio técnico em alimentos",
    "estágio técnico em análises clínicas",
    "estágio técnico em contabilidade",
    "estágio técnico em edificações",
    "estágio técnico em eletroeletrônica",
    "estágio técnico em eletromecânica",
    "estágio técnico em eletrotécnica",
    "estágio técnico em eletrônica",
    "estágio técnico em elétrica",
    "estágio técnico em enfermagem do trabalho",
    "estágio técnico em enfermagem",
    "estágio técnico em farmácia",
    "estágio técnico em gestão empresarial",
    "estágio técnico em informática",
    "estágio técnico em logística",
    "estágio técnico em mecatrônica",
    "estágio técnico em mecânica",
    "estágio técnico em meio ambiente",
    "estágio técnico em nutrição",
    "estágio técnico em plásticos",
    "estágio técnico em prótese dentária",
    "estágio técnico em publicidade e propaganda",
    "estágio técnico em qualidade",
    "estágio técnico em química",
    "estágio técnico em secretariado",
    "estágio técnico em segurança do trabalho",
    "estágio técnico em telecomunicações",
    "estágio técnico na área de manutenção",
    "estágio técnico na área de recursos humanos",
    "estágio técnico na área de vendas",
    "etiquetador",
    "executivo de contas",
    "executivo de vendas",
    "extrusor",
    "farmacêutico bioquímico",
    "farmacêutico homeopata",
    "farmacêutico hospitalar",
    "farmacêutico industrial",
    "farmacêutico trainee",
    "farmacêutico",
    "faturista hospitalar",
    "faturista",
    "ferramenteiro de moldes plásticos",
    "ferramenteiro",
    "ferreiro",
    "figurinista",
    "fiscal de caixa",
    "fiscal de campo",
    "fiscal de loja",
    "fiscal de obras",
    "fiscal de prevenção de perdas",
    "fiscal de tráfego",
    "fisioterapeuta acupunturista",
    "fisioterapeuta dermato funcional",
    "fisioterapeuta do trabalho",
    "fisioterapeuta esportivo",
    "fisioterapeuta home care",
    "fisioterapeuta hospitalar",
    "fisioterapeuta neurofuncional",
    "fisioterapeuta oncofuncional",
    "fisioterapeuta respiratório",
    "fisioterapeuta rpg",
    "fisioterapeuta vendedor",
    "fisioterapeuta",
    "florista",
    "fonoaudiólogo",
    "forneiro",
    "fotogravador",
    "fotógrafo",
    "fracionador",
    "frentista",
    "fresador cnc",
    "fresador",
    "fundidor de metais",
    "funileiro de veículos",
    "funileiro",
    "físico",
    "galvanizador",
    "garantista de veículos",
    "garantista",
    "garde manger",
    "garçom",
    "gastrônomo",
    "gemólogo",
    "geofísico",
    "gerente administrativo",
    "gerente agrícola",
    "gerente bancário",
    "gerente comercial",
    "gerente de aeroporto",
    "gerente de alimentos e bebidas",
    "gerente de almoxarifado",
    "gerente de armazém",
    "gerente de assistência técnica",
    "gerente de assuntos regulatórios",
    "gerente de atendimento",
    "gerente de auditoria",
    "gerente de benefícios",
    "gerente de canais",
    "gerente de cinema",
    "gerente de cobrança",
    "gerente de compliance",
    "gerente de compras",
    "gerente de comunicação",
    "gerente de comércio exterior",
    "gerente de condomínio",
    "gerente de confecção",
    "gerente de contabilidade",
    "gerente de contas",
    "gerente de conteúdo",
    "gerente de contratos",
    "gerente de controladoria",
    "gerente de controle de qualidade",
    "gerente de crm",
    "gerente de crédito e cobrança",
    "gerente de crédito imobiliário",
    "gerente de custos",
    "gerente de departamento pessoal",
    "gerente de e-commerce",
    "gerente de enfermagem",
    "gerente de engenharia",
    "gerente de estoque",
    "gerente de eventos",
    "gerente de expedição",
    "gerente de facilities",
    "gerente de factoring",
    "gerente de farmácia",
    "gerente de faturamento",
    "gerente de filial",
    "gerente de frota",
    "gerente de fusões e aquisições",
    "gerente de garantia da qualidade",
    "gerente de hotel",
    "gerente de infraestrutura",
    "gerente de inteligência de mercado",
    "gerente de inventário",
    "gerente de investimentos",
    "gerente de lavanderia",
    "gerente de licitação",
    "gerente de limpeza",
    "gerente de locação",
    "gerente de logística",
    "gerente de loja",
    "gerente de manutenção industrial",
    "gerente de manutenção mecânica",
    "gerente de manutenção predial",
    "gerente de manutenção",
    "gerente de marketing digital",
    "gerente de marketing",
    "gerente de merchandising",
    "gerente de moda",
    "gerente de mídias sociais",
    "gerente de negócios",
    "gerente de nutrição",
    "gerente de obras",
    "gerente de oficina",
    "gerente de orçamento",
    "gerente de patrimônio",
    "gerente de pcp",
    "gerente de pesquisa e desenvolvimento",
    "gerente de peças",
    "gerente de planejamento",
    "gerente de prevenção de perdas",
    "gerente de pricing",
    "gerente de processos",
    "gerente de produto",
    "gerente de produção",
    "gerente de projeto",
    "gerente de projetos pmo",
    "gerente de projetos web",
    "gerente de projetos",
    "gerente de pós-venda",
    "gerente de qsms",
    "gerente de qualidade",
    "gerente de recepção",
    "gerente de recrutamento e seleção",
    "gerente de recursos humanos",
    "gerente de reservas",
    "gerente de responsabilidade social",
    "gerente de restaurante",
    "gerente de saúde, segurança e meio ambiente",
    "gerente de segurança da informação",
    "gerente de segurança do trabalho",
    "gerente de segurança patrimonial",
    "gerente de seguros",
    "gerente de sem",
    "gerente de seo",
    "gerente de serviços",
    "gerente de sinistros",
    "gerente de suporte técnico",
    "gerente de suprimentos",
    "gerente de tecnologia da informação",
    "gerente de telecomunicações",
    "gerente de tesouraria",
    "gerente de trade marketing",
    "gerente de transportes",
    "gerente de treinamento e desenvolvimento",
    "gerente de turismo",
    "gerente de vendas",
    "gerente financeiro",
    "gerente fiscal",
    "gerente geral",
    "gerente imobiliário",
    "gerente industrial",
    "gerente jurídico",
    "gerente médico",
    "gerente operacional",
    "gerente pedagógico",
    "gerente predial",
    "gerente tributário",
    "gerente técnico",
    "gerontólogo",
    "gesseiro",
    "gestor ambiental",
    "gestor portuário",
    "geógrafo",
    "geólogo",
    "governanta",
    "greidista",
    "guia de turismo",
    "historiador",
    "ilustrador",
    "impermeabilizador",
    "implantador de sistemas",
    "impressor de rotogravura",
    "impressor digital",
    "impressor flexográfico",
    "impressor off-set",
    "impressor serigráfico",
    "impressor tampográfico",
    "impressor",
    "inspetor de alunos",
    "inspetor de equipamentos",
    "inspetor de fabricação",
    "inspetor de frota",
    "inspetor de pintura",
    "inspetor de qualidade",
    "inspetor de riscos",
    "inspetor de segurança",
    "inspetor de solda",
    "inspetor veicular",
    "instalador de acessórios",
    "instalador de alarmes",
    "instalador de ar condicionado",
    "instalador de comunicação visual",
    "instalador de equipamentos eletrônicos",
    "instalador de insulfilm",
    "instalador de rastreador veicular",
    "instalador de telecomunicações",
    "instalador de tv a cabo",
    "instalador de vidros automotivos",
    "instalador de vidros",
    "instalador",
    "instrumentador cirúrgico",
    "instrumentista",
    "instrutor de autoescola",
    "instrutor de aviação",
    "instrutor de cabeleireiro",
    "instrutor de curso",
    "instrutor de excel",
    "instrutor de motorista",
    "instrutor de pilates",
    "instrutor de qualidade",
    "instrutor de treinamento",
    "intérprete de libras",
    "intérprete",
    "jardineiro",
    "jatista",
    "jornaleiro",
    "jornalista",
    "laboratorista de concreto",
    "laboratorista de solos",
    "laboratorista fotográfico",
    "lactarista",
    "laminador",
    "lancheiro",
    "lapidador de gemas",
    "lapidador de vidros",
    "laqueador",
    "lavadeiro",
    "lavador de veículos",
    "lavador",
    "layoutista",
    "leiturista",
    "letrista",
    "limpador de vidros",
    "limpador",
    "lingotador",
    "lixador",
    "locutor",
    "lubrificador",
    "lustrador de móveis",
    "líder comercial",
    "líder de atendimento",
    "líder de caixa",
    "líder de cobrança",
    "líder de controle de qualidade",
    "líder de equipe",
    "líder de expedição",
    "líder de extrusão",
    "líder de help desk",
    "líder de logística",
    "líder de loja",
    "líder de manutenção elétrica",
    "líder de manutenção",
    "líder de merchandising",
    "líder de montagem",
    "líder de pintura",
    "líder de portaria",
    "líder de produção",
    "líder de recepção",
    "líder de refrigeração",
    "líder de testes",
    "líder de usinagem",
    "líder de vendas",
    "líder de visual merchandising",
    "magarefe",
    "mandrilhador",
    "manicure e pedicure",
    "manipulador de cosméticos",
    "manipulador de farmácia",
    "manobrista",
    "maqueiro",
    "maquetista",
    "maquiador",
    "maquinista de trem",
    "marceneiro",
    "marinheiro",
    "marmorista",
    "marteleteiro",
    "masseiro",
    "massoterapeuta",
    "matemático",
    "maçariqueiro",
    "maître",
    "mecânico compressor",
    "mecânico de ar condicionado",
    "mecânico de bicicletas",
    "mecânico de empilhadeira",
    "mecânico de manutenção de aeronaves",
    "mecânico de manutenção hidráulica",
    "mecânico de manutenção predial",
    "mecânico de manutenção",
    "mecânico de máquinas",
    "mecânico de refrigeração",
    "mecânico de sopro",
    "mecânico de usinagem",
    "mecânico de veículos",
    "mecânico eletricista",
    "mecânico hidráulico",
    "mecânico industrial",
    "mecânico lubrificador",
    "mecânico montador",
    "mecânico",
    "mecânico",
    "medidor de obras",
    "meio oficial ajustador mecânico",
    "meio oficial carpinteiro",
    "meio oficial de caldeiraria",
    "meio oficial de ferramentaria",
    "meio oficial de impressão flexográfica",
    "meio oficial de manutenção predial",
    "meio oficial de manutenção",
    "meio oficial de pintura",
    "meio oficial de refrigeração",
    "meio oficial eletricista",
    "meio oficial fresador cnc",
    "meio oficial fresador",
    "meio oficial funileiro",
    "meio oficial hidráulico",
    "meio oficial marceneiro",
    "meio oficial mecânico de manutenção",
    "meio oficial mecânico",
    "meio oficial montador",
    "meio oficial serralheiro",
    "meio oficial soldador",
    "meio oficial torneiro mecânico",
    "mensageiro de hotel",
    "mensageiro",
    "merendeira",
    "mestre cervejeiro",
    "mestre de cabotagem",
    "mestre de obras",
    "meteorologista",
    "metrologista",
    "microbiologista",
    "modelista",
    "modelo de prova",
    "modelo",
    "moldador",
    "moldureiro",
    "moleiro",
    "monitor de alunos",
    "monitor de informática",
    "monitor de qualidade",
    "montador de calçados",
    "montador de elevador",
    "montador de equipamentos",
    "montador de esquadrias",
    "montador de estruturas metálicas",
    "montador de máquinas",
    "montador de móveis",
    "montador de painéis",
    "montador de veículos",
    "montador eletrônico",
    "montador elétrico",
    "montador hidráulico",
    "montador",
    "mordomo",
    "motoboy",
    "motorista administrativo",
    "motorista categoria b",
    "motorista categoria c",
    "motorista categoria d",
    "motorista categoria e",
    "motorista de ambulância",
    "motorista de caminhão",
    "motorista de carro forte",
    "motorista de guincho",
    "motorista de ônibus",
    "motorista entregador",
    "motorista escolar",
    "motorista executivo",
    "motorista operador de betoneira",
    "motorista",
    "museólogo",
    "musicoterapeuta",
    "mãe social",
    "médico acupunturista",
    "médico alergologista",
    "médico ambulatorial",
    "médico anestesista",
    "médico auditor",
    "médico cardiologista",
    "médico cirurgião plástico",
    "médico cirurgião torácico",
    "médico cirurgião vascular",
    "médico cirurgião",
    "médico clínico geral",
    "médico colonoscopista",
    "médico dermatologista",
    "médico do esporte",
    "médico do trabalho",
    "médico do tráfego",
    "médico ecocardiografista",
    "médico emergencista",
    "médico endocrinologista",
    "médico esteticista",
    "médico fisiatra",
    "médico gastroenterologista",
    "médico gastropediatra",
    "médico geneticista",
    "médico geriatra",
    "médico ginecologista",
    "médico hebiatra",
    "médico hematologista",
    "médico hemoterapeuta",
    "médico homeopata",
    "médico imunologista",
    "médico infectologista",
    "médico intensivista",
    "médico mamografista",
    "médico mastologista",
    "médico nefrologista",
    "médico neurocirurgião",
    "médico neurologista",
    "médico nutrólogo",
    "médico obstetra",
    "médico oftalmologista",
    "médico oncologista",
    "médico ortomolecular",
    "médico ortopedista",
    "médico otorrinolaringologista",
    "médico patologista",
    "médico pediatra",
    "médico plantonista",
    "médico pneumologista",
    "médico proctologista",
    "médico psiquiatra",
    "médico radiologista",
    "médico regulador",
    "médico reumatologista",
    "médico traumatologista",
    "médico ultrassonografista",
    "médico urologista",
    "médico visitador",
    "músico",
    "naturólogo",
    "neuropsicólogo",
    "nivelador",
    "nutricionista clínico",
    "nutricionista comercial",
    "nutricionista de produção",
    "nutricionista de qualidade",
    "nutricionista do esporte",
    "nutricionista",
    "oceanógrafo",
    "office-boy",
    "oficial de manutenção predial",
    "oficial de manutenção",
    "oficial de redes",
    "oficial de refrigeração",
    "oficial eletricista",
    "oficial mecânico",
    "oficial serralheiro",
    "operador audiovisual",
    "operador de balança rodoviária",
    "operador de bomba de concreto",
    "operador de caixa",
    "operador de calandra",
    "operador de caldeira",
    "operador de call center",
    "operador de central de concreto",
    "operador de centro de usinagem",
    "operador de cftv",
    "operador de cnc",
    "operador de cobrança",
    "operador de coladeira",
    "operador de computador",
    "operador de controle mestre",
    "operador de corte e dobra",
    "operador de corte e solda",
    "operador de corte e vinco",
    "operador de câmaras frias",
    "operador de câmbio",
    "operador de dobradeira",
    "operador de eletroerosão",
    "operador de empilhadeira",
    "operador de equipamento",
    "operador de escavadeira",
    "operador de estacionamento",
    "operador de estação de tratamento de águas e efluentes",
    "operador de extrusora",
    "operador de factoring",
    "operador de furadeira",
    "operador de guilhotina",
    "operador de guincho",
    "operador de guindaste",
    "operador de injetora",
    "operador de laminação",
    "operador de lixadeira",
    "operador de logística",
    "operador de mesa",
    "operador de monitoramento",
    "operador de montagem",
    "operador de motoniveladora",
    "operador de motosserra",
    "operador de máquina agrícola",
    "operador de máquina copiadora",
    "operador de máquina de corte",
    "operador de máquina de sopro",
    "operador de máquina perfuratriz",
    "operador de máquina",
    "operador de negócios",
    "operador de onduladeira",
    "operador de perfiladeira",
    "operador de photoshop",
    "operador de plotter",
    "operador de ponte rolante",
    "operador de prensa",
    "operador de processos químicos",
    "operador de puncionadeira",
    "operador de pá carregadeira",
    "operador de rama",
    "operador de rastreamento",
    "operador de rebocador",
    "operador de redes",
    "operador de refrigeração",
    "operador de retificadora",
    "operador de retroescavadeira",
    "operador de rolo compactador",
    "operador de rádio",
    "operador de sala de máquinas",
    "operador de serra",
    "operador de telemarketing",
    "operador de televendas",
    "operador de torno automático",
    "operador de torno cnc",
    "operador de torno",
    "operador de tráfego",
    "operador de turismo",
    "operador de utilidades",
    "operador de zincagem",
    "operador de áudio",
    "operador mantenedor",
    "orientador educacional",
    "orientador sócio educativo",
    "orçamentista civil",
    "orçamentista",
    "ourives",
    "padeiro",
    "paisagista",
    "palestrante",
    "panfleteiro",
    "passador",
    "pedagogo empresarial",
    "pedagogo",
    "pedreiro",
    "peixeiro",
    "perfumista",
    "perito judicial",
    "personal stylist",
    "personal trainer",
    "pesador",
    "pesquisador de mercado",
    "pesquisador",
    "piloteiro",
    "piloto agrícola",
    "piloto de avião",
    "piloto de helicóptero",
    "piloto de testes",
    "pintor automotivo",
    "pintor de estruturas metálicas",
    "pintor de móveis",
    "pintor de obras",
    "pintor eletrostático",
    "pintor",
    "pizzaiolo",
    "planejador de produção",
    "planejador de projetos",
    "podólogo",
    "polidor de metais",
    "polidor de veículos",
    "polidor",
    "porteiro",
    "prensista",
    "preparador de autos",
    "preparador de cnc",
    "preparador de máquina injetora",
    "preparador de máquinas",
    "preparador de pintura",
    "preparador de tintas",
    "preparador de torno",
    "preparador físico",
    "processista",
    "produtor artístico",
    "produtor cultural",
    "produtor de casting",
    "produtor de conteúdo",
    "produtor de eventos",
    "produtor de moda",
    "produtor de televisão",
    "produtor de vídeo",
    "produtor editorial",
    "produtor gráfico",
    "produtor musical",
    "professor auxiliar",
    "professor de administração de empresas",
    "professor de administração pública",
    "professor de agronomia",
    "professor de alemão",
    "professor de anatomia",
    "professor de arquitetura e urbanismo",
    "professor de artes cênicas",
    "professor de artes marciais",
    "professor de artesanato",
    "professor de autocad",
    "professor de automação industrial",
    "professor de biologia",
    "professor de biomedicina",
    "professor de canto",
    "professor de capoeira",
    "professor de circo",
    "professor de ciência da computação",
    "professor de ciências contábeis",
    "professor de computação gráfica",
    "professor de comunicação social",
    "professor de comunicação visual",
    "professor de comércio exterior",
    "professor de construção civil",
    "professor de coreldraw",
    "professor de costura",
    "professor de cálculo",
    "professor de dança",
    "professor de departamento pessoal",
    "professor de desenho técnico",
    "professor de design de interiores",
    "professor de design gráfico",
    "professor de direito administrativo",
    "professor de direito constitucional",
    "professor de direito penal",
    "professor de direito tributário",
    "professor de direito",
    "professor de economia",
    "professor de edificações",
    "professor de educação artística",
    "professor de educação especial",
    "professor de educação física",
    "professor de educação infantil",
    "professor de eletrotécnica",
    "professor de eletrônica",
    "professor de elétrica",
    "professor de empreendedorismo",
    "professor de enfermagem",
    "professor de engenharia ambiental",
    "professor de engenharia civil",
    "professor de engenharia da computação",
    "professor de engenharia de produção",
    "professor de engenharia elétrica",
    "professor de engenharia mecatrônica",
    "professor de engenharia mecânica",
    "professor de engenharia",
    "professor de ensino a distância",
    "professor de ensino fundamental",
    "professor de ensino médio",
    "professor de ensino religioso",
    "professor de ensino técnico",
    "professor de espanhol",
    "professor de estatística",
    "professor de estética e cosmetologia",
    "professor de expressão corporal",
    "professor de farmácia",
    "professor de filosofia",
    "professor de fisioterapia",
    "professor de fotografia",
    "professor de francês",
    "professor de futebol",
    "professor de física",
    "professor de gastronomia",
    "professor de geografia",
    "professor de gestão ambiental",
    "professor de gestão da qualidade",
    "professor de gestão de projetos",
    "professor de gestão financeira",
    "professor de gestão hospitalar",
    "professor de ginástica laboral",
    "professor de ginástica",
    "professor de grego",
    "professor de hebraico",
    "professor de hidroginástica",
    "professor de história",
    "professor de holandês",
    "professor de hotelaria e turismo",
    "professor de hotelaria",
    "professor de idiomas",
    "professor de informática",
    "professor de inglês e espanhol",
    "professor de inglês e português",
    "professor de inglês",
    "professor de italiano",
    "professor de japonês",
    "professor de jornalismo",
    "professor de letras",
    "professor de libras",
    "professor de literatura",
    "professor de logística",
    "professor de mandarim",
    "professor de manicure",
    "professor de maquiagem",
    "professor de marketing",
    "professor de massoterapia",
    "professor de matemática financeira",
    "professor de matemática",
    "professor de mecânica aeronáutica",
    "professor de mecânica",
    "professor de medicina do trabalho",
    "professor de medicina",
    "professor de moda",
    "professor de musculação",
    "professor de métodos e processos",
    "professor de música",
    "professor de natação",
    "professor de norueguês",
    "professor de nutrição",
    "professor de odontologia",
    "professor de oracle",
    "professor de pedagogia",
    "professor de petróleo e gás",
    "professor de photoshop",
    "professor de php",
    "professor de pintura em tela",
    "professor de podologia",
    "professor de português",
    "professor de programação de computadores",
    "professor de programação de redes",
    "professor de prótese dentária",
    "professor de psicanálise",
    "professor de psicologia",
    "professor de psicopedagogia",
    "professor de publicidade e propaganda",
    "professor de química",
    "professor de radiologia",
    "professor de recreação",
    "professor de recursos humanos",
    "professor de redação",
    "professor de relações públicas",
    "professor de robótica",
    "professor de russo",
    "professor de secretariado",
    "professor de segurança do trabalho",
    "professor de serviço social",
    "professor de sistemas de informação",
    "professor de sociologia",
    "professor de sql",
    "professor de sueco",
    "professor de teatro",
    "professor de tecnologia da informação",
    "professor de telemarketing",
    "professor de teologia",
    "professor de turismo",
    "professor de tênis",
    "professor de vendas",
    "professor de veterinária",
    "professor de violão",
    "professor de visual basic",
    "professor de web design",
    "professor de windows",
    "professor de xadrez",
    "professor de yoga",
    "professor de zootecnia",
    "professor de árabe",
    "professor de ética",
    "professor universitário",
    "professor",
    "programador .net",
    "programador abap",
    "programador advpl",
    "programador android",
    "programador asp",
    "programador asp.net",
    "programador back-end",
    "programador bpm",
    "programador c",
    "programador c#",
    "programador c++",
    "programador clipper",
    "programador clp",
    "programador cnc",
    "programador cobol",
    "programador de banco de dados",
    "programador de bordado",
    "programador de business intelligence",
    "programador de centro de usinagem",
    "programador de jogos",
    "programador de manutenção",
    "programador de materiais",
    "programador de produção",
    "programador de software",
    "programador delphi",
    "programador dynamics",
    "programador etl",
    "programador flash",
    "programador flex",
    "programador forms reports",
    "programador front-end",
    "programador genexus",
    "programador ios",
    "programador java",
    "programador javascript",
    "programador linux",
    "programador microsiga",
    "programador mobile",
    "programador node.js",
    "programador oracle",
    "programador php",
    "programador pl sql",
    "programador powerbuilder",
    "programador progress",
    "programador protheus",
    "programador python",
    "programador qlikview",
    "programador ruby on rails",
    "programador sap",
    "programador sharepoint",
    "programador soa",
    "programador sql",
    "programador trainee",
    "programador visual basic",
    "programador visual foxpro",
    "programador visual",
    "programador web",
    "programador",
    "programador",
    "projetista 3d",
    "projetista cadista",
    "projetista civil",
    "projetista de ar condicionado",
    "projetista de automação",
    "projetista de estruturas metálicas",
    "projetista de ferramentas",
    "projetista de iluminação",
    "projetista de instalações",
    "projetista de moldes",
    "projetista de máquinas",
    "projetista de móveis",
    "projetista de produto",
    "projetista de telecomunicações",
    "projetista eletrônico",
    "projetista elétrico",
    "projetista hidráulico",
    "projetista mecânico",
    "projetista orçamentista",
    "projetista",
    "promotor de crédito",
    "promotor de eventos",
    "promotor de financiamento",
    "promotor de merchandising",
    "promotor de vendas",
    "promotor técnico",
    "propagandista",
    "protético",
    "psicomotricista",
    "psicopedagogo",
    "psicólogo clínico",
    "psicólogo do trânsito",
    "psicólogo escolar",
    "psicólogo hospitalar",
    "psicólogo organizacional",
    "psicólogo social",
    "psicólogo",
    "publicitário",
    "químico industrial",
    "químico responsável",
    "químico",
    "radialista",
    "rasteleiro",
    "rebarbador de metais",
    "rebobinador",
    "recebedor",
    "recepcionista de hotel",
    "recepcionista hospitalar",
    "recepcionista",
    "recreador",
    "recuperador de crédito",
    "redator publicitário",
    "redator web",
    "redator",
    "regulador de sinistros",
    "relações públicas",
    "relojoeiro",
    "repositor de perecíveis",
    "repositor",
    "representante comercial",
    "repórter",
    "retificador",
    "revisor de tecidos",
    "revisor de texto",
    "rigger",
    "robotista",
    "roteirista",
    "roteirizador",
    "saladeiro",
    "salgadeiro",
    "salva vidas",
    "sapateiro",
    "scouter",
    "scrum master",
    "secretária comercial",
    "secretária escolar",
    "secretária executiva",
    "secretária financeira",
    "secretária",
    "segurança patrimonial",
    "segurança",
    "segurança",
    "selecionador",
    "separador de mercadorias",
    "serralheiro",
    "servente de obras",
    "sinaleiro",
    "sociólogo",
    "socorrista",
    "soldador montador",
    "soldador",
    "sommelier",
    "sondador",
    "sonoplasta",
    "sorveteiro",
    "steward",
    "superintendente comercial",
    "superintendente de operações",
    "supervisor administrativo",
    "supervisor agrícola",
    "supervisor comercial",
    "supervisor contábil",
    "supervisor de alimentos e bebidas",
    "supervisor de almoxarifado",
    "supervisor de arquivo",
    "supervisor de assistência técnica",
    "supervisor de atendimento ao cliente",
    "supervisor de auditoria",
    "supervisor de automação",
    "supervisor de back office",
    "supervisor de caixa",
    "supervisor de caldeiraria",
    "supervisor de call center",
    "supervisor de campo",
    "supervisor de cobrança",
    "supervisor de compras",
    "supervisor de comércio exterior",
    "supervisor de condomínio",
    "supervisor de contas a pagar",
    "supervisor de contas a receber",
    "supervisor de contas",
    "supervisor de contratos",
    "supervisor de controladoria",
    "supervisor de controle de qualidade",
    "supervisor de costura",
    "supervisor de crédito e cobrança",
    "supervisor de crédito imobiliário",
    "supervisor de crédito",
    "supervisor de custos",
    "supervisor de departamento pessoal",
    "supervisor de e-commerce",
    "supervisor de elétrica",
    "supervisor de engenharia",
    "supervisor de estamparia",
    "supervisor de eventos",
    "supervisor de expedição",
    "supervisor de facilities",
    "supervisor de farmácia",
    "supervisor de faturamento",
    "supervisor de ferramentaria",
    "supervisor de filial",
    "supervisor de folha de pagamento",
    "supervisor de frota",
    "supervisor de fundição",
    "supervisor de garantia da qualidade",
    "supervisor de hotelaria",
    "supervisor de infraestrutura",
    "supervisor de injeção plástica",
    "supervisor de instalação",
    "supervisor de inteligência de mercado",
    "supervisor de laboratório",
    "supervisor de lavanderia",
    "supervisor de limpeza",
    "supervisor de locação",
    "supervisor de logística",
    "supervisor de loja",
    "supervisor de manutenção automotiva",
    "supervisor de manutenção eletromecânica",
    "supervisor de manutenção elétrica",
    "supervisor de manutenção industrial",
    "supervisor de manutenção mecânica",
    "supervisor de manutenção predial",
    "supervisor de manutenção",
    "supervisor de marketing",
    "supervisor de meio ambiente",
    "supervisor de merchandising",
    "supervisor de mineração",
    "supervisor de monitoramento",
    "supervisor de monitoria",
    "supervisor de montagem",
    "supervisor de negócios",
    "supervisor de nutrição",
    "supervisor de obras",
    "supervisor de operações",
    "supervisor de ouvidoria",
    "supervisor de patrimônio",
    "supervisor de pcp",
    "supervisor de peças",
    "supervisor de pintura",
    "supervisor de planejamento",
    "supervisor de prevenção de perdas",
    "supervisor de processos",
    "supervisor de produtos",
    "supervisor de produção",
    "supervisor de projeto social",
    "supervisor de projetos",
    "supervisor de pós-venda",
    "supervisor de qualidade",
    "supervisor de radiologia",
    "supervisor de recepção",
    "supervisor de recrutamento e seleção",
    "supervisor de recursos humanos",
    "supervisor de refrigeração",
    "supervisor de relacionamento",
    "supervisor de reservas",
    "supervisor de restaurante",
    "supervisor de sac",
    "supervisor de segurança do trabalho",
    "supervisor de segurança patrimonial",
    "supervisor de seguros",
    "supervisor de serviços gerais",
    "supervisor de suprimentos",
    "supervisor de tecnologia da informação",
    "supervisor de tesouraria",
    "supervisor de trade marketing",
    "supervisor de transportes",
    "supervisor de treinamento e desenvolvimento",
    "supervisor de usinagem",
    "supervisor de vendas",
    "supervisor financeiro",
    "supervisor florestal",
    "supervisor industrial",
    "supervisor pedagógico",
    "supervisor tributário",
    "supervisor técnico",
    "suporte técnico",
    "sushiman",
    "síndico",
    "tapeceiro de veículos",
    "tapeceiro",
    "tecelão",
    "tecnólogo em alimentos",
    "tecnólogo em construção civil",
    "tecnólogo em mecânica",
    "tecnólogo em radiologia",
    "tecnólogo em redes de computadores",
    "tecnólogo em saneamento ambiental",
    "telefonista recepcionista",
    "telefonista",
    "telhadista",
    "terapeuta ocupacional",
    "tesoureiro",
    "tintureiro",
    "topógrafo",
    "torneiro ferramenteiro",
    "torneiro mecânico",
    "tosador",
    "trader",
    "tradutor de alemão",
    "tradutor de chinês",
    "tradutor de coreano",
    "tradutor de espanhol",
    "tradutor de francês",
    "tradutor de inglês",
    "tradutor de italiano",
    "tradutor de japonês",
    "tradutor de mandarim",
    "tradutor de árabe",
    "tradutor",
    "trainee em administração de empresas",
    "trainee em agronomia",
    "trainee em arquitetura e urbanismo",
    "trainee em ciências contábeis",
    "trainee em direito",
    "trainee em economia",
    "trainee em engenharia civil",
    "trainee em engenharia de produção",
    "trainee em engenharia elétrica",
    "trainee em engenharia mecânica",
    "trainee em engenharia química",
    "trainee em engenharia",
    "trainee em marketing",
    "trainee em nutrição",
    "trainee em recursos humanos",
    "trainee em suporte técnico",
    "trainee em vendas",
    "trainee na área comercial",
    "trainee na área de compras",
    "trainee na área de departamento pessoal",
    "trainee na área de tecnologia da informação",
    "trainee na área financeira",
    "trainee na área fiscal",
    "trainee na área industrial",
    "tratador de piscina",
    "tratorista",
    "traçador de caldeiraria",
    "trefilador",
    "trocador de moldes",
    "turismólogo",
    "técnico administrativo",
    "técnico agrícola",
    "técnico bancário",
    "técnico comercial",
    "técnico contábil",
    "técnico da qualidade",
    "técnico de arquivo",
    "técnico de assistência técnica",
    "técnico de atendimento",
    "técnico de campo",
    "técnico de celular",
    "técnico de controle de qualidade",
    "técnico de equipamentos médicos",
    "técnico de equipamentos",
    "técnico de eventos",
    "técnico de gasoterapia",
    "técnico de gesso",
    "técnico de help desk",
    "técnico de iluminação",
    "técnico de implantação",
    "técnico de impressora",
    "técnico de infraestrutura",
    "técnico de instalação",
    "técnico de laboratório industrial",
    "técnico de laboratório",
    "técnico de manutenção de computadores",
    "técnico de manutenção elétrica",
    "técnico de manutenção industrial",
    "técnico de manutenção predial",
    "técnico de materiais",
    "técnico de monitoramento",
    "técnico de montagem",
    "técnico de planejamento de manutenção",
    "técnico de planejamento",
    "técnico de processos",
    "técnico de produto",
    "técnico de produção",
    "técnico de redes",
    "técnico de service desk",
    "técnico de sinistro",
    "técnico de som",
    "técnico de suporte",
    "técnico de tacógrafo",
    "técnico de testes",
    "técnico de vendas",
    "técnico eletricista",
    "técnico eletroeletrônico",
    "técnico eletromecânico",
    "técnico em agrimensura",
    "técnico em agropecuária",
    "técnico em alimentos",
    "técnico em análises clínicas",
    "técnico em automação bancária",
    "técnico em automação industrial",
    "técnico em automação predial",
    "técnico em biblioteconomia",
    "técnico em cabeamento",
    "técnico em cftv",
    "técnico em documentação",
    "técnico em edificações",
    "técnico em eletrocardiograma",
    "técnico em eletroencefalograma",
    "técnico em eletrotécnica",
    "técnico em eletrônica",
    "técnico em enfermagem do trabalho",
    "técnico em enfermagem",
    "técnico em espirometria",
    "técnico em esquadrias de alumínio",
    "técnico em estradas",
    "técnico em farmácia",
    "técnico em fibras ópticas",
    "técnico em gastronomia",
    "técnico em geoprocessamento",
    "técnico em hemoterapia",
    "técnico em imobilização ortopédica",
    "técnico em informática",
    "técnico em inspeção de equipamentos",
    "técnico em instrumentação",
    "técnico em licitação",
    "técnico em logística",
    "técnico em lubrificação",
    "técnico em manutenção automotiva",
    "técnico em manutenção de elevadores",
    "técnico em manutenção",
    "técnico em mecatrônica",
    "técnico em mecânica",
    "técnico em medição",
    "técnico em meio ambiente",
    "técnico em metalurgia",
    "técnico em metrologia",
    "técnico em mineração",
    "técnico em nutrição",
    "técnico em optometria",
    "técnico em orientação e mobilidade",
    "técnico em panificação",
    "técnico em plásticos",
    "técnico em polissonografia",
    "técnico em processos industriais",
    "técnico em projetos",
    "técnico em química",
    "técnico em radiologia",
    "técnico em recursos humanos",
    "técnico em refrigeração",
    "técnico em saneamento",
    "técnico em saúde bucal",
    "técnico em secretariado",
    "técnico em segurança do trabalho",
    "técnico em segurança e meio ambiente",
    "técnico em segurança eletrônica",
    "técnico em seguros",
    "técnico em tecnologia da informação",
    "técnico em telecomunicações",
    "técnico em turismo",
    "técnico em áudio e vídeo",
    "técnico em óptica",
    "técnico financeiro",
    "técnico florestal",
    "técnico hidráulico",
    "técnico industrial",
    "técnico mecânico de manutenção",
    "técnico operacional",
    "técnico orçamentista",
    "técnico projetista",
    "técnico têxtil",
    "vendedor de consórcio",
    "vendedor de e-commerce",
    "vendedor de franquias",
    "vendedor de frete",
    "vendedor de informática",
    "vendedor de loja",
    "vendedor de máquinas",
    "vendedor de seguros",
    "vendedor de telecomunicações",
    "vendedor de veículos",
    "vendedor externo",
    "vendedor industrial",
    "vendedor projetista",
    "vendedor técnico",
    "vendedor",
    "vendendor",
    "veterinário patologista",
    "veterinário",
    "videografista",
    "vidraceiro",
    "vigia",
    "vigilante de escolta",
    "vigilante líder",
    "vigilante",
    "visitador de navios",
    "vistoriador de imóveis",
    "vistoriador de veículos",
    "visual merchandiser",
    "vitrinista",
    "web designer trainee",
    "web designer",
    "web developer",
    "webdesign",
    "webmaster",
    "zelador",
    "zootecnista"
]

