import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AutenticacaoEndpointService } from '../autenticacao-endpoint.service';
import { CustomErrorHandler } from '../exceptions/error-handler.interceptor';
import swal from 'sweetalert2';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AcessoRootService
  implements CanActivate, CanActivateChild, OnDestroy {
  constructor(
    private autenticacaoService: AutenticacaoEndpointService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.autenticacaoService.me().toPromise()
      .then(response => {
        let logado = false;

        if (response) {
          if (response.name) {
            logado = true;
          }
        }

        if (!logado) {
          this.autenticacaoService.sair(false, state.url).pipe(untilDestroyed(this)).subscribe();
          return false;
        }

        let ehAdmin = false;
        let ehColaborador = false;

        if (response.authorities) {
          ehAdmin =
            response.principal.authorities.filter(
              item =>
                item.permissao === 'ROLE_ADMINISTRADOR' &&
                item.authority === 'ROLE_ADMINISTRADOR'
            ).length > 0;
          ehColaborador =
            response.principal.authorities.filter(
              item =>
                item.permissao === 'ROLE_COLABORADOR' &&
                item.authority === 'ROLE_COLABORADOR'
            ).length > 0;
          if (ehAdmin || ehColaborador) {
            return true;
          }
        }

        return false;
      })
      .catch(err => {
        this.customErrorHandler.handleError(err);
        return this.autenticacaoService.sair(false, state.url).toPromise().then(() => false);
      })
      .then((retorno: boolean) => {
        if (!retorno) {
          this.matDialog.closeAll();
          return swal
            .fire({
              title: 'Acesso não autorizado',
              text:
                'Entre como administrador para acessar a página solicitada.',
              icon: 'warning',
              confirmButtonText: 'OK'
            })
            .then(() => retorno);
        }
        return retorno;
      });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.canActivate(route, state);
  }

  ngOnDestroy() { }
}
