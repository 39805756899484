import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmpresaDTO } from '@project/src/app/model/pessoa/empresa-dto.model';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';

@Component({
  selector: 'app-limite-pix',
  templateUrl: './limite-pix.component.html',
  styleUrls: ['./limite-pix.component.less']
})
export class LimitePixComponent implements OnInit {

  limiteDiurno: number = 0;
  limiteNoturno: number = 0;
  limiteDiario: number = 0;

  @Input() public form: FormGroup;

  @Input()
  empresaId: number;

  @Input() public data: any;


  empresa: EmpresaDTO
  configuracaoEmpresa;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private pixService: PixEndpointService
  ) {
    this.data = this.data || {};

    this.empresa = this.autenticacao.regraEmpresa.empresa;
    this.configuracaoEmpresa = this.empresa.configuracao;
    this.limiteDiurno = this.configuracaoEmpresa.valorTransferenciaDiurnoPix;
    this.limiteNoturno = this.configuracaoEmpresa.valorTransferenciaNoturnoPix;
    this.limiteDiario = this.configuracaoEmpresa.valorLimiteDiarioPix;

  }

  ngOnInit(): void {
  }


  salvarLimites(param) {
    this.pixService.limitesPix(param)
      .subscribe((res) => {
        console.log(res)
      });
  }


  limiteEvent($event, tipo) {
    if (tipo == 'diurno') {
      this.limiteDiurno = $event.value;
      this.salvarLimites({ valorTransferenciaDiurnoPix: this.limiteDiurno })

    } else if (tipo == 'noturno') {
      this.limiteNoturno = $event.value;
      this.salvarLimites({ valorTransferenciaNoturnoPix: this.limiteNoturno })

    } else if (tipo == 'diario') {
      this.limiteDiario = $event.value;
      this.salvarLimites({ valorLimiteDiarioPix: this.limiteDiario })
    }
  }

}
