import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import moment from 'moment';
import { DateBarModel } from './date-bar.model';

@Component({
  selector: 'app-date-bar',
  templateUrl: './date-bar.component.html',
  styleUrls: ['./date-bar.component.less']
})
export class DateBarComponent implements OnInit {
  @Input()
  appearance = 'outline';

  @Input()
  value: DateBarModel;

  @Output()
  change = new EventEmitter<DateBarModel>();

  meses = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ];

  constructor() { }

  ngOnInit() {
    this.value = this.value || {};
    this.value.de = this.value.de || moment().startOf('month').toDate();
    this.value.ate = this.value.ate || moment().endOf('month').toDate();
  }

  onToggleGroupChange(event: MatButtonToggleChange) {
    const { value } = event;
    if (value < 0) {
      this.value.de = moment(this.value.de).subtract(1, 'year').startOf('month').toDate();
    } else if (value > this.meses.length) {
      this.value.de = moment(this.value.de).add(1, 'year').startOf('month').toDate();
    } else {
      this.value.de = moment(this.value.de).set('month', value).startOf('month').toDate();
    }
    this.value.ate = moment(this.value.de).endOf('month').toDate();
    this.change.emit(this.value);
  }
}
