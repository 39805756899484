<p class="super-title">Informações financeiras</p>

<div class="box-body" [formGroup]="form">
    <div class="col-12" style="text-align: center;">
        <p>Preencha os dados abaixo suas informações financeiras
            <i class="fas fa-info-circle info-icon"
                matTooltip="Ao compartilhar esses detalhes conosco, você nos ajuda a proporcionar as melhores ofertas personalizadas para atender às suas necessidades exclusivas"></i>
        </p>
        <p>Essas informações não são obrigatórias, você pode prosseguir normalmente.</p>
    </div>
    <br>
    <div class="row row-inputs">
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Renda mensal</mat-label>
                <input class="valor-transferencia" matInput currencyMask type="text"
                    onclick="this.setSelectionRange(-1,-1);" onfocus="this.setSelectionRange(-1,-1);"
                    formControlName="rendaMensal" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            </mat-form-field>
        </div>
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Aplicações financeiras</mat-label>
                <input class="valor-transferencia" matInput currencyMask type="text"
                    onclick="this.setSelectionRange(-1,-1);" onfocus="this.setSelectionRange(-1,-1);"
                    formControlName="aplicacoesFinanceiras"
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            </mat-form-field>
        </div>
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Bens móveis</mat-label>
                <input class="valor-transferencia" matInput currencyMask type="text"
                    onclick="this.setSelectionRange(-1,-1);" onfocus="this.setSelectionRange(-1,-1);"
                    formControlName="bensMoveis" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            </mat-form-field>
        </div>
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Bens imóveis</mat-label>
                <input class="valor-transferencia" matInput currencyMask type="text"
                    onclick="this.setSelectionRange(-1,-1);" onfocus="this.setSelectionRange(-1,-1);"
                    formControlName="bensImoveis" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            </mat-form-field>
        </div>

        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Outros rendimentos</mat-label>
                <input class="valor-transferencia" matInput currencyMask type="text"
                    onclick="this.setSelectionRange(-1,-1);" onfocus="this.setSelectionRange(-1,-1);"
                    formControlName="outrosRendimentos" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            </mat-form-field>
        </div>
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Ocupação</mat-label>
                <input type="text" matInput formControlName="ocupacao" [matAutocomplete]="auto"
                    class="valor-transferencia">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="capitalizeFirstLetter(option)">
                        {{ capitalizeFirstLetter(option) }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="box-body" style="margin-top: 20px" [formGroup]="form">
    <div class="col-12">
        <p style="text-align: center;">Possuí convênio?
            <i class="fas fa-info-circle info-icon"
                matTooltip="O código de convênio é um vínculo especial oferecido por empresas para acesso a ofertas exclusivas de produtos."></i>
        </p>
        <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Digite o código</mat-label>
                <input matInput type="text" formControlName="codigoConvenio" />
            </mat-form-field>
        </div>
    </div>
</div>