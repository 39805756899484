import { AutenticacaoEndpointService } from '../../../../../service/autenticacao-endpoint.service';
import { Component, Input, OnInit } from '@angular/core';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { environment } from '@src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})
export class IntegracaoComponent implements OnInit {
  token: string;
  env = environment;
  empresaId: any;
  temToken = false;
  @Input()
  public data: any;

  constructor(private autenticacao: AutenticacaoEndpointService,
    private usuarioService: UsuarioEndpointService,
    ) { }

  ngOnInit() {
    const userData = this.autenticacao.sessao;
    this.empresaId = this.autenticacao.regraEmpresa.empresa.id;
    if(userData.principal.usuario.email.includes("@root")){
      this.token = "Usuário Root"
    }else{
    this.token = `${userData.principal.usuario.apiToken}:${this.data.id}`;
  }}


  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  abrirAPI() {
    window.open(this.env.url.readme, '_blank');
  }
  pedirToken(){
    this.usuarioService.getApiToken(this.autenticacao.sessao.principal.usuario.id, this.empresaId).pipe().subscribe(
      (response) => {
        response
        Swal.fire('Sucesso', 'Token enviado para ' + response , 'success')
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error,
        })
        console.error(error);
      }
    );;
  }
}
