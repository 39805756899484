import {
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

@Directive({
    selector: '[appInteger]',
})
export class IntegerDirective {

    private run() {
        setTimeout(() => {
            this.el.nativeElement.value = (this.el.nativeElement.value || '').replace(/[^0-9]/gim, '');
        });
    }

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.run();
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        this.run();
    }
}
