import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { IconsModule } from '@app/icons.module';
import { SelecionarCorporacaoComponent } from '@project/src/app/pages/interno/corporacao/selecionar-corporacao/selecionar-corporacao.component';
import { SelecionarCorporacaoModule } from '@project/src/app/pages/interno/corporacao/selecionar-corporacao/selecionar-corporacao.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    RouterModule,
    CommonModule,
    IconsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTooltipModule,
    IconsModule,
    SelecionarCorporacaoModule
  ],
  exports: [SidebarComponent],
})
export class SidebarModule { }
