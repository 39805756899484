import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class CodigoConvenioEndpointService {
    constructor(private client: HttpClient) { }


    cadastrarCodigoConvenio(codigo: string) {
        const url = '/codigo-convenio/cadastrar?codigo=' + codigo;
        return this.client.post(url, { codigo });
    }

    vincularCodigoConvenio(idEmpresa: number, codigo: string) {
        const url = `/codigo-convenio/vincular?idEmpresa=${idEmpresa}&codigo=${codigo}`;
        return this.client.post(url, { idEmpresa, codigo });
    }

    listarCodigos() {
        const url = '/codigo-convenio/listar';
        return this.client.get(url);
    }

    listarCodigoPaginado(page: number, size: number, order?: string, query?: string): Observable<any> {
        let params = new HttpParams();

        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);
        order ? params = params.append('order', `${order}`) : null;
        query ? params = params.append('query', `${query}`) : null;

        return this.client.get('/codigo-convenio/listar-paginado', { params });
    }

    desvincularCodigo(idEmpresa, codigo) {
        const url = `/codigo-convenio/desvincular?idEmpresa=${idEmpresa}&codigo=${codigo}`;
        return this.client.post(url, { idEmpresa, codigo });
    }
}