<p class="super-title"> {{ tipoConta === 'pf' ? 'Suas informações' : 'Informações da empresa' }}</p>

<div class="box-body" [formGroup]="form">
  <div class="col-12">
    <p style="text-align: center;">Preencha os dados abaixo para realizar o
      {{ tipoConta === 'pf' ? 'seu cadastro' : 'cadastro da sua empresa' }} : </p>
  </div>
  <app-geral [tipoConta]="tipoConta" tipoCadastro="primeiros-passos" (dadosDoCnpjCpfAmazon)="atualizaTudo($event)"
    [form]="form.get('geral')">
  </app-geral>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px">
  <div class="col-12">
    <p style="text-align: center;">Qual o {{ tipoConta === 'pf' ? 'seu endereço' : 'endereço do seu negócio' }} ? </p>
  </div>
  <app-endereco [form]="form.get('enderecos')"></app-endereco>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px">
  <div class="col-12">
    <p style="text-align: center;">Qual o {{ tipoConta === 'pf' ? 'seu telefone' : 'telefone do seu negócio' }} ? </p>
  </div>
  <app-telefone [form]="form.get('telefones')"></app-telefone>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px">
  <div class="col-12">
    <p style="text-align: center;">Qual o {{ tipoConta === 'pf' ? 'seu e-mail' : 'e-mail do seu negócio' }} ? </p>
  </div>
  <app-email [form]="form.get('emails')"></app-email>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px">
  <div class="col-12">
    <p style="text-align: center;">Qual o {{ tipoConta === 'pf' ? 'seu e-mail de notificação' : 'e-mail de notificação
      do seu negócio' }} ? <span> <i class="fas fa-info-circle info-icon"
          matTooltip="O preenchimento desse email é obrigatório, uma vez que você receberá seus tokens e notificações através dele."></i></span>
    </p>
  </div>
  <app-email-notificacao [form]="form.get('emailNotificacao')"></app-email-notificacao>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px">
  <div class="col-12">
    <p style="text-align: center;">Representantes Legais </p>
  </div>
  <app-representante-legal [form]="form.get('representantes')" [data]="representantesData"></app-representante-legal>
</div>