<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        DEAL SIGN
        <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    </h2>
    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
            <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="input-size column">
                <div class="card-body">
                    <div class="row">
                        <label for="url">Url</label>
                        <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                            matTooltip="Editável apenas pelo time de desenvolvimento"></i>
                    </div>
                    <input type="text" class="form-control" id="url" placeholder="url" [(ngModel)]="url" required
                        disabled>
                    <hr>

                    <div class="row">
                        <label for="email">E-mail</label>
                        <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                            matTooltip="E-mail utilizado para se logar na plataforma do Deal Sign."></i>
                    </div>
                    <input type="text" class="form-control" id="email" placeholder="Email" [(ngModel)]="email" required>

                    <hr>

                    <div class="row">
                        <label for="token-uuid">Token Uuid</label>
                        <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                            matTooltip="Dentro da plataforma do deal Sign, acesse 'Minha Empresa', vá para aba 'API'. Gere um novo ou copie o 'Token' em 'Access Token'."></i>
                    </div>
                    <input type="text" class="form-control" id="token-uuid" placeholder="Token Uuid"
                        [(ngModel)]="tokenUuid" required>

                    <hr>
                    
                    <div class="row">
                        <label for="folder-uuid">Pasta Uuid</label>
                        <i class="fas fa-info-circle info-icon" style="font-size: 8px;"
                            matTooltip="Uuid de uma pasta criada em modelos/Documentos"></i>
                    </div>
                    <input type="text" class="form-control" id="folder-uuid" placeholder="Pasta Uuid" [(ngModel)]="folderUuid" required>

                    <hr>
                </div>
                <div class="row">
                    <div mat-dialog-actions
                        style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px; margin: auto;">
                        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
                        margin: 0;">
                            <button (click)="testar()" class="btn btn-default next">Testar</button>
                        </span>
                    </div>
                    <div mat-dialog-actions
                        style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px; margin: auto;">
                        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
                        margin: 0;">
                            <button (click)="salvar()" class="btn btn-default next fundoCorPrimaria">Salvar</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>