import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UsuarioDTO } from "../model/usuario/usuario-dto.model";
import { EmpresaDTO } from "../model/pessoa/empresa-dto.model";
import { CorporacaoDTO } from "../model/pessoa/corporacao-dto.model";
import { GenericDTO } from "../model/generic-dto.model";

interface TokenAtendimentoDTO extends GenericDTO {
    token?: string,
    usuarioId?: number,
    empresaId?: number,
    corporacaoId?: number,
    usuario?: UsuarioDTO,
    empresa?: EmpresaDTO,
    corporacao?: CorporacaoDTO
}

@Injectable({
    providedIn: "root",
  })
export class TokenAtendimentoEndpointService {
    constructor(private client: HttpClient) { }

    novoTokenAtendimento(tokenAtendimentoData: TokenAtendimentoDTO){
        const url = "/token-atendimento/novo";
        return this.client.post<string>(url, tokenAtendimentoData);
    }

    recuperarInformacoes(tokenAtendimento: string){
        const url = "/token-atendimento/recuperar-informacoes";
        let params = new HttpParams();
        params = params.append("tokenAtendimento", `${tokenAtendimento}`);
        return this.client.get<TokenAtendimentoDTO>(url, { params });
    }

    getToken(usuarioId: number, empresaId: number){
        const url = "/token-atendimento/get-token";
        let params = new HttpParams();
        params = params.append("usuarioId", `${usuarioId}`);
        params = params.append("empresaId", `${empresaId}`);
        return this.client.get<TokenAtendimentoDTO>(url, { params });
    }
}