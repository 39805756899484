import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ArquivoEmpresaDTO, formArquivoEmpresaDTO } from '@model/pessoa/arquivo-empresa-dto.model';
import { EmpresaDTO } from '@model/pessoa/empresa-dto.model';
import { CustomFormValidatorService } from '@project/src/app/service/util/custom-form-validator.service';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-documentacao',
	templateUrl: './documentacao.component.html',
	styleUrls: ['./documentacao.component.less']
})
export class DocumentacaoComponent implements OnInit, OnDestroy {
	subscription: Subscription;
	public arquivoEmpresa: any = [];
	endereco = '';

	@Input()
	public form: FormGroup;
	@Input()
	inputEmpresa = '';
	@Input()
	tipoConta: string;
	@Input()
	inputEndereco = [];

	constructor(
		private autenticacao: AutenticacaoEndpointService
	) { }

	ngOnInit() {
		if (!this.documento) {
			this.form.addControl('documento', new FormGroup({}));
		}
		this.documento.addControl('comprovanteEmpresa', new FormGroup({
			preenchido: new FormControl(null, CustomFormValidatorService.requiredIf((() => this.tipoConta === 'pj')).bind(this)),
			arquivos: new FormArray([])
		}));
		this.documento.addControl('comprovanteDocumento', new FormGroup({
			preenchido: new FormControl(null, CustomFormValidatorService.requiredIf((() => this.tipoConta === 'pf')).bind(this)),
			arquivos: new FormArray([])
		}));
		this.documento.addControl('comprovanteEndereco', new FormGroup({
			preenchido: new FormControl(null, CustomFormValidatorService.requiredIf((() => this.tipoConta === 'pf')).bind(this)),
			arquivos: new FormArray([])
		}));

		if (this.inputEndereco) {
			this.getEnderecoPrincipal(this.inputEndereco);
		} else {
			this.montaEndereco(this.autenticacao.sessao.principal.usuario.endereco);
		}
	}

	get documento() {
		return this.form.get('documento') as FormGroup;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.inputEndereco) {
			for (const element of this.inputEndereco) {
				if (element.principal === true) {
					this.montaEndereco(element);
				}
			}
		} else {
			this.montaEndereco(this.autenticacao.sessao.principal.usuario.endereco);
		}
	}

	getEnderecoPrincipal(enderecos) {
		for (const element of enderecos) {
			if (element.principal === true) {
				this.montaEndereco(element);
			}
		}
	}

	montaEndereco(element) {
		this.endereco = element.logradouro + ' - ' + element.numero + ' - ' + element.bairro +
			' - ' + element.cidade + '/' + element.estado;
	}

	ngOnDestroy() { }

	upload(tipo: string, arquivos: ArquivoEmpresaDTO[]) {
		const value = arquivos && arquivos.length ? true : null;
		this.documento.get(tipo).get('preenchido').setValue(value);
		const formArquivos = this.documento.get(tipo).get('arquivos') as FormArray;
		formArquivos.clear();
		for (const arquivo of arquivos) {
			const formArquivo = formArquivoEmpresaDTO();
			formArquivo.patchValue(arquivo);
			formArquivos.push(formArquivo);
		}
		const arquivoEmpresa = (this.form.get('arquivoEmpresa') as FormArray);
		if (arquivoEmpresa) {
			arquivoEmpresa.clear();
			const comprovanteEmpresa = this.documento.get('comprovanteEmpresa').get('arquivos').value || [];
			for (const item of comprovanteEmpresa) {
				arquivoEmpresa.push(item);
			}
			const comprovanteDocumento = this.documento.get('comprovanteDocumento').get('arquivos').value || [];
			for (const item of comprovanteDocumento) {
				arquivoEmpresa.push(item);
			}
			const comprovanteEndereco = this.documento.get('comprovanteEndereco').get('arquivos').value || [];
			for (const item of comprovanteEndereco) {
				arquivoEmpresa.push(item);
			}
		}
	}

	build(empresa: EmpresaDTO) {
		const arquivoEmpresa = empresa.arquivoEmpresa = [];

		const comprovanteEmpresa = this.documento.get('comprovanteEmpresa').get('arquivos').value;
		for (const item of comprovanteEmpresa) {
			arquivoEmpresa.push(item);
		}
		const comprovanteDocumento = this.documento.get('comprovanteDocumento').get('arquivos').value;
		for (const item of comprovanteDocumento) {
			arquivoEmpresa.push(item);
		}
		const comprovanteEndereco = this.documento.get('comprovanteEndereco').get('arquivos').value;
		for (const item of comprovanteEndereco) {
			arquivoEmpresa.push(item);
		}
	}

	getErrorMessage(form: FormGroup, field) {
		if (form.get(field)) {
			return form.get(field).hasError('required')
				? 'Campo requerido'
				: form.get(field).hasError('obrigatorio')
					? 'Campo requerido'
					: '';
		}
	}

}
