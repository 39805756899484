import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import Swal from 'sweetalert2';
import { TokenComponent } from '../../token/token.component';

@Component({
  selector: 'app-modal-confirma-token',
  templateUrl: './modal-confirma-token.component.html',
  styleUrls: ['./modal-confirma-token.component.less']
})
export class ModalConfirmaTokenComponent implements OnInit, OnDestroy {

  public processando: boolean = false;
  public form: FormGroup;

  env = environment;

  @ViewChild(TokenComponent, { static: false }) tokenComponent: TokenComponent;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmaTokenComponent>,
    private usuarioEndpointService: UsuarioEndpointService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ngOnInit(): any {
    this.form = new FormGroup({});
    this.processando = false;
  }

  public ngOnDestroy(): any { }

  public validaToken() {
    const token = {
      token: this.tokenComponent.getToken(),
      estrutura: this.data.estrutura,
      tipo: this.form.get("isSMS").value ? "sms" : this.form.get("isEmail").value ? "email" : "mfa"
    }
    if (this.data.onSave) {
      return this.data.onSave(token);
    }
    this.usuarioEndpointService.validaTokenGenerico(token).subscribe(
      response => {
        Swal.fire({
          title: "Sucesso!",
          text: this.data.mensagemSucesso ?? "Seu token foi validado!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          this.dialogRef.close({
            validado: true
          });
        })
      },
      error => {
        Swal.fire({
          title: "Atenção!",
          text: error.error,
          icon: "warning",
        })
      }
    );
  }

  public fecha() {
    this.dialogRef.close()
  }

  public valido() {
    return this.form.get('token').valid;
  }
}
