<form [formGroup]="form">

    <div class="row alinha" *ngFor="let email of form.controls; index as i" [formGroup]="email">
        <div class="col-11">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" />
            </mat-form-field>
        </div>
        <div class="col-1 add">
            <span (click)="del(i)"><i class="fas fa-trash" style="color: red;font-size: 16px;"></i></span>
        </div>
    </div>
    <div class="row alinha">
        <div class="col-10 col-md-10 add" *ngIf="!pix">
            <span (click)="add()">+ adicionar novo e-mail</span>
        </div>
    </div>
</form>