import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableControlDirective } from './disable-control.directive';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { VarDirective } from './var.directive';
import { IntegerDirective } from './integer.directive';
import { DragAndDropDirective } from './drag-and-drop.directive';

@NgModule({
  declarations: [
    DisableControlDirective,
    ScrollSpyDirective,
    VarDirective,
    IntegerDirective,
    DragAndDropDirective
  ],
  imports: [CommonModule],
  exports: [
    DisableControlDirective,
    ScrollSpyDirective,
    VarDirective,
    IntegerDirective,
    DragAndDropDirective
  ]
})
export class DirectiveModule { }
