import { Component } from '@angular/core';
import { version } from '@project/package.json';
import moment from 'moment';
import { environment } from '@src/environments/environment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';

@Component({
	selector: 'app-footer-cmp',
	templateUrl: 'footer.component.html'
})
export class FooterComponent {
	versao = version;
	ano = moment().year();
	env = environment;
	urlLogo = null;

	constructor(
		private rootService: RootEndpointService,
	) {
		this.getURL();
	}


	getURL() {
		this.rootService.getUrl().pipe()
			.subscribe(
				response => {
					if (response[0] != '') this.urlLogo = response[0];

				},
				error => {
					Swal.fire({
						title: 'Atenção',
						text: error.error,
						icon: 'warning',
						confirmButtonText: 'OK'
					});
				}
			);
	}
}
