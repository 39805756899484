import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@app/icons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { SensibilizacaoComponent } from './sensibilizacao.component';
import { TransferenciaSensibilizacaoComponent } from './transferencia-sensibilizacao/transferencia-sensibilizacao.component';
import { TaxaSensibilizacaoComponent } from './taxa-sensibilizacao/taxa-sensibilizacao.component';
import { ValidacoesService } from '../../service/validacoes.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

export enum saidaTaxa {
  saidaTaxaSelic = "CUSTO SELIC"
}

export enum entradaTaxa {
  recebimentoSelic = "RECEBIMENTO DE JUROS – SELIC"
}

@NgModule({
  declarations: [
    SensibilizacaoComponent,
    TransferenciaSensibilizacaoComponent,
    TaxaSensibilizacaoComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule,
    MatDialogModule,
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  exports: [
    SensibilizacaoComponent,
  ],
  providers: [
    ValidacoesService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ],
})
export class SensibilizacaoModule { }
