import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicidadeEndpointService } from '@project/src/app/service/publicidade-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-imagem',
  templateUrl: './editar-imagem.component.html',
  styleUrls: ['./editar-imagem.component.less']
})
export class EditarImagemComponent {

  constructor(
    public publicidadeService: PublicidadeEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditarImagemComponent>,
  ) { }

  atualizaHref(event) {
    this.data.href = event.target.value;
  }

  deletarPubli() {
    this.publicidadeService.deletarPublicidade(this.data.id).subscribe((res) => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Publicidade deletada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        this.dialogRef.close();
      })
    }, error => {
      console.error(error);
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
    })
  }

  atualizarPubli() {
    this.publicidadeService.atualizarPublicidade(this.data).subscribe((res) => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Publicidade atualizada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        this.dialogRef.close();
      })
    }, error => {
      console.error(error);
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
    })
  }

  fecha() {
    this.dialogRef.close();
  }

}
