import { PERMISSOES_PERSONALIZACAO_SISTEMA, PERMISSOES_ROOT } from "../../pages/interno/root/usuarios/permissoes.service";

export class CheckAuthoritiesService {
  userData: any;
  constructor(
    userData: any
  ) {
    this.userData = userData;
  }
  get isRoot() {
    if (this.userData?.name === "root@root") {
      return true
    } else {
      return false;
    }
  }

  get isColaborador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_COLABORADOR" &&
        item.authority === "ROLE_COLABORADOR"
    ).length > 0;
  }

  get isAdministrador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_ADMINISTRADOR" &&
        item.authority === "ROLE_ADMINISTRADOR"
    ).length > 0;
  }

  get isProprietario() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_PROPRIETARIO" &&
        item.authority === "ROLE_PROPRIETARIO"
    ).length > 0;
  }

  get isOperador() {
    return this.userData?.principal.authorities.filter(
      (item) =>
        item.permissao === "ROLE_OPERADOR" &&
        item.authority === "ROLE_OPERADOR"
    ).length > 0;
  }

  permissaoGuard(role: string, empresaId?: number): boolean {
    if (this.isAdministrador || this.isProprietario || this.isRoot) {
      return true;
    }
    if(this.isOperador && empresaId){
      return this.userData?.authorities.filter(
        (item) => item.permissao === role && item.authority === role && item.empresaId === empresaId).length > 0
    }else{
      return this.userData?.authorities.filter(
        (item) => item.permissao === role && item.authority === role).length > 0
    }
  }

  permissaoToken(): boolean {
    if (
      this.userData.authorities.filter(
        (item) =>
          item.permissao === 'ROLE_TOKEN_EMAIL' &&
          item.authority === 'ROLE_TOKEN_EMAIL'
      ).length > 0 ||
      this.userData.authorities.filter(
        (item) =>
          item.permissao === 'ROLE_EMPRESA_RECEBER_TOKEN' &&
          item.authority === 'ROLE_EMPRESA_RECEBER_TOKEN'
      ).length > 0
    ) {
      return true;
    } else return false;
  }

  possuiPermissaoRoot() {
    const roles = [
      ...PERMISSOES_ROOT,
      ...PERMISSOES_PERSONALIZACAO_SISTEMA,
      "ROLE_MODERAR_COLABORADOR_ROOT"
    ];

    let permitido = false;
    this.userData.authorities.forEach((regra) => {
      if (roles.includes(regra.permissao)) permitido = true;
    });
    return permitido;
  }
} 