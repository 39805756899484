import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StatusExtrato } from '../model/root/status-extrato.enum';

@Injectable({
  providedIn: 'root'
})
export class ExtratoEndpointService {

  constructor(private client: HttpClient) { }

  getExtrato(dataInicial, dataFinal, q?): Observable<any> {

    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    if (q) {
      params = params.append('q', q);
    }

    return this.client.get('/extrato', { params });
  }

  getExportar(dataInicial, dataFinal, formato, consolidado?: boolean): Observable<any> {

    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }

    if (formato) {
      params = params.append('ext', formato);
    }

    if(consolidado) params = params.append('consolidado', 'true')

    return this.client.get('/extrato/exportar', { params });
  }

  getInformeRendimentos(ano): Observable<any> {

    let params = new HttpParams();

    if (ano) {
      params = params.append('ano', ano);
    }

    return this.client.get('/extrato/rendimento-anual', { params });
  }

  getExportarInvestidor(dataInicial, documentoUsuario?): Observable<any> {

    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('dataInicial', dataInicial);
    }
    if (documentoUsuario) {
      params = params.append('documentoUsuario', documentoUsuario);
    }

    return this.client.get('/extrato/extrato-investidor', { params });
  }

  getExportarInvestidorOnline(documentoUsuario, dataRef?): Observable<any> {

    let params = new HttpParams();

    if (dataRef) {
      params = params.append('data', dataRef);
    }
    if (documentoUsuario) {
      params = params.append('documentoUsuario', documentoUsuario);
    }

    return this.client.get('/extrato/extrato-investidor-online', { params });
  }

  getInformeRendimento(ano, documento?): Observable<any> {
    let params = new HttpParams();

    if (ano) {
      params = params.append('ano', ano);
    }
    if (documento) {
      params = params.append('documento', documento);
    }

    return this.client.get('/extrato/informe-rendimento', { params });
  }

  getExtratoReceitasFiltro(dataInicial, dataFinal, isBoleto, isPagamento, isCartao, isTransferencia, q?): Observable<any> {

    let params = new HttpParams();

    if (dataInicial) {
      params = params.append('i', dataInicial);
    }
    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    if (isBoleto) {
      params = params.append('isBoleto', isBoleto);
    }
    if (isCartao) {
      params = params.append('isCartao', isCartao);
    }
    if (isPagamento) {
      params = params.append('isPagamento', isPagamento);
    }
    if (isTransferencia) {
      params = params.append('isTransferencia', isTransferencia);
    }
    if (q) {
      params = params.append('q', q);
    }

    return this.client.get('/extrato/receitas-filtros', { params });
  }



  getCartaCircularizacao(dataFinal): Observable<any> {
    let params = new HttpParams();


    if (dataFinal) {
      params = params.append('f', dataFinal);
    }
    
    return this.client.get('/extrato/carta-circularizacao', { params });
  }


  enviarCartaCircularizacaoEmail(base64, emails): Observable<any> {

    let params = new HttpParams();

    emails.forEach(email => {
      if (email === null || email.email === null || email.email === "" || email.email.trim() === "") {
        throw new Error("Preencha o email");
      }
    });


    var body = {
      base64: base64,
      emails: emails
    }

    return this.client.post('/extrato/carta-circularizacao-email', body);

  }

  listarExtratosEmDebito(): Observable<any> {
    return this.client.get('/extrato/em-debito');
  }

  getExtratoByStatus(status: StatusExtrato, empresaId): Observable<any> {
    let params = new HttpParams();
    params = params.append("empresaId", empresaId);

    return this.client.get('/extrato/status/' + status, { params })
  }

  desativarExtrato(id: string){
    return this.client.patch(`/root/extrato/${id}`, null);
  }

  contaTemMovimentacao(empresaId){
    return this.client.get<boolean>(`/root/extrato/conta-tem-movimentacao/${empresaId}`);
  }

}
