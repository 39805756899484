import { OperadoresEditComponent } from './../../../interno/empresa/operadores/operadores-edit/operadores-edit.component';
import { AutenticacaoSecundariaComponent } from '../../../../components/autenticacao-secundaria/autenticacao-secundaria.component';
import { ExportarConciliacaoComponent } from '@components/root/exportar-conciliacao/exportar-conciliacao.component';
import { RootComponent } from '@components/root/root.component';
import { SensibilizacaoComponent } from '@components/sensibilizacao/sensibilizacao.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgPipesModule } from 'ngx-pipes';
import { EmitirCobrancaModule } from '@project/src/app/pages/interno/cobranca/emitir-cobranca/emitir-cobranca.module';
import { UsuarioEditarModule } from '@pages/interno/usuario/usuario-editar/usuario-editar.module';
import { LiquidarArquivoModule } from '@project/src/app/pages/interno/cobranca/liquidar-arquivo/liquidar-arquivo.module';
import { PrimeirosPassosModule } from '@components/primeiros-passos/primeiros-passos.module';
import { FalarComGerenteModule } from '@components/falar-com-gerente/falar-com-gerente.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateFnsModule } from 'ngx-date-fns';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { ToastrModule } from 'ngx-toastr';
import { RootConfigModule } from '@project/src/app/components/root/root.module';
import { LocalizacaoModule } from '@project/src/app/components/localizacao/localizacao.module';
import { SensibilizacaoModule } from '@project/src/app/components/sensibilizacao/sensibilizacao.module';
import { TransferenciaLoteZeragemModule } from '../../../interno/root/transferencia-lote-zeragem/transferencia-lote-zeragem.module';


@NgModule({
  imports: [
    MatDatepickerModule,
    DateFnsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    RouterModule,
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    EmitirCobrancaModule,
    UsuarioEditarModule,
    LiquidarArquivoModule,
    NgPipesModule,
    PrimeirosPassosModule,
    FalarComGerenteModule,
    ReactiveFormsModule,
    FormsModule,
    RootConfigModule,
    SensibilizacaoModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(),
    MatMenuModule,
    MatIconModule,
    LocalizacaoModule,
    TransferenciaLoteZeragemModule
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule { }
