import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { RootEndpointService } from '@service/root-endpoint.service';

@Component({
  selector: 'app-liquidar-boletos',
  templateUrl: './liquidar-arquivo.component.html',
  styleUrls: ['./liquidar-arquivo.component.less']
})
export class LiquidarArquivoComponent implements OnInit {
  form: FormGroup;
  processando = false;
  arquivo = null;

  constructor(
    public dialogRef: MatDialogRef<LiquidarArquivoComponent>,
    private rootService: RootEndpointService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      arquivoPath: new FormControl(''),
      arquivo: new FormControl(null)
    });
  }

  fechar(): void {
    this.dialogRef.close();
  }

  uploadNull() {
    this.arquivo = null;
  }

  onSelectFile(event) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.type.includes('text/plain')) {
        const reader = new FileReader();
        const tamanhoArquivo = event.target.files[0].size;

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event: any) => {
          // called once readAsDataURL is completed
          const base64 = event.target.result.substring(
            event.target.result.lastIndexOf(',') + 1,
            event.target.result.length
          );

          const arquivoPath = this.form.get('arquivoPath').value;
          const arquivooNome = arquivoPath.substring(
            arquivoPath.lastIndexOf('h\\') + 2,
            arquivoPath.length
          );
          const arquivoData = {
            locale: 'UTF-8',
            base64: base64,
            mimeType: file.type,
            nome: arquivooNome,
            tamanho: String(tamanhoArquivo)
          };

          this.arquivo = event.target.result; // substitui o src pelo novo arquivo
          this.form.get('arquivo').setValue(arquivoData);
        };
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Entre com um arquivo válido',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
      }
    }
  }

  enviar() {
    this.processando = true;
    if (this.dataDialog.tipo === 'boleto') {
      this.rootService.liquidarBoleto(this.form.get('arquivo').value).subscribe(
        response => {
          this.processando = false;
          this.swal('Sucesso', response, 'success');
        },
        error => {
          this.processando = false;
          this.swal('Atenção', error.error, 'warning');
        }
      );
    } else if (this.dataDialog.tipo === 'transferencia') {
      this.rootService
        .liquidarTransferencia(this.form.get('arquivo').value)
        .subscribe(
          response => {
            this.processando = false;
            this.swal('Sucesso', response, 'success');
          },
          error => {
            this.processando = false;
            this.swal('Atenção', error.error, 'warning');
          }
        );
    }
  }

  swal(title, text, icon) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK'
    });
  }
}
