<div class="box-body" [formGroup]="form" style="height: 100%;">
  <p class="super-title">Como você quer cobrar seus clientes?</p>
  <p *ngIf="bloqueiaFuncionalidades" style="text-align: center;font-size: 12px;
  ">Para acessar as funcionalidades bloqueadas você precisa
    ter um beneficiário
    cadastrado.</p>
  <p *ngIf="listaChavesPix.length === 0 && areaPix && env?.modulos?.pix?.gerarQrCode" style="text-align: center;font-size: 12px;
  ">Para gerar um QR Code você precisa ter uma chave
    cadastrada.</p>
  <div class="row" style="justify-content: space-evenly;">
    <div class="col-3 col-md-2 tipo-box"
      [ngClass]="{'tipo-box-selecionado': form.get('tipoCobranca').value == 1, 'disabled-div': bloqueiaFuncionalidades}"
      (click)="!bloqueiaFuncionalidades && setTipoCobranca(1)" *ngIf="env?.modulos?.cobranca?.avulsa"
      [disabled]="bloqueiaFuncionalidades">
      <button class="btn-close" [hidden]="form.get('tipoCobranca').value !== 1">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">AVULSA</span>
      <br />
      <i class="far fa-file"></i>
      <br />
      <span>Cobrança única, para pagamentos no boleto ou cartão de
        crédito</span>
    </div>
    <div class="col-3 col-md-2 tipo-box"
      [ngClass]="{'tipo-box-selecionado': form.get('tipoCobranca').value == 2, 'disabled-div': bloqueiaFuncionalidades}"
      (click)="!bloqueiaFuncionalidades && setTipoCobranca(2)" *ngIf="env?.modulos?.cobranca?.assinatura"
      [disabled]="bloqueiaFuncionalidades">
      <button class="btn-close" [hidden]="form.get('tipoCobranca').value !== 2"
        [matToolTip]="bloqueiaFuncionalidades ? 'Para acessar essa funcionalidade você precisa ter um beneficiário cadastrado' : ''">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">ASSINATURA</span>
      <br />
      <i class="far fa-copy"></i>
      <br />
      <span>Emita boletos que se repetem, para mensalidades e assinaturas</span>
    </div>
    <div class="col-3 col-md-2 tipo-box"
      [ngClass]="{'tipo-box-selecionado': form.get('tipoCobranca').value == 3, 'disabled-div': bloqueiaFuncionalidades}"
      (click)="!bloqueiaFuncionalidades && setTipoCobranca(3)" *ngIf="env?.modulos?.cobranca?.parcelada"
      [disabled]="bloqueiaFuncionalidades">
      <button class="btn-close" [hidden]="form.get('tipoCobranca').value !== 3"
        [matToolTip]="bloqueiaFuncionalidades ? 'Para acessar essa funcionalidade você precisa ter um beneficiário cadastrado' : ''">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">PARCELADA</span>
      <br />
      <i class="far fa-file-alt"></i>
      <br />
      <span>Emite cobrança parcelada em até 24 vezes</span>
    </div>

    <div class="col-3 col-md-2 tipo-box" [ngClass]="form.get('tipoCobranca').value == 4 ? 'tipo-box-selecionado' : null"
      (click)="listaChavesPix.length !== 0 && setTipoCobranca(4)" *ngIf="env?.modulos?.pix?.gerarQrCode && areaPix"
      [disabled]="listaChavesPix.length === 0"
      [matToolTip]="listaChavesPix.length === 0 ? 'Para gerar QR Code você precisa ter uma chave cadastrada': ''">
      <button class="btn-close" [hidden]="form.get('tipoCobranca').value !== 4">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">GERAR QRCODE</span>
      <br />
      <i class="fa-solid fa-qrcode"></i>
      <br />
      <span>Cobrança única, para pagamentos via Pix</span>
    </div>

  </div>

  <hr style="border: 0.5px solid #dadada; margin: 1em 7em">

  <!-- <div class="col-8 col-md-8" style="display: flex; padding-bottom: 1.2em" class="opcoes-pix">
    <div class="opcoes-pix">
      <div [class]="form.get('contatoOuPix').value === 1 ?  'content-opcoes-checked' : 'content-opcoes' ">
        <button class="button-opc" (click)="setContatoOuPix(1)" [disabled]="bloqueiaFuncionalidades">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none">
            <path
              d="M4.2 8.57143C5.74437 8.57143 7 7.29018 7 5.71429C7 4.13839 5.74437 2.85714 4.2 2.85714C2.65563 2.85714 1.4 4.13839 1.4 5.71429C1.4 7.29018 2.65563 8.57143 4.2 8.57143ZM23.8 8.57143C25.3444 8.57143 26.6 7.29018 26.6 5.71429C26.6 4.13839 25.3444 2.85714 23.8 2.85714C22.2556 2.85714 21 4.13839 21 5.71429C21 7.29018 22.2556 8.57143 23.8 8.57143ZM25.2 10H22.4C21.63 10 20.9344 10.317 20.4269 10.8304C22.19 11.817 23.4412 13.5982 23.7125 15.7143H26.6C27.3744 15.7143 28 15.0759 28 14.2857V12.8571C28 11.2812 26.7444 10 25.2 10ZM14 10C16.7081 10 18.9 7.76339 18.9 5C18.9 2.23661 16.7081 0 14 0C11.2919 0 9.1 2.23661 9.1 5C9.1 7.76339 11.2919 10 14 10ZM17.36 11.4286H16.9969C16.0869 11.875 15.0763 12.1429 14 12.1429C12.9237 12.1429 11.9175 11.875 11.0031 11.4286H10.64C7.8575 11.4286 5.6 13.7321 5.6 16.5714V17.8571C5.6 19.0402 6.54063 20 7.7 20H20.3C21.4594 20 22.4 19.0402 22.4 17.8571V16.5714C22.4 13.7321 20.1425 11.4286 17.36 11.4286ZM7.57313 10.8304C7.06563 10.317 6.37 10 5.6 10H2.8C1.25563 10 0 11.2812 0 12.8571V14.2857C0 15.0759 0.625625 15.7143 1.4 15.7143H4.28312C4.55875 13.5982 5.81 11.817 7.57313 10.8304Z"
              fill="#4A494A" />
          </svg>
          <span class="opc-text" style="margin-left: 15px;">CONTATO SALVO</span>
        </button>
      </div>

      <div *ngIf="env?.modulos?.pix?.gerarQrCode">
        <div [class]="form.get('contatoOuPix').value === 2 ? 'content-opcoes-checked' : 'content-opcoes' "
          [matTooltip]="listaChavesPix.length > 0 ? 'Na cobrança pix o contato é opcional.' : 'Você precisa ter uma chave cadastrada para gerar cobrança via Pix'">
          <button class="button-opc" (click)="setContatoOuPix(2)" style="display: flex;
              align-items: center;" [disabled]="listaChavesPix.length === 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M5.58024 4.8547C5.08918 4.6765 4.55349 4.6765 3.97314 4.6765C5.15615 3.47368 7.62483 1.07248 7.6784 1.0012C7.74536 0.9121 8.18809 0.551143 8.3257 0.444336C8.57123 0.253765 8.99979 0.0790482 9.19621 0.0434075C9.44174 -0.00114291 10.3332 -0.0162034 10.5578 0.0211363C10.8256 0.0656798 11.2904 0.247409 11.4729 0.377523C11.6292 0.488895 12.0012 0.778449 12.2988 1.06802L13.4372 2.15947L15.9594 4.69876H15.2451C15.0666 4.69876 14.7541 4.7656 14.3523 4.9438C14.2544 4.98722 13.7125 5.35214 13.4372 5.61201L11.4729 7.57219C11.2348 7.79493 10.7587 8.32952 10.6247 8.44089C10.5248 8.52404 10.3123 8.70819 10.089 8.70819C9.91048 8.70819 9.64263 8.50772 9.55334 8.41862L8.973 7.86176C8.31826 7.1564 7.58911 6.48074 7.29894 6.19117C7.15343 6.04596 6.16308 5.0662 5.58024 4.8547Z"
                fill="#4A494A" />
              <path
                d="M14.4412 15.1453C14.9323 15.3235 15.468 15.3235 16.0483 15.3235C14.8653 16.5263 12.3967 18.9275 12.3431 18.9988C12.2761 19.0879 11.8334 19.4489 11.6958 19.5557C11.4503 19.7462 11.0217 19.921 10.8253 19.9566C10.5797 20.0011 9.68824 20.0162 9.4637 19.9789C9.19586 19.9343 8.73109 19.7526 8.54855 19.6225C8.39231 19.5111 8.02029 19.2216 7.72268 18.932L6.58432 17.8405L4.06207 15.3012L4.77634 15.3012C4.9549 15.3012 5.26739 15.2344 5.66917 15.0562C5.76707 15.0128 6.30903 14.6479 6.58432 14.388L8.54855 12.4278C8.78664 12.2051 9.26282 11.6705 9.39674 11.5591C9.49673 11.476 9.70923 11.2918 9.93244 11.2918C10.111 11.2918 10.3789 11.4923 10.4681 11.5814L11.0485 12.1382C11.7032 12.8436 12.4324 13.5193 12.7225 13.8088C12.8681 13.954 13.8584 14.9338 14.4412 15.1453Z"
                fill="#4A494A" />
              <path
                d="M10.7145 9.51027C10.5896 9.58155 10.1863 9.59937 10.0003 9.59937C9.97796 9.86666 9.94671 10.3923 10.0003 10.3567C10.0672 10.3122 10.6128 10.4395 10.7145 10.4903C10.8038 10.5349 11.1386 10.8393 11.2949 11.0027L13.5939 13.3192C13.8097 13.542 14.3037 14.0275 14.5537 14.1879C14.8037 14.3483 15.1192 14.4032 15.2457 14.4107H16.8304C17.5968 13.6236 19.2122 12.088 19.3081 11.9605C19.5089 11.6932 19.8164 11.1111 19.8884 10.9136C19.9777 10.6685 20 10.1117 20 9.95576C20 9.80634 19.8828 9.17326 19.8438 8.99796C19.7991 8.79749 19.6428 8.55247 19.5536 8.39655C19.4891 8.2839 19.1816 7.91393 19.0179 7.75059L17.9465 6.72596L16.7858 5.54541H15.5581C15.4019 5.54541 15.134 5.61223 15.0448 5.63451C14.9555 5.65678 14.5972 5.7513 14.4644 5.85725C14.3528 5.94635 14.1594 6.11712 14.085 6.21365C13.1698 7.13433 11.3306 8.98459 11.2949 9.02023C11.2502 9.06478 10.8708 9.42117 10.7145 9.51027Z"
                fill="#4A494A" />
              <path
                d="M9.28545 10.4455C9.41045 10.3743 9.81371 10.3564 9.99972 10.3564C10.022 10.0891 10.0533 9.56347 9.99972 9.59911C9.93276 9.64366 9.38725 9.51627 9.28545 9.46546C9.19619 9.42091 8.86136 9.1165 8.70511 8.95315L6.40607 6.6366C6.1903 6.41386 5.69627 5.92827 5.44627 5.7679C5.19628 5.60752 4.88081 5.55258 4.75433 5.54515L3.16955 5.54515C2.40321 6.33218 0.787767 7.86783 0.691945 7.99535C0.491078 8.26264 0.18355 8.84476 0.111603 9.04225C0.0223413 9.28727 -4.00026e-07 9.84413 -3.86367e-07 10.0001C-3.73276e-07 10.1495 0.11719 10.7826 0.156245 10.9579C0.200908 11.1583 0.357154 11.4033 0.446416 11.5593C0.510901 11.6719 0.818429 12.0419 0.982115 12.2052L2.05351 13.2299L3.2142 14.4104L4.44186 14.4104C4.59811 14.4104 4.86596 14.3436 4.95524 14.3213C5.04452 14.299 5.40281 14.2045 5.53556 14.0986C5.64718 14.0095 5.84061 13.8387 5.91501 13.7422C6.83016 12.8215 8.6694 10.9712 8.70511 10.9356C8.74975 10.891 9.12921 10.5346 9.28545 10.4455Z"
                fill="#4A494A" />
            </svg>
            <span class="opc-text" style="margin-left: 15px;">COBRANÇA PIX</span>
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row" style="justify-content: center; padding: 14px 0;" *ngIf="form.get('contatoOuPix').value === 1">
    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Quem cobrar?</mat-label>
        <input type="text" matInput formControlName="pessoa" [matAutocomplete]="auto" [(ngModel)]="buscar"
          (keyup)="applySearch($event.target.value)" required>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)='getNomeNumeroContato($event.option.value)'>
          <mat-option *ngFor="let contato of filtroContatos | async" [value]="contato">
            {{ (contato.pessoa.razaoSocial || contato.pessoa.nome) }} - {{ contato.pessoa.documento }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 col-md-3">
      <button type="button" (click)="criarEmpresa()"
        [disabled]="this.form.get('tipoCobranca').enabled === false || !this.editaContato"
        class="btn-novocliente btn btn-block" mat-raised-button>
        <fa-icon [icon]="['fas', 'circle-plus']"></fa-icon> NOVO CONTATO
      </button>
    </div>
  </div>
  <div class="row" style="justify-content: center;">
    <div class="row toggle">
      <div class="col-6 col-md-6" style="border-right: 1px solid #dadada" *ngIf="env?.modulos?.notificacao?.email">
        <p class="super-title">Emails para notificação:</p>
      </div>
      <div class="col-6 col-md-6" *ngIf="env?.modulos?.notificacao?.sms">
        <p class="super-title">Celular para notificação:</p>
      </div>

      <div class="col-6 col-md-6" style="height: 140px;
      overflow-y: auto;border-right: 1px solid #dadada;" *ngIf="env?.modulos?.notificacao?.email">
        <app-email [form]="form.get('emailNotificacao')" [data]="data" [dataEmails]="emails"
          [pix]="form.get('tipoCobranca').value == 4"></app-email>
      </div>
      <div class="col-6 col-md-6" style="height: 140px;
      overflow-y: hidden;padding-top: 5px;" *ngIf="env?.modulos?.notificacao?.sms">
        <mat-form-field appearance="outline" style="padding-left: 4px;">
          <mat-label>Celular</mat-label>
          <input matInput formControlName="notificacaoSms" type="text" placeholder="Celular" />
          <mat-error>{{ getErrorMessage(form, "notificacaoSms") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row" style="justify-content: right; padding: 14px 0;"
    *ngIf="this.form.get('beneficiarioFinal').enabled === false && form.get('tipoCobranca').value != 4">
    <div class="col-3 col-md-3 add" style="font-weight: unset !important; color: unset !important">
      <span (click)="adicionarOuRemoverBeneficiarioFinal()">+ Adicionar beneficiário final</span>
    </div>
  </div>
  <div class="row" style="justify-content: right; padding: 14px 0;"
    *ngIf="this.form.get('beneficiarioFinal').enabled === true">
    <div class="col-3 col-md-3 add" style="font-weight: unset !important; color: unset !important">
      <span (click)="adicionarOuRemoverBeneficiarioFinal()">- Remover beneficiário final</span>
    </div>
  </div>

  <div *ngIf="this.form.get('beneficiarioFinal').enabled === true">
    <hr style="border: 0.5px solid #dadada; margin: 1em 7em">
    <p class="super-title">Beneficiário Final</p>

    <div formGroupName="beneficiarioFinal" class="row" style="justify-content: center; padding: 14px 0;">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual o beneficiário final?</mat-label>
          <input type="text" matInput formControlName="contato" [matAutocomplete]="auto"
            [(ngModel)]="buscarBeneficiarioFinal" (keyup)="applySearchBeneficiarioFinal($event.target.value)">

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)='getNomeNumeroContatoBeneficiarioFinal($event.option.value)'>
            <mat-option *ngFor="let contato of filtroContatosBeneficiarioFinal | async" [value]="contato">
              {{ (contato.pessoa.razaoSocial || contato.pessoa.nome) }} - {{ contato.pessoa.documento }}
            </mat-option>
          </mat-autocomplete>

          <mat-error>{{ getErrorMessage(form.get('beneficiarioFinal'), "contato") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-3 col-md-3">
        <button type="button" (click)="criarContatoBeneficiarioFinal()" class="btn-novocliente btn btn-block"
          mat-raised-button>
          <fa-icon [icon]="['fas', 'circle-plus']"></fa-icon> Novo beneficiário final
        </button>
      </div>
    </div>

    <div formGroupName="beneficiarioFinal" class="row" style="justify-content: center;">
      <div class="row toggle">
        <div class="col-6 col-md-6" style="border-right: 1px solid #dadada" *ngIf="env?.modulos?.notificacao?.email">
          <p class="super-title">Emails para notificação:</p>
        </div>
        <div class="col-6 col-md-6" *ngIf="env?.modulos?.notificacao?.sms">
          <p class="super-title">Celular para notificação:</p>
        </div>

        <div class="col-6 col-md-6" style="height: 140px; overflow-y: auto;border-right: 1px solid #dadada;"
          *ngIf="env?.modulos?.notificacao?.email">
          <app-email [form]="form.get('beneficiarioFinal').get('emailNotificacao')" [data]="dataBeneficiarioFinal"
            [dataEmails]="emailsBeneficiarioFinal"></app-email>
        </div>
        <div class="col-6 col-md-6" style="height: 140px; overflow-y: hidden;padding-top: 5px;"
          *ngIf="env?.modulos?.notificacao?.sms">
          <mat-form-field appearance="outline" style="padding-left: 4px;">
            <mat-label>Celular</mat-label>
            <input matInput formControlName="notificacaoSms" type="text" placeholder="Celular" />
            <mat-error>{{ getErrorMessage(form.get('beneficiarioFinal'), "notificacaoSms") }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>