export enum StatusProtesto {
     // Enum interno - Usado quando o título é registrado no banco de dados pela primeira vez, enviado para IEPTB e está aguardando retorno
    AGUARDANDORETORNO = "AGUARDANDORETORNO",
    REMOVIDO = "REMOVIDO",
    DESISTIDO = "DESISTIDO",
    // Enuns externos IEPTB
    INEXISTENTE = "INEXISTENTE",
    COLETADO = "COLETADO", 
    GERADO = "GERADO", 
    ENVIADO = "ENVIADO",
    CONFIRMADO = "CONFIRMADO",
    DEVOLVIDO = "DEVOLVIDO",
    PAGO = "PAGO",
    RETIRADO = "RETIRADO",
    PROTESTADO = "PROTESTADO",
    SUSTADO = "SUSTADO",
    CANCELADO = "CANCELADO"
}