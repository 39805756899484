<div class="card card-plano">
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-md-2">
        <i [class]="dados[dadoSelecionado].icon"></i>
      </div>
      <div class="col-12 col-md-5">
        <p class="text-left">{{dados[dadoSelecionado].title}}</p>
      </div>
      <div class="col-12 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Selecione uma configuração</mat-label>
          <mat-select [(value)]="dadoSelecionado" (selectionChange)="disableSolicitarAumento()">
            <mat-option *ngFor="let item of dados; index as i" [value]="i">{{item.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-6" *ngFor="let item of dados[dadoSelecionado].params">
        <mat-form-field appearance="fill" class="formPlan">
          <div style="display: flex; flex-direction: row;">
            <mat-label >
              {{item.label}} 
            </mat-label>
            <i *ngIf="item.hasInfo" class="fas fa-info-circle info-icon" [matTooltip]="item.info"></i>
          </div>
          <input matInput type="text" [(ngModel)]="item.value" disabled [mask]="item.mask" [prefix]="item.prefix" [suffix]="item.suffix">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dados[dadoSelecionado].hasAumento" class="container">
    <div [hidden]="allDataLoaded" class="col-12" style="padding: 10px 0 20px 0;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="row">
      <div class="col-11 col-md-11">
        <p class="corPrimaria" style=" font-weight: bolder; margin-left: 20px;"> HISTÓRICO DE SOLICITAÇÕES DE AUMENTO DE LIMITE </p>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="10 últimas soicitações de aumento para esse limite"></i>
      </div>
    </div>
    <div *ngIf="allDataLoaded" class="row">
      <div class="col-12 col-md-12" *ngFor="let item of historico;">
        <p style="border-bottom: 1px solid #424242; color: #424242">
          {{item.dataCriacao | date:'dd/MM/yyyy'}} - Solicitado por <strong>{{item.usuarioSolicitante ? item.usuarioSolicitante : ""}}</strong> -<br/>
          Limite: {{item.valorPrecedente | currency:'':'R$':'':'PT-BR'}} para <strong>Limite: {{item.status =="aprovada" ? (item.valorEfetivado | currency:'':'R$':'':'PT-BR') : (item.valorSolicitado | currency:'':'R$':'':'PT-BR') }}</strong>: <span [style.color]="item.status === 'aprovada' ? '#4FA953' : item.status === 'reprovada' ? '#FE431C' : '#DFB924'" style="text-transform: uppercase;">{{item.status}}</span>
        </p>
      </div>
    </div>
    <div  *ngIf="historico.length == 0" class="col-12 col-md-12">
      <div *ngIf="allDataLoaded; else aguardandoProcessamento" class="sem-resultado">
        <i class="fas fa-scroll fa-2x"></i>
        <p>Ainda não possui solicitações cadastradas</p>
      </div>
      <ng-template #aguardandoProcessamento>
        <p>Processando histórico...</p>
      </ng-template>
    </div>
    <hr/>
</div>
<div *ngIf="dados[dadoSelecionado].hasAumento&&!solicitaAumento" class="row justify-content-center">
  <div  class="col-6" style="flex: 0 0 12.5%;">
    <button *ngIf="(env?.modulos?.aumentoLimites?.cobranca &&  dados[dadoSelecionado].title == 'BOLETO') || (env?.modulos?.aumentoLimites?.transferencia &&  dados[dadoSelecionado].title == 'TRANSFERÊNCIA' ) || (env?.modulos?.aumentoLimites?.antecipacao &&  dados[dadoSelecionado].title == 'ANTECIPAÇÃO' )  "
     mat-raised-button class="btn mat-primary" (click)="enableSolicitarAumento(); scroll(target)" style="background-color: #4FA9A9;">
      Solicitar aumento {{ dados[dadoSelecionado].title }}
    </button>
  </div>
</div>
<div  [hidden]="!solicitaAumento" class="container">
  <hr #target  />
  <div class="row">
    <div class="col-12" style="margin-bottom: 10px">
      <h4 class="corPrimaria" style="font-weight: bolder; margin-top: 12px">
        SOLICITAÇÃO DE AUMENTO DE LIMITES
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <p><strong>Limite atual disponível: </strong>
        <span style="color: #818d91;">{{ valorPrecedente | currency:'':'R$':'':'PT-BR' }}</span>
      </p>
    </div>
    <div class="col-12 col-md-6">
      <p class="corPrimaria" style=" font-weight: bolder">
        Ao solicitar o aumento do seu limite de {{ dados[dadoSelecionado].title.toLowerCase() }}, vamos analisar e retornar por e-mail em 2 dias úteis.
      </p>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="solicitarAumento()">
    <div id="processa" [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" style="margin-top: 30px;">
        <p>Qual o motivo da solicitação?</p>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Digite o novo limite:</mat-label>
          <input style="color: #4FA9A9;" matInput type="text" formControlName="valorSolicitado" mask="separator.2"
            thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>
    </div>
    <div  class="row">
      <div class="col-12 col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Motivo</mat-label>
          <textarea matInput rows="4" formControlName="motivo" maxlength="400" placeholder="Escreva aqui o seu motivo"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div  class="row justify-content-end">
      <div  class="col-12 col-md-4">
        <button type="submit" class="btn btn-block fundoCorPrimaria" style=" color: #fff;" mat-raised-button
          [disabled]="form.invalid">
          ENVIAR SOLICITAÇÃO
        </button>
      </div>
    </div>
  </form>
</div>
