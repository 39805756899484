<div>
  <!-- Cabeçalho Modal -->
  <h2 mat-dialog-title class="titulo-modal">
    NEO FINANCE
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
  </h2>
  
    <mat-tab-group mat-stretch-tabs class="vertical-tab-group">
      <div class="tab-container">

      <mat-tab label="SMS">
        <div class="tab-content">
          <div class="input-size">
            <div class="card-body">
              <h4 class="subtitulo">Teste de envio SMS</h4>
              
              <div class="row">
                <label for="celular">Celular</label>
                <i class="fas fa-info-circle info-icon" matTooltip="Insira o número do celular que deseja receber a mensagem"></i>
              </div>
              <input type="text" class="form-control" id="celular" placeholder="Celular" required [(ngModel)]="celular" mask="(00) 00000-0000">
              
              <hr>
              
              <div class="row">
                <label for="mensagem">Mensagem</label>
                <i class="fas fa-info-circle info-icon" matTooltip="Insira a mensagem"></i>
              </div>
              <input type="text" class="form-control" id="mensagem" placeholder="Mensagem" required [(ngModel)]="mensagem">
              
              <hr>

              <div mat-dialog-actions style="display: block; overflow: initial; margin: 0; padding: 8px 0 0; border-radius: 4px; margin: auto;">
                <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px; border-radius: 4px; margin: 0;">
                  <button class="btn btn-default next fundoCorPrimaria" (click)="testar()" [disabled]="!(mensagem && celular)">Testar</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      
      <hr>
      <!-- Aba API Onboard -->
      <mat-tab label="API Onboard">
        <div class="tab-content">
          <div class="input-size">
            <div class="card-body">
              <div class="div-interna flex-end" style="align-items: center;">
                <h4 class="subtitulo">API Onboard</h4>
              </div>
      
              <div class="row">
                <label for="cpfCnpj">CPF/CNPJ</label>
                <i class="fas fa-info-circle info-icon" matTooltip="Insira o número do CPF ou CNPJ que deseja consultar"></i>
              </div>
              <div class="input-button-container">
                <input type="text" style="width: 330px;" class="form-control" id="cpfCnpj" placeholder="CPF/CNPJ" required [(ngModel)]="cpfCnpj" mask="CPF_CNPJ">
                <button class="btn btn-default next fundoCorPrimaria" (click)="testarCpfCnpj()" [disabled]="!(cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14))">Testar</button>
              </div>
      
              <hr>
      
              <div class="row">
                <label for="cep">CEP</label>
                <i class="fas fa-info-circle info-icon" matTooltip="Insira o número do CEP que deseja consultar"></i>
              </div>
              <div class="input-button-container">
                <input type="text" style="width: 330px;" class="form-control" id="cep" placeholder="CEP" required [(ngModel)]="cep" mask="00000-000">
                <button class="btn btn-default next fundoCorPrimaria" (click)="testarCep()" [disabled]="!(cep)">Testar</button>
              </div>
              <hr>
              <hr>
            </div>
          </div>
        </div>
      </mat-tab>
    </div>

    </mat-tab-group>
</div>
