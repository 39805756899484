import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IndicarAmigoEndpointService } from '@project/src/app/service/indicar-amigo-endpoint.service';
import { PublicidadeEndpointService } from '@project/src/app/service/publicidade-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adiciona-imagem-indicar-contato',
  templateUrl: './adiciona-imagem.component.html',
  styleUrls: ['./adiciona-imagem.component.less']
})
export class AdicionaImagemIndicarContatoComponent implements OnInit {

  form: FormGroup;
  imagem = null;
  getBase64: string = null;
  getNameFile: string = null;
  getTypeFile: string = null;

  atualiza: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    public dialogRef: MatDialogRef<AdicionaImagemIndicarContatoComponent>,
    public formBuilder: FormBuilder,
    public publicidadeService: PublicidadeEndpointService,
    public indicarAmigoService: IndicarAmigoEndpointService,
  ) { }

  ngOnInit() {
    this.formImagem();
  }


  formImagem() {
    this.form = this.formBuilder.group({});
    this.form.addControl('imagem', new FormControl(null));
    this.form.addControl('imagemPath', new FormControl(''));
    this.form.addControl('href', new FormControl(''));
  }

  imagemNull() {
    this.imagem = null;
  }

  capturarBase64(event) {
    this.getBase64 = event;
  }

  capturarNomeFile(event) {
    this.getNameFile = event;
  }

  capturarTypeFile(event) {
    this.getTypeFile = event;
  }

  onSelectFile() {
    const base64 = this.getBase64.substring(
      this.getBase64.lastIndexOf(',') + 1,
      this.getBase64.length
    );
    const imagemData = {
      nome: this.getNameFile,
      tipo: this.getTypeFile,
      s3key: null,
      base64: base64,
      href: this.form.get('href').value
    };

    this.form.get('imagem').setValue(imagemData);

    this.salvarImagem();
    this.dialogRef.close();
  }

  salvarImagem() {
    const imagem = this.form.get('imagem').value;

    if (imagem) {
      this.indicarAmigoService.salvarImagem(imagem).subscribe(
        (res) => {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Imagem salva com sucesso',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close();
          });
        },
        (err: any) => {
          Swal.fire({
            title: 'Erro!',
            text: 'Erro ao salvar imagem',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close();
          });
        }
      );
    }
  }

  fecha() {
    this.dialogRef.close();
  }

}
