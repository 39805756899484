import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoEmpresa',
  pure: false
})
export class TipoEmpresaPipe implements PipeTransform {

  transform(value: string): string {

    switch (value) {
      case "ltda":
         return 'LTDA - Sociedade limitada'
      case "mei":
         return 'MEI - Microempreendedor individual'
      case "me":
         return 'ME - Microempresa'
      case "eireli":
         return 'EIRELI - Empresa individual de responsabilidade limitada'
      case "sa":
         return 'SA - Sociedade anônima'
      case "esc":
         return 'ESC - Empresa Simples de Crédito'
      case "scd":
         return 'SCD - Sociedade de Crédito Direto'
      case "fundo":
         return 'Fundo de investimento'
      case "holding":
         return 'Holding'
      case "outro":
         return 'Instituição, ONG ou Associação'
    }
  }

}
