<h2 mat-dialog-title class="titulo-modal">
   Notificações <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  
  <div mat-dialog-content class="div-body">

    <div class="main-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
      
              <div class="card">
                <div class="card-header card-header-primary card-header-icon">
                  <div class="row">
                    <div class="col-12 col-md-6" style="padding-left: 0">
                      <div class="card-icon">
                        <i class="fas fa-bell fa-2x"></i>
                      </div>
                      <h4 class="card-title" style="font-weight: 600">Notificações</h4>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <mat-tab-group #tab mat-stretch-tabs>
                    <mat-tab label="Todas">
                      <ng-template matTabContent>
                        <app-lista-notificacao [lida]=""></app-lista-notificacao>
                      </ng-template>
                    </mat-tab>
                    <mat-tab label="Não lidas">
                      <ng-template matTabContent>
                        <app-lista-notificacao [lida]="false"></app-lista-notificacao>
                      </ng-template>
                    </mat-tab>
                    <mat-tab label="Lidas">
                      <ng-template matTabContent>
                        <app-lista-notificacao [lida]="true"></app-lista-notificacao>
                      </ng-template>
                    </mat-tab>
                  </mat-tab-group>
                </div>
                <div class="card-footer"></div>
              </div>
      
            </div>
          </div>
        </div>
      </div>
      </div>
