import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RootEndpointService } from '../../service/root-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-tela-login',
  templateUrl: './tela-login.component.html',
  styleUrls: ['./tela-login.component.less']
})
export class TelaLoginComponent implements OnInit {

  public form: FormGroup;
  processando: boolean = false;
  config = {
    urlLogo: '',
    urlTermos: '',
    urlAjudaAntecipacoes: '',
    urlAjudaCobrancas: '',
    urlAjudaPagamentos: '',
    urlAjudaTransferencias: '',
    urlAjudaContatos: ''
  }

  constructor(
    public dialogRef: MatDialogRef<TelaLoginComponent>,
    private rootService: RootEndpointService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.processando = true;
    this.getConfig();
    this.form = new FormGroup({});
    this.form.addControl('urlLogo', new FormControl());
    this.form.addControl('urlTermos', new FormControl());
    this.form.addControl('urlAjudaAntecipacoes', new FormControl());
    this.form.addControl('urlAjudaCobrancas', new FormControl());
    this.form.addControl('urlAjudaPagamentos', new FormControl());
    this.form.addControl('urlAjudaTransferencias', new FormControl());
    this.form.addControl('urlAjudaContatos', new FormControl());
  }

  salvaConfig() {
    this.config.urlLogo = this.form.get('urlLogo').value;
    this.config.urlTermos = this.form.get('urlTermos').value;
    this.config.urlAjudaAntecipacoes = this.form.get('urlAjudaAntecipacoes').value;
    this.config.urlAjudaCobrancas = this.form.get('urlAjudaCobrancas').value;
    this.config.urlAjudaPagamentos = this.form.get('urlAjudaPagamentos').value;
    this.config.urlAjudaTransferencias = this.form.get('urlAjudaTransferencias').value;
    this.config.urlAjudaContatos = this.form.get('urlAjudaContatos').value;

    this.rootService.updateConfiguracoesSistema(this.config).pipe().subscribe(
      (response) => {
        this.modalFinal('Configurações foram atualizadas com sucesso!', response);
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error, 'error');
      });
  }

  modalFinal(titulo, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: ``,
        btnTexto: 'Rever configurações',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    }).afterClosed()
      .pipe()
      .subscribe(filtroData => {
        this.fecha();
      });
  }

  getConfig() {
    this.rootService.getConfiguracoesSistema().pipe().subscribe(
      (response) => {
        this.config = response;
        this.form.get("urlLogo").setValue(response.urlLogo)
        this.form.get("urlTermos").setValue(response.urlTermos)
        this.form.get("urlAjudaAntecipacoes").setValue(response.urlAjudaAntecipacoes)
        this.form.get("urlAjudaCobrancas").setValue(response.urlAjudaCobrancas)
        this.form.get("urlAjudaPagamentos").setValue(response.urlAjudaPagamentos)
        this.form.get("urlAjudaTransferencias").setValue(response.urlAjudaTransferencias)
        this.form.get("urlAjudaContatos").setValue(response.urlAjudaContatos)
        this.processando = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  fecha() {
    this.dialogRef.close();
  }
}
