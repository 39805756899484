import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-notificacao',
  templateUrl: './email-notificacao.component.html',
  styleUrls: ['./email-notificacao.component.less']
})
export class EmailNotificacaoComponent implements OnInit {

  contador = 0;
  @Input()
  public form: FormArray;
  @Input()
  public data: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (this.data && this.data?.emailNotificacao && this.data?.emailNotificacao?.length) {
      this.data?.emailNotificacao?.forEach(element => this.add(element));
    } else if (this.form?.controls?.length === 0) {
      this.add();
    }
  }

  public add(email?: any) {
    email = email || '';

    this.form.push(
      this.fb.group({
        id: this.fb.control(email.id || null),
        email: this.fb.control(email.email)
      }
      )
    );

    this.contador++;
  }

  public del(index) {
    this.form.removeAt(index);
    this.contador--;
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('email')
          ? 'Email inválido'
          : '';
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }
}