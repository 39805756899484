<div fxLayout="column" style="height: 100%; display: flex; align-items: center;  justify-content: space-between;">
    <b><p class="tamanho-texto">Assinatura</p></b>

    <div>
        <b><p class="tamanho-texto">Enviamos um linka para a assinatura do documento ao e-mail:</p></b>
        <p id="email" class="tamanho-texto">{{usuarioLogado?.email}}</p>
    </div>    

    <div>
        <p class="tamanho-texto">Não recebeu o o email?</p>
        <p><a (click)="reenviarEmail()" id="email" class="tamanho-texto">Reenviar</a></p>
    </div>

</div>