import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfiguracoesOnboardingDTO } from "../model/configuracoes-onboarding-dto.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ConfiguracaoEndpointService{
    constructor(private client: HttpClient){}

    atualizarConfiguracoesOnboarding(dto: ConfiguracoesOnboardingDTO){
        return this.client.post('/configuracao/onboarding', dto);
    }

    listarConfiguracoesOnboarding(): Observable<ConfiguracoesOnboardingDTO>{
        return this.client.get<ConfiguracoesOnboardingDTO>('/configuracao/onboarding');
    }
}