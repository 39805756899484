import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StringFormatService } from '../../service/util/stringFormater.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.less']
})
export class RootComponent implements OnInit {
  processando = false;
  constructor(
    public stringFormat: StringFormatService,
    public dialogRef: MatDialogRef<RootComponent>
  ) { }

  ngOnInit() { }

  fecha(): void {
    this.dialogRef.close();
  }
}
