<div class="row" [formGroup]="form">
    <div class="col-10 ml-5">
        <div class="row">
            <div class="col-12">
                <h4 class="corPrimaria" style=" font-weight: bolder; margin: 12px">TAXA DE {{
                    (form.get('tipoTransferencia') && form.get('tipoTransferencia').value == "entrada") ? "CREDITO" :
                    "DEBITO" }}
                </h4>
            </div>
        </div>

        <div class= "grid-sensibilização">
            
                <mat-form-field appearance="outline">
                    <mat-label>Tipo transferência</mat-label>
                    <mat-select (ngModelChange)="mudaTipo($event)" formControlName="tipoTransferencia">
                        <mat-option value="entrada">Entrada</mat-option>
                        <mat-option value="saida">Saída</mat-option>
                    </mat-select>
                </mat-form-field>
            
            
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de taxa</mat-label>
                    <mat-select formControlName="tipoTaxa">
                        <mat-option *ngFor = "let taxa of listaTaxas" [value]="taxa">{{mascaraTaxa(taxa)}}</mat-option>

                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>CNPJ/CPF Cliente</mat-label>
                    <input type="text" formControlName="cNPJCPFCli" matInput />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Conta corrente</mat-label>
                    <input type="text" formControlName="ct" (ngModelChange)="validaConta.emit($event)" matInput />
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Histórico do Lançamento</mat-label>
                    <input type="text" formControlName="historicoLancamento" matInput />
                </mat-form-field>
                              
                <mat-form-field appearance="outline">
                    <mat-label>Valor lançamento</mat-label>
                    <input type="text" formControlName="vlrLanc" matInput mask="separator.2" thousandSeparator="."
                        prefix="R$ " placeholder="0,00" />
                </mat-form-field>
                
                <div *ngIf="!env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Data bacen</mat-label>
                    <input matInput readonly="true" [matDatepicker]="picker1" [min]="todayDate" [max]="todayDate"
                        id="dtBC" (click)="picker1.open()" formControlName="dtBC">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                </div>
             <div *ngIf="env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Data bacen</mat-label>
                    <input matInput readonly="true" [matDatepicker]="picker1" [max]="yesterdayDate" id=dtBC
                        (click)="picker1.open()" formControlName="dtBC">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                </div>
             <div >
                <mat-form-field appearance="outline" *ngIf="!env?.modulos?.transferencia?.sensibilizacaoPassado">
                    <mat-label>Hora bacen</mat-label>
                    <input matInput type="text" id="hrBC" (ngModelChange)="validarHora($event, 'hrBC')" [maxLength]="5"
                        formControlName="hrBC">
                </mat-form-field>
             </div>
                <div *ngIf="env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Hora bacen</mat-label>
                    <input matInput type="text" id="hrBC" (ngModelChange)="mascaraHora($event, 'hrBC')" [maxLength]="5"
                        max formControlName="hrBC">
                </mat-form-field>
            </div>
        </div>
        <div class="row">   
            <div class="col-12 text-center">
                <p>Para finalizar a solicitação desta adição de taxa, é necessário autoriza-la por meio do seu token
                    SMS.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <p>O código de autorização será enviado para o número {{ numeroTelefone }}</p>
            </div>
        </div>

        <div class="row" style="justify-content: center; padding-top: 5px;">
            <div class="col-4 col-md-4">
                <button [disabled]="!podeReenviarSms" type="button" (click)="novoCodigoSms()"
                    class="btn-novocliente btn btn-block" mat-raised-button>
                    <fa-icon [icon]="['fas', 'circle-plus']"></fa-icon> ENVIAR TOKEN SMS
                </button>
                <p *ngIf="podeReenviarSms" class="obs">Não recebeu o SMS? <a (click)="novoCodigoSms()"
                        style="cursor: pointer;">Clique aqui</a></p>
                <p *ngIf="!podeReenviarSms" class="obs">Você poderá solicitar um novo código em {{ tempoReenvio }}
                    minutos
                    <br />
                    {{ bloqueiaEnviarSmsAte | date: "dd/MM/yyyy HH:mm" }}
                </p>
                <p [hidden]="podeReenviarSms" class="obs" style="color: #3d8686 !important">SMS enviado!</p>
            </div>
            <div class="col-5 col-md-5">
                <mat-form-field appearance="outline">
                    <mat-label>Token</mat-label>
                    <input matInput type="text" formControlName="token" />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>