<div class="box" appDragDrop (fileDropped)="onFileDropped($event)" (click)="idRef.click()">
    <input hidden type="file" #idRef (change)="onSelectFile($event.target.files)" />
    <div class="text">
        Arraste seu arquivo ou
        <label class="link">
            clique aqui
        </label>
        para o upload
    </div>
    <div class="icon">
        <fa-icon [icon]="['fas', 'cloud-arrow-up']"></fa-icon>
    </div>
</div>