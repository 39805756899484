import Swal from 'sweetalert2';
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { untilDestroyed } from "ngx-take-until-destroy";
import { EmpresaEndpointService } from "src/app/service/empresa-endpoint.service";
import { HomeEndpointService } from "src/app/service/home-endpoint.service";
import { ArquivosComponent } from "../arquivos/arquivos.component";

@Component({
  selector: "app-resumo",
  templateUrl: "./resumo.component.html",
  styleUrls: ["./resumo.component.less"],
})
export class ResumoComponent implements OnInit, OnDestroy {
  @Input()
  public data: any;

  analiseCadastro: any = {
    total: 0,
    feito: 0,
    faltando: 0,
    totalPorcentagem: 0,
  };
  dadosEmpresa: any;

  constructor(
    private fb: FormBuilder,
    private home: HomeEndpointService,
    private dialog: MatDialog,
    private empresaService: EmpresaEndpointService
  ) { }

  ngOnDestroy() { }

  ngOnInit() {
    this.getAnaliseCadastral();
    this.getDadosEmpresa();
  }

  getErrorMessage(form: FormControl): string | null {
    if (form.hasError("required")) {
      return "Campo requerido";
    }
    if (form.hasError("minlength")) {
      return "Campo inválido";
    }
    if (form.hasError("pattern")) {
      return "Campo inválido";
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === "" || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }

  getAnaliseCadastral() {
    let feito = 0;
    let faltando = 0;
    this.home
      .getAnaliseCadastral()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.analiseCadastro = res;
          if (this.analiseCadastro.aprovacaoEmpresa) {
            feito += 1;
          } else {
            this.analiseCadastro.faltando += 1;
          }
          if (this.analiseCadastro.aprovacaoUsuario) {
            feito += 1;
          } else {
            faltando += 1;
          }
          if (this.analiseCadastro.cadastroCorporacao) {
            feito += 1;
          } else {
            faltando += 1;
          }
          if (this.analiseCadastro.cadastroEmpresa) {
            feito += 1;
          } else {
            faltando += 1;
          }
          if (this.analiseCadastro.envioDocumentacaoEmpresa) {
            feito += 1;
          } else {
            faltando += 1;
          }
          if (this.analiseCadastro.envioDocumentacaoRepresentantesLegais) {
            feito += 1;
          } else {
            faltando += 1;
          }
          this.analiseCadastro.feito = feito;
          this.analiseCadastro.faltando = faltando;
          this.analiseCadastro.total = feito + faltando;
          this.analiseCadastro.totalPorcentagem =
            (feito / (feito + faltando)) * 100;
        },
        (err) => {
          Swal.fire({
            title: "Erro ao buscar dados da empresa",
            text: err.error,
            icon: "error",
          })
          console.error(err);
        }
      );
  }

  getDadosEmpresa() {
    this.empresaService
      .getEmpresaById(this.data)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.dadosEmpresa = res;
        },
        (err) => {
          Swal.fire({
            title: "Erro ao buscar dados da empresa",
            text: err.error,
            icon: "error",
          })
          console.error(err);
        }
      );
  }

  abrir(item, enviar, type, title) {
    if (type == "empresa" && item.arquivoEmpresa.length == 0) {
      enviar = !enviar;
      Swal.fire('Sua empresa não possui documeto', 'Por favor envie os documentos', 'info');
    }
    if (type == "representantes" && item.representantes.length == 0) {
      enviar = !enviar;
      Swal.fire('Sua empresa não possui representantes cadastrados', 'Por favor realize o cadastro', 'info');
    }
    if (item) {
      this.dialog.open(ArquivosComponent, {
        width: "45vw",
        data: {
          item,
          enviar: enviar,
          title: title,
          type: type,
        },
        backdropClass: "close-button-position",
        panelClass: "fundo-empresa",
      });
    }
  }
}
