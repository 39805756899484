<div class="arquivos-representantes box-body">
  <div>
    <form [formGroup]="form" class="espaco-top">
      <div class="row-inputs pb-3" [formGroup]="form">
        <div class="row" style="padding-bottom: 1em;padding-top: 3rem;">
          <div class="col-11" style="align-self: center;">
            <p style="text-align: center; margin: 0;">Lembre-se, os representantes legais devem estar no contrato social
              do
              seu negócio.
            </p>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Qual é o nome do representante?</mat-label>
              <input matInput type="text" formControlName="nome" required />
              <mat-error>{{ getErrorMessage(form, "nome") }}</mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="49">
            <mat-form-field appearance="outline">
              <mat-label>Qual é o CPF do representante?</mat-label>
              <input matInput type="text" formControlName="documento" mask="000.000.000-00" required />
              <mat-error>{{ getErrorMessage(form, "documento") }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="49">
            <mat-form-field appearance="outline">
              <mat-label>Data de nascimento</mat-label>
              <input matInput [matDatepicker]="picker1" id="dataNascimento"
                (keyup)="mascaraData($event,'dataNascimento')" formControlName="dataNascimento" [min]='dataMinima'
                [max]="dataMaxima">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error>{{ getErrorMessage(form, "dataNascimento") }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <input matInput type="text" hidden formControlName="pessoaPoliticamenteExposta" />
              <mat-slide-toggle formControlName="pessoaPoliticamenteExposta">Politicamente Exposta</mat-slide-toggle>
              <i class="fas fa-info-circle info-icon"
                matTooltip="É uma pesssoa que desempenha ou desempenhou, no Brasil ou no exterior nos últimos 5 anos, funções públicas relevantes, ou tece familiares, representantes ou colaboradores próximos que desempenharam ou desempenham esse tipo de função. Para saber mais, veja a Circular BCB n.3.978/20."></i>
              <mat-error>{{ getErrorMessage(form, "pessoaPoliticamenteExposta") }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div fxLayout="row">
    <div fxFlex="100">
      <app-upload-arquivos requiredText="Necessário informar ao menos um comprovante do representante"
        (outputFiles)="upload(documentos[0], $event,0)" [class]="documentos[0] ==''?'disabled-arquivos':''"
        [disabled]="documentos[0] ==''"></app-upload-arquivos>
    </div>
  </div>
</div>