<div class="container" #target>
    <div class="title">
        <h3>Gerenciamento de variáveis</h3>
    </div>
    <form [formGroup]="formVariaveis">
        <h4 *ngIf="!atualizandoVariavel">Nova variável</h4>
        <h4 *ngIf="atualizandoVariavel">Atualizando variável</h4>
        <div class="flex-center">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Nome da Variável</mat-label>
            <input matInput formControlName="nomeVariavel">
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
              <mat-label>Valor da Variável</mat-label>
              <input matInput formControlName="valorVariavel">
          </mat-form-field>
        </div>
        
        <div style="display: flex; justify-content: flex-end;">
            <button *ngIf="!atualizandoVariavel" class="btn btn-block" (click)="novaVariavel(formVariaveis)" style="width: 25%;">
              <i class="fas fa-plus" style="padding:0.5rem"></i>Adicionar nova variável 
            </button>
            <button *ngIf="atualizandoVariavel" class="btn btn-block" (click)="atualizarVariavel()" style="width: 25%;">
              <i *ngIf="!atualizandoVariavel" class="fas fa-plus" style="padding:0.5rem"></i>Atualizar variável 
            </button>
        </div>
    </form>
    <table  #table mat-table [dataSource]="dataSource" style="width: 100%;">
        <ng-container matColumnDef="opcoes">
          <th mat-header-cell *matHeaderCellDef >Opções</th>
          <td mat-cell *matCellDef="let row;" class="w-1">
            <button mat-button class="mat-button" [matMenuTriggerFor]="menu">
              <span class="mat-button-wrapper">
                  <i class="fas fa-tasks"></i>
              </span>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editar(row, target)">
                <i class="fas fa-edit"></i>
                Editar
              </button>
              <button mat-menu-item (click)="excluir(row)">
                <i class="fas fa-trash"></i>
                Excluir
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <ng-container matColumnDef="nomeVariavel">
            <th mat-header-cell *matHeaderCellDef >Nome da Variável</th>
            <td mat-cell *matCellDef="let row;">
                {{row.nomeVariavel}}
            </td>
        </ng-container>
        <ng-container matColumnDef="valorVariavel">
            <th mat-header-cell *matHeaderCellDef>Valor da Variável</th>
            <td mat-cell *matCellDef="let row">
                {{row.valorVariavel}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
