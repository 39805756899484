import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmitirCobrancaComponent } from './emitir-cobranca.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { PessoaEditarComponent } from '../../pessoa/pessoa-editar/pessoa-editar.component';
import { PessoaEditarModule } from '../../pessoa/pessoa-editar/pessoa-editar.module';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxMaskModule } from 'ngx-mask';
import { Stepper1Component } from './stepper1/stepper1.component';
import { Stepper2Component } from './stepper2/stepper2.component';
import { IconsModule } from '@app/icons.module';
import { ConfirmarEditarCobrancaComponent } from '../confirmar-editar-cobranca/confirmar-editar-cobranca.component';
import { ConfirmarEditarCobrancaModule } from '../confirmar-editar-cobranca/confirmar-editar-cobranca.module';
import { EmailComponent } from './email/email.component';
import { ModalConfirmacaoComponent } from '../../../../components/modal/modal-confirmacao/modal-confirmacao.component';
import { InputDateMonthrModule } from '../../../../components/input-date-month/input-date-month.module';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ValidacoesService } from '@project/src/app/service/validacoes.service';
import { LocalizacaoModule } from '@project/src/app/components/localizacao/localizacao.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [EmitirCobrancaComponent, Stepper1Component, Stepper2Component, EmailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatStepperModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    PessoaEditarModule,
    NgxCurrencyModule,
    InputDateMonthrModule,
    IconsModule,
    ConfirmarEditarCobrancaModule,
    DateFnsModule.forRoot(),
    NgxMaskModule.forRoot(),
    LocalizacaoModule
  ],
  providers: [
    ValidacoesService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ],
  exports: [EmailComponent]
})
export class EmitirCobrancaModule { }
