<form [formGroup]="form">
    <div class="row alinha" *ngFor="let tel of form.controls; index as i" [formGroup]="tel">
  
      <div [ngClass]="ehTelefoneNotificacao ? 'col-10' : 'col-8'">
        <h4 class="titulo">TELEFONE</h4>
      </div>
      <div class="col-2" style="text-align: end;">
        <i class="fas fa-trash icones"
          [title]="!tel.get('principal').value ? 'Excluir' : 'Não é possivel excluir o telefone principal'"
          (click)="!tel.get('principal').value && contador > 1 ? del(i) : null"
          [style.color]="!tel.get('principal').value && contador > 1 ? 'red' : 'grey'" style="padding-top: 12px"></i>
      </div>
      <div *ngIf="!ehTelefoneNotificacao" class="col-2" style="margin-top: 14px; text-align: end;">
        <mat-slide-toggle formControlName="principal" (change)="changeContaPrincipal($event)">Principal
        </mat-slide-toggle>
        <mat-error style="font-size: 12px;">{{ getErrorMessage(tel, "principal") }}</mat-error>
      </div>
  
      <div class="col-12 col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="tipo">
            <mat-option *ngFor="let tipo of listaTipoTelefone" [value]="tipo.valor">
              {{ tipo.valor }}
            </mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(tel, "tipo") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-7">
        <mat-form-field appearance="outline">
          <mat-label>DDD + Número</mat-label>
          <input matInput type="text" formControlName="numero"
            [mask]="tel.get('tipo').value === 'celular' ? '(00) 000000000' : '(00) 00000000'" />
          <mat-error>{{ getErrorMessage(tel, "numero") }}</mat-error>
        </mat-form-field>
      </div>
  
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Ramal</mat-label>
          <input matInput type="text" formControlName="ramal" />
          <mat-error>{{ getErrorMessage(tel, "ramal") }}</mat-error>
        </mat-form-field>
      </div>
  
    </div>
  </form>
  
  <div class="row alinha">
    <!-- <a class="text-muted" (click)="gerenteModal()">Dúvidas, visite nossa FAQ</a> -->
    <!-- <div class="col-6 col-md-6 add">
      <span (click)="add()">+ adicionar novo telefone</span>
    </div> -->
  </div>