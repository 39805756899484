import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { subYears } from 'date-fns';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-novo-signatario',
  templateUrl: './novo-signatario.component.html',
  styleUrls: ['./novo-signatario.component.less']
})
export class NovoSignatarioComponent implements OnInit {

  form;
  constructor(private _fb: FormBuilder,
    public dialogRef: MatDialogRef<NovoSignatarioComponent>,
    private rootService: RootEndpointService) { }

  mainAuthList: Array<{
    label: string,
    data: boolean,
    disable: boolean
  }> = [{
    label: "E-mail",
    data: false,
    disable: false
  }, {
    label: "Certificado digital",
    data: false,
    disable: true
  }, {
    label: "Pix",
    data: false,
    disable: true
  }, {
    label: "SMS",
    data: false,
    disable: false
  }, {
    label: "Whatsapp",
    data: false,
    disable: true
  }];

  otherAuthList: Array<{
    label: string,
    data: boolean,
    disable: boolean
  }> = [{
    label: "Selfie",
    data: false,
    disable: true
  }, {
    label: "Assinatura manuscrita",
    data: false,
    disable: true
  }, {
    label: "Documento oficial",
    data: false,
    disable: true
  }, {
    label: "Autenticação liveness",
    data: false,
    disable: true
  }, {
    label: "Biometria facial",
    data: false,
    disable: false
  }];

  dataDezoitoAnos: Date;
  dataMinima: Date;
  listaTipoTelefone = [{ valor: 'Telefone' }, { valor: 'Celular' }]
  telefoneTipo = "Celular";
  dataSource: MatTableDataSource<any>;
  selected: number;


  ngOnInit() {
    this.dataDezoitoAnos = subYears(new Date(), 18);
    this.dataMinima = subYears(new Date(), 120);
    this.form = new FormGroup({});
    this.form.addControl("nome", new FormControl(null, [Validators.required]));
    this.form.addControl("email", new FormControl(null, [Validators.required]));
    this.form.addControl("telefone", new FormControl(null, [Validators.required]));
    this.form.addControl("documento", new FormControl(null, [Validators.required]));
    this.form.addControl("ramal", new FormControl(null));
    this.form.addControl("tipo", new FormControl(this.telefoneTipo, [Validators.required]));
    this.form.addControl("nascimento", new FormControl(null, [Validators.required]));
  }

  validaTelefone(tel: FormControl) {
    if (tel && tel.value) {
      if (this.telefoneTipo === 'Celular' && tel.value.length === 11) {
        tel.setErrors(null);
        tel.clearAsyncValidators();
      } else if (this.telefoneTipo === 'Telefone' && tel.value.length === 10) {
        tel.setErrors(null);
        tel.clearAsyncValidators();
      } else {
        tel.setErrors({ valid: false });
      }
    }

  }

  onClose() {
    this.dialogRef.close();
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, "");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d{2})$/, "$1$2");
    document.getElementById(field)['value'] = data;
  }

  criar() {
    var signer = this.build();
    this.rootService.criarSignatario(signer).toPromise().then((response) => {
      Swal.fire('Sucesso', 'Signatário criado com sucesso.', 'success').then(() => this.dialogRef.close({ atualizar: true }));

    }, error => {
      Swal.fire('Erro', error.error, 'error').then(() => this.dialogRef.close({ atualizar: true }));
      this.dialogRef.close();
    })
  }

  build() {
    var auths = {
      email: false,
      certificado: false,
      pix: false,
      sms: false,
      selfie: false,
      whatsapp: false,
      assinatura: false,
      documento: false,
      autenticacao: false,
      biometria: false
    };
    this.mainAuthList.forEach(data => {
      switch (data.label) {
        case 'E-mail':
          auths.email = data.data;
          break;
        case 'Certificado digital':
          auths.certificado = data.data;
          break;
        case 'Pix':
          auths.pix = data.data
          break;
        case 'SMS':
          auths.sms = data.data
          break;
      }
    });

    this.otherAuthList.forEach(data => {
      switch (data.label) {
        case 'Whatsapp':
          auths.whatsapp = data.data;
          break;
        case 'Selfie':
          auths.selfie = data.data;
          break;
        case 'Assinatura manuscrita':
          auths.assinatura = data.data;
          break;
        case 'Documento oficial':
          auths.documento = data.data;
          break;
        case 'Autenticação':
          auths.autenticacao = data.data;
          break;
        case 'Biometria facial':
          auths.biometria = data.data;
          break;
      }
    });

    var signer = {
      email: this.form.get('email').value,
      nome: this.form.get('nome').value,
      telefone: this.form.get('telefone').value,
      nascimento: this.form.get('nascimento').value,
      documento: this.form.get('documento').value,
      auths: auths
    }

    return signer;
  }

  atualizarToggle(conta, i) {
    this.selected = i;
    this.mainAuthList.forEach(item => {
      if (item.label === conta) {
        item.data = !item.data;
      } else {
        item.data = false;
      }
    });
  }
}
