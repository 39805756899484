<div *ngIf="loading" class="loading-container flex-content-center" style="display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;">
    <mat-progress-spinner mode="indeterminate" style="justify-content: center;align-items: center;" id="spinner-load">
    </mat-progress-spinner>
</div>


<div class="body-all">
    <div class="logo-header" style="justify-content: center; 
    display: flex;
    padding-top: 40px;">
        <img src="assets/img/icons/pluga-bank/logos/logo-colorido.png" class="logo" />
    </div>
    <div class="body-container" [hidden]="loading">
        <div class="body-adjust">
            <div class="qrcode-image">
                <div>
                    <span class="title">Use o QR Code do Pix para fazer o pagamento.</span>
                </div>
                <div>
                    <span class="sub-title">Esacaneie com o aplicativo do seu banco ou cole o código do QR
                        Code.</span>
                </div>
                <div style="border-radius: 4px;">
                    <img alt="pix" style="width: 230px; height: 230px; margin: 0 auto; display: block;border-radius: 6px; margin-top: 30px;
                        margin-bottom: 30px;" src="data:image/png;base64,{{ dadosQr?.imagemQrCodeInBase64 }}">
                </div>
                <div class="valorQr">
                    {{ dadosQr?.valorOriginal | currency: "BRL":"R$" }}
                </div>
                <div class="copiar" (click)="copiarQrCode()">
                    <span>Copiar código do QR Code </span>
                    <i class="fas fa-copy"></i>
                </div>
            </div>

        </div>
        <div class="container-texto">
            <div class="text-qr">
                <div style="margin-top: 12px; align-items: flex-start;">

                    <!-- <p style=" font-size: 24px;width: 305px;line-height: normal;">
                        Transfira <span class="valor">
                            {{ dadosQr?.valorOriginal ? (dadosQr?.valorOriginal | currency: "BRL":"R$") :
                            'R$0,00' }}</span> para {{ dadosQr?.nomeRecebedor }}.
                    </p> -->

                    <div class="sub-content-qr">
                        <div class="resumo">
                            <p class="text-qr-sub">Ou use a <span class="corBase">chave Pix</span></p>
                            <hr style="width: 100%; margin-top: none;">
                            <div class="resumo-item">
                                <span style="font-weight: 600;">Nome</span>
                                <span>{{ dadosQr?.nomeRecebedor }}
                                </span>
                            </div>
                            <div class="resumo-item">
                                <span style="font-weight: 600;">Chave pix</span>
                                <span class="corBase" style="font-weight: 450;">{{ dadosQr?.chave }} <i
                                        class="fas fa-copy" (click)="copiarChave()" style="cursor: pointer;"></i>
                                </span>
                            </div>
                            <div class="resumo-item">
                                <span style="font-weight: 600;">Documento</span>
                                <span>{{ dadosQr?.cpfCnpj | cpfCnpj }}</span>
                            </div>
                            <div class="resumo-item">
                                <span style="font-weight: 600;">Institução financeira</span>
                                <span>{{ env.empresa.razaoSocial }}</span>
                            </div>
                            <div class="resumo-item">
                                <span style="font-weight: 600;">Valor</span>
                                <span>{{ dadosQr?.valorOriginal | currency: "BRL":"R$" }}</span>
                            </div>
                            <div class="resumo-item" *ngIf="dadosQr?.expiracaoQR">
                                <span style="font-weight: 600;">Data expiração</span>
                                <span>{{ dadosQr?.expiracaoQR | date:'dd/MM/yyyy' }}</span>
                            </div>
                            <div class="resumo-item" *ngIf="dadosQr?.reutilizavel">
                                <span style="font-weight: 600;" class="corBase">Reutilizável</span>
                            </div>
                        </div>
                        <!-- <div>
                            <button class="btn btn-block btn-secundario btn-save">Conheça {{
                                env.empresa.nomeAbrevidado }}
                            </button>
                        </div> -->
                        <hr style="width: 100%; margin-top: none;">

                    </div>
                    <div class="descricao">
                        {{ dadosQr?.descricao ? '"' + dadosQr.descricao + '"' : '' }}
                    </div>
                </div>
            </div>
            <div class="saiba-mais">
                <a [href]="env.url.appSite">
                    <p>Saiba mais > </p>
                </a>
            </div>
        </div>
    </div>

    <div style="text-align: center; font-size: 12px;">
        <div>{{env?.empresa?.email?.contato}} {{env?.empresa?.telefone?.contato}}</div>
        <div>{{env?.empresa?.enderecoDTVM?.tipoLogradouro}}
            {{env?.empresa?.enderecoDTVM?.logradouro}},
            {{env?.empresa?.enderecoDTVM?.numero}}</div>
    </div>
</div>