<div mat-dialog-content class="div-body">
      <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
          <div class="row" style="justify-content: center;">
              <div class="col-12">

                  <div class="card">
                      <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                          <div class="col-12">
                              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                          </div>
                      </div>

                      <div class="card-body" *ngIf="!processando">
                          <h2 mat-dialog-title class="titulo-modal">                         
                              {{data.titulo}}
                          </h2>
                          <div class="box-body">
                            <div class="icone-confirmacao">
                            <i class="fas fa-check fa-2x"></i>
                          </div>
                          </div>
                          <div class="box-body info-modal">
                            {{data.texto}}
                            </div>
                          <div class="box-body info-subtexto">
                            {{data.subtexto}}
                            </div>
                          <hr *ngIf="data.whatsapp">
                          <div class="box-body extra-modal" (click)="enviarWhats()" *ngIf="data.whatsapp">
                            <i class="fab fa-whatsapp"></i> Possui o contato? Avise por WhatsApp
                          </div>
                          <hr *ngIf="data.checkout">
                          <div class="box-body extra-modal" (click)="checkout()" *ngIf="data.checkout">
                            <i class="fas fa-external-link-alt"></i> Ver checkout
                          </div>
                          <hr>
                          <div class="box-body div-btn">
                              <!-- <button mat-raised-button (click)="nova()" class="btn mat-primary btn-disabled">
                                  <p>{{data.btnTexto}}</p>
                              </button> -->
                              <button mat-raised-button (click)="fechar()" class="btn mat-primary">       
                                  <p>Sair</p>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</div>