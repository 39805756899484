<h2 mat-dialog-title class="titulo-modal">
  NOTIFICAÇÃO <i (click)="fecha()" class="material-icons btn-fechar">close</i>
  <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
  <div class="row">
    <div class="col-12 horizontal-stepper">
      <div class="box-body" [formGroup]="form">
        <p class="super-title">Dados da notificação</p>
        <hr style="border: 0.5px solid #dadada; margin: 1em 7em" />
        <div class="row" style="justify-content: center">
          <div class="col-10 col-md-10">
            <mat-form-field appearance="outline">
              <mat-label>Nível de notificação</mat-label>
              <mat-select formControlName="type" (selectionChange)="selecionar()">
                <mat-option value="corporação">Corporação</mat-option>
                <mat-option value="empresa">Empresa</mat-option>
                <mat-option value="usuario">Usuário</mat-option>
              </mat-select>
              <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="form.get('type').value != ''">
          <div class="row" style="justify-content: center" *ngIf="form.get('type').value == 'usuario'">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Usuário</mat-label>
                <input type="text" formControlName="usuario" matInput />
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="justify-content: center" *ngIf="form.get('type').value == 'empresa'">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Empresa</mat-label>
                <input type="text" matInput formControlName="empresaId" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)='getEmpresas($event.option.value)'>
                  <mat-option *ngFor="let item of filtroEmpresa | async" [value]="item">
                    {{ (item.pessoa.razaoSocial || item.pessoa.nome) }} - {{ item.pessoa.documento }}
                  </mat-option>
                </mat-autocomplete>
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="justify-content: center" *ngIf="form.get('type').value == 'corporação'">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Corporação</mat-label>
                <input type="text" matInput formControlName="corporacaoId" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCorporacao"
                  (optionSelected)='getCorporacao($event.option.value)'>
                  <mat-option *ngFor="let item of filtroCorporacao | async" [value]="item">
                    {{ item.nome }}
                  </mat-option>
                </mat-autocomplete>
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="justify-content: center">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Mensagem</mat-label>
                <textarea matInput formControlName="mensagem" rows="2" maxlength="100"></textarea>
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="justify-content: center">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Nível</mat-label>
                <mat-select formControlName="nivel">
                  <mat-option value="INFORMACAO">Informação</mat-option>
                  <mat-option value="ALERTA">Alerta</mat-option>
                  <mat-option value="FALHA">Falha</mat-option>
                </mat-select>
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="justify-content: center">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Tipo de informação</mat-label>
                <mat-select formControlName="tipo">
                  <mat-option value="BOAS_VINDAS">Boas vindas</mat-option>
                  <mat-option value="CONVITE">Convite</mat-option>
                  <mat-option value="INFORMACAO">Informação</mat-option>
                  <mat-option value="COBRANCAS">Cobrança</mat-option>
                </mat-select>
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>

          <div class="row" style="justify-content: center">
            <div class="col-10 col-md-10">
              <mat-form-field appearance="outline">
                <mat-label>Url</mat-label>
                <input type="text" formControlName="url" matInput />
                <!-- <mat-error>{{ getErrorMessage(form, "pessoa") }}</mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row" style="justify-content: center">
          <div class="col-10 col-md-10">
            <button (click)="criar()" [disabled]="!form.valid || processando == true" type="button"
              class="btn btn-block fundoCorPrimaria" style=" color: #fff" mat-raised-button>
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>