import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { PageResponse } from "../model/page-response.model";
import { SolicitacaoAumentoDTO } from "../model/solicitacao-aumento-dto.model";

@Injectable({
    providedIn: 'root'
})
export class AumentoEndpointService {
    constructor(private client: HttpClient) { }

    public solicitarAumento(solicitacao: SolicitacaoAumentoDTO): any {
        const url = '/aumento/cadastrar';
        return this.client.post(url, solicitacao);
    }

    public listar(filtro: any): Observable<PageResponse<SolicitacaoAumentoDTO>>{
        filtro = filtro || {};
        let params = new HttpParams();

        if (filtro.data) {
            if (filtro.data.emissao && filtro.data.emissao.show) {
              if (filtro.data.emissao.de) {
                params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
              }
              if (filtro.data.emissao.ate) {
                params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
              }
            }
        }

        if (filtro.tipo) {
            params = params.append('tipo', `${filtro.tipo}`);
        }
        if (filtro.status) {
            params = params.append('status', `${filtro.status}`);
        }

        if (filtro.order) {
            params = params.append('order', `${filtro.order}`);
        }
        if (filtro.page >= 0) {
            params = params.append('page', `${filtro.page}`);
        }
        if (filtro.size >= 0) {
            params = params.append('size', `${filtro.size}`);
        }
        if (filtro.corporacao) {
            params = params.append('idCorporacao', `${filtro.corporacao}`);
        }
        if (filtro.idEmpresa){
            params = params.append('idEmpresa', `${filtro.idEmpresa}`);
        }
        
        const url = '/aumento/listar';
        return this.client.get(url, { params });
    }

    public listarEmpresa(filtro: any): Observable<PageResponse<SolicitacaoAumentoDTO>> {
        filtro = filtro || {};
        let params = new HttpParams();

        if (filtro.data) {
            if (filtro.data.emissao && filtro.data.emissao.show) {
                if (filtro.data.emissao.de) {
                    params = params.append('data-emissao-maior-igual', `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`);
                }
                if (filtro.data.emissao.ate) {
                    params = params.append('data-emissao-menor-igual', `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`);
                }
            }
        }

        if (filtro.tipo) {
            params = params.append('tipo', `${filtro.tipo}`);
        }
        if (filtro.status) {
            params = params.append('status', `${filtro.status}`);
        }

        if (filtro.order) {
            params = params.append('order', `${filtro.order}`);
        }
        if (filtro.page >= 0) {
            params = params.append('page', `${filtro.page}`);
        }
        if (filtro.size >= 0) {
            params = params.append('size', `${filtro.size}`);
        }
        if (filtro.corporacao) {
            params = params.append('idCorporacao', `${filtro.corporacao}`);
        }
        if (filtro.idEmpresa) {
            params = params.append('idEmpresa', `${filtro.idEmpresa}`);
        }

        const url = '/aumento/listar-empresa';
        return this.client.get(url, { params });
    }

    public moderar(form) {
        const url = '/aumento/moderar';
        let params = new HttpParams();
        params = params.append('id', `${form.id}`);
        params = params.append('moderacao', `${form.moderacao}`);
        params = params.append('valor', `${form.valor}`);
        return this.client.get(url, { params });
    }
}