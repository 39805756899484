import { MatPaginatorIntl } from "@angular/material/paginator";
export function translatePaginator() {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.nextPageLabel = 'Próxima';
  paginatorIntl.previousPageLabel = 'Anterior';
  paginatorIntl.getRangeLabel = getDisplayText;

  return paginatorIntl;
}

const getDisplayText = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return 'Sem resultados.';
  }
  const startIndex = page * pageSize;
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;
  return `${startIndex + 1}-${endIndex} de ${length}`;
}