<div mat-dialog-content class="div-body">
  <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
    <div class="row" style="justify-content: center;">
      <div class="col-12">
        <div class="card">
          
          <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>

          <div class="card-header" *ngIf="!processando">
            <div class="col-12">
              <div style="text-align: center;" class="row">
                <div class="col-12 col-md-12">
                  <img src="assets/img/icons/pluga-bank/logos/logo-colorido.png" class="logo" />
                </div>
              </div>
              <div class="row">
                <div class="col-12" style="text-align: center;">
                  <p>{{ data.mensagem }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" *ngIf="!processando"> 
            <app-token [form]="form" [estrutura]="data.estrutura" [celular]="data.celular"></app-token>
            <div class="row justify-content-center align-items-end">
              <div class="col-12 col-md-4">
                <button type="button" (click)="fecha()" class="btn btn-block btn-primario" style="color: #fff;margin-top: 10%"
                  mat-raised-button>
                  Cancelar
                </button>
              </div>
              <div class="col-12 col-md-4">
                <button type="submit" class="btn btn-block btn-primario fundoCorPrimaria"
                style=" border-color: #ee9900;margin-top: 10%;" mat-raised-button
                [disabled]="!valido()" (click)="validaToken()">
                  <fa-icon [icon]="['fas', 'spinner']" [pulse]="true" *ngIf="processando"></fa-icon>
                  Confirmar
                </button>
              </div>
            </div>
                  
          </div>
        </div>
      </div>
    </div>
  </div>
</div>