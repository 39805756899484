import { ArquivoRepresentanteEmpresaDTO } from './../../../../../../model/pessoa/arquivo-representante-empresa-dto.model';
import { RepresentanteEmpresaDTO } from './../../../../../../model/pessoa/representante-empresa-dto.model';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';

@Component({
  selector: 'app-representantes',
  templateUrl: './representantes.component.html',
  styleUrls: ['./representantes.component.less']
})
export class RepresentantesComponent implements OnInit, AfterViewInit {

  @ViewChild('mainDiv', { static: true }) mainDiv: ElementRef;

  @Input()
  representantes: RepresentanteEmpresaDTO[];

  @Input()
  form: FormArray;

  @Input()
  documento: string;

  @Input()
  dataDialog;

  documentos: string[] = [];

  ehAdmin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private autenticacao: AutenticacaoEndpointService
  ) {
    const userData = this.autenticacao.sessao;
    if (this.autenticacao && userData.principal.authorities) {
      this.ehAdmin =
        userData.principal.authorities.filter(
          (item) =>
            item.permissao === "ROLE_ADMINISTRADOR" &&
            item.authority === "ROLE_ADMINISTRADOR"
        ).length > 0;
    }
   }

  ngAfterViewInit(): void {
    for (let representante of this.representantes) {
      this.documentos.push('');
    }
  }

  ngOnInit() {
    this.mainDiv.nativeElement.scrollTop = 0;
  }

  upload(tipo: string, arquivos: ArquivoRepresentanteEmpresaDTO[], index: number) {
    const form = this.form.at(index).get('arquivoRepresentanteEmpresa') as FormArray;
    for (let arquivo of arquivos) {
      arquivo.documento = this.documento;
      arquivo.moderacao = 'aguardandoModeracao';
      form.push(new FormControl(arquivo))
    }
  }

  getModeracao(representante: FormGroup) {
    let arquivoRepresentanteEmpresa = representante.get('arquivoRepresentanteEmpresa') as FormArray;
    let arquivos: ArquivoRepresentanteEmpresaDTO[] = arquivoRepresentanteEmpresa.value;
    arquivos = arquivos.filter(
      arquivo => arquivo.documento === this.documento);
    if (arquivos && arquivos[0])
      return arquivos[0];
  }

  public add() {
    // representante.arquivoRepresentanteEmpresa
    let formRepresentantes = this.form as FormArray;
    formRepresentantes.push(
      this.fb.group({
        arquivoRepresentanteEmpresa: this.fb.array([]),
        id: this.fb.control(null),
        nome: this.fb.control('', [Validators.required]),
        documento: this.fb.control('', [Validators.required]),
        dataNascimento: this.fb.control('', [Validators.required]),
        telefone: this.fb.control('', [Validators.required]),
        email: this.fb.control('', [Validators.required]),
        tipoRepresentante: this.fb.control('socioDiretor'),
        pessoaPoliticamenteExposta: this.fb.control(false, [Validators.required]),
      })
    );
  }

}
