<div mat-dialog-title class="titulo-modal" fxLayout="row">
    <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
      
    </div>
    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">{{(data && data.documento)? 'Atualizar '+data.documento : 'NOVO DOCUMENTO DO ONBOARDING'}}</div> 
    <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
        <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="onClose()">FECHAR</p>
        <i style="margin-right: 10px;"(click)="onClose()" class="material-icons btn-fechar" >close</i>
    </div>
</div>

<div mat-dialog-content class="mat-dialog-content-responsive mudaCor" [formGroup]="form">
    <div class="row">
        <div [class]="somenteUpload ? 'col-12' : 'col-9'" fxLayout="column">
            <div class="box-body" fxLayout="row wrap" fxFlex="100" [hidden]="somenteUpload">
                <p class="title-row" fxFlex="100">Tipo de abertura de conta</p>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="4rem">
                    <div fxFlex="30" class="mat-slide-div" fxLayoutAlign="center center">
                        Pessoa Física <mat-slide-toggle [checked]="pessoa === true ? true : false"
                            (change)="onChangeToggle('pessoa', $event)"></mat-slide-toggle>
                    </div>
                    <div fxFlex="30" class="mat-slide-div" fxLayoutAlign="center center">
                        Pessoa Jurídica <mat-slide-toggle [checked]="empresa === true ? true : false"
                            (change)="onChangeToggle('empresa', $event)"></mat-slide-toggle>
                    </div>
                    <div fxFlex="30" class="mat-slide-div" fxLayoutAlign="center center">
                        Representante Legal <mat-slide-toggle [checked]="representantes === true ? true : false"
                            (change)="onChangeToggle('representantes', $event)"></mat-slide-toggle>
                    </div>
                </div>

            </div>
            <div class="box-body" [hidden]="somenteUpload">
                <p class="title-row">Características do campo</p>
                <div>
                    <div fxFlex="70">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Título do campo</mat-label>
                            <input matInput placeholder="Escreva o nome" formControlName="documento">
                        </mat-form-field>
                    </div>
                    <div fxFlex="30" fxLayout="row wrap" class="div-obrigatorio" fxLayoutAlign="space-around center">
                        <span fxFlex="100" fxLayoutAlign="center center">É um campo obrigatório?</span>
                        <mat-radio-group formControlName="obrigatorio" fxFlex="100" fxLayoutAlign="space-evenly center">
                            <mat-radio-button [value]="true">Sim</mat-radio-button>
                            <mat-radio-button [value]="false">Não</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-body" [hidden]="somenteUpload">
                <p class="title-row">Informações adicionais do documento</p>
                <div>
                    <div fxFlex="95">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput placeholder="Escreva a descrição" formControlName="descricao">
                            </textarea>   
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="box-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <p class="title-row" fxFlex="100" [hidden]="somenteUpload">Tipo ou funcionalidade do campo</p>

                <div fxFlex="24" [hidden]="somenteUpload">
                    <div class="mat-slide-div">
                        <mat-slide-toggle [checked]="uploadDeArquivo === true ? true : false"
                            (change)="onChangeToggleCampo('uploadDeArquivo')">Upload simples de arquivos
                        </mat-slide-toggle>
                    </div>
                </div>
                <div fxFlex="24" [hidden]="somenteUpload">
                    <div class="mat-slide-div">
                        <mat-slide-toggle [checked]="assEletronica === true ? true : false"
                            (change)="onChangeToggleCampo('assEletronica')">Assinatura eletrônica</mat-slide-toggle>
                    </div>
                </div>
                <div fxFlex="24" [hidden]="somenteUpload">
                    <div class="mat-slide-div">
                        <mat-slide-toggle [checked]="assPreencher === true ? true : false"
                            (change)="onChangeToggleCampo('assPreencher')">Preencher e assinar</mat-slide-toggle>
                    </div>
                </div>
                <div fxFlex="24" [hidden]="somenteUpload">
                    <div class="mat-slide-div">
                        <mat-slide-toggle [checked]="assSemiEletronica === true ? true : false"
                            (change)="onChangeToggleCampo('assSemiEletronica')">Semi-eletrônica</mat-slide-toggle>
                    </div>
                </div>
                <div fxFlex="100" class="div-upload-arquivos" *ngIf="assPreencher || assSemiEletronica">
                    <app-upload-arquivos ehDocumentoRoot="true" [somentePdf]="!!assSemiEletronica" [subTitle]="assSemiEletronica ? 'Somente formato pdf' : ''" [files]="data?.arquivoRoot" requiredText="Selecione o arquivo" (outputFiles)="upload('arquivoRoot', $event)">
                    </app-upload-arquivos>
                </div>

                <div fxFlex="100" fxLayout="column" fxLayoutAlign="center none"  class="div-upload-arquivos" *ngIf="assEletronica">

                    <div class="box-body">
                        <p class="title-row">Tipos de autenticação</p>
                        <div class="menor-title">Autenticações principais</div>
                            <mat-radio-group fxLayout="row wrap" fxLayoutAlign="start start" class="div-checkbox" [value]="selected">
                                <mat-radio-button fxFlex="33" fxFlex.md="50" *ngFor="let conta of mainAuthList; let i = index" [value]="conta?.label" [disabled]="conta?.disable" (change)="selected=conta?.label">
                                    {{ conta?.label }}
                                </mat-radio-button>
                            </mat-radio-group>
                        <div class="menor-title">Autenticações adicionais</div>
                        <div fxLayout="row wrap" fxLayoutAlign="start start" class="div-checkbox">
                            <ng-container *ngFor="let conta of otherAuthList; let i = index">
                                <mat-checkbox fxFlex="33" fxFlex.md="50" [value]="conta?.label" [checked]="conta?.data" (change)="onChange(conta)"
                                    [disabled]="conta?.disable">
                                    {{ conta?.label }}
                                </mat-checkbox>
                            </ng-container>
                        </div>
                    </div>

                    <div fxFlex="100">
                        <app-upload-arquivos ehDocumentoRoot="true" [files]="data?.arquivoRoot" requiredText="Selecione o arquivo" (outputFiles)="upload('arquivoRoot', $event)">
                        </app-upload-arquivos> 
                    </div>        
                </div>
            </div>

            <div class="box-body" *ngIf="data.modelo === 'investidor' && fundos.length > 0" fxLayout="column" fxFlex="100" [hidden]="somenteUpload">
                <p class="title-row" fxFlex="100">Vínculo com fundo de investimento</p>
                <mat-radio-group fxLayout="row wrap" fxLayoutAlign="start start" class="div-checkbox" [value]="fundoSelecionado">
                    <mat-radio-button fxFlex="100" [value]="0" (change)="fundoSelecionado=0">Nenhum</mat-radio-button>
                    <mat-radio-button fxFlex="100" *ngFor="let fundo of fundos" [value]="fundo.id" (change)="fundoSelecionado=fundo.id">
                        {{ fundo.name.length > 80 ? fundo.name.substr(0,80)+'...' : fundo.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div> 

        </div>
        <div class="col-3 resumo" style="height:60vh;overflow-y: auto;" [hidden]="somenteUpload">
            <p class="super-title"><b>RESUMO</b>
            </p>
            <br />
            <p><span style="font-weight: bold;">Tipo de conta:</span> {{form.get('tipo').value | tipoDocumento}}</p>
            <br />
            <p><span style="font-weight: bold;">Título do campo: </span> {{form.get('documento').value}}</p>
            <br />
            <p><span style="font-weight: bold;">Descrição do documento:</span> <br /><span style="word-wrap: break-word"> {{form.get('descricao').value}} </span> </p>
            <br />
            <p><span style="font-weight: bold;">Campo obrigatório:</span>{{form.get('obrigatorio').value ==true ? 'Sim':'Não'}}</p>
            <br />
            <p><span style="font-weight: bold;">Tipo e funcionalidade:</span><br/>{{form.get('formato').value | formatoDocumento}}</p>
            <br />
            <p *ngIf="fundoSelecionado"><span style="font-weight: bold;">Fundo vinculado:</span><br/>{{nomeFundoSelecionado}}</p>
            <!-- <p>Arquivo enviado - 27/01/22 - 10h24</p> -->
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <div [hidden]="!loading" class="col-12" style="padding: 10px 0 20px 0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div fxFlex="100" fxLayoutAlign="center center">
        <div fxFlex="40" fxLayoutAlign="space-between center">
            <button type="button" fxFlex="48" (click)="onClose()" class="btn btn-default btn-block" style="color: #fff"
                mat-raised-button>
                Cancelar
            </button>
            <button type="submit" fxFlex="48" class="btn btn-block fundoCorPrimaria" [disabled]="somenteUpload ? false : (form.invalid || loading)" (click)="onSubmit()"
                style=" color: #fff" mat-raised-button>
                {{data?.formato ? 'Atualizar' : 'Salvar'}}
            </button>
        </div>
    </div>

</div>