<app-toolbar-signer (atualizar)="atualizar()"></app-toolbar-signer>
<table  #table mat-table [dataSource]="dataSource" style="width: 100%;">
    <ng-container matColumnDef="opcoes">
      <th mat-header-cell *matHeaderCellDef >Opções</th>
      <td mat-cell *matCellDef="let row;" class="w-1">
        <button mat-button class="mat-button" [matMenuTriggerFor]="menu">
          <span class="mat-button-wrapper">
              <i class="fas fa-tasks"></i>
          </span>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="visualizar(row)">
            <i class="fas fa-edit"></i>
            Informações
          </button>
          <button *ngIf="row.tipo==='testemunha'" mat-menu-item (click)="vincular(row)">
            <i class="fas fa-plus"></i>
            Vincular com documentos root
          </button>
          <button mat-menu-item (click)="excluir(row)">
            <i class="fas fa-trash"></i>
            Excluir
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let row;">{{row.email}}</td>
    </ng-container>

    <ng-container matColumnDef="telefone">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let row;">{{row.telefone}}</td>
    </ng-container>

    <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let row;">{{row.nome}}</td>
    </ng-container>
    
    <ng-container matColumnDef="documento">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let row;">{{row.documento}}</td>
    </ng-container>

    <ng-container matColumnDef="nascimento">
        <th mat-header-cell *matHeaderCellDef>Nascimento</th>
        <td mat-cell *matCellDef="let row;">{{row.nascimento | date:'dd/MM/yyyy'}}</td>
    </ng-container>
    
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let row;">{{row.tipo==='testemunha'?'Testemunha':'Representante'}}</td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 30, 50, 100]" [length]="lista?.totalElements">
</mat-paginator>