import { ParseError } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidacoesService {
  constructor() { }

  validaCNPJ(valor: string) {
    valor = valor.replace(/[^0-9]/g, '');
    if (!valor) {
      return false;
    }
    const todosIguais = valor.split('').filter(a => a === valor[0]).length === valor.length;
    if (todosIguais) {
      return false;
    }
    let tamanho = valor.length - 2;
    let numeros = valor.substring(0, tamanho);
    const digitos = valor.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (`${resultado}` !== digitos.charAt(0)) {
      return false;
    }
    tamanho = tamanho + 1;
    numeros = valor.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (`${resultado}` !== digitos.charAt(1)) {
      return false;
    }

    return true;
  }

  validaCPF(valor) {
    if (!valor) {
      return false;
    }
    valor = valor.replace(/[^0-9]/g, '');
    const todosIguais = valor.split('').filter(a => a === valor[0]).length === valor.length;
    if (todosIguais) {
      return false;
    }
    let soma = 0;
    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(valor.substring(i - 1, i), 10) * (11 - i);
    }
    let resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(valor.substring(9, 10), 10)) {
      return false;
    }
    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(valor.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }
    if (resto !== parseInt(valor.substring(10, 11), 10)) {
      return false;
    }

    return true;
  }

  verificaBandeiraCartao(cardNumber: string) {
    if (/^(4011|4312|4389|4514|4576|5041|5067|5090|6500|6516|6521|6522|6550)/.test(cardNumber)) {
      return 'Elo';
    } else if (cardNumber.startsWith('4')) {
      return 'Visa';
    } else if (/^5[1-5]/.test(cardNumber) || /^2[2-7]/.test(cardNumber)) {
      return 'Mastercard';
    } else if (/^3[47]/.test(cardNumber)) {
      return 'Amex';
    } else if (/^(6062|3841|6370|6371|6372)/.test(cardNumber)) {
      return 'Hipercard';
    } else {
      return 'invalido';
    }
  }
}
