import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListaNotificacaoDTO } from '@model/lista-notificacao-dto.model';
import { PageResponse } from '@model/page-response.model';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoEndpointService {
  notificacaoVisualizadaSource = new BehaviorSubject<number>(0);
  notificacaoVisualizada$ = this.notificacaoVisualizadaSource.asObservable();

  unreadNotificacoesSource = new BehaviorSubject<
    PageResponse<ListaNotificacaoDTO>
  >(null);
  unreadNotificacoesList$ = this.unreadNotificacoesSource.asObservable();

  newNotificacaoSource = new Subject<ListaNotificacaoDTO>();
  newNotificacao$ = this.newNotificacaoSource.asObservable();

  constructor(private client: HttpClient) {}

  //  /transferencia/lista?page=[integer]&size=[integer]
  listar(arg?: {
    order?: string;
    page?: number;
    query?: string;
    size?: number;
    lida?: boolean;
  }): Observable<PageResponse<ListaNotificacaoDTO>> {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.query) {
      params = params.append('q', `${arg.query}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    if (arg.lida != undefined) {
      params = params.append('is-lida', `${arg.lida}`);
    }

    const url = '/notificacao';

    return this.client
      .get<PageResponse<ListaNotificacaoDTO>>(url, { params })
      .pipe(
        tap((response) => {
          if (arg.lida === false) {
            this.unreadNotificacoesSource.next(response);
          }
        }),
        catchError(_ => {
          return of(null);
        })
      );
  }

  novaNotificacao(data): Observable<any> {
    const url = '/notificacao';
    return this.client.post(url, data);
  }

  getCorporacao(): Observable<any> {
    const url = '/usuario/corporacoes';
    return this.client.get(url);
  }

  alterarNotificacaoParaVisualizado(notificacaoId): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', notificacaoId);
    const url = '/notificacao';
    return this.client.patch(url, { id: notificacaoId });
  }
}
