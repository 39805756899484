<div mat-dialog-content class="div-body">
    <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
        <div class="row" style="justify-content: center;">
            <div class="col-12" style="display: flex;
                flex-direction: column;
                justify-content: space-between;">
                <div class="card">
                    <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div class="row" style="justify-content: center;">
                        <div class="col-12" style="display: flex;
                        flex-direction: column;
                        height: 492px;
                        justify-content: space-between;">
                            <div class="card">

                                <div class="card-body" *ngIf="!processando">
                                    <div class="box-body">
                                    </div>
                                </div>
                                <h2 mat-dialog-title class="titulo-modal">
                                    INFORMAÇÕES DA PORTABILIDADE
                                </h2>
                                <div>
                                    <!-- <div class="box-body info-modal">
                            Você tem até dia <span class="realce">{{ data.data?.dtHrLimiteResolucao | date:'dd/MM/yyyy
                                HH:mm' }}</span> para
                            a aceitar a portabilidade no banco <span class="realce">{{ data.data.banco?.nomeReduzido
                                }}</span>
                        </div> -->
                                </div>
                                <div>
                                    <div class="box-body info-subtexto">
                                        Confirme sua portabilidade e começe a usar sua chave na instituição <span
                                            class="realce" style="font-weight: 400;font-style: italic;">{{ env.nomeApp
                                            }}</span>!
                                    </div>
                                </div>

                                <div class="box-body info-modal comprovante"
                                    style="display: flex;justify-content: space-between; background-color: white;">

                                    <div class="comprovante" style="width: 100%;">
                                        <div style="display: flex;
                                justify-content: space-around; padding-top: 8px;">
                                            <div>
                                                <i class="fa-brands fa-pix"
                                                    style="font-size: 30px; margin-left: 20px;"></i>
                                                <span style="padding-left: 10px;
                                    font-weight: 500;">INFORMAÇÕES DA PORTABILIDADE</span>
                                            </div>
                                        </div>
                                        <div id="linha">
                                            <p>CPF/CNPJ do solicitante: {{ data.data?.cpfCnpj }}</p>
                                            <p>Chave pix: {{ data.data?.chave }}</p>
                                            <p>Agência: {{ data.data?.nrAgencia.padStart(4, '0') }} </p>
                                            <p>Conta: {{ data.data?.nrConta.slice(0, -1) + ' - ' +
                                                data.data?.nrConta.slice(-1) }}
                                            <p>Status: {{ checaStatus(data.data?.stReivindicacao) }}</p>
                                            <br>
                                            <div id="linha-vertical">
                                            </div>
                                        </div>
                                        <div style="margin: auto; padding-bottom: 5px;"
                                            *ngIf="data.data?.stReivindicacao != 2">
                                            <span class="realce">Aprove a baixo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; justify-content: center;">
                                <div class="box-body div-btn"
                                    style="display: flex;justify-content: space-evenly; width: 100%;">
                                    <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                                        (click)="cancelarReivindicacao()" style="width: 10vw;"
                                        [disabled]="processando || data.data?.stReivindicacao == 2">
                                        <span style="font-size: 16px; width: 100%">REJEITAR</span>
                                    </button>
                                    <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                                        (click)="confirmarReivindicacao()" style=" width: 10vw;"
                                        [disabled]="data.data?.stReivindicacao == 2 || processando">
                                        <span style="font-size: 16px; width: 160px;">CONFIRMAR</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>