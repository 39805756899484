import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoDocumento',
  pure: false
})
export class FormatoDocumentoPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'uploadDeArquivo':
        return 'Upload de arquivo';
      case 'assEletronica':
        return 'Assinatura eletrônica';
      case 'assPreencher':
        return 'Preencher e assinar';
      case 'assSemiEletronica':
        return 'Assinatura Semi-eletrônica'
        
    }
  }

}
