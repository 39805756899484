<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    INDICAR CONTATO
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <form [formGroup]="form" (ngSubmit)="salvarTexto()">

    <div mat-dialog-content class="mat-dialog-content-responsive">
      <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
        <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="mudaCor input-size row "
          style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; ">

          <div class="card-body">
            <div class="first-container">
              <h4 class="color-title">Imagem</h4>
              <div class="publicidade-content">
                <div class="publicidade-card">
                  <div fxFlex="100" style="text-align: center;">
                    <div>
                      <div style="display:flex; justify-content: center;">
                        <a href="{{ imagem?.href }}" target="_blank">
                          <img *ngIf="imagem?.base64" style="max-height: 100%; height: 200px;"
                            src="data:image/png;base64,{{ imagem?.base64 }}">
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;width: 100%;
                          justify-content: center; width: 100%;">
                <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px;border-radius: 4px;
                        margin: 0;">
                  <button type="button" class="btn btn-default next" id="color-btn" (click)="abreCropper()">Mudar
                    imagem</button>
                </span>
              </div>
            </div>

            <div class="first-container">
              <h4 class="color-title">Texto</h4>

              <div fxFlex="100" style="text-align: center;">
                <div>
                  <div class="" style="display:flex; justify-content: center;">
                    <mat-form-field appearance="outline">
                      <mat-label>Texto</mat-label>
                      <textarea matInput rows="8" formControlName="texto" maxlength="400"
                        placeholder="Escreva aqui o texto"></textarea>
                    </mat-form-field>
                  </div>

                </div>
              </div>

              <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;width: 100%;
                            justify-content: center; width: 100%;">
                <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px;border-radius: 4px;
                          margin: 0;">
                  <button class="btn btn-default next" id="color-btn">Mudar texto</button>
                </span>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </form>

</div>