import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  ArquivoEmpresaDTO,
  formArquivoEmpresaDTO,
} from '@model/pessoa/arquivo-empresa-dto.model';
import Swal from 'sweetalert2';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';

@Component({
  selector: 'app-upload-arquivos',
  templateUrl: './upload-arquivos.component.html',
  styleUrls: ['./upload-arquivos.component.less'],
})
export class UploadArquivosComponent implements OnInit {
  form: FormArray;

  @Input()
  requiredText: string;

  @Input()
  subTitle: string;

  @Input()
  disabled: boolean = false;

  @Input()
  files: ArquivoEmpresaDTO[];

  @Input()
  ehDocumentoRoot: boolean = false;

  @Input()
  somentePdf: boolean = false;

  @Input()
  multipleFiles: boolean = false;

  @Input()
  index: number;

  @Output()
  public outputFiles: EventEmitter<ArquivoEmpresaDTO[]> = new EventEmitter();

  usuarioLogado;

  constructor(private autenticacao: AutenticacaoEndpointService) {
    this.usuarioLogado = this.autenticacao.regraEmpresa.usuario;
  }

  ngOnInit() {
    this.form = new FormArray([]);
    if (this.files && this.files.length > 0) {
      for (let file of this.files) {
        const value = formArquivoEmpresaDTO();
        value.patchValue(file);
        this.form.push(value);
        this.update();
      }
    }
    if (this.files && this.files.length > 0) {
      const aux = document.getElementById('upload-disabled' + this.index);
      if (aux) {
        aux.style.pointerEvents = 'none';
        aux.style.marginRight = '20px';
      }
    }
  }

  ngOnDestroy() {}

  onFileDropped(event) {
    this.addToFileList(event.dataTransfer.files as Array<File>);
  }

  onSelectFile(event) {
    this.addToFileList(event.target.files);
  }

  addToFileList(files: Array<File>) {
    if (this.form && this.form.value) {
      if (this.form.value.length === 0) {
        const aux = document.getElementById('upload-disabled' + this.index);
        if (aux) {
          aux.style.marginRight = '20px';
        }
      }
      if (this.form.value.length > 0) {
        Swal.fire(
          'Atenção',
          'Não é possível acrescentar mais um arquivo a esse documento.',
          'info'
        );
        return;
      }
    }
    if (files.length > 1 && !this.multipleFiles) {
      Swal.fire('Atenção', 'Selecione somente um único arquivo.', 'info');
      return;
    }
    for (const item of files) {
      if (
        (this.somentePdf && item.type !== 'application/pdf') ||
        (!item.type.includes('image/') &&
          item.type !== 'application/pdf' &&
          item.type !== 'application/msword' &&
          item.type !==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      ) {
        Swal.fire(
          'Aviso',
          'O arquivo selecionado possui formato inválido.',
          'warning'
        );
        break;
      }

      const reader = new FileReader();

      reader.readAsDataURL(item);

      reader.onload = (event: any) => {
        const base64 = event.target.result.substring(
          event.target.result.lastIndexOf(',') + 1,
          event.target.result.length
        );
        const value = formArquivoEmpresaDTO();
        value.patchValue({
          locale: 'UTF-8',
          base64: base64,
          mimeType: item.type,
          nome: item.name,
          tamanho: `${item.size}`,
          excluir: false,
        });
        this.form.push(value);
        this.update();
      };
    }
  }

  update() {
    this.outputFiles.emit(this.form.value);
  }

  deleteFile(file) {
    Swal.fire({
      title: 'Atenção',
      text: 'Tem certeza que deseja apagar este arquivo?',
      icon: 'info',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result['isConfirmed']) {
        if (
          !file.usuarioUpload ||
          file.usuarioUpload == this.usuarioLogado.id ||
          !this.usuarioLogado.email.includes('root') ||
          this.ehDocumentoRoot
        ) {
          if (this.form && this.form.value) {
            if (this.form.value.length > 0) {
              const aux = document.getElementById(
                'upload-disabled' + this.index
              );
              if (aux) {
                aux.style.pointerEvents = 'auto';
              }
            }
          }
          file.excluir = true;
          const index = this.form.value.indexOf(file);
          this.form.removeAt(index);
          this.update();
        } else {
          Swal.fire(
            'Atenção',
            'Esse arquivo não pode ser excluído.',
            'warning'
          );
        }
      }
    });
  }
}
