import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectiveModule } from '@app/directive/directive.module';
import { UploadArquivoModalComponent } from './upload-arquivo-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [UploadArquivoModalComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    DirectiveModule,
  ],
  exports: [UploadArquivoModalComponent],
})
export class UploadArquivoModalModule { }
