<p class="super-title">Confirme seu e-mail e celular</p>

<div class="box-body" [formGroup]="form">
  <p style="margin-bottom: 0;">O primeiro passo para começar a utilizar o {{env.nomeApp}} é ativando a sua conta.</p>
  <img src="assets/img/icons/pluga-bank/logos/logo-colorido.png" class="logo" />
  <p>Insira abaixo o código de ativação (token) que enviamos no celular (SMS) e no e-mail informados.</p>
  <div class="row">
    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Qual o código do celular?</mat-label>
        <input matInput type="text" formControlName="codcelular" mask="000000" required/>
        <mat-error>{{ getErrorMessage(form, "codcelular") }}</mat-error>
      </mat-form-field>
      <p *ngIf="podeReenviarSms" class="obs">Não recebeu o SMS ou seu celular está incorreto? <a (click)="novoCodigo('telefone')"
          style="cursor: pointer;">Clique aqui</a></p>
      <p *ngIf="!podeReenviarSms" class="obs">Enviado para ({{usuarioLogado?.celular?.ddd}}) {{usuarioLogado?.celular?.numero}}.Você poderá solicitar um novo código em {{ tempoReenvio }} minutos
        <br />
        {{ bloqueiaEnviarSmsAte | date: "dd/MM/yyyy HH:mm" }}</p>
      <p [hidden]="podeReenviarSms" class="obs" style="color: #3d8686 !important">SMS enviado!</p>
    </div>
    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Qual o código do e-mail?</mat-label>
        <input matInput type="text" formControlName="codemail" mask="000000" required/>
        <mat-error>{{ getErrorMessage(form, "codemail") }}</mat-error>
      </mat-form-field>
      <p *ngIf="podeReenviarEmail" class="obs">Não recebeu o e-mail ou seu e-mail está incorreto? <a (click)="novoCodigo('email')"
          style="cursor: pointer;">Clique aqui</a></p>
      <p *ngIf="!podeReenviarEmail" class="obs">Enviado para {{usuarioLogado.email}}.Você poderá solicitar um novo código em {{ tempoReenvio }} minutos
        <br />
        {{ bloqueiaEnviarEmailAte | date: "dd/MM/yyyy HH:mm" }}</p>
      <p [hidden]="podeReenviarEmail" class="obs" style="color: #3d8686 !important">E-mail enviado!</p>
    </div>
  </div>
  <p class="mt-3">Defina abaixo perguntas de segurança para proteger sua conta.</p>
  <div *ngFor="let perguntaSeguranca of formPerguntasSeguranca.controls, let i=index" [formGroup]="perguntaSeguranca" class="col-12 mt-4 d-flex flex-column" style="gap:0.8rem">
    <mat-form-field style="width: 85%; align-self: center;" appearance="outline">
      <mat-label>Pergunta de segurança {{i+1}}</mat-label>
      <mat-select formControlName="pergunta">
        <mat-option *ngFor="let pergunta of perguntasSeguranca; let i=index" [value]="i">{{pergunta}}</mat-option>
      </mat-select>
      <mat-error>{{ getErrorMessage(perguntaSeguranca, "pergunta") }}</mat-error>
    </mat-form-field>
    <mat-form-field style="width: 85%; align-self: center;" appearance="outline">
      <mat-label>Resposta {{i+1}}</mat-label>
      <input matInput type="text" formControlName="resposta">
      <mat-error>{{ getErrorMessage(perguntaSeguranca, "resposta") }}</mat-error>
    </mat-form-field>
    <div *ngIf="i > 0" style="position: absolute; right: 3rem; top: 0.2rem">
      <i class="fas fa-trash icones"
        title="Excluir"
        (click)="removePergunta(i)"
        style="color:red;"></i>
    </div>
  </div>
  <div *ngIf="formPerguntasSeguranca.length < 3" class="add d-flex justify-content-around">
    <span (click)="addPergunta()" style="padding-left: 20px;">+ adicionar nova pergunta</span>
    <span class="col-6"></span>
  </div>
</div>