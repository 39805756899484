import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@project/src/environments/environment.default';

@Component({
  selector: 'app-importacoes',
  templateUrl: './importacoes.component.html',
  styleUrls: ['./importacoes.component.less']
})
export class ImportacoesComponent implements OnInit {
  env = environment;
  constructor(
    public dialogRef: MatDialogRef<ImportacoesComponent>
  ) { }

  ngOnInit() {
  }
  fecha(): void {
    this.dialogRef.close();
  }
}
