import { Component, OnInit, OnDestroy, Injector, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { IPessoaService, PessoaServiceBuilder } from '@service/pessoa-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { startWith, map } from 'rxjs/operators';
import { PessoaEditarComponent } from '@project/src/app/pages/interno/pessoa/pessoa-editar/pessoa-editar.component';
import { MatDialog } from '@angular/material/dialog';
import { AutenticacaoEndpointService } from "@service/autenticacao-endpoint.service";
import { environment } from '@project/src/environments/environment.default';
import { ConsultasEndpointService } from '@project/src/app/service/consultas-endpoint.service';
import { ValidacoesService } from '@project/src/app/service/validacoes.service';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { ChavePixDTO } from '@project/src/app/model/financeiro/transferencia/chave-pix-dto-model';

@Component({
  selector: 'app-stepper1',
  templateUrl: './stepper1.component.html',
  styleUrls: ['./stepper1.component.less']
})
export class Stepper1Component implements OnInit, OnDestroy {
  private service: IPessoaService;
  subscription: Subscription;

  contatosList: any = [];
  contatosBeneficiarioFinalList: any = [];
  filtroContatos: Observable<any[]>;
  filtroContatosBeneficiarioFinal: Observable<any[]>;
  numeroContato: string;
  numeroContatoBeneficiarioFinal: string;
  contatoId: number;
  contatoBeneficiarioFinalId: number;
  buscar: string = '';
  buscarBeneficiarioFinal: string = '';
  listaChavesPix: ChavePixDTO[] = [];

  @Input()
  public form: FormGroup;
  @Input()
  public data: any = [];
  @Input()
  public dataBeneficiarioFinal: any = [];
  @Input()
  public processando: boolean;
  @Input()
  public isEdicao: boolean = false;

  contador: number = 0;
  emails: any = [];
  emailsBeneficiarioFinal: any = [];
  editaContato: boolean = false;
  operador: boolean = false;
  pixDesabilita: boolean = false;
  empresaId: number;
  empresaLogada: any;
  bloqueiaFuncionalidades: boolean = false;
  areaPix: boolean = false;


  env = environment;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private injector: Injector,
    private dialog: MatDialog,
    private consulta: ConsultasEndpointService,
    private validacoesService: ValidacoesService,
    private pixService: PixEndpointService,
  ) {
    this.autenticacao.sessao.authorities.forEach(element => {
      if (element.permissao === 'ROLE_OPERADOR') {
        this.operador = true;
      }
    });
    this.autenticacao.sessao.authorities.forEach(element => {
      if (this.operador) {
        if (element.permissao === 'ROLE_EMPRESA_PESSOAS_VISUALIZAR') {
          this.editaContato = true;
        }
      } else {
        this.editaContato = true;
      }
    });
  }

  ngOnInit() {

    this.empresaId = this.autenticacao.regraEmpresa.empresa.id;
    this.areaPix = this.autenticacao.regraEmpresa.empresa.configuracao.areaPix;

    this.form.addControl('tipoCobranca', new FormControl(1)); // 1-fixa, 2-assinatura, 3-parcelada
    this.form.addControl('pessoa', new FormControl('', [Validators.required]));
    this.form.addControl('enviarEmail', new FormControl(true));
    this.form.addControl('emailNotificacao', new FormArray([]));
    this.form.addControl('enviarEmail', new FormControl(true));
    this.form.addControl('notificacaoSms', new FormControl(''));
    this.form.addControl('beneficiarioFinal', new FormGroup({
      contato: new FormControl('', [Validators.required]),
      notificacaoSms: new FormControl(''),
      emailNotificacao: new FormArray([]),
      documento: new FormControl('', [Validators.required]),
      nome: new FormControl('', [Validators.required]),
      razaoSocial: new FormControl(''),
      tipoDocumento: new FormControl('')
    }));
    this.form.get('beneficiarioFinal').get('documento').setValidators([this.verificaDocumento(this.form)]);
    this.getContatos();
    this.getContatosBeneficiarioFinal();
    this.dataBeneficiarioFinal = null;
    this.empresaLogada = this.autenticacao.regraEmpresa;


    this.form.addControl('contatoOuPix', new FormControl(1));
    this.form.addControl('isPix', new FormControl(false));
    this.form.addControl('temChavePix', new FormControl(false));


    if ((this.empresaLogada.empresa.numIdentcBenfcrio === null || this.empresaLogada.empresa.numIdentcBenfcrio == "0") &&
      this.empresaLogada.empresa.tipoConta !== "contaDigitalS") {
      this.bloqueiaFuncionalidades = true;
    }

    if (this.env.modulos.pix.gerarQrCode) {
      this.listarChaves();
    }

    if (this.env.modulos.pix.gerarQrCode && (this.empresaLogada.empresa.numIdentcBenfcrio === null || this.empresaLogada.empresa.numIdentcBenfcrio == "0") &&
      this.empresaLogada.empresa.tipoConta !== "contaDigitalS") {
      this.form.get('isPix').setValue(true);
      this.form.get('contatoOuPix').setValue(2);
      this.form.get('tipoCobranca').setValue(4);
      this.pixDesabilita = true;
    }

    if (this.isEdicao) {
      this.bloqueiaFuncionalidades = true;
    }

    // if ((this.empresaLogada.empresa.numIdentcBenfcrio === null || this.empresaLogada.empresa.numIdentcBenfcrio == "0")) {
    //   this.bloqueiaFuncionalidades = true;
    // }
  }

  validacaoNotificacaoEmail(form: FormGroup) {
    return (control: AbstractControl) => {
      const enviarEmail = form.get('enviarEmail').value;

      if (control.invalid) {
        return;
      }

      if (enviarEmail === true) {
        this.form.get('emailNotificacao').enable();
        this.form.get('emailNotificacao').setErrors({ required: true });
      } else if (enviarEmail === false) {
        this.form.get('emailNotificacao').disable();
      }

      this.form.get('emailNotificacao').setErrors(null);
      return null as ValidationErrors;
    }
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido'
          : form.get(field).hasError('minlength')
            ? 'Número de celular inválido'
            : form.get(field).hasError('maxlength')
              ? 'Número de celular inválido'
              : form.get(field).hasError('email')
                ? 'Email inválido'
                : ''
    }
  }

  private _filter(value: any): any[] {
    if (this.isString(value)) {
      const filterValue = value;

      if (!isNaN(Number(filterValue))) {
        return this.contatosList.filter(contato =>
          contato.pessoa.documento.toLowerCase().includes(filterValue)
        );
      } else {
        return this.contatosList.filter(contato =>
          (contato.pessoa.razaoSocial || contato.pessoa.nome).toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }

  private _filterBeneficiarioFinal(value: any): any[] {
    if (this.isString(value)) {
      const filterValue = value;

      if (!isNaN(Number(filterValue))) {
        return this.contatosBeneficiarioFinalList.filter(contato =>
          contato.pessoa.documento.toLowerCase().includes(filterValue)
        );
      } else {
        return this.contatosBeneficiarioFinalList.filter(contato =>
          (contato.pessoa.razaoSocial || contato.pessoa.nome).toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  displayFn(contato): any {
    if (!Array.isArray(contato)) {
      return contato
        ? (contato?.pessoa?.razaoSocial || contato?.pessoa?.nome) + ' - ' + contato?.pessoa?.documento
        : contato;
    }
  }

  setTipoCobranca(tipo) {
    if (this.form.get('tipoCobranca').enabled === true) {
      this.form.get('tipoCobranca').setValue(tipo);
    }

    if(tipo === 1) {
      this.setContatoOuPix(1)
      this.form.get('isPix').setValue(false);
      this.form.get('tipoCobranca').setValue(tipo)
    }

    if(tipo === 2) {
      this.setContatoOuPix(1)
      this.form.get('isPix').setValue(false);
      this.form.get('tipoCobranca').setValue(tipo)
    }

    if(tipo === 3) {
      this.setContatoOuPix(1)
      this.form.get('isPix').setValue(false);
      this.form.get('tipoCobranca').setValue(tipo)
    }

    if(tipo === 4) {
      this.setContatoOuPix(2)
      this.form.get('tipoCobranca').setValue(tipo)
    }
  }

  setContatoOuPix(tipo) {
    if (this.form.get('contatoOuPix').enabled === true) {
      this.form.get('contatoOuPix').setValue(tipo);

      if (tipo === 1) {
        this.form.get('isPix').setValue(false);
        this.pixDesabilita = false;
      } else {
        this.form.get('isPix').setValue(true);
        this.pixDesabilita = true;
        this.form.get('emailNotificacao').reset();
        this.form.get('notificacaoSms').reset();
        this.form.get('tipoCobranca').setValue(4);
      }
    }
  }

  async getNomeNumeroContato(contato) {
    let telefone;
    this.emails = [];
    this.form.get('emailNotificacao').reset();
    for (const element of contato.pessoa.telefones) {
      if (element.principal === true) {
        telefone = element.ddd + '' + element.numero;
      }
    }
    this.numeroContato = telefone;
    this.form.get('notificacaoSms').setValue(telefone);
    let arrayEmail = [];

    for (const element of contato.pessoa.emails) {
      if (element.principal) { arrayEmail.push({ email: element.email }); }

    }

    this.emails = arrayEmail;
    this.contatoId = contato.id;
    this.form.markAllAsTouched();
  }

  async getNomeNumeroContatoBeneficiarioFinal(contato) {
    let telefone;
    this.emailsBeneficiarioFinal = [];
    this.form.get('beneficiarioFinal').get('emailNotificacao').reset();
    for (const element of contato.pessoa.telefones) {
      if (element.principal === true) {
        telefone = element.ddd + '' + element.numero;
      }
    }
    this.numeroContatoBeneficiarioFinal = telefone;
    this.form.get('beneficiarioFinal').get('notificacaoSms').setValue(telefone);
    let arrayEmail = [];

    for (const element of contato.pessoa.emails) {
      if (element.principal) { arrayEmail.push({ email: element.email }); }
    }

    this.emailsBeneficiarioFinal = arrayEmail;

    // Dados do beneficiário final 
    this.contatoBeneficiarioFinalId = contato.id;
    this.form.get('beneficiarioFinal').get('documento').setValue(contato.pessoa.documento);
    this.form.get('beneficiarioFinal').get('nome').setValue(contato.pessoa.nome);
    this.form.get('beneficiarioFinal').get('razaoSocial').setValue(contato.pessoa.razaoSocial);
    this.ehPessoaJuridica();

    this.form.markAllAsTouched();
  }

  nomeOuRazaoSocial(pessoa) {
    if (pessoa) {
      const documentoInput = pessoa.documento.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11 && documentoInput.length != 0) {
        return pessoa.nome;
      } else {
        return pessoa.razaoSocial;
      }
    }
    return '';
  }

  criarEmpresa() {
    const dialogRef = this.dialog.open(PessoaEditarComponent, {
      width: '1240px',
      data: {
        tipo: 'contato',
        detalhe: 'cobranca-avulsa',
        contatoId: this.contatoId
      },
      backdropClass: 'close-button-position'
    });

    this.subscription = dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.getContatos();
          if (response.salvou === true && response.pessoaCadastrada) {
            this.form.get('pessoa').setValue(response.pessoaCadastrada);
            this.getNomeNumeroContato(response.pessoaCadastrada);
            this.form.get('pessoa').value.id = response.contatoId;
          }
        }
      });
  }

  criarContatoBeneficiarioFinal() {
    const dialogRef = this.dialog.open(PessoaEditarComponent, {
      width: '1240px',
      data: {
        tipo: 'contato',
        detalhe: 'cobranca-avulsa',
        contatoId: this.contatoId
      },
      backdropClass: 'close-button-position'
    });

    this.subscription = dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.getContatosBeneficiarioFinal();
          if (response.salvou === true && response.pessoaCadastrada) {
            this.form.get('beneficiarioFinal').get('contato').setValue(response.pessoaCadastrada);
            this.form.get('beneficiarioFinal').get('documento').setValue(response.pessoaCadastrada.pessoa.documento);
            this.form.get('beneficiarioFinal').get('nome').setValue(response.pessoaCadastrada.pessoa.nome);
            this.form.get('beneficiarioFinal').get('razaoSocial').setValue(response.pessoaCadastrada.pessoa.razaoSocial);
            this.form.get('beneficiarioFinal').get('contato').value.id = response.contatoId;
            this.getNomeNumeroContatoBeneficiarioFinal(response.pessoaCadastrada);
          }
        }
      });
  }

  applySearch(filterValue: string) {
    this.buscar = filterValue;
    this.getContatos();
  }

  applySearchBeneficiarioFinal(filterValue: string) {
    this.buscarBeneficiarioFinal = filterValue;
    this.getContatosBeneficiarioFinal();
  }

  getContatos() {
    this.service = PessoaServiceBuilder.getInstance(this.injector, 'contato');
    let page = 0, size = 30;
    let q: string;
    q = this.buscar;

    this.service
      .getAllPessoa({ page, size, q })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.contatosList = response.content;
          this.filtroContatos = this.form.get('pessoa').valueChanges.pipe(
            startWith(''),
            map(contato => this._filter(contato))
          );
        },
        error => {
        }
      );
  }

  getContatosBeneficiarioFinal() {
    this.service = PessoaServiceBuilder.getInstance(this.injector, 'contato');
    let page = 0, size = 30;
    let q: string;
    q = this.buscarBeneficiarioFinal;

    this.service
      .getAllPessoa({ page, size, q })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.contatosBeneficiarioFinalList = response.content;
          this.filtroContatosBeneficiarioFinal = this.form.get('beneficiarioFinal').get('contato').valueChanges.pipe(
            startWith(''),
            map(contato => this._filterBeneficiarioFinal(contato))
          );
        },
        error => {
        }
      );
  }

  adicionarOuRemoverBeneficiarioFinal() {
    const arr = <FormArray>this.form.get('beneficiarioFinal').get('emailNotificacao');
    arr.controls = [];
    this.emailsBeneficiarioFinal = [];
    this.dataBeneficiarioFinal = null;
    if (this.form.get('beneficiarioFinal').enabled) {
      this.form.get('beneficiarioFinal').reset();
      this.form.get('beneficiarioFinal').disable();
    } else {
      this.form.get('beneficiarioFinal').enable();
      this.form.get('beneficiarioFinal').get('documento').setValidators([this.verificaDocumento(this.form)]);
      this.getContatosBeneficiarioFinal();
    }
  }

  ehPessoaJuridica() {
    if (this.form.get('beneficiarioFinal').get('documento').value) {
      const documentoInput = this.form.get('beneficiarioFinal').get('documento').value.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11) {
        this.form.get('beneficiarioFinal').get('razaoSocial').reset();
        this.form.get('beneficiarioFinal').get('razaoSocial').clearValidators();
        this.form.get('beneficiarioFinal').get('razaoSocial').updateValueAndValidity();
        this.form.get('beneficiarioFinal').get('tipoDocumento').setValue("01");
        return false;
      } else {
        this.form.get('beneficiarioFinal').get('razaoSocial').setValidators([Validators.required]);
        this.form.get('beneficiarioFinal').get('tipoDocumento').setValue("02");
        return true;
      }
    } else {
      return false;
    }
  }

  verificaDocumento(form: FormGroup) {
    return (control: AbstractControl) => {
      const documento = this.form.get('beneficiarioFinal').get('documento').value;
      if (this.ehPessoaJuridica()) {
        if (!this.validacoesService.validaCNPJ(documento)) {
          return { cnpjinvalido: true } as ValidationErrors;
        }
        return null as ValidationErrors;
      } else {
        if (!this.validacoesService.validaCPF(documento)) {
          return { cpfinvalido: true } as ValidationErrors;
        }
        return null as ValidationErrors;
      }
    }
  }

  listarChaves() {
    this.pixService.listarChaves(this.empresaId).subscribe((res) => {
      this.listaChavesPix = res;
      if (this.listaChavesPix.length > 0) {
        this.form.get('temChavePix').setValue(true);
      } else {
        this.form.get('temChavePix').setValue(false);
      }
    }, err => {
      console.log(err);
    });
  }

  ngOnDestroy() { }
}
