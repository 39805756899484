import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cpfCnpj',
	pure: false
})
export class CpfCnpjPipe implements PipeTransform {

	constructor() { }

	transform(value: string) {
		if (value) {
			value = value.replace(/[^0-9]+/gim, '');
			if (value.length <= 11) {
				return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})(.*)/, '$1.$2.$3-$4$5');
			}
			return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})(.*)/, '$1.$2.$3/$4-$5$6');
		}
		return value;
	}

}
