import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { INgxLoadingConfig } from './ngx-loading-config';
import { NgxLoadingComponent } from './ngx-loading.component';
import { NgxLoadingService } from './ngx-loading.service';
export class NgxLoadingModule {
/**
 * @param {?} loadingConfig
 * @return {?}
 */
static forRoot(loadingConfig: INgxLoadingConfig): ModuleWithProviders {
    return {
      ngModule: NgxLoadingModule,
      providers: [{ provide: 'loadingConfig', useValue: loadingConfig }]
    };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [CommonModule],
  declarations: [NgxLoadingComponent],
  exports: [NgxLoadingComponent],
  providers: [NgxLoadingService]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function NgxLoadingModule_tsickle_Closure_declarations() {
/** @type {?} */
NgxLoadingModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgxLoadingModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
