import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RootEndpointService } from '../../service/root-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-personalizacao-investimentos',
  templateUrl: './personalizacao-investimentos.component.html',
  styleUrls: ['./personalizacao-investimentos.component.less']
})
export class PersonalizacaoInvestimentosComponent implements OnInit, OnDestroy {

  form: FormGroup;
  config: any;
  constructor(
    public dialogRef: MatDialogRef<PersonalizacaoInvestimentosComponent>,
    private rootService: RootEndpointService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      texto: new FormControl('', Validators.required)
    })
    this.rootService.getConfiguracoesSistema().subscribe((res) => {
      this.config = res
      if (res.textoInvestimentos)
        this.form.get('texto').setValue(res.textoInvestimentos)
    }, error => {
      console.error(error);
      Swal.fire('Erro', error.error, 'error');
    })
  }

  fecha() {
    this.dialogRef.close()
  }

  salvar() {
    this.config.textoInvestimentos = this.form.get('texto').value
    this.rootService.updateConfiguracoesSistema(this.config).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        Swal.fire('Succeso', 'Texto atualizado com sucesso', 'success')
          .then(() => this.fecha)
          .finally(() => window.location.reload())
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error.error, 'error');
      });
  }

  ngOnDestroy() { }
}
