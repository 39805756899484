import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { environment } from "@src/environments/environment.default";

@Component({
  selector: 'app-personalizacao-sistema',
  templateUrl: './personalizacao-sistema.component.html',
  styleUrls: ['./personalizacao-sistema.component.less']
})
export class PersonalizacaoSistemaComponent implements OnInit {
  env = environment;
  codigoPrimaria: string;
  codigoSecundaria: string;
  constructor(
    public dialogRef: MatDialogRef<PersonalizacaoSistemaComponent>
  ) { }

  ngOnInit() {
    var a = document.getElementById("corPrimaria")
    var b = document.getElementById("corSecundaria")

    this.codigoPrimaria = this.rgbToHex(window.getComputedStyle(a).backgroundColor);
    this.codigoSecundaria = this.rgbToHex(window.getComputedStyle(b).backgroundColor);
  }

  rgbToHex(rgb: string): string {
    const valores = rgb.replace(/[^\d,]/g, '').split(',');
    const r = parseInt(valores[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(valores[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(valores[2], 10).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`.toUpperCase();
  }


  fecha(): void {
    this.dialogRef.close();
  }
}
