import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class DealsignService {

    constructor(
        private client: HttpClient,
    ) { }


    getConfig(): Observable<any> {
        const url = '/dealsign/configuracao';
        return this.client.get(url);
    }

    setConfig(config): Observable<any> {
        const url = '/dealsign/configuracao';
        return this.client.post(url, config);
    }

    testar(config): Observable<any> {
        const url = '/dealsign/teste';
        return this.client.post(url, config);
    }

}