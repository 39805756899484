import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor() { }

  private atualizar = new Subject<any>();

  private atualizarPainelAdm = new Subject<any>();

  enviaUpdate(item: any){
    this.atualizar.next(item);
  }

  enviaUpdatePainelAdm(item: any){
    this.atualizarPainelAdm.next(item);
  }

  pegaUpdate() : Observable<any> {
    return this.atualizar.asObservable();
  }
  pegaUpdatePainelAdm() : Observable<any> {
    return this.atualizarPainelAdm.asObservable();
  }
}
