import { Pipe, PipeTransform } from '@angular/core';
import { UtilCaniveteService } from '@service/util/util-canivete.service';

@Pipe({
	name: 'filtro',
	pure: false
})
export class FiltroPipe implements PipeTransform {

	constructor(private util: UtilCaniveteService) { }

	transform(values: Array<any>, search: string | ((item: any) => boolean)) {
		if (values) {
			if (typeof search === 'string') {
				return values.filter(v => this.util.customFilter({ v }, search));
			}
			return values.filter(v => search(v));
		}
		return values;
	}

}
