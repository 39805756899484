<h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    ATENDIMENTO AO CLIENTE <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<form [formGroup]="form" class="d-flex flex-column justify-content-center" style="background-color: #fff;">
    <div class="d-flex flex-row justify-content-center align-items-start input-menor" style="gap: 1rem; margin: 2rem 1rem;">
        <mat-form-field appearance="outline" class="col-6">
            <mat-label>Token de atendimento</mat-label>
            <input type="text" matInput formControlName="token" mask="000000">
            <mat-error>{{ getErrorMessage('token') }}</mat-error>
        </mat-form-field>
        <button class="col-3 btn btn-default next" type="button" [disabled]="form.invalid" (click)="recuperarInformacoes()">Buscar</button>
    </div>
    <div *ngIf="usuario && corporacao && empresa" class="d-flex flex-column justify-content-center">
        <h2 style="margin-left: 2rem;">USUÁRIO</h2>
        <div class="campos-informacao">
            <div>
               <label>NOME</label>
                <p>{{usuario.nome}}</p> 
            </div>
            <div>
                <label>CORPORAÇÃO</label>
                <p>{{corporacao.nome}}</p>
            </div>
        </div>
        <div class="campos-informacao">
            <div>
                <label>E-MAIL</label>
                <p>{{usuario.email}}</p>
            </div>
            <div>
                <label>CELULAR</label>
                 <p>{{'(' + usuario.celular.ddd + ') ' + usuario.celular.numero}}</p> 
             </div>
        </div>
        <h2 style="margin-left: 2rem;" class="mt-3">EMPRESA</h2>
        <div class="campos-informacao">
            <div>
               <label>NOME EMPRESA</label>
                <p>{{empresa.pessoa.razaoSocial || empresa.pessoa.nome}}</p> 
            </div>
            <div>
                <label>DOCUMENTO</label>
                <p>{{empresa.pessoa.documento.length === 14 ? (empresa.pessoa.documento | mask: "00.000.000/0000-00") : (empresa.pessoa.documento | mask: "000.000.000-00") }}</p>
            </div>
        </div>
        <div class="mt-4 campos-informacao">
            <p style="font-weight: 400;">Token gerado em: {{ dataToken | date:'dd/MM/yyyy HH:mm'}}</p>
        </div>
    </div>
</form>