import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CodigoConvenioEndpointService } from '../../service/codigo-convenio-endpoint.service';
import { RootEndpointService } from '../../service/root-endpoint.service';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EmpresaDTO } from '../../model/pessoa/empresa-dto.model';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-codigo-convenio',
  templateUrl: './codigo-convenio.component.html',
  styleUrls: ['./codigo-convenio.component.less']
})
export class CodigoConvenioComponent implements OnInit {

  formNovo: FormGroup;
  formsVinculaCodigo: FormGroup;
  allEmpresas: EmpresaDTO[] = [];
  processando: boolean = false;
  filteredEmpresas: Observable<EmpresaDTO[]>;
  filteredCodigos: Observable<any[]>;
  isFormValidNovo: boolean = false;
  isFormValidVincula: boolean = false;
  allCodigosConvenio: any;
  objEmpresa: any = [];
  selectedEmpresa: any;

  constructor(
    public dialogRef: MatDialogRef<CodigoConvenioComponent>,
    public formBuilder: FormBuilder,
    public codigoConvenioService: CodigoConvenioEndpointService,
    public rootService: RootEndpointService
  ) { }

  ngOnInit() {
    this.novoCodigo();
    this.vinculaCodigo();
    this.listarTodasEmpresas();
    this.listarCodigos();


    this.formNovo.statusChanges.subscribe(() => {
      this.updateFormValidityNovo();
    });

    this.formsVinculaCodigo.statusChanges.subscribe(() => {
      this.updateFormValidityVincula();
    });

    this.filteredEmpresas = this.formsVinculaCodigo.get('empresa').valueChanges.pipe(
      startWith(''),
      map(value => this._filterEmpresas(value.toLowerCase()))
    );

    this.filteredCodigos = this.formsVinculaCodigo.get('codigo').valueChanges.pipe(
      startWith(''),
      map(value => this._filterCodigo(value.toLowerCase()))
    );

    this.selectedEmpresa = { label: '' };
  }

  onInputChangeEmpresa(): void {
    const query = this.formsVinculaCodigo.get('empresa').value;
    this.filteredEmpresas = this.formsVinculaCodigo.get('empresa').valueChanges.pipe(
      startWith(query),
      map(value => this._filterEmpresas(value))
    );
  }

  onInputChangeCodigo(): void {
    const query = this.formsVinculaCodigo.get('codigo').value;
    this.filteredCodigos = this.formsVinculaCodigo.get('codigo').valueChanges.pipe(
      startWith(query),
      map(value => this._filterCodigo(value.toLowerCase()))
    );

    this.filteredCodigos.subscribe(valores => {
    });
  }


  private _filterEmpresas(value: string): EmpresaDTO[] {
    const filterValue = value;
    return this.allEmpresas.filter(empresa =>
      (empresa.pessoa.nome || '').toLowerCase().includes(filterValue)
    );
  }

  private _filterCodigo(value: string): any[] {
    const filterValue = value.toLowerCase();

    if (!this.allCodigosConvenio) {
      return [];
    }

    return this.allCodigosConvenio.filter(codigo =>
      codigo.codigo.toLowerCase().includes(filterValue)
    );
  }

  novoCodigo(): void {
    this.formNovo = this.formBuilder.group({
      novoCodigo: new FormControl(null, [Validators.required, Validators.minLength(2)]),
    });
  }

  listarCodigos(): void {
    this.codigoConvenioService.listarCodigos()
      .subscribe(res => {
        this.allCodigosConvenio = res;
      }, error => {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: error.error,
        })
        console.log(error);
      })
  }

  vinculaCodigo(): void {
    this.formsVinculaCodigo = this.formBuilder.group({
      codigo: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      empresa: new FormControl(null, [Validators.required]),
      idEmpresa: new FormControl(null, [Validators.required]),
    });
  }

  criarCodigo(): void {
    const novoCodigo = this.formNovo.get('novoCodigo').value;
    this.codigoConvenioService.cadastrarCodigoConvenio(novoCodigo)
      .subscribe(res => {

        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Código criado com sucesso!',
          });
        }
        this.listarCodigos();
      }, error => {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: error.error,
        })
        console.log(error);
      })
  }

  listarTodasEmpresas() {
    this.processando = true;
    this.rootService.listarTodasEmpresas()
      .subscribe(res => {
        this.allEmpresas = res;
        this.processando = false;
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error,
        })
        console.log(error);
        this.processando = false;
      });
  }

  vincularCodigoNaEmpresa(): void {
    const codigo = this.formsVinculaCodigo.get('codigo').value;

    console.log(codigo);

    this.codigoConvenioService.vincularCodigoConvenio(this.selectedEmpresa.id, codigo)
      .subscribe(res => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Código vinculado com sucesso!',
          });
        }
      }, error => {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: error.error,
        })
        console.log(error);
      })
  }

  defineTipoConta(tipo) {
    switch (tipo) {
      case 'contaDigitalS':
        return "Conta DigitalS";
      case `contaCobrancaDigital`:
        return "Conta Cobrança Digital";
      case `contaCorrenteDigital`:
        return "Conta Corrente";
      case `contaVinculadaDigital`:
        return "Conta Vinculada";
      case `contaEscrowDigital`:
        return "Conta Escrow";
      case `contaBloqueioJudicial`:
        return "Conta Bloqueio";
      case `contaSalarioDigital`:
        return "Conta Salario";
      case `contaServicoDigital`:
        return "Conta Servico";
      case `contaInvestidorDigital`:
        return "Conta Investidor";
      case `contaDigital`:
        return "Conta Digital";
    }
  }

  updateFormValidityNovo(): void {
    const novoFormValid = this.formNovo.valid;
    this.isFormValidNovo = novoFormValid
  }

  updateFormValidityVincula(): void {
    this.isFormValidVincula = this.formsVinculaCodigo.valid;
  }

  pegaIdEmpresa(event): void {
    this.selectedEmpresa = event.option.value;
  }

  fecha(): void {
    this.dialogRef.close();
  }
}
