import { PipeModule } from './../../pipe/pipe.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxMaskModule } from 'ngx-mask';
import { IconsModule } from '@app/icons.module';
import { Stepper1Component } from './stepper1/stepper1.component';
import { Stepper2Component } from './stepper2/stepper2.component';
import { Stepper3Component } from './stepper3/stepper3.component';
import { Stepper4Component } from './stepper4/stepper4.component';
import { Stepper5Component } from './stepper5/stepper5.component';
import { PrimeirosPassosComponent } from './primeiros-passos.component';
import { GeralComponent } from '../../pages/interno/pessoa/pessoa-core-editar/geral/geral.component';
import { EnderecoComponent } from '../../pages/interno/pessoa/pessoa-core-editar/endereco/endereco.component';
import { TelefoneComponent } from '../../pages/interno/pessoa/pessoa-core-editar/telefone/telefone.component';
import { EmailComponent } from '../../pages/interno/pessoa/pessoa-core-editar/email/email.component';
import { PessoaCoreEditarModule } from '../../pages/interno/pessoa/pessoa-core-editar/pessoa-core-editar.module';
import { RepresentanteLegalComponent } from '../../pages/interno/pessoa/pessoa-core-editar/representante-legal/representante-legal.component';
import { DocumentacaoComponent } from '../../pages/interno/pessoa/pessoa-core-editar/documentacao/documentacao.component';
import { ValidacoesService } from '@service/validacoes.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FalarComGerenteComponent } from '../falar-com-gerente/falar-com-gerente.component';
import { FalarComGerenteModule } from '../falar-com-gerente/falar-com-gerente.module';
import { EditarContatoComponent } from './stepper1/editar-contato/editar-contato.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StepperInformacoesFinanceirasComponent } from './stepper-informacoes-financeiras/stepper-informacoes-financeiras.component';

@NgModule({
  declarations: [
    PrimeirosPassosComponent,
    EditarContatoComponent,
    Stepper1Component,
    Stepper2Component,
    Stepper3Component,
    Stepper4Component,
    Stepper5Component,
    StepperInformacoesFinanceirasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatStepperModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxCurrencyModule,
    MatTooltipModule,
    IconsModule,
    PipeModule,
    PessoaCoreEditarModule,
    FalarComGerenteModule,
    DateFnsModule.forRoot(),
    NgxMaskModule.forRoot()

  ],
  providers: [
    ValidacoesService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class PrimeirosPassosModule { }
