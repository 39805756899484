import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-juros-desconto',
  templateUrl: './juros-desconto.component.html',
  styleUrls: ['./juros-desconto.component.less']
})
export class JurosDescontoComponent implements OnInit {
  ehAdmin = false;
  env = environment;

  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  @Input()
  public tipoConta: any;

  @Input()
  public dadosEmpresa: any;

  constructor(private autenticacao: AutenticacaoEndpointService) {
    // const userData = this.autenticacao.sessao;
    // if (userData.principal.authorities) {
    //   this.ehAdmin =
    //     userData.principal.authorities.filter(
    //       item =>
    //         item.permissao === 'ROLE_ADMINISTRADOR' &&
    //         item.authority === 'ROLE_ADMINISTRADOR'
    //     ).length > 0;
    // }
  }

  ngOnInit() {
    this.data = this.data || {};

    this.form.addControl('juros', new FormControl(this.data.juros + "%" || 0));
    this.form.addControl('multa', new FormControl(this.data.multa + "%" || 0));
    this.form.addControl('desconto', new FormControl(this.data.desconto || 0));
    this.form.addControl(
      'diasDeProtesto',
      new FormControl({
        value: this.data.diasDeProtesto || null,
        disabled: this.data.diasDeProtesto ? false : true
      })
    );
    this.form.addControl(
      'enviarParaProtesto',
      new FormControl(this.data.enviarParaProtesto || false)
    );

    // if (this.ehAdmin === true) {
    //   this.form.addControl('limiteAntecipacao', new FormControl(this.data.limiteAntecipacao || 0));
    //   this.form.addControl('porcentagemMesFaturaAntecipada', new FormControl(this.data.porcentagemMesFaturaAntecipada || 0));
    //   this.form.addControl('tipoTaxaAntecipacao', new FormControl(this.data.tipoTaxaAntecipacao || 'simples'));
    // }
  }

  troca(e){
    if(e.key == "Backspace" || e.key== " "|| e.key =="ArrowLeft"|| e.key =="ArrowRight"){
      if(e.target.value == ""){
        e.target.value = e.target.value + '%';
      }
      return
    }
    e.target.value = e.target.value.replace(/%/i,"")
    e.target.value = e.target.value + '%';
  }
  
  getErrorMessage(field) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError('required')
        ? 'Campo requerido'
        : this.form.get(field).hasError('minlength')
        ? 'Campo inválido'
        : this.form.get(field).hasError('obrigatorio')
        ? 'Campo requerido'
        : '';
    }
  }

  desabilitaInputs(event) {
    if (this.form.get('enviarParaProtesto').value === false) {
      this.form.get('diasDeProtesto').setValue(null);
      this.form.get('diasDeProtesto').disable();
      this.form.get('diasDeProtesto').setErrors(null);
    } else {
      this.form.get('diasDeProtesto').enable();
      this.form.get('diasDeProtesto').setErrors({ obrigatorio: true });
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }
}
