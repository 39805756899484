import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActivatedRoute } from '@angular/router';
import { isSameDay, isBefore, isAfter, addMonths } from 'date-fns';
import Swal from 'sweetalert2';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { EmitirCobrancaComponent } from '../emitir-cobranca/emitir-cobranca.component';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import {
  Environment,
  environment,
} from '@project/src/environments/environment.default';
import { ListaCobrancaService } from '../listar-cobranca/lista-cobranca.service';
import { PessoaEditarComponent } from '../../pessoa/pessoa-editar/pessoa-editar.component';
import { PessoaContatoService } from '@service/pessoa-endpoint.service';
import { EspecieCobranca } from '@project/src/app/model/cobranca/especie.model';

@Component({
  selector: 'app-visualizar-protesto',
  templateUrl: './visualizar-protesto.component.html',
  styleUrls: ['./visualizar-protesto.component.less'],
})
export class VisualizarProtestoComponent implements OnInit, OnDestroy, AfterViewInit {
  cobrancaId;
  empresaId;
  processando = false;
  data: any = null;
  informacoesProtesto: any = null;
  empresaLogada: any;
  status = ''; // vencido, pago, excluida/cancelado, aguardando pagamento, erro
  vencimentoEmDiaUtil = false;
  private destroy = new Subject();
  lastros: any = [];
  ehAdmin: boolean;
  usuarioExcluiu: any;
  usuarioCriou: any;
  authCobranca: boolean = false;
  authCobrancaOperador: boolean = false;
  env: Environment = environment;
  atualizeContatoProtesto: boolean = false;

  constructor(
    private cobrancaService: CobrancaEndpointService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private autenticacao: AutenticacaoEndpointService,
    private usuarioService: UsuarioEndpointService,
    public listaCobrancaService: ListaCobrancaService,
    public dialogRef: MatDialogRef<VisualizarProtestoComponent>,
    public stringFormat: StringFormatService,
    private pessoaContatoService: PessoaContatoService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;

    const userData = this.autenticacao.sessao;
    const checkAuthorities = new CheckAuthoritiesService(userData);
    this.authCobranca = checkAuthorities.permissaoGuard(
      'ROLE_COBRANCA_ATUALIZAR'
    );
    this.authCobrancaOperador = checkAuthorities.permissaoGuard(
      'ROLE_EMPRESA_COBRANCAS_ATUALIZAR',
      this.empresaLogada.id
    );
  }

  ngOnInit() {
    this.cobrancaId = this.dataDialog.idCobranca;
    this.empresaId = this.dataDialog.idEmpresa;
    this.getCobranca();
    const userData = this.autenticacao.sessao;
    if (userData.principal.authorities) {
      this.ehAdmin =
        userData.principal.authorities.filter(
          (item) =>
            item.permissao === 'ROLE_ADMINISTRADOR' &&
            item.authority === 'ROLE_ADMINISTRADOR'
        ).length > 0;
    }
  }

  ngAfterViewInit() {
    if (this.dataDialog.atualizarEndereco) {
      this.atualizeContatoProtesto = true;
      Swal.fire({
        title: 'Atualize o contato',
        text: 'Se deseja enviar para protesto, o endereço deve estar completo.',
        icon: 'warning',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result) {
          this.editarContato(this.data?.cliente?.id);
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  getCobranca() {
    this.processando = true;

    this.cobrancaService
      .visualizarCobranca(this.cobrancaId, this.empresaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.data = response;

          if (
            isSameDay(
              new Date(this.data.dataVencimentoRecalculada),
              new Date(this.data.dataVencimentoOriginal)
            )
          ) {
            this.vencimentoEmDiaUtil = true;
          } else {
            this.vencimentoEmDiaUtil = false;
          }

          if (this.data.dataPagamento) {
            this.status = 'PAGAMENTO EFETUADO';
          } else if (this.data.dataDesativacao) {
            this.status = 'FATURA EXCLUÍDA';
          } else {
            if (
              isSameDay(
                new Date(this.data.dataVencimentoRecalculada),
                new Date()
              ) ||
              isAfter(new Date(this.data.dataVencimentoRecalculada), new Date())
            ) {
              this.status = 'AGUARDANDO PAGAMENTO';
            } else if (
              isBefore(
                new Date(this.data.dataVencimentoRecalculada),
                new Date()
              )
            ) {
              this.status = 'COBRANÇA VENCIDA';
            }
          }
          if (this.data.statusProcessamentoBanco == 'erro') {
            this.status = 'ERRO';
          }

          if (this.data.statusProtesto) {
            this.getInformacoesProtesto();
          }
          if (
            !this.data?.cliente?.pessoa?.enderecos[0]?.bairro ||
            !this.data?.cliente?.pessoa?.enderecos[0]?.numero
          ) {
            this.atualizeContatoProtesto = true;
          } else {
            this.atualizeContatoProtesto = false;
          }

          this.processando = false;
        },
        (error) => {
          console.error(error);

          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK',
          });

          this.processando = false;
        }
      );
  }

  getInformacoesProtesto() {
    this.processando = true;

    this.cobrancaService
      .consultarProtestoCompletoPelaLinhaDigitavel(this.data?.linhaDigitavel)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.informacoesProtesto = response;
          this.processando = false;
        },
        (error) => {
          console.error(error);
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
          this.processando = false;
        }
      );
  }

  getValorTotalPago() {
    let valor = 0;

    this.data.parcelasCobranca.forEach((element) => {
      if (element.pago) {
        valor =
          valor + (element.valorPagoEmDinheiro || element.valorRecalculado);
      }
    });

    return valor;
  }

  fecha(): void {
    this.dialogRef.close();
  }

  visualizarFatura() {
    window.open('/#/fatura/visualizar/' + this.data.idPublico, '_blank');
  }

  visualizarBoleto() {
    window.open('/#/boleto/visualizar/' + this.data.numeroDaFatura, '_blank');
  }

  reenviar() {
    this.cobrancaService
      .reenviarSmsEmail(this.cobrancaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          Swal.fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK',
          });
        },
        (error) => {
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      );
  }

  editarProtesto() {
    // tipo: 1 - avulsa, 2 - assinatura, 3 - parcelada
    var tipo: string;
    if (this.data.tipoCobranca == 'avulsa') {
      tipo = '1';
    } else if (this.data.tipoCobranca == 'assinatura') {
      tipo = '2';
    } else if (this.data.tipoCobranca == 'parcelada') {
      tipo = '3';
    }
    const dialog = this.dialog.open(EmitirCobrancaComponent, {
      data: {
        tipoCobranca: tipo,
        idCobranca: this.cobrancaId || null,
        numeroParcelas: null,
        isEdicao: true,
        isProtesto: true,
      },
      backdropClass: 'modal-maior',
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.editarProtesto();
        }
      }
    });
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  nomeOuRazaoSocial(pessoa) {
    if (pessoa) {
      return pessoa.razaoSocial || pessoa.nome;
    }
    return '';
  }

  protestarCobranca(itemSelecionado) {
    itemSelecionado.idCobranca = this.cobrancaId;
    this.listaCobrancaService.protestarCobranca(itemSelecionado);
    this.listaCobrancaService.protestouItem
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (response) {
            this.getCobranca();
          }
        },
        (error) => {
          Swal.fire({
            title: 'Erro',
            text: error.error,
            icon: 'error',
          });
          console.error(error);
        }
      );
  }

  cancelarProtestoCobranca(itemSelecionado) {
    itemSelecionado.idCobranca = this.cobrancaId;
    this.listaCobrancaService.cancelarProtestoCobranca(itemSelecionado);
    this.listaCobrancaService.protestouItem
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (response) {
            this.getCobranca();
          }
        },
        (error) => {
          Swal.fire({
            title: 'Erro',
            text: error.error,
            icon: 'error',
          });
          console.error(error);
        }
      );
  }

  protestoPermitido(dataProtestoCalculada) {
    if (dataProtestoCalculada) {
      return this.listaCobrancaService.envioProtestoPermitido(
        dataProtestoCalculada
      );
    } else {
      return false;
    }
  }

  cancelamentoProtestoPermitido(statusProtesto) {
    if (statusProtesto) {
      return this.listaCobrancaService.cancelamentoProtestoPermitido(
        statusProtesto
      );
    } else {
      return false;
    }
  }

  alteracaoProtestoPermitida(statusProtesto) {
    if (statusProtesto) {
      return this.listaCobrancaService.alteracaoProtestoPermitida(
        statusProtesto
      );
    } else {
      return false;
    }
  }

  baixarProtesto() {
    window.open('/#/protesto/visualizar/' + this.cobrancaId, '_blank');
  }

  descricaoEspecie(codigoEspecie: string): string {
    const descricao = EspecieCobranca.find(e => e.codigo == codigoEspecie);
    return descricao ? descricao.descricao : codigoEspecie;
  }

  destaqueSituacao(situacao: string): string {
    if (situacao == "AGUARDANDORETORNO"
      || situacao == "COLETADO"
      || situacao == "GERADO"
      || situacao == "ENVIADO"
      || situacao == "CONFIRMADO"
      || situacao == "PAGO"
    ) {

      return "#4FA953"; // verde
    }
    if (situacao == "DEVOLVIDO") {

      return "#DFB924"; // amarelo
    }
    if (situacao == "REMOVIDO"
      || situacao == "DESISTIDO"
      || situacao == "INEXISTENTE"
      || situacao == "RETIRADO"
      || situacao == "SUSTADO"
      || situacao == "CANCELADO") {

      return "#FE431C"; // vermelho
    }
    if (situacao == "PROTESTADO") {

      return "#4FA9A9"; // azul
    }
  }

  verificaStatus(statusProtesto) {
    if (
      statusProtesto === 'GERADO' ||
      statusProtesto === 'ENVIADO' ||
      statusProtesto === 'CONFIRMADO' ||
      statusProtesto === 'PAGO' ||
      statusProtesto === 'PROTESTADO'
    ) {
      return true;
    }
    return false;
  }

  editarContato(contato) {
    this.pessoaContatoService
      .getPessoa(contato)
      .toPromise()
      .then((pessoa) => {
        const dialog = this.dialog
          .open(PessoaEditarComponent, {
            width: '1240px',
            data: {
              tipo: 'contato',
              pessoa: pessoa.pessoa,
              id: contato,
              isEdicaoEndereco: true,
            },
            backdropClass: 'close-button-position',
            panelClass: 'fundo-empresa',
          })
          .afterClosed()
          .pipe()
          .subscribe((result) => {
            this.getCobranca();
          });
      });
  }
}
