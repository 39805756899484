import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomFormValidatorService {

    static requiredIf(onlyIf: () => boolean | Promise<boolean>): ValidatorFn | AsyncValidatorFn {
        return (control: AbstractControl) => {
            const valid = () => {
                const { value } = control;
                if (!value) {
                    return {
                        required: true
                    } as ValidationErrors;
                }
            }
            const value = onlyIf();
            if (typeof value === 'boolean') {
                if (value) {
                    return valid();
                }
            } else {
                return value.then(response => {
                    if (response) {
                        return valid();
                    }
                })
            }
        }
    }
}