import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResponse } from '@model/page-response.model';
import { EmpresaDTO } from '@model/pessoa/empresa-dto.model';
import { Observable } from 'rxjs';
import { InformacoesFinanceirasDTO } from '../model/informacoes-financeiras-dto.model';
import { TaxaCobrarDTO } from '../model/root/taxa-cobrar-dto.model';
import { UsuarioDTO } from '../model/usuario/usuario-dto.model';
import { ConfiguracaoFtpDTO } from '../model/pessoa/configuracao-ftp-dto.model';

@Injectable({
  providedIn: 'root'
})
export class EmpresaEndpointService {

  constructor(private client: HttpClient) { }

  listar(arg?: {
    order?: string;
    page?: number;
    query?: string;
    size?: number;
    idCorporacao?: number;
  }) {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.query) {
      params = params.append('q', `${arg.query}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    if (arg.idCorporacao) {
      params = params.append('idCorporacao', `${arg.idCorporacao}`);
    }

    const url = '/empresa';
    return this.client.get<PageResponse<EmpresaDTO>>(url, { params });
  }

  contaExiste(numeroConta) {
    const url = `/empresa/existe-numero-conta?numeroContaInterna=${numeroConta}`;
    return this.client.get<boolean>(url);
  }

  getEmpresaById(id) {
    const url = `/empresa/${id}`;
    return this.client.get<EmpresaDTO>(url);
  }

  setarPerfilInvestidor(empresaId: number, perfilInvestidor: string, analise) {
    const url = `/empresa/atualizar-perfil-investidor/${empresaId}/${perfilInvestidor}`;
    return this.client.post(url, analise);
  }

  encerrarConta(id) {
    const url = `/empresa/encerrar-conta/${id}`;
    return this.client.get<EmpresaDTO>(url);
  }

  listarAgencias() {
    type Agencia = {
      nome: string;
      codigo: string;
      codigoIntegracaoContaPatrimonio: string;
    }
    const url = '/empresa/agencias';
    return this.client.get<Agencia[]>(url);
  }

  findByContaAndAgencia(conta: string, agencia: string) {
    const url = `/empresa/find-by-conta-and-agencia?conta=${+conta.replace('-', '')}&agencia=${+agencia.replace('-', '')}`
    return this.client.get<EmpresaDTO>(url)
  }

  cadastrarInformacoesFinanceiras(idEmpresa: number, informacoesFinanceiras: any) {
    const url = `/empresa/cadastrar-informacoes-financeiras?idEmpresa=${idEmpresa}`;
    return this.client.post(url, informacoesFinanceiras);
  }

  listarInformacoesFinanceiras(idEmpresa: number): Observable<InformacoesFinanceirasDTO> {
    const url = `/empresa/listar-informacoes-financeiras?idEmpresa=${idEmpresa}`;

    return this.client.get<InformacoesFinanceirasDTO>(url);
  }

  realizaCobracaTaxaManutencao(taxaCobrar: TaxaCobrarDTO): Observable<string> {
    const url = `/empresa/cobrar-taxa`;
    return this.client.post<string>(url, taxaCobrar);
  }

  listarUsuariosAdministradores(empresaId: number): Observable<UsuarioDTO[]>{
    const url = `/empresa/listar-usuarios-administradores/${empresaId}`
    return this.client.get<UsuarioDTO[]>(url)
  }

  salvarConfiguracoesFtp(dto: ConfiguracaoFtpDTO){
    const url = `/empresa/salvar-configuracao-ftp`;
    return this.client.post(url, dto);
  }
  
  listarConfiguracoesFtp(empresaId) {
    const url = `/empresa/listar-configuracao-ftp/${empresaId}`;
    return this.client.get<ConfiguracaoFtpDTO>(url);
  }

  testarConexaoFtp(empresaId){
    const url = `/empresa/testar-conexao-ftp/${empresaId}`;
    return this.client.get(url);
  }

  existeContaAgencia(numeroConta: string, agencia: string){
    const url = '/empresa/existe-conta-agencia';
    let params = new HttpParams();
    params = params.append('numeroConta', `${numeroConta}`);
    params = params.append('agencia', `${agencia}`);
    return this.client.get<boolean>(url, { params });
  }
}
