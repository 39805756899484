<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    LINKS
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <form [formGroup]="form">

    <div mat-dialog-content class="mat-dialog-content-responsive" style="max-height: 41vh !important;">
      <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
        <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!processando" class="mudaCor input-size row "
          style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px;">
          <div class="first-container">
            <!-- FORMS -->
            <div id="input-container" class="row row-inputs">
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Logo</mat-label>
                  <input matInput type="text" placeholder="URL Logo" formControlName="urlLogo">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Termos de Contratos e Políticas  </mat-label>
                  <input matInput type="text" placeholder="URL Termos de Contratos e Políticas " formControlName="urlTermos">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Ajuda Antecipações</mat-label>
                  <input matInput type="text" placeholder="URL Ajuda Antecipações" formControlName="urlAjudaAntecipacoes">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Ajuda Cobranças</mat-label>
                  <input matInput type="text" placeholder="URL Ajuda Cobranças" formControlName="urlAjudaCobrancas">
                </mat-form-field>
              </div>

            </div>

          </div>
          <div class="first-container">
            <div id="input-container" class="row row-inputs">

              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Ajuda Pagamentos</mat-label>
                  <input matInput type="text" placeholder="URL Ajuda Pagamentos" formControlName="urlAjudaPagamentos">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Ajuda Transferências</mat-label>
                  <input matInput type="text" placeholder="URL Ajuda Transferências"
                    formControlName="urlAjudaTransferencias">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12" style="margin-top: 25px;">
                <mat-form-field appearance="outline">
                  <mat-label>URL Ajuda Contatos</mat-label>
                  <input matInput type="text" placeholder="URL Ajuda Contatos" formControlName="urlAjudaContatos">
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- FAZER A PARTE DE LOGOS AQUI -->

        </div>

      </div>

    </div>
  </form>
  <div mat-dialog-actions
    style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;background-color: white; display: flex; justify-content: end;">

    <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
    margin: 0;">
      <button (click)="fecha()" class="btn btn-default next">Voltar</button>
    </span>
    <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
    margin: 0;">
      <button (click)="salvaConfig()" class="btn btn-default fundoCorPrimaria next">Salvar</button>
    </span>
  </div>
</div>