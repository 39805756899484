import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RootEndpointService } from '../../service/root-endpoint.service';

@Component({
  selector: 'app-senhas-banidas',
  templateUrl: './senhas-banidas.component.html',
  styleUrls: ['./senhas-banidas.component.less']
})
export class SenhasBanidasComponent implements OnInit {
  form: FormGroup;
  saveObj: object;
  isFormValid: boolean = false;
  processando: boolean = false;
  todasSenhas: [] = [];

  constructor(
    public dialogRef: MatDialogRef<SenhasBanidasComponent>,
    public formBuilder: FormBuilder,
    public rootService: RootEndpointService,
  ) { }

  ngOnInit() {
    this.formsEmails();
    this.listarTodos();

    this.form.statusChanges.subscribe(status => {
      this.isFormValid = status === 'VALID';
    });
  }

  formsEmails(): void {
    this.form = this.formBuilder.group({
      senha: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  salvar(): void {
    const novaSenha = this.form.get('senha').value;

    const password = {
      password: novaSenha
    }

    this.rootService.cadastrarSenhaBanida(password).subscribe((res) => {
      Swal.fire(
        "Sucesso",
        "Senha cadastrada com sucesso",
        "success"
      ).then(() => {
        this.listarTodos();
      });
    },
      (error) => {
        Swal.fire(
          "Erro ao cadastrar senha",
          error.error,
          "error"
        );
        console.error(error);
      }
    );
  }

  fecha(): void {
    this.dialogRef.close();
  }

  listarTodos(): void {
    this.processando = true;
    this.rootService
      .listarSenhasBanidas()
      .subscribe((res) => {
        this.processando = false;
        this.todasSenhas = res;
      }, error => {
        this.processando = false;
        Swal.fire(
          "Erro ao listar emails",
          error.error,
          "error"
        );
        console.error(error);
      });
  }

}
