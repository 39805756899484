import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EmpresaDTO } from '@model/pessoa/empresa-dto.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { DocumentacaoComponent } from '../../../pages/interno/pessoa/pessoa-core-editar/documentacao/documentacao.component';
import { RepresentanteLegalComponent } from '../../../pages/interno/pessoa/pessoa-core-editar/representante-legal/representante-legal.component';

@Component({
  selector: 'app-stepper5',
  templateUrl: './stepper5.component.html',
  styleUrls: ['./stepper5.component.less']
})
export class Stepper5Component implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  processando = false;

  @Input()
  public form: FormGroup;
  @Input()
  inputEmpresa = '';
  @Input()
  inputEndereco = '';
  @Input()
  tipoConta: string;
  @Input()
  representantesData = [];

  @Input()
  public dadosRecuperados: any;

  @Output()
  public atualizarForm: EventEmitter<any> = new EventEmitter();

  @ViewChild(DocumentacaoComponent, { static: false })
  private documentacao: DocumentacaoComponent;
  @ViewChild(RepresentanteLegalComponent, { static: false })
  private representanteLegal: RepresentanteLegalComponent;

  constructor(private formBuilder: FormBuilder) { }
  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    // this.form.patchValue(this.dadosRecuperados.formStepper5);
  }

  ngOnInit() {
    const elemBody = <HTMLElement>(
      document.querySelector('.horizontal-stepper')
    );
    elemBody.scrollTop = 0;
    this.form.addControl('tipoDocumento', new FormControl('', null));
    this.form.addControl('tipoEmpresa', new FormControl(''));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(3000)).subscribe(val => {
      if (this.form.get('documentos').valid || this.form.get('representantes').valid || this.form.get('tipoEmpresa').valid)
        this.atualizarForm.emit({ atualizar: true });
    });
  }

  atualizaTudo(obj) {
    this.representanteLegal.atualizaTudo(obj);
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido'
          : '';
    }
  }

  public build(empresa: EmpresaDTO) {
    this.documentacao.build(empresa);
    if (this.representanteLegal) {
      this.representanteLegal.build(empresa);
    }
    return empresa;
  }
}
