import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DadosIncompletosModel } from '@model/dados-incompletos.model';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutenticacaoEndpointService } from './autenticacao-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class DadosEndpointService {

  _data = new BehaviorSubject(null) || null;

  constructor(
    private client: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private autenticacao: AutenticacaoEndpointService,
  ) { }

  postDados(dadoJson: string, localIncompleto: string): Observable<any> {
    const url = '/dado-incompleto';
    const dados: DadosIncompletosModel = {};
    dados.dado = dadoJson;
    dados.localIncompleto = localIncompleto;
    dados.usuarioId = this.autenticacao.sessao.principal.usuario.id;
    return this.client.post(url, dados);
  }

  patchDados(dadoJson: string, localIncompleto: string): Observable<any> {
    const dados: DadosIncompletosModel = {};
    if (this.data) {
      dados.id = this.data.id;
      dados.usuarioId = this.data.usuarioId;
    } else {
      dados.id = null;
      dados.usuarioId = null;
    }
    dados.dado = dadoJson;
    dados.localIncompleto = localIncompleto;
    const url = '/dado-incompleto/atualizar';
    return this.client.patch(url, dados);
  }

  getDados(localIncompleto: string): Observable<any> {
    const url = '/dado-incompleto/recuperar';
    let params = new HttpParams();
    params = params.append('local', `${localIncompleto}`);
    return this.client
      .get<DadosIncompletosModel>(url, { params })
      .pipe(
        map(retorno => {
          this.data = retorno;
          return retorno;
        })
      );
  }

  deleteDados(id): Observable<any> {
    const url = `/dado-incompleto/apagar/${id}`;
    this.data = null;
    return this.client.delete(url);
  }

  get data(): DadosIncompletosModel | null {
    return this.storage.get('dados') as DadosIncompletosModel;
  }

  set data(sessao: DadosIncompletosModel | null) {
    if (sessao) {
      this._data.next(sessao);
      this.storage.set('dados', sessao);
    } else {
      this._data.next(null);
      this.storage.remove('dados');
    }
  }

}
