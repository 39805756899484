<div class="box-body" [formGroup]="form">
  <div class="row justify-content-center">
    <div class="col-3">
      <p class="super-title">Autorizar via token</p>
    </div>
    <div class="col-2"
      *ngIf="env.modulos.token.sms && !usuarioColaborador && estrutura !== 'edicaoTelefoneUsuario' && estrutura !== 'transferenciaLoteZeragem'">
      <mat-slide-toggle formControlName="isSMS" (change)="changeSlide($event, 'sms')" style="padding-top:10px;">
        <span matTooltip="Token via sms">SMS</span>
      </mat-slide-toggle>
    </div>
    <div class="col-2"
      *ngIf="env.modulos.token.email && estrutura !== 'edicaoTelefoneUsuario' && estrutura !== 'transferenciaLoteZeragem'">
      <mat-slide-toggle formControlName="isEmail" (change)="changeSlide($event, 'email')" style="padding-top:10px;">
        <span matTooltip="Token via e-mail">E-mail</span>
      </mat-slide-toggle>
    </div>
    <div class="col-2" *ngIf="estrutura !== 'edicaoTelefoneUsuario'">
      <mat-slide-toggle formControlName="isMfa" (change)="changeSlide($event, 'mfa')" style="padding-top:10px;">
        <span matTooltip="Token via autenticação multifator">MFA</span>
      </mat-slide-toggle>
    </div>
  </div>
  <br>
  <br>
  <div class="col-12 text-center">
    <p *ngIf="!form.get('isMfa').value">Para finalizar a solicitação, é necessário autorizá-la por meio do seu token via
      {{ form.get('isSMS').value ?
      'sms' : 'e-mail'}}.</p>
    <p *ngIf="form.get('isMfa').value">Para finalizar a solicitação, é necessário autorizá-la por meio do seu token
      gerado no aplicativo de autenticação.</p>
  </div>
  <div class="col-12 text-center" *ngIf="!form.get('isMfa').value">
    <p>O código de autorização será enviado para o {{ form.get('isSMS').value ? 'número ' +numeroTelefone : 'e-mail '
      +emailEnvio }} </p>
  </div>
  <div class="row" style="justify-content: center; padding-top: 5px;">
    <div class="col-4 col-md-4" *ngIf="!form.get('isMfa').value">
      <button [disabled]="!podeReenviar" type="button" (click)="novoCodigo()" class="btn-novocliente btn btn-block"
        mat-raised-button>
        <fa-icon [icon]="['fas', 'circle-plus']"></fa-icon> ENVIAR TOKEN {{ form.get('isSMS').value ? 'SMS' : 'E-MAIL'}}
      </button>
      <p *ngIf="podeReenviar" class="obs">Não recebeu o {{ form.get('isSMS').value ? 'sms' : 'e-mail'}}? <a
          (click)="novoCodigo()" style="cursor: pointer;">Clique aqui</a></p>
      <p *ngIf="!podeReenviar" class="obs">Você poderá solicitar um novo código em {{ tempoReenvio }} minutos
        <br />
        {{ bloqueiaEnviarSmsAte | date: "dd/MM/yyyy HH:mm" }}
      </p>
      <p [hidden]="podeReenviar" class="obs" style="color: #3d8686 !important">{{ form.get('isSMS').value ? 'Sms' :
        'E-mail'}} enviado!</p>
    </div>
    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Token</mat-label>
        <input matInput type="text" formControlName="token" mask="000000" />
        <mat-error>{{ getErrorMessage(form, "token") }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>