import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class EmprestimoEndpointService {
    constructor(
        private client: HttpClient
    ) { }


    getDocumentos(): Observable<any> {
        const url = '/emprestimo/documentos-emprestimo';
        return this.client.get<any>(url);
    }

    listarDocumentosEmprestimoEmpresa(idEmpresa: number): Observable<any> {
        const url = '/emprestimo/listar-documentos-emprestimo-empresa';
        let params = new HttpParams();

        params = params.append('idEmpresa', `${idEmpresa}`);

        return this.client.get<any>(url, { params });
    }

    moderarDocumentoEmprestimo(idDocumento: number): Observable<any> {
        const url = '/emprestimo/moderar-documentos-emprestimo-empresa?idDocumento=' + idDocumento;

        return this.client.put<any>(url, idDocumento);
    }

    cadastrarEmprestimo(emprestimo: any): Observable<any> {
        const url = '/emprestimo/cadastrar-emprestimo';
        return this.client.post<any>(url, emprestimo);
    }

    listarEmprestimos(page: number, size: number, query?: string, order?: string): Observable<any> {
        const url = '/emprestimo/listar-emprestimos'
        let params = new HttpParams();

        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);
        params = params.append('query', `${query}`);
        order ? params = params.append('order', `${order}`) : null;

        return this.client.get(url, { params });
    }

    listarEmprestimosPorCodigo(codigo: string, page: number, size: number, query?: string, order?: string): Observable<any> {
        const url = `/emprestimo/listar-emprestimos-by-codigo?codigo=${codigo}`
        let params = new HttpParams();

        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);
        params = params.append('query', `${query}`);
        order ? params = params.append('order', `${order}`) : null;

        return this.client.get(url, { params });
    }

    listarEmprestimosPorEmpresa(idEmpresa: number, page: number, size: number, query?: string, order?: string): Observable<any> {
        const url = `/emprestimo/listar-emprestimos-by-empresa`;
        let params = new HttpParams();

        params = params.append('idEmpresa', `${idEmpresa}`);
        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);
        params = params.append('query', `${query}`);
        order ? params = params.append('order', `${order}`) : null;

        return this.client.get(url, { params });
    }

    desativarEmprestimo(idEmprestimo: number) {
        const url = '/emprestimo/desativar-emprestimo?idEmprestimo=' + idEmprestimo;
        return this.client.put(url, idEmprestimo);
    }

    solicitarEmprestimo(emprestimo: any): Observable<any> {
        const url = '/emprestimo/solicitar-emprestimo';
        return this.client.post<any>(url, emprestimo);
    }

    listarEmprestimosRoot(page: number, size: number, filter: string): Observable<any> {
        const url = '/emprestimo/listar-emprestimos-root';
        let params = new HttpParams();

        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);
        params = params.append('filter', `${filter}`);

        return this.client.get(url, { params });
    }

    aprovarEmprestimoRoot(idEmprestimo: number, moderacao: string): Observable<any> {
        const url = '/emprestimo/aprovar-emprestimo-root?idEmprestimo=' + idEmprestimo + '&moderacao=' + moderacao;
        return this.client.put<any>(url, idEmprestimo);
    }

    aprovarEmprestimoUsuario(dtoUser): Observable<any> {
        const url = '/emprestimo/aprovar-emprestimo-usuario'
        return this.client.post<any>(url, dtoUser);
    }

    calcularEmprestimo(valorEmprestimo, numeroDeParcelas, taxa, taxaIof, tarifaAdesao): Observable<any> {
        const url = '/emprestimo/calcular-emprestimo';
        let params = new HttpParams();

        params = params.append('valorEmprestimo', `${valorEmprestimo}`);
        params = params.append('numeroDeParcelas', `${numeroDeParcelas}`);
        params = params.append('taxa', `${taxa}`);
        params = params.append('taxaIof', `${taxaIof}`);
        params = params.append('tarifaAdesao', `${tarifaAdesao}`);

        return this.client.get<any>(url, { params });
    }

    listarParcelasEmprestimo(idEmprestimo: number): Observable<any> {
        const url = '/emprestimo/listar-parcelas-emprestimo?idEmprestimo=' + idEmprestimo;
        return this.client.get<any>(url);
    }

    salvarContratoEmprestimo(contrato: any): Observable<any> {
        const url = '/emprestimo/salvar-arquivo-emprestimo';
        return this.client.post<any>(url, contrato);
    }

    getContratoEmprestimo(configEmprestimoId): Observable<any> {
        const url = `/emprestimo/get-arquivos-emprestimo/${configEmprestimoId}`;
        return this.client.get<any>(url, configEmprestimoId);
    }

    deletarContratoEmprestimo(arquivoEmprestimoId): Observable<any> {
        const url = '/emprestimo/deletar-arquivo-emprestimo/' + arquivoEmprestimoId;
        return this.client.put<any>(url, arquivoEmprestimoId);
    }
}