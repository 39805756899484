import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { UsuarioEditarComponent } from './usuario-editar.component';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { EnderecoComponent } from '../../pessoa/pessoa-core-editar/endereco/endereco.component';
import { PessoaCoreEditarModule } from '../../pessoa/pessoa-core-editar/pessoa-core-editar.module';
import { NgxMaskModule } from 'ngx-mask';
import { ModalConfirmaTokenModule } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.module';
import { LocalizacaoModule } from '@project/src/app/components/localizacao/localizacao.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
	declarations: [UsuarioEditarComponent, TrocarSenhaComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatFormFieldModule,
		MatIconModule,
		MatDialogModule,
		MatInputModule,
		MatProgressBarModule,
		MatSlideToggleModule,
		PessoaCoreEditarModule,
		MatTabsModule,
		NgxMaskModule,
		MatDatepickerModule,
		ModalConfirmaTokenModule,
		MatListModule,
		LocalizacaoModule
	],
})
export class UsuarioEditarModule { }
