import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LocalizacaoEndPointService } from '../../service/localizacao-endpoint.service';


@Component({
  selector: 'app-localizacao',
  templateUrl: './localizacao.component.html',
  styleUrls: ['./localizacao.component.less']
})
export class LocalizacaoComponent implements OnInit {

  latitude: number;
  longitude: number;
  @Input() usuarioLogado: any;
  @Input() tipo: any;

  public localizacaoNegada: boolean = false;

  constructor(
    private localizacaoService: LocalizacaoEndPointService,
  ) { }

  ngOnInit() {
    this.getLocationAndCheckPermission();
  }


  async getLocationAndCheckPermission() {
    if (navigator.geolocation) {
      try {
        const position = await new Promise<any>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const localizacao = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          usuario: this.usuarioLogado,
          tipo: this.tipo,
        };

        this.localizacaoNegada = false;
        this.salvarLocalizacao(localizacao);
        return "OK"
      } catch (error) {
        if (error.code === error.PERMISSION_DENIED) {
          this.localizacaoNegada = true;
        }
      }

      // Verifique as permissões de geolocalização após a tentativa de obter a localização
      if ('permissions' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          permissionStatus.onchange = function () {
            if (this.state === 'granted') {
              console.log('Permissão para localização concedida');
            }
          };
        });
      }
    }
  }


  salvarLocalizacao(localizacao) {
    this.localizacaoService.salvar(localizacao)
      .subscribe(
        (res) => {
        },
        (err) => {
        });
  }
}
