import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.less']
})
export class ModalConfirmacaoComponent implements OnInit {

  processando: boolean = false;


  constructor(
    private autenticacao: AutenticacaoEndpointService,
    public dialogRef: MatDialogRef<ModalConfirmacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.processando = false;
  }

  nova() {
    this.dialogRef.close({
      nova: true
    });
  }

  enviarWhats() {
    this.dialogRef.close({
      whatsapp: true
    });
  }

  checkout() {
    this.dialogRef.close({
      checkout: true
    });
  }

  fechar(): void {
    this.dialogRef.close({
      finalizar: true
    });
  }
}
