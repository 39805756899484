import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActivatedRoute } from '@angular/router';
import { isSameDay, isBefore, isAfter, addMonths } from 'date-fns';
import Swal from 'sweetalert2';
import { LiquidarCobrancaComponent } from './liquidar-cobranca/liquidar-cobranca.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { EmitirCobrancaComponent } from '../emitir-cobranca/emitir-cobranca.component';
import { StringFormatService } from "@project/src/app/service/util/stringFormater.service"
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import { Environment, environment } from "@project/src/environments/environment.default";
import { ListaCobrancaService } from '../listar-cobranca/lista-cobranca.service';

@Component({
  selector: 'app-visualizar-cobranca',
  templateUrl: './visualizar-cobranca.component.html',
  styleUrls: ['./visualizar-cobranca.component.less']
})
export class VisualizarCobrancaComponent implements OnInit, OnDestroy {
  cobrancaId;
  empresaId;
  processando = false;
  data: any = null;
  empresaLogada: any;
  status = ''; // vencido, pago, excluida/cancelado, aguardando pagamento, erro
  vencimentoEmDiaUtil = false;
  private destroy = new Subject();
  lastros: any = [];
  ehAdmin: boolean;
  usuarioExcluiu: any;
  usuarioCriou: any;
  authCobranca: boolean = false;
  authCobrancaOperador: boolean = false;
  env: Environment = environment;
  contatoIncompleto: boolean = false;

  constructor(
    private cobrancaService: CobrancaEndpointService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private autenticacao: AutenticacaoEndpointService,
    private usuarioService: UsuarioEndpointService,
    public listaCobrancaService: ListaCobrancaService,
    public dialogRef: MatDialogRef<VisualizarCobrancaComponent>,
    public stringFormat: StringFormatService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;

    const userData = this.autenticacao.sessao;
    const checkAuthorities = new CheckAuthoritiesService(userData);
    this.authCobranca = checkAuthorities.permissaoGuard("ROLE_COBRANCA_ATUALIZAR");
    this.authCobrancaOperador = checkAuthorities.permissaoGuard("ROLE_EMPRESA_COBRANCAS_ATUALIZAR", this.empresaLogada.id);
    this.contatoIncompleto = this.dataDialog?.atualizarEndereco;
  }

  ngOnInit() {
    this.cobrancaId = this.dataDialog.idCobranca;
    this.empresaId = this.dataDialog.idEmpresa;
    this.getCobranca();
    this.getLastro();
    const userData = this.autenticacao.sessao;
    if (userData.principal.authorities) {
      this.ehAdmin =
        userData.principal.authorities.filter(
          item =>
            item.permissao === 'ROLE_ADMINISTRADOR' &&
            item.authority === 'ROLE_ADMINISTRADOR'
        ).length > 0;
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  getCobranca() {
    this.processando = true;

    this.cobrancaService
      .visualizarCobranca(this.cobrancaId, this.empresaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.data = response;

          if (
            isSameDay(
              new Date(this.data.dataVencimentoRecalculada),
              new Date(this.data.dataVencimentoOriginal)
            )
          ) {
            this.vencimentoEmDiaUtil = true;
          } else {
            this.vencimentoEmDiaUtil = false;
          }

          if (this.data.dataPagamento) {
            this.status = 'PAGAMENTO EFETUADO';
          } else if (this.data.dataDesativacao) {
            this.status = 'FATURA EXCLUÍDA';
          } else {
            if (
              isSameDay(
                new Date(this.data.dataVencimentoRecalculada),
                new Date()
              ) ||
              isAfter(new Date(this.data.dataVencimentoRecalculada), new Date())
            ) {
              this.status = 'AGUARDANDO PAGAMENTO';
            } else if (
              isBefore(
                new Date(this.data.dataVencimentoRecalculada),
                new Date()
              )
            ) {
              this.status = 'COBRANÇA VENCIDA';
            }
          }
          if(this.data.statusProcessamentoBanco == 'erro'){
            this.status = 'ERRO';
          }

          this.processando = false;
        },
        error => {
          console.error(error);

          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });

          this.processando = false;
        }
      );
  }

  getLastro() {
    this.processando = true;

    this.cobrancaService
      .getLastroById(this.cobrancaId, 0, 10)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.lastros = response;
        }, error => {
          console.error(error);
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
          this.processando = false;
        });
  }

  getValorTotalPago() {
    let valor = 0;

    this.data.parcelasCobranca.forEach(element => {
      if (element.pago) {
        valor =
          valor + (element.valorPagoEmDinheiro || element.valorRecalculado);
      }
    });

    return valor;
  }

  fecha(): void {
    this.dialogRef.close();
  }

  visualizarFatura() {
    window.open('/#/fatura/visualizar/' + this.data.idPublico, '_blank');
  }

  visualizarBoleto() {
    window.open('/#/boleto/visualizar/' + this.data.numeroDaFatura, '_blank');
  }

  reenviar() {
    this.cobrancaService
      .reenviarSmsEmail(this.cobrancaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          Swal.fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK'
          });
        },
        error => {
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  editarCobranca() {
    // tipo: 1 - avulsa, 2 - assinatura, 3 - parcelada
    var tipo: string;
    if (this.data.tipoCobranca == "avulsa") {
      tipo = '1';
    } else if (this.data.tipoCobranca == "assinatura") {
      tipo = '2';
    } else if (this.data.tipoCobranca == "parcelada") {
      tipo = '3';
    }
    const dialog = this.dialog.open(EmitirCobrancaComponent, {
      data: {
        tipoCobranca: tipo,
        idCobranca: this.cobrancaId || null,
        numeroParcelas: null,
        isEdicao: true,
      },
      backdropClass: 'modal-maior'
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.nova === true) {
          this.editarCobranca();
        }
      }
    });
  }

  liquidarCobranca() {
    const dialog = this.dialog.open(LiquidarCobrancaComponent, {
      data: {
        cobrancaId: this.cobrancaId,
        liquidouCobranca: false
      }
    });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          if (result.liquidouCobranca === true) {
            this.ngOnInit();
          }
        }
      });
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  nomeOuRazaoSocial(pessoa) {
    if (pessoa) {
      return pessoa.razaoSocial || pessoa.nome;
    }
    return '';
  }

  protestarCobranca(itemSelecionado) {
    itemSelecionado.idCobranca = this.cobrancaId;
    this.listaCobrancaService.protestarCobranca(itemSelecionado);
    this.listaCobrancaService.protestouItem.pipe(untilDestroyed(this)).subscribe(
      (response) => {
        if (response) {
          this.getCobranca();
          this.getLastro();
        }
      },
      (error) => {
        Swal.fire({
          title: "Erro",
          text: error.error,
          icon: "error",
        })
        console.error(error);
      }
    );
  }

  cancelarProtestoCobranca(itemSelecionado) {
    itemSelecionado.idCobranca = this.cobrancaId;
    this.listaCobrancaService.cancelarProtestoCobranca(itemSelecionado);
    this.listaCobrancaService.protestouItem.pipe(untilDestroyed(this)).subscribe(
      (response) => {
        if (response) {
          this.getCobranca();
          this.getLastro();
        }
      },
      (error) => {
        Swal.fire({
          title: "Erro",
          text: error.error,
          icon: "error",
        })
        console.error(error);
      }
    );
  }

  protestoPermitido(dataProtestoCalculada) {
    if (dataProtestoCalculada) {
      return this.listaCobrancaService.envioProtestoPermitido(dataProtestoCalculada);
    } else {
      return false;
    }
  }

  cancelamentoProtestoPermitido(statusProtesto) {
    if (statusProtesto) {
      return this.listaCobrancaService.cancelamentoProtestoPermitido(statusProtesto);
    } else {
      return false;
    }
  }

  alteracaoProtestoPermitida(statusProtesto) {
    if (statusProtesto) {
      return this.listaCobrancaService.alteracaoProtestoPermitida(statusProtesto);
    } else {
      return false;
    }
  }

}
