<h3 mat-dialog-title class="titulo">Alterar grupo e conta</h3>

<div mat-dialog-content class="mat-dialog-content-responsive">
  <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <form [formGroup]="form">
    <div class="box-body input-size">

      <mat-form-field [floatLabel]="'always'" appearance="outline">
        <mat-label>Selecione o grupo</mat-label>
        <span style="position: absolute; top: 5.1%!important" class="material-icons">account_balance</span>
        <input style="padding-left: 5.1%!important;" type="text" placeholder="Selecione o grupo de empresas" matInput
          formControlName="corporacao" [matAutocomplete]="autoCorp" [value]="corporacaoNome"
          (keyup)="resetFieldCorp($event.target.value)"/>

        <mat-autocomplete #autoCorp="matAutocomplete" [displayWith]="displayCorp"
          (optionSelected)="getEmpresasDaCorporacao($event.option.value)">
          <mat-option *ngFor="let corporacao of corporacaoList" [value]="corporacao"
            (click)="atualizarCorporacao(corporacao)">
            <span [style.color]="corporacao?.status == 'bloqueada' ? 'darkred' : ''">{{ !corporacao ? "" :
              corporacao.status == 'ativada' ? corporacao.nome : corporacao.nome + " - BLOQUEADA" }}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ getErrorMessage("corporacao") }}</mat-error>
      </mat-form-field>

      <mat-form-field [floatLabel]="'always'" appearance="outline">
        <mat-label>Selecione a conta</mat-label>
        <span style="position: absolute; top: 5.1%!important" class="material-icons">home</span>
        <input style="margin-left: 5.1%!important;max-width: 69ch;overflow: hidden;
        white-space: nowrap;" type="text" placeholder="Selecione a empresa" matInput formControlName="empresa"
          [matAutocomplete]="autoEmp" [value]="empresaNome" (change)="resetFieldEmp(empresaNome)" />
        <mat-autocomplete #autoEmp="matAutocomplete" [displayWith]="displayEmp">
          <mat-option *ngFor="let empresa of filtroEmpresa | async" [value]="empresa"
            (click)="atualizarEmpresa(displayEmp(empresa))" matTooltip="{{exibirTooltip(empresa)}}"
            matTooltipPosition="after">
            <span [style.color]="empresa.moderacaoEmpresa == 'bloqueada' ? 'rgb(139, 0, 0)' : ''">{{
              empresa.pessoa.razaoSocial ? empresa.pessoa.razaoSocial : empresa.pessoa.nome }} -
              {{ empresa.pessoa.documento }} -
              Bco: {{ this.getComp(empresa) }} -
              Ag: {{ this.getAgencia(empresa) }} -
              {{ this.getTipo(empresa)}}:
              {{ this.calculaNroConta(empresa) }}
              {{ empresa.moderacaoEmpresa == 'bloqueada' ? ' - BLOQUEADA' : ""}}
              {{ empresa.moderacaoEmpresa == 'encerrada' ? ' - ENCERRADA' : ""}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="row" style="justify-content: center">
  <div class="col-12 col-md-5">
    <button (click)="alterar()" class="btn btn-block" style="background-color: #4fa9a9"
      [disabled]="form.get('empresa').value == ''" mat-raised-button>
      Alterar
    </button>
  </div>
</div>