import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { HomeEndpointService } from '@project/src/app/service/home-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-variaveis',
  templateUrl: './variaveis.component.html',
  styleUrls: ['./variaveis.component.less']
})
export class VariaveisComponent implements OnInit {
  @ViewChild('table', { static: true }) table


  @Input()
  public form: FormArray;

  formVariaveis: FormGroup;

  @Input()
  public variaveis = [];

  @Output()
  public update: EventEmitter<any> = new EventEmitter();

  dataSource: MatTableDataSource<AbstractControl>;

  atualizandoVariavel: boolean = false;
  variavelAtualizada;
  idVar: number;

  displayedColumns: string[] = [
    "opcoes",
    "nomeVariavel",
    "valorVariavel",
  ];

  constructor(private _fb: FormBuilder,
    private homeService: HomeEndpointService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.data = this.variaveis;
    this.formVariaveis = new FormGroup({
      nomeVariavel: this._fb.control('', [Validators.required]),
      valorVariavel: this._fb.control('', [Validators.required]),
    })
  }

  novaVariavel(item) {
    const form = item;
    var control = new FormGroup({
      nomeVariavel: this._fb.control(item.value.nomeVariavel, null),
      valorVariavel: this._fb.control(item.value.valorVariavel, null),
    })
    this.form.push(control);
    this.variaveis.push(control.value);
    this.dataSource.data = this.variaveis;
    this.limpaCampos();
  }

  limpaCampos() {
    this.formVariaveis.patchValue({
      nomeVariavel: '',
      valorVariavel: ''
    })
    this.formVariaveis.controls.nomeVariavel.markAsUntouched();
    this.formVariaveis.controls.valorVariavel.markAsUntouched();
    this.formVariaveis.updateValueAndValidity();
  }

  editar(row, el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
    this.variaveis.forEach(variavel => {
      if (variavel.nomeVariavel === row.nomeVariavel && variavel.valorVariavel === row.valorVariavel) {
        this.idVar = variavel.id;
      }
    })

    this.atualizandoVariavel = true;
    this.formVariaveis.get('nomeVariavel').setValue(row.nomeVariavel);
    this.formVariaveis.get('valorVariavel').setValue(row.valorVariavel);
  }

  excluir(row) {
    Swal.fire({
      title: 'Atenção',
      text: 'Tem certeza que quer excluir essa variável?',
      icon: 'info',
      confirmButtonText: 'OK',
      showCancelButton: true,
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancelar'
    }).then((data) => {
      if (data.isConfirmed) {
        this.homeService.desativarVariavel(row).subscribe((response: string) => {
          Swal.fire('Sucesso', "Variável excluída com sucesso", 'success').then(() => { this.update.emit() });
        })
      }
    })
  }

  atualizarVariavel() {
    this.variaveis.forEach(variavel => {
      if (this.idVar === variavel.id) {
        this.variavelAtualizada = variavel;
        this.variavelAtualizada.nomeVariavel = this.formVariaveis.controls.nomeVariavel.value;
        this.variavelAtualizada.valorVariavel = this.formVariaveis.controls.valorVariavel.value;
      }
    })

    this.homeService.atualizarVariavel(this.idVar, this.variavelAtualizada).subscribe(data => {
      Swal.fire('Sucesso', "Variável atualizada com sucesso", 'success').then(() => {
        this.atualizandoVariavel = false;
        this.update.emit();
      });
    }, error => {
      Swal.fire({
        title: 'Erro',
        text: error.error,
        icon: 'error'
      })
      console.error(error);
    })
  }
}
