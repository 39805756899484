<div [hidden]="!processando || lista" style="padding: 10px 0 20px 0">
  <div class="col-12">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div [hidden]="processando && !lista" class="borda">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="button">
      <th
        mat-header-cell
        *matHeaderCellDef
        style="padding-left: 20px !important"
      >
        Opções
      </th>
      <td mat-cell *matCellDef="let row" class="w-1">
        <button mat-button class="mat-button" [matMenuTriggerFor]="menu">
          <span class="mat-button-wrapper">
            <i class="fas fa-tasks"></i>
          </span>
        </button>

        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="atualizarNotificacao(row)"
            [disabled]="row.visualizou"
          >
            <i class="fas fa-eye"></i>
            Marcar como lida
          </button>
          <button mat-menu-item (click)="abrir(row)" [disabled]="!row.url">
            <i class="fas fa-eye"></i>
            Visualizar
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="dataHora">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row" class="w-1">
        {{ row.dataHora | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- Nivel Column -->
    <ng-container matColumnDef="nivel">
      <th mat-header-cell *matHeaderCellDef>Nivel</th>
      <td mat-cell *matCellDef="let row">
        <i
          [class]="
            row.nivel === 'INFORMACAO'
              ? 'fas fa-info info-icon'
              : row.nivel === 'ALERTA'
              ? 'fas fa-flag warning-icon'
              : row.nivel === 'FALHA'
              ? 'fas fa-triangle-exclamation error-icon'
              : ''
          "
          [matTooltip]="
            row.nivel === 'INFORMACAO'
              ? 'Informação'
              : row.nivel === 'ALERTA'
              ? 'Alerta'
              : row.nivel === 'FALHA'
              ? 'Erro'
              : ''
          "
        ></i>
      </td>
    </ng-container>

    <!-- Tipo Column -->
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let row">
        <i
          [class]="
            row.tipo === 'BOAS_VINDAS'
              ? 'fas fa-solid fa-handshake info-icon'
              : row.tipo === 'CONVITE'
              ? 'fas fa-solid fa-envelope info-icon'
              : row.tipo.includes('TRANSFERENCIA')
              ? 'fas fa-solid fa-right-left info-icon'
              : row.tipo.includes('COBRANCA')
              ? 'fas fa-solid fa-copy info-icon'
              : 'fas fa-solid fa-circle-info info-icon'
          "
          [matTooltip]="
            row.tipo === 'BOAS_VINDAS'
              ? 'Boas-vindas'
              : row.tipo === 'CONVITE'
              ? 'Convite'
              : row.tipo === 'INFORMACAO'
              ? 'Informação'
              : row.tipo.includes('TRANSFERENCIA')
              ? 'Transferência'
              : row.tipo.includes('COBRANCA')
              ? 'Cobrança'
              : ''
          "
        ></i>
      </td>
    </ng-container>

    <!-- Mensagem Column -->
    <ng-container matColumnDef="mensagem">
      <th mat-header-cell *matHeaderCellDef>Mensagem</th>
      <td mat-cell *matCellDef="let row">
        {{ row.mensagem }}
      </td>
    </ng-container>

    <!-- Mensagem Column -->
    <ng-container matColumnDef="visualizou">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <i
          *ngIf="row.visualizou"
          class="fas fa-check icones"
          style="color: rgb(114, 192, 118)"
          matTooltip="Visualizado"
        ></i>
        <i
          *ngIf="!row.visualizou"
          class="fas fa-times icones"
          style="color: #fe431c"
          matTooltip="Não visualizado"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div *ngIf="!processando && lista?.totalElements === 0" class="sem-resultado">
    <i class="fas fa-scroll fa-2x"></i>
    <p>Ainda não há notificações</p>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 30, 50, 100]"
    [length]="lista?.totalElements"
  >
  </mat-paginator>
</div>
