<div [hidden]="!processando" style="padding: 10px 0 20px 0;">
  <div class="col-12">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<!-- mudar aqui pra ngIf -->
<div [hidden]="processando" class="borda">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <!-- Button Column -->
    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 20px !important;"></th>
      <td mat-cell *matCellDef="let row" class="w-1">
        <!-- <button mat-button class="mat-button" [matMenuTriggerFor]="menu">
          <span class="mat-button-wrapper">
            <i class="fas fa-tasks"></i>
          </span>
        </button> -->
      </td>
    </ng-container>

    <!-- Nome Column -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
    </ng-container>

    <!-- dataCriacao Column -->
    <ng-container matColumnDef="dataCriacao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Criado em</th>
      <td mat-cell *matCellDef="let row">{{ row.dataCriacao | date:'dd/MM/yyyy' || ' - ' }}</td>
    </ng-container>

    <!-- documento Column -->
    <ng-container matColumnDef="documento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Documento</th>
      <td mat-cell *matCellDef="let row">
        <i (click)="visualizarArquivo(row)"  class="fas fa-cloud-download-alt icones corPrimaria"
          id="dropdown-icon"></i>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="expandedDetail" *ngIf="tipoLista == 'visualizar' || tipoLista === 'gerenciar'">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" style="padding-left: 70px">
        <div class="example-element-description"
          [@detailExpandAnimate]="row == expandedElement ? 'expanded' : 'collapsed'">
          <div *ngFor="let arquivo of row.arquivos" class="row">
            <div class="col-4" id="titulo">
              {{ arquivo.nome }}
            </div>
            <div class="col-4">
              <a (click)="visualizarArquivo(arquivo)" id="link">Visualizar arquivo</a>
            </div>
          </div>
        </div>
      </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
</div>