<div mat-dialog-content class="div-body">
    <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
        <div class="row" style="justify-content: center;" *ngIf="data.tipo == 'solicitar'">
            <div class="col-12" style="display: flex;
                flex-direction: column;
                height: 74vh;
                justify-content: space-between;">
                <div class="card">
                    <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="!processando">
                        <div class="box-body">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 30%;"
                                class="svg-interrogacao">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M9 9.00001C9.00011 8.45004 9.15139 7.91068 9.43732 7.44088C9.72325 6.97108 10.1328 6.58891 10.6213 6.33616C11.1097 6.08341 11.6583 5.96979 12.2069 6.00773C12.7556 6.04566 13.2833 6.23369 13.7323 6.55126C14.1813 6.86883 14.5344 7.30372 14.7529 7.8084C14.9715 8.31308 15.0471 8.86813 14.9715 9.41288C14.8959 9.95763 14.6721 10.4711 14.3244 10.8972C13.9767 11.3234 13.5185 11.6457 13 11.829C12.7074 11.9325 12.4541 12.1241 12.275 12.3775C12.0959 12.6309 11.9998 12.9337 12 13.244V14.5"
                                        stroke="#1d8f91" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path d="M12 18V18.5001" stroke="#1d8f91" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path
                                        d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
                                        stroke="#1d8f91" stroke-width="1.5" stroke-miterlimit="10"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <h2 mat-dialog-title class="titulo-modal">
                        Você tem certeza que deseja realizar a portabilidade?
                    </h2>
                    <div class="box-body info-modal">
                        A portabilidade da chave Pix é um processo simples que permite que você transfira sua chave
                        cadastrada em uma instituição financeira para outra de sua escolha.
                    </div>
                    <div class="box-body info-subtexto">
                        Essa ação não gera custos e pode ser feita de maneira rápida e segura.
                    </div>
                    <div class="box-body info-modal comprovante" style="display: flex;justify-content: space-between; background-color: white;
                        ">
                        <div class="comprovante">

                            <div id="linha">
                                <p style="text-align: left;">Nome: {{ data.data?.nome }}</p>
                                <p>CPF/CNPJ: {{ data.data?.cpfCnpj | cpfCnpj}}</p>
                                <p>Chave pix: {{ data.data?.chave }}</p>
                                <p>Instituição: {{ data.data.banco?.nomeReduzido }}</p>
                                <p>Agência: {{ data.data?.nrAgencia?.padStart(4, '0') }} </p>
                                <p>Conta: {{ data.data?.nrConta?.slice(0, -1) + ' - ' + data.data?.nrConta?.slice(-1) }}
                                </p>
                                <br>
                                <div id="linha-vertical">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body div-btn" style="margin-top: 25px; 
                    display: flex;
                    justify-content: space-evenly;">
                    <button class="btn btn-block btn-secundario btn-save" mat-raised-button (click)="fechar()">
                        <span style="font-size: 16px">Não</span>
                    </button>
                    <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                        (click)="realizarPortabilidade()">
                        <span style="font-size: 16px">Sim</span>
                    </button>
                </div>
            </div>
        </div>


        <div class="row" style="justify-content: center;" *ngIf="data.tipo == 'consultar'">
            <div class="col-12" style="display: flex;
                flex-direction: column;
                height: 492px;
                justify-content: space-between;">
                <div class="card">
                    <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="!processando">
                        <div class="box-body">
                        </div>
                    </div>
                    <h2 mat-dialog-title class="titulo-modal">
                        INFORMAÇÕES DA PORTABILIDADE
                    </h2>
                    <div *ngIf="data.data?.stReivindicacao != '2'">
                        <div class="box-body info-modal">
                            Você tem até dia <span class="realce">{{ data.data?.dtHrLimiteResolucao | date:'dd/MM/yyyy
                                HH:mm' }}</span> para
                            a aceitar a portabilidade no banco <span class="realce">{{ data.data.banco?.nomeReduzido
                                }}</span>
                        </div>
                        <div class="box-body info-subtexto">
                            Após esse prazo, a portabilidade será cancelada.
                        </div>
                    </div>
                    <div *ngIf="data.data?.stReivindicacao == '2'">
                        <div class="box-body info-modal">
                            Você aprovou sua portabilidade no banco <span class="realce">{{
                                data.data.banco?.nomeReduzido
                                }}</span>
                        </div>
                        <div class="box-body info-subtexto">
                            Para começar a usar sua chave Pix no <span class="realce">{{ env.nomeApp }}</span>, é só
                            confirmar a portabilidade.
                        </div>
                    </div>

                    <div class="box-body info-modal comprovante" style="display: flex;justify-content: space-between; background-color: white;
                        ">

                        <div class="comprovante" style="width: 100%;">
                            <div style="display: flex;
                                width: 125%;
                                justify-content: space-around; padding-top: 8px;" *ngIf="data.sucesso">
                                <div *ngIf="data.sucesso">
                                    <i class="fa-brands fa-pix" style="font-size: 30px;"></i>
                                    <span style="padding-left: 10px;
                                    font-weight: 500;">INFORMAÇÕES DA PORTABILIDADE</span>
                                </div>
                            </div>
                            <div id="linha">
                                <p>CPF/CNPJ: {{ data.data?.cpfCnpj | cpfCnpj}}</p>
                                <p>Chave pix: {{ data.data?.chave }}</p>
                                <p>Agência: {{ data.data?.nrAgencia.padStart(4, '0') }} </p>
                                <p>Conta: {{ data.data?.nrConta.slice(0, -1) + ' - ' + data.data?.nrConta.slice(-1) }}
                                <p>Status: {{ checaStatus(data.data?.stReivindicacao) }}</p>
                                <br>
                                <div id="linha-vertical">
                                </div>
                            </div>
                            <div *ngIf="data.data?.stReivindicacao == '2'" style="margin: auto; padding-bottom: 5px;">
                                <span class="realce">Aprove a baixo</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;">
                    <div class="box-body div-btn" style="display: flex;justify-content: space-evenly; width: 100%;">
                        <button class="btn btn-block btn-secundario btn-save" mat-raised-button (click)="fechar()"
                            [disabled]="processando" style="width: 10vw;">
                            <span style="font-size: 16px; width: 100%">VOLTAR</span>
                        </button>
                        <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                            (click)="realizarPortabilidade()" style=" width: 10vw;"
                            *ngIf="data.data?.stReivindicacao != '2'"
                            [disabled]="data.data.stReivindicacao == 1 || processando">
                            <span style="font-size: 16px; width: 160px;">CONFIRMAR</span>
                        </button>

                        <button *ngIf="data.data?.stReivindicacao == '2'" class="btn btn-block btn-secundario btn-save"
                            mat-raised-button (click)="confirmarPortabilidade()" style=" width: 10vw;">
                            <span style="font-size: 16px; width: 160px;" [disabled]="processando">CONFIRMAR</span>
                        </button>

                    </div>
                </div>
                <div class="box-body info-subtexto">
                    Deseja cancelar sua portabilidade? <a (click)="cancelarReivindicacao()"
                        style="cursor: pointer;"><u>Clique aqui</u></a>
                </div>
            </div>
        </div>
    </div>
</div>