<div class="container card-plano">

    <div class="col-12 col-md-12">
        <div id="titulo">
            <span style="display: flex;
            align-items: center;"> <i class="fa-brands fa-pix" style="font-size: 40px;"></i>
                <h3 style="margin-left: 10px;"><strong>AJUSTE DE LIMITE PIX</strong></h3>
            </span>
            <p style="margin-left: 52px;">Ajuste seus limites de transferência.</p>
        </div>
    </div>

    <div id="chaves-ativas" style="margin-top: 25px;">
        <span class="sub-title">
            Ajustar limite diurno (06h-20h)
            <p class="sub-second-title">
                Digite ou arraste a barra para ajustar o limite diurno.
            </p>
        </span>
        <div id="ativas-container">
            <div class="ativas">
                <span style="display: flex; margin-top: -31px;">
                    <mat-form-field appearance="outline" style="padding-left: 20px;">
                        <input matInput type="text" [(ngModel)]="limiteDiurno" readonly currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                    </mat-form-field>
                    <div class="slider-valor">
                        <span>{{ configuracaoEmpresa.valorMinimoTransferenciaPix | currency: "BRL":"R$" }}</span>
                        <mat-slider [min]="configuracaoEmpresa.valorMinimoTransferenciaPix"
                            [max]="configuracaoEmpresa.valorMaximoTransferenciaDiurnoPix" [(ngModel)]="limiteDiurno"
                            [value]="limiteDiurno" showTickMarks discrete thumbLabel size="large"
                            (change)="limiteEvent($event, 'diurno')" class="slider-tamanho">
                            <input matSliderThumb>
                        </mat-slider>
                        <span>{{ configuracaoEmpresa.valorMaximoTransferenciaDiurnoPix | currency: "BRL":"R$" }}</span>
                    </div>
                </span>
            </div>
            <!-- <span class="sub-aumento">
                <p>
                    Pedir aumento do limite diurno >
                </p>
            </span> -->
        </div>

        <span class="sub-title">
            Ajustar limite noturno (20h-06h)
            <p class="sub-second-title">
                Digite ou arraste a barra para ajustar o limite noturno.
            </p>
        </span>
        <div id="ativas-container">
            <div class="ativas">
                <span style="display: flex; margin-top: -31px;">
                    <mat-form-field appearance="outline" style="padding-left: 20px;">
                        <input matInput type="text" [(ngModel)]="limiteNoturno" readonly currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                    </mat-form-field>
                    <div class="slider-valor">

                        <span>{{ configuracaoEmpresa.valorMinimoTransferenciaPix | currency: "BRL":"R$" }}</span>
                        <mat-slider [min]="configuracaoEmpresa.valorMinimoTransferenciaPix"
                            [max]="configuracaoEmpresa.valorMaximoTransferenciaNoturnoPix" [value]="limiteNoturno"
                            (change)="limiteEvent($event, 'noturno')" [(ngModel)]="limiteNoturno" showTickMarks discrete
                            thumbLabel class="slider-tamanho">
                            <input matSliderThumb>
                        </mat-slider>
                        <span>{{ configuracaoEmpresa.valorMaximoTransferenciaNoturnoPix | currency: "BRL":"R$" }}</span>
                    </div>

                </span>
            </div>
            <!-- <span class="sub-aumento">
                <p>
                    Pedir aumento do limite notornu >
                </p>
            </span> -->
        </div>


        <span class="sub-title">
            Ajustar limite diário
            <p class="sub-second-title">
                Digite ou arraste a barra para ajustar o limite diário.
            </p>
        </span>
        <div id="ativas-container">
            <div class="ativas">
                <span style="display: flex;margin-top: -31px;">
                    <mat-form-field appearance="outline" style="padding-left: 20px;">
                        <input matInput type="text" [(ngModel)]="limiteDiario" readonly currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                    </mat-form-field>
                    <div class="slider-valor">
                        <span>{{configuracaoEmpresa.valorMinimoTransferenciaPix | currency: "BRL":"R$" }}</span>
                        <mat-slider [min]="configuracaoEmpresa.valorMinimoTransferenciaPix"
                            [max]="configuracaoEmpresa.valorMaximoLimiteDiarioPix" [value]="limiteDiario"
                            (change)="limiteEvent($event, 'diario')" [(ngModel)]="limiteDiario" showTickMarks discrete
                            thumbLabel class="slider-tamanho">
                            <input matSliderThumb>
                        </mat-slider>
                        <span> {{ configuracaoEmpresa.valorMaximoLimiteDiarioPix | currency: "BRL":"R$" }}</span>
                    </div>
                </span>
            </div>
            <!-- <span class="sub-aumento">
                <p>
                    Pedir aumento do limite diário >
                </p>
            </span> -->
        </div>
    </div>

</div>