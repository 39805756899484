<div style="height: 100% !important; flex-direction: column; display: flex;">
    <div mat-dialog-title class="titulo-modal" fxLayout="row">
        <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
        
        </div>
        <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">Informações do signatário</div> 
        <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
            <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="onClose()">FECHAR</p>
            <i style="margin-right: 10px;"(click)="onClose()" class="material-icons btn-fechar" >close</i>
        </div>
    </div>  
    <div style="background-color:#EEE; height: 100%">
        <div class="box-body" style="padding: 1vw; margin: 1vw">
            <p class="title-row">Dados do signatário</p>
            <p class="title-menor"><b>Nome:</b> {{signatario?.nome}}</p>
            <p class="title-menor"><b>Documento:</b> {{signatario?.documento}}</p>
            <p class="title-menor"><b>Telefone:</b> {{signatario?.telefone}}</p>
            <p class="title-menor"><b>E-mail:</b> {{signatario?.email}}</p>
            <p class="title-menor"><b>Autenticação principal:</b> </p>
            <span style="padding-left: 2rem; display: block !important">
                <p *ngIf="signatario?.auths?.email">E-mail</p>
                <p *ngIf="signatario?.auths?.certificado">Certificado digital</p>
                <p *ngIf="signatario?.auths?.whatsapp">Whatsapp</p>
                <p *ngIf="signatario?.auths?.pix">Pix</p>
                <p *ngIf="signatario?.auths?.sms">SMS</p>
            </span>

            <p class="title-menor" *ngIf="signatario?.auths?.assinatura
            || signatario?.auths?.autenticacao
            || signatario?.auths?.documento
            || signatario?.auths?.selfie
            || signatario?.auths?.biometria"><b>Outras autenticações:</b> </p>
            <span style="padding-left: 2rem; display: block !important">
                <p *ngIf="signatario?.auths?.assinatura">Assinatura manuscrita</p>
                <p *ngIf="signatario?.auths?.autenticacao">Liveness</p>
                <p *ngIf="signatario?.auths?.documento">Documento</p>
                <p *ngIf="signatario?.auths?.selfie">Selfie</p>
                <p *ngIf="signatario?.auths?.biometria">Biometria facial</p>
            </span>
        </div>

        <div *ngIf="vinculados && vinculados.length > 0" class="box-body" style="padding: 1vw; margin: 1vw">
            <p class="title-row">Vínculos do signatario</p>
            <p class="title-menor" *ngFor="let doc of vinculados">
                {{doc.documento}}
            </p>
        </div>
    </div>
</div>
