import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootEndpointService } from '../../service/root-endpoint.service';
import Swal from 'sweetalert2';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-config-apps',
  templateUrl: './config-apps.component.html',
  styleUrls: ['./config-apps.component.less']
})
export class ConfigAppsComponent implements OnInit {
  public form: FormGroup;
  processando: boolean = false;
  config = {
    urlAppAndroid: '',
    urlAppApple: '',
  }

  constructor(
    public dialogRef: MatDialogRef<ConfigAppsComponent>,
    private rootService: RootEndpointService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.processando = true;
    this.getConfig();
    this.form = new FormGroup({
      urlAppAndroid: new FormControl(),
      urlAppApple: new FormControl()
    });
  }

  salvaConfig() {
    this.config.urlAppAndroid = this.form.get('urlAppAndroid').value;
    this.config.urlAppApple = this.form.get('urlAppApple').value;

    this.rootService.updateConfiguracoesSistema(this.config).pipe().subscribe(
      (response) => {
        this.modalFinal('Configurações foram atualizadas com sucesso!', response);
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error, 'error');
      });
  }

  modalFinal(titulo, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: ``,
        btnTexto: 'Rever configurações',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    }).afterClosed()
      .pipe()
      .subscribe(filtroData => {
        this.fecha();
      });
  }

  getConfig() {
    this.rootService.getConfiguracoesSistema().pipe().subscribe(
      (response) => {
        this.config = response;
        this.form.get("urlAppAndroid").setValue(response.urlAppAndroid)
        this.form.get("urlAppApple").setValue(response.urlAppApple)
        this.processando = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  fecha() {
    this.dialogRef.close();
  }
}
