<form [formGroup]="form">
  <div class="col-12" style="margin-bottom: 5px">
    <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">CELULAR NOTIFICAÇÕES
    </h4>
  </div>

  <app-telefone-notificacao [form]="form.get('telefoneNotificacao')" [ehTelefoneNotificacao]="true" [data]="data">
  </app-telefone-notificacao>
  <hr>
  <div class="col-12" style="margin-bottom: 5px">
    <h4 class="corPrimaria" style="font-weight: bolder; margin-top: 12px">EMAIL NOTIFICAÇÕES
    </h4>
  </div>
  <app-email-notificacao [data]="data" [form]="form.get('emailNotificacao')"></app-email-notificacao>
  <hr>

  <div class="alinha">
    <div class="w-100">
      <h4 class="titulo-maior">NOTIFICAÇÕES PARA FATURAS ANTES DO VENCIMENTO
      </h4>
    </div>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Criação de novas faturas
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando você gerar uma nova fatura."></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorNovasCobrancas" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorNovasCobrancas" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorNovasCobrancas" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorNovasCobrancas" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>

    <hr>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Avisar alteração no valor ou data de vencimento das faturas
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando você alterar a data de vencimento ou o valor das faturas."></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas"
              matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas"
              matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas"
              matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas"
              matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>

    <hr>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left w-100">
        <h5 class="titulo">Enviar faturas
          {{ form.get('diasAntesDoVencimentoParaRelembrarProximasCobrancas').value }}
          dias antes do vencimento
          <div class="input-menor d-flex justify-content-start w-100 input-texto-numero">
            <span> Esta mensagem será enviada quando faltar </span>
            <mat-form-field appearance="outline">
              <input matInput type="number" min="0"
                formControlName="diasAntesDoVencimentoParaRelembrarProximasCobrancas" />
            </mat-form-field>
            <span> dias para o vencimento das faturas. E será recorrente</span>
          </div>
        </h5>
      </div>
    </div>
    <hr>
    <div class="create d-flex justify-content-between w-100">
      <div class="text-left w-100">
        <h5 class="titulo">Enviar lembrete individual
          {{ form.get('diasAntesDoVencimentoParaEnvioLembreteIndividual').value }}
          dias antes do vencimento
          <div class="input-menor d-flex justify-content-start w-100 input-texto-numero">
            <span> Esta mensagem será enviada quando faltar </span>
            <mat-form-field appearance="outline">
              <input matInput type="number" min="0"
                formControlName="diasAntesDoVencimentoParaEnvioLembreteIndividual" />
            </mat-form-field>
            <span> dias para o vencimento das faturas.</span>
          </div>
        </h5>
      </div>
    </div>

    <hr>

    <div class="w-100">
      <h4 class="titulo-maior">NOTIFICAÇÕES PARA FATURAS VENCIDAS
      </h4>
    </div>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Avisar sobre atrasos e falhas nos pagamentos
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando o seu cliente deixar de pagar uma fatura."></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorCobrancasAtrasadas" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorCobrancasAtrasadas" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorCobrancasAtrasadas" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorCobrancasAtrasadas" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>

    <hr>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left w-100">
        <h5 class="titulo">Relembrar faturas vencidas a cada
          {{ form.get('diasAposVencimentoParaRelembrarCobrancaVencida').value }}
          dias após o vencimento
          <div class="input-menor d-flex justify-content-start w-100 input-texto-numero">
            <span> Esta mensagem será enviada a cada </span>
            <mat-form-field appearance="outline">
              <input matInput type="number" min="0" formControlName="diasAposVencimentoParaRelembrarCobrancaVencida" />
            </mat-form-field>
            <span> dias enquanto a fatura não for paga.</span>
          </div>
        </h5>
      </div>
    </div>

    <hr>

    <div class="w-100">
      <h4 class="titulo-maior">NOTIFICAÇÕES PARA FATURAS PAGAS
      </h4>
    </div>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Avisar quando os pagamentos forem confirmados
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando os pagamentos das faturas forem confirmados."></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorPagamentosConfirmados"
              matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorPagamentosConfirmados" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorPagamentosConfirmados"
              matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorPagamentosConfirmados" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>

    <hr>

    <div class="w-100">
      <h4 class="titulo-maior">NOTIFICAÇÕES PARA TODAS AS FATURAS
      </h4>
    </div>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Avisar remoção de faturas
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando você remover uma fatura." aria-hidden="false"></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorAoRemoverCobranca" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorAoRemoverCobranca" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorAoRemoverCobranca" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorAoRemoverCobranca" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>

    <hr>

    <div class="create d-flex justify-content-between w-100">
      <div class="text-left">
        <h5 class="titulo">Avisar visualização de faturas
          <i class="fas fa-info-circle info-icon"
            matTooltip="Esta mensagem será enviada quando uma fatura for visualizada."></i>
        </h5>
      </div>

      <div class="d-flex justify-content-center">
        <div class="text-center">
          <span class="yellow">Para mim</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarCobradorAoVisualizarFatura" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarCobradorAoVisualizarFatura" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>

        <div class="text-center">
          <span class="yellow">Para meu cliente</span>
          <figure class="d-flex justify-content-center">
            <app-input-ativar-desativar formControlName="smsNotificarPagadorAoVisualizarFatura" matIcon="phone_iphone">
            </app-input-ativar-desativar>
            <app-input-ativar-desativar formControlName="emailNotificarPagadorAoVisualizarFatura" matIcon="email">
            </app-input-ativar-desativar>
          </figure>
        </div>
      </div>
    </div>
  </div>
</form>