import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputAtivarDesativarComponent } from './input-ativar-desativar.component';
import { IconsModule } from '@app/icons.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [InputAtivarDesativarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule
  ],
  exports: [
    InputAtivarDesativarComponent
  ]
})
export class InputAtivarDesativarModule { }
