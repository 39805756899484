import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DealsignService } from '../../service/dealsign.service';

@Component({
  selector: 'app-integracao-dealsign',
  templateUrl: './integracao-dealsign.component.html',
  styleUrls: ['./integracao-dealsign.component.less']
})
export class IntegracaoDealsignComponent implements OnInit {

  public processando: boolean = false;

  public email: string = '';
  public tokenUuid: string = '';
  public folderUuid: string = '';
  public url: string = '';

  constructor(
    public dialogRef: MatDialogRef<IntegracaoDealsignComponent>,
    private dealsignService: DealsignService,
  ) { }

  ngOnInit(): void {
    this.getConfiguracoes();
  }

  fecha() {
    this.dialogRef.close();
  }

  getConfiguracoes() {
    this.processando = true;
    this.dealsignService.getConfig().toPromise().then(res => {
      this.email = res.email;
      this.tokenUuid = res.tokenUuid;
      this.folderUuid = res.folderUuid;
      this.url = res.url;

      this.processando = false;
    });
  }

  testar() {
    this.processando = true;
    this.dealsignService.testar({
      email: this.email,
      tokenUuid: this.tokenUuid,
      folderUuid: this.folderUuid,
    }).toPromise().then(res => {
      Swal.fire('Sucesso', res, 'success');
      this.processando = false;
    }, erro => {
      Swal.fire('Erro!', erro.error, 'error');

      this.processando = false;
    })
  }

  salvar() {
    this.processando = true;
    this.dealsignService.setConfig({
      email: this.email,
      tokenUuid: this.tokenUuid,
      folderUuid: this.folderUuid,
    }).toPromise().then(res => {
      this.processando = false;
      Swal.fire('Sucesso', 'Configuração salva com sucesso!', 'success').then(() => {
        this.fecha();
      });
    }, err => {
      this.processando = false;
      Swal.fire('Erro', err.error, 'error');
    });

  }

}
