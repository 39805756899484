<h2 mat-dialog-title class="titulo-modal">
    SENSIBILIZAÇÃO <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<div mat-dialog-content class="mat-dialog-content-responsive">
    <form [formGroup]="form" class="mudaCor input-size">
        <div class="row">
            <div class="col-9">
                <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-exchange-alt" style="margin-right: 8px"></i>
                            Transferência
                        </ng-template>
                        <app-transferencia-sensibilizacao style="width: 100%;" (isEntrada)="changeTipoTransf($event)"
                            (validaConta)="ValidaConta($event)" [form]="form.get('transferencia')"
                            [data]="data"></app-transferencia-sensibilizacao>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-dollar-sign" style="margin-right: 8px"></i>
                            Taxas
                        </ng-template>
                        <app-taxa-sensibilizacao style="width: 100%;" (isEntrada)="changeTipoTaxa($event)"
                            (validaConta)="ValidaConta($event)" [form]="form.get('taxas')"
                            [data]="data"></app-taxa-sensibilizacao>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="col-3 resumo">
                <div *ngIf="selectedTab == 0; else outroResumo">
                    <p class="super-title" style="padding-bottom: 5px">RESUMO DA TRANSFERÊNCIA</p>
                    <p style="text-align:center; padding-top: 5px">Você está transferindo para uma conta {{ env.nomeApp
                        }} - <b>{{form.get("transferencia").value?.tipoTransferencia || ""}}</b></p>
                    <br />
                    <p *ngIf="( form.get('transferencia').value?.nomCliA
                             || (form.get('transferencia') != null && form.get('transferencia.cNPJCPFCliA').valid)
                             || form.get('transferencia').value?.ctA
                             || form.get('transferencia').value?.agencia)" style="color: #4A494A;"><b>{{isEntradaTransf
                            ? "DEBITADO" : "CREDITADO"}} --</b></p>
                    <p *ngIf="form.get('transferencia').value?.nomCliA"><b>Nome:</b> {{
                        form.get("transferencia").value?.nomCliA || "" }}</p>
                    <p *ngIf="(form.get('transferencia') != null && form.get('transferencia.cNPJCPFCliA').valid)">
                        <b>CPF/CNPJ:</b> {{ (form.get("transferencia") != null &&
                        form.get("transferencia.cNPJCPFCliA").valid) ?
                        stringFormat.adicionarPontosDocumento(form.get("transferencia").value.cNPJCPFCliA.replace(expr,
                        "")) : "" }}</p>
                    <p *ngIf="form.get('transferencia').value?.ctA"><b>{{ form.get("transferencia").value?.tpCtA || "CC"
                            }}</b> {{ form.get("transferencia").value?.ctA || "" }}</p>
                    <p *ngIf="form.get('transferencia').value?.agencia"><b>Agência:</b> {{
                        form.get("transferencia").value?.agencia || "" }}</p>
                    <br />
                    <p *ngIf="( nomeCreditd
                             || (form.get('transferencia') != null && form.get('transferencia.cNPJCPFCliB').valid)
                             || form.get('transferencia').value?.ctB)" style="color: #4A494A;"><b>{{isEntradaTransf ?
                            "CREDITADO" : "DEBITADO" }} --</b></p>
                    <p *ngIf="nomeCreditd"><b>Nome:</b> {{ nomeCreditd || "" }}</p>
                    <p *ngIf="(form.get('transferencia') != null && form.get('transferencia.cNPJCPFCliB').valid)">
                        <b>CPF/CNPJ:</b> {{ (form.get("transferencia") != null &&
                        form.get("transferencia.cNPJCPFCliB").valid) ?
                        stringFormat.adicionarPontosDocumento(form.get("transferencia").value.cNPJCPFCliB.replace(expr,
                        "")) : "" }}</p>
                    <p *ngIf="form.get('transferencia').value?.ctB"><b>CC:</b> {{ form.get("transferencia").value?.ctB
                        || "" }}</p>
                    <br />
                    <p *ngIf="form.get('transferencia').value?.vlrLanc" style="color: #4A494A;"><b>Valor:</b> {{
                        (form.get("transferencia").value?.vlrLanc || "") | currency: 'BRL': 'R$' }}</p>
                    <p *ngIf="form.get('transferencia').value?.dtBC && (form.get('transferencia') != null && form.get('transferencia.hrBC').valid)"
                        style="color: #4A494A;"><b>Data:</b> {{ (form.get('transferencia').value?.dtBC &&
                        (form.get('transferencia') != null && form.get('transferencia.hrBC').valid)) ?
                        this.form.get("transferencia.dtBC").value.toISOString().split('T')[0] +"T"
                        +this.form.get("transferencia.hrBC").value : "" }}</p>
                </div>
                <ng-template #outroResumo>
                    <p class="super-title" style="padding-bottom: 5px">RESUMO DA TAXA</p>
                    <p style="text-align:center; padding-top: 5px">Você está taxando para uma conta {{ env.nomeApp }} -
                        <b>{{form.get("taxas").value?.tipoTransferencia || ""}}</b></p>
                    <br />
                    <p *ngIf="( nomeDebtd
                             || (form.get('taxas') != null && form.get('taxas.cNPJCPFCli').valid)
                             || form.get('taxas').value?.ct )" style="color: #4A494A;"><b>{{isEntradaTaxa ? "CREDITADO"
                            : "DEBITADO"}} --</b></p>
                    <p *ngIf="nomeDebtd"><b>Nome:</b> {{ nomeDebtd || "" }} </p>
                    <p *ngIf="(form.get('taxas') != null && form.get('taxas.cNPJCPFCli').valid)"><b>CPF/CNPJ:</b> {{
                        (form.get("taxas") != null && form.get("taxas.cNPJCPFCli").valid) ?
                        stringFormat.adicionarPontosDocumento(form.get("taxas").value.cNPJCPFCli.replace(expr, "")) : ""
                        }}</p>
                    <p *ngIf="form.get('taxas').value?.ct"><b>CC: </b> {{ form.get("taxas").value?.ct || "" }}</p>
                    <br />
                    <p *ngIf="form.get('taxas').value?.vlrLanc" style="color: #4A494A;"><b>Valor:</b> {{
                        (form.get("taxas").value?.vlrLanc || "") | currency: 'BRL': 'R$' }}</p>
                    <p *ngIf="showData && form.get('taxas').value?.dtHrBC" style="color: #4A494A;"><b>Data:</b> {{
                        form.get("taxas").value?.dtHrBC.toISOString().replace(".000Z", "") || "" }}</p>
                </ng-template>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="row botao-centro">
        <div class="col-12 col-md-4">
            <button [disabled]="processando" type="button" (click)="fecha()" class="btn btn-default btn-block"
                style="color: #fff;margin-left: 55%;" mat-raised-button>
                Cancelar
            </button>
        </div>

        <div class="col-12 col-md-4">
            <button [disabled]="processando" type="submit" class="btn btn-block fundoCorPrimaria" (click)="onSubmit()"
                style=" color: #fff;margin-left: 70%;" mat-raised-button>
                Enviar
            </button>
        </div>
    </div>
</div>