import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageResponse } from '@model/page-response.model';
import { ListaMensagensDTO } from '@project/src/app/model/lista-mensagens-dto.model';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-ocorrencias-protesto',
  templateUrl: './listar-ocorrencias-protesto.component.html',
  styleUrls: ['./listar-ocorrencias-protesto.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListarOcorrenciasProtestoComponent implements OnDestroy, AfterViewInit, OnChanges, OnInit {
  processando = false;
  private destroy = new Subject();

  @Input()
  listaOcorrenciasProtesto: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public dataSource = new MatTableDataSource<any>();
  
  displayedColumns: string[] = [
    'dataCriacao',
    'dataHora',
    'status',
    'statusMensagem',
    'dataProtocolo',
    'cartorioCodigo',
    'cartorioProtocolo',
    'custas',
  ];

  expandedElement: Custas | null;
  
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private cobrancaService: CobrancaEndpointService,
  ) { }

  ngOnInit(): void {
    this.dataSource.data = this.listaOcorrenciasProtesto;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.listaOcorrenciasProtesto;
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
export interface Custas {
  custasDistribuidor: string;
  custasCartorio: string;
  custasGravacao: string;
  custasDespesas: string;
  custasConfirmacao: string;
  custasRetorno: string;
  custasGravacaoEletronica: string;
  custasDistribuicaoConfirmacao: string;
  custasDistribuicaoRetorno: string;
}
