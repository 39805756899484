import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmpresaDTO } from '@model/pessoa/empresa-dto.model';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ValidacoesService } from '@project/src/app/service/validacoes.service';
@Component({
  selector: 'app-representante-legal',
  templateUrl: './representante-legal.component.html',
  styleUrls: ['./representante-legal.component.less']
})
export class RepresentanteLegalComponent implements OnInit, OnChanges, OnDestroy {
  subscription: Subscription;

  @Input()
  public form: FormArray;

  contDocumentacao: number = 0;

  @Input()
  dataMinima = moment().subtract(150, 'years').toDate();

  @Input()
  dataMaxima = moment().subtract(18, 'years').toDate();

  @Input()
  public data = [];

  public mascara: string[] = [];

  public indiceRepresentante: number = 0;

  public tipoDocumento: string[] = [];

  constructor(private fb: FormBuilder, private dialog: MatDialog, private verifica: ChangeDetectorRef, private validacoesService: ValidacoesService,) { }

  indiceForm: number;
  flagStopLoopForm: number = 1;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue) {
      this.form.clear();
      this.addRepresentantes();
    }
  }

  ngOnInit() {
    if (this.data && this.data.length > 0) {
      this.data.forEach(element => this.add(element));
    } else if (this.form.length === 0) {
      this.add();
    }
  }

  addRepresentantes() {
    if (this.data && this.data.length > 0) {
      this.data.forEach(element => this.add(element));
    } else if (this.form.length === 0) {
      this.add();
    }
  }

  ngOnDestroy() { }

  public add(representante?: any) {
    representante = representante || '';
    this.form.push(
      this.fb.group({
        nome: this.fb.control(representante.nomeRepresentante || '', [Validators.required, Validators.pattern(/^[A-Za-z\u00C0-\u00FF]+(?:\s[A-Za-z\u00C0-\u00FF]+)+$/)]),
        telefone: this.fb.control(representante.telefoneRepresentante || '', [Validators.required]),
        email: this.fb.control(representante.emailRepresentante || '', [Validators.required, Validators.email]),
        documento: this.fb.control(representante.documento || '', [Validators.required]),
        dataNascimento: this.fb.control(representante.dataNascimento || '', [Validators.required]),
        tipoRepresentante: this.fb.control('socioDiretor'),
        pessoaPoliticamenteExposta: this.fb.control(representante.politicamenteExposta || false, [Validators.required]),
        colorIcon: this.fb.control(representante.colorIcon || null)
      })
    );
    this.setarDocumento(this.form.length - 1, 'CPF')
  }

  validaDocumento(form, indice?: any) {
    if (form.value) {
      if (form.value.length === 11 && this.tipoDocumento[indice] == 'CPF' && this.validacoesService.validaCPF(form.value)) {
        this.form.controls[this.indiceForm].get('documento').setErrors(null);
        this.form.controls[this.indiceForm].get('documento').clearValidators()
      } else if (form.value.length === 14 && this.tipoDocumento[indice] == 'CNPJ' && this.validacoesService.validaCNPJ(form.value)) {
        this.form.controls[this.indiceForm].get('documento').setErrors(null)
      } else {
        this.form.controls[this.indiceForm].get('documento').setErrors({ 'documentoInvalido': true })
      }
    }
  }

  atualizaTudo(obj) {
    this.form.clear();
    const representantes = obj.representanteLegal || [];

    if (representantes.length > 0) {
      obj.representanteLegal.forEach(element => {
        this.add(element);
      });
    } else {
      this.add();
    }
    this.verifica.detectChanges();
  }

  public del(index) {
    this.form.removeAt(index);
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field))
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido' :
          form.get(field).hasError("documentoInvalido")
            ? "Documento inválido" :
            form.get(field).hasError('pattern')
              ? 'Campo inválido' : '';
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }

  mascaraData(index: number, value: any, field) {
    if (value)
      var mes = moment(value._d).toDate().getMonth();
    let data = document.getElementById(field + index)['value'].length <= 10 ? document.getElementById(field + index)['value'] : moment(value._d).format(mes <= 9 ? 'DD/0M/YYYY' : 'DD/MM/YYYY');
    if (!value && document.getElementById(field + index)['value'].length <= 10) {
      data = data.replace(/\D/g, "");
      data = data.replace(/(\d{2})(\d)/, "$1/$2");
      data = data.replace(/(\d{2})(\d)/, "$1/$2");
      data = data.replace(/(\d{2})(\d{2})$/, "$1$2");
    }
    document.getElementById(field + index)['value'] = data;
  }

  build(empresa: EmpresaDTO) {
    const representantes = empresa.representantes = empresa.representantes || [];
    for (const item of (this.form.value || [])) {
      representantes.push(item);
    }
    return empresa;
  }

  cpfCnpjMask(form: FormGroup, indiceRepresentante?: number) {
    const value = form.get('documento').value;
    var campo = (<HTMLInputElement>document.getElementById('documento' + indiceRepresentante)).value.replace(/[^0-9]/gim, "");
    if (value !== campo.substring(0, 10) || value.subtring(0, 10) !== campo) {
      if (campo.length > 11 || campo === '') {
        this.mascara[indiceRepresentante] = '00.000.000/0000-00'
        form.get('dataNascimento').setValue(new Date(1, 1, 1990));
      }
      else {
        this.mascara[indiceRepresentante] = '000.000.000-00'
      }
    }
  }

  setarDocumento(indice, tipo) {
    this.indiceForm = indice;
    if (tipo === 'CNPJ') {
      this.tipoDocumento[indice] = 'CNPJ';
      this.form.controls[indice].get('dataNascimento').setValue(new Date(1, 1, 1900));
      this.validaDocumento(this.form.controls[indice].get('documento'), indice);
    } else {
      this.tipoDocumento[indice] = 'CPF';
      this.form.controls[indice].get('dataNascimento').setValue(null);
      this.validaDocumento(this.form.controls[indice].get('documento'), indice);
    }
    this.verifica.detectChanges();
  }
}