<div class="container card-plano">
    <div class="col-12 col-md-12">
        <div id="titulo">
            <span style="display: flex;
            align-items: center;"> <i class="fa-brands fa-pix" style="font-size: 40px;"></i>
                <h3 style="margin-left: 10px;"><strong>CHAVES PIX DO CONTATO</strong></h3>
            </span>
            <p style="margin-left: 52px;">Cadastre, edite e exclua chaves do seu contato</p>
        </div>
    </div>


    <div id="chaves-ativas">
        <span class="title-chaves">
            Chave(s):
        </span>
        <div class="col-12">
            <mat-progress-bar [hidden]="!processando" mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="ativas-container">
            <div class="ativas" [hidden]="processando">
                <span style="display: flex;" *ngFor="let item of form.value; let i = index">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ transformTipoPix(item.tipoChave) }}</mat-label>
                        <input matInput type="text" readonly [value]="formatarChavePix(item.chave, item.tipoChave)" />
                    </mat-form-field>
                    <button matTooltip="Copiar chave"
                        style="border: 0; background-color: transparent;margin-bottom: 25px;">
                        <i class="far fa-copy" (click)="copiarChave(item.chave)" style="color: #707070;"></i>
                    </button>
                    <button matTooltip="Apagar chave" (click)="del(i)"
                        style="border: 0; background-color: transparent;margin-bottom: 25px;">
                        <i class="fas fa-trash" style="color: #707070;"></i>
                    </button>
                </span>
            </div>
            <div class="ativas" *ngIf="form.value?.length  === 0">
                <span style="display: flex;" id="titulo">
                    <p>Não identificamos nenhuma chave cadastrada.</p>
                </span>
            </div>
        </div>
    </div>

    <hr style="width: 650px; color: #D7D7D7;">

    <form [formGroup]="formLocal">
        <div id="cadastrar-chaves">
            <span class="title-chaves">Cadastrar chave(s):</span>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>Celular</mat-label>
                    <input matInput type="text" mask="(00) 0 0000-0000" class="form-disabled"
                        formControlName="celular" />
                    <mat-error>{{ getErrorMessage("celular") }}</mat-error>

                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    [disabled]="formLocal.get('celular').value?.length < 11" (click)="cadastrarPixContato('celular')">
                    ADICIONAR CHAVE
                </button>
            </div>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>CPF/CNPJ</mat-label>
                    <input matInput type="text" mask="CPF_CNPJ" formControlName="cpfCnpj" />
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria" (click)="cadastrarPixContato('cpfCnpj')"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    [disabled]="formLocal.get('cpfCnpj').value?.length < 11">
                    ADICIONAR CHAVE
                </button>
            </div>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" class="form-disabled" formControlName="email" [disabled]="formLocal.get('email').value?.length < 1 || 
                    !formLocal.get('email').value.includes('@')" />
                    <mat-error>{{ getErrorMessage("email") }}</mat-error>
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria" (click)="cadastrarPixContato('email')"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button>
                    ADICIONAR CHAVE
                </button>
            </div>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>Aleatória</mat-label>
                    <input matInput type="text" class="form-disabled" formControlName="aleatoria" />
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria" (click)="cadastrarPixContato('aleatoria')"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button>
                    ADICIONAR CHAVE
                </button>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>