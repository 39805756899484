<p class="super-title">Qual a finalidade</p>

<div class="box-body" [formGroup]="form">
  <p class="super-title">Defina se sua conta é de pessoa física ou pessoa jurídica</p>
  <div class="row" style="justify-content: space-evenly;">
    <div class="col-3 col-md-3 tipo-box"
      [ngClass]="form.get('tipoConta').value === 'pf' ? 'tipo-box-selecionado' : null" (click)="setTipoConta('pf')" *ngIf="permissaoCadastro?.permitirCadastroPessoaFisica">
      <button class="btn-close" [hidden]="form.get('tipoConta').value !== 'pf'">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">PESSOA FÍSICA</span>
      <br />
      <i class="fas fa-user"></i>
      <br />
    </div>
    <div class="col-3 col-md-3 tipo-box"
      [ngClass]="form.get('tipoConta').value === 'pj' ? 'tipo-box-selecionado' : null" (click)="setTipoConta('pj')" *ngIf="permissaoCadastro?.permitirCadastroPessoaJuridica">
      <button class="btn-close" [hidden]="form.get('tipoConta').value !== 'pj'">
        <i class="material-icons icone-selecionado">fiber_manual_record</i>
      </button>
      <span class="title">PESSOA JURÍDICA</span>
      <br />
      <i class="fas fa-building"></i>
      <br />
    </div>
  </div>
</div>