import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListaConvidarAmigoDTO } from '@model/lista-convidar-amigo-dto.model';
import { PageResponse } from '@model/page-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndicarAmigoEndpointService {

  constructor(private client: HttpClient) { }

  convidarAmigo(data): Observable<any> {
    const url = '/convidar-amigo';
    return this.client.post(url, data);
  }

  getConvidarAmigo(arg?: {
    order?: string;
    page?: number;
    query?: string;
    size?: number;
  })
    : Observable<PageResponse<ListaConvidarAmigoDTO>> {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.query) {
      params = params.append('q', `${arg.query}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/convidar-amigo';
    return this.client.get(url, { params });
  }

  getConvidarAmigoSugestao(arg?: {
    order?: string;
    page?: number;
    query?: string;
    size?: number;
    lida?: boolean;
  })
    : Observable<PageResponse<ListaConvidarAmigoDTO>> {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.query) {
      params = params.append('q', `${arg.query}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/convidar-amigo/sugestao';
    return this.client.get(url, { params });
  }

  deleteConvidarAmigo(id: any): Observable<any> {
    return this.client.delete(`/convidar-amigo/apagar/${id}`);
  }

  salvarImagem(imagem): Observable<any> {
    const url = '/convidar-amigo/salvar-imagem';
    return this.client.post(url, imagem);
  }

  retornarImagem(): Observable<any> {
    const url = '/convidar-amigo/retornar-imagem';
    return this.client.get(url);
  }

  retornarTexto(): Observable<any> {
    const url = '/convidar-amigo/retornar-texto';
    return this.client.get(url);
  }

  salvarTexto(texto): Observable<any> {
    const url = '/convidar-amigo/salvar-texto';
    return this.client.post(url, texto);
  }

  viuConvite(id): Observable<any> {
    let params = new HttpParams();
    params = params.append("convite",`${id}`)

    const url = '/convidar-amigo/convite';
    return this.client.post(url, null, {params: params});
  }

}
