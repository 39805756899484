import { MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormGroup } from '@angular/forms';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { TemporizadorService } from '@project/src/app/service/temporizador.service';
import { SensibilizacaoComponent } from '../sensibilizacao.component';
import Swal from 'sweetalert2';
import { entradaTaxa, saidaTaxa } from '../sensibilizacao.module';
import { environment } from '@project/src/environments/environment.default';
import {
  StatusExtratoEnum,
  StatusExtratoInfo,
} from '@project/src/app/model/root/status-extrato.enum';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-taxa-sensibilizacao',
  templateUrl: './taxa-sensibilizacao.component.html',
  styleUrls: ['./taxa-sensibilizacao.component.less'],
})
export class TaxaSensibilizacaoComponent {
  public numeroTelefone: string = '';
  public bloqueiaEnviarSmsAte: Date;
  public podeReenviarSms = true;
  public tempoReenvio = 2; //minutos
  public taxaStatus: any = saidaTaxa;
  private smsEnviadoEm: Date;
  yesterdayDate = new Date();
  todayDate = new Date();
  env = environment;
  public listaTaxas = Object.keys(StatusExtratoEnum);

  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  @Output()
  public isEntrada: EventEmitter<any> = new EventEmitter();

  @Output()
  public validaConta: EventEmitter<any> = new EventEmitter();

  constructor(
    private usuarioEndpointService: UsuarioEndpointService,
    private temporizadorService: TemporizadorService,
    public dialogRef: MatDialogRef<SensibilizacaoComponent>
  ) {
    this.usuarioEndpointService
      .obterUsuarioLogado()
      .toPromise()
      .then(
        (response) => {
          this.numeroTelefone = this.getUltimosNumeros(response.celular);
        },
        (error) => {
          console.error(error);
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
        }
      );

    this.todayDate.setHours(0, 0, 0, 0);
  }
  ngOnInit() {
    this.listaTaxas.sort((a, b) => a.localeCompare(b));
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, '');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d{2})$/, '$1$2');
    document.getElementById(field)['value'] = data;
  }
  mascaraHora(event: any, field: string) {
    let data = event;
    data = data.replace(/\D/g, '');
    data = data.replace(/(\d{2})(\d)/, '$1:$2');
    data = data.replace(/(\d{2})(\d{2})$/, '$1$2');
    document.getElementById(field)['value'] = data;
  }

  novoCodigoSms() {
    if (this.env.modulos.token.sms) {
      this.usuarioEndpointService
        .solicitarToken('sensibilizacao', 'sms')
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.form.get('token').enable();
            this.smsEnviadoEm =
              this.temporizadorService.setConfirmacaoEnviadoEm();
            this.bloqueiaEnviarSmsAte =
              this.temporizadorService.setBloqueiaEnviarAte(
                this.bloqueiaEnviarSmsAte,
                this.smsEnviadoEm,
                this.tempoReenvio,
                'smsEnviadoEm'
              );
            this.temporizadorService.setIntervaloNovoCodigo(
              this.bloqueiaEnviarSmsAte,
              'smsEnviadoEm'
            );

            this.temporizadorService.podeReenviarSms
              .pipe(untilDestroyed(this))
              .subscribe(
                (response) => {
                  this.podeReenviarSms = response;
                },
                (error) => {
                  console.error(error);
                }
              );
          },
          (error) => {
            Swal.fire('Atenção', error.error, 'warning');
          }
        );
    } else {
      Swal.fire(
        'Atenção',
        'Não possui permissão para solicitar token por meio de SMS',
        'warning'
      );
    }
  }

  private mascaraHoraBloqueio(valor: string, field: string): string {
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d{2})(\d)/, '$1:$2');
    valor = valor.replace(/(\d{2})(\d{2})$/, '$1$2');
    document.getElementById(field)['value'] = valor;
    return valor;
  }
  validarHora(valor: string, campoId: string) {
    valor = this.mascaraHoraBloqueio(valor, campoId);
    const horaAtual = new Date();

    // Insere os dois pontos entre horas e minutos
    const valorComPontos =
      valor.length === 4
        ? `${valor.substring(0, 2)}:${valor.substring(2, 4)}`
        : valor;
    const [hora, minuto] = valorComPontos.split(':').map(Number);

    const horaInserida = new Date(
      horaAtual.getFullYear(),
      horaAtual.getMonth(),
      horaAtual.getDate(),
      hora,
      minuto
    );

    if (horaInserida >= horaAtual) {
      // Ajuste o valor para a hora atual
      const horaFormatada = this.formatarHora(
        horaAtual.getHours(),
        horaAtual.getMinutes()
      );
      this.form.get(campoId).setValue(horaFormatada);
    }
  }

  private formatarHora(hora: number, minuto: number): string {
    return `${hora.toString().padStart(2, '0')}:${minuto
      .toString()
      .padStart(2, '0')}`;
  }

  mudaTipo(tipoTaxa: string) {
    if (tipoTaxa == 'entrada') {
      this.taxaStatus = entradaTaxa;
    } else {
      this.taxaStatus = saidaTaxa;
    }
    this.isEntrada.emit(tipoTaxa);
  }

  getUltimosNumeros(telefone) {
    if (telefone == undefined) {
      Swal.fire(
        'Cadastro incompleto',
        'Para realizar as operações, por favor verifique o telefone em Empresa-> Configurações -> Autenticação',
        'warning'
      ).then((result) => {
        this.dialogRef.close();
      });
    } else {
      const numero = String(telefone.numero);
      return (
        '(' +
        telefone.ddd +
        ')' +
        ' XXXXX-XX' +
        numero.substring(numero.length - 2, numero.length)
      );
    }
  }

  mascaraTaxa(listaTaxa: StatusExtratoEnum): string {
    let stringFormatada = StatusExtratoInfo.getNome(listaTaxa);
    return stringFormatada;
  }

  ngOnDestroy() {}
}
