import { FormControl, FormGroup } from '@angular/forms';
import { ModeracaoArquivo } from '../enum.model';
import { GenericDTO } from '../generic-dto.model';

export interface ArquivoEmpresaDTO extends GenericDTO {
  	documentoRoot: any;
	locale?: string;
	base64?: string;
	mimeType?: string;
	nome: string;
	documento: string;
	moderacao: ModeracaoArquivo;
	tamanho?: string;
	linkDownload?: string;
	excluir: boolean;
	empresaId?: number;
	usuarioUpload?: number;
	motivo?: string;
}

export const formArquivoEmpresaDTO = () => new FormGroup({
	locale: new FormControl(null),
	base64: new FormControl(null),
	mimeType: new FormControl(null),
	nome: new FormControl(null),
	documento: new FormControl(null),
	moderacao: new FormControl(false),
	tamanho: new FormControl(null),
	id: new FormControl(null),
	linkDownload: new FormControl(null),
	excluir: new FormControl(null),
	empresaId: new FormControl(null),
	usuarioUpload: new FormControl(null),
	motivo: new FormControl(null)
});
