import { Observable } from "rxjs";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";
import { CustomErrorHandler } from "../exceptions/error-handler.interceptor";
import swal from "sweetalert2";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatDialog } from "@angular/material/dialog";
import { PrimeirosPassosComponent } from "@components/primeiros-passos/primeiros-passos.component";
import { AutenticacaoSecundariaComponent } from "../../components/autenticacao-secundaria/autenticacao-secundaria.component";
import { UsuarioEndpointService } from "../usuario-endpoint.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { environment } from "@project/src/environments/environment.default";

@Injectable({
  providedIn: "root",
})
export class AcessoInternoService implements CanActivate, CanActivateChild, OnDestroy {
  corporacaoLogada: any = null;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private autenticacaoService: AutenticacaoEndpointService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private dialog: MatDialog,
    private usuario: UsuarioEndpointService,
    private usuarioService: UsuarioEndpointService,
  ) {
    if (autenticacaoService.regraCorporacao)
      this.corporacaoLogada = autenticacaoService.regraCorporacao.corporacao;
  }

  ngOnDestroy() { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.autenticacaoService
      .me()
      .toPromise()
      .then(async (response) => {
        let logado = false;

        if (response) {
          if (response.name) {
            logado = true;
          }
        }

        if (!logado) {
          this.autenticacaoService
            .sair(false, state.url)
            .pipe(untilDestroyed(this))
            .subscribe();
          return false;
        }

        let temCorp = false;
        let emailValidado = false;
        let celularValidado = false;
        let moderacaoUsuario = "";
        let multifatorAtivado = false;
        let multifatorValidado = false;
        let ehAdmin = false;
        let ehOperador = false;
        let ehColaborador = false;
        let ehProprietario = false;

        if (response.authorities) {
          ehAdmin =
            response.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_ADMINISTRADOR" &&
                item.authority === "ROLE_ADMINISTRADOR"
            ).length > 0;
          ehOperador =
            response.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_OPERADOR" &&
                item.authority === "ROLE_OPERADOR"
            ).length > 0;
          ehColaborador =
            response.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_COLABORADOR" &&
                item.authority === "ROLE_COLABORADOR"
            ).length > 0;
          ehProprietario =
            response.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_PROPRIETARIO" &&
                item.authority === "ROLE_PROPRIETARIO"
            ).length > 0;

          if (ehAdmin) {
            return true;
          }

          temCorp =
            response.authorities.filter(
              (item) => !!item.corporcaoId || !!item.empresaId
            ).length > 0;

          if (!temCorp) {
            temCorp = ehOperador || ehColaborador;
          }

          if (response.principal.usuario) {
            emailValidado = response.principal.usuario.emailConfirmado;
            celularValidado = response.principal.usuario.celularConfirmado;
            moderacaoUsuario = response.principal.usuario.moderacao;
            multifatorAtivado = response.principal.usuario.multifatorAtivado;
            multifatorValidado = response.principal.usuario.multifatorValidado;
          }

          if (multifatorAtivado && !multifatorValidado && (!ehProprietario || (ehProprietario && moderacaoUsuario === 'aprovado'))) {
            await this.usuarioService.getUsuarioById(response.principal.usuario.id).toPromise().then(
              (res) => {
                multifatorValidado = res.multifatorValidado
                multifatorAtivado = res.multifatorAtivado
              }
            )

            await this.usuario
              .obterUsuarioLogado()
              .toPromise()
              .then((response) => {
                if (!this.storage.get("logado")) {
                  return this.dialog.open(AutenticacaoSecundariaComponent, {
                    disableClose: true,
                    width: "60rem",
                    height: "40rem",
                  });
                }
              });
            return false;
          }
        }

        if (!temCorp) {
          this.dialog.open(PrimeirosPassosComponent, {
            backdropClass: "modal-maior",
            data: {
              apenasCadastroPessoa: false,
            },
          });
        }
        if (ehOperador) {
          if (state.url === "/privado/empresa") {
            return false;
          }
        }
        return true;
      })
      .catch((err) => {
        this.customErrorHandler.handleError(err);
        return this.autenticacaoService
          .sair(false, state.url)
          .toPromise()
          .then(() => false);
      })
      .then((retorno: boolean) => {
        if (
          !retorno &&
          (this.router.url.startsWith("/privado") ||
            this.router.url.startsWith("/root"))
        ) {
          this.dialog.closeAll();
          if (
            this.autenticacaoService.sessao.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_OPERADOR" &&
                item.authority === "ROLE_OPERADOR"
            ).length > 0
          ) {
            return swal
              .fire({
                title: "Acesso negado",
                text: "Não possui permissão para acessar esta página.",
                icon: "warning",
                confirmButtonText: "OK",
              })
              .then(() => retorno);
          }
          return swal
            .fire({
              title: "Sessão expirada",
              text: "Sua sessão expirou. Logue novamente para continuar.",
              icon: "warning",
              confirmButtonText: "OK",
            })
            .then(() => retorno);
        }
        return retorno;
      });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.canActivate(route, state);
  }

}
