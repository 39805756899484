<div #mainDiv>
  <div *ngFor="let representante of form?.controls;let index = index" class="arquivos-representantes box-body">
    <div *ngIf="representante.get('id').value != null">
      <div class="title-representante">
        {{representante.get('nome').value}}
      </div>
      <div fxLayout="row" *ngIf="(getModeracao(representante)?.moderacao != 'aprovado' && (getModeracao(representante)?.moderacao != 'aguardandoModeracao'|| getModeracao(representante)?.id == null)) || ehAdmin || dataDialog?.arquivos">
        <div fxFlex="100" *ngIf="dataDialog?.arquivos">
          <app-upload-arquivos [index]="index" [files]="dataDialog?.arquivos[representante.get('nome').value]" requiredText="Necessário informar ao menos um comprovante do representante"
            (outputFiles)="upload(documentos[index], $event,index)">
          </app-upload-arquivos>
        </div>
        <div fxFlex="100" *ngIf="!dataDialog?.arquivos">
          <app-upload-arquivos [index]="index" requiredText="Necessário informar ao menos um comprovante do representante"
            (outputFiles)="upload(documentos[index], $event,index)">
          </app-upload-arquivos>
        </div>
      </div>
      <div *ngIf="getModeracao(representante)?.moderacao == 'aprovado'">
        <p>Documento do representante aprovado</p>
      </div>
      <div *ngIf="(getModeracao(representante)?.moderacao == 'aguardandoModeracao' && getModeracao(representante)?.id != null)">
        <p>Documento do representante está aguardando moderação</p>
      </div>
    </div>
    <div *ngIf="representante.get('id').value == null">
      <app-add-representante [form]="representante"></app-add-representante>
    </div>
  </div>

  <!-- <p class="novo-representante" (click)="add()">+ Adicionar novo representante</p> -->

</div>