<div style="height: 100% !important; flex-direction: column; display: flex;">
    <div mat-dialog-title class="titulo-modal" fxLayout="row">
        <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
        
        </div>
        <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">Vincular signatário</div> 
        <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
            <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="onClose()">FECHAR</p>
            <i style="margin-right: 10px;"(click)="onClose()" class="material-icons btn-fechar" >close</i>
        </div>
    </div>
    
    <div fxLayout="column" style="padding: 0 2rem">
        <ng-container *ngFor="let doc of form.controls; let i = index">
            <mat-checkbox fxFlex="33" fxFlex.md="50" (change)="doc.value.vincular = !doc.value.vincular">
                {{doc.value.documento}}
            </mat-checkbox>
        </ng-container>
    </div>

    <div style="padding: 0 2rem 1rem 2rem">
        <button class="btn btn-primary" (click)="salvarVinculo()">Salvar</button>
    </div>
</div>
