import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArquivoDTO } from '@model/sistema/arquivo-dto.model';
import { UtilCaniveteService } from '../../service/util/util-canivete.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-arquivo',
  templateUrl: './upload-arquivo.component.html',
  styleUrls: ['./upload-arquivo.component.less']
})
export class UploadArquivoComponent implements OnInit {

  @Input()
  multiple = false;

  @Output()
  onUpload = new EventEmitter<ArquivoDTO[]>();

  constructor(
    private utilCaniveteService: UtilCaniveteService
  ) { }

  ngOnInit() {
  }

  private async parse(event: Array<File>) {
    let valido = true;
    for (const item of event) {
      if(item.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && item.type !== 'application/vnd.ms-excel' && 
          item.type !== 'text/plain' && item.type !== ""){
        valido = false;
        Swal.fire('Aviso', 'O arquivo selecionado possui formato inválido.', 'warning')
        break;
      }
    }
    if(valido){
      const arquivos = await this.utilCaniveteService.parseFiles(event);
      this.onUpload.emit(arquivos);
    }
  }

  onFileDropped(event) {
    this.parse(event.dataTransfer.files as Array<File>);
  }

  onSelectFile(event) {
    this.parse(event);
  }
}
