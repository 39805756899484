<div style="height: 100% !important; flex-direction: column;; display: flex;">
    <div mat-dialog-title class="titulo-modal" fxLayout="row">
        <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
        
        </div>
        <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">Novo signatário</div> 
        <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
            <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="onClose()">FECHAR</p>
            <i style="margin-right: 10px;"(click)="onClose()" class="material-icons btn-fechar" >close</i>
        </div>
    </div>

    
    <div mat-dialog-content fxLayout="row" fxFlex="90" class="mat-dialog-content-responsive mudaCor" [formGroup]="form" style="height: 100% !important">
        <div fxFlex="70">
            <div class="box-body">
                <p class="title-row">Dados do signatário</p>
                <div fxLayout="row">
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="nome" type="text">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Documento</mat-label>
                            <input matInput formControlName="documento" type="text" mask="000.000.000-00">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input
                                matInput
                                [matDatepicker]="picker1"
                                id="dataNascimento"
                                (keyup)="mascaraData($event, 'dataNascimento')"
                                formControlName="nascimento"
                                [min]="dataMinima"
                                [max]="dataDezoitoAnos"/>
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker1"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput formControlName="email" type="text">
                    </mat-form-field>
                </div>
            </div>

            <div class="box-body">
                <p class="title-row">Telefone do signatário</p>
                <div fxLayout="row">
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="tipo">
                                <mat-option  *ngFor="let tipo of listaTipoTelefone" [value]="tipo.valor" (click)="telefoneTipo = tipo.valor">
                                  {{ tipo.valor }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input *ngIf="telefoneTipo==='Celular'" matInput formControlName="telefone" type="text" mask="(00) 0 0000-0000">
                            <input *ngIf="telefoneTipo==='Telefone'" matInput formControlName="telefone" type="text" mask="(00) 0000-0000">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline">
                            <mat-label>ramal</mat-label>
                            <input matInput type="text" formControlName="ramal">
                        </mat-form-field>
                    </div>
                </div>
            
            </div>

            <div class="box-body">
                <p class="title-row">Tipos de autenticação</p>
                <div class="menor-title">Autenticações principais</div>
                <div fxLayout="row wrap" fxLayoutAlign="start start" class="div-checkbox">
                    <ng-container *ngFor="let conta of mainAuthList; let i = index">
                        <mat-checkbox [disabled]="conta.disable" fxFlex="33" fxFlex.md="50" [value]="conta.label" [checked]="selected === i"
                            (change)="atualizarToggle(conta.label, i)">
                            {{ conta.label }}
                        </mat-checkbox>
                    </ng-container>
                </div>
                <div class="menor-title">Autenticações adicionais</div>
                <div fxLayout="row wrap" fxLayoutAlign="start start" class="div-checkbox">
                    <ng-container *ngFor="let conta of otherAuthList; let i = index">
                        <mat-checkbox fxFlex="33" fxFlex.md="50" [value]="conta.label" [checked]="conta.data"
                            (change)="conta.data = !conta.data;" [disabled]="conta?.disable">
                            {{ conta.label }}
                        </mat-checkbox>
                    </ng-container>
                </div>
            </div>

            
        </div>
        
        <div fxFlex="30" class="resumo" fxLayout="column">
            <p class="super-title" style="padding: 0px 20px 30px 20px">
                RESUMO
            </p>
          
            <p class="menor-title" style="padding-left: 20px">Dados signatário</p>
            <p *ngIf="form?.get('nome')?.value" style="padding-left: 20px">Nome: {{form?.get('nome')?.value}}</p>
            <p *ngIf="form?.get('documento')?.value" style="padding-left: 20px">Documento: {{form?.get('documento')?.value}}</p>
            <p *ngIf="form?.get('nascimento')?.value" style="padding-left: 20px">Data de nascimento: {{form?.get('nascimento')?.value | date:'dd/MM/yyyy'}}</p>
            <p *ngIf="form?.get('email')?.value" style="padding-left: 20px">E-mail: {{form?.get('email')?.value}}</p>
            <p *ngIf="form?.get('telefone')?.value" style="padding-left: 20px">Telefone: {{form?.get('telefone')?.value}}</p>

            <p class="menor-title" style="padding-left: 20px">Autenticações</p>
            <ng-container *ngFor="let conta of mainAuthList; let i = index">
                <p *ngIf="conta.data" fxFlex.md="50" style="padding-left: 20px; width: 100%">
                    {{ conta.label }}
                </p>
            </ng-container>
            <ng-container *ngFor="let conta of otherAuthList; let i = index">
                <p *ngIf="conta.data" fxFlex.md="50" style="padding-left: 20px; width: 100%">
                    {{ conta.label }}
                </p>
            </ng-container>
        </div>
    </div>
    <div fxLayout="row" fxFlex="8" fxLayoutAlign="end center">
        <div fxFlex="20">
            <button class="btn btn-block btn-primario" (click)="criar()">
                Criar
            </button>
        </div>
    </div>
</div>
