import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-stepper1',
  templateUrl: './stepper1.component.html',
  styleUrls: ['./stepper1.component.less']
})
export class Stepper1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
