import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PessoaCoreEditarModule } from '../pessoa-core-editar/pessoa-core-editar.module';
import { PessoaEditarComponent } from './pessoa-editar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalConfirmacaoComponent } from '../../../../components/modal/modal-confirmacao/modal-confirmacao.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { ModalConfirmaTokenModule } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.module';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [PessoaEditarComponent],
  imports: [
    CommonModule,
    PessoaCoreEditarModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ModalConfirmaTokenModule
  ],
  exports: [PessoaEditarComponent],
})
export class PessoaEditarModule { }
