import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input, SimpleChange, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmarPortabilidadeComponent } from '../../../pix/confirmar-portabilidade/confirmar-portabilidade.component';
import { AceitarPortabilidadeComponent } from '../../../pix/aceitar-portabilidade/aceitar-portabilidade.component';


@Component({
  selector: 'app-portabilidade-pix',
  templateUrl: './portabilidade-pix.component.html',
  styleUrls: ['./portabilidade-pix.component.less']
})
export class PortabilidadePixComponent implements OnInit {

  env = environment;

  form: FormGroup;
  chave: string;
  formTipos: FormGroup;
  isCnpj: boolean = true;

  // Numeração do ENUM do beckend
  cpf = 0;
  cnpj = 1;
  email = 2;
  celular = 3;
  evp = 4;

  cpfCnpj;
  cpfCnpjMask: string;
  @Input()
  empresaId: number;
  empresa: any;
  processando: boolean = false;
  listaReivindicacoes: any[] = [];
  dadosChaveReivindicada: any;
  desabilitaBotao: boolean = false;
  listaChavesReivindicadas: any[] = [];
  documento: string;
  numeroConta: string;



  constructor(
    private pixService: PixEndpointService,
    private fb: FormBuilder,
    private autenticacao: AutenticacaoEndpointService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.empresa = this.autenticacao.regraEmpresa.empresa;
    this.documento = this.empresa.pessoa.documento;
    this.numeroConta = this.empresa.numeroConta;
    this.listarPortabilidades();
    this.listarChavesReivindicadas();
  }


  buildForm() {
    this.form = this.fb.group({
      celular: ['', [Validators.pattern]],
      cpfCnpj: [''],
      email: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      aleatoria: ['', [Validators.minLength(5)]]
    });
  }

  listarPortabilidades() {
    this.listaReivindicacoes = [];
    this.listaChavesReivindicadas = [];
    this.processando = true;
    this.pixService.consultarRevindicacaoLista(this.empresa.pessoa.documento, this.empresa.numeroConta).subscribe((res) => {
      res.forEach(element => {
        if (element.stReivindicacao == 0 || element.stReivindicacao == 1 || element.stReivindicacao == 2) {
          this.listaReivindicacoes.push(element);
        }
        this.changeDetectorRef.detectChanges();
        this.processando = false;
      })
    }, err => {
      console.log(err);
      this.processando = false;
    });
    this.processando = false;
  }

  public getErrorMessage(field: string) {
    if (this.form.get(field)) {
      const control = this.form.get(field);
      if (control.hasError('pattern')) {
        if (field === 'email') {
          return 'Email inválido';
        } else if (field === 'celular') {
          return 'Telefone inválido';
        } else if (field === 'cpfCnpj') {
          return 'CPF/CNPJ inválido';
        }
      }
    }
    return 'Campo inválido';
  }

  validaTelefone(tel: FormControl) {
    if (tel && tel.value) {
      if (tel.value?.length === 11) {
        tel.setErrors(null);
        tel.clearAsyncValidators();
      } else {
        tel.setErrors({ valid: false });
      }
    }
  }

  buildChavePix(chave, tipoChave) {
    const chavePix = {
      chave: chave,
      tpChave: tipoChave,
    }
    return chavePix;
  }

  revindicarChave(tipo) {

    let objetoChavePix;

    if (this.form.get('cpfCnpj').value?.length > 11) {
      objetoChavePix = this.buildChavePix(this.form.get('cpfCnpj').value, this.cnpj);
    } else {
      objetoChavePix = this.buildChavePix(this.form.get('cpfCnpj').value, this.cpf);
    }

    if (tipo == this.celular) {
      objetoChavePix = this.buildChavePix(this.form.get('celular').value, this.celular);
    }

    if (tipo == this.email) {
      objetoChavePix = this.buildChavePix(this.form.get('email').value, this.email);
    }

    this.pixService.consultarChave(objetoChavePix.chave, objetoChavePix.tpChave).subscribe((res) => {
      const dialog = this.dialog.open(ConfirmarPortabilidadeComponent, {
        data: {
          data: res,
          objetoChavePix: objetoChavePix,
          tipo: 'solicitar'
        },
        width: '581px',
        height: '798px',
      })

      dialog.afterClosed().subscribe(() => {
        this.listarPortabilidades();
        this.listarChavesReivindicadas();
      });
    }, err => {
      Swal.fire({
        title: 'Atenção',
        text: 'Não foi possível encontrar a chave informada.',
        icon: 'warning',
      })
    })
  }

  consultarRevindicacaoChave(chave, item) {
    this.processando = true;
    this.desabilitaBotao = true;
    this.pixService.consultaReivindicacaoChave(this.empresa.pessoa.documento, this.empresa.numeroConta, chave, item.idReivindicacao).subscribe((res) => {
      this.dadosChaveReivindicada = [];
      this.dadosChaveReivindicada = res;
      this.processando = false;
      const dialog = this.dialog.open(ConfirmarPortabilidadeComponent, {
        data: {
          data: res,
          tipo: 'consultar',
        },
        width: '581px',
        height: '550px',
      });

      dialog.afterClosed().subscribe(() => {
        this.processando = false;
      });
    }, err => {
      console.log(err);
    });
    this.processando = false;
    this.desabilitaBotao = false;
  }

  consultarReivindicacaoSolicitada(chave) {
    this.processando = true;
    this.desabilitaBotao = true;
    const dialog = this.dialog.open(AceitarPortabilidadeComponent, {
      data: {
        data: chave,
        tipo: 'consultar',
      },
      width: '520px',
      height: '550px',
    });
    dialog.afterClosed().subscribe(() => {
      this.processando = false;
    });
    this.processando = false;
    this.desabilitaBotao = false;
  }


  listarChavesReivindicadas() {
    this.listaReivindicacoes = [];
    this.listaChavesReivindicadas = [];
    this.processando = true;
    this.pixService.listarReivindicacaoChave()
      .subscribe((res) => {
        res.reivindicacoesAssociadas.forEach(element => {
          if (element.stReivindicacao != 4 && element.stReivindicacao != 3)
            this.listaChavesReivindicadas.push(element);
        });
        this.changeDetectorRef.detectChanges();
        this.processando = false;
      });
    this.processando = false;
  }

  copiarChave(link) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Chave Pix copiada!", '', {
      positionClass: "toast-top-center",
      timeOut: 2000
    })
  }

  transformTipoPix(param) {
    switch (param) {
      case 0:
        return 'CPF';
      case 1:
        return 'CNPJ';
      case 2:
        return 'Email';
      case 3:
        return 'Celular';
    }
  }

}
