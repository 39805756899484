<div class="container" [formGroup]="form">
    <div class="title">
        <h3>Personalizar documentos {{ modelo }}</h3>
        <p>Vamos começar!</p>
    </div>
    <div>
        <app-toolbar [filtro]="filtro" (atualizar)="atualizar($event)" [modelo]="modelo"></app-toolbar>
    </div>
    <div fxFlexLayout="row" class="row-header-table">
        <div fxFlex="7" fxLayoutAlign="start center">STATUS</div>
        <div fxFlex="30" fxLayoutAlign="start center">TÍTULO DO CAMPO</div>
        <div fxFlex="25" fxLayoutAlign="start center">TIPO DE CONTA</div>
        <div fxFlex="18" fxLayoutAlign="start center">OBRIGATÓRIO</div>
        <div fxFlex="15" fxLayoutAlign="start center">TIPO</div>
        <div *ngIf="modelo === 'investidor'" fxFlex="10" fxLayoutAlign="start center">VÍNCULO FUNDO</div>
        <div fxFlex="5"  fxLayoutAlign="start center">EDITAR</div>
    </div>

    <div fxFlexLayout="row" [class]="isAtivo(documento)?'ativo row-td':'row-td'" *ngFor="let documento of form.controls; index as i" [formGroup]="documento" >
        <div fxFlex="7"><mat-slide-toggle formControlName="ativo" [hidden]="camposDesabilitados(data[i])"></mat-slide-toggle></div>
        <div fxFlex="30" class="title-row-td"><i class="fas fa-building"> </i>{{documento.get('documento').value}}</div>
        <div fxFlex="25">{{documento.get('tipo').value | tipoDocumento}}</div>
        <div fxFlex="18"><mat-slide-toggle formControlName="obrigatorio" [hidden]="camposDesabilitados(data[i])"></mat-slide-toggle></div>
        <div fxFlex="15">{{documento.get('formato').value | formatoDocumento}}</div>
        <div *ngIf="modelo === 'investidor'" fxFlex="10" fxLayoutAlign="start center">{{documento.get('fundoId').value ? 'Sim' : 'Não'}}</div>
        <div fxFlex="5"  fxFlexLayout="row" fxLayoutAlign="center center" class="edit"><i class="far fa-edit" (click)="onEdit(data[i])"></i></div>
    </div>
</div>
