import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";

@Injectable({
  providedIn: "root",
})
export class acessoPixService implements CanActivate {
  constructor(private autenticacao: AutenticacaoEndpointService) { }

  canActivate(route: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.autenticacao) {
      if (this.autenticacao.regraEmpresa.empresa.configuracao.areaPix) {
        return true;
      }
    } else {
      return false;
    }
  }
}