import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { AutenticacaoEndpointService } from "@service/autenticacao-endpoint.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UsuarioDTO } from "../../model/usuario/usuario-dto.model";
import { UsuarioEndpointService } from "../../service/usuario-endpoint.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleAuthenticatorEndpointService } from "../../service/google-authenticator.service";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { LoginSecundarioDTO } from "../../model/login/login-secundario-dto.model";
import { PrincipalModel } from "../../model/principal.model";
import { environment } from "@project/src/environments/environment";
import { InvisibleReCaptchaComponent } from "ngx-captcha";
import { RootEndpointService } from "../../service/root-endpoint.service";
import { AlterarSenhaComponent } from "../../pages/externo/alterar-senha/alterar-senha.component";
import { TrocarSenhaComponent } from "../../pages/interno/usuario/usuario-editar/trocar-senha/trocar-senha.component";

declare var $: any;

@Component({
  selector: "app-autenticacao-secundaria",
  templateUrl: "./autenticacao-secundaria.component.html",
  styleUrls: ["./autenticacao-secundaria.component.less"],
})
export class AutenticacaoSecundariaComponent implements OnInit, OnDestroy {
  form: FormGroup;
  processando = false;
  env = environment;
  urlLogo = null;
  urlTermos = null;
  ehOperador = false;
  ehColaborador = false;
  ehAgenciaConta = false;
  ehMultifator = false;

  ehNewAuthenticator = false;
  qrcode: any;
  authenticatorKey: string;
  usuarioLogado: UsuarioDTO;
  atualizarSenha = false;

  captchaKey = environment.google.captcha; // Site key captcha publica
  public recaptcha: any = null;
  captchaPronto = false;
  captchaSucesso = false;
  token: string;
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<AutenticacaoSecundariaComponent>,
    private autenticacao: AutenticacaoEndpointService,
    private rootService: RootEndpointService,
    private authenticatorService: GoogleAuthenticatorEndpointService,
    private sanitizer: DomSanitizer,
    private usuarioService: UsuarioEndpointService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataDialog: LoginSecundarioDTO,
  ) { }

  async ngOnInit() {
    this.getURL();
    this.form = this.formBuilder.group({
      codigoAutenticacao: this.formBuilder.control("", Validators.pattern(/^[0-9]{6}$/)),
      agencia: this.formBuilder.control("", Validators.pattern(/^([0-9]{1,4})(-[0-9])?$/)),
      conta: this.formBuilder.control("", Validators.pattern(/^[0-9]+(-[0-9])?$/)),
      codigoAuthenticator: this.formBuilder.control("", Validators.pattern(/^[0-9]{6}$/))
    });

    if (this.dataDialog && this.dataDialog.data) {
      this.ehColaborador = this.dataDialog.data.requiredCodigoColaborador;
      this.ehOperador = this.dataDialog.data.requiredCodigoOperador;
      this.ehAgenciaConta = this.dataDialog.data.requiredAgConta;
      this.ehMultifator = this.dataDialog.data.requiredMFA;
    } else {
      this.ehNewAuthenticator = true;

      this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
      this.authenticatorService.gerarQRCode(this.usuarioLogado.email).subscribe(
        (res) => {
          let imagemUrl = 'data:image/png;base64,' + res.qrcode;
          this.qrcode = this.sanitizer.bypassSecurityTrustUrl(imagemUrl);
          this.authenticatorKey = res.key;
        },
        (err) => Swal.fire('Erro', 'Erro ao gerar QR Code', 'error')
      )
    }
  }

  ngOnDestroy() { }

  fechar() {
    this.dialogRef.close();
    this.router.navigateByUrl("/acesso");
  }

  async login() {
    this.processando = true;

    this.autenticacao.acessar({
      usuario: this.dataDialog.usuario, senha: this.dataDialog.senha, token: this.token,
      agencia: this.form.get('agencia').value, conta: this.form.get('conta').value,
      codeColaborador: this.form.get('codigoAutenticacao').value, codeOperador: this.form.get('codigoAutenticacao').value,
      codeMultifator: this.form.get('codigoAuthenticator').value,
    })
      .pipe(untilDestroyed(this))
      .subscribe(
        async (response: PrincipalModel) => {
          if (response && response.name) {
            this.processando = false;
            this.router.navigateByUrl('/privado/principal');
            this.dialogRef.close();
          } else {
            this.processando = false;
            Swal.fire('Erro', 'Usuário e/ou senha inválido', 'error')
          }
        },
        error => {
          this.processando = false;
          return Swal.fire({
            title: 'Atenção',
            text: error.error.mensagem,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  logout() {
    this.autenticacao
      .sair()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          localStorage.removeItem('senhaPendente');
          this.router.navigateByUrl('/acesso');
        },
        (error) => {
          console.error(error);
        }
      );
  }


  getURL() {
    this.rootService.getUrl().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[0] != '') this.urlLogo = response[0];

          if (response[1] != '') this.urlTermos = response[1];
        },
        error => {
          this.processando = false;
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  habilitarMfa() {
    const codigo = this.form.get('codigoAuthenticator').value;
    this.processando = true;
    this.authenticatorService.validar(this.usuarioLogado.email, codigo, this.authenticatorKey).subscribe(
      (res) => {
        if (res.valid) {
          this.usuarioService.atualizarMultifator(this.usuarioLogado.id, null, true, this.authenticatorKey).subscribe(
            (res) => {
              this.processando = false;
              Swal.fire('Sucesso', 'Autenticação de dois fatores habilitada com sucesso!', 'success')
                .then(() => window.location.reload())
            },
            (err) => {
              this.processando = false;
              Swal.fire('Erro', 'Erro ao atualizar usuário', 'error')
            }
          )
        } else {
          this.processando = false;
          Swal.fire('Erro', 'Suas informações de autenticação estão incorretas. Tente novamente.', 'error')
        }
      },
      (err) => {
        this.processando = false;
        Swal.fire('Erro', 'Erro na validação do código', 'error')
      }
    )
  }

  get codigoValido() {
    return this.form.get('codigoAutenticacao').value.length > 0 &&
      this.form.get('codigoAutenticacao').valid;
  }

  get agenciaContaValido() {
    return this.form.get('agencia').valid &&
      this.form.get('conta').valid &&
      this.form.get('agencia').value.length > 0 &&
      this.form.get('conta').value.length > 0;
  }

  get botaoAtivo() {
    if ((this.ehOperador || this.ehColaborador) && !this.codigoValido) {
      return false;
    }
    if (this.ehAgenciaConta && !this.agenciaContaValido) {
      return false;
    }
    if (this.ehMultifator && this.form.get('codigoAuthenticator').value.length != 6) {
      return false;
    }
    return true;
  }

  setCaptchaPronto() {
    setTimeout(() => {
      this.captchaPronto = true;
    });
  }

  executarCaptcha() {
    this.processando = true;
    this.captchaElem.execute();
  }

  onSuccess(token: string) {
    this.token = token;
    this.login();
  }

  onError(error) {
    Swal.fire('Erro', 'Houve um erro de validação no ReCaptcha: ' + error, 'error')
  }
}
