import { Pipe, PipeTransform } from '@angular/core';
import { UtilCaniveteService } from '@service/util/util-canivete.service';

@Pipe({
	name: 'mark',
	pure: false
})
export class MarkPipe implements PipeTransform {

	constructor(private util: UtilCaniveteService) { }

	transform(value: string, [search]) {
		return search ? value.replace(new RegExp(`(${search})`, 'gim'), '<mark>$1</mark>') : value;
	}

}
