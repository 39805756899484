import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';

@Component({
  selector: 'app-stepper2',
  templateUrl: './stepper2.component.html',
  styleUrls: ['./stepper2.component.less']
})
export class Stepper2Component implements OnInit {
  empresaLogada: any;
  usuarioLogado: any;

  constructor(private autenticacao: AutenticacaoEndpointService) { 
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
  }

  ngOnInit() {
  }

  reenviarEmail(){
    
  }
}
