<div class="row">
  <div class="col-12" [formGroup]="form">

    <div class="row alinha" style="margin-top: 20px;">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">MANUTENÇÃO DA
          {{defineTipoConta(tipoConta).toUpperCase()}}
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Mensalidade {{defineTipoConta(tipoConta)}}</mat-label>
          <input matInput type="text" formControlName="precoMensalidade" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Dia de fechamento</mat-label>
          <input matInput type="number" formControlName="diaFechamentoMensalidade" min="1" max="28" />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Atualização Cadastral Automática</mat-label>
          <input matInput type="number" formControlName="atualizacaoAutomaticaCadastro" min="1" max="180" />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de Abertura de Conta</mat-label>
          <input matInput formControlName="taxaAberturaConta" type="text" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Mensalidade de Conta Inativa</mat-label>
          <input matInput formControlName="mensalidadeContaInativa" type="text" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Validação Cadastral Conta Pessoa Física Simples</mat-label>
          <input matInput formControlName="validacaoCadastralContaPessoaFisicaSimples" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Validação Cadastral Conta Pessoa Física Completa</mat-label>
          <input matInput formControlName="validacaoCadastralContaPessoaFisicaCompleta" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Validação Cadastral Conta Pessoa Jurídica</mat-label>
          <input matInput formControlName="validacaoCadastralContaPessoaJuridica" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Pesquisa Serasa</mat-label>
          <input matInput formControlName="pesquisaSerasa" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Negativação Serasa</mat-label>
          <input matInput formControlName="negativacaoSerasa" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>
    </div>

    <div class="row alinha" style="margin-top: 20px;" *ngIf="env?.modulos?.pagamentoContas?.sidebar">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">PAGAMENTO DE CONTAS
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Preço de pagamento</mat-label>
          <input matInput type="text" formControlName="precoPagamentoConta" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Pagamento Tributos e Impostos de Boletos</mat-label>
          <input matInput type="text" formControlName="pagamentoTributosImpostosBoletos" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Pagamento de Boletos Diversos</mat-label>
          <input matInput type="text" formControlName="pagamentoBoletosDiversos" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Pagamento de Contas de Consumo</mat-label>
          <input matInput type="text" formControlName="pagamentoContasConsumo" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

    </div>

    <div class="row alinha" style="margin-top: 20px;" *ngIf="env?.modulos?.cobranca?.sidebar">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">COBRANÇAS
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de baixa</mat-label>
          <input matInput type="text" formControlName="precoBaixaBoleto" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de edição</mat-label>
          <input matInput type="text" formControlName="precoPadraoEdicaoBoleto" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de emissão</mat-label>
          <input matInput type="text" formControlName="precoEmissaoBoleto" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de liquidação</mat-label>
          <input matInput type="text" formControlName="precoLiquidacaoBoleto" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Valor máximo de emissão</mat-label>
          <input matInput type="text" formControlName="valorMaximoEmissaoBoleto" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Preço manutenção de titulo vencidos</mat-label>
          <input matInput type="text" formControlName="precoManutencaoTituloVencido" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Dias após vencimento aplicação preço manutenção</mat-label>
          <input type="number" min="1" max="28" formControlName="diasAposVencimentoAplicacaoPrecoManutencao" matInput />
        </mat-form-field>
      </div>
    </div>

    <div class="row alinha" style="margin-top: 20px;" *ngIf="env?.modulos?.cartaoCredito?.sidebar">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">TIPO DE CARTEIRA - CARTÃO
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Preço de pagamento via cartão</mat-label>
          <input matInput type="text" formControlName="precoPagamentoCartao" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa ao mês de pagamento via cartão</mat-label>
          <input matInput type="text" formControlName="porcentagemMesPagamentoCartao" (keyup)="troca($event)"  />
        </mat-form-field>
      </div>
    </div>

    <div class="row alinha" style="margin-top: 20px;" *ngIf="env?.modulos?.transferencia?.sidebar">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">TRANSFERÊNCIA
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de transferência externa - máx</mat-label>
          <input matInput type="text" formControlName="precoMaximoTransferencia" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="Preço de transferência com valor abaixo de R$ 250,00"></i>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de transferência externa - mín</mat-label>
          <input matInput type="text" formControlName="precoMinimoTransferencia" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="Preço de transferência com valor superior a R$ 250,00"></i>
      </div>
      <div class="col-12 col-md-4 input-size" >
        <mat-form-field appearance="outline">
          <mat-label>Taxa transferência entre agências</mat-label>
          <input matInput type="text" formControlName="taxaTransferenciaEntreAgencias" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div> 
      <div class="col-1 col1-icone" >
        <i class="fas fa-info-circle info-icon" matTooltip="Preço de transferência entre agências do banco {{env?.empresa?.nomeIF}}"></i>
      </div> 
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de transferência interna </mat-label>
          <input matInput type="text" formControlName="transferenciasEntreContasInternas" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="Preço de transferência para contas de mesma agência ({{env?.codAgencia}})"></i>
      </div>
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de transferência - em lote</mat-label>
          <input matInput type="text" formControlName="precoTransferenciaLote" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
      </div>
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Limite de transferência diária</mat-label>
          <input matInput type="text" formControlName="limiteTransferenciaDiaria" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
      </div>
      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Valor mínimo transferência</mat-label>
          <input matInput type="text" formControlName="valorMinimoTransferencia" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="Valor mínimo da transferência (> 0)"></i>
      </div>
        
      
      <div class="col-12 col-md-4 input-size" >
        <mat-form-field appearance="outline">
          <mat-label>Transferências gratuitas</mat-label>
          <input matInput type="text" formControlName="quantidadeTransferenciasGratuitasNoMes" />
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
      </div>
      
      <div class="col-12 col-md-4 input-size" *ngIf="env.modulos.transferencia.alcada">
        <mat-form-field appearance="outline">
          <mat-label>Valor Transferência Alçada</mat-label>
          <input matInput type="text" formControlName="valorTransferenciaAlcada" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div> 
      <div class="col-1 col1-icone" *ngIf="env.modulos.transferencia.alcada">
        <i class="fas fa-info-circle info-icon" matTooltip="Valor máximo de uma transferência que não precisa de aprovação"></i>
      </div> 
      <!-- <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Valor mínimo transferência</mat-label>
          <input matInput type="text" formControlName="valorMinimoTransferencia" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon" matTooltip="Valor mínimo da transferência (> 0)"></i>
      </div> -->
      
    </div>

    <div class="row alinha" style="margin-top: 20px;" *ngIf="env?.modulos?.antecipacao?.sidebar">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">ANTECIPAÇÃO
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Limite de antecipação</mat-label>
          <input matInput type="text" formControlName="limiteAntecipacao" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa ao mês por fatura (%)</mat-label>
          <input matInput type="text" formControlName="porcentagemMesFaturaAntecipada" (keyup)="troca($event)" />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Tipo da taxa</mat-label>
          <mat-select formControlName="tipoTaxaAntecipacao">
            <mat-option value="simples">Juros Simples</mat-option>
            <mat-option value="composto">Juros Compostos</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6 input-size">
        <mat-slide-toggle formControlName="descontarTituloAposVencimento" style="margin-bottom: 13px">
          <span style="color: #6D6D6D">Descontar titulo após o vencimento</span>
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row alinha" style="margin-top: 20px;">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">OUTRAS TAXAS
        </h4>
      </div>
      <div class="col-12" style="margin-bottom: 10px">
        <h5 class="corPrimaria" style="font-weight: bolder; margin-top: 12px">ENVIO DE SMS
        </h5>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Aviso na emissão de faturas</mat-label>
          <input matInput type="text" formControlName="taxaAvisoEmissaoFaturas" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Lembrete antes e depois do vencimento</mat-label>
          <input matInput type="text" formControlName="taxaLembreteAntesDepoisVencimento" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Mensagem de confirmação do pagamento</mat-label>
          <input matInput type="text" formControlName="taxaMensagemConfirmacaoPagamento" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Redução da inadimplência</mat-label>
          <input matInput type="text" formControlName="taxaReducaoInadimplencia" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Envio Compromisso/Agendamento no calendário</mat-label>
          <input matInput type="text" formControlName="taxaEnvioCompromissoAgendamentoCalendario" mask="separator.2"
            thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Envio token</mat-label>
          <input matInput type="text" formControlName="taxaEnvioToken" mask="separator.2" thousandSeparator="." prefix="R$ " />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Taxa de solicitação de extrato</mat-label>
          <input matInput type="text" formControlName="taxaSolicitacaoExtrato" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
      </div>
    </div>

    <!-- <div class="row alinha" style="margin-top: 20px;">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">AMBIENTE
        </h4>
      </div>

      <div class="col-12 col-md-4 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Ambiente da empresa</mat-label>
          <mat-select formControlName="ambiente">
            <mat-option value="teste">Teste</mat-option>
            <mat-option value="producao">Produção</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
  </div>
</div>