import { Component, OnInit } from '@angular/core';
import { RootEndpointService } from '../../service/root-endpoint.service';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracao-carrossel',
  templateUrl: './configuracao-carrossel.component.html',
  styleUrls: ['./configuracao-carrossel.component.less']
})
export class ConfiguracaoCarrosselComponent implements OnInit {
  processando: boolean = false;
  itensCarrossel: any = [];

  constructor(
    public rootService: RootEndpointService,
    public dialogRef: MatDialogRef<ConfiguracaoCarrosselComponent>
  ) { }

  ngOnInit() {
    this.listarCarrossel();
  }


  listarCarrossel() {
    this.processando = true;
    this.rootService.getCarrossel().subscribe((res) => {
      this.itensCarrossel = res;
      this.processando = false;
    })
  }

  atualizarCarrossel(tipo) {
    this.rootService.atualziaCarrossel(tipo).subscribe(
      (res) => {
      },
      (error) => {
        Swal.fire("Atenção!", error.error.replaceAll('"', ''), "warning");
        this.listarCarrossel();
      }
    );
  }

  formataNome(tipo) {
    switch (tipo) {
      case "transferencias":
        return "Transferências";
      case "contatos":
        return "Contatos";
      case "cobrancasRecebidas":
        return "Cobranças Recebidas";
      case "resumo":
        return "Resumo";
      case "pagamentoDeContas":
        return "Pagamento de Contas";
      case "publicidade":
        return "Publicidade";
    }
  }

  iconeCard(tipo) {
    switch (tipo) {
      case "transferencias":
        return "fas fa-exchange-alt fa-2x";
      case "contatos":
        return "fas fa-users fa-2x";
      case "cobrancasRecebidas":
        return "far fa-file fa-2x";
      case "resumo":
        return "fas fa-book fa-2x";
      case "pagamentoDeContas":
        return "fas fa-barcode fa-2x";
      case "publicidade":
        return "fas fa-bullhorn fa-2x";
    }
  }

  fecha() {
    this.dialogRef.close();
  }



}
