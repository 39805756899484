import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BuscaCnpjDTO } from '../model/apionboard/busca-cnpj-dto.model';
import { BuscaCepDTO } from '../model/apionboard/busca-cep-dto.model';
import { BuscaCpfDTO } from '../model/apionboard/busca-cpf-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultasEndpointService {
  // LISTA DE SERVIÇOS
  // GET - https://api.neofinance.com.br/pessoa/buscar-cnpj/{cnpj}
  // GET - https://api.neofinance.com.br/moeda/obter
  // GET - https://api.neofinance.com.br/banco/obter
  // GET - https://api.neofinance.com.br/agencia/obter
  // GET - https://api.neofinance.com.br/teste/ping
  // GET - https://api.neofinance.com.br/teste/versao
  // GET - https://api.neofinance.com.br/endereco/buscar-cep/{cep}
  // GET - https://api.neofinance.com.br/endereco/listar-uf?page&q
  // GET - https://api.neofinance.com.br/endereco/listar-localidade?page&q
  // GET - https://api.neofinance.com.br/endereco/buscar-localidade-ibge?codigo
  // GET - https://api.neofinance.com.br/endereco/listar-bairro?page&q
  // GET - https://api.neofinance.com.br/endereco/listar-bairro-por-localidade?codigoIbge
  // GET - https://api.neofinance.com.br/endereco/listar-localidade-por-uf?uf

  constructor(private client: HttpClient) { 

  }
  
  private acesso = '/home/busca'

  buscarCnpj(cnpj: string): Observable<BuscaCnpjDTO> {
    const url = this.acesso + "-cnpj/" + cnpj;
    return this.client.get<BuscaCnpjDTO>(url);
  }

  buscarCep(cep: string): Observable<BuscaCepDTO> {
    const url = this.acesso + "-cep/" + cep;
    return this.client.get<BuscaCepDTO>(url);
  }

  buscarCpf(cpf: string): Observable<BuscaCpfDTO> {
    const url = this.acesso + "-cpf/" + cpf;
    const response = this.client.get<BuscaCpfDTO>(url);
    // 
    return this.client.get<BuscaCpfDTO>(url);
  }

  // buscarMoeda(p: {
  // 	codigo: number,
  // 	de?: Date,
  // 	ate?: Date,
  // }): Observable<any> {
  // 	let params: HttpParams = new HttpParams();
  // 	params = params.append('codigo', p.codigo.toString());
  // 	if (p) {
  // 		if (p.de) params = params.append('de', moment(p.de).format('DD/MM/YYYY'));
  // 		if (p.ate) params = params.append('ate', moment(p.ate).format('DD/MM/YYYY'));
  // 	}
  //   return this.client.get<any>(`${this.url}/moeda/obter`, {
  // 		params
  // 	});
  // }

  // 	top=quantidade
  // 	skip=pula as já pesquisar
  // 	orderby=informa o campo ele ordena (asc)
  // 	filter=filtra ele transforma o dado em JSON.stringfy e busca pelo indexof (case insensitive)

  buscarBanco(p?: {
    q?: string;
    top?: number;
    ispb?: string;
    numeroCodigo?: number;
  }): Observable<any> {
    const url = this.acesso + '/banco/obter';

    let params: HttpParams = new HttpParams();
    if (p) {
      if (p.q) {
        params = params.append('q', p.q);
      }
      if (p.top) {
        params = params.append('top', `${p.top}`);
      }
    }

    return this.client.get(url, { params });
  }

  buscarAgencia(p?: {
    q?: string;
    cnpjBase?: string;
    cnpj?: string;
    top?: number;
  }): Observable<any> {
    const url = this.acesso + '/agencia/obter';

    let params: HttpParams = new HttpParams();
    if (p) {
      if (p.q) {
        params = params.append('q', p.q);
      }
      if (p.cnpjBase) {
        params = params.append('cnpjBase', p.cnpjBase);
      }
      if (p.cnpj) {
        params = params.append('cnpj', p.cnpj);
      }
      if (p.top) {
        params = params.append('top', p.top.toString());
      }
    }
    return this.client.get(url, { params });
  }

  buscarBancos(p?: {
    q?: string;
    top?: number;
  }): Observable<any> {
    const url = '/consulta-bancos/buscar-todos';
    
    let params: HttpParams = new HttpParams();
    if (p) {
      if (p.q) {
        params = params.append('q', p.q);
      }
      if (p.top) {
        params = params.append('size', `${p.top}`);
      }
    }

    return this.client.get(url,{ params });
  }
}
