import { TipoConta } from "./enum.model";

type ConstEnum<T> = {
    label: string;
    value: T;
};

export const constToEnumLabel = <T>(list: ConstEnum<T>[], value: T) => {
    const filtro = list.filter(f => f.value === value);
    if (filtro && filtro.length) {
        return filtro[0].label;
    }
    return "";
}

export const tipoContaOptions: ConstEnum<TipoConta>[] = [
    {
        label: 'Conta Poupança',
        value: 'poupanca'
    },
    {
        label: 'Conta Corrente',
        value: 'corrente'
    },
    {
        label: 'Conta Digital',
        value: 'digital'
    }

];

export const classificacaoCVMOptions: ConstEnum<any>[] = [
    {
        label: 'Fundos de Renda Fixa',
        value: 'fundoRendaFixa'
    },
    {
        label: 'Fundo Cambial',
        value: 'fundoCambial'
    },
    {
        label: 'Fundo Multimercado',
        value: 'fundoMultimercado'
    },
    {
        label: 'Fundo de Ações',
        value:'fundoAcoes'
    }
];

export const benchmarkOptions: ConstEnum<any>[] = [
    {
        label: 'Índice de inflação',
        value: 'indiceInflacao'
    },
    {
        label: 'Taxa CDI',
        value: 'cdi'
    },
    {
        label: 'PTax',
        value: 'ptax'
    }
];