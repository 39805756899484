<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        CÓDIGO DE CONVÊNIO
        <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>
    <div mat-dialog-content class="mat-dialog-content-responsive" style="width: 65vw;">
        <div class="mudaCor input-size row"
            style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; ">
            <div class="card-body">
                <div class="first-container">
                    <form [formGroup]="formNovo">
                        <!-- Conteúdo do primeiro formulário -->
                        <div id="input-container" class="row row-inputs">
                            <h4 class="color-title">Criar código de convênio</h4>
                            <div class="col-12 col-md-12" style="margin-top: 25px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Código</mat-label>
                                    <input matInput type="text" formControlName="novoCodigo" placeholder="Código"
                                        required maxlength="45">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-12" style="height: 92px;">

                            </div>
                        </div>
                        <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;width: 100%;
                                justify-content: center; width: 100%;">
                            <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px;border-radius: 4px;
                            margin: 0;">
                                <button class="btn btn-default next" id="color-btn" [disabled]="!isFormValidNovo"
                                    (click)="criarCodigo()" style="
                                    width: 117px;
                                ">Criar</button>
                            </span>
                        </div>
                    </form>
                </div>
                <div class="second-container">
                    <form [formGroup]="formsVinculaCodigo">
                        <!-- Conteúdo do segundo formulário -->
                        <div id="input-container" class="row row-inputs">
                            <h4 class="color-title" style="margin-left: 10px;
                            ">Vincular código de convênio</h4>
                            <div class="col-12 col-md-12" style="margin-top: 25px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Código</mat-label>
                                    <input matInput type="text" formControlName="codigo" placeholder="Código"
                                        [matAutocomplete]="autoCodigo" (input)="onInputChangeCodigo()" required
                                        maxlength="45">
                                    <mat-autocomplete #autoCodigo="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredCodigos | async"
                                            [value]="option.codigo">
                                            {{ option.codigo }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-12" style="margin-top: 25px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Empresa</mat-label>
                                    <input matInput type="text" formControlName="empresa" placeholder="Empresa"
                                        [matAutocomplete]="autoEmpresa" (input)="onInputChangeEmpresa()"
                                        [ngModel]="selectedEmpresa.label">
                                    <mat-autocomplete #autoEmpresa="matAutocomplete"
                                        (optionSelected)="pegaIdEmpresa($event)">
                                        <mat-option *ngFor="let option of filteredEmpresas | async"
                                            [value]="{ label: option.pessoa.razaoSocial !== null ? option.pessoa.razaoSocial : option.pessoa.nome + ' - ' + defineTipoConta(option.tipoConta) + ' - ' + option.pessoa.documento, id: option.id }"
                                            matTooltip="{{ option.pessoa.razaoSocial }} - {{ defineTipoConta(option.tipoConta) }} - {{ option.pessoa.documento }}">
                                            {{ option.pessoa.razaoSocial !== null ? option.pessoa.razaoSocial + ' - ' +
                                            option.tipoConta + ' - ' +
                                            option.pessoa.documento :
                                            option.pessoa.nome + ' - ' + defineTipoConta(option.tipoConta) + ' - ' +
                                            option.pessoa.documento }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;width: 100%;
                                justify-content: center; width: 100%;">
                            <span class="div-interna flex-end" style="align-items: center; padding: 0px 20px;border-radius: 4px;
                                margin: 0;">
                                <button class="btn btn-default next" id="color-btn"
                                    (click)="vincularCodigoNaEmpresa()">Vincular</button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>