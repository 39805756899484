import { ExportarConciliacaoComponent } from './exportar-conciliacao/exportar-conciliacao.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@app/icons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RootComponent } from './root.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipeModule } from '../../pipe/pipe.module';
import { UploadArquivosModule } from '../upload-arquivos/upload-arquivos.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    RootComponent,
    ExportarConciliacaoComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule,
    MatDialogModule,
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FlexLayoutModule,
    PipeModule,
    UploadArquivosModule,
    MatButtonToggleModule,
    AngularEditorModule
  ],
  exports: [
    RootComponent,
    ExportarConciliacaoComponent
  ],
})
export class RootConfigModule { }
