import {
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioDTO } from '@project/src/app/model/usuario/usuario-dto.model';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-contato',
  templateUrl: './editar-contato.component.html',
  styleUrls: ['./editar-contato.component.less']
})
export class EditarContatoComponent implements OnInit, OnDestroy {

  public processando: boolean = false;
  public form: FormGroup;
  public tipo: string;

  private usuarioLogado: UsuarioDTO;

  constructor(
    private dialogRef: MatDialogRef<EditarContatoComponent>,
    private autenticacao: AutenticacaoEndpointService,
    private usuarioService: UsuarioEndpointService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tipo = data.tipo;
  }

  public ngOnInit(): any {
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;

    this.form = this.fb.group({
      contato: new FormControl("")
    });

    if (this.tipo === "telefone") {
      this.form.get("contato").setValidators([Validators.required, this.verificaTelefone(this.form)]);
    } else {
      this.form.get("contato").setValidators([Validators.required, Validators.email]);
    }
  }

  public editarContato() {
    var usuario: UsuarioDTO = this.usuarioLogado;
    if (this.form.valid) {
      this.processando = true;
      if (this.tipo === 'telefone') {
        let numero = this.form.get("contato").value.replace(/[^0-9]/g, "");
        usuario.celular.ddd = +numero.slice(0, 2),
          usuario.celular.numero = +numero.substring(2);
        this.atualizaUsuario(usuario);
      } else {
        let email: string = this.form.get('contato').value;
        usuario.email = email;
        this.usuarioService.getUsuarioByEmail(email)
          .pipe(untilDestroyed(this))
          .subscribe(
            (response) => {
              if (response) {
                this.processando = false;
                Swal.fire("atenção!", "O email digitado já está cadastrado.", "warning")
              } else {
                this.atualizaUsuario(usuario);
              }
            },
            (error) => { this.atualizaUsuario(usuario); }
          )
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  private atualizaUsuario(usuario: UsuarioDTO) {
    this.usuarioService.atualizarUsuario(usuario)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.processando = false;
          Swal.fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close({ usuario });
          });
        },
        (error) => {
          this.processando = false;
          Swal.fire('error!', error.error, 'error');
        }
      );
  }

  private verificaTelefone(form: FormGroup) {
    return () => {
      const tamanhoTelefone = form.get("contato").value.replace(/[^0-9]/g, "").length;

      if (tamanhoTelefone != 10 && tamanhoTelefone != 11) {
        return { telefone: true } as ValidationErrors;
      }
    };
  }

  public fecha() { this.dialogRef.close(); }

  public getErrorMessage(field: string) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError('required')
        ? 'Campo requerido'
        : this.form.get(field).hasError('email')
          ? 'Email inválido'
          : this.form.get(field).hasError('telefone')
            ? 'Telefone inválido'
            : 'campo inválido';
    }
  }

  public ngOnDestroy(): any { }
}
