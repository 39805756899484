<div class="row" [formGroup]="form">
  <div class="col-12">
    <div class="col-12" style="margin-bottom: 5px">
      <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">
        CELULAR PRINCIPAL
      </h4>
      <p style="font-size: 13px">
        O celular principal é utilizado caso o seu gerente de conta necessite
        entrar em contato com você e para o recebimento, via SMS, de códigos de
        autorização.
      </p>
    </div>

    <app-telefone
      [form]="form.get('telefoneSeguranca')"
      [ehTelefoneSeguranca]="true"
      [aparecerLinkDuvidas]="true"
      [data]="data"
    ></app-telefone>

    <div class="row alinha">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">
          EVENTOS QUE NECESSITAM AUTENTICAÇÃO
        </h4>
        <p style="font-size: 13px">
          Para preservar a sua segurança, deixamos por padrão todos os eventos
          considerados críticos no sistema marcados para serem autenticados, mas
          se quiser você pode desativar alguns deles logo abaixo:
        </p>
      </div>

      <mat-slide-toggle
        formControlName="agendarTransferencias"
        class="col-12"
        style="margin-bottom: 13px"
        [checked]="form.get('agendarTransferencias').value"
        (change)="setarToggle('agendar-transferencias')"
      >
        <span style="color: #6d6d6d">Agendar transferências</span>
      </mat-slide-toggle>
      <mat-slide-toggle
        formControlName="alterarContasBancarias"
        class="col-12"
        style="margin-bottom: 13px"
        [checked]="form.get('alterarContasBancarias').value"
        (change)="setarToggle('alterar-conta-bancaria')"
      >
        <span style="color: #6d6d6d">Alterar contas bancárias</span>
      </mat-slide-toggle>
      <mat-slide-toggle
        formControlName="alterarDadosComerciais"
        class="col-12"
        style="margin-bottom: 13px"
        [checked]="form.get('alterarDadosComerciais').value"
        (change)="setarToggle('alterar-dados-comerciais')"
      >
        <span style="color: #6d6d6d">Alterar dados comerciais</span>
      </mat-slide-toggle>
      <!-- <mat-slide-toggle
        formControlName="alterarCelularPrincipal"
        class="col-12"
        style="margin-bottom: 13px"
      >
        <span style="color: #6d6d6d">Alterar o celular principal</span>
      </mat-slide-toggle> -->
    </div>
  </div>
</div>
