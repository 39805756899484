import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'telefone',
	pure: false
})
export class TelefonePipe implements PipeTransform {

	constructor() { }

	transform(value: string) {
		if (value) {
			value = value.replace(/[^0-9]+/gim, '');
			if (value.length <= 10) { // (99) 9999-9999
				return value.replace(/(\d{2})(\d{4})(\d{4})(.*)/, '($1) $2-$3 $4');
			} // (99) 9 9999-9999
			return value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})(.*)/, '($1) $2 $3-$4 $5');
		}
		return value;
	}

}
