import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aceitar-portabilidade',
  templateUrl: './aceitar-portabilidade.component.html',
  styleUrls: ['./aceitar-portabilidade.component.less']
})
export class AceitarPortabilidadeComponent implements OnInit {

  processando: boolean = false;
  env = environment;

  constructor(
    public dialogRef: MatDialogRef<AceitarPortabilidadeComponent>,
    private pixService: PixEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data.data);
  }

  checaStatus(status) {
    if (status === 0) {
      return 'Aberta';
    }
    if (status === 1) {
      return 'Aguardando Resolução';
    }
    if (status === 2) {
      return 'Confirmada';
    }
    if (status === 3) {
      return 'Cancelada';
    }
    if (status === 4) {
      return 'Concluída';
    }
  }

  confirmarReivindicacao() {
    this.processando = true;

    const obj = {
      idReivindicacao: this.data.data.idReivindicacao,
      motivo: 0
    }

    this.pixService.confirmarReivindicacao(obj).subscribe((res) => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Reivindicação confirmada! Acesse sua conta e conclua a portabilidade.',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      this.processando = false;
    }, err => {
      Swal.fire({
        title: 'Erro!',
        text: err.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }, () => {
      this.dialogRef.close();
    });
  }


  fechar() {
    this.dialogRef.close();
  }

  cancelarReivindicacao() {
    const obj = {
      idReivindicacao: this.data.data.idReivindicacao,
      motivo: 0,
      ehReivindicador: false,
    }

    Swal.fire({
      title: 'Atenção!',
      text: 'Deseja realmente cancelar a portabilidade?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pixService.cancelarReivindicacao(obj)
          .subscribe((res) => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Portabilidade cancelada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            this.dialogRef.close(true);
          }, err => {
            Swal.fire({
              title: 'Erro!',
              text: err.error,
              icon: 'error',
              confirmButtonText: 'OK',
            });
            this.dialogRef.close(false);
          });
      }
    })

  }


}
