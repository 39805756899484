<div class="row">
    <div class="col-12 titulo align-self-end text-center">
        <span>
            Modelo
        </span>
    </div>
    <div class="col-12 subtitulo align-self-end text-center">
        <span>
            Escolha e baixe o modelo de documento para fazer o upload preenchido
        </span>
    </div>
    <div class="col-12 align-self-end d-flex justify-content-center">
        <button type="button" class="btn mat-primary btn-filtro" (click)="xlsx()">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            XLSX
        </button>
    </div>
    <div class="col-12 rodape align-self-end text-center">
        <span>
            * Este passo não é obrigatório, mas certifique-se de que a versão do seu arquivo para upload seja da versão
            {{versao}}
        </span>
    </div>
</div>