import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import swal from 'sweetalert2';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-liquidar-cobranca',
  templateUrl: './liquidar-cobranca.component.html',
  styleUrls: ['./liquidar-cobranca.component.less']
})
export class LiquidarCobrancaComponent implements OnInit, OnDestroy {
  processando = false;
  form: FormGroup;
  env = environment;

  constructor(
    public dialogRef: MatDialogRef<LiquidarCobrancaComponent>,
    private cobrancaService: CobrancaEndpointService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      idCobranca: new FormControl(this.data.cobrancaId),
      notificarCliente: new FormControl(true),
      dataPagamento: new FormControl(new Date(), [Validators.required]),
      valor: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  notificar() { }

  enviar() {
    this.processando = true;

    const data = {
      idCobranca: this.form.get('idCobranca').value,
      notificarCliente: this.form.get('notificarCliente').value,
      dataPagamento: this.form.get('dataPagamento').value.toISOString(),
      valor: Number(this.form.get('valor').value)
    };

    this.cobrancaService.liquidarCobrancaManualmente(data).subscribe(
      response => {
        this.processando = false;

        swal
          .fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(() => {
            if (this.data) {
              if (this.data.liquidouCobranca === false) {
                this.dialogRef.close({ liquidouCobranca: true });
              }
            } else {
              this.dialogRef.close();
            }
          });
      },
      error => {
        this.processando = false;

        swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    );
  }
}
