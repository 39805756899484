import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateBarModule } from '@project/src/app/components/date-bar/date-bar.module';
import { UploadArquivoModule } from '@project/src/app/components/upload-arquivo/upload-arquivo.module';
import { IconsModule } from '@project/src/app/icons.module';
import { MaterialModule } from '@project/src/app/material.module';
import { PipeModule } from '@project/src/app/pipe/pipe.module';
import { TokenModule } from '@project/src/app/components/token/token.module';
import { TransferenciaLoteZeragemEditarComponent } from './transferencia-lote-zeragem-editar/transferencia-lote-zeragem-editar.component';
import { TransferenciaLoteZeragemListarComponent } from './transferencia-lote-zeragem-listar/transferencia-lote-zeragem-listar.component';
import { TransferenciaLoteZeragemModeloComponent } from './transferencia-lote-zeragem-editar/transferencia-lote-zeragem-modelo/transferencia-lote-zeragem-modelo.component';
import { TransferenciaLoteZeragemResumoComponent } from './transferencia-lote-zeragem-editar/transferencia-lote-zeragem-resumo/transferencia-lote-zeragem-resumo.component';
import { TransferenciaLoteZeragemUploadComponent } from './transferencia-lote-zeragem-editar/transferencia-lote-zeragem-upload/transferencia-lote-zeragem-upload.component';
import { TransferenciaLoteItemZeragemListaComponent } from './transferencia-lote-zeragem-listar/transferencia-lote-item-zeragem-lista/transferencia-lote-item-zeragem-lista.component';

@NgModule({
  declarations: [
    TransferenciaLoteZeragemEditarComponent,
    TransferenciaLoteZeragemListarComponent,
    TransferenciaLoteZeragemModeloComponent,
    TransferenciaLoteZeragemResumoComponent,
    TransferenciaLoteZeragemUploadComponent,
    TransferenciaLoteItemZeragemListaComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DateBarModule,
    IconsModule,
    UploadArquivoModule,
    PipeModule,
    TokenModule
  ],
})
export class TransferenciaLoteZeragemModule { }
