import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FalarComGerenteComponent } from '@project/src/app/components/falar-com-gerente/falar-com-gerente.component';
import { TipoTelefone } from './telefone.model';

@Component({
	selector: 'app-telefone',
	templateUrl: './telefone.component.html',
	styleUrls: ['./telefone.component.less']
})
export class TelefoneComponent implements OnInit {
	temPrincipal = false;
	public listaTipoTelefone = TipoTelefone;
	contador = 0;
	@Input()
	public form: FormArray;
	@Input()
	public data: any;
	@Input()
	ehTelefoneSeguranca = false;
	@Input()
	aparecerLinkDuvidas = false;
	@Input()
	telaContato = false;

	constructor(private fb: FormBuilder, private dialog: MatDialog) { }

	ngOnInit() {

		if (this.data && this.data.telefones && this.data.telefones.length) {
			for (let i = 0; i < this.data.telefones.length; i++) {
				if (this.data.telefones[i].principal === true && i != 0) {
					var aux = this.data.telefones[0];
					this.data.telefones[0] = this.data.telefones[i];
					this.data.telefones[i] = aux;
				}
			}

			this.data.telefones.forEach(element => {
				this.add(element);
			});


		} else if (this.form.controls.length === 0) {
			this.add();
			this.form.controls[0].get('principal').setValue(true);
			this.form.controls[0].get('principal').enable();
		}
		this.temPrincipal = true;
	}

	public add(tel?: any) {
		tel = tel || {};

		if (tel.principal === true && this.temPrincipal === false) {
			this.changeContaPrincipal();
		}
		this.form.push(
			this.fb.group(
				{
					principal: this.fb.control(
						{
							value: tel.principal || false,
							disabled: tel.principal === true || this.temPrincipal === false
								? false
								: true
						},
						[Validators.required]
					),
					id: this.fb.control(tel.id || null),
					tipo: this.fb.control(tel.tipo || 'celular', [Validators.required]), // 2 é o tipo celular
					ddd: this.fb.control(tel.ddd),
					numero: this.fb.control(tel.numero ? tel.ddd + '' + tel.numero : '', [Validators.required]), // quando for celular faz verificação de digitos, quando não, não faz
					ramal: this.fb.control(tel.ramal),
					pais: this.fb.control('+55')
				},
				{
					validator: this.obrigatorioTerPrincipal('principal')
				}
			)
		)
		this.contador++;
	}

	obrigatorioTerPrincipal(principal) {
		return (group: FormGroup) => {
			const principalInput = group.controls[principal];

			if (this.ehTelefoneSeguranca) {
				return principalInput.setErrors(null);
			} else {
				let temPrincipal = false;
				this.form.controls.forEach(element => {
					if (element.get('principal').value === true) {
						temPrincipal = true;
						return principalInput.setErrors(null);
					}
				});
				if (temPrincipal === false) {
					return principalInput.setErrors({ obrigatorio: true });
				}
			}
		};
	}

	public del(index) {
		if (this.form.controls[index].get('principal').value === true) {
			this.changeContaPrincipal();
		}
		this.form.removeAt(index);
		this.contador--;
	}

	public esvazia() { // esvazia até sobrar apenas 1
		while (this.form.length > 1) { 
      this.form.removeAt(1);
	  this.contador--;
    }
	}

	changeContaPrincipal(event?) {
		this.temPrincipal = !this.temPrincipal;
		if (this.temPrincipal === true) {
			this.form.controls.forEach(element => {
				if (element.get('principal').value === false) {
					element.get('principal').disable();
				}
			});
		} else {
			this.form.controls.forEach(element => {
				element.get('principal').enable();
			});
		}
	}

	getErrorMessage(form: FormGroup, field) {
		if (form.get(field)) {
			return form.get(field).hasError('required')
				? 'Campo requerido'
				: form.get(field).hasError('minlength')
					? 'Campo inválido'
					: form.get(field).hasError('obrigatorio')
						? 'Deve haver um principal'
						: '';
		}
	}

	isValid(form: FormGroup, field) {
		if (form.get(field)) {
			if (form.get(field).value === '' || form.get(field).value === null) {
				return false;
			}
			return form.get(field).valid;
		}
	}

	gerenteModal() {
		this.dialog.open(FalarComGerenteComponent, {
			width: "800px",
			panelClass: "fundo-empresa",
		});
	}
}
