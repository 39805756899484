<div>
    <h2 style="padding-left: 10%; margin-bottom: 1rem !important;" mat-dialog-title class="titulo-modal mb-5">
        PERSONALIZAÇÃO INVESTIMENTOS <i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>
    <div class="mudaCor input-size row"
        style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px; height: 42.5vh;">

        <p style="margin-left:38px">Digite o texto que aparecerá na tela de investimentos:</p>
        <div mat-dialog-content class="col-12 d-flex flex-column align-items-center justify-content-center"
            [formGroup]="form">
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Texto de investimento</mat-label>
                <textarea matInput #texto formControlName="texto" rows="8" maxlength="600"></textarea>
                <mat-hint align="end">{{texto.value.length}}/600</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions>
        <span class="div-interna flex-end">
            <button [disabled]="form.invalid" (click)="salvar()" class="btn btn-default next fundoCorPrimaria"
                style="width: 20%;">Salvar</button>
        </span>
    </div>
</div>