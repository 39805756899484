import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MensagemEmailEndpointService } from '../../service/mensagem-email-endpoint.service';
import { MensagemEmail } from '../../model/mensagem-email-dto.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ItemMensagemEmail } from '../../model/item-mensagem-email-dto.model';
import { environment } from '@project/src/environments/environment';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-personalizar-mensagens-email',
  templateUrl: './personalizar-mensagens-email.component.html',
  styleUrls: ['./personalizar-mensagens-email.component.less'],
})
export class PersonalizarMensagensEmailComponent
  implements OnInit, AfterViewInit
{
  public formControl: FormControl = new FormControl();
  public filteredOptions: Observable<string[]>;
  public processando: boolean = true;
  public listMensagemEmail: MensagemEmail[];
  public editorContent: string;
  public previewContent: SafeHtml;
  public MAX_LENGTH: number = 5000;
  public actualLength: number = 0;
  public blockSubmit: boolean = false;
  public hasEmailSelected: boolean = false;
  public selectedEmail: string;
  public footerText: string;

  @ViewChild('previewRef') previewRef: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<PersonalizarMensagensEmailComponent>,
    private mensagemEmailEndpointService: MensagemEmailEndpointService,
    private sanitizer: DomSanitizer
  ) {
    this.mensagemEmailEndpointService.listMensagemEmail$.subscribe((list) => {
      this.listMensagemEmail = list;
      this.listMensagemEmail.forEach((mensagemEmail) => {
        mensagemEmail.mensagens.sort((a, b) => a.ordem - b.ordem);
        mensagemEmail.mensagens.forEach((itemMensagemEmail) => {
          itemMensagemEmail.mensagem = itemMensagemEmail.mensagem.replace(
            /\${\(.*?\)!}/,
            (match) => {
              const matchWithoutBraces = match.slice(3, -3);
              return matchWithoutBraces.toUpperCase();
            }
          );
        });
      });
      this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
      this.processando = false;
    });
    dialogRef.backdropClick().subscribe(() => {
      this.fecha();
    });
  }

  ngOnInit(): void {
    this.findAll();
    const endereco = environment.empresa.endereco;
    this.footerText =
      'Enviado por ' +
      environment.nomeApp +
      '. <br/>' +
      endereco.tipoLogradouro +
      ' ' +
      endereco.logradouro +
      ', ' +
      endereco.numero +
      ' - ' +
      endereco.complemento +
      ' - ' +
      endereco.bairro +
      ', ' +
      endereco.cidade +
      ', ' +
      endereco.cep;
  }

  ngAfterViewInit(): void {
    this.previewRef.nativeElement.addEventListener('click', (event) => {
      let target = event.target;
      while (target != null && target.tagName !== 'A') {
        target = target.parentNode;
      }

      if (target && target.tagName === 'A') {
        event.preventDefault();
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.listMensagemEmail
      .filter(
        (option) => option.tipoEmail.toLowerCase().indexOf(filterValue) === 0
      )
      .map((option) => option.tipoEmail);
  }

  findAll(): void {
    this.mensagemEmailEndpointService.findAll();
  }

  onEditorChanged(content: any) {
    if (this.hasEmailSelected) {
      this.actualLength = content.text.length - 1;
      this.hasEmailSelected = false;
      return;
    }
    this.setEditorContent(content.html);
    this.setPreviewContent(content.html);
    this.actualLength = content.text.length - 1;
    if (this.actualLength > this.MAX_LENGTH) {
      this.blockSubmit = true;
    } else {
      this.blockSubmit = false;
    }
  }

  setEditorContent(message: string): void {
    if (message) {
      message.replace(/\\\"/g, '"');
      this.editorContent = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(message)
      );
    } else {
      this.editorContent = '';
    }
  }

  setPreviewContent(editorContent: string): void {
    const editableMessage = { ...this.actualMessage(true) };
    const nonEditableMessage = { ...this.actualMessage(false) };

    editableMessage.mensagem = editorContent;
    const allMessages = [nonEditableMessage, editableMessage];
    allMessages.sort((a, b) => a.ordem - b.ordem);
    const message = !nonEditableMessage.mensagem
      ? editableMessage.mensagem
      : allMessages.map((item) => item.mensagem).join('<br>');

    if (message) {
      this.previewContent = this.sanitizer.bypassSecurityTrustHtml(
        message + '<br>' + this.actualSaudation()
      );
    } else {
      this.previewContent = '';
    }
  }

  actualMessage(isEditable: boolean): ItemMensagemEmail {
    return this.listMensagemEmail
      .find((item) => item.tipoEmail === this.selectedEmail)
      .mensagens.find((item) => item.editavel === isEditable);
  }

  actualSaudation(): string {
    return this.listMensagemEmail.find(
      (item) => item.tipoEmail === this.selectedEmail
    ).saudacao;
  }

  onEmailSelected(event: MatSelectChange) {
    if (
      this.selectedEmail &&
      this.editorContent != this.actualMessage(true).mensagem
    ) {
      Swal.fire({
        title: 'Deseja trocar de e-mail sem salvar suas alterações?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          this.setValues(event);
        }
      });
    } else {
      this.setValues(event);
    }
  }

  setValues(event: MatSelectChange) {
    this.selectedEmail = event.value;
    const editableMessage = { ...this.actualMessage(true) };
    this.setEditorContent(editableMessage.mensagem);
    this.setPreviewContent(editableMessage.mensagem);
    this.hasEmailSelected = true;
  }

  fecha(): void {
    if (
      this.selectedEmail &&
      this.editorContent !== this.actualMessage(true).mensagem
    ) {
      Swal.fire({
        title: 'Deseja sair sem salvar sua alterações?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  onSubmit(): void {
    const actualMensagemEmail: MensagemEmail = this.listMensagemEmail.find(
      (item) => this.selectedEmail === item.tipoEmail
    );
    actualMensagemEmail.mensagens.find((item) => item.editavel).mensagem =
      this.editorContent;
    this.mensagemEmailEndpointService
      .update(actualMensagemEmail)
      .subscribe((_) => {
        Swal.fire({
          title: 'Mensagem de e-mail atualizada com sucesso!',
          icon: 'success',
        });
        this.dialogRef.close();
      });
  }
}
