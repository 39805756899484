import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ModalInfoSignatarioComponent } from './modal-info-signatario/modal-info-signatario.component';
import { VincularSignatarioDocumentoComponent } from './vincular-signatario-documento/vincular-signatario-documento.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-tabela-signatario',
  templateUrl: './tabela-signatario.component.html',
  styleUrls: ['./tabela-signatario.component.less']
})
export class TabelaSignatarioComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private rootService: RootEndpointService,
    private dialog: MatDialog) { }

  @Input()
  data: any;

  private destroy = new Subject();

  @Output()
  public update: EventEmitter<any> = new EventEmitter();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  lista: any = [];

  displayedColumns: string[] = [
    "opcoes",
    "email",
    "telefone",
    "nome",
    "documento",
    "nascimento",
    "tipo"
  ];

  ngOnInit() {
    this.pesquisar();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit() {
    if (this.sort)
      this.sort.sortChange
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.pesquisar());
    if (this.paginator)
      this.paginator.page
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.pesquisar());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //this.pesquisar(this.filtro);
  }

  pesquisar() {
    let page = 0;
    let size = 10;
    if (this.dataSource && this.dataSource.paginator) {
      page = this.dataSource.paginator.pageIndex;
      size = this.dataSource.paginator.pageSize;
    }
    this.rootService.listarSignatario({ page, size }).toPromise().then(data => {
      if (data && data.content) {
        this.dataSource.connect().next(data.content);
        this.lista = data;
      }
    }, error => {
      console.error(error);
    })
  }

  visualizar(row) {
    this.dialog.open(ModalInfoSignatarioComponent, {
      data: {
        signerId: row.id
      },
      width: "800px",
      height: "500px",
      panelClass: "modal-medio",
    });
  }

  excluir(row) {
    this.rootService.excluirSignatario(row.id).toPromise().then(() => {
      Swal.fire("Sucesso", "Signatário excluído com sucesso.", "success").then(() => { this.update.emit() })
    }, error => {
      console.error(error);
      Swal.fire("Erro", error.error, "error");
    })
  }

  vincular(row) {
    this.dialog.open(VincularSignatarioDocumentoComponent, {
      data: {
        dialog: this.data,
        signerId: row.id
      },
      width: "800px",
      height: "500px",
      panelClass: "modal-medio",
    });
  }

  atualizar() {
    this.update.emit();
  }
}
