<div mat-dialog-content class="div-body">
  <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
    <div class="row" style="justify-content: center;">
      <div class="col-12">
        <div class="card">

          <div class="card-header">
            <div class="col-12">
              <div class="row">
                <div class="col-12 text-center">
                  <p class="titulo-modal">NOVO {{ tipo.toUpperCase() }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">   
            <form class="row justify-content-center" [formGroup]="form" (ngSubmit)="editarContato()">  
                <div class="col-12 col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ tipo }}</mat-label>
                    <input *ngIf="tipo === 'email'" matInput type="email" formControlName="contato" required/>
                    <input *ngIf="tipo === 'telefone'" matInput type="tel" formControlName="contato" [dropSpecialCharacters]="false" mask="(00)000000000" required/>
                    <mat-error>{{ getErrorMessage("contato") }}</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6">
                  <button mat-raised-button class="btn btn-block btn-cancel" type="button" (click)="fecha()">
                    Cancelar
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <button mat-raised-button class="btn btn-block btn-submit" type="submit" [disabled]="form.invalid">
                    <fa-icon [icon]="['fas', 'spinner']" [pulse]="true" *ngIf="processando"></fa-icon>
                    Confirmar
                  </button>
                </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>  
