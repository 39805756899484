import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SolicitacaoAumentoDTO } from '@project/src/app/model/solicitacao-aumento-dto.model';
import { AumentoEndpointService } from '@project/src/app/service/aumento-endpoint.service';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import { error } from 'console';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-plano-empresa',
  templateUrl: './plano-empresa.component.html',
  styleUrls: ['./plano-empresa.component.less']
})
export class PlanoEmpresaComponent implements OnInit {

  @Input()
  public data: any;

  @Input()
  public empresaId: number;

  @Input()
  public tipoConta;

  public historico: Array<SolicitacaoAumentoDTO> = [];
  public solicitaAumento: boolean = false;
  public allDataLoaded: boolean = false;
  public processando: boolean = false;
  public valorPrecedente: number = 0.0;
  public dadoSelecionado: number = 0;
  public idCorporacao: number;
  public tipoSelecionado: string = "antecipacao";
  public form: FormGroup;
  public dados: any = [];

  public env = environment;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private service: AumentoEndpointService,
    private autenticaService: AutenticacaoEndpointService,
    private cd: ChangeDetectorRef,
  ) {
    if (this.autenticaService.regraCorporacao)
      this.idCorporacao = this.autenticaService.regraCorporacao.corporacao.id;
  }

  ngOnInit() {

    this.form = this.fb.group({
      valorSolicitado: new FormControl("", [Validators.required]),
      tipo: new FormControl("", [Validators.required]),
      empresaId: new FormControl(this.empresaId, [Validators.required]),
      motivo: new FormControl("")
    })

    this.data = this.data || {};

    if (this.env.modulos.antecipacao.sidebar) {
      this.dados.push({
        title: 'ANTECIPAÇÃO', icon: 'fas fa-copy fa-2x', hasAumento: true, params: [
          { label: 'Limite de antecipação', value: this.data.limiteAntecipacao, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Taxa ao mês por fatura (%)', value: this.data.porcentagemMesFaturaAntecipada, mask: 'separator.2', thousandSeparator: '.', prefix: '', suffix: '%', hasInfo: false },
          { label: 'Tipo da taxa', value: this.data.tipoTaxaAntecipacao, mask: '', prefix: '', suffix: '', hasInfo: false },
          { label: 'Descontar titulo após o vencimento', value: this.data.descontarTituloAposVencimento ? 'Sim' : 'Não', mask: '', prefix: '', suffix: '', hasInfo: false },
        ]
      });
    }
    this.dados.push({
      title: 'MANUTENÇÃO DA CONTA', icon: 'fas fa-cog fa-2x', hasAumento: false, params: [
        { label: 'Mensalidade', value: this.data.precoMensalidade, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
        { label: 'Dia de fechamento', value: this.data.diaFechamentoMensalidade, mask: '', prefix: '', suffix: '', hasInfo: false }
      ]
    })
    if (this.env.modulos.pagamentoContas.sidebar) {
      this.dados.push({
        title: 'PAGAMENTO DE CONTAS', icon: 'fas fa-barcode fa-2x', hasAumento: false, params: [
          { label: 'Preço de pagamento', value: this.data.precoPagamentoConta, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Pagamento de Boletos Diversos', value: this.data.pagamentoBoletosDiversos, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false }
        ]
      });
    }
    if (this.env.modulos.cobranca.sidebar) {
      this.dados.push({
        title: 'BOLETO', icon: 'fas fa-copy fa-2x', hasAumento: true, params: [
          { label: 'Preço de baixa', value: this.data.precoBaixaBoleto, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Preço de edição', value: this.data.precoPadraoEdicaoBoleto, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Preço de emissão', value: this.data.precoEmissaoBoleto, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Preço de liquidação', value: this.data.precoLiquidacaoBoleto, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Valor máximo de emissão', value: this.data.valorMaximoEmissaoBoleto, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Preço manutenção de titulo vencidos', value: this.data.precoManutencaoTituloVencido, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Dias após vencimento aplicação preço manutenção', value: this.data.diasAposVencimentoAplicacaoPrecoManutencao, mask: '', prefix: '', suffix: '', hasInfo: false }
        ]
      })
    }
    if (this.env.modulos.cartaoCredito.sidebar) {
      this.dados.push({
        title: 'CARTÃO', icon: 'far fa-credit-card fa-2x', hasAumento: false, params: [
          { label: 'Preço de pagamento', value: this.data.precoPagamentoCartao, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
          { label: 'Taxa ao mês de pagamento (%)', value: this.data.porcentagemMesPagamentoCartao, mask: 'separator.2', thousandSeparator: '.', prefix: '', suffix: '%', hasInfo: false },
        ]
      });
    }
    if (this.env.modulos.transferencia.sidebar) {
      this.dados.push({
        title: 'TRANSFERÊNCIA', icon: 'fas fa-exchange-alt fa-2x', hasAumento: true, params: [
          { label: 'Limite de transferência diária', value: this.data.limiteTransferenciaDiaria, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Valor limite para soma das transferências no dia' },
          { label: 'Valor mínimo transferência', value: this.data.valorMinimoTransferencia, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Valor mínimo da transferência (> 0)' },
          { label: 'Preço máximo', value: this.data.precoMaximoTransferencia, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Preço de transferência com valor abaixo de R$250,00' },
          { label: 'Preço mínimo', value: this.data.precoMinimoTransferencia, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Preço de transferência com valor superior a R$250,00' },
          { label: 'Transferências gratuitas', value: this.data.quantidadeTransferenciasGratuitasNoMes, mask: '', prefix: '', suffix: '', hasInfo: true, info: 'Quantidade de transferências gratuitas no mês' },
          { label: 'Transferências entre contas ' + this.env.nomeApp, value: this.data.transferenciasEntreContasInternas, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Taxa cobrada ao efetuar uma transferência interna' },
          { label: 'Taxa de transferências - em lote', value: this.data.precoTransferenciaLote, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: true, info: 'Taxa cobrada ao efetuar um lote de transferências' },
        ]
      });
    }
    this.dados.push({
      title: 'ENVIO DE SMS', icon: 'fas fa-copy fa-2x', hasAumento: false, params: [
        { label: 'Taxa de aviso de emissão de fatura', value: this.data.taxaAvisoEmissaoFaturas, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
        { label: 'Taxa de solicitação de extrato', value: this.data.taxaSolicitacaoExtrato, mask: 'separator.2', thousandSeparator: '.', prefix: 'R$ ', suffix: '', hasInfo: false },
      ]
    });
    this.service.listarEmpresa({
      page: 0,
      size: 10,
      order: "-dataCriacao",
      corporacao: this.idCorporacao,
      tipo: this.tipoSelecionado,
      idEmpresa: this.empresaId
    }).toPromise()
      .then((response) => {
        this.historico = response.content;
        this.allDataLoaded = true;
      }, error => {
        Swal.fire({
          title: 'Erro!',
          text: error.error,
          icon: 'error',
        });
        console.log(error);
      })
  }

  public enableSolicitarAumento() {
    if (!this.solicitaAumento) {
      this.solicitaAumento = true;
      switch (this.dados[this.dadoSelecionado].title) {
        case "ANTECIPAÇÃO":
          this.valorPrecedente = this.data.limiteAntecipacao;
          this.form.get("valorSolicitado").setValue(this.data.limiteAntecipacao);
          this.form.get("tipo").setValue("antecipacao");
          this.tipoSelecionado = "antecipacao";
          break;
        case "BOLETO":
          this.valorPrecedente = this.data.valorMaximoEmissaoBoleto;
          this.form.get("valorSolicitado").setValue(this.data.valorMaximoEmissaoBoleto);
          this.form.get("tipo").setValue("boleto");
          this.tipoSelecionado = "boleto";
          break;
        case "TRANSFERÊNCIA":
          this.valorPrecedente = this.data.limiteTransferenciaDiaria;
          this.form.get("valorSolicitado").setValue(this.data.limiteTransferenciaDiaria);
          this.form.get("tipo").setValue("transferencia");
          this.tipoSelecionado = "transferencia";
          break;
        default:
      }
    }
  }
  scroll(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 2);
  }
  public disableSolicitarAumento() {
    this.solicitaAumento = false;
    this.processando = false;
    this.allDataLoaded = false;
    this.historico = [];
    this.tipoSelecionado = "outro";
    this.form.get('motivo').setValue("");
    switch (this.dados[this.dadoSelecionado].title) {
      case "ANTECIPAÇÃO":
        this.tipoSelecionado = "antecipacao";
        break;
      case "BOLETO":
        this.tipoSelecionado = "boleto";
        break;
      case "TRANSFERÊNCIA":
        this.tipoSelecionado = "transferencia";
        break;
      default:
    }
    if (this.tipoSelecionado != "outro") {
      this.service.listarEmpresa({
        page: 0,
        size: 10,
        order: "-dataCriacao",
        corporacao: this.idCorporacao,
        tipo: this.tipoSelecionado,
        idEmpresa: this.empresaId
      }).toPromise()
        .then((response) => {
          this.historico = response.content;
          this.allDataLoaded = true;
          this.cd.detectChanges();
        }, error => {
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
          console.log(error);
        })
    }
  }

  public async solicitarAumento() {
    if (this.form.invalid) { return; }

    this.processando = true;
    this.service.solicitarAumento(this.form.value)
      .subscribe(
        response => {
          this.disableSolicitarAumento();
          Swal.fire({
            title: "Solicitação Cadastrada!",
            text: "Sua solicitação de aumento já foi enviada para moderação. Um email será enviado assim que for verificada.",
            icon: "success"
          });
        },
        error => {
          this.processando = false;
          document.getElementById("processa").hidden = true;
          Swal.fire({
            title: 'Erro',
            text: error.error,
            icon: 'error'
          });
        }
      );
  }
}
