import { Pipe, PipeTransform } from '@angular/core';
import { DateFnsInputDate } from './types';
import addHours from 'date-fns/addHours';

@Pipe({ name: 'dfnsAddHours' })
export class AddHoursPipe implements PipeTransform {
  transform(date: DateFnsInputDate, amount: number): Date {
    return addHours(date, amount);
  }
}
