import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { RequestCache } from './request-cache.service';
import { AutenticacaoEndpointService } from './autenticacao-endpoint.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let copia: HttpRequest<any>;
    if (req.url.startsWith('/')) {
      const auth = req.headers.keys().includes('Authorization');

      if (auth || !this.autenticacao.sessao) {
        copia = req.clone({
          url: `${environment.url.backend}${req.url}`
        });
      } else {
        copia = req.clone({
          url: `${environment.url.backend}${req.url}`,
          headers: req.headers.append('x-auth-token', this.autenticacao.sessao.details.sessionId)
        });
      }
      copia.headers.append('X-Requested-With', 'XMLHttpRequest');
    } else {
      copia = req.clone({});
    }

    return next.handle(copia).pipe(
      tap(
        () => { },
        (event: any) => {
          if ((this.router.url.startsWith('/privado') || this.router.url.startsWith('/root'))
            && event instanceof HttpErrorResponse) {
            if (event.status === 401 && !this.router.url.startsWith('/acesso') && event.error.path !== '/me' && event.error.path !== '/login') {
              this.matDialog.closeAll();
              swal
                .fire({
                  title: 'Sessão expirada',
                  text: 'Sua sessão expirou. Logue novamente para continuar.',
                  icon: 'warning',
                  confirmButtonText: 'OK'
                })
                .then(() => {
                  swal.close();
                  this.router.navigate(['/acesso'])
                }
                );
            }
            if (event.status === 403 && !this.router.url.startsWith('/acesso') && event.error.path !== '/me' && event.error.path !== '/login') {
              this.matDialog.closeAll();
              swal
                .fire({
                  title: 'Acesso negado',
                  text: 'Infelizmente você não tem acesso a essa funcionalidade. Entre em contato com o administrador do sistema se achar que isso é um erro.',
                  icon: 'warning',
                  confirmButtonText: 'OK'
                })
                .then(() => {
                  swal.close();
                }
                );
            }
          }
          return;
        }
      )
    );
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
