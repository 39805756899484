import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-ativar-desativar',
  templateUrl: './input-ativar-desativar.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputAtivarDesativarComponent),
    multi: true,
  }],
  styleUrls: ['./input-ativar-desativar.component.less']
})
export class InputAtivarDesativarComponent implements OnInit, ControlValueAccessor {

  @Input()
  matIcon: string;

  @Input()
  fontSet: string;

  @Input()
  fontIcon: string;

  touched = false;
  model: boolean;
  fnChange: any;
  fnTouched: any;
  disabled: boolean;

  constructor() { }

  ngOnInit() {
    this.model = !!this.model;
  }

  alternar() {
    if (this.disabled === true) {
      return;
    }
    this.touched = true;
    this.model = !this.model;
    if (this.fnChange) {
      this.fnChange(this.model);
    }
    if (this.fnTouched) {
      this.fnTouched();
    }
  }

  writeValue(value: boolean): void {
    this.model = !!value;
  }

  registerOnChange(fn: any): void {
    this.fnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.fnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
