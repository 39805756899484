import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alterar-senha-recorrente',
  templateUrl: './alterar-senha-recorrente.component.html',
  styleUrls: ['./alterar-senha-recorrente.component.less']
})
export class AlterarSenhaRecorrenteComponent implements OnInit {

  processando: boolean = false;
  usuariosClientes: string = '';
  colaboradores: string = '';
  configSistema: any;
  administradores: string = '';

  disabilitaOpcoes: boolean = false;

  inputsEnabled = {
    usuariosClientes: false,
    colaboradores: false,
    operadores: false,
    administradores: false
  };

  configValues = {
    usuariosClientes: null,
    colaboradores: null,
    operadores: null,
    administradores: null
  }

  constructor(
    public dialogRef: MatDialogRef<AlterarSenhaRecorrenteComponent>,
    public rootEndpointService: RootEndpointService,
  ) { }

  ngOnInit(): void {
    this.configuracaoSistema();
  }

  fecha() {
    this.dialogRef.close();
  }

  configuracaoSistema() {
    this.rootEndpointService.getConfiguracoesSistema().subscribe(res => {
      this.configSistema = res;
      this.configValues.usuariosClientes = this.configSistema.diasParaRedefinicaoSenhaUsuario;
      this.configValues.colaboradores = this.configSistema.diasParaRedefinicaoSenhaColaborador;
      this.configValues.operadores = this.configSistema.diasParaRedefinicaoSenhaOperador;
      this.configValues.administradores = this.configSistema.diasParaRedefinicaoSenhaAdministrador;
      this.openInput();
    });
  }

  openInput() {
    for (let input in this.configValues) {
      if (this.configValues[input] !== null) {
        this.inputsEnabled[input] = true;
      }
    }
  }

  toggleInput(inputName: string, event: any) {
    this.inputsEnabled[inputName] = event.checked;
    if (!event.checked) {
      this.configValues[inputName] = null;
    }
  }

  cadastrarConfiguracao() {
    console.log(this.configSistema);
    this.configSistema.diasParaRedefinicaoSenhaUsuario = this.configValues.usuariosClientes;
    this.configSistema.diasParaRedefinicaoSenhaColaborador = this.configValues.colaboradores;
    this.configSistema.diasParaRedefinicaoSenhaOperador = this.configValues.operadores;
    this.configSistema.diasParaRedefinicaoSenhaAdministrador = this.configValues.administradores;

    this.processando = true;
    this.rootEndpointService.updateConfiguracoesSistema(this.configSistema).subscribe(res => {
      this.processando = false;
      if (res) {
        Swal.fire({
          icon: 'success',
          title: 'Configurações cadastradas com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.dialogRef.close(true);
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar configurações!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.dialogRef.close(false);
        })
      }
    })
    this.processando = false;
  }

}
