import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentoDTO } from '@project/src/app/model/sistema/documento-dto.model';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vincular-signatario-documento',
  templateUrl: './vincular-signatario-documento.component.html',
  styleUrls: ['./vincular-signatario-documento.component.less']
})
export class VincularSignatarioDocumentoComponent implements OnInit {

  form: FormArray;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<VincularSignatarioDocumentoComponent>,
    private rootService: RootEndpointService) { }

  ngOnInit() {
    this.form = new FormArray([]);
    this.dataDialog.dialog.documentosOnboardingRoot.forEach((data) => {
      if (data.formato === 'assEletronica')
        this.addDocumentos(data)
    })
    this.dataDialog.dialog.documentosInvestidorRoot.forEach((data) => {
      if (data.formato === 'assEletronica')
        this.addDocumentos(data)
    })
  }

  addDocumentos(documento: DocumentoDTO) {
    this.form.push(
      this._fb.group({
        documento: this._fb.control(documento.documento || '', [Validators.required]),
        id: this._fb.control(documento.id || '', [Validators.required]),
        ativo: this._fb.control(documento.ativo || false, [Validators.required]),
        tipo: this._fb.control(documento.tipo),
        moderacao: this._fb.control(documento.moderacao, [Validators.required]),
        formato: this._fb.control(documento.formato),
        obrigatorio: this._fb.control(documento.obrigatorio || false, [Validators.required]),
        vincular: this._fb.control(false, [Validators.required])
      })
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  salvarVinculo() {
    var docs = [];
    this.form.controls.forEach(doc => {
      if (doc.value.vincular)
        docs.push(doc)
    });
    docs.forEach(doc => {
      this.rootService.vincularSignatarioDocumento(this.dataDialog.signerId, doc.value.id).toPromise().then(response => {
        Swal.fire("Sucesso", "Signatário vinculado ao documento.", "success").then(() => this.dialogRef.close());
      }, error => {
        console.error(error);
        Swal.fire("Error", "Signatário já vinculado a esse documento.", "error").then(() => this.dialogRef.close());
      })
    })
  }
}
