import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate, Router, UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { environment } from '@project/src/environments/environment.default';


@Injectable({
  providedIn: "root",
})
export class acessoRecursosService implements CanActivate {
  modules = environment.modulos;
  constructor(private _router: Router) { }

  private environmentCheck(param: string) {
    switch (param) {
      case "extratoInvestidor":
        return this.modules.investidor.extratoInvestidor;
      case "cobranca":
        return this.modules.cobranca.sidebar;
      case "extrato":
        return this.modules.extrato.sidebar;
      case "transferencia":
        return this.modules.transferencia.sidebar;
      case "credito":
        return this.modules.credito.sidebar;
      case "antecipacao":
        return this.modules.antecipacao.sidebar;
      case "pague-contas":
        return this.modules.pagamentoContas.sidebar;
      case "lista-logs":
        return this.modules.auditoria.logs;
      case "convite":
        return this.modules.convite.indicarAmigos;
      case "investimentos":
        return this.modules.investidor.investimentos;
      case "assessor":
        return this.modules.usuarios.assessor;
      case "assistente":
        return this.modules.usuarios.assistente;
      case "dtvm":
        return this.modules.usuarios.dtvm;
      case "gerenteDeContas":
        return this.modules.usuarios.gerenteDeContas;
      case "gestora":
        return this.modules.usuarios.gestora;
      case "pix":
        return this.modules.pix.transferenciaPix;
      default:
        return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.environmentCheck(route.data.param)) {
      return true;
    } else {
      this._router.navigate(['/principal'])
    }
  }
}