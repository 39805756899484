import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';

@Component({
  selector: 'app-documentacao',
  templateUrl: './documentacao.component.html',
  styleUrls: ['./documentacao.component.less']
})
export class DocumentacaoComponent implements OnInit, OnDestroy {
  processando = false;
  public form: FormGroup;
  public data: any;
  public tipoDocumentacao: string;

  constructor(
    private fb: FormBuilder,
    private rootService: RootEndpointService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DocumentacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.tipoDocumentacao = this.dialogData.tipoDocumentacao
  }

  ngOnInit() {
    this.getConfig()
    this.form = this.fb.group({
      documentosOnboardingRoot: new FormArray([]),
      documentosEmprestimoRoot: new FormArray([]),
      documentosInvestidorRoot: new FormArray([]),
      variaveis: new FormArray([])
    })
  }

  getConfig() {
    this.processando = true;
    this.rootService.getConfiguracoesSistema().pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.data = response;
        this.processando = false;
      },
      (error) => {
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
        this.processando = false;
        console.error(error);
      }
    );
  }

  salvar() {
    const config: any = {
      ...this.data,
      variaveisDocumento: this.form.get('variaveis').value
    }

    switch (this.tipoDocumentacao) {
      case 'baas':
        config.documentosOnboardingRoot = this.form.get('documentosOnboardingRoot').value
        break;
      case 'iaas':
        config.documentosInvestidorRoot = this.form.get('documentosInvestidorRoot').value
        break;
      case 'caas':
        config.documentosEmprestimoRoot = this.form.get('documentosEmprestimoRoot').value
        break;
    }

    this.rootService.updateConfiguracoesSistema(config).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.processando = false;
        this.modalFinal('Documentação atualizada com sucesso!', response);
      }, (error) => {
        console.error(error);
        Swal.fire('Erro', error.error, 'error');
      });
  }

  modalFinal(titulo, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: ''
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    });
    dialog.afterClosed().subscribe(result => {
      this.dialogRef.close();
    });
  }

  update() {
    this.getConfig()
  }

  fecha() {
    this.dialogRef.close()
  }

  ngOnDestroy() { }
}
