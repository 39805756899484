<h2 mat-dialog-title class="titulo-modal">
  Falar com meu gerente <i (click)="fecha()" class="material-icons btn-fechar">close</i>
  <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<div mat-dialog-content class="div-body">
  <div class="row">
    <div class="col-6" *ngIf="gerente">
      <p class="titulo-principal">{{gerente.usuario.nome}} {{gerente.usuario.sobrenome}}</p>
      <p class="menor">Gerente de Contas</p>
      <br>
      <p class="contatos"> <i class="fas fa-phone"></i>{{'(' + gerente.usuario.celular.ddd + ')' + gerente.usuario.celular.numero}}</p>
      <p class="contatos"><i class="fas fa-envelope"></i>{{ gerente.usuario.email }}</p>
    </div>
    <div class="col-6" *ngIf="!gerente">
      <p class="titulo-principal">{{env.empresa.gerente.nome}}</p>
      <p class="menor">{{env.empresa.gerente.cargo}}</p>
      <br>
      <p class="contatos"> <i class="fas fa-phone"></i>{{env.empresa.gerente.telefone}}</p>
      <p class="contatos"><i class="fas fa-envelope"></i>{{env.empresa.gerente.email}}</p>
    </div>
    <div class="col-6">
      <p class="titulo">Suporte</p>
      <p class="menor">Horário de Atendimento</p>
      <p class="menor">{{env.empresa.suporteHorarioAtendimento}}</p>
      <br>
      <p class="titulo">Ouvidoria</p>
      <p class="contatos"> <i class="fas fa-envelope"></i>{{env.empresa.email.ouvidoria}}</p>
    </div>
  </div>

</div>