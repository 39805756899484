import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-arquivo-modal',
  templateUrl: './upload-arquivo-modal.component.html',
  styleUrls: ['./upload-arquivo-modal.component.less']
})
export class UploadArquivoModalComponent implements OnInit, OnDestroy {
  itemSelecionado: any = {};
  files: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadArquivoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) { }

  ngOnInit() {
    if (this.dataDialog.itemSelecionado !== null) {
      this.itemSelecionado = this.dataDialog.itemSelecionado;
    }
  }

  ngOnDestroy() { }

  onFileDropped(event) {
    this.addToFileList(event.dataTransfer.files as Array<File>);
  }

  onSelectFile(event) {
    this.addToFileList(event.target.files);
  }

  addToFileList(files: Array<any>) {
    for (const item of files) {
      const reader = new FileReader();

      reader.readAsDataURL(item);

      reader.onload = (event: any) => {
        const base64 = event.target.result.substring(
          event.target.result.lastIndexOf(',') + 1,
          event.target.result.length
        );

        this.files.push({
          locale: 'UTF-8',
          base64: base64,
          mimeType: item.type,
          nome: item.name,
          tamanho: String(item.size)
        });
      };
    }
  }

  deleteFile(file) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
  }

  formata() {
    const data = {
      idCobranca: [this.itemSelecionado.idCobranca],
      arquivo: this.files
    };

    return data;
  }

  enviar() {
    const gruposCobrancasSelecionadas = this.formata();

    if (this.dataDialog) {
      if (this.dataDialog.fezUpload === false) {
        if (this.dataDialog.tipoModal !== 'documentacao') {
          this.dialogRef.close({ fezUpload: true, gruposCobrancasSelecionadas });
        } else {
          this.dialogRef.close({ fezUpload: true, arquivos: this.files });
        }
      }
    } else {
      this.dialogRef.close();
    }
  }
}
