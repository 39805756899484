import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-signatario',
  templateUrl: './signatario.component.html',
  styleUrls: ['./signatario.component.less']
})
export class SignatarioComponent implements OnInit {

  @Input()
  data: any;
  
  @Output()
  public update: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  atualizar(element) {
    this.update.emit();
  }
}
