import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { LoaderService } from '@components/loader/loader.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private url: string;
  loading = true;

  constructor(
    private router: Router,
    private title: Title,
    private loaderService: LoaderService,
    private meta: Meta) { }

  ngOnInit() {
    this.listenToLoading();
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(untilDestroyed(this))
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName(
          'modal-backdrop'
        )[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    const urlBase = `${window.location.protocol}//${window.location.host}`;
    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', urlBase);
    document.head.appendChild(link);
    this.title.setTitle(`${environment.nomeApp}`);
    this.meta.addTags([
      { name: 'description', content: environment.empresa.descricao },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: `${urlBase}/assets/img/icons/pluga-bank/256x256/fav256.png` },
      { name: 'twitter:title', content: environment.empresa.jargao },
      { name: 'twitter:description', content: environment.empresa.descricao },
      { property: 'og:description', content: environment.empresa.descricao },
      { property: 'og:title', content: environment.empresa.jargao },
      { property: 'og:image', content: `${urlBase}/assets/img/icons/pluga-bank/256x256/fav256.png` },
    ], true);


  }

  ngOnDestroy() { }

  listenToLoading(): void {
    this.loaderService.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
