import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ordenar',
	pure: false
})
export class OrdenarPipe implements PipeTransform {

	transform(value: Array<any>, campo: string, asc: boolean = true) {
		if (value) {
			const ordem = asc ? 1 : -1;
			const result = value.sort((c1: any, c2: any) => {
				const a = c1[campo];
				const b = c2[campo];
				if (typeof a === 'string' && typeof b === 'string') {
					return a.localeCompare(b) * ordem;
				}
				if (typeof a === 'number' && typeof b === 'number') {
					return (a < b ? -1 : a > b ? 1 : 0) * ordem;
				}
				return JSON.stringify(a).localeCompare(JSON.stringify(b)) * ordem;
			});
			return result;
		}
		return value;
	}

}
