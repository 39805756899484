import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PessoaContatoService } from "@project/src/app/service/pessoa-endpoint.service";

@Component({
  selector: "app-limites",
  templateUrl: "./limites.component.html",
  styleUrls: ["./limites.component.less"],
})
export class LimitesComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public processando: boolean;
  @Input() public data: any;
  @Input() public telaContato = false;

  constructor(
    private pessoaContatoService: PessoaContatoService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) { }

  ngOnInit() {
    this.data = this.data || {};

    const limiteTransferencia = this.dataDialog.configuracao ? this.dataDialog.configuracao.limiteTransferencia : null
    this.form.addControl("limiteTransferencia", new FormControl(limiteTransferencia || ""));
  }

  loading() {
    this.processando = true;
    this.form.disable();
  }

  disloading() {
    this.processando = false;
    this.form.enable();
  }
}
