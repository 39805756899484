import { Component, Input, OnChanges, OnInit, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.less']
})
export class EmailComponent implements OnInit, OnChanges, AfterViewInit {
  contador = 0;
  @Input()
  public form: FormArray;
  @Input()
  public data: any;
  @Input()
  public dataEmails: any;
  emails: any;
  @Input()
  public pix: boolean = false;

  constructor(private fb: FormBuilder, private cobrancaService: CobrancaEndpointService) {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataEmails.length) {
      if (this.dataEmails[0].email !== this.form.value[0].email) {
        if (this.form.value[0].email == null) this.form.patchValue(this.dataEmails)
        else this.dataEmails.forEach(element => { this.add(element) });
      }
    }
  }

  ngOnInit() {
    if (this.data) {
      this.cobrancaService.getCobrancaById(this.data).subscribe(
        (response) => {
          this.emails = response.emailsNotificacao;
          this.emails.forEach(element => { this.add(element) });
        }, error => {
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
          console.error(error);
        }
      );
    } else {
      this.add();
    }


  }
  ngAfterViewInit() {

  }
  public add(email?: any) {
    email = email || '';

    this.form.push(
      this.fb.group({
        email: this.fb.control(email.email)
      }
      )
    );
    this.contador++;
  }

  public del(index) {
    this.form.removeAt(index);
    this.contador--;
  }


  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('email')
          ? 'Email inválido'
          : form.get(field).hasError('obrigatorio')
            ? 'Deve haver um principal'
            : '';
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }
}
