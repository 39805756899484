<div fxLayout="column" fxLayoutAlign="start start" style="min-height: 100%">
  <div
    mat-dialog-title
    class="titulo-modal"
    fxLayout="row"
    fxLayoutAlign="start start"
  >
    <div
      fxLayout="row"
      fxFlex="30"
      fxLayoutAlign="start center"
      fxLayoutGap="0.3rem"
      class="titulo-modal-title"
    >
      <i
        class="fas fa-building fa"
        style="color: #707070; font-size: 1.4em; margin-right: 0.5em"
      ></i>
      <span
        ><b>{{ dataDialog.item.pessoa.nome }}</b> <br />
        {{ dataDialog.item.pessoa.documento | cpfCnpj }}</span
      >
    </div>
    <div fxLayout="column" fxFlex="40" style="text-align: center">
      <h3>{{ dataDialog.documento.documento }}</h3>
    </div>
    <div
      fxFlex="30"
      fxLayout="row"
      fxLayoutAlign="end center"
      class="fechar"
      fxLayoutGap="0.5rem"
    >
      <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    </div>
  </div>
  <div
    class="modal-content-arquivos"
    [hidden]="assinaturaEletronica || assinaturaSemiEletronica"
  >
    <div
      class="box-body"
      *ngIf="
        dataDialog.documento.tipo == 'empresa' ||
        dataDialog.documento.tipo == 'pessoa'
      "
    >
      <div
        fxLayout="row wrap"
        class="div-pac"
        fxLayoutAlign="start start"
        *ngIf="
          dataDialog.documento.formato == 'assPreencher' &&
          dataDialog?.documento?.arquivoRoot[0]
        "
      >
        <p fxFlex="60">
          Para fazer download do documento {{ dataDialog.documento.documento }},
          <a
            href="javascript:void(0)"
            class="corPrimaria"
            style="cursor: pointer"
            (click)="downloadDocumento()"
            >clique aqui</a
          >
        </p>
      </div>
      <app-upload-arquivos
        [files]="dataDialog?.arquivos"
        requiredText="Necessário informar ao menos um comprovante de empresa"
        [subTitle]="
          'Faça aqui o upload do ' +
          dataDialog.documento.documento +
          ' assinado'
        "
        (outputFiles)="upload('comprovanteEmpresa', $event)"
      ></app-upload-arquivos>
    </div>
    <div *ngIf="dataDialog.documento.tipo == 'representantes'">
      <div
        fxLayout="row wrap"
        class="div-pac"
        fxLayoutAlign="start start"
        *ngIf="
          dataDialog.documento.formato == 'assPreencher' &&
          dataDialog?.documento?.arquivoRoot[0] &&
          dataDialog?.documento?.formato == 'assPreencher'
        "
      >
        <p fxFlex="100">
          Para fazer download do documento {{ dataDialog.documento.documento }},
          <a
            href="javascript:void(0)"
            class="corPrimaria"
            style="cursor: pointer"
            (click)="downloadDocumento()"
            >clique aqui</a
          >
        </p>
      </div>
      <app-representantes
        [dataDialog]="dataDialog"
        [form]="form.get('representantes')"
        [representantes]="dataDialog.item.representantes"
        [documento]="dataDialog.documento.documento"
      ></app-representantes>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="actions"
    fxLayout="column"
    [hidden]="assinaturaEletronica || assinaturaSemiEletronica"
  >
    <div [hidden]="!processando" fxFlex="100" style="padding: 10px 0 20px 0">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div
      class="botao-centro"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="1rem"
    >
      <div fxFlex="30">
        <div
          (click)="fecha()"
          class="btn btn-default btn-block"
          style="
            color: #ababab;
            background-color: #fff;
            border: 1px solid #ababab;
          "
        >
          Cancelar
        </div>
      </div>

      <div fxFlex="30">
        <div
          class="btn btn-block fundoCorPrimaria"
          (click)="salvar()"
          style="color: #fff"
        >
          Salvar
        </div>
      </div>
    </div>
  </div>

  <div
    fxFlex="100"
    class="modal-content-arquivos"
    *ngIf="assinaturaEletronica && !assinaturaSemiEletronica"
    style="
      flex-direction: column !important;
      justify-content: space-between;
      min-height: 100%;
    "
  >
    <div fxLayout="row" fxFlex="100">
      <div fxFlex="70" class="box-body flex-center column-center" *ngIf="!dataDialog.origem">
        <div style="height: 500px; overflow-y: auto">
          <img
            style="display: block; width: 496px; height: fit-content"
            src="/assets/img/documento_embacado_assinatura_eletronica.png"
          />
        </div>

        <span
          class="font"
          style="
            margin-top: 15px;
            font-size: 0.8rem;
            width: 50%;
            text-align: center;
          "
        >
          <a
            id="link-vinculado"
            (click)="vincular(dataDialog)"
            [style.background-color]="
              notificacoesEnviadas || clicou ? '#818d91' : '#fb8c00'
            "
            [ngStyle]="{
              'pointer-events': notificacoesEnviadas || clicou ? 'none' : ''
            }"
            >Clique aqui</a
          >
          para criar os assinantes e enviar os e-mails para assinatura.
        </span>
      </div>

      <div
        *ngIf="dataDialog.origem === 'root'"
        style="padding-top: 15px"
      >
        <app-upload-arquivos
          [files]="dataDialog?.arquivos"
          requiredText="Necessário informar ao menos um comprovante de empresa"
          [subTitle]="
            'Faça aqui o upload do ' + dataDialog.documento.documento + ' assinado'
          "
          (outputFiles)="upload('comprovanteEmpresa', $event)"
        ></app-upload-arquivos>
        <div
          class="botao-centro"
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayoutGap="1rem"
          style="margin-top: 15px; background-color: transparent;"
        >
          <div fxFlex="30">
            <div
              (click)="fecha()"
              class="btn btn-default btn-block"
              style="
                color: #ababab;
                background-color: #fff;
                border: 1px solid #ababab;
              "
            >
              Cancelar
            </div>
          </div>

          <div fxFlex="30">
            <div
              class="btn btn-block fundoCorPrimaria"
              (click)="salvar()"
              style="color: #fff"
            >
              Salvar
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="30" [hidden]="signers.length === 0">
        <p
          class="font-l"
          style="margin-bottom: 1vh !important; text-align: center"
        >
          Assinantes
        </p>
        <hr style="margin: 10px 20px" />
        <div
          style="
            margin: 10px 20px;
            overflow-y: scroll;
            overflow-x: hidden;
          "
          [style.height]="dataDialog.origem === 'root' ? '20vh' : '70vh'"
        >
          <div *ngFor="let item of signers" class="font">
            Nome: {{ item?.nome }} <br />
            Email: {{ item?.email }} <br />
            Telefone: {{ mascaraTelefone(item?.telefone) }} <br />
            Tipo:
            {{
              item?.tipo === 'representante' ? 'Representante' : 'Testemunha'
            }}
            <br />
            Status:
            <span
              *ngIf="item?.status === 'Aguardando assinatura'"
              style="color: #ff9e00"
              >{{ item?.status }}</span
            >
            <span
              *ngIf="item?.status === 'Clique em Assinar'"
              style="color: #ff9e00"
              >{{ item?.status }}</span
            >
            <span
              *ngIf="item?.status === 'Assinatura realizada'"
              style="color: #00ab5a"
              >{{ item?.status }}</span
            >
            <span
              *ngIf="item?.status === 'Assinatura recusada'"
              style="color: #ff0000"
              >{{ item?.status }}</span
            ><br />
            <span *ngIf="item?.dataAssinatura"
              >Data de assinatura: {{ item?.dataAssinatura }}</span
            ><br />
            <button
              class="btn btn-default"
              [disabled]="!podeReenviar"
              (click)="reenviarEmail(item.id)"
              *ngIf="
                notificacoesEnviadas && item?.status === 'Aguardando assinatura'
              "
              [ngStyle]="{
                'background-color': notificacoesEnviadas ? '#818d91' : '#fb8c00'
              }"
            >
              Reenviar e-mail</button
            ><br />
            <hr />
          </div>
        </div>
        <span class="font" style="margin: 0px 20px"
          >Dúvidas?
          <a [attr.href]="urlTermos" target="_blank" style="color: #fb8c00"
            >Visite nosso FAQ!</a
          ></span
        >
      </div>
      <div *ngIf="signers.length === 0">
        <p
          class="font-l"
          style="margin: 1vh 1vw !important; text-align: center"
        >
          Nenhum assinante foi criado
        </p>
      </div>
    </div>
  </div>
  <div [hidden]="!carregando || !assinaturaEletronica" style="padding: 10px">
    <div class="col-12">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div
    class="modal-content-arquivos"
    *ngIf="assinaturaSemiEletronica && !emprestimo"
    style="padding: 0 !important"
  >
    <div
      fxLayout="row"
      style="height: 64vh !important; padding: 1rem; gap: 1rem"
    >
      <div
        class="preenche box-body"
        fxFlex="70"
        *ngIf="
          dataDialog.documento.tipo == 'empresa' ||
          dataDialog.documento.tipo == 'pessoa'
        "
      >
        <!-- <object [data]="pdf" type="application/pdf"></object> -->
        <object
          class="posiciona-arquivo"
          [data]="pdf"
          type="application/pdf"
        ></object>
      </div>

      <div
        class="box-body"
        fxFlex="30"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 6vh 40px 50px 40px;
          gap: 5%;
        "
      >
        <i class="fas fa-pen-nib fa-3x icone-cor"></i>
        <span
          >Marque as caixas abaixo para CONCORDAR com as declarações a
          seguir.</span
        >
        <div style="margin: 10px 20px">
          <mat-checkbox
            [disabled]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [checked]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [value]="lidoTermo"
            (change)="lidoTermo = !lidoTermo"
            >Declaro ter lido aceito o(s) termo(s) do
            {{ dataDialog?.documento?.documento }}</mat-checkbox
          >
          <mat-checkbox
            [disabled]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [checked]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [value]="naoImpedidoMercado"
            (change)="naoImpedidoMercado = !naoImpedidoMercado"
            >Não estou impedido de operar no mercado de valores
            imobiliários.</mat-checkbox
          >
          <mat-checkbox
            [disabled]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [checked]="
              dataDialog.documento.moderacao == 'aprovado' ||
              dataDialog.documento.moderacao == 'aguardandoModeracao'
            "
            [value]="pessoaVinculadaInstituicao"
            (change)="pessoaVinculadaInstituicao = !pessoaVinculadaInstituicao"
            >Não sou pessoa vinculada a esta instituição.</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div [hidden]="!carregando" style="padding: 10px">
      <div class="col-12">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <div
      mat-dialog-title
      class="titulo-modal"
      fxLayout="row"
      fxLayoutAlign="start start"
    >
      <div
        class="botao-centro"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="1rem"
        style="padding: 0 !important"
      >
        <div fxFlex="15">
          <button
            (click)="fecha()"
            class="btn btn-default btn-block"
            style="
              color: #707070;
              background-color: #fff;
              border: 1px solid #ababab;
            "
          >
            CANCELAR
          </button>
        </div>

        <div fxFlex="15">
          <button
            class="btn btn-block fundoCorPrimaria"
            (click)="salvar()"
            style="color: #fff"
            [disabled]="
              !(
                lidoTermo &&
                naoImpedidoMercado &&
                pessoaVinculadaInstituicao
              ) || processando
            "
          >
            CONCORDAR
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal-content-arquivos"
    *ngIf="assinaturaSemiEletronica && emprestimo"
    style="padding: 0 !important"
  >
    <div
      fxLayout="row"
      style="height: 64vh !important; padding: 1rem; gap: 1rem"
    >
      <div
        class="preenche box-body"
        fxFlex="70"
        *ngIf="dataDialog.documento.modelo == 'emprestimo'"
      >
        <!-- <object [data]="pdf" type="application/pdf"></object> -->
        <object
          class="posiciona-arquivo"
          [data]="pdf"
          type="application/pdf"
        ></object>
      </div>

      <div
        class="box-body"
        fxFlex="30"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 6vh 40px 50px 40px;
          gap: 5%;
        "
      >
        <i class="fas fa-pen-nib fa-3x icone-cor"></i>
        <span
          >Marque as caixas abaixo para CONCORDAR com as declarações a
          seguir.</span
        >
        <div style="margin: 10px 20px">
          <mat-checkbox
            [disabled]="dataDialog.documento.moderacao"
            [checked]="dataDialog.documento.moderacao"
            [value]="lidoTermo"
            (change)="lidoTermo = !lidoTermo"
            >Declaro ter lido aceito o(s) termo(s) do
            {{ dataDialog?.documento?.documento }}</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div [hidden]="!carregando" style="padding: 10px">
      <div class="col-12">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <div
      mat-dialog-title
      class="titulo-modal"
      fxLayout="row"
      fxLayoutAlign="start start"
    >
      <div
        class="botao-centro"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="1rem"
        style="padding: 0 !important"
      >
        <div fxFlex="15">
          <button
            (click)="fecha()"
            class="btn btn-default btn-block"
            style="
              color: #707070;
              background-color: #fff;
              border: 1px solid #ababab;
            "
          >
            CANCELAR
          </button>
        </div>

        <div fxFlex="15">
          <button
            class="btn btn-block fundoCorPrimaria"
            (click)="salvar()"
            style="color: #fff"
            [disabled]="!lidoTermo || processando"
          >
            CONCORDAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
