import {
  Directive,
  HostListener,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragAndDropDirective {
  @Output() isHover = new EventEmitter<boolean>();
  @Output() over = new EventEmitter<DragEvent>();
  @Output() fileDropped = new EventEmitter<DragEvent>();
  @HostBinding('class.hovering') hovering = false;

  // dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.hovering = true;
    this.isHover.emit(true);
  }

  // dragleave listener
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.hovering = false;
    this.isHover.emit(false);
  }

  // drop listener
  @HostListener('drop', ['$event'])
  ondrop(event: DragEvent) {
    event.preventDefault();
    this.hovering = false;
    this.isHover.emit(false);
    const { dataTransfer } = event;
    if (dataTransfer) {
      const { files } = dataTransfer;
      if (files && files.length) {
        this.fileDropped.emit(event);
      }
    }
  }
}
