import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.less']
})
export class NotificacaoComponent implements OnInit {
  env = environment;

  @ViewChild('tab', { static: false }) tabGroup: MatTabGroup;


  constructor(
    public dialogRef: MatDialogRef<NotificacaoComponent>
  ) { }

  ngOnInit() {
  }

  fecha(): void {
    this.dialogRef.close();
  }
}
