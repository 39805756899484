import { Component, OnInit, Input } from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormGroup,
	Validators,
	FormControl
} from '@angular/forms';

@Component({
	selector: 'app-personalizacao',
	templateUrl: './personalizacao.component.html',
	styleUrls: ['./personalizacao.component.less']
})
export class PersonalizacaoComponent implements OnInit {
	@Input()
	public form: FormGroup;

	@Input()
	public data: any;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.data = this.data || {};
		this.form.addControl(
			'habilitarPersonalizacao',
			new FormControl(this.data.habilitarPersonalizacao || true, [
				Validators.required
			])
		);
	}

	getErrorMessage(field) {
		if (this.form.get(field)) {
			return this.form.get(field).hasError('required')
				? 'Campo requerido'
				: this.form.get(field).hasError('minlength')
				? 'Campo inválido'
				: '';
		}
	}

	isValid(form: FormGroup, field) {
		if (form.get(field)) {
			if (form.get(field).value === '' || form.get(field).value === null) {
				return false;
			}
			return form.get(field).valid;
		}
	}
}
