import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AutenticacaoEndpointService } from "src/app/service/autenticacao-endpoint.service";
import { EmpresaEndpointService } from "src/app/service/empresa-endpoint.service";
import { NotificacaoEndpointService } from "src/app/service/notificacao-endpoint.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-nova-notificacao",
  templateUrl: "./nova-notificacao.component.html",
  styleUrls: ["./nova-notificacao.component.less"],
})
export class NovaNotificacaoComponent implements OnInit, OnDestroy {
  empresaLogada: any = {};
  processando = false;
  public form: FormGroup;
  nivelNotificacao: string;

  empresaList: any = [];
  filtroEmpresa: Observable<any[]>;

  corporacaoList: any = [];
  filtroCorporacao: Observable<any[]>;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private dialog: MatDialog,
    private notificacaoService: NotificacaoEndpointService,
    private formBuilder: FormBuilder,
    private empresaService: EmpresaEndpointService,
    public dialogRef: MatDialogRef<NovaNotificacaoComponent>
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa.pessoa;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: new FormControl(""),
      dataHora: new FormControl(new Date()),
      mensagem: new FormControl(""),
      nivel: new FormControl(""),
      tipo: new FormControl(""),
      url: new FormControl(""),
      usuario: new FormControl(""),
      empresaId: new FormControl(""),
      corporacaoId: new FormControl(""),
      visualizou: new FormControl(false),
    });
    this.getEmpresas();
    this.getCorporacao();
  }

  ngOnDestroy() { }

  fecha(): void {
    this.dialogRef.close();
  }
  criar() {
    let corporacaoId = this.form.get("corporacaoId").value.id || "";
    let empresaId = this.form.get("empresaId").value.id || "";
    let body = {
      corporacaoId: corporacaoId,
      dataHora: this.form.get("dataHora").value,
      empresaId: empresaId,
      mensagem: this.form.get("mensagem").value,
      nivel: this.form.get("nivel").value,
      tipo: this.form.get("tipo").value,
      url: this.form.get("url").value,
      usuario: this.form.get("usuario").value,
      visualizou: false,
    };


    this.notificacaoService.novaNotificacao(body).pipe(untilDestroyed(this)).subscribe(
      (response) => {
        Swal.fire('Sucesso', 'Notificação cadastrada com sucesso', 'success').then(() => {
          this.dialog.closeAll();
        });
      },
      (error) => {
        console.error(error);
        Swal.fire('Erro', error.error, 'error').then(() => {
          this.dialog.closeAll();
        });
      }
    );
  }

  selecionar() { }

  getCorporacao() {
    this.notificacaoService
      .getCorporacao()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.corporacaoList = response;
          this.filtroCorporacao = this.form
            .get("corporacaoId")
            .valueChanges.pipe(
              startWith(""),
              map((item) => this._filterCorporacao(item))
            );
        },
        (error) => {
          Swal.fire({
            title: "Erro ao buscar corporações",
            text: error.error,
            icon: "error",
          })
          console.log(error);
        }
      );
  }

  getEmpresas() {
    this.processando = true;
    let page = 0, size = 10;
    let order: string;
    const filtro = {};
    this.empresaService
      .listar({ page, size })
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.processando = false;
          this.empresaList = response.content;
          this.filtroEmpresa = this.form.get("empresaId").valueChanges.pipe(
            startWith(""),
            map((item) => this._filter(item))
          );
        },
        (error) => { }
      );
  }

  // listar() {
  //   this.processando = true;
  //   this.changeDetectorRef.detectChanges();

  //   const prefixSort = this.sort.direction === 'desc' ? '-' : '';
  //   let order: string;
  //   if (this.sort.active) {
  //     if (this.sort.active === 'valorTotal') {
  //       order = prefixSort + 'titulo.valorNominal';
  //     } else {
  //       order = prefixSort + this.sort.active;
  //     }
  //   }

  //   const filtro = this.filtro;

  //   let page = 0, size = 10;
  //   if (this.dataSource) {
  //     if (this.dataSource.paginator) {
  //       page = this.dataSource.paginator.pageIndex;
  //       size = this.dataSource.paginator.pageSize;
  //     }
  //   }
  //   this.empresaService.listar({
  //     page,
  //     size,
  //     order,
  //    ...filtro
  //   }).toPromise().then(data => {
  //     this.dataSource.connect().next(data.content);
  //     this.lista = data;
  //     return data;
  //   }).catch(console.error)
  //     .then(() => {
  //       this.processando = false;
  //       this.changeDetectorRef.detectChanges();
  //     });
  // }

  private _filter(value: any): any[] {
    if (this.isString(value)) {
      const filterValue = value;

      if (!isNaN(filterValue)) {
        return this.empresaList.filter((item) =>
          item.pessoa.documento.toLowerCase().includes(filterValue)
        );
      } else {
        return this.empresaList.filter((item) =>
          (item.pessoa.razaoSocial || item.pessoa.nome)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      }
    }
  }

  private _filterCorporacao(value: any): any[] {
    if (this.isString(value)) {
      const filterValue = value;

      if (!isNaN(filterValue)) {
        return this.corporacaoList.filter((item) =>
          item.nome.toLowerCase().includes(filterValue)
        );
      } else {
        return this.corporacaoList.filter((item) =>
          item.nome.toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }

  isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  displayFn(item): any {
    if (this.empresaList.length > 0) {
      return item
        ? (item.pessoa.razaoSocial || item.pessoa.nome) +
        " - " +
        item.pessoa.documento
        : item;
    }
  }

  displayCorporacao(item): any {
    if (this.corporacaoList.length > 0) {
      return item ? item.nome : item;
    }
  }
}
