export const iconsCarrousel = [
  'insert_drive_file',
  'home',
  'attach_money',
  'phone',
  'email',
  'location_on',
  'account_circle',
  'rss_feed',
  'link',
  'language',
  'info',
  'help',
  'settings',
  'build',
  'search',
  'import_export',
  'fingerprint',
  'help',
  'info',
  'language',
  'link',
  'lock',
  'lock_open',
  'lock_outline',
  'loyalty',
  'offline_pin',
  'opacity',
  'open_with',
  'pageview',
  'pan_tool',
  'payment',
  'report_problem',
  'visibility',
]