import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TransferenciaLoteZeragemEditarComponent } from '../transferencia-lote-zeragem-editar.component';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { Environment, environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-transferencia-lote-zeragem-resumo',
  templateUrl: './transferencia-lote-zeragem-resumo.component.html',
  styleUrls: ['./transferencia-lote-zeragem-resumo.component.less']
})
export class TransferenciaLoteZeragemResumoComponent implements OnInit, OnDestroy {
  public env: Environment = environment;
  urlAjuda = null;
  @Input()
  transferenciaLoteEditarComponent: TransferenciaLoteZeragemEditarComponent

  constructor(
    private rootService: RootEndpointService,
  ) { }

  ngOnInit() {
    this.getAjuda();
  }

  get saldoAtual() {
    if (this.transferenciaLoteEditarComponent) {
      return this.transferenciaLoteEditarComponent.saldoAtual || 0;
    }
    return 0;
  }

  getAjuda() {
    this.rootService.getAjudas().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[3] != '') this.urlAjuda = response[3];
          // Antecipacoes 0
          // Cobrancas 1
          // Contatos 2
          // Transferencias 3
          // Pagamentos 4

        })
  }
  get transferenciaLoteEditarUploadComponent() {
    if (this.transferenciaLoteEditarComponent) {
      return this.transferenciaLoteEditarComponent.transferenciaLoteEditarUploadComponent;
    }
  }

  get dadosValidacao() {
    if (this.transferenciaLoteEditarUploadComponent) {
      return this.transferenciaLoteEditarUploadComponent.dadosValidacao;
    }
  }

  get itens() {
    if (this.dadosValidacao) {
      return this.dadosValidacao.itens;
    }
  }

  get valorTotalLote() {
    if (this.itens && this.itens.length) {
      return this.itens.map(m => m.valor || 0).reduce((a, b) => a + b);
    }
    return 0;
  }

  get linhasTotais() {
    if (this.itens) {
      return this.itens.length;
    }
    return 0;
  }

  get linhasErro() {
    if (this.itens) {
      return this.itens.filter(m => !m.status).length;
    }
    return 0;
  }

  get linhasSucesso() {
    return this.linhasTotais - this.linhasErro;
  }

  ngOnDestroy() { }
}
