import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirmar-portabilidade',
  templateUrl: './confirmar-portabilidade.component.html',
  styleUrls: ['./confirmar-portabilidade.component.less']
})
export class ConfirmarPortabilidadeComponent implements OnInit {

  processando: boolean = false;
  env = environment;

  constructor(
    private pixService: PixEndpointService,
    public dialogRef: MatDialogRef<ConfirmarPortabilidadeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
  }


  realizarPortabilidade() {
    this.processando = true;
    this.pixService.criarRevindicacao(this.data.objetoChavePix).subscribe(
      (res) => {
        this.processando = false;
        this.dialogRef.close(true);
        if (res) {
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#4caf50',
          });
        }
      },
      (err) => {
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#f44336',
        });
        this.processando = false;
        this.dialogRef.close(false);
      }
    );
  }

  checaStatus(status) {
    if (status === "0") {
      return 'Aberta';
    }
    if (status === "1") {
      return 'Aguardando Resolução';
    }
    if (status === "2") {
      return 'Confirmada';
    }
    if (status === "3") {
      return 'Cancelada';
    }
    if (status === "4") {
      return 'Concluída';
    }
  }

  confirmarPortabilidade() {

    const obj = {
      idReivindicacao: this.data.data.idReivindicacao,
      motivo: 0,
    }

    this.pixService.concluirReivindicacao(obj)
      .subscribe((res) => {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Portabilidade confirmada com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#4caf50',
        });
        this.dialogRef.close(true);
      }, err => {
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#f44336',
        });
        this.dialogRef.close(false);
      });
  }

  confirmarReivindicacao(chave) {
    this.pixService.confirmarReivindicacao(chave).subscribe((res) => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Reivindicação confirmada! Acesse sua conta e conclua a portabilidade.',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    }, err => {
      console.log(err);
    })
  }

  cancelarReivindicacao() {
    const obj = {
      idReivindicacao: this.data.data.idReivindicacao,
      motivo: 0,
      ehReivindicador: true,
    }

    Swal.fire({
      title: 'Atenção!',
      text: 'Deseja realmente cancelar a portabilidade?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pixService.cancelarReivindicacao(obj)
          .subscribe((res) => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Portabilidade cancelada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            this.dialogRef.close(true);
          }, err => {
            Swal.fire({
              title: 'Erro!',
              text: err.error,
              icon: 'error',
              confirmButtonText: 'OK',
            });
            this.dialogRef.close(false);
          });
      }
    })

  }


  fechar() {
    this.dialogRef.close();
  }

}
