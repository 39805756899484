import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadArquivoComponent } from './upload-arquivo.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { DirectiveModule } from '../../directive/directive.module';
import { IconsModule } from '../../icons.module';



@NgModule({
  declarations: [UploadArquivoComponent],
  exports: [UploadArquivoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectiveModule,
    IconsModule
  ]
})
export class UploadArquivoModule { }
