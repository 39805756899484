import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizacaoComponent } from './localizacao.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';



@NgModule({
    declarations: [LocalizacaoComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: [
        LocalizacaoComponent
    ]
})
export class LocalizacaoModule { }
