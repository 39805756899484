import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})

export class LoaderComponent implements OnInit {

  loading: boolean = false;

  constructor(private _loading: LoaderService) { }

  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

}
