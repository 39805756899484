import { AutenticacaoEndpointService } from './../../service/autenticacao-endpoint.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CnabService } from '@service/cnab-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cnab',
  templateUrl: './cnab.component.html',
  styleUrls: ['./cnab.component.less']
})
export class CnabComponent implements OnInit {

  empresaLogada: any = {};
  files: any[] = [];
  processando: boolean = false;

  constructor(private autenticacao: AutenticacaoEndpointService,
    private cnabService: CnabService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CnabComponent>) {
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa.pessoa;
  }

  ngOnInit() {
  }

  fecha(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() { }

  onFileDropped($event) {
    this.addToFileList($event);
  }

  onSelectFile(event) {
    this.addToFileList(event.target.files);
  }

  addToFileList(files: Array<any>) {
    for (const item of files) {
      const reader = new FileReader();

      reader.readAsDataURL(item);

      reader.onload = (event: any) => {
        const base64 = event.target.result.substring(
          event.target.result.lastIndexOf(',') + 1,
          event.target.result.length
        );

        this.files.push({
          locale: 'UTF-8',
          base64: base64,
          mimeType: item.type,
          nome: item.name,
          tamanho: `${item.size}`
        });
      };
    }
  }

  deleteFile(file) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
  }

  enviar() {
    this.processando = true;
    this.cnabService.importarCnab(this.files[0]).subscribe((response: any) => {
      this.processando = false;
      Swal.fire('Sucesso', response, 'success');
      this.dialogRef.close();
    }, error => {
      this.processando = false;
      Swal.fire('Erro', error.error, 'error');
      console.error(error);
    });

  }

}
