<form [formGroup]="form">
  <div class="row alinha" *ngFor="let tel of form.controls; index as i" [formGroup]="tel">

    <div [ngClass]="ehTelefoneSeguranca ? 'col-10' : 'col-8'">
      <h4 class="titulo">TELEFONE</h4>
    </div>
    <div class="col-2" style="text-align: end;">
      <i class="fas fa-trash icones"
        [title]="!tel.get('principal').value ? 'Excluir' : 'Não é possivel excluir o telefone principal'"
        (click)="!tel.get('principal').value && contador > 1 ? del(i) : null"
        [style.color]="!tel.get('principal').value && contador > 1 ? 'red' : 'grey'" style="padding-top: 12px"></i>
    </div>
    <div *ngIf="!ehTelefoneSeguranca" class="col-2" style="margin-top: 14px; text-align: end;">
      <mat-slide-toggle formControlName="principal" (change)="changeContaPrincipal($event)">Principal
      </mat-slide-toggle>
      <mat-error style="font-size: 12px;">{{ getErrorMessage(tel, "principal") }}</mat-error>
    </div>



    <div class="col-12 col-md-3">
      <mat-form-field appearance="outline">
        <mat-label>Tipo</mat-label>
        <mat-select *ngIf="!ehTelefoneSeguranca" formControlName="tipo" required>
          <mat-option *ngFor="let tipo of listaTipoTelefone" [value]="tipo.valor">
            {{ tipo.valor }}
          </mat-option>
        </mat-select>
        <mat-select *ngIf="ehTelefoneSeguranca" [disabled]="apenasLerCampo" formControlName="tipo">
          <mat-option *ngFor="let tipo of listaTipoTelefone" [value]="tipo.valor">
            {{ tipo.valor }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getErrorMessage(tel, "tipo") }}</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-7">
      <mat-form-field appearance="outline">
        <mat-label>DDD + Número</mat-label>
        <input *ngIf="!ehTelefoneSeguranca" matInput type="text" formControlName="numero" [mask]="'(00) 0000000099'" required/>

        <input *ngIf="ehTelefoneSeguranca" matInput type="text" formControlName="numero" [mask]="'(00) 0000000099'" required/>
        <mat-error>{{ getErrorMessage(tel, "numero") }}</mat-error>
      </mat-form-field>
    </div>


    <div class="col-12 col-md-2">
      <mat-form-field appearance="outline">
        <mat-label>Ramal</mat-label>
        <input *ngIf="!ehTelefoneSeguranca" matInput type="text" formControlName="ramal" />
        <input [readonly]="apenasLerCampo" *ngIf="ehTelefoneSeguranca" matInput type="text" formControlName="ramal" />
        <mat-error>{{ getErrorMessage(tel, "ramal") }}</mat-error>
      </mat-form-field>
    </div>

  </div>
</form>

<div class="row alinha">
  <div class="col-6 col-md-6 add">
    <a *ngIf="aparecerLinkDuvidas === true" class="text-muted" (click)="gerenteModal()">Dúvidas? visite nossa FAQ</a>

    <!-- #observacao se pedirem para remover a adição de novos números no contato é só remover essa linha -->
    <span *ngIf="telaContato === true" (click)="add()">+ adicionar novo telefone</span>
  </div>
</div>