import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoDocumento',
  pure: false
})
export class TipoDocumentoPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'empresa':
        return 'Pessoa Jurídica';
      case 'pessoa':
        return 'Pessoa Física';
      case 'representantes':
        return 'Pessoa Jurídica (Representantes)';
      default:
        return '';
    }
  }

}