import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disable]'
})
export class DisableControlDirective {

  @Input()
  set disable(condition: boolean) {
    if (this.ngControl) {
      if (this.ngControl.control) {
        const action = condition ? 'disable' : 'enable';
        if (this.ngControl.control[action]) {
          if (typeof this.ngControl.control[action] === 'function') {
            this.ngControl.control[action]();
          }
        }
      }
    }
  }

  constructor(private ngControl: NgControl) { }

}
