import { Injectable } from '@angular/core';
import { environment } from '@project/src/environments/environment';
import { Client } from '@stomp/stompjs';
import { AutenticacaoEndpointService } from './autenticacao-endpoint.service';
import { ListaNotificacaoDTO } from '@model/lista-notificacao-dto.model';
import { NotificacaoEndpointService } from './notificacao-endpoint.service';
import { PageResponse } from '../model/page-response.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketNotificationsService {
  private client: Client;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private notificacaoEndpointService: NotificacaoEndpointService
  ) {}

  initializeWebSocketConnection(): void {
    const backendWebSocket =
      environment.url.backend.replace('http', 'ws') + '/ws';
    this.client = new Client({
      brokerURL: `${backendWebSocket}?X-Auth-Token=${this.autenticacao.sessao.details.sessionId}`,
      connectHeaders: {
        'X-Auth-Token': this.autenticacao.sessao.details.sessionId,
      },
      reconnectDelay: 30000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    this.client.onConnect = (frame) => {
      this.client.subscribe('/user/queue/notifications', (message) => {
        const notificacao: ListaNotificacaoDTO = JSON.parse(message.body);
        this.incrementUnreadNotifications(notificacao);
      });
    };

    this.client.activate();
  }

  incrementUnreadNotifications(notificacao: ListaNotificacaoDTO): void {
    this.notificacaoEndpointService.newNotificacaoSource.next(notificacao);

    const currentResponse: PageResponse<ListaNotificacaoDTO> =
      this.notificacaoEndpointService.unreadNotificacoesSource.getValue();
    const updatedList: ListaNotificacaoDTO[] = [
      notificacao,
      ...currentResponse.content,
    ];
    this.notificacaoEndpointService.unreadNotificacoesSource.next({
      ...currentResponse,
      content: updatedList,
      totalElements: currentResponse.totalElements + 1,
    });
  }

  decrementUnreadNotifications(notificacao: ListaNotificacaoDTO): void {
    this.notificacaoEndpointService
      .alterarNotificacaoParaVisualizado(notificacao.id)
      .subscribe(() => {
        const currentResponse: PageResponse<ListaNotificacaoDTO> =
          this.notificacaoEndpointService.unreadNotificacoesSource.getValue();
        const updatedList: ListaNotificacaoDTO[] =
          currentResponse.content.filter((item) => item.id !== notificacao.id);
        this.notificacaoEndpointService.unreadNotificacoesSource.next({
          ...currentResponse,
          content: updatedList,
          totalElements: currentResponse.totalElements - 1,
        });
      });
  }

  disconnectWebSocketConnection() {
    if (this.client) {
      this.client.deactivate();
    }
  }
}
