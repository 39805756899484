import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MensagemEmail } from '../model/mensagem-email-dto.model';

@Injectable({
  providedIn: 'root'
})
export class MensagemEmailEndpointService {
  listMensagemEmail = new Subject<MensagemEmail[]>();
  listMensagemEmail$ = this.listMensagemEmail.asObservable();
  private url = '/mensagem-email';

  constructor(private client: HttpClient) { }

  findAll(): void {
    this.client.get(this.url).subscribe((response: MensagemEmail[]) => {
      this.listMensagemEmail.next(response);
    });
  }

  update(mensagemEmail: MensagemEmail): Observable<Object> {
    return this.client.patch(this.url, mensagemEmail);
  }
}
