<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    ADICIONAR IMAGEM
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <div mat-dialog-content class="mat-dialog-content-responsive">
    <div class="mudaCor input-size row " id="modal-total">
      <!-- <h4 class="color-title">Resolução indicada: 744x200</h4> -->
      <app-image-cropper-indicar-contato (base64)="capturarBase64($event)" (nameFile)="capturarNomeFile($event)"
        (type)="capturarTypeFile($event)"></app-image-cropper-indicar-contato>
      <div class="card-body">
        <form [formGroup]="form">

          <div style="display: flex;
          justify-content: center;">
            <button style="width: 170px;" type="button" class="btn btn-default next" id="color-btn" mat-raised-button
              (click)="onSelectFile()">
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>