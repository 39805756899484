import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PerfilColaboradorDTO } from '../model/perfil-colaborador-dto.model';
import { PageResponse } from '../model/page-response.model';
import { ColaboradorDTO } from '../model/colaborador-dto-model';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorEndpointService {

  constructor(private client: HttpClient) { }

  criar(colaborador: any): Observable<any> {
    const url = '/colaborador/criar-colaborador'
    return this.client.post(url, colaborador);
  }

  editar(colaborador: any): Observable<any> {
    const url = '/colaborador/atualizar-colaborador'
    return this.client.post(url, colaborador);
  }

  desativar(idUsuario: string): Observable<any> {
    const url = `/colaborador/desativar-colaborador?idUsuario=${idUsuario}`
    return this.client.delete(url);
  }

  ativar(idUsuario: string): Observable<any> {
    const url = `/colaborador/ativar-colaborador?idUsuario=${idUsuario}`
    return this.client.get(url);
  }

  listarTodos(page: number, size: number, order?: string, query?: string, tipoColaborador?: string, filtro?: any): Observable<any> {
    const url = '/colaborador/getAll-colaborador'
    let params = new HttpParams();
  
    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    order ? params = params.append('order', `${order}`) : null;
    query ? params = params.append('query', `${query}`) : null;
    tipoColaborador ? params = params.append('tipoColaborador', tipoColaborador) : null;

    if(filtro){
      if (filtro.desativado && filtro.desativado.show) {
        params = params.append("desativado", `${filtro.desativado.choice}`);
      }
      if (filtro.mfaAtivado && filtro.mfaAtivado.show) {
        params = params.append("mfaAtivado", `${filtro.mfaAtivado.choice}`);
      }
      if (filtro.permissaoNovasEmpresas && filtro.permissaoNovasEmpresas.show) {
        params = params.append("permissaoNovasEmpresas", `${filtro.permissaoNovasEmpresas.choice}`);
      }
    }

    return this.client.get(url, { params });
  }

  getByEmail(emailColaborador: string): Observable<any> {
    const url = '/colaborador/getByEmail-colaborador'
    let params = new HttpParams();

    params = params.append('emailColaborador', `${emailColaborador}`);
  
    return this.client.get(url, { params });
  }

  getColaboradorByEmpresaAndTipo(page: number, size: number, empresaId: number, tipo: string, order?: string): Observable<any> {
    const url = '/colaborador/getColaborador-by-empresa'
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    params = params.append('empresaId', `${empresaId}`);
    params = params.append('tipo', `${tipo}`)
    order ? params = params.append('order', `${order}`) : null;
    return this.client.get(url, { params });
  }

  getColaboradorByFundoAndTipo(page: number, size: number, fundoId: number, tipo: string, order?: string): Observable<any> {
    const url = '/colaborador/getColaborador-by-fundo'
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    params = params.append('fundoId', `${fundoId}`);
    params = params.append('tipo', `${tipo}`)
    order ? params = params.append('order', `${order}`) : null;
    return this.client.get(url, { params });
  }

  getByNro(nroColaborador: string): Observable<any> {
    const url = '/colaborador/getByNro-colaborador'
    let params = new HttpParams();

    params = params.append('nroColaborador', `${nroColaborador}`);
  
    return this.client.get(url, { params });
  }

  salvarPerfilColaborador(perfil: PerfilColaboradorDTO): Observable<any> {
    const url = '/colaborador/salvar-perfil-colaborador'
    return this.client.post(url, perfil);
  }

  listarPerfisColaborador(page: number, size: number): Observable<PageResponse<PerfilColaboradorDTO>> {
    const url = '/colaborador/listar-perfis-colaborador'
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    return this.client.get<PageResponse<PerfilColaboradorDTO>>(url, { params });
  }

  listarPerfilColaborador(id: number): Observable<PerfilColaboradorDTO> {
    const url = `/colaborador/listar-perfil-colaborador/${id}`
    return this.client.get<PerfilColaboradorDTO>(url);
  }

  alternarStatusPerfilColaborador(id: number): Observable<any> {
    const url = `/colaborador/alternar-status-perfil-colaborador/${id}`
    return this.client.patch(url, null);
  }

  listarColaboradoresPerfil(perfilId: number, page: number, size: number): Observable<PageResponse<ColaboradorDTO>> {
    const url = `/colaborador/listar-colaboradores-perfil/${perfilId}`
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    return this.client.get(url, { params });
  }
}
