import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@app/icons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipeModule } from '../../pipe/pipe.module';
import { ToolbarComponent } from './listar-documento/toolbar/toolbar.component';
import { NovoDocumentoComponent } from './novo-documento/novo-documento.component';
import { UploadArquivosModule } from '../upload-arquivos/upload-arquivos.module';
import { VariaveisComponent } from './variaveis/variaveis.component';
import { ListarDocumentoComponent } from './listar-documento/listar-documento.component';
import { SignatarioComponent } from './signatario/signatario.component';
import { TabelaSignatarioComponent } from './signatario/tabela-signatario/tabela-signatario.component';
import { CriarSignatarioComponent } from './signatario/criar-signatario/criar-signatario.component';
import { ToolbarSignerComponent } from './signatario/tabela-signatario/toolbar-signer/toolbar-signer.component';
import { NovoSignatarioComponent } from './signatario/novo-signatario/novo-signatario.component';
import { VincularSignatarioDocumentoComponent } from './signatario/tabela-signatario/vincular-signatario-documento/vincular-signatario-documento.component';
import { ModalInfoSignatarioComponent } from './signatario/tabela-signatario/modal-info-signatario/modal-info-signatario.component';
import { DocumentacaoComponent } from './documentacao.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [
    DocumentacaoComponent,
    ListarDocumentoComponent,
    ToolbarComponent,
    NovoDocumentoComponent,
    VariaveisComponent,
    SignatarioComponent,
    TabelaSignatarioComponent,
    CriarSignatarioComponent,
    ToolbarSignerComponent,
    NovoSignatarioComponent,
    VincularSignatarioDocumentoComponent,
    ModalInfoSignatarioComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule,
    MatDialogModule,
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FlexLayoutModule,
    PipeModule,
    UploadArquivosModule,
    MatButtonToggleModule,
    AngularEditorModule,
  ],
  exports: [
    ListarDocumentoComponent,
    NovoDocumentoComponent,
    NovoSignatarioComponent,
    VincularSignatarioDocumentoComponent,
    ModalInfoSignatarioComponent,
  ],
})
export class DocumentacaoModule { }
