import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moderacaoEmpresa',
  pure: false
})
export class ModeracaoEmpresaPipe implements PipeTransform {

  transform(value: string): string {

    switch (value) {
      case 'aprovada':
        return 'Aprovada';
      case 'aguardandoModeracao':
        return 'Em análise';
      case 'reprovado':
        return 'Reprovada';
      case 'incompleto':
        return 'Pendente';
      case 'aprovado':
        return 'Aprovado';
      case 'reprovada':
        return 'Reprovada';
      case 'encerrada':
        return 'Encerrada';
    }
  }

}
