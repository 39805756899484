import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EmailDTO } from '@model/pessoa/email-dto.model';
import { EmpresaDTO } from '@model/pessoa/empresa-dto.model';
import { EnderecoDTO } from '@model/pessoa/endereco-dto.model';
import { PessoaDTO } from '@model/pessoa/pessoa-dto.model';
import { TelefoneDTO } from '@model/pessoa/telefone-dto.model';
import { RepresentanteLegalComponent } from '@project/src/app/pages/interno/pessoa/pessoa-core-editar/representante-legal/representante-legal.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { EnderecoComponent } from '../../../pages/interno/pessoa/pessoa-core-editar/endereco/endereco.component';
import { TelefoneComponent } from '../../../pages/interno/pessoa/pessoa-core-editar/telefone/telefone.component';
import { ConfiguracaoDTO } from '@project/src/app/model/pessoa/configuracao-dto.model';
import { EmailNotificacaoDTO } from '@project/src/app/model/pessoa/email-notificacao-dto.model';

@Component({
  selector: 'app-stepper4',
  templateUrl: './stepper4.component.html',
  styleUrls: ['./stepper4.component.less']
})
export class Stepper4Component implements OnChanges, AfterViewInit, OnDestroy {
  processando = false;

  @Input()
  public form: FormGroup;
  @Input()
  public tipoConta: string;

  @Input()
  public dadosRecuperados: any;

  @Input()
  representantesData = [];

  @ViewChild(TelefoneComponent, { static: false })
  private telefone: TelefoneComponent;
  @ViewChild(EnderecoComponent, { static: false })
  private endereco: EnderecoComponent;

  @ViewChild(RepresentanteLegalComponent, { static: false })
  private representantes: RepresentanteLegalComponent;

  @Output()
  public atualiza: EventEmitter<any> = new EventEmitter();

  @Output()
  public atualizarForm: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private verifica: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if (this.form) {
      if (this.dadosRecuperados && this.dadosRecuperados['formStepper4'] && this.dadosRecuperados['formStepper4'] == null && this.dadosRecuperados['formStepper4'] == undefined) {
        this.form.patchValue(this.dadosRecuperados.formStepper4);
        if (this.dadosRecuperados.formStepper4['representantes']) {
          this.form.get('representantes').patchValue(this.dadosRecuperados.formStepper4.representantes);
        }
      }
    }
  }

  ngOnDestroy(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(800)).subscribe(val => {
      this.atualizarForm.emit({ atualizar: true });
    });
  }

  atualizaTudo(obj) {
    this.atualiza.emit(obj);

    if (!!obj.email) {
      this.form.get(['emails', 0]).patchValue({
        email: obj.email
      });
    }

    if (!!obj.telefone) {
      const split = obj.telefone.split('/');
      for (let i = 0; i < split.length; i++) {
        if (split.hasOwnProperty(i)) {
          const telefone = split[i];
          const value = telefone.replace(/[^0-9]/gim, '');
          const reg = new RegExp('([0-9]{2})([0-9]+)', 'gim').exec(value);
          if (reg && reg.length) {
            if (!this.form.get(['telefones', i])) {
              this.telefone.add();
            }
            this.form.get(['telefones', i]).patchValue({
              tipo: 'comercial',
              ddd: reg[1],
              numero: reg[0],
              pais: '+55'
            });
          }
        }
      }
    }

    this.form.get('geral').patchValue({
      documento: obj.cnpj,
      nome: !!obj.nomeFantasia ? obj.nomeFantasia : obj.razaoSocial,
      razaoSocial: obj.razaoSocial,
    });

    this.form.get(['enderecos', 0]).patchValue({
      cep: obj.endereco.cep,
      tipoLogradouro: '',
      logradouro: obj.endereco.logradouro,
      numero: obj.endereco.numero,
      complemento: obj.complemento,
      bairro: obj.endereco.bairro || '',
      cidade: obj.endereco.cidade,
      codigo_ibge: '',
      estado: obj.endereco.estado
    });
    let formRepresentantes = this.form.get('representantes') as FormArray;
    formRepresentantes.reset();
    this.representantesData = obj.representanteLegal || [];

    this.endereco.buscaEndereco(this.form.get(['enderecos', 0]));

    this.verifica.detectChanges();
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido'
          : '';
    }
  }

  public build(empresa: EmpresaDTO) {
    const pessoa = empresa.pessoa = {} as PessoaDTO;
    pessoa.documento = this.form.get('geral.documento').value;
    pessoa.nome = this.form.get('geral.nome').value;
    pessoa.razaoSocial = this.form.get('geral.razaoSocial').value;
    const enderecos = pessoa.enderecos = [] as EnderecoDTO[];

    for (const item of (this.form.get('enderecos').value || [])) {
      enderecos.push(item);
    }
    const emails = pessoa.emails = [] as EmailDTO[];
    for (const item of (this.form.get('emails').value || [])) {
      emails.push(item);
    }
    const telefones = pessoa.telefones = [] as TelefoneDTO[];
    for (const item of (this.form.get('telefones').value || [])) {
      telefones.push(item);
    }

    let emailNotificacao = empresa.configuracao.emailNotificacao as EmailNotificacaoDTO[];
    emailNotificacao = this.form.get('emailNotificacao.email').value;

    for (const item of (this.form.get('emailNotificacao.email').value || [])) {
      emailNotificacao.push(item);
    }

    return empresa;
  }

}
