import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-excluir-cobranca',
  templateUrl: './excluir-cobranca.component.html',
  styleUrls: ['./excluir-cobranca.component.less']
})
export class ExcluirCobrancaComponent implements OnInit, OnDestroy {
  processando = false;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ExcluirCobrancaComponent>,
    private cobrancaService: CobrancaEndpointService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      notificarCliente: new FormControl(true),
      dms: new FormControl(false),
      keyExclusao: new FormControl('')
    });

    if (this.dataDialog.tipoCobranca !== 'avulsa') {
      this.form.get('keyExclusao').setValidators([this.validacaoKeyExclusao(this.form)]);
      this.form.get('dms').setValidators([this.validacaoKeyExclusao(this.form)]);
    }
  }

  validacaoKeyExclusao(form: FormGroup) {
    return (control: AbstractControl) => {
      const keyExclusao = form.get('keyExclusao').value,
        numeroParcelas = this.dataDialog.numeroParcelas,
        excluirTodos = form.get('dms').value;

      if (keyExclusao.toLowerCase() !== 'excluir' && excluirTodos === true) {
        return { naoconfirmado: true } as ValidationErrors;
      }

      this.form.get('keyExclusao').setErrors(null);
      this.form.get('dms').setErrors(null);
    }
  }

  ngOnDestroy() { }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('naoconfirmado')
          ? 'Entrada inválida'
          : ''
    }
  }

  fecha(): void {
    this.dialogRef.close();
  }

  notificar() { }

  excluir() {
    const data = {
      cobrancaId: this.dataDialog.cobrancaId,
      notificarCliente: this.form.get('notificarCliente').value,
      dms: this.form.get('dms').value
    };
    document.getElementById('btn_remover').setAttribute('disabled', 'disabled');
    if (this.processando) {
      return;
    }
    this.processando = true;
    this.cobrancaService
      .excluirCobranca(data.cobrancaId, data.notificarCliente, data.dms)
      .toPromise()
      .then(
        response => {
          Swal.fire({
            title: 'Sucesso',
            text: response,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            if (this.dataDialog) {
              if (this.dataDialog.excluiuCobranca === false) {
                this.dialogRef.close({ excluiuCobranca: true });
              }
            } else {
              this.dialogRef.close();
            }
          });
        }).catch(
          error => {
            Swal.fire('Erro!', error.error, 'error');
          }
        ).then(() => this.processando = null);
  }
}
