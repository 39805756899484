import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringFormatService {

  constructor() { }

  adicionarPontosDocumento(documento = null) {
    if (documento) {
      const documentoInput = documento.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11 && documentoInput.length !== 0) {
        return (
          documento.substring(0, 3) +
          '.' +
          documento.substring(3, 6) +
          '.' +
          documento.substring(6, 9) +
          '-' +
          documento.substring(9, 11)
        );
      } else {
        return (
          documento.substring(0, 2) +
          '.' +
          documento.substring(2, 5) +
          '.' +
          documento.substring(5, 8) +
          '/' +
          documento.substring(8, 12) +
          '-' +
          documento.substring(12, 14)
        );
      }
    }
  }

}


