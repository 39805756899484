<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        GEOLOCALIZAÇÃO
        <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div class="mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px;display: flex;
            justify-content: center; margin-bottom: 15px;     border: 1px solid #cecece;
  flex-direction: column;
">
            <div>
                <div id="titulo-lista">Defina se deseja ativar o recurso de geolocalização</div>
            </div>
            <div class="container">
                <div class="sub-container">
                    <div id="config-list" style="display: flex; align-items: center; overflow: hidden; width: 100%;">
                        <div style=" display: flex;
                        align-items: center;">
                            <i class="fa-solid fa-location-dot" id="icones-titulo"></i>
                            <span class="msg-lista-titulo">Ativar geolocalização</span>
                        </div>
                        <mat-slide-toggle [checked]="geral" (change)="checkDisabled($event)"></mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px;display: flex;
        justify-content: center; margin-bottom: 15px;     border: 1px solid #cecece;
flex-direction: column;
">
            <div>
                <div id="titulo-lista">Defina em quais momentos o sistema irá exigir a geolocalização</div>
            </div>
            <div class="container" style="width: 100%; display: flex;">
                <div class="sub-container" style="flex: 1;">
                    <div class="sub-container" class="example-list">
                        <table mat-table [dataSource]="dataSourceLeft" matSort>

                            <ng-container matColumnDef="icone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                </th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <i class="{{ iconeCard(row?.tipo) }}" id="icones"></i>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="funcionalidade">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px !important;">
                                    Funcionalidade</th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <span id="texto-tabela"> {{ formataNome(row.tipo) }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="exigir">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 0px !important;">
                                    Exigir</th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <mat-slide-toggle [checked]="row.ativo" [disabled]="desabilitados"
                                        (change)="atualizarLocalizacao(row.tipo)"></mat-slide-toggle>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [length]="lista?.totalElements">
                        </mat-paginator> -->

                    </div>
                </div>
                <div class="sub-container" style="flex: 1;">
                    <div class="sub-container" class="example-list">
                        <table mat-table [dataSource]="dataSourceRight" matSort>

                            <ng-container matColumnDef="icone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                </th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <i class="{{ iconeCard(row?.tipo) }}" id="icones"></i>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="funcionalidade">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px !important;">
                                    Funcionalidade</th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <span id="texto-tabela"> {{ formataNome(row.tipo) }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="exigir">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 0px !important;">
                                    Exigir</th>
                                <td mat-cell *matCellDef="let row" style=" padding-right: 20px;">
                                    <mat-slide-toggle [checked]="row.ativo" [disabled]="desabilitados"
                                        (change)="atualizarLocalizacao(row.tipo)"></mat-slide-toggle>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [length]="lista?.totalElements">
                        </mat-paginator> -->

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
        <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>