import { Injectable, Inject } from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { compareAsc, addMinutes } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemporizadorService {
  private _podeReenviarEmail = new BehaviorSubject<boolean>(false);
  podeReenviarEmail = this._podeReenviarEmail.asObservable();
  private _podeReenviarSms = new BehaviorSubject<boolean>(false);
  podeReenviarSms = this._podeReenviarSms.asObservable();

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  setConfirmacaoEnviadoEm() {
    return new Date();
  }

  private _podeEnviarNovoCodigo(bloqueiaEnviarAte) {
    const result = compareAsc(new Date(), bloqueiaEnviarAte);

    if (result === 1 || result === 0) {
      return true;
    }

    return false;
  }

  setBloqueiaEnviarAte(bloqueiaEnviarAte, enviadoEm, tempoReenviar, tipo: string) {
    bloqueiaEnviarAte = addMinutes(enviadoEm, tempoReenviar);
    this.storage.set(tipo, bloqueiaEnviarAte.getTime());

    return bloqueiaEnviarAte;
  }

  setIntervaloNovoCodigo(bloqueiaEnviarAte, tipo: string) {
    const podeReenviarAux = setInterval(() => {
      const novoCodigo: boolean = this._podeEnviarNovoCodigo(bloqueiaEnviarAte);
      if (tipo === 'emailEnviadoEm') {
        this._podeReenviarEmail.next(novoCodigo);
      } else {
        this._podeReenviarSms.next(novoCodigo);
      }
      if (novoCodigo) {
        clearInterval(podeReenviarAux);
        this.storage.remove(tipo);
      }
    }, 1000);
  }
}
