import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentoDTO } from '../model/sistema/documento-dto.model';
import { VariavelDTO } from '../model/sistema/variavel-documento';

@Injectable({
  providedIn: 'root'
})
export class HomeEndpointService {
  constructor(private client: HttpClient) {}

 getDocumentosRequisitados(empresaId?: number, iaas?: boolean): Observable<DocumentoDTO[]> {
  if (!empresaId) {
    empresaId = 0;
  }
  if (!iaas) {
    iaas = false;
  }
  const url = `/home/documentos-requisitados/${empresaId}/${iaas}`;
  return this.client.get<DocumentoDTO[]>(url);
}

  getDashboard(): Observable<any> {
    const url = '/home/dashboard';
    return this.client.get(url);
  }

  getCardMeuDinheiro(): Observable<any> {
    const url = '/home/card-meu-dinheiro';
    return this.client.get(url);
  }

  getConfiguracoesSistema(): Observable<any> {
    const url = '/home/configuracoes-sistema';
    return this.client.get(url);
  }

  // todos os dados dos carrossel juntos
  getCardCarrossel(): Observable<any> {
    const url = '/home/card-carrossel';
    return this.client.get(url);
  }

  getCardClientes(): Observable<any> {
    const url = '/home/card-clientes';
    return this.client.get(url);
  }

  getAnaliseCadastral(): Observable<any> {
    const url = '/home/card-analise-cadastral';
    return this.client.get(url);
  }

  // 1 = hoje, 2 = últimos 7 dias, 3 = últimos 30 dias
  getCobrancasRecebidas(filtro = 1): Observable<any> {
    const url = '/home/card-cobrancas-recebidas?filtro=' + filtro;
    return this.client.get(url);
  }

  // 1 = hoje, 2 = últimos 7 dias, 3 = últimos 30 dias
  getPagamentoContas(filtro = 1): Observable<any> {
    const url = '/home/card-pagamento-contas?filtro=' + filtro;
    return this.client.get(url);
  }

  // 1 = hoje, 2 = últimos 7 dias, 3 = últimos 30 dias
  getTransferencias(filtro = 1): Observable<any> {
    const url = '/home/card-transferencias?filtro=' + filtro;
    return this.client.get(url);
  }

  // 1 = janeiro, 2 = fevereiro (...) 12
  getResumo(mes): Observable<any> {
    const url = '/home/card-resumo?mes=' + mes;
    return this.client.get(url);
  }

  getCardUltimasMovimentacoes(): Observable<any> {
    const url = '/home/card-ultimas-movimentacoes';
    return this.client.get(url);
  }

  // 1 (este mês), 2 (este ano), 3 (desde o inicio)
  filtrarGrafico(filtro): Observable<any> {
    const url = '/home/dados-grafico?filtro=' + filtro;
    return this.client.get(url);
  }

  desativarVariavel(dto: VariavelDTO) {
    const url = '/home/desativar-variavel';
    return this.client.post(url, dto);
  }

  atualizarVariavel(id, dto: any) {
    const url = '/home/atualizar-variavel/' + id;
    return this.client.put(url, dto);
  }

  getArquivo(tipo: string, empresaId: number, nomeArquivo: string): Observable<any> {
    let params = new HttpParams();
    if (tipo != null) {
      params = params.append('tipo', `${tipo}`);
    }
    if (empresaId != null) {
      params = params.append('empresaId', `${empresaId}`);
    }
    if (nomeArquivo != null) {
      params = params.append('nomeArquivo', `${nomeArquivo}`);
    }
    const url = '/home/get-arquivo';
    return this.client.get<any>(url, {params});
  }

  getSignatarioArquivo(tipo: string, id: number): Observable<any> {
    const url = '/home/get-signatario-arquivo/' + `${id}`;
    let params = new HttpParams();
    if (tipo) {
      params = params.append('tipo', `${tipo}`);
    }

    return this.client.get<any>(url, {params});
  }

  vincularSignatarioArquivo(arquivoId: number, documento) {
    const url = '/home/vincular-signatario-arquivo/' + `${arquivoId}`;

    return this.client.patch(url, documento);
  }

  reenvioEmail(arquivoId, signerId): Observable<any> {
    const url = '/home/reenvio-email';
    let params = new HttpParams();
    if (arquivoId) {
      params = params.append('arquivoId', `${arquivoId}`);
    }
    if (signerId) {
      params = params.append('signerId', `${signerId}`);
    }
    return this.client.get<any>(url, {params});
  }
}
