import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioDTO } from '@model/usuario/usuario-dto.model';
import { Environment } from '@project/src/environments/environment.default';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { TemporizadorService } from '@service/temporizador.service';
import { UsuarioEndpointService } from '@service/usuario-endpoint.service';
import { environment } from '@src/environments/environment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Swal from 'sweetalert2';
import { EditarContatoComponent } from './editar-contato/editar-contato.component';
import { PerguntasSeguranca } from '@project/src/app/model/enum.model';

@Component({
  selector: 'app-stepper1',
  templateUrl: './stepper1.component.html',
  styleUrls: ['./stepper1.component.less']
})
export class Stepper1Component implements OnInit, OnDestroy {
  public podeReenviarEmail: boolean = true;
  public podeReenviarSms: boolean = true;
  public env: Environment = environment;
  public bloqueiaEnviarEmailAte: Date;
  public bloqueiaEnviarSmsAte: Date;
  public usuarioLogado: UsuarioDTO;
  public tempoReenvio: number = 2 // minutos

  private emailEnviadoEm: Date;
  private smsEnviadoEm: Date;

  @Input()
  public form: FormGroup;

  @Output()
  public changeUserInfo: EventEmitter<any> = new EventEmitter();

  @Output()
  public fecha: EventEmitter<any> = new EventEmitter();

  constructor(
    private usuario: UsuarioEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private temporizadorService: TemporizadorService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
    this.addPergunta();
  }

  public novoCodigo(tipo: string) {
    let enviadoPara: string = (tipo !== 'telefone') ? this.usuarioLogado.email : "(" + this.usuarioLogado.celular.ddd + ") " + this.usuarioLogado.celular.numero
    Swal.fire({
      html: `
      <style>
      .swal-cancel-button{
        background-color: white !important;
        color: #ffa300 !important;
        font-weight: bold !important;
        border: 1px solid #ffa300 !important;
      }
      
      .swal-button{
          background-color: #ffa300 !important;
      }
      </style>
      <p>
      <span style="color: #ffa300; font-weigth: bold; font-size: 22px">O seu código foi enviado para</span><br>
      <span style="font-size: 16px">${enviadoPara}</span>
      </p>
      <p style="font-size: 16px">Deseja tentar novamente ou mudar o contato?</p>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ALTERAR',
      cancelButtonText: 'REENVIAR',
      reverseButtons: true,
      customClass: {
        cancelButton: 'swal-cancel-button',
        confirmButton: 'swal-button',
        icon: 'swal-icon',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if (tipo === 'telefone') {
          this.alterarInfoContato(tipo);
        } else {
          Swal.fire({
            title: "Atenção!",
            text: "Alterar o email necessitará que refaça o login. Prosseguir?",
            icon: "warning",
            confirmButtonText: "OK"
          }).then((response) => {
            if (response.isConfirmed) {
              this.alterarInfoContato(tipo);
            }
          })
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (tipo === 'telefone') {
          this.novoCodigoSms();
        } else {
          this.novoCodigoEmail();
        }
      }
    });
  }

  private alterarInfoContato(tipo: string) {
    this.dialog.open(EditarContatoComponent, {
      data: { tipo }
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (response) {
            if (tipo === 'telefone') {
              this.novoCodigoSms(response.usuario);
            } else {
              this.fecha.emit();
            }
            this.usuarioLogado = response.usuario;
            this.changeUserInfo.emit(response.usuario);
          }
        },
        (error) => { Swal.fire("error!", error.error, "error") }
      );
  }

  private novoCodigoSms(user?: UsuarioDTO) {
    const usuarioSessao = user || this.autenticacao.sessao.principal.usuario;

    const celularData = {
      celular: {
        tipo: usuarioSessao.celular.tipo,
        ddd: usuarioSessao.celular.ddd,
        numero: usuarioSessao.celular.numero,
        ramal: usuarioSessao.celular.ramal,
        pais: usuarioSessao.celular.pais,
      },
      email: usuarioSessao.email
    }


    this.usuario.reenviarCodigoCelular(celularData).pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.smsEnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
        this.bloqueiaEnviarSmsAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarSmsAte, this.smsEnviadoEm, this.tempoReenvio, 'smsEnviadoEm');
        this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarSmsAte, 'smsEnviadoEm');

        this.temporizadorService.podeReenviarSms.pipe(untilDestroyed(this))
          .subscribe((response) => {
            this.podeReenviarSms = response;
          }, error => {
            Swal.fire({
              title: "Atenção!",
              text: error.error,
              icon: "warning",
            })
            console.error(error);
          }
          );
      }, error => {
        Swal.fire({
          title: "Atenção!",
          text: error.error,
          icon: "warning",
        })
        console.error(error);
      });
  }

  private novoCodigoEmail(user?: UsuarioDTO) {
    const usuarioSessao = user || this.autenticacao.sessao.principal.usuario;

    const emailData = {
      nome: usuarioSessao.nome,
      email: usuarioSessao.email
    }

    this.usuario.reenviarCodigoEmail(emailData).pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.emailEnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
        this.bloqueiaEnviarEmailAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarEmailAte, this.emailEnviadoEm, this.tempoReenvio, 'emailEnviadoEm');
        this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarEmailAte, 'emailEnviadoEm');

        this.temporizadorService.podeReenviarEmail.pipe(untilDestroyed(this))
          .subscribe((response) => {
            this.podeReenviarEmail = response;
          }, error => {
            console.error(error);
          }
          );
      }, error => {
        console.error(error);
      });
  }

  get perguntasSeguranca(){
    return Object.values(PerguntasSeguranca);
  }

  get formPerguntasSeguranca(){
    return this.form.get('perguntasSeguranca') as FormArray;
  }

  addPergunta(){
    let form = this.form.get('perguntasSeguranca') as FormArray;
    form.push(
      new FormGroup({
        pergunta: new FormControl("", [Validators.required]),
        resposta: new FormControl("", [Validators.required]),
      })
    );
  }

  removePergunta(index: number){
    let formDesconto = this.form.get('perguntasSeguranca') as FormArray;
    formDesconto.removeAt(index);
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('maxlength')
          ? 'Código inválido'
          : form.get(field).hasError('minlength')
            ? 'Código inválido'
            : form.get(field).hasError('incorreto')
              ? 'Código incorreto'
              : '';
    }
  }

  ngOnDestroy(): void { }
}
