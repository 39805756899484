<div class="row ml-3">
  <div class="col-12">
    <div class="row alinha">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">DOCUMENTAÇÃO DE INTEGRAÇÃO
        </h4>
      </div>
      <div class="col-10 col-md-10 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Url de documentação</mat-label>
          <input matInput type="text" [(ngModel)]="env.url.readme" disabled />
        </mat-form-field>
      </div>
      <div class="col-2 col-md-2 input-size" style="align-self:center">
        <span class="bordinha mr-3" style="background-color: #4FA9A9; cursor: pointer;"><i matTooltip="Copiar"
            style="font-size: large;" (click)="copyToClipboard(env.url.readme)" class="fas fa-copy"></i></span>
            <span class="bordinha" style="background-color: #4FA9A9; cursor: pointer;"><i matTooltip="Ir"
              style="font-size: large;" (click)="abrirAPI()" class="fas fa-external-link-square-alt"></i></span>
      </div>
    </div>
  </div>
</div>
<div class="row ml-3">
  <div class="col-12">
    <div class="row alinha">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">TOKEN DE INTEGRAÇÃO
        </h4>
      </div>
      <div class="col-10 col-md-10 input-size">
        <mat-form-field appearance="outline">
          <mat-label>Token API</mat-label>
          <input matInput type="password" [(ngModel)]="token" disabled />
        </mat-form-field>
      </div>
      <div class="col-2 col-md-2 input-size" style="align-self:center">
        <span class="bordinha" style="background-color: #4FA9A9; cursor: pointer;"><i matTooltip="Receber token via Email"
            style="font-size: large;" (click)="pedirToken()" class="fas fa-envelope"></i></span>
      </div>
    </div>
  </div>
</div>

<div class="row ml-3">
  <div class="col-12">
    <div class="row alinha">
      <div class="col-12" style="margin-bottom: 10px">
        <h4 class="corPrimaria" style="font-weight: bolder; margin-top: 12px">ID EMPRESA
        </h4>
      </div>
      <div class="col-10 col-md-10 input-size">
        <mat-form-field appearance="outline">
          <mat-label>ID empresa</mat-label>
          <input matInput type="text" [(ngModel)]="data.id" disabled />
        </mat-form-field>
      </div>
      <div class="col-2 col-md-2 input-size" style="align-self:center">
        <span class="bordinha mr-3" style="background-color: #4FA9A9; cursor: pointer;"><i matTooltip="Copiar"
            style="font-size: large;" (click)="copyToClipboard(data.id)" class="fas fa-copy"></i></span>
      </div>
    </div>
  </div>
</div>