<div class="container card-plano">

    <div class="col-12 col-md-12">
        <div id="titulo">
            <span style="display: flex;
            align-items: center;"> <i class="fa-brands fa-pix" style="font-size: 40px;"></i>
                <h3 style="margin-left: 10px;"><strong>Portabilidade de chave</strong></h3>
            </span>
            <p style="margin-left: 52px;">Traga para o {{ env.nomeApp }} uma chave cadastrada em outra conta. É só pedir
                portabilidade e aprovar o pedido na sua outra conta.</p>
        </div>
    </div>

    <div class="col-12">
        <mat-progress-bar [hidden]="!processando" mode="indeterminate"></mat-progress-bar>
    </div>


    <div id="chaves-ativas">
        <span class="title-chaves">
            Portabilidade(s) em andamento:
        </span>
        <div id="ativas-container">
            <div class="ativas" *ngIf="listaReivindicacoes?.length > 0 && !processando">
                <p>
                    <strong>Chaves solicitadas:</strong>
                </p>
                <div class="col-12">
                    <mat-progress-bar [hidden]="!processando" mode="indeterminate"></mat-progress-bar>
                </div>
                <span *ngFor="let item of listaReivindicacoes">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ transformTipoPix(item.tpChave) }}</mat-label>
                        <input matInput type="text" readonly [value]="item.chave" />
                        <button (click)="consultarRevindicacaoChave(item.chave, item)" class="button-consulta"><i
                                class="fa-solid fa-list-check" [disabled]="desabilitaBotao"></i>
                        </button>
                    </mat-form-field>
                </span>
            </div>
            <div class="ativas" *ngIf="listaChavesReivindicadas?.length > 0 && !processando" style="margin-left: 25px;">
                <p>
                    <strong>Chaves reivindicadas:</strong>
                </p>
                <span *ngFor="let item of listaChavesReivindicadas">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ transformTipoPix(item.tpChave) }}</mat-label>
                        <input matInput type="text" readonly [value]="item.chave" />
                        <button (click)="consultarReivindicacaoSolicitada(item)" class="button-consulta"><i
                                class="fa-solid fa-list-check" [disabled]="desabilitaBotao"></i>
                        </button>
                    </mat-form-field>
                </span>
            </div>
            <div class="ativas" *ngIf="listaReivindicacoes?.length === 0 && listaChavesReivindicadas?.length === 0">
                <span style="display: flex;" id="titulo">
                    <p>Não identificamos nenhuma solicitação de portabilidade.</p>
                </span>
            </div>
        </div>
    </div>

    <hr style="width: 650px; color: #D7D7D7;">


    <form [formGroup]="form">
        <div id="cadastrar-chaves">
            <span class="title-chaves">Revindicar chave(s):</span>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>Celular</mat-label>
                    <input matInput type="text" mask="(00) 0 0000-0000" class="form-disabled"
                        formControlName="celular" />
                    <mat-error>{{ getErrorMessage("celular") }}</mat-error>

                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="revindicarChave(celular)" [disabled]="form.get('celular').value?.length < 11">
                    REVINDICAR CHAVE
                </button>
            </div>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>CPF/CNPJ</mat-label>
                    <input matInput type="text" mask="CPF_CNPJ" formControlName="cpfCnpj" />
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="revindicarChave(cpfCnpj)" [disabled]="form.get('cpfCnpj').value?.length < 11">
                    REVINDICAR CHAVE
                </button>
            </div>

            <div class="chaves">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" class="form-disabled" formControlName="email" />
                    <mat-error>{{ getErrorMessage("email") }}</mat-error>
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="revindicarChave(email)"
                    [disabled]="!form.get('email').value || !form.get('email').value?.includes('@')">
                    REVINDICAR CHAVE
                </button>
            </div>
        </div>
    </form>

</div>