<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    SUPORTE E CONTATO
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <form [formGroup]="form">
    <div mat-dialog-content class="mat-dialog-content-responsive">
      <div class="mudaCor input-size row"
        style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px">
        <!-- CARROSEL -->
        <div class="card-body">
          <div class="first-container">
            <h4 class="color-title">Adicionar</h4>
            <div>
              <ngb-carousel #carousel [showNavigationArrows]="true" [showNavigationIndicators]="false" [interval]="0"
                [pauseOnHover]="true" (slide)="slideChanged($event)" class="dash-slider">
                <ng-template ngbSlide *ngFor="let icon of icons">
                  <div id="icon-select">
                    <mat-icon (click)="slideClicked()" *ngIf="!editando">{{ icon }}</mat-icon>
                    <mat-icon (click)="slideClicked()" *ngIf="editando">{{ iconEdit }}</mat-icon>
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
            <!-- FORMS -->
            <div id="input-container" class="row row-inputs">
              <div class="col-12 col-md-12">
                <mat-form-field appearance="outline">
                  <mat-label>Descrição</mat-label>
                  <input matInput type="text" placeholder="descricao" formControlName="descricao" required
                    maxlength="30">
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12">
                <mat-form-field appearance="outline">
                  <mat-label>Link</mat-label>
                  <input matInput type="text" placeholder="Link" formControlName="link" required>
                </mat-form-field>
              </div>
            </div>
            <div mat-dialog-actions style="overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;     width: 100%;
            justify-content: center; width: 100%;">
              <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
          margin: 0;">
                <button class="btn btn-default next" id="color-btn" (click)=" salvar()"
                  [disabled]="!isFormValid">Adicionar</button>
              </span>
            </div>
          </div>

          <div class="second-container">
            <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
              <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
            <h4 class="color-title">Prévia</h4>
            <mat-list id="list-container" cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
              <mat-list-item *ngFor="let item of listContato" class="example-box" cdkDrag>
                <i class="fas fa-grip-vertical" style="color: #818d91;"></i>
                <a [href]="item?.link" target="_blank" style="width: 22vh;">
                  <div id="config-list" style="display: flex; align-items: center; margin-left: 15px; width: 23.5vw;">
                    <div>
                      <mat-icon>
                        {{ item?.icone }}
                      </mat-icon>
                      <span class="msg-lista">
                        {{ item?.descricao }}
                      </span>
                    </div>
                    <div>
                      <button mat-icon-button [matMenuTriggerFor]="menu" id="edit-button">
                        <i class="fa-solid fa-ellipsis" id="tres-pontos"></i>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editarContato(item.descricao, item.icone, item.link)">
                          <i class="fas fa-edit"></i>
                          Editar
                        </button>
                        <button mat-menu-item (click)="desativar(item.descricao)">
                          <i class="fas fa-trash" style="color: rgb(223, 67, 19)"></i>
                          Excluir
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </a>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>