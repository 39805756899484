<h2 mat-dialog-title class="titulo-modal pl-4">

    Transferências em Lote para Destino Único

    <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
    <div class="card">
        <div class="card-header card-header-primary card-header-icon" style=" padding-left: 25px; ">
            <div class="row">
                <div class="col-12 col-md-6" style="padding-left: 0">
                    <div class="card-icon">
                        <i class="fas fa-cubes fa-2x"></i>
                    </div>
                    <h4 class="card-title" style="font-weight: 600">Lista de Importações</h4>
                </div>
            </div>
        </div>
        <mat-toolbar class="espacamento pl-0" style="margin-top: 5%">
            <div class="row row-toolbar">
                <div *ngIf="!mostrarBusca" class="col-7 ml-3 input-size"
                    style="margin-top: 0px; text-align: center; margin-left: 4.5% !important;">
                    <a class="teste" style="margin-right: 5%" (click)="onChangeYear(anoSelecionado - 1)">
                        <i class="fas fa-angle-left"></i>
                        <i class="fas fa-angle-left"></i>
                    </a>
                    <span class="p-anoSelecionado">{{ anoSelecionado }}</span>
                    <a class="teste" style="margin-left: 5%" (click)="onChangeYear(anoSelecionado + 1)">
                        <i class="fas fa-angle-right"></i>
                        <i class="fas fa-angle-right"></i>

                    </a>
                </div>
                <div class="col-12 col-md-8 input-size" style="margin-top: 0px; padding: 0">
                    <div class="col-12" style="padding-left: 35px;">
                        <div *ngIf="!mostrarBusca">

                            <mat-button-toggle-group class="toggle-group" appearance="outline">
                                <!-- (change)="onToggleGroupChange($event)" -->
                                <mat-button-toggle [checked]="numeroMes === 0" value="01">Jan</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 1" value="02">Fev</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 2" value="03">Mar</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 3" value="04">Abr</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 4" value="05">Mai</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 5" value="06">Jun</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 6" value="07">Jul</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 7" value="08">Ago</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 8" value="09">Set</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 9" value="10">Out</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 10" value="11">Nov</mat-button-toggle>
                                <mat-button-toggle [checked]="numeroMes === 11" value="12">Dez</mat-button-toggle>
                            </mat-button-toggle-group>

                        </div>
                        <div *ngIf="mostrarBusca">
                            <mat-form-field appearance="outline" class="form-buscar" color="orange">
                                <mat-label>Procurar</mat-label>
                                <input matInput [(ngModel)]="key" (keyup)="applySearch($event.target.value)"
                                    [disabled]="true" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 pl-6" style="padding-bottom: 1.38375em !important">
                    <div class="col-12 d-flex justify-content-between" style="padding-left: 0;">
                        <button class="btn mat-primary btn-filtro" (click)="editar()">
                            <i class="fas fa-plus"></i>
                            <p>Adicionar</p>
                        </button>
                        <button disabled=true (click)="abreBusca()" class="btn mat-primary btn-filtro">
                            <div *ngIf="!mostrarBusca">
                                <i class="fas fa-search"></i>
                                <p>Buscar</p>
                            </div>
                            <div *ngIf="mostrarBusca">
                                <i class="far fa-calendar-alt"></i>
                                <p>Selecionar</p>
                            </div>
                        </button>
                        <button disabled=true (click)="abreFiltro()" class="btn mat-primary btn-filtro">
                            <i class="fas fa-filter"></i>
                            <p>Filtros</p>
                        </button>
                    </div>
                    <div class="col-12" style="padding: 0;">
                        <button [disabled]="true" class="btn mat-primary btn-filtro btn-full"
                            [matMenuTriggerFor]="opcoes">
                            <i class="fas fa-cog"></i>
                            <p>Ações</p>
                        </button>
                        <mat-menu #opcoes xPosition="before">
                            <button mat-menu-item>
                                <i class="fas fa-download"></i>
                                Exportar xlsx
                            </button>
                            <button mat-menu-item>
                                <i class="fas fa-download"></i>
                                Exportar pdf
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </mat-toolbar>
        <div class="card-body" style="padding-top: 1.8rem !important">
            <div class="row">
                <div class="col-12 tabela-area">
                    <div [hidden]="!processando" class="loading-bar">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div [hidden]="processando" class="borda">
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="dataCriacao"
                            matSortDirection="desc">

                            <!-- Opções -->
                            <ng-container matColumnDef="button">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 20px !important;">Opções</th>
                                <td mat-cell *matCellDef="let row" class="w-1">
                                    <button mat-button class="mat-button" [matMenuTriggerFor]="menu">
                                        <span class="mat-button-wrapper">
                                            <i class="fas fa-tasks"></i>
                                        </span>
                                    </button>

                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item
                                            *ngIf="row.mensagemStatus === 'Lote de transferência programado'"
                                            (click)="cancelar(row.id)">
                                            <i class="fas fa-trash"></i>
                                            Cancelar lote programado
                                        </button>
                                        <button mat-menu-item
                                            *ngIf="row.mensagemStatus === 'Lote de transferência processado com sucesso'"
                                            (click)="downloadComprovantes(row.id)">
                                            <i class="fas fa-eye"></i>
                                            Download comprovantes
                                        </button>
                                        <button mat-menu-item [disabled]="row.status !== false" (click)="editar(row)">
                                            <i class="fas fa-eye"></i>
                                            Download feedback com falhas
                                        </button>
                                        <button mat-menu-item (click)="abrirModalItens(row.id)"
                                            *ngIf="row.mensagemStatus === 'Lote de transferência processado com sucesso'">
                                            <i class="fas fa-eye"></i>
                                            Visualizar transferências
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <!-- Data de solicitação -->
                            <ng-container matColumnDef="dataCriacao">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de solicitação</th>
                                <td mat-cell *matCellDef="let row"> {{ row.dataCriacao | date:'dd/MM/yyyy HH:mm' }}
                                </td>
                            </ng-container>

                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let row">
                                    <ng-container *ngIf="objectUtilsService.isNull(row.status)">
                                        <fa-icon class="text-warning" [icon]="['fas', 'rotate']"
                                            [spin]="true"></fa-icon>
                                    </ng-container>
                                    <ng-container *ngIf="row.status === true">
                                        <fa-icon class="text-success" [icon]="['fas', 'check']"></fa-icon>
                                    </ng-container>
                                    <ng-container *ngIf="row.status === false">
                                        <fa-icon class="text-danger" [icon]="['fas', 'xmark']"></fa-icon>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <!-- Mensagem de status -->
                            <ng-container matColumnDef="mensagemStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mensagem de Status</th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.mensagemStatus }}</td>
                            </ng-container>

                            <!-- Valor -->
                            <ng-container matColumnDef="valor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.valor | currency:'':'R$':'':'PT-BR'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>

                        <div *ngIf="!processando && lista?.totalElements == 0" class="sem-resultado">
                            <fa-icon [icon]="['fas', 'scroll']" [size]="'2x'"></fa-icon>
                            <p>Ainda não possui transferências cadastrados</p>
                        </div>

                        <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]" [length]="lista?.totalElements">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div *ngIf="urlAjuda">
        <b>Ficou com Dúvidas?</b> Acesse nossa <a href={{urlAjuda}} target="_blank" class="link">Central de Ajuda</a> e
        conheça o
        processo de importação de lote
    </div>
</mat-dialog-actions>