import { environment } from './../../../../../../environments/environment.j17';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QrCodePixDTO } from '@project/src/app/model/qrCode-pix-dto.model';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comprovante-qr-code',
  templateUrl: './comprovante-qr-code.component.html',
  styleUrls: ['./comprovante-qr-code.component.less']
})
export class ComprovanteQrCodeComponent implements OnInit {

  qrcode: string;
  dadosQr: any;
  nome: any;
  loading: boolean = true;
  idDocumento: string;
  documento: string;
  env = environment


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private autenticacaoService: AutenticacaoEndpointService,
    private route: ActivatedRoute,
    private pixService: PixEndpointService,
    public toastr: ToastrService,
    public router: Router
  ) { }

  ngOnInit(): void {

    this.idDocumento = this.route.snapshot.params['idDocumento'];

    this.consulsultarQrCode();
    // setTimeout(() => {
    //   this.exportarComoPDF();
    // }, 2000);
  }

  buildQrCode(): QrCodePixDTO {
    const obj = {
      idDocumento: this.idDocumento,
    }
    return obj;
  }

  consulsultarQrCode() {
    this.loading = true;
    this.pixService.consultarQrCodeEstatico(this.buildQrCode()).subscribe((response) => {
      this.dadosQr = response;
      this.loading = false;
    });
  }

  copiarQrCode() {
    const link = this.dadosQr.qrCodePayload;

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Qr Code copiado!", '', {
      positionClass: "toast-top-center",
      timeOut: 2000
    })
  }

  copiarChave() {
    const link = this.dadosQr.chave;

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Chave copiada!", '', {
      positionClass: "toast-top-center",
      timeOut: 2000
    })
  }

  saibaMais() {
    this.router.navigate([]);
  }
}
