import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LocalizacaoEndPointService } from '../../service/localizacao-endpoint.service';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-configuracao-localizacao',
  templateUrl: './configuracao-localizacao.component.html',
  styleUrls: ['./configuracao-localizacao.component.less']
})
export class ConfiguracaoLocalizacaoComponent implements OnInit {

  processando = false;
  itensLocalizacao: any = [];
  geral: boolean;
  desabilitados: boolean = true;

  displayedColumns: string[] = [
    'icone',
    'funcionalidade',
    'exigir',
  ];

  public dataSourceLeft = new MatTableDataSource<any>();
  public dataSourceRight = new MatTableDataSource<any>();


  constructor(
    public localizacaoService: LocalizacaoEndPointService,
    public dialogRef: MatDialogRef<ConfiguracaoLocalizacaoComponent>
  ) { }

  ngOnInit() {
    this.listarConfigLocalizacao();
  }

  listarConfigLocalizacao() {
    this.localizacaoService.listarConfiguracoes(0, 20)
      .subscribe((res) => {
        this.itensLocalizacao = res.content;
        // Divide o array em duas metades
        const meio = Math.ceil(this.itensLocalizacao.length / 2);
        const primeiraMetade = this.itensLocalizacao.slice(0, meio);
        const segundaMetade = this.itensLocalizacao.slice(meio);

        // Atribui cada metade a uma fonte de dados da tabela
        this.dataSourceLeft = new MatTableDataSource(primeiraMetade);
        this.dataSourceRight = new MatTableDataSource(segundaMetade);

        this.checkAtivo();
      }, error => {
        Swal.fire({
          title: 'Erro!',
          text: error.error,
          icon: 'error',
        });
      });
  }

  atualizarLocalizacao(tipo) {
    this.localizacaoService.atualizarConfiguracao(tipo).subscribe(
      (res) => {
        this.listarConfigLocalizacao();
      },
      (error) => {
        Swal.fire("Atenção!", error.error, "warning");
        this.listarConfigLocalizacao();
      }
    );
  };

  formataNome(tipo) {
    switch (tipo) {
      case "geral" :
        return "Geral";
      case "cobranca":
        return "Cobrança";
      case "emprestimo":
        return "Empréstimo";
      case "alterarSenha":
        return "Alterar senha";
      case "editarUsuario":
        return "Editar usuário";
      case "investimento":
        return "Investimento";
      case "transferencia":
        return "Transferência";
      case "conta":
        return "Configurações da conta";
      case "login":
        return "Login";
    }
  }

  iconeCard(tipo) {
    switch (tipo) {
      case "geral":
        return "fa-solid fa-gear";
      case "cobranca":
        return "fas fa-copy fa-2x";
      case "conta":
        return "fas fa-building fa";
      case "emprestimo":
        return "fa-solid fa-hand-holding-dollar";
      case "alterarSenha":
        return "fa-solid fa-lock-open";
      case "editarUsuario":
        return "fas fa-user-edit";
      case "investimento":
        return "fa-solid fa-chart-line";
      case "transferencia":
        return "fas fa-exchange-alt fa-2x";
      case "conta":
        return "fas fa-bullhorn fa-2x";
      case "login":
        return "fas fa-users fa-2x"
    }
  }

  fecha() {
    this.dialogRef.close();
  }

  checkAtivo() {
    const checkGeral = this.itensLocalizacao.some((item) => item.ativo);
    if (checkGeral) {
      this.geral = true;
      this.desabilitados = false;
    }
  }

  checkDisabled(event) {
    this.geral = !this.geral;

    console.log(event);

    if (event.checked) {
      this.localizacaoService.atualizarConfiguracao("login")
        .subscribe((res) => {
          this.desabilitados = false;
          this.listarConfigLocalizacao();
        }, error => {
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          })
          console.log(error);
        })
    }

    if (!event.checked) {
      this.localizacaoService.desativarTodasConfiguracoes()
        .subscribe((res) => {
          this.desabilitados = true;
          this.listarConfigLocalizacao();
        }, error => {
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          })
          console.log(error);
        })
    }
  }
}
