<div class="row" [formGroup]="form">
    <div class="col-10 ml-5">
        <div class="row">
            <div class="col-12">
                <h4 class="corPrimaria" style=" font-weight: bolder; margin: 12px">PESSOA {{transTextA.toUpperCase()}}
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo transferência</mat-label>
                    <mat-select (ngModelChange)="mudaTipo($event)" formControlName="tipoTransferencia">
                        <mat-option value="entrada">Entrada</mat-option>
                        <mat-option value="saida">Saída</mat-option>
                    </mat-select>
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Agência {{ transTextA }}</mat-label>
                    <input type="text" formControlName="agencia" maxlength="5" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo conta {{ transTextA }}</mat-label>
                    <mat-select formControlName="tpCtA">
                        <mat-option value="CC">Conta Corrente</mat-option>
                        <!-- <mat-option value="CP">Conta Poupança</mat-option> -->
                    </mat-select>
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Conta {{ transTextA }}</mat-label>
                    <input type="text" formControlName="ctA" maxlength="12" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>CNPJ/CPF Pessoa {{ transTextA }}</mat-label>
                    <input type="text" formControlName="cNPJCPFCliA" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nome pessoa {{ transTextA }}</mat-label>
                    <input type="text" formControlName="nomCliA" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Número controle STR</mat-label>
                    <input type="text" formControlName="numCtrlSTR" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>ISPB IF {{ transTextA }}</mat-label>
                    <input type="text" formControlName="iSPBIF" matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-6 col-md-6" *ngIf="!env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Data bacen</mat-label>
                    <input matInput readonly="true" [matDatepicker]="picker1" [min]="todayDate" [max]="todayDate"
                        id="dtBC" (click)="picker1.open()" formControlName="dtBC">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6" *ngIf="env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Data bacen</mat-label>
                    <input matInput readonly="true" [matDatepicker]="picker1" [max]="yesterdayDate" id=dtBC
                        (click)="picker1.open()" formControlName="dtBC">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline" *ngIf="!env?.modulos?.transferencia?.sensibilizacaoPassado">
                    <mat-label>Hora bacen</mat-label>
                    <input matInput type="text" id="hrBC" (ngModelChange)="validarHora($event, 'hrBC')" [maxLength]="5"
                        formControlName="hrBC">
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6" *ngIf="env?.modulos?.transferencia?.sensibilizacaoPassado">
                <mat-form-field appearance="outline">
                    <mat-label>Hora bacen</mat-label>
                    <input matInput type="text" id="hrBC" (ngModelChange)="mascaraHora($event, 'hrBC')" [maxLength]="5"
                        max formControlName="hrBC">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h4 class="corPrimaria" style=" font-weight: bolder; margin: 12px">PESSOA {{ transTextB.toUpperCase() }}
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Conta {{ transTextB }}</mat-label>
                    <input type="text" formControlName="ctB" (ngModelChange)="validaConta.emit($event)" maxlength="12"
                        matInput />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Valor lançamento</mat-label>
                    <input type="text" formControlName="vlrLanc" matInput mask="separator.2" thousandSeparator="."
                        prefix="R$ " placeholder="0,00" />
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Finalidade da Transferência</mat-label>
                    <mat-select formControlName="finlddCli">
                        <mat-option value="10">10</mat-option>
                    </mat-select>
                    <!-- <mat-error>{{ getErrorMessage(email, "email") }}</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-6 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>CNPJ/CPF Pessoa {{ transTextB }}</mat-label>
                    <input type="text" formControlName="cNPJCPFCliB" matInput />
                    <mat-error>{{ getErrorMessage('cNPJCPFCliB') }}</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <p>Para finalizar a solicitação desta sensibilização, é necessário autoriza-la por meio do seu token
                    SMS.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <p>O código de autorização será enviado para o número {{ numeroTelefone }}</p>
            </div>
        </div>

        <div class="row" style="justify-content: center; padding-top: 5px;">
            <div class="col-4 col-md-4">
                <button [disabled]="!podeReenviarSms" type="button" (click)="novoCodigoSms()"
                    class="btn-novocliente btn btn-block" mat-raised-button>
                    <fa-icon [icon]="['fas', 'circle-plus']"></fa-icon> ENVIAR TOKEN SMS
                </button>
                <p *ngIf="podeReenviarSms" class="obs">Não recebeu o SMS? <a (click)="novoCodigoSms()"
                        style="cursor: pointer;">Clique aqui</a></p>
                <p *ngIf="!podeReenviarSms" class="obs">Você poderá solicitar um novo código em {{ tempoReenvio }}
                    minutos
                    <br />
                    {{ bloqueiaEnviarSmsAte | date: "dd/MM/yyyy HH:mm" }}
                </p>
                <p [hidden]="podeReenviarSms" class="obs" style="color: #3d8686 !important">SMS enviado!</p>
            </div>
            <div class="col-5 col-md-5">
                <mat-form-field appearance="outline">
                    <mat-label>Token</mat-label>
                    <input matInput type="text" formControlName="token" />
                    <!-- <mat-error>{{ getErrorMessage(form, "token") }}</mat-error> -->
                </mat-form-field>
            </div>
        </div>
    </div>
</div>