import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmaTokenComponent } from './modal-confirma-token.component';
import { MaterialModule } from '@project/src/app/material.module';
import { IconsModule } from '@project/src/app/icons.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenModule } from '../../token/token.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    IconsModule,
    NgxMaskModule.forRoot(),
    NgxMaskModule.forChild(),
    NgxCurrencyModule,
    FormsModule,
    ReactiveFormsModule,
    TokenModule
  ],
  declarations: [ModalConfirmaTokenComponent],
})
export class ModalConfirmaTokenModule { }
