<form [formGroup]="form" class="espaco-top">
  <div class="alinha" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div [hidden]="!processando" fxFlex="100" style="padding: 5px 5px 10px 5px">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxFlex="49" fxFlex.sm="100" fxFlex.xs="100">
      <p>Limite de transferência</p>
      <mat-form-field appearance="outline">
        <mat-label>Limite</mat-label>
        <input
          matInput
          currencyMask
          type="text"
          formControlName="limiteTransferencia"
          onclick="this.setSelectionRange(-1,-1);"
          onfocus="this.setSelectionRange(-1,-1);"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        />
      </mat-form-field>
    </div>
  </div>
</form>
