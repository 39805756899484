import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UsuarioEndpointService } from "../usuario-endpoint.service";
import { environment } from "@src/environments/environment";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";
import { CheckAuthoritiesService } from "../util/checkAuthorities.service";

@Injectable({
  providedIn: "root",
})
export class acessoTipoContaService implements CanActivate {
  constructor(
    private _roleService: UsuarioEndpointService,
    private _router: Router,
    private _autenticacao: AutenticacaoEndpointService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._roleService
      .getRegraEmpresaLogada()
      .toPromise()
      .then(async (response) => {
        const checkAuthorities = new CheckAuthoritiesService(this._autenticacao.sessao);
        if(checkAuthorities.isAdministrador){
          return true;
        }

        if (route.data.param === "investimentos") {
          return response.empresa.tipoConta !== "contaDigitalS";
        }

        if (route.data.param === "pague-contas") {
          if(response.empresa.configuracao.habilitaPagamentos){
            return true;
          }else{
            return false;
          }
        }

        if (response.empresa.numIdentcBenfcrio ||
          (!response.empresa.numIdentcBenfcrio &&
            response.empresa.tipoConta == "contaDigitalS")  || (environment.modulos.pix.gerarQrCode == true && this._autenticacao.regraEmpresa.empresa.configuracao.areaPix == true)
        ) {
          return true;
        }

        this._router.navigate(["/principal"]);
        return false;
      });
  }

  ngOnDestroy() {}
}
