import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FiltroListaGenericaDTO } from "../model/filtro/filtro-lista-generica-dto.model";
import moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class LocalizacaoEndPointService {

    constructor(private client: HttpClient) { }

    salvar(localizacao): Observable<any> {
        const url = `/localizacao/salvar`;
        return this.client.post(url, localizacao);
    }

    listarConfiguracoes(page, size): Observable<any> {
        let params = new HttpParams();

        params = params.append('page', `${page}`);
        params = params.append('size', `${size}`);

        const url = `/localizacao/listar-configuracao`;
        return this.client.get(url, { params });
    }

    atualizarConfiguracao(tipo): Observable<any> {
        const url = `/localizacao/atualizar-configuracao?tipo=${tipo}`;
        return this.client.put(url, tipo);
    }

    desativarTodasConfiguracoes(): Observable<any> {
        const url = `/localizacao/desativar-todas-configuracoes`;
        return this.client.put(url, { params: {} });
    }

    listarConfigPorTipo(tipo): Observable<any> {
        const url = `/localizacao/listar-por-tipo?tipo=${tipo}`;
        return this.client.get(url);
    }

    listarPorEmpresa(filtro: FiltroListaGenericaDTO) {
        let params = new HttpParams();
        params = params.append('page', filtro.page ? `${filtro.page}` : '0')
        params = params.append('size', filtro.size ? `${filtro.size}` : '10')
        if (filtro.i) {
            params = params.append('i', `${moment(filtro.i).format('DDMMYYYY')}`);
        }
        if (filtro.f) {
            params = params.append('f', `${moment(filtro.f).format('DDMMYYYY')}`);
        }
        if (filtro.query) {
            params = params.append('user', filtro.query);
        }
        if (filtro.empresaId) {
            params = params.append('empresaId', `${filtro.empresaId}`)
        }

        const url = `/localizacao/listar-por-empresa`;
        return this.client.get(url, { params });
    }
}