import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';

@Component({
  selector: 'app-modal-info-signatario',
  templateUrl: './modal-info-signatario.component.html',
  styleUrls: ['./modal-info-signatario.component.less']
})
export class ModalInfoSignatarioComponent implements OnInit {

  public vinculados = [];
  public signatario;

  constructor(@Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public dialogRef: MatDialogRef<ModalInfoSignatarioComponent>,
    private rootService: RootEndpointService) { }

  ngOnInit() {
    this.rootService.getVinculoSignatarioDocumento(this.dataDialog.signerId).toPromise().then(data => {
      if (!data || data.length === 0) {
        this.rootService.getSigner(this.dataDialog.signerId).toPromise().then(data => {
          this.signatario = data;
        })
      } else {
        data.forEach(item => {
          this.vinculados.push(item.documento);
          this.signatario = item.signatario;
        })
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }
}
