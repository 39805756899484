import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

type Validation = {
  valid: boolean;
}

type GoogleAuthenticatorDTO = {
  username?: string,
  code?: number,
  qrcode?: any,
  key?: string
}

@Injectable({
  providedIn: "root",
})
export class GoogleAuthenticatorEndpointService {
  constructor(private client: HttpClient) {}

  gerarQRCode(usuario: string) {
    const url = `/authenticator/generate/${usuario}`;
    return this.client.get<GoogleAuthenticatorDTO>(url);
  }

  validar(usuario: string, codigo: number, authenticatorKey?: string) {
    const url = "/authenticator/validate";
    const body: GoogleAuthenticatorDTO = {
      username: usuario,
      code: codigo,
    };
    if(authenticatorKey) body.key = authenticatorKey;
    return this.client.post<Validation>(url, body);
  }
}
