<form [formGroup]="form">
  <div class="row alinha" *ngFor="let email of form.controls; index as i" [formGroup]="email">

    <div class="col-8">
      <h4 class="titulo">EMAIL {{ i + 1 }}</h4>
    </div>
    <div class="col-2" style="text-align: end;">
      <i class="fas fa-trash icones"
        [title]="!email.get('principal').value ? 'Excluir' : 'Não é possivel excluir o e-mail principal'"
        (click)="!email.get('principal').value && contador > 1 ? del(i) : null"
        [style.color]="!email.get('principal').value && contador > 1 ? 'red' : 'grey'" style="padding-top: 12px"></i>
    </div>
    <div class="col-2" style="margin-top: 14px; text-align: end;">
      <mat-slide-toggle formControlName="principal" (change)="changeContaPrincipal($event)">Principal
      </mat-slide-toggle>
      <mat-error style="font-size: 12px;">{{ getErrorMessage(email, "principal") }}</mat-error>
    </div>

    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" required />
        <mat-error>{{ getErrorMessage(email, "email") }}</mat-error>
      </mat-form-field>
    </div>

  </div>
</form>


<div class="row alinha">
  <div *ngIf="telaContato === true" class="col-6 col-md-6 add">
    <span (click)="add()">+ adicionar novo e-mail</span>
  </div>
</div>