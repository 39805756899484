import { Component, OnDestroy, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { HomeEndpointService } from '@service/home-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stepper3',
  templateUrl: './stepper3.component.html',
  styleUrls: ['./stepper3.component.less']
})
export class Stepper3Component implements AfterViewInit, OnDestroy {
  @Input()
  public form: FormGroup;

  @Output() atualizar: EventEmitter<any> = new EventEmitter();

  @Output()
  public atualizarForm: EventEmitter<any> = new EventEmitter();

  permissaoCadastro: {
    permitirCadastroPessoaFisica: true,
    permitirCadastroPessoaJuridica: true
  };

  constructor(private homeService: HomeEndpointService) { }

  ngAfterViewInit(): void {
    this.homeService.getConfiguracoesSistema().pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.permissaoCadastro = response;
        if (!this.permissaoCadastro.permitirCadastroPessoaFisica) {
          this.setTipoConta('pj');
          this.atualizarForm.emit({
            atualizar: true,
            tipoCadastro: true
          });
        } else if (!this.permissaoCadastro.permitirCadastroPessoaJuridica) {
          this.setTipoConta('pf');
          this.atualizarForm.emit({
            atualizar: true,
            tipoCadastro: true
          });
        }
        else if (this.permissaoCadastro.permitirCadastroPessoaJuridica && this.permissaoCadastro.permitirCadastroPessoaFisica) {
          this.setTipoConta('pf');
          this.atualizarForm.emit({
            atualizar: true,
            tipoCadastro: false
          });
        }
      }, error => {
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
        console.error(error);
      }
    );
  }

  ngOnDestroy() { }

  setTipoConta(tipoConta) {
    this.form.get('tipoConta').setValue(tipoConta);
  }
}
