import { HttpParams } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

export interface SimpleFilter {
    page?: number;
    size?: number;
    order?: string;
    q?: string;
}

export class SimpleFilterUtils {
    static toHttpUtils(model: SimpleFilter, httpParams?: HttpParams) {
        httpParams = httpParams || new HttpParams();
        if (model) {
            if (model.page >= 0) {
                httpParams = httpParams.append('page', `${model.page}`);
            }
            if (model.size >= 0) {
                httpParams = httpParams.append('size', `${model.size}`);
            }
            if (model.order && model.order.trim()) {
                httpParams = httpParams.append('order', `${model.order}`);
            }
            if (model.q && model.q.trim()) {
                httpParams = httpParams.append('q', `${model.q}`);
            }
        }
        return httpParams;
    }

    static toSimpleFilter(args: {
        order?: {
            sort: MatSort;
            defaultOrderBy?: string;
        };
        pagination?: {
            paginator: MatPaginator;
            defaultPage?: number;
            defaultSize?: number;
        }
        q?: string;
        model?: SimpleFilter;
    }) {
        args = args || {};
        let model = args.model || {};
        const { order, pagination, q } = args;

        if (order) {
            const { sort, defaultOrderBy } = order;
            let orderBy = defaultOrderBy;
            if (sort && sort.active) {
                const prefixSort = sort.direction === 'desc' ? '-' : '';
                orderBy = `${prefixSort}${sort.active}`;
            }
            model.order = orderBy;
        }

        if (pagination) {
            const { paginator, defaultPage, defaultSize } = pagination;
            model.page = defaultPage;
            model.size = defaultSize;
            if (paginator) {
                const { pageIndex, pageSize } = paginator;
                if (pageIndex >= 0) {
                    model.page = pageIndex;
                }
                if (pageSize >= 0) {
                    model.size = pageSize;
                }
            }
        }

        if (q && q.trim()) {
            model.q = q;
        }

        return model;
    }
}
