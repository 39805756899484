import { MaterialModule } from './../../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadArquivosComponent } from './upload-arquivos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directive/directive.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectiveModule
  ],
  declarations: [UploadArquivosComponent],
  exports:[UploadArquivosComponent]
})
export class UploadArquivosModule { }
