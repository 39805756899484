import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicidadeEndpointService } from '../../service/publicidade-endpoint.service';
import { AdicionaImagemIndicarContatoComponent } from './adiciona-imagem/adiciona-imagem.component';
import { IndicarAmigoEndpointService } from '../../service/indicar-amigo-endpoint.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { error } from 'console';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-indicar-contato',
  templateUrl: './indicar-contato.component.html',
  styleUrls: ['./indicar-contato.component.less'],
})
export class IndicarContatoComponent implements OnInit {

  publicidade = null;
  imagem: any;
  processando: boolean = false;
  selectedPub: any;
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<IndicarContatoComponent>,
    public publicidadeService: PublicidadeEndpointService,
    public indicarAmigoService: IndicarAmigoEndpointService,
    private dialog: MatDialog,
    private fb: FormBuilder,
  ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      texto: new FormControl("")
    })
    this.listarImagem();
    this.retornarTexto();
  }

  listarImagem() {
    this.processando = true;
    this.indicarAmigoService.retornarImagem().subscribe((res) => {
      this.imagem = res;
      this.processando = false;
      this.selectedPub = this.imagem;
    }, error => {
      this.processando = false;
      // Swal.fire({
      //   title: 'Erro!',
      //   text: error.error,
      //   icon: 'error',
      // })
    });
  }

  retornarTexto() {
    this.processando = true;
    this.indicarAmigoService.retornarTexto().subscribe((res) => {
      this.processando = false;
      this.form.get('texto').setValue(res);
    }, error => {
      this.processando = false;
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
      console.log(error);
    });
  }

  abreCropper() {
    this.dialog.open(AdicionaImagemIndicarContatoComponent, {
      backdropClass: "modal-medio",
    })
      .afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          this.listarImagem();
        }, 2000); // Ajuste o valor para o tempo que você considerar adequado.
      })
  }


  fecha() {
    this.dialogRef.close();
  }

  public async salvarTexto() {
    if (this.form.invalid) { return; }

    this.processando = true;
    const texto = {
      campo: 'TEXTO_INDICAR_CONTATO',
      valor: this.form.get('texto').value
    };
    this.indicarAmigoService.salvarTexto(texto)
      .subscribe(
        response => {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Texto salvo com sucesso',
            icon: "success"
          }).then(() => {
            this.dialogRef.close();
          });
        },
        error => {
          this.processando = false;
          Swal.fire({
            title: 'Erro!',
            text: 'Erro ao salvar texto',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close();
          });
        }
      );
  }

}
