<h2 mat-dialog-title class="titulo-modal pl-4">
    Importação de lote
    <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<div class="row body">
    <div class="col-12 col-md-9">
        <mat-dialog-content>

            <mat-horizontal-stepper class="top-stp" [linear]=true (selectionChange)="stepSelectionChange($event)"
                #stepGroupComponents>
                <mat-step [editable]="true" state="done"
                    [stepControl]="transferenciaLoteEditarModeloComponent?.getForm()">
                    <app-transferencia-lote-zeragem-modelo></app-transferencia-lote-zeragem-modelo>
                </mat-step>
                <mat-step [editable]="true" state="done"
                    [stepControl]="transferenciaLoteEditarUploadComponent?.getForm()">
                    <app-transferencia-lote-zeragem-upload [data]="dataDialog"></app-transferencia-lote-zeragem-upload>
                </mat-step>
                <mat-step [editable]="true" state="done" [stepControl]="tokenComponent?.getForm()">
                    <app-token estrutura="transferenciaLoteZeragem"></app-token>
                </mat-step>
            </mat-horizontal-stepper>

        </mat-dialog-content>
    </div>
    <div class="col-12 col-md-3">
        <app-transferencia-lote-zeragem-resumo
            [transferenciaLoteEditarComponent]="this"></app-transferencia-lote-zeragem-resumo>
    </div>
</div>

<mat-dialog-actions>
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="row w-100">
        <div class="col-12 col-md-4 align-self-center d-flex justify-content-end col-btn-voltar">
            <button *ngIf="currentStep?.back?.isShow()" type="button" (click)="voltar()"
                class="btn btn-secundario btn-voltar" mat-raised-button>
                {{currentStep?.back?.label}}
            </button>
        </div>
        <div class="col-12 col-md-4 align-self-center">
            <mat-horizontal-stepper class="bottom-stp" labelPosition="bottom" [linear]="true"
                (selectionChange)="stepSelectionChange($event)" #stepGroupButtons>
                <mat-step [editable]="true" state="done" *ngFor="let step of steps"
                    [stepControl]="step.step().getForm()">
                    <ng-template matStepLabel>{{step.label}}</ng-template>
                </mat-step>

                <ng-template matStepperIcon="edit">
                    <i class="fa fa-check"></i>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <i class="fa fa-circle"></i>
                </ng-template>
                <ng-template matStepperIcon="error">
                    <i class="fa fa-times-circle"></i>
                </ng-template>
            </mat-horizontal-stepper>
        </div>
        <div class="col-12 col-md-4 align-self-center col-btn-proximo">
            <button *ngIf="currentStep?.next?.isShow()" type="button" (click)="proximo()"
                [disabled]="!currentStep?.step()?.isValid()" class="btn btn-primario btn-proximo" mat-raised-button>
                {{currentStep?.next?.label}}
            </button>
        </div>
    </div>
</mat-dialog-actions>