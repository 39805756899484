<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    CONFIGURAÇÃO
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div mat-dialog-content class="mat-dialog-content-responsive">
    <div class="mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 28px 0px;display: flex;
      justify-content: center; margin-bottom: 15px;">
      <div id="titulo-lista">Escolha os itens a serem exibidos no carrossel do dashboard</div>
      <div class="container">
        <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
          <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <mat-list id="list-container" class="example-list">
          <mat-list-item *ngFor="let item of itensCarrossel" class="example-box">
            <i class="{{ iconeCard(item?.tipo) }}" id="icones"></i>
            <div style="width: 22vh;">
              <div id="config-list" style="display: flex; align-items: center; margin-left: 15px; overflow: hidden;">
                <div>
                  <span class="msg-lista">
                    {{ formataNome(item?.tipo) }}
                  </span>
                </div>
                <div>
                  <mat-slide-toggle [checked]="item.ativo" (change)="atualizarCarrossel(item.tipo)"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>