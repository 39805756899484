import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { tipoContaOptions } from "@model/constante.model";
import { ConsultasEndpointService } from "@service/consultas-endpoint.service";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { TransferenciaEndpointService } from "@service/transferencia-endpoint.service";
import { TooltipPosition } from '@angular/material/tooltip';
import moment from "moment";
import { environment } from "@project/src/environments/environment.default";
import Swal from "sweetalert2";

@Component({
  selector: "app-dados-bancarios",
  templateUrl: "./dados-bancarios.component.html",
  styleUrls: ["./dados-bancarios.component.less"],
})
export class DadosBancariosComponent implements OnInit, OnDestroy {

  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  listaTipoConta = tipoContaOptions;

  @Input()
  public form: FormArray;

  @Input()
  public data: any;

  @Input()
  public documentoPreenchido = false;

  @Input()
  public formGeral: any;

  nomeEmpresa: string;
  bancoList: any;
  subBanco: Subscription;
  env = environment;

  constructor(
    private fb: FormBuilder,
    private consultas: ConsultasEndpointService,
    private transferenciaConsulta: TransferenciaEndpointService,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.data && this.data.contas) {
      this.data.contas.forEach((element) => {
        this.transferenciaConsulta.contaPossuiTransferencia(element.id).toPromise()
          .then(response => this.add(element, response))
          .catch(error => console.error(error))
      });

      this.nomeEmpresa = this.data.razaoSocial || this.data.nome;
    }

    this.getBanco();
  }

  getBanco(event?) {
    const valor = event ? event.term : null;
    if (this.subBanco) {
      this.subBanco.unsubscribe();
    }

    this.subBanco = this.consultas
      .buscarBancos({
        q: valor,
        top: 20,
      })
      .pipe(first())
      .subscribe(
        (response) => {
          this.bancoList = response;
          if (response && valor && (valor.includes("0") || valor.includes("00") || valor.includes("000"))) {
            var obj = {
              acessoPrincipal: "",
              inicioDaOperacao: moment(new Date()),
              ispb: "",
              nomeExtenso: "Conta Cobrança Digital " + this.env.nomeApp,
              nomeReduzido: "Conta Cobrança Digital " + this.env.nomeApp,
              numeroCodigo: "000"
            };
            this.bancoList.push(obj);
          }
          this.changeDetectorRef.detectChanges();
        },
        (error) => {
          Swal.fire({
            title: "Erro ao buscar bancos",
            text: error.error,
            icon: "error",
          })
          console.error(error);
        }
      );
  }

  ngOnDestroy() { }

  public add(dadosBancarios?: any, isDeletavel?: any) {
    dadosBancarios = dadosBancarios || {};

    const tipoCadastroContaBancaria = "terceiros";
    let nome, documento, celular, email;
    if (this.data) {
      nome = this.data.razaoSocial || this.data.nome;
      documento = this.data.documento;
      if (this.data.telefones && this.data.telefones.length) {
        const item = this.data.telefones
          .sort((a, b) => {
            if (
              (a.tipo === "celular" && b.tipo === "celular") ||
              !(a.tipo === "celular" && b.tipo === "celular")
            ) {
              if (
                (a.principal && b.principal) ||
                !(a.principal && b.principal)
              ) {
                return 0;
              }
              if (a.principal && !b.principal) {
                return 1;
              }
              return -1;
            }
            if (a.tipo === "celular" && b.tipo !== "celular") {
              return 1;
            }
            return -1;
          })
          .sort((a, b) => { })[0];
        celular = item.numero ? `${item.ddd}${item.numero}` : "";
      }
      if (this.data.emails && this.data.emails.length) {
        const item = this.data.emails.sort((a, b) => {
          if ((a.principal && b.principal) || !(a.principal && b.principal)) {
            return 0;
          }
          if (a.principal && !b.principal) {
            return 1;
          }
          return -1;
        })[0];
        email = item.email || "";
      }
    } else {
      documento = this.formGeral.get('documento').value
      nome = this.formGeral.get('razaoSocial').value || this.formGeral.get('nome').value
    }

    if ((isDeletavel != null && !isDeletavel) || dadosBancarios.dataDesativacao == null) {
      this.form.push(
        this.fb.group(
          {
            favorito: this.fb.control(!!dadosBancarios.favorito),
            id: this.fb.control(dadosBancarios.id || null),
            bancoItem: this.fb.control(dadosBancarios.banco || "", [
              Validators.required,
            ]),
            banco: this.fb.control(dadosBancarios.banco || " "),
            codigoBanco: this.fb.control(dadosBancarios.codigoBanco || ""),
            tipoConta: this.fb.control(dadosBancarios.tipoConta, [
              Validators.required,
            ]),
            agencia: this.fb.control(dadosBancarios.agencia, [
              Validators.required,
              Validators.pattern(/^[0-9]+$/),
            ]),
            agenciaDigito: this.fb.control(dadosBancarios.agenciaDigito || null, [
              Validators.pattern(/^[0-9]+$/),
            ]),
            conta: this.fb.control(dadosBancarios.conta, [
              Validators.required,
              Validators.pattern(/^[0-9]+$/),
            ]),
            contaDigito: this.fb.control(dadosBancarios.contaDigito || null, [
              Validators.pattern(/^[0-9]+$/),
            ]),
            apelidoConta: this.fb.control(dadosBancarios.apelidoConta, [
              Validators.required,
            ]),
            titular: this.fb.control(dadosBancarios.titular || nome),
            documento: this.fb.control(
              (dadosBancarios.documento || documento || "").replace(
                /[^0-9]/gim,
                ""
              ),
              [Validators.pattern(/^([0-9]{11}|[0-9]{14})$/)]
            ),
            dataDesativacao: this.fb.control(dadosBancarios.dataDesativacao || null),
            celularResponsavel: this.fb.control(
              dadosBancarios.celularResponsavel || celular || ""
            ),
            emailResponsavel: this.fb.control(
              dadosBancarios.emailResponsavel || email || "", Validators.email
            ),
            tipoEnvio: this.fb.control(tipoCadastroContaBancaria, [
              Validators.required,
            ]),
            isAtivo: this.fb.control(dadosBancarios.dataDesativacao ? false : true),
            isDeletavel: this.fb.control(isDeletavel != null ? isDeletavel : true),
          },
          {
            validator: [
              this.obrigatoriosTerceiro("tipoEnvio", "documento", "titular"),
            ],
          }
        )
      )
    };
  }

  preencheCamposBanco(event, index) {
    let codigo;

    if (event) {
      if (event.numeroCodigo) {
        codigo =
          String(event.numeroCodigo).length === 1
            ? "00" + String(event.numeroCodigo)
            : String(event.numeroCodigo).length === 2
              ? "0" + String(event.numeroCodigo)
              : String(event.numeroCodigo);
      } else {
        codigo = "000";
      }

      this.form.controls[index].get("codigoBanco").setValue(codigo);
      this.form.controls[index].get("banco").setValue(event.nomeExtenso);
    }
  }

  obrigatoriosTerceiro(tipoEnvio, documento, titular) {
    return (group: FormGroup) => {
      const tipoEnvioInput = group.controls[tipoEnvio],
        documentoInput = group.controls[documento],
        titularInput = group.controls[titular];

      let documentoErrors: any = { required: true };
      let titularErrors: any = { required: true };

      if (tipoEnvioInput.value === "terceiros") {
        if (documentoInput.value !== "" && documentoInput.value !== null) {
          if (!documentoInput.value.match(/^([0-9]{11}|[0-9]{14})$/)) {
            documentoErrors = { pattern: true };
          } else {
            documentoErrors = null;
          }
        } else {
          documentoErrors = { required: true };
        }

        if (titularInput.value !== "" && titularInput.value !== null) {
          titularErrors = null;
        } else {
          titularErrors = { required: true };
        }

        return (
          documentoInput.setErrors(documentoErrors),
          titularInput.setErrors(titularErrors)
        );
      } else if (tipoEnvioInput.value === "empresa") {
        return documentoInput.setErrors(null), titularInput.setErrors(null);
      }
    };
  }

  public del(index) {
    this.form.removeAt(index);
  }

  getErrorMessage(form: FormControl): string | null {
    if (form.hasError("required")) {
      return "Campo requerido";
    }
    if (form.hasError("minlength") || form.hasError("pattern")) {
      return "Campo inválido";
    }
  }

  isDocumentoPreenchido() {
    if (this.data && this.data.documento) {
      if (this.data.documento.replace(/[ ().-]/g, '').length == 11 || this.data.documento.replace(/[^\d]+/g, '').length == 14) {
        return false
      } else {
        return true
      }
    } else {
      return !this.documentoPreenchido;
    }
  }
}
