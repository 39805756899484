import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RegraEmpresaDTO } from '@model/pessoa/regra-empresa-dto.model';
import { PrincipalModel } from '@model/principal.model';
import { RegraCorporacaoDTO } from '@model/sistema/regra-corporacao-dto.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permissao } from './pagina.service';
import { UsuarioEndpointService } from './usuario-endpoint.service';
import { LoginDTO } from '../model/login/login-dto.model';

@Injectable({
	providedIn: 'root'
})
export class AutenticacaoEndpointService implements OnDestroy {
	_sessao = new BehaviorSubject(null) || null;
	_regraEmpresa = new BehaviorSubject(null) || null;

	constructor(
		private client: HttpClient,
		@Inject(LOCAL_STORAGE) private storage: StorageService,
		private router: Router,
		private usuario: UsuarioEndpointService
	) { }

	ngOnDestroy() { }

	acessar({ agencia, codeColaborador, codeMultifator, codeOperador, conta, senha, token, usuario }: LoginDTO): Observable<PrincipalModel> {
		const url = '/login';
		let headers = new HttpHeaders();

		if (usuario && senha) {
			headers = new HttpHeaders().append(
				'Authorization',
				'Basic ' + btoa(usuario + ':' + senha)
			);
		}

		return this.client
			.post<PrincipalModel>(url, {
				agencia,
				codeColaborador,
				codeMultifator,
				codeOperador,
				conta,
				token,
			}, { headers })
			.pipe(
				map(retorno => {
					this.sessao = retorno;
					this.usuario
						.getRegraCorporacaoLogada()
						.pipe(untilDestroyed(this))
						.subscribe(
							sucesso => (this.regraCorporacao = sucesso),
							console.warn
						);
					this.usuario
						.getRegraEmpresaLogada()
						.pipe(untilDestroyed(this))
						.subscribe(sucesso => (this.regraEmpresa = sucesso), console.warn);
					return retorno;
				})
			);
	}

	me(): Observable<PrincipalModel> {
		return this.client
			.get<PrincipalModel>('/me')
			.pipe(
				map(retorno => {
					this.sessao = retorno;
					this.usuario
						.getRegraCorporacaoLogada()
						.pipe(untilDestroyed(this))
						.subscribe(
							sucesso => (this.regraCorporacao = sucesso),
							console.warn
						);
					this.usuario
						.getRegraEmpresaLogada()
						.pipe(untilDestroyed(this))
						.subscribe(sucesso => (this.regraEmpresa = sucesso), console.warn);
					return retorno;
				})
			);
	}

	isAdministrador() {
		return this.permite([
			{
				tipo: 'sistema',
				nome: 'ROLE_ADMINISTRADOR'
			}
		]);
	}

	permite(permissoes: Array<Permissao>) {
		try {
			const user = this.sessao;

			if (!user) {
				return false;
			}

			let serverEmpresaId, serverCorporacaoId;
			const regraCorporacao = this.regraCorporacao;
			if (regraCorporacao && regraCorporacao.corporacao) {
				serverCorporacaoId = regraCorporacao.corporacao.id;
			}
			const regraEmpresa = this.regraEmpresa;
			if (regraEmpresa && regraEmpresa.empresa) {
				serverEmpresaId = regraEmpresa.empresa.id;
			}

			for (const permissao of permissoes) {
				const { tipo, nome } = permissao;
				if (!nome) {
					continue;
				}
				if (!tipo) {
					continue;
				}
				for (const auth of user.authorities) {
					const { authority, corporcaoId, empresaId } = auth;
					if (
						tipo === 'sistema' &&
						(authority === nome || authority === 'ROLE_ADMINISTRADOR')
					) {
						return true;
					}
					if (
						(tipo === 'corporacao' || tipo === 'empresa') &&
						corporcaoId &&
						corporcaoId === serverCorporacaoId &&
						(authority === nome || authority === 'ROLE_PROPRIETARIO')
					) {
						return true;
					}
					if (
						tipo === 'empresa' &&
						corporcaoId &&
						corporcaoId === serverCorporacaoId &&
						empresaId &&
						empresaId === serverEmpresaId &&
						authority === nome
					) {
						return true;
					}
				}
			}
			return false;
		} catch (error) {
			console.warn('Falha ao validar permissão de usuário: ', error);
			return false;
		}
	}

	sairSemRedirecionar(): Observable<any> {
		if (this.storage.get('logado')) {
			this.storage.set('logado', false);
		}
		const url = '/logout';
		return this.client.get(url);
	}

	sair(isHome: boolean = true, lastState?: string): Observable<any> {
		if (this.storage.get('logado')) {
			this.storage.set('logado', false);
		}

		return this.client.get('/logout').pipe(e => {
			this.sessao = null;
			if (isHome) {
				this.router.navigateByUrl('/acesso');
			} else {
				this.router.navigate(['/acesso'], {
					queryParams: {
						redirect: lastState
					}
				});
			}
			return e;
		});
	}

	get sessao(): PrincipalModel | null {
		return this.storage.get('sessao') as PrincipalModel;
	}

	set sessao(sessao: PrincipalModel | null) {
		if (sessao) {
			this._sessao.next(sessao);
			this.storage.set('sessao', sessao);
		} else {
			this._sessao.next(null);
			this.storage.remove('sessao');
			this.regraEmpresa = null;
			this.regraCorporacao = null;
		}
	}

	get regraEmpresa() {
		return this.storage.get('regraEmpresa') as RegraEmpresaDTO;
	}

	set regraEmpresa(regraEmpresa) {
		if (regraEmpresa) {
			this._regraEmpresa.next(regraEmpresa);
			this.storage.set('regraEmpresa', regraEmpresa);
		} else {
			this._regraEmpresa.next(null);
			this.storage.remove('regraEmpresa');
		}
	}

	get regraCorporacao() {
		return this.storage.get('regraCorporacao') as RegraCorporacaoDTO;
	}

	set regraCorporacao(regraCorporacao) {
		if (regraCorporacao) {
			this.storage.set('regraCorporacao', regraCorporacao);
		} else {
			this.storage.remove('regraCorporacao');
		}
	}


}
