import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { addYears } from 'date-fns';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-input-date-month',
  templateUrl: './input-date-month.component.html',
  styleUrls: ['./input-date-month.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class InputDateMonthComponent{

  @Input()
  date: FormControl;

  @Input()
  label: string = ' ';

  @Input()
  dateMin: Date = new Date();

  dateMax: Date = addYears(this.dateMin,3)

  constructor() { }

  private ultimoDia(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
  }

  chosenMonthHandler(month: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    if(this.dateMin.getDate() == 31 && this.ultimoDia(month.toDate()) != 31){
      var mes: Date = month.toDate()
      mes.setMonth(mes.getMonth()+1)
      this.date.setValue(mes);
    } else{
      this.date.setValue(month.add(this.dateMin.getDate()-1, 'days').toDate());
    }
    datepicker.close();
  }

}
