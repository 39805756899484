import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@src/environments/environment';
import { AutenticacaoEndpointService } from '../../service/autenticacao-endpoint.service';
import { ColaboradorEndpointService } from '../../service/colaborador-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-falar-com-gerente',
  templateUrl: './falar-com-gerente.component.html',
  styleUrls: ['./falar-com-gerente.component.less']
})
export class FalarComGerenteComponent implements OnInit {
  env = environment;
  usuarioLogado: any;
  gerente: any;
  constructor(
    public dialogRef: MatDialogRef<FalarComGerenteComponent>,
    private autenticacao: AutenticacaoEndpointService,
    private colaboradorService: ColaboradorEndpointService
  ) {
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
  }

  ngOnInit() {
    if (this.usuarioLogado.codigoGerenteConta && this.env.modulos.usuarios.gerenteDeContas) {
      this.colaboradorService.getByNro(this.usuarioLogado.codigoGerenteConta).toPromise()
        .then((res) => {
          this.gerente = res;
        }, error => {
          Swal.fire({
            title: 'Erro ao buscar gerente',
            text: error.error,
            icon: 'error',
          })
          console.log(error);
        })
    }
  }

  fecha(): void {
    this.dialogRef.close();
  }
}
