import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { TokenComponent } from '@project/src/app/components/token/token.component';
import { TransferenciaLoteDTO } from '@project/src/app/model/financeiro/transferencia/transferencia-lote-dto.model';
import { PessoaDTO } from '@project/src/app/model/pessoa/pessoa-dto.model';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { StepConfig } from '../../../cnab/cnab-editar/cnab-editar.model';
import { TransferenciaLoteZeragemModeloComponent } from './transferencia-lote-zeragem-modelo/transferencia-lote-zeragem-modelo.component';
import { TransferenciaLoteZeragemUploadComponent } from './transferencia-lote-zeragem-upload/transferencia-lote-zeragem-upload.component';


@Component({
  selector: 'app-transferencia-lote-zeragem-editar',
  templateUrl: './transferencia-lote-zeragem-editar.component.html',
  styleUrls: ['./transferencia-lote-zeragem-editar.component.less']
})
export class TransferenciaLoteZeragemEditarComponent  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepGroupComponents', { static: false })
  _stepGroupComponents: MatHorizontalStepper;

  @ViewChild('stepGroupButtons', { static: false })
  _stepGroupButtons: MatHorizontalStepper;

  @ViewChild(TransferenciaLoteZeragemModeloComponent, { static: false })
  transferenciaLoteEditarModeloComponent: TransferenciaLoteZeragemModeloComponent;

  @ViewChild(TransferenciaLoteZeragemUploadComponent, { static: false })
  transferenciaLoteEditarUploadComponent: TransferenciaLoteZeragemUploadComponent;

  @ViewChild(TokenComponent, { static: false })
  tokenComponent: TokenComponent;

  currentStepIndex: number;
  steps: StepConfig[];

  empresaLogada: PessoaDTO;
  processando: boolean;

  private destroy: Subject<void>;
  saldoAtual: number;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    public dialogRef: MatDialogRef<TransferenciaLoteZeragemEditarComponent>,
    private transferenciaEndpointService: TransferenciaEndpointService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: TransferenciaLoteDTO,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentStepIndex = 0;
    this.destroy = new Subject<void>();
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa.pessoa;

    this.transferenciaEndpointService
      .saldoDisponivel()
      .toPromise()
      .then((response) => (this.saldoAtual = response ? response : 0))
      .catch((error) => console.error(error));
  }

  ngAfterViewInit(): void {
    this.steps = [];
    this.steps.push({
      step: () => this.transferenciaLoteEditarModeloComponent,
      label: 'Download do Modelo',
      back: {
        isShow: () => false,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Próximo',
      },
    });
    this.steps.push({
      step: () => this.transferenciaLoteEditarUploadComponent,
      label: 'Upload e Validação',
      back: {
        isShow: () => true,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Próximo',
      },
    });
    this.steps.push({
      step: () => this.tokenComponent,
      label: 'Autenticação (token)',
      back: {
        isShow: () => true,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Enviar Lote',
      },
    });
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      if (this.dataDialog && this.dataDialog.id) {
        this._stepGroupButtons.selectedIndex = 1;
        this._stepGroupComponents.selectedIndex = 1;
      }
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  stepSelectionChange(event: StepperSelectionEvent) {
    this.currentStepIndex = event.selectedIndex;
  }

  get currentStep() {
    if (this.steps && this.steps.length) {
      return this.steps[this.currentStepIndex];
    }
  }

  voltar() {
    if (this.currentStepIndex <= 0) {
      this.currentStepIndex = 0;
    } else {
      this.currentStepIndex--;
    }
    this._stepGroupButtons.selectedIndex = this.currentStepIndex;
    this._stepGroupComponents.selectedIndex = this.currentStepIndex;
  }

  proximo() {
    const max = this.steps.length - 1;
    if (this.currentStepIndex >= max) {
      this.finalizar();
    }
    if (this.currentStepIndex >= max) {
      this.currentStepIndex = max;
    } else {
      this.currentStepIndex++;
    }
    this._stepGroupButtons.selectedIndex = this.currentStepIndex;
    this._stepGroupComponents.selectedIndex = this.currentStepIndex;
  }

  async finalizar() {
    try {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      let model = {} as TransferenciaLoteDTO;
      model = this.transferenciaLoteEditarModeloComponent.parse(model);
      model = this.transferenciaLoteEditarUploadComponent.parse(model);
      model = this.tokenComponent.parse(model);
      const token = this.tokenComponent.getToken();
      const tipoToken = this.tokenComponent.getForm().get("isSMS").value ? "sms" : 
          this.tokenComponent.getForm().get("isEmail").value ? "email" : "mfa";
      await this.transferenciaEndpointService
        .enviarLoteZeragem(model.arquivo, token, tipoToken)
        .toPromise();
      Swal.close();
      await Swal.fire(
        'Sucesso!',
        'O lote está sendo processado, ao término você será notificado',
        'info'
      );
      window.location.reload();
    } catch (err) {
      await Swal.fire(
        'Ops!',
        `Falha ao processar o lote: ${err.error.error}`,
        'error'
      );
    }
  }

  showStep(index: number) {
    return index === this.currentStepIndex;
  }

  fecha(atualizar = false) {
    this.dialogRef.close(atualizar);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
