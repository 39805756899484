<div>
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width: 100%;">
        <!-- Data Hora -->
        <ng-container matColumnDef="dataCriacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dataCriacao">Data da Ocorrência (Banco)</th>
            <td mat-cell *matCellDef="let row">
                {{ row.dataCriacao | date:'dd/MM/yyyy' }} </td>
        </ng-container>
        <!-- Data Hora -->
        <ng-container matColumnDef="dataHora">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dataHora">Data da Ocorrência (Cartório)</th>
            <td mat-cell *matCellDef="let row">
                {{ row.dataHora | date:'dd/MM/yyyy' }} </td>
        </ng-container>
        <!-- Status -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status" style="padding-left: 4.3%;">Status</th>
            <td mat-cell *matCellDef="let row">
                {{ row.status }} </td>
        </ng-container>
        <!-- Mensagem do Status -->
        <ng-container matColumnDef="statusMensagem" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header="statusMensagem" style="padding-left: 3.4%;">Mensagem</th>
            <td mat-cell *matCellDef="let row">
                {{ row.statusMensagem }} </td>
        </ng-container>
        <!-- Data Protocolo -->
        <ng-container matColumnDef="dataProtocolo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dataProtocolo">Data Protocolo Cartório</th>
            <td mat-cell *matCellDef="let row">
                {{ row.dataProtocolo | date:'dd/MM/yyyy' }} </td>
        </ng-container>
        <!-- Código do Protocolo -->
        <ng-container matColumnDef="cartorioCodigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="cartorioCodigo">Código Cartório</th>
            <td mat-cell *matCellDef="let row">
                {{ row.cartorioCodigo }} </td>
        </ng-container>
        <!-- Protocolo Cartório -->
        <ng-container matColumnDef="cartorioProtocolo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="cartorioProtocolo">Protocolo Cartório</th>
            <td mat-cell *matCellDef="let row">
                {{ row.cartorioProtocolo }} </td>
        </ng-container>

        <!-- Coluna de Custas com ícone de expansão -->
        <ng-container matColumnDef="custas">
            <th mat-header-cell *matHeaderCellDef>Custas</th>
            <td mat-cell *matCellDef="let element" style="cursor:pointer">
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            </td>
        </ng-container>

        <!-- Linha de detalhes expandida -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" style="padding: 0px">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="box-body">
                        <div class="linha-3">
                            <p class="dado-item">
                                <span class="dado">Custas Distribuidor: </span>
                                {{ element.custasDistribuidor | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Cartório: </span>
                                {{ element.custasCartorio | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Gravação: </span>
                                {{ element.custasGravacao | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Despesas: </span>
                                {{ element.custasDespesas | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Confirmação: </span>
                                {{ element.custasConfirmacao | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Retorno: </span>
                                {{ element.custasRetorno | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Gravação Eletrônica: </span>
                                {{ element.custasGravacaoEletronica | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Distribuição Confirmação: </span>
                                {{ element.custasDistribuicaoConfirmacao | currency: 'BRL': 'R$' }}
                            </p>
                            <p class="dado-item">
                                <span class="dado">Custas Distribuição Retorno: </span>
                                {{ element.custasDistribuicaoRetorno | currency: 'BRL': 'R$' }}
                            </p>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns; index as i"
            [class.example-expanded-row]="expandedElement === element" class="example-element-row"
            (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <div *ngIf="!processando && listaOcorrenciasProtesto?.totalElements == 0" class="sem-resultado">
        <i class="fas fa-scroll fa-2x"></i>
        <p>Ainda não possui ocorrências</p>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]" [length]="listaOcorrenciasProtesto?.totalElements">
    </mat-paginator>
</div>