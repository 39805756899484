import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
	name: 'errorMessage',
	pure: false
})
export class ErrorMessagePipe implements PipeTransform {

	constructor() { }

	transform(value: AbstractControl) {
		if (value instanceof AbstractControl) {
			if (value.hasError('email')) {
				return 'Email inválido';
			}
			if (value.hasError('minlength')) {
				return 'Campo inválido';
			}
			if (value.hasError('maxlength')) {
				return 'Campo inválido';
			}
			if (value.hasError('deveSerIgual')) {
				return 'Campo não coincidem';
			}
			if (value.hasError('deveSerDiferente')) {
				return 'Campo coincidem';
			}
			if (value.hasError('usuarioJaExiste')) {
				return 'Usuário já existe';
			}
			if (value.hasError('celularJaExiste')) {
				return 'Celular já existe';
			}
			if (value.hasError('required')) {
				return 'Campo requerido';
			}
			if (value.hasError('validarTelefone')) {
				return 'Telefone inválido';
			}
		}
		return '';
	}

}
