import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidacoesService } from '../../service/validacoes.service';
import Swal from 'sweetalert2';
import { environment } from "@src/environments/environment";
import { TransferenciaEndpointService } from '@service/transferencia-endpoint.service';
import { StringFormatService } from '../../service/util/stringFormater.service';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-sensibilizacao',
  templateUrl: './sensibilizacao.component.html',
  styleUrls: ['./sensibilizacao.component.less']
})
export class SensibilizacaoComponent implements OnInit {
  private TransContaDoc = "";
  private TaxasContaDoc = "";

  public isEntradaTransf: boolean = true;
  public isEntradaTaxa: boolean = false;
  public subscription: Subscription;
  public processando = false;
  public data: any;
  public env = environment;
  public form: FormGroup;
  public selectedTab = 0;
  public nomeCreditd: string;
  public nomeDebtd: string;
  public expr = /[^0-9]/gim;
  public sendTransf = null;
  public showData: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    public stringFormat: StringFormatService,
    private dialog: MatDialog,
    private transferenciaService: TransferenciaEndpointService,
    private verifica: ChangeDetectorRef,
    private validacoesService: ValidacoesService,
    public dialogRef: MatDialogRef<SensibilizacaoComponent>,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      transferencia: this.formBuilder.group({
        agencia: new FormControl("", [Validators.required, Validators.pattern(/^[0-9]+$/)]),
        tpCtA: new FormControl("", [Validators.required]),
        ctA: new FormControl("", [Validators.required]),
        cNPJCPFCliA: new FormControl(""),
        nomCliA: new FormControl("", [Validators.required]),
        ctB: new FormControl("", [Validators.required]),
        vlrLanc: new FormControl("", [Validators.required]),
        finlddCli: new FormControl("", [Validators.required]),
        numCtrlSTR: new FormControl("", [Validators.required]),
        dtBC: new FormControl("", [Validators.required]),
        hrBC: new FormControl("", [Validators.required]),
        iSPBIF: new FormControl("", [Validators.required, Validators.pattern(/^[0-9]+$/)]),
        cNPJCPFCliB: new FormControl(""),
        tipoTransferencia: new FormControl("entrada", [Validators.required]),
        token: new FormControl({ value: '', disabled: true }),
      }),
      taxas: this.formBuilder.group({
        tipoTransferencia: new FormControl("saida", [Validators.required]),
        historicoLancamento: new FormControl("", [Validators.required]),
        ct: new FormControl("", [Validators.required]),
        dtHrBC: new FormControl(""),
        hrBC: new FormControl("", [Validators.required]),
        vlrLanc: new FormControl("", [Validators.required]),
        cNPJCPFCli: new FormControl(""),
        dtBC: new FormControl("", [Validators.required]),
        token: new FormControl({ value: '', disabled: true }),
        tipoTaxa: new FormControl("", [Validators.required])
      }),
    })

    this.form.get('transferencia.cNPJCPFCliA').setValidators([this.verificaDocumento(this.form, "transferencia", "cNPJCPFCliA")]);
    this.form.get('transferencia.cNPJCPFCliB').setValidators([this.verificaDocumento(this.form, "transferencia", "cNPJCPFCliB"), this.validaContaDoc(this.form)]);
    this.form.get('transferencia.hrBC').setValidators([this.verificaHora(this.form)])
  }

  verificaDocumento(form: FormGroup, campo: string, value: string) {
    return (control: AbstractControl) => {
      const documento = form.get(campo).get(value).value.replace(/[^0-9]/gim, "");

      if (documento.length <= 11 && documento.length != 0) {
        if (!this.validacoesService.validaCPF(documento)) {
          return { cpfinvalido: true } as ValidationErrors;
        }
      } else {
        if (!this.validacoesService.validaCNPJ(documento)) {
          return { cnpjinvalido: true } as ValidationErrors;
        }
      }
      return null as ValidationErrors;
    }
  }

  ValidaConta(conta) {
    conta = conta.replace(/[^0-9]/gim, "");
    this.transferenciaService
      .getContaDocumento(conta)
      .toPromise()
      .then((response) => {
        if (response) {
          if (this.selectedTab == 0) {
            this.TransContaDoc = response.pessoa.documento;
            this.form.get("transferencia.cNPJCPFCliB").setValue(response.pessoa.documento);
            this.nomeCreditd = response.pessoa.nome;
          } else {
            this.TaxasContaDoc = response.pessoa.documento;
            this.form.get("taxas.cNPJCPFCli").setValue(response.pessoa.documento);
            this.nomeDebtd = response.pessoa.nome;
          }
        } else {
          this.form.get("taxas.cNPJCPFCli").setValue("");
          this.form.get("transferencia.cNPJCPFCliB").setValue("");

          this.TransContaDoc = "";
          this.TaxasContaDoc = "";
          this.nomeCreditd = "";
          this.nomeDebtd = "";
        }
      }).catch((error) => {
        this.TransContaDoc = "";
        this.TaxasContaDoc = "";
        this.nomeCreditd = "";
        this.nomeDebtd = "";
        console.error(error);
      });
  }

  verificaHora(form: FormGroup) {
    return (control: AbstractControl) => {
      if (form.get("transferencia.hrBC").value.length != 5) {
        return { horaInvalida: true } as ValidationErrors;
      }
      let horas = form.get("transferencia.hrBC").value.split(':');
      if (horas.length != 2 || horas[0].length != 2 || horas[1].length != 2) {
        return { horaInvalida: true } as ValidationErrors;
      }
      try {
        if (parseInt(horas[0]) > 23 || parseInt(horas[0]) < 0
          || parseInt(horas[1]) > 59 || parseInt(horas[1]) < 0) {
          return { horaInvalida: true } as ValidationErrors;
        }
        return null as ValidationErrors;
      } catch (e) {
        return { horaInvalida: true } as ValidationErrors;
      }
    }
  }

  validaContaDoc(form: FormGroup) {
    return (control: AbstractControl) => {
      if (this.selectedTab == 0) {
        if (this.TransContaDoc === form.get("transferencia").get("cNPJCPFCliB").value.replace(/[^0-9]/gim, "")) {
          return null as ValidationErrors;
        }
      } else if (this.TaxasContaDoc === form.get("taxas").get("cNPJCPFCli").value.replace(/[^0-9]/gim, "")) {
        return null as ValidationErrors;
      }
      return { contaInvalida: true } as ValidationErrors;
    }
  }

  private formatarDataHoraParaBackend(data: string, hora: string): string {
    return `${data}T${hora}:00.000`;
  }

  onSubmit() {

    if (this.form) {
      this.processando = true;
      if (this.selectedTab == 0 && this.form.get("transferencia").valid) {

        const dataISO = this.form.get("transferencia.dtBC").value.toISOString().split('T')[0];
        const horaValor = this.form.get("transferencia.hrBC").value;
        const dtHrBCFormatada = this.formatarDataHoraParaBackend(dataISO, horaValor);

        if (this.form.get("transferencia.tipoTransferencia").value == "entrada") {
          this.sendTransf = {
            agDebtd: this.form.get("transferencia.agencia").value,
            tpCtDebtd: this.form.get("transferencia.tpCtA").value,
            ctDebtd: this.form.get("transferencia.ctA").value,
            cNPJCPFCliDebtd: this.form.get("transferencia.cNPJCPFCliA").value,
            nomCliDebtd: this.form.get("transferencia.nomCliA").value,
            ctCredtd: this.form.get("transferencia.ctB").value,
            vlrLanc: this.form.get("transferencia.vlrLanc").value,
            finlddCli: this.form.get("transferencia.finlddCli").value,
            numCtrlSTR: this.form.get("transferencia.numCtrlSTR").value,
            dtHrBC: dtHrBCFormatada,
            iSPBIFDebtd: this.form.get("transferencia.iSPBIF").value,
            cNPJCPFCliCredtd: this.form.get("transferencia.cNPJCPFCliB").value,
            tipoTransferencia: this.form.get("transferencia.tipoTransferencia").value,
            token: this.form.get("transferencia.token").value
          };
        } else {
          this.sendTransf = {
            agCredtd: this.form.get("transferencia.agencia").value,
            tpCtCredtd: this.form.get("transferencia.tpCtA").value,
            ctCredtd: this.form.get("transferencia.ctA").value,
            cNPJCPFCliCredtd: this.form.get("transferencia.cNPJCPFCliA").value,
            nomCliCredtd: this.form.get("transferencia.nomCliA").value,
            ctDebtd: this.form.get("transferencia.ctB").value,
            vlrLanc: this.form.get("transferencia.vlrLanc").value,
            finlddCli: this.form.get("transferencia.finlddCli").value,
            numCtrlSTR: this.form.get("transferencia.numCtrlSTR").value,
            dtHrBC: dtHrBCFormatada,
            iSPBIFCredtd: this.form.get("transferencia.iSPBIF").value,
            cNPJCPFCliDebtd: this.form.get("transferencia.cNPJCPFCliB").value,
            tipoTransferencia: this.form.get("transferencia.tipoTransferencia").value,
            token: this.form.get("transferencia.token").value
          };
        }
        if (this.sendTransf != null) {
          this.transferenciaService.sensiabilizaTransferencia(this.sendTransf)
            .toPromise()
            .then(response => {
              this.processando = false;
              this.modalFinal("Transferência realizada com sucesso", "Sua transferência ocorreu sem problemas");
            })
            .catch(error => {
              this.processando = false;
              Swal
                .fire({
                  title: 'Atenção',
                  text: error.error.replace("br.com.movvime.exception.BusinessException: ", ""),
                  icon: 'warning',
                  confirmButtonText: 'OK'
                })
                .then(() => {
                  this.verifica.detectChanges();
                });
              console.error(error);
            })
        } else { this.processando = false; }
      } else if (this.form.get("taxas").valid) {
        this.showData = false;

        const dataISO = this.form.get("taxas.dtBC").value.toISOString().split('T')[0];
        const horaValor = this.form.get("taxas.hrBC").value;
        const dtHrBCFormatada = this.formatarDataHoraParaBackend(dataISO, horaValor);


        this.form.get('taxas.dtHrBC').setValue(dtHrBCFormatada);
        this.transferenciaService.sensiabilizaTaxa(this.form.get('taxas').value)
          .toPromise()
          .then(response => {
            this.processando = false;
            this.modalFinal("Taxação realizada com sucesso", "Sua aplicação de taxa ocorreu sem problemas");
          })
          .catch(error => {
            this.processando = false;
            Swal
              .fire({
                title: 'Atenção',
                text: error.error,
                icon: 'warning',
                confirmButtonText: 'OK'
              })
              .then(() => {
                this.verifica.detectChanges();
              });
            console.error(error);
          })
      } else {
        this.processando = false;
        Swal
          .fire({
            title: 'Atenção',
            text: 'Preencha todos os campos obrigatórios antes de continuar',
            icon: 'warning',
            confirmButtonText: 'OK'
          })
          .then(() => {
            this.form.markAllAsTouched();
          })
          .then(() => {
            this.verifica.detectChanges();
          });
      }
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTab = tabChangeEvent.index;
  }


  changeTipoTransf(event: any) {
    this.isEntradaTransf = (event == "entrada")
  }

  changeTipoTaxa(event: any) {
    this.isEntradaTaxa = (event == "entrada")
  }

  fecha(): void {
    this.dialogRef.close();
  }

  modalFinal(titulo, texto) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: texto,
        subtexto: "",
        btnTexto: 'Nova Sensibilização',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.finalizar === true) {
          this.dialogRef.close({
            salvou: true,
          });
        } else if (result.nova === true) {
          this.dialogRef.close({
            nova: true
          });
        }
      }
    });
  }
}
