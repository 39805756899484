<div class="md-modal">
  <div mat-dialog-title class="header-modal">
    <h2 class="titulo-modal">PERSONALIZAÇÃO DE MENSAGENS DE E-MAIL</h2>
    <a (click)="fecha()" class="header-btn-close">
      <span>FECHAR</span>
      <i class="material-icons btn-fechar" id="btn-close">close</i>
    </a>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="card-body">
      <div class="first-container">
        <h4 class="color-title">Editar mensagem do e-mail</h4>
        <div
          [hidden]="!processando"
          class="col-12"
          style="padding: 10px 0 20px 0"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <form>
          <mat-form-field appearance="outline">
            <mat-select
              (selectionChange)="onEmailSelected($event)"
              placeholder="Selecione o e-mail"
            >
              <mat-option
                *ngFor="let mensagemEmail of listMensagemEmail"
                [value]="mensagemEmail.tipoEmail"
              >
                {{ mensagemEmail.tipoEmail }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <quill-editor
            [(ngModel)]="editorContent"
            (onContentChanged)="onEditorChanged($event)"
            name="editor"
            [ngClass]="{ 'border-error': actualLength > 5000 }"
            [maxLength]="MAX_LENGTH"
            [readOnly]="!selectedEmail"
            [placeholder]="
              !selectedEmail
                ? 'Primeiro selecione um e-mail'
                : 'Digite sua mensagem aqui...'
            "
          ></quill-editor>
          <span class="hint" [ngClass]="{ 'hint-error': actualLength > 5000 }"
            >{{ actualLength }}/{{ MAX_LENGTH }}</span
          >
        </form>
      </div>
      <div class="second-container">
        <div class="title-container">
          <h4 class="color-title">Pré-visualização</h4>
          <i
            class="fa-solid fa-info fa-2xs tooltip-icon"
            matTooltip="Aqui você pode visualizar como a mensagem será enviada. 
                      É importante destacar que a mensagem é composta por campos editáveis e não editáveis. 
                      Dessa forma, você pode personalizar somente o texto exibido no editor ao lado esquerdo."
          ></i>
        </div>

        <div [hidden]="processando">
          <div class="preview-header">
            <img src="assets/img/icons/pluga-bank/logos/logo-nome.png" />
            <div class="preview-intro">
              <strong>Olá, NOME DO DESTINATÁRIO</strong>
            </div>
          </div>

          <div class="preview-content-bg">
            <div class="preview-content" #previewRef>
              <div
                *ngIf="previewContent; else noContent"
                class="ql-editor"
                [innerHTML]="previewContent"
              ></div>
              <ng-template #noContent>
                <div class="preview-init">
                  <i class="fa-solid fa-envelope-open-text fa-2xl"></i>
                  <span>Seu e-mail aparecerá aqui</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="preview-footer">
            <p [innerHTML]="footerText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-action-buttons" mat-dialog-actions>
    <button
      [disabled]="processando"
      type="button"
      (click)="fecha()"
      class="btn btn-default"
      mat-raised-button
    >
      Cancelar
    </button>

    <button
      [disabled]="processando || blockSubmit || !selectedEmail"
      type="submit"
      class="btn fundoCorPrimaria"
      (click)="onSubmit()"
      mat-raised-button
    >
      Salvar
    </button>
  </div>
</div>
