import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documento',
  pure: false
})
export class DocumentoPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'contrato-social':
        return 'Contrato/Extrato social';
      case 'comprovante-representantes':
        return 'Comprovante dos Representantes';
      case 'comprovante-identidade':
        return 'Comprovante de identidade';
      case 'comprovante-residencia':
        return 'Comprovante de residência';
      case 'pac':
        return 'Proposta de abertura de conta';
      case 'demonstracao-financeiras':
        return 'Demonstrações Financeiras ou Meios Circulantes';
      case 'declaracao-relacionamento':
        return 'Declaração de Relacionamento de Crédito ';
    }
  }

}
