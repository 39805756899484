import { Inject, Injectable, Optional } from '@angular/core';
import { INgxLoadingConfig, NgxLoadingConfig } from './ngx-loading-config';
export class NgxLoadingService {
public loadingConfig: INgxLoadingConfig;
/**
 * @param {?} config
 */
constructor(
private config: INgxLoadingConfig) {
    this.loadingConfig = this.config || new NgxLoadingConfig();
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: undefined, decorators: [{ type: Optional }, { type: Inject, args: ['loadingConfig', ] }, ]},
];
}

function NgxLoadingService_tsickle_Closure_declarations() {
/** @type {?} */
NgxLoadingService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgxLoadingService.ctorParameters;
/** @type {?} */
NgxLoadingService.prototype.loadingConfig;
/** @type {?} */
NgxLoadingService.prototype.config;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
