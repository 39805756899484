import { PixEndpointService } from './../../../../../service/pix-endpoint.service';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ConfirmarPortabilidadeComponent } from '../../../pix/confirmar-portabilidade/confirmar-portabilidade.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-chaves-pix',
  templateUrl: './chaves-pix.component.html',
  styleUrls: ['./chaves-pix.component.less'],
})
export class ChavesPixComponent implements OnInit {

  form: FormGroup;
  chave: string;
  formTipos: FormGroup;
  isCnpj: boolean = true;

  // Numeração do ENUM do beckend
  cpf = 0;
  cnpj = 1;
  email = 2;
  celular = 3;
  evp = 4;

  //
  cpfCnpj;
  cpfCnpjMask: string;

  mascaraDinamica: any;
  documento: any;

  @Input()
  empresaId: number;
  processando: boolean = false;
  listaChaves: any;
  maskteste: any;

  temCpfCnpj: boolean = false;
  temEmail: boolean = false;
  temCelular: boolean = false;


  constructor(
    private pixService: PixEndpointService,
    private fb: FormBuilder,
    private autenticacao: AutenticacaoEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.processando = true;
    this.buildForm();
    this.listarChaves();
    this.processando = false;

    this.documento = this.autenticacao.regraEmpresa.empresa.pessoa.documento
    this.form.get('cpfCnpj').setValue(this.documento);
    console.log(this.documento)

  }

  buildForm() {
    this.form = this.fb.group({
      celular: ['', [Validators.pattern]],
      cpfCnpj: [''],
      email: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      aleatoria: ['', [Validators.minLength(5)]]
    });
  }

  public getErrorMessage(field: string) {
    if (this.form.get(field)) {
      const control = this.form.get(field);
      if (control.hasError('pattern')) {
        if (field === 'email') {
          return 'Email inválido';
        } else if (field === 'celular') {
          return 'Telefone inválido';
        } else if (field === 'cpfCnpj') {
          return 'CPF/CNPJ inválido';
        }
      }
    }
    return 'Campo inválido';
  }

  validaTelefone(tel: FormControl) {
    if (tel && tel.value) {
      if (tel.value?.length === 11) {
        tel.setErrors(null);
        tel.clearAsyncValidators();
      } else {
        tel.setErrors({ valid: false });
      }
    }
  }

  buildChavePix(chave, tipoChave) {
    const chavePix = {
      chave: chave,
      tpChave: tipoChave,
    }
    return chavePix;
  }


  async cadastrarPix(tipo) {
    try {
      this.processando = true;
      let objetoChavePix;

      if (this.form.get('cpfCnpj').value?.length > 11 || this.documento?.length > 11) {
        objetoChavePix = this.buildChavePix(this.form.get('cpfCnpj').value, this.cnpj);
      } 
      
      if (this.form.get('cpfCnpj').value?.length == 11 || this.documento?.length == 11) {
        objetoChavePix = this.buildChavePix(this.form.get('cpfCnpj').value, this.cpf);
      }

      if (tipo == this.celular) {
        objetoChavePix = this.buildChavePix(this.form.get('celular').value, this.celular);
      }

      if (tipo == this.email) {
        objetoChavePix = this.buildChavePix(this.form.get('email').value, this.email);
      }

      let existeChave = false;

      try {
        existeChave = await this.pixService.consultarChave(objetoChavePix.chave, objetoChavePix.tpChave).toPromise();
      } catch (err) {
        existeChave = false; // Garantindo que existeChave é false em caso de erro
      }

      if (existeChave) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Chave já cadastrada em outro banco, deseja fazer a portabilidade?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            const dialog = this.dialog.open(ConfirmarPortabilidadeComponent, {
              data: {
                data: existeChave,
                objetoChavePix: objetoChavePix,
                tipo: 'solicitar'
              },
              width: '581px',
              height: '798px',
            })
          }
        });
      }

      if (!existeChave) {
        const res = await this.pixService.criarChave(objetoChavePix).toPromise();
        Swal.fire({
          title: 'Sucesso!',
          text: 'Seu pedido de criação de chave foi enviado com sucesso! Ele está sendo processado e será atualizado aqui em breve.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: 'Erro!',
        text: err.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

    } finally {
      this.form.reset();
      this.listarChaves();
      this.processando = false;
    }
  }


  async cadastrarChaveAleatoria() {
    this.processando = true;
    try {
      const   obj = {
        tpChave: this.evp,
      }

      const res = await this.pixService.criarChave(obj).toPromise();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Chave Pix cadastrada com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      this.listarChaves();
      this.processando = false;
      this.changeDetectorRef.detectChanges();

    } catch (err) {
      console.log(err);
      Swal.fire({
        title: 'Erro!',
        text: err.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });

    } finally {
      this.form.reset();
      this.listarChaves();
      this.processando = false;
    }
  }



  copiarChave(link) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Chave Pix copiada!", '', {
      positionClass: "toast-top-center",
      timeOut: 2000
    })
  }

  async listarChaves() {
    try {
      this.processando = true;
      const res = await this.pixService.listarChaves(this.empresaId).toPromise();
      this.listaChaves = res.map(chave => ({
        ...chave,
        chaveFormatada: this.formatarChaveComMascara(chave.chave, this.getMask(chave.tpChave))
      }));
      this.processando = false;

      this.verificaSeTemAChave();
      this.changeDetectorRef.detectChanges();


    } catch (err) {
      console.log(err);
    }
  }

  verificaSeTemAChave() {
    this.listaChaves.forEach(chave => { 
      if(chave.tpChave == 0 || chave.tpChave == 1){
        this.temCpfCnpj = true;
      }
      if(chave.tpChave == 2){
        this.temEmail = true;
      }
      if(chave.tpChave == 3){
        this.temCelular = true;
      }
    });
  }

  excluirChave(param) {
    try {
      this.processando = true;
      const res = this.pixService.desativarChave(param).toPromise();

      if (res) {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Chave Pix excluída com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: 'Erro!',
        text: err.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      this.listarChaves();
      this.processando = false;
    }
    this.listarChaves();
    this.changeDetectorRef.detectChanges();
  }

  transformTipoPix(param) {
    switch (param) {
      case 0:
        return 'CPF';
      case 1:
        return 'CNPJ';
      case 2:
        return 'Email';
      case 3:
        return 'Celular';
      case 4:
        return 'Aleatória';
    }
  }


  formatarChaveComMascara(chave: string, mask: string): string {
    if (!mask || mask.length === 0) {
      return chave;
    }

    let result = '';
    let chaveIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        if (chaveIndex === chave.length) {
          break;
        }
        result += chave[chaveIndex];
        chaveIndex++;
      } else {
        result += mask[i];
      }
    }

    return result;
  }

  getMask(tpChave: number): string {
    switch (tpChave) {
      case 0: // CPF
        return '000.000.000-00';
      case 1: // CNPJ
        return '00.000.000/0000-00';
      case 3: // Telefone
        return '000 00 00000-0000';
      default:
        return ''; // Sem máscara
    }
  }


}
