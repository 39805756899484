<div class="col-12 text-center">
    <h3>{{value?.de?.getFullYear()}}</h3>
</div>
<div class="col-12">
    <mat-button-toggle-group class="d-flex justify-content-between w-100" [value]="value?.de?.getMonth()"
        [appearance]="appearance" (change)="onToggleGroupChange($event)">
        <mat-button-toggle [value]="-1">
            <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
        </mat-button-toggle>
        <mat-button-toggle *ngFor="let mes of meses; let i = index" [value]="i">{{mes}}</mat-button-toggle>
        <mat-button-toggle [value]="meses.length + 1">
            <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>