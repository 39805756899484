import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ObjectUtilsService {

    isNull(value: any) {
        return [null, undefined].indexOf(value) >= 0;
    }

    isNotNull(value: any) {
        return !this.isNull(value);
    }

    isAnyNull(...values: any[]) {
        for (const value of values) {
            if (this.isNull(value)) {
                return true;
            }
        }
        return false;
    }

    isAnyNonNull(...values: any[]) {
        for (const value of values) {
            if (this.isNotNull(value)) {
                return true;
            }
        }
        return false;
    }

    isAllNull(...values: any[]) {
        for (const value of values) {
            if (this.isNotNull(value)) {
                return false;
            }
        }
        return true;
    }

    isAllNotNull(...values: any[]) {
        for (const value of values) {
            if (this.isNull(value)) {
                return false;
            }
        }
        return true;
    }
}
