import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class SuporteContatoEndpointService {
  constructor(private client: HttpClient) { }

  cadastrarItem(item) {
    const url = '/suporte-contato/criar';
    return this.client.post(url, item);
  }

  getAll() {
    const url = '/suporte-contato/pegar-todos';
    return this.client.get(url);
  }

  desativar(descricao) {
    const url = `/suporte-contato/desativar?descricao=${descricao}`;
    return this.client.delete(url);
  }

  atualizaOrdem(item) {
    const url = '/suporte-contato/atualizar-ordem';
    return this.client.post(url, item);
  }
}