<div class="row" style="justify-content: center">
  <div
    id="upload-disabled{{ index }}"
    class="col-8 col-md-8 arquivo-box"
    appDragDrop
    (fileDropped)="onFileDropped($event)"
    style="margin-right: 20px; cursor: pointer; z-index: 999"
    (click)="idRef.click()"
  >
    <div style="pointer-events: none">
      <input
        hidden
        type="file"
        accept="image/*,.pdf,.docx,.doc"
        #idRef
        (change)="onSelectFile($event)"
      />
      <i class="fas fa-cloud-arrow-up"></i>
      <p>
        <label [for]="idRef" style="font-weight: bold; cursor: pointer"
          >Clique ou arraste seu arquivo aqui</label
        >
      </p>
      <span *ngIf="subTitle">{{ subTitle }}</span>
    </div>
  </div>
  <div
    [class]="
      disabled || form?.value?.length > 0
        ? 'disabled-upload col-3 col-md-3 arquivo-lista-box'
        : 'col-3 col-md-3 arquivo-lista-box'
    "
  >
    <ng-template [ngIf]="requiredText">
      <p *ngIf="form.value.length == 0">{{ requiredText }}</p>
    </ng-template>
    <ng-template [ngIf]="!requiredText">
      <p *ngIf="form.value.length == 0">Nenhum arquivo adicionado.</p>
    </ng-template>
    <div *ngIf="form.value.length > 0">
      <div class="arquivo-row" *ngFor="let file of form.value; index as i">
        <i class="far fa-file-alt" id="file-icon"></i>
        <a
          id="arquivo-title"
          [href]="file.linkDownload"
          download
          target="_blank"
          [matTooltip]="file.nome"
          >{{ file.nome || '' }}</a
        >
        <i (click)="deleteFile(file)" class="fas fa-trash" id="trash-icon"></i>
      </div>
    </div>
  </div>
</div>
