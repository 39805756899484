import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

@Component({
  selector: 'app-autenticacao',
  templateUrl: './autenticacao.component.html',
  styleUrls: ['./autenticacao.component.less']
})
export class AutenticacaoComponent implements OnInit {
  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  alterar = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.data) {
      this.data.telefones = this.data.telefoneSeguranca;
      if ( this.data.telefones != [] &&  this.data.telefones != null) {
        this.form.controls["telefoneSeguranca"].disable();
      }
    }
  

    this.data = this.data || {
      agendarTransferencias: true,
      alterarContasBancarias: true,
      alterarDadosComerciais: true
    };

    this.form.addControl('agendarTransferencias', new FormControl(this.data.agendarTransferencias));
    this.form.addControl('alterarContasBancarias', new FormControl(this.data.alterarContasBancarias));
    this.form.addControl('alterarDadosComerciais', new FormControl(this.data.alterarDadosComerciais));
  }

  getErrorMessage(field) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError('required')
        ? 'Campo requerido'
        : this.form.get(field).hasError('minlength')
        ? 'Campo inválido'
        : '';
    }
  }

  showInput() {
    this.alterar = !this.alterar;
  }

  setarToggle(formName){
    switch(formName){
      case 'agendar-transferencias':
        if(this.data.agendarTransferencias){
          this.data.agendarTransferencias = false;
          this.form.get("agendarTransferencias").patchValue(false);
        }else{
          this.data.agendarTransferencias = true;
          this.form.get("agendarTransferencias").patchValue(true);
        }
        break;
      case 'alterar-conta-bancaria':
        if(this.data.alterarContasBancarias){
          this.data.alterarContasBancarias = false;
          this.form.get("alterarContasBancarias").patchValue(false);
        }else{
          this.data.alterarContasBancarias = true;
          this.form.get("alterarContasBancarias").patchValue(true);
        }
        break;
      case 'alterar-dados-comerciais':
        if(this.data.alterarDadosComerciais){
          this.data.alterarDadosComerciais = false;
          this.form.get("alterarDadosComerciais").patchValue(false);
        }else{
          this.data.alterarDadosComerciais = true;
          this.form.get("alterarDadosComerciais").patchValue(true);
        }
        break;
    }
  }
}
