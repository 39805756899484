<div class="row">
  <div class="col-12">
    <div class="row alinha">

      <div class="col-12">
        <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">SITUAÇÃO CADASTRAL
        </h4>
        <p style="font-size: 13px">Confira abaixo as pendências para a validação do seu cadastro:
        </p>
      </div>

      <div class="col-12">
        <div class="row linha" style="padding: 8px; margin: 0;margin-bottom: 10px;">
          <div class="col-12" style="padding-right: 4px;padding-left:4px;">
            <div class="btn-cadastro"
              [ngClass]="analiseCadastro.aprovacaoUsuario ? 'btn-cadastro-completo' :'btn-cadastro-pendente'">
              <div class="row" style="align-items: center;">
                <div class="col-2">
                  <i class="fas fa-user"></i>
                </div>
                <div class="col-6">
                  <p>Usuário</p>
                  <div *ngIf="analiseCadastro.aprovacaoUsuario">
                    <p class="aprovado">Aprovado</p>
                  </div>
                  <div *ngIf="!analiseCadastro.aprovacaoUsuario">
                    <p class="reprovado">Em análise</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-right: 4px;padding-left:4px;">
            <div class="btn-cadastro"
              [ngClass]="analiseCadastro.envioDocumentacaoEmpresa ? 'btn-cadastro-completo' :'btn-cadastro-pendente'">
              <div class="row" style="align-items: center;">
                <div class="col-2">
                  <i class="fas fa-file"></i>
                </div>
                <div class="col-6">
                  <p>Documento da empresa</p>
                  <div *ngIf="analiseCadastro.envioDocumentacaoEmpresa">
                    <p class="aprovado">Aprovado</p>
                  </div>
                  <div *ngIf="!analiseCadastro.envioDocumentacaoEmpresa">
                    <p class="reprovado">Em análise</p>
                  </div>
                </div>
                <div class="col-3">
                  <div *ngIf="analiseCadastro.envioDocumentacaoEmpresa">
                    <button type="button" class="btn-secundario btn btn-block "
                      (click)="abrir(dadosEmpresa,true,'empresa','Documento da empresa')"
                      mat-raised-button>
                      Visualizar
                    </button>
                  </div>
                  <div *ngIf="!analiseCadastro.envioDocumentacaoEmpresa">
                    <button type="button" class="btn-secundario btn btn-block "
                      (click)="abrir(dadosEmpresa,true,'empresa','Documento da empresa')"
                      mat-raised-button>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-right: 4px;padding-left:4px;">
            <div class="btn-cadastro"
              [ngClass]="analiseCadastro.envioDocumentacaoRepresentantesLegais ? 'btn-cadastro-completo' :'btn-cadastro-pendente'">
              <div class="row" style="align-items: center;">
                <div class="col-2">
                  <i class="fas fa-file"></i>
                </div>
                <div class="col-6">
                  <p>Documento dos representante legais</p>
                  <div *ngIf="analiseCadastro.envioDocumentacaoRepresentantesLegais">
                    <p class="aprovado">Aprovado</p>
                  </div>
                  <div *ngIf="!analiseCadastro.envioDocumentacaoRepresentantesLegais">
                    <p class="reprovado">Em análise</p>
                  </div>
                </div>
                <div class="col-3">
                  <div *ngIf="analiseCadastro.envioDocumentacaoRepresentantesLegais">
                    <button type="button" class="btn-secundario btn btn-block"
                      (click)="abrir(dadosEmpresa,true,'representantes','Documento dos representante legais')"
                      mat-raised-button>
                      Visualizar
                    </button>
                  </div>
                  <div *ngIf="!analiseCadastro.envioDocumentacaoRepresentantesLegais">
                    <button type="button" class="btn-secundario btn btn-block "
                      (click)="abrir(dadosEmpresa,true,'representantes','Documento dos representante legais')"
                      mat-raised-button>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="padding-right: 4px;padding-left:4px;">
            <div class="btn-cadastro"
              [ngClass]="analiseCadastro.aprovacaoEmpresa ? 'btn-cadastro-completo' :'btn-cadastro-pendente'">
              <div class="row" style="align-items: center;">
                <div class="col-2">
                  <i class="fas fa-building"></i>
                </div>
                <div class="col-6">
                  <p>Empresa</p>
                  <div *ngIf="analiseCadastro.aprovacaoEmpresa">
                    <p class="aprovado">Aprovada</p>
                  </div>
                  <div *ngIf="!analiseCadastro.aprovacaoEmpresa">
                    <p class="reprovado">Em análise</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>