import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ArquivoDTO } from '@model/sistema/arquivo-dto.model';
import moment from 'moment';

let utilCaniveteService: any;

@Injectable({
	providedIn: 'root'
})
export class UtilCaniveteService implements OnInit {

	PADRAO_MOMENT_FORMATO_DATA = 'YYYY-MM-DD';
	PADRAO_MOMENT_FORMATO_DATA_HORA = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

	constructor(
		@Inject(LOCALE_ID) private locale: string) {
	}

	ngOnInit(): void {
		utilCaniveteService = this;
	}

	customFilter(value: any, search: string, deep = 0): boolean {
		if (deep === 3) {
			// Gambiarra para parar a pesquisa recursiva
			return false;
		}
		search = (search || '').toLowerCase();
		value = value || {} as any;
		if (value instanceof Array) {
			return !!value.filter(v => this.customFilter(v, search, deep + 1)).length;
		} else if (value instanceof Date) {
			return moment(value).format('DD/MM/YYYY').indexOf(search) >= 0;
		} else if (typeof value === 'string') {
			if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}(T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{3}(Z|\-[0-9]{2}:[0-9]{2})?)?)?$/gim.exec(value)) {
				return moment(value).format('DD/MM/YYYY').indexOf(search) >= 0;
			}
			return value.toLowerCase().indexOf(search) >= 0;
		} else if (typeof value === 'boolean') {
			return ['true', 'false', 'sim', 'nao', 'não'].join('').indexOf(search) >= 0;
		} else if (typeof value === 'number') {
			const realSemSigla = formatCurrency(value, this.locale, '');
			const realSigla = formatCurrency(value, this.locale, 'R$');
			const normal = formatNumber(value, this.locale);
			return [realSemSigla, realSigla, normal, `${value}`].join('').indexOf(search) >= 0;
		} else if (typeof value === 'object') {
			return !!Object.keys(value).filter(v => this.customFilter(value[v], search, deep + 1)).length;
		}
		return false;
	}

	generateColor() {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	arrayMove(array: any[], x: any, y: any) {
		const b = array[x];
		array[x] = array[y];
		array[y] = b;
	}

	getIndice(valor: number, campo: string, array: Array<any>): number {
		let indice = -1;
		array.some((item: any, index: number) => {
			if (item[campo] === valor) {
				indice = index;
				return true;
			}
		});
		return indice;
	}

	formatarValor(value) {
		if (value && !isNaN(value)) {
			value = value.toLocaleString('pt-BR', {
				minimumFractionDigits: 2
			});
			return ['', value, ''].join('');
		} else {
			return value;
		}
	}

	formatarData(value) {
		return moment(value).format('DD/MM/YYYY');
	}

	encurtar(value: string, tamanho: number, replace: string = '...') {
		value = value || '';
		tamanho = tamanho || 10;

		let result = '';
		for (let count = 0; count < tamanho; count++) {
			if (value[count]) {
				result += value[count];
			} else {
				break;
			}
		}
		if (result.length < value.length) {
			result += replace;
		}
		return result;
	}

	customMatTableFilter<T>(params?: { excluirCampos?: string[], recursivo?: boolean }): (element: T, query: string) => boolean {
		params = params || {};
		params.excluirCampos = params.excluirCampos || [];
		params.recursivo = params.recursivo || false;

		return (element: T, query: string): boolean => {
			if (query && element) {
				const reg = query.split(/[^0-9a-zA-Z]/gim);
				const colunas = Object.keys(element).filter((value) => !params.excluirCampos.includes(value));
				for (const key of colunas) {
					const campo: any = element[key];
					if (typeof campo === 'string' && campo.match(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}$/gm)) {
						try {
							const validoData = formatDate(new Date(campo), 'short', this.locale).startsWith(query);
							if (validoData) {
								return true;
							}
						} catch (error) { }
					} else if (typeof campo === 'number' && (query.startsWith('R$') || query.startsWith('-R$') || !query.match(/[a-zA-Z]/gim))) {
						try {
							const queryClean = query.replace(/[^0-9]/gim, '');
							if (queryClean) {
								let validoNumero = campo.toFixed(2).replace(/[^0-9]/gim, '').startsWith(queryClean);
								validoNumero = validoNumero || campo.toString().replace(/[^0-9]/gim, '').startsWith(queryClean);
								if (validoNumero) {
									return true;
								}
							}
						} catch (error) { }
					} else if (typeof campo === 'string') {
						let validoOutros = true;
						for (const regex of reg) {
							const match = `${campo}`.match(new RegExp(regex, 'gim'));
							validoOutros = validoOutros && match && match.length > 0;
							if (!validoOutros) {
								break;
							}
						}
						if (validoOutros) {
							return true;
						}
					} else {
						if (params.recursivo) {
							const validoOutros = this.customMatTableFilter(params)(campo, query);
							if (validoOutros) {
								return true;
							}
						}
					}
				}
			}
			return false;
		};
	}

	formatarCpfCnpj(value: string) {
		if (value) {
			value = value.replace(/[^0-9]+/gim, '');
			if (value.length <= 11) {
				return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})(.*)/, '$1.$2.$3-$4$5');
			}
			return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})(.*)/, '$1.$2.$3/$4-$5$6');
		}
		return value;
	}

	parseToData(data: any) {
		if (moment.isMoment(data)) {
			return data.format(this.PADRAO_MOMENT_FORMATO_DATA);
		}
		if (moment.isDate(data)) {
			return moment(data).format(this.PADRAO_MOMENT_FORMATO_DATA);
		}
		return data;
	}

	parseToDataHora(data: any) {
		if (moment.isMoment(data)) {
			return data.format(this.PADRAO_MOMENT_FORMATO_DATA_HORA);
		}
		if (moment.isDate(data)) {
			return data.toISOString();
		}
		return data;
	}

	clipboard(text: string) {
		const el = document.createElement('textarea');
		el.value = text;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	}

	updateAllValueAndValidity(form: AbstractControl) {
		if (form instanceof FormControl) {
			form.updateValueAndValidity();
		} else if (form instanceof FormArray) {
			form.controls.forEach(v => {
				this.updateAllValueAndValidity(v);
			});
		} else if (form instanceof FormGroup) {
			Object.keys(form.controls)
				.forEach(key => {
					const v = form.get(key);
					this.updateAllValueAndValidity(v);
				})
		}
	}

	downloadFile(base64: string, filename = 'file.bin') {
		var a = document.createElement(`a`); //Create <a>
		a.href = `data:image/png;base64,${base64}`; //Image Base64 Goes here
		a.download = filename; //File name Here
		a.click(); //Downloaded file
	}

	downloadFileXlsx(base64: string, filename = 'file.xlsx') {
		const a = document.createElement('a'); // Create <a> element
		a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`; // Set href to base64 for .xlsx
		a.download = filename; // Set the file name
		a.click(); // Trigger the download
	}
	

	async parseFiles(files: Array<File>) {
		if (files && files.length > 0) {
			const proms = [] as Promise<ArquivoDTO>[];
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				proms.push(this.parseFile(file));
			}
			const arquivos = await Promise.all(proms);
			return arquivos;
		}
		throw new Error('Necessário informar os arquivos para conversão');
	}

	parseFile(file: File) {
		return new Promise<ArquivoDTO>((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const arquivo = {} as ArquivoDTO;
				arquivo.tamanho = `${file.size || 0}`;
				arquivo.mimeType = `${file.type || 'application/octet-stream'}`;
				arquivo.nome = `${file.name || 'file.bin'}`;
				arquivo.locale = `UTF-8`;
				if (typeof reader.result === 'string') {
					arquivo.base64 = reader.result;
				} else {
					arquivo.base64 = this.bufferToBase64(reader.result);
				}
				resolve(arquivo);
			}
			if (file) {
				reader.readAsArrayBuffer(file);
			} else {
				reject(new Error('Dados do arquivo não informado'));
			}
		});
	}

	bufferToBase64(buffer: ArrayBuffer) {
		return btoa(
			new Uint8Array(buffer)
				.reduce((data, byte) => data + String.fromCharCode(byte), '')
		);
	}

	duracao(a: Date, b: Date) {
		let distance = Math.abs(a.getTime() - b.getTime());
		let texto = [] as string[];
		const possibilidades = {
			dias: 24 * 60 * 60 * 1000,
			horas: 60 * 60 * 1000,
			min: 60 * 1000,
			seg: 1000
		}
		for (const [key, contagem] of Object.entries(possibilidades)) {
			if (distance >= contagem) {
				const multiplicador = Math.floor(distance / contagem);
				texto.push(`${multiplicador} ${key}`);
				distance -= (contagem * multiplicador);
			}
		}
		return texto.join(' ');
	}

	getErrorMessage(form: AbstractControl) {
		if (form && form.invalid) {
			const { errors } = form;
			if (errors.required) {
				return 'Campo requrido';
			}
		}
	}

	capitalizeFirstLetter(str) {
		const texto = str.toLowerCase();
		return texto.charAt(0).toUpperCase() + texto.slice(1);
	}

	capitalizeFirstLetterOfEachWord(str: string): string {
		return str.replace(/\s+/g, ' ')
		  .split(' ')
		  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		  .join(' ');
	}

	openPdfFile(base64: string, nome: string){
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'application/pdf' });
		const url = URL.createObjectURL(blob);

		const headers = new Headers();
		headers.append('Content-Disposition', 'inline; filename='+nome+'.pdf');
		const options = {
			headers,
		}

		fetch(url, options)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				window.open(url, '_blank');
			})
	}

}
