import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.less']
})
export class EmailComponent implements OnInit {
  temPrincipal = false;
  contador = 0;
  @Input()
  public form: FormArray;
  @Input()
  public data: any;
  @Input() telaContato = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.data && this.data.emails && this.data.emails.length) {
      this.data.emails.forEach(element => this.add(element));
    } else if (this.form.controls.length === 0) {
      this.add();
			this.form.controls[0].get('principal').setValue(true);
			this.form.controls[0].get('principal').enable();
    }

    this.temPrincipal = true;
  }

  public add(email?: any) {
    email = email || '';

    if (email.principal === true && this.temPrincipal === false) {
      this.changeContaPrincipal();
    }

    this.form.push(
      this.fb.group({
          principal: this.fb.control(
            {
              value: email.principal || false,
              disabled:
                email.principal === true || this.temPrincipal === false
                  ? false
                  : true
            },
            [Validators.required]
          ),
          id: this.fb.control(email.id || null),
          email: this.fb.control(email.email, [Validators.required, Validators.email])
        },
        {
          validator: this.obrigatorioTerPrincipal('principal')
        }
      )
    );

    this.contador++;
  }

  obrigatorioTerPrincipal(principal) {
		return (group: FormGroup) => {
			const principalInput = group.controls[principal];

			let temPrincipal = false;
			this.form.controls.forEach(element => {
				if (element.get('principal').value === true) {
					temPrincipal = true;
					return principalInput.setErrors(null);
				}
			});
			if (temPrincipal === false) {
				return principalInput.setErrors({ obrigatorio: true });
			}
		};
	}

	public del(index) {
		if (this.form.controls[index].get('principal').value === true) {
			this.changeContaPrincipal();
		}
    this.form.removeAt(index);
    this.contador--;
  }

  public esvazia() { // esvazia até sobrar apenas 1
		while (this.form.length > 1) { 
      this.form.removeAt(1);
      this.contador--;
    }
	}
  
  changeContaPrincipal(event?) {
		this.temPrincipal = !this.temPrincipal;
		if (this.temPrincipal === true) {
			this.form.controls.forEach(element => {
				if (element.get('principal').value === false) {
					element.get('principal').disable();
				}
			});
		} else {
			this.form.controls.forEach(element => {
				element.get('principal').enable();
			});
		}
	}

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('email')
        ? 'Email inválido'
        : form.get(field).hasError('obrigatorio')
				? 'Deve haver um principal'
        : '';
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }
}
