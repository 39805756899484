import { GraficoFundoDTO } from './../model/financeiro/investimento/grafico-fundo-dto.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvestimentoEndpointService {
    constructor(private client: HttpClient) { }

    listar(empresaId, order, page, size, q): Observable<any> {
        let params = new HttpParams();
        if (order) {
            params = params.append('order', `${order}`);
        }
        if (page >= 0) {
            params = params.append('page', `${page}`);
        }
        if (size >= 0) {
            params = params.append('size', `${size}`);
        }
        if (q) {
            params = params.append('q', `${q}`);
        }
        const url = `/investimento/listar/${empresaId}`;
        let teste = this.client.get<any>(url, {params});
        return teste;
    }

    listarFundos(empresaId, order, page, size, q, root): Observable<any> {
        let params = new HttpParams();
        if (order) {
            params = params.append('order', `${order}`);
        }
        if (page >= 0) {
            params = params.append('page', `${page}`);
        }
        if (size >= 0) {
            params = params.append('size', `${size}`);
        }
        if (q) {
            params = params.append('q', `${q}`);
        }
        params = params.append('root', `${root}`)
        const url = `/investimento/listar-fundos/${empresaId}`;
        let teste = this.client.get<any>(url, {params});
        return teste;
    }

    resgatar(empresaId, fundoId, valor, token, resgateTotal){
        const body: any = {
            empresaId: empresaId,
            fundoId: fundoId,
            valor: valor,
            token: token
        }
        if(resgateTotal) body.resgateTotal = true
        const url = '/investimento/resgatar';
        return this.client.post(url, body);
    }
    
    getDocumentos(): Observable<any>{
        const url = '/investimento/documentos'
        return this.client.get<any>(url);
    }

    getDocumentosFundo(fundoId: number): Observable<any>{
        const url = `/investimento/documentos-fundo/${fundoId}`
        return this.client.get<any>(url);
    }

    atualizarFundo(fundo){
        const url = '/investimento/atualizar-fundo';
        return this.client.post<any>(url, fundo);
    }

    desativarFundo(fundoId){
        const url = `/investimento/desativar-fundo/${fundoId}`;
        return this.client.delete<any>(url);
    }

    salvaArquivoFundo(arquivo): Observable<any>{
        const url = "/investimento/salvar-arquivo-fundo"
        return this.client.post<any>(url, arquivo);
    }

    deleteArquivoFundos(arquivoFundoId): Observable<any>{
        const url = `/investimento/deleta-arquivo-fundo/${arquivoFundoId}`;
        return this.client.get<any>(url);
    }

    getArquivosFundos(fundoId, order, page, size, q): Observable<any>{
        let params = new HttpParams();
        if (order) {
            params = params.append('order', `${order}`);
        }
        if (page >= 0) {
            params = params.append('page', `${page}`);
        }
        if (size >= 0) {
            params = params.append('size', `${size}`);
        }
        if (q) {
            params = params.append('q', `${q}`);
        }
        const url = `/investimento/get-arquivos-fundo/${fundoId}`;
        return this.client.get<any>(url, {params});
    }

    getAnalisePerfil(empresaId: number): Observable<any>{
        const url = `/investimento/get-analise/${empresaId}`
        return this.client.get<any>(url);
    }

    cadastrarGraficoFundo(grafico: GraficoFundoDTO): Observable<any>{
        const url = '/investimento/grafico-fundo'
        return this.client.post<any>(url, grafico)
    }

    getGraficoFundo(fundoId: number): Observable<GraficoFundoDTO>{
        const url = `/investimento/grafico-fundo/${fundoId}`
        return this.client.get<any>(url)
    }

    adicionarColaborador(nroColaborador: string, fundoId: number): Observable<any> {
        const url = '/investimento/adicionar-colaborador'
        let params = new HttpParams();
    
        params = params.append('nroColaborador', `${nroColaborador}`);
        params = params.append('fundoId', `${fundoId}`);
        return this.client.post(url, null, { params });
    }

    removerColaborador(nroColaborador: string, fundoId: number): Observable<any> {
        const url = '/investimento/remover-colaborador'
        let params = new HttpParams();
    
        params = params.append('nroColaborador', `${nroColaborador}`);
        params = params.append('fundoId', `${fundoId}`);
        return this.client.post(url, null, { params });
    }

    cadastrarTipoInvestidor(body){
        return this.client.post('/investimento/cadastrar-tipo-investidor', body)
    }

    valorDisponivelResgate(documento: string, documentoFundo: string, tempoCarencia: number): Observable<number>{
        let params = new HttpParams();
        params = params.append('documento', documento);
        params = params.append('documentoFundo', documentoFundo);
        params = params.append('tempoCarencia', `${tempoCarencia}`)
        
        const url = '/investimento/valor-disponivel-resgate';
        return this.client.get<number>(url, {params});
    }
}