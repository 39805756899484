<h2 mat-dialog-title class="corPrimaria" style="font-weight: 600; text-align: center">Confirmar Recebimento em Dinheiro</h2>
<div mat-dialog-content class="espacamento">
  <form [formGroup]="form" class="box-body input-size">
    <mat-form-field appearance="outline">
      <mat-label>Valor recebido: </mat-label>
      <input matInput currencyMask type="text" onclick="this.setSelectionRange(-1,-1);"
        onfocus="this.setSelectionRange(-1,-1);" formControlName="valor"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
      <!-- <mat-error>{{ getErrorMessage(firstForm, "dataVencimento") }}</mat-error> -->
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Data do Recebimento: </mat-label>
      <input matInput [matDatepicker]="picker1" formControlName="dataPagamento">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
      <!-- <mat-error>{{ getErrorMessage(firstForm, "dataVencimento") }}</mat-error> -->
    </mat-form-field>
    <br />

    <div class="row" style="justify-content: center;">
      <mat-checkbox formControlName="notificarCliente">
        Notificar meu cliente sobre o recebimento desta cobrança</mat-checkbox>
    </div>
    <br />
    <p style="font-size: 13px; font-weight: 400;">Atenção! O Recebimento em dinheiro desta cobrança não gera saldo
      no {{env.nomeApp}}.</p>
      
  <i class="fas fa-info-circle info-icon" matTooltip="Não serão mais enviadas notificações" style="margin-left: 95%;"></i>
  </form>
</div>
<div mat-dialog-actions style="justify-content: center">
  <div [hidden]="!processando" class="col-12" style="padding-bottom: 20px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="col-12 col-md-6">
    <button [disabled]="!form.valid" (click)="enviar()" class="btn btn-block fundoCorPrimaria" 
      mat-raised-button>
      Confirmar
    </button>
  </div>
</div>