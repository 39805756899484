export interface Environment {
  production: boolean;
  nomeApp: string;
  plugaBank: boolean;
  codAgencia: string;
  codBanco: string;
  empresa: {
    descricao: string;
    jargao: string;
    nomeFantasia: string;
    razaoSocial: string;
    ispb: number;
    nomeAbrevidado: string;
    cnpj: string;
    nomeIF: string;
    cnpjIF: string;
    endereco: {
      tipoLogradouro: string;
      logradouro: string;
      numero: string;
      complemento: string;
      bairro: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
    };
    enderecoDTVM: {
      tipoLogradouro: string;
      logradouro: string;
      numero: string;
      complemento: string;
      bairro: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
    };
    email: {
      contato: string;
      suporte: string;
      comercial: string;
      ouvidoria: string;
    };
    telefone: {
      contato: string;
      suporte: string;
      comercial: string;
      ouvidoria: string;
    }
    gerente: {
      nome: string;
      cargo: string;
      telefone: string;
      email: string;
    };
    suporteHorarioAtendimento: string;
  };
  url: {
    appSite: string;
    lojaAppAndroid: string;
    lojaAppApple: string;
    webSite: string;
    backend: string;
    termosContratos: string,
    termoUso: string
    openBanking: string,
    planosPrecos: string,
    centralAjuda: string,
    blog: string,
    readme: string,
    envioOperacoes: string,
    ajudaAntecipacao: string,
    ajudaCobranca: string,
    ajudaContatos: string,
    ajudaPagamento: string,
    ajudaTransferencia: string
  };
  google: {
    captcha: string;
  };
  configCobranca: {
    cip: boolean,
    santander: boolean,
    cipBoletoPix: boolean,
    cipBoletoBancario: boolean,
    cipCartaoCredito: boolean,
  };
  modulos: {
    multiagencias: boolean;
    empresa: {
      multiEmpresas: boolean;
    };
    cobranca: {
      sidebar: boolean;
      avulsa: boolean;
      assinatura: boolean;
      parcelada: boolean;
      importacaoCnab: boolean;
      protesto: boolean;
    };
    notificacao: {
      sms: boolean;
      email: boolean;
    };
    pagamento: {
      boletoPix: boolean;
      boletoBancario: boolean;
      cartaoCredito: boolean;
      importacaoCnab: boolean;
    };
    desconto: {
      opcoesDesconto: boolean;
    };
    arquivo: {
      anexarArquivo: boolean;
    };
    fiscal: {
      notaFiscal: boolean;
    };
    pagamentoContas: {
      sidebar: boolean;
      boleto: boolean;
      arrecadacao: boolean;
    };
    extrato: {
      sidebar: boolean;
      ofx: boolean;
      xls: boolean;
      pdf: boolean;
      csv: boolean;
      informeRendimentos: boolean;
      cartaCircularizacao: boolean;
    };
    transferencia: {
      sidebar: boolean;
      ted: boolean;
      interna: boolean;
      p2p: boolean;
      agendamento: boolean;
      sensibilizacao: boolean;
      sensibilizacaoPassado: boolean;
      lote: boolean;
      alcada: boolean;
      importacaoCnab : boolean;
    };
    cartaoCredito: {
      sidebar: boolean;
      prePago: boolean;
      saldoUnificado: boolean;
    };
    credito: {
      sidebar: boolean,
    };
    investidor: {
      sidebar: boolean;
      extratoInvestidor: boolean;
      listaFundos: boolean;
      investimentos: boolean;
    };
    antecipacao: {
      sidebar: boolean;
      boleto: boolean;
      faturaCartao: boolean;
      xmlRecebiveis: boolean;
      consignado: boolean;
    };
    convite: {
      indicarAmigos: boolean;
    };
    suporteContato: {
      termosContratos: boolean;
      planosPrecos: boolean;
      centralAjuda: boolean;
      relacionamento: boolean;
      blog: boolean;
      envioOperacoes: boolean;
    };
    contabilidade: {
      tesouraria: boolean;
    };
    tipoConta: {
      digitals: boolean;
      correnteDigital: boolean;
      vinculadaDigital: boolean;
      escrowDigital: boolean;
      bloqueioJudicial: boolean;
      salarioDigital: boolean;
      servicoDigital: boolean;
      investidorDigital: boolean;
      digital: boolean;
      contaCobrancaDigital: boolean;
      
    };
    onboard: {
      pessoaFisica: boolean;
      pessoaJuridica: boolean;
    };
    operador: {
      operadores: boolean;
    };
    fraude: {
      antiFraude: boolean;
    };
    api: {
      api: boolean;
    };
    auditoria: {
      logs: boolean;
    };
    aumentoLimites: {
      cobranca: boolean;
      transferencia: boolean;
      antecipacao: boolean;
    };
    token: {
      email: boolean;
      sms: boolean
    };
    espacoPublicitario: {
      publicidadeCarrossel: boolean;
    };
    autenticacaoSecundaria: boolean;
    contato: {
      importacao: boolean;
    };
    usuarios: {
      assessor: boolean;
      assistente: boolean;
      dtvm: boolean;
      gerenteDeContas: boolean;
      gestora: boolean;
    };
    tesouraria: {
      movimentacoesPorConta: boolean;
    };
    pix: {
      sidebar: boolean;
      transferenciaPix: boolean;
      gerarQrCode: boolean;
    }
  };
}

export const environment: Environment = {
  production: false,
  nomeApp: 'Movvime',
  plugaBank: false,
  codAgencia: '0002',
  codBanco: '463',
  empresa: {
    nomeIF: 'Azumi DTVM',
    cnpjIF: '40.434.681/0001-10',
    cnpj: '32.252.750/0001-47',
    ispb: 40434681,
    descricao: 'Nossa especialidade hoje é encontrar o equilíbrio entre inovação, tecnologia e viabilidade econômica',
    email: {
      comercial: 'comercial@movvime.com.br',
      contato: 'contato@movvime.com.br',
      suporte: 'suporte@movvime.com.br',
      ouvidoria: 'ouvidoria@movvime.com.br',
    },
    telefone: {
      comercial: '+55 (11) 5555-4893',
      contato: '+55 (11) 5555-4893',
      suporte: '+55 (11) 5555-4893',
      ouvidoria: '+55 (11) 5555-4893',
    },
    endereco: {
      cep: '04543-011',
      tipoLogradouro: 'Av.',
      logradouro: 'Pres. Juscelino Kubitschek',
      numero: '1455',
      complemento: '4º andar',
      bairro: 'Vila Nova Conceição',
      cidade: 'São Paulo',
      estado: 'São Paulo',
      pais: 'Brasil'
    },
    enderecoDTVM: {
      cep: '04730-090',
      tipoLogradouro: 'Av.',
      logradouro: 'Das Nações Unidas',
      numero: '17007',
      complemento: 'Torre Alpha, 13º Andar',
      bairro: 'Várzea de Baixo',
      cidade: 'São Paulo',
      estado: 'São Paulo',
      pais: 'Brasil'
    },
    gerente: {
      nome: 'Lucas Reis',
      cargo: 'Gerente de Contas',
      telefone: '(11) 5555-4893',
      email: 'lucas.reis@movvime.com.br',
    },
    suporteHorarioAtendimento: 'Segunda à Sexta - 9h às 18h (Exceto feriados)',
    jargao: 'Movvime - Agilidade no Recebimento',
    nomeFantasia: 'Movvime Gestão Financeira LTDA',
    razaoSocial: 'Movvime Gestão Financeira LTDA',
    nomeAbrevidado: 'Movvime',
  },
  url: {
    appSite: 'https://app.movvime.com.br',
    lojaAppAndroid: 'https://play.google.com/store/apps/details?id=br.com.movvime',
    lojaAppApple: 'https://apps.apple.com/us/app/movvime/id1524021412',
    webSite: 'https://movvime.com.br',
    backend: 'http://localhost:8080',
    termosContratos: 'https://movvime.com.br/termos-contratos-e-politicas/',
    termoUso: 'https://movvime.com.br/termos-de-uso/',
    openBanking: 'https://www.movvime.com.br/openbanking/',
    planosPrecos: 'https://movvime.com.br/planos-e-precos/',
    centralAjuda: 'https://movvime.tomticket.com/kb',
    blog: 'https://movvime.com.br/blog/',
    readme: 'https://movvime.readme.io/',
    envioOperacoes: 'https://movvime.qprof.com.br/',
    ajudaAntecipacao: 'https://movvime.tomticket.com/kb/antecipacao',
    ajudaCobranca: 'https://movvime.tomticket.com/kb/cobranca',
    ajudaContatos: 'https://movvime.tomticket.com/kb/-clientes/adicionar-editar-e-remover-clientes',
    ajudaPagamento: 'https://movvime.tomticket.com/kb/pagar-contas',
    ajudaTransferencia: 'https://movvime.tomticket.com/kb/-transferencias'
  },
  google: {
    captcha: '6Ley6WgqAAAAAPO6vOB7-OvDJ9CeLq0geNgCOuFs'
  },
  configCobranca: {
    cip: true,
    santander: true,
    cipBoletoPix: false,
    cipBoletoBancario: false,
    cipCartaoCredito: false,
  },
  modulos: {
    multiagencias: false,
    empresa: {
      multiEmpresas: true
    },
    cobranca: {
      sidebar: true,
      avulsa: true,
      assinatura: true,
      parcelada: true,
      importacaoCnab: true,
      protesto: true,
    },
    notificacao: {
      sms: true,
      email: true
    },
    pagamento: {
      boletoPix: true,
      boletoBancario: true,
      cartaoCredito: true,
      importacaoCnab: true,
    },
    desconto: {
      opcoesDesconto: true
    },
    arquivo: {
      anexarArquivo: true,
    },
    fiscal: {
      notaFiscal: true,
    },
    pagamentoContas: {
      sidebar: true,
      boleto: true,
      arrecadacao: false
    },
    extrato: {
      sidebar: true,
      ofx: true,
      xls: true,
      pdf: true,
      csv: true,
      informeRendimentos: true,
      cartaCircularizacao: true,
    },
    transferencia: {
      sidebar: true,
      ted: true,
      interna: true,
      p2p: true,
      agendamento: true,
      sensibilizacao: true,
      sensibilizacaoPassado: false,
      lote: true,
      alcada: true,
      importacaoCnab: true
    },
    cartaoCredito: {
      sidebar: true,
      prePago: true,
      saldoUnificado: true
    },
    credito: {
      sidebar: true,
    },
    investidor: {
      sidebar: true,
      extratoInvestidor: true,
      listaFundos: true,
      investimentos: true
    },
    antecipacao: {
      sidebar: true,
      boleto: true,
      faturaCartao: true,
      xmlRecebiveis: true,
      consignado: true
    },
    convite: {
      indicarAmigos: true,
    },
    suporteContato: {
      termosContratos: true,
      planosPrecos: true,
      centralAjuda: true,
      relacionamento: true,
      blog: true,
      envioOperacoes: true
    },
    contabilidade: {
      tesouraria: true,
    },
    tipoConta: {
      digitals: true,
      correnteDigital: true,
      vinculadaDigital: true,
      escrowDigital: true,
      bloqueioJudicial: true,
      salarioDigital: true,
      servicoDigital: true,
      investidorDigital: true,
      digital: true,
      contaCobrancaDigital: false
    },
    onboard: {
      pessoaFisica: true,
      pessoaJuridica: true
    },
    operador: {
      operadores: true,
    },
    fraude: {
      antiFraude: true,
    },
    api: {
      api: true,
    },
    auditoria: {
      logs: true
    },
    aumentoLimites: {
      cobranca: true,
      transferencia: true,
      antecipacao: true
    },
    token: {
      email: true,
      sms: true
    },
    espacoPublicitario: {
      publicidadeCarrossel: true
    },
    autenticacaoSecundaria: false,
    contato: {
      importacao: true,
    },
    usuarios: {
      assessor: true,
      assistente: true,
      dtvm: true,
      gerenteDeContas: true,
      gestora: true,
    },
    tesouraria: {
      movimentacoesPorConta: true
    },
    pix: {
      sidebar: true,
      transferenciaPix: true,
      gerarQrCode: true
    }
  }
};
