<div mat-dialog-content class="div-body">
    <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
        <div class="row" style="justify-content: center;">
            <div class="col-12">
                <div class="card">
                    <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="!processando">
                        <h2 class="titulo-modal">SUA COBRANÇA FOI GERADA!
                        </h2>
                        <img alt="pix" style="width: 230px; height: 230px; margin: 0 auto; display: block;"
                            src="data:image/png;base64,{{ qrcode }}">
                    </div>
                    <div id="linha" style="display: flex;justify-content: space-between;">
                        <p>Mostre o QR Code na sua tela, copie o código ou envie um link com todos os dados para quem
                            deseja cobrar.</p>
                    </div>
                    <div class="box-body div-btn" style="margin-top: 25px; 
                        display: flex;
                        justify-content: center;">
                        <div style="margin-right: 8px;">
                            <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                                (click)="copiarQrCode()">
                                <span style="font-size: 13px; margin-right: 10px;
                                "> <i class="fas fa-copy"></i>
                                    COPIAR CÓDIGO</span>
                            </button>
                        </div>
                        <div style="margin-left: 8px;">
                            <button class="btn btn-block btn-secundario btn-save" mat-raised-button
                                (click)="compartilharQrCode2()">
                                <span style="font-size: 13px; margin-left: 10px;
                                ">
                                    <i class="fas fa-share"></i>
                                    COMPARTILHAR</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="loading" class="loading-container flex-content-center" style="display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;">
                        <mat-progress-spinner mode="indeterminate" style="justify-content: center;align-items: center;"
                            id="spinner-load">
                        </mat-progress-spinner>
                    </div>
                    <div class="box-body div-btn" style="margin-top: 80px;" *ngIf="!loading">
                        <button mat-raised-button (click)="fechar()" class="btn mat-primary" style="width: 70%;                
                    font-size: 15px;
                    font-weight: 600;
                    ">
                            <p>CONCLUIR</p>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>