<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        {{sistemaOp}}<i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>

    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div class=" mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;">
            <div style="width: 100%;">
                <div style="width: 100%; display: flex;">
                    <div style="width: 50%; display: flex; justify-content: center; align-items: center;">
                        <ngx-qrcode cssClass="cqrcode" [value]="qrCode" errorCorrectionLevel="L">
                        </ngx-qrcode>
                    </div>
                    <div style="width: 50%;">
                        <i *ngIf="sistemaOp == 'ANDROID'">
                            <svg style="scale: 0.6;" fill="#707070" height="256px" width="256px" version="1.1"
                                id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 299.679 299.679"
                                xml:space="preserve" stroke="#707070">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g id="XMLID_197_">
                                        <path id="XMLID_221_"
                                            d="M181.122,299.679c10.02,0,18.758-8.738,18.758-18.758v-43.808h12.525c7.516,0,12.525-5.011,12.525-12.525 V99.466H74.749v125.123c0,7.515,5.01,12.525,12.525,12.525H99.8v43.808c0,10.02,8.736,18.758,18.758,18.758 c10.019,0,18.756-8.738,18.756-18.758v-43.808h25.051v43.808C162.364,290.941,171.102,299.679,181.122,299.679z">
                                        </path>
                                        <path id="XMLID_222_"
                                            d="M256.214,224.589c10.02,0,18.756-8.737,18.756-18.758v-87.615c0-9.967-8.736-18.75-18.756-18.75 c-10.021,0-18.758,8.783-18.758,18.75v87.615C237.456,215.851,246.192,224.589,256.214,224.589z">
                                        </path>
                                        <path id="XMLID_223_"
                                            d="M43.466,224.589c10.021,0,18.758-8.737,18.758-18.758v-87.615c0-9.967-8.736-18.75-18.758-18.75 c-10.02,0-18.756,8.783-18.756,18.75v87.615C24.71,215.851,33.446,224.589,43.466,224.589z">
                                        </path>
                                        <path id="XMLID_224_"
                                            d="M209.899,1.89c-2.504-2.52-6.232-2.52-8.736,0l-16.799,16.743l-0.775,0.774 c-9.961-4.988-21.129-7.479-33.566-7.503c-0.061,0-0.121-0.002-0.182-0.002h-0.002c-0.063,0-0.121,0.002-0.184,0.002 c-12.436,0.024-23.604,2.515-33.564,7.503l-0.777-0.774L98.516,1.89c-2.506-2.52-6.232-2.52-8.736,0 c-2.506,2.506-2.506,6.225,0,8.729l16.25,16.253c-5.236,3.496-9.984,7.774-14.113,12.667C82.032,51.256,75.727,66.505,74.86,83.027 c-0.008,0.172-0.025,0.342-0.033,0.514c-0.053,1.125-0.078,2.256-0.078,3.391H224.93c0-1.135-0.027-2.266-0.078-3.391 c-0.008-0.172-0.025-0.342-0.035-0.514c-0.865-16.522-7.172-31.772-17.057-43.487c-4.127-4.893-8.877-9.171-14.113-12.667 l16.252-16.253C212.405,8.115,212.405,4.396,209.899,1.89z M118.534,65.063c-5.182,0-9.383-4.201-9.383-9.383 c0-5.182,4.201-9.383,9.383-9.383c5.182,0,9.383,4.201,9.383,9.383C127.917,60.862,123.716,65.063,118.534,65.063z M181.145,65.063 c-5.182,0-9.383-4.201-9.383-9.383c0-5.182,4.201-9.383,9.383-9.383c5.182,0,9.383,4.201,9.383,9.383 C190.528,60.862,186.327,65.063,181.145,65.063z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </i>
                        <i *ngIf="sistemaOp == 'APPLE'">
                            <svg style="scale: 0.6;" fill="#707070" width="256px" height="256px"
                                viewBox="-52.01 0 560.035 560.035" xmlns="http://www.w3.org/2000/svg" stroke="#707070">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655">
                                    </path>
                                </g>
                            </svg>
                        </i>
                    </div>
                </div>
                <div style="width: 100%; text-align: center;">
                    <p style="font-size: 18px;">
                        Você pode encontrar nosso aplicativo para dispositivos móveis em : <br> <br> <a class="link"
                            href="{{qrCode}}">{{qrCode}}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;">
        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
        margin: 0;">
            <button (click)="fecha()" class="btn btn-default next">Voltar</button>
        </span>
    </div>
</div>