export const PERMISSOES_TRANSFERENCIA = [
  'ROLE_TRANSFERENCIA_CRIAR',
  'ROLE_TRANSFERENCIA_CANCELAR',
  'ROLE_TRANSFERENCIA_LISTAR',
  'ROLE_TRANSFERENCIA_ATUALIZAR',
  'ROLE_TRANSFERENCIA_AGENDAR'
]

export const PERMISSOES_COBRANCA = [
  'ROLE_COBRANCA_CRIAR',
  'ROLE_COBRANCA_LISTAR',
  'ROLE_COBRANCA_ATUALIZAR'
]

export const PERMISSOES_PROTESTO = [
  'ROLE_PROTESTO_CRIAR',
  'ROLE_PROTESTO_LISTAR',
  'ROLE_PROTESTO_ATUALIZAR',
  'ROLE_PROTESTO_CANCELAR',
  'ROLE_CEDENTE_CRIAR',
  'ROLE_CEDENTE_LISTAR',
  'ROLE_CEDENTE_ATUALIZAR'
]

export const PERMISSOES_CONTATO = [
  'ROLE_CONTATO_LISTAR',
  'ROLE_CONTATO_IMPORTAR'
]

export const PERMISSOES_PAGAMENTOS = [
  'ROLE_PAGAMENTOCONTAS_LISTAR'
]

export const PERMISSOES_ANTECIPACAO = [
  'ROLE_ANTECIPACAO_CRIAR',
  'ROLE_ANTECIPACAO_CANCELAR',
  'ROLE_ANTECIPACAO_LISTAR'
]

export const PERMISSOES_USUARIO = [
  'ROLE_USUARIO_CRIAR',
  'ROLE_USUARIO_CANCELAR',
  'ROLE_USUARIO_ATUALIZAR'
]

export const PERMISSOES_CORPORACAO = [
  'ROLE_CORPORACAO_CRIAR',
  'ROLE_CORPORACAO_CANCELAR',
  'ROLE_CORPORACAO_LISTAR',
  'ROLE_CORPORACAO_ATUALIZAR'
]

export const PERMISSOES_NOTIFICACAO = [
  'ROLE_NOTIFICACAO_CRIAR',
  'ROLE_NOTIFICACAO_ATUALIZAR',
  'ROLE_ACOMPANHAMENTO_MOVIMENTACAO',
  'ROLE_ACOMPANHAMENTO_PRIMEIRA_MOVIMENTACAO'
]

export const PERMISSOES_CONVIDARAMIGO = [
  'ROLE_CONVIDARAMIGO_CRIAR',
  'ROLE_CONVIDARAMIGO_LISTAR',
  'ROLE_CONVIDARAMIGO_CANCELAR'
]

export const PERMISSOES_BENEFICIARIO = [
  'ROLE_BENEFICIARIO_CRIAR',
  'ROLE_BENEFICIARIO_LISTAR'
]

export const PERMISSOES_SOLICITARAUMENTO = [
  'ROLE_SOLICITARAUMENTO_CRIAR',
  'ROLE_SOLICITARAUMENTO_LISTAR',
  'ROLE_SOLICITARAUMENTO_ATUALIZAR'
]

export const PERMISSOES_CNAB = [
  'ROLE_CNAB_CRIAR'
]

export const PERMISSOES_FERIADO = [
  'ROLE_FERIADO_CRIAR',
  'ROLE_FERIADO_CANCELAR',
  'ROLE_FERIADO_LISTAR',
  'ROLE_FERIADO_ATUALIZAR'
]

export const PERMISSOES_HOME = [
  'ROLE_HOME_LISTAR',
  'ROLE_HOME_ATUALIZAR'
]

export const PERMISSOES_INVESTIMENTO = [
  'ROLE_INVESTIMENTO_CANCELAR',
  'ROLE_INVESTIMENTO_ATUALIZAR'
]

export const PERMISSOES_INTEGRANFE = [
  'ROLE_INTEGRANFE_LISTAR'
]

export const PERMISSOES_OPERADORES = [
  'ROLE_OPERADORES_CRIAR',
  'ROLE_OPERADORES_LISTAR',
  'ROLE_OPERADORES_ATUALIZAR'
]

export const PERMISSOES_EXTRATO = [
  'ROLE_EXTRATO_LISTAR'
]

export const PERMISSOES_EMPRESA = [
  'ROLE_EMPRESA_CRIAR',
  'ROLE_EMPRESA_LISTAR',
  'ROLE_EMPRESA_ATUALIZAR'
]

export const PERMISSOES_ROOT = [
  "ROLE_ANTECIPACAO_ROOT",
  "ROLE_COBRANCA_ROOT",
  "ROLE_EMPRESA_ROOT",
  "ROLE_TRANSFERENCIA_ROOT",
  "ROLE_SISTEMA_ROOT",
  "ROLE_USUARIO_ROOT",
  "ROLE_INVESTIMENTO_ROOT",
  "ROLE_LIMITE_ROOT",
  "ROLE_ATENDIMENTO_CLIENTE_ROOT",
  "ROLE_PAGAMENTOCONTAS_ROOT",
  "ROLE_ONBOARDING_BAAS",
  "ROLE_ONBOARDING_IAAS",
  "ROLE_ONBOARDING_CAAS",
  "ROLE_CORRENTISTA_ROOT"
];

export const PERMISSOES_TOKEN = [
  'ROLE_TOKEN_EMAIL'
];

export const PERMISSOES_COLABORADOR = [
  'ROLE_NOVAS_EMPRESAS_COLABORADOR',
  'ROLE_MODERAR_COLABORADOR_ROOT',
];

export const PERMISSOES_PERSONALIZACAO_SISTEMA = [
  'ROLE_CADASTRAR_EMAIL_SISTEMA',
  'ROLE_CONFIGURAR_CADASTRO_SISTEMA',
  'ROLE_CONFIGURAR_GERAL_SISTEMA',
  'ROLE_CONFIGURAR_CAROUSEL_SISTEMA',
  'ROLE_TEXTO_SISTEMA',
  'ROLE_SUPORTE_CONTATO',
  'ROLE_TARIFAS_PADROES',
  'ROLE_CONFIGURAR_APPS',
  'ROLE_CONFIGURAR_INTEGRACAO'
]

export const PERMISSOES_PIX = [
  'ROLE_PIX_CRIAR',
  'ROLE_PIX_LISTAR',
  'ROLE_QRCODE_CRIAR',
  'ROLE_QRCODE_LISTAR',
  'ROLE_QRCODE_ATUALIZAR',
  'ROLE_CHAVEPIX_CRIAR',
  'ROLE_CHAVEPIX_LISTAR',
  'ROLE_CHAVEPIX_CANCELAR'
]
export const TODAS_PERMISSOES = [
  ...PERMISSOES_TRANSFERENCIA,
  ...PERMISSOES_COBRANCA,
  ...PERMISSOES_CONTATO,
  ...PERMISSOES_PAGAMENTOS,
  ...PERMISSOES_ANTECIPACAO,
  ...PERMISSOES_USUARIO,
  ...PERMISSOES_CORPORACAO,
  ...PERMISSOES_NOTIFICACAO,
  ...PERMISSOES_CONVIDARAMIGO,
  ...PERMISSOES_BENEFICIARIO,
  ...PERMISSOES_SOLICITARAUMENTO,
  ...PERMISSOES_CNAB,
  ...PERMISSOES_FERIADO,
  ...PERMISSOES_HOME,
  ...PERMISSOES_INVESTIMENTO,
  ...PERMISSOES_INTEGRANFE,
  ...PERMISSOES_OPERADORES,
  ...PERMISSOES_EXTRATO,
  ...PERMISSOES_EMPRESA,
  ...PERMISSOES_ROOT,
  ...PERMISSOES_COLABORADOR,
  ...PERMISSOES_TOKEN,
  ...PERMISSOES_PERSONALIZACAO_SISTEMA,
  ...PERMISSOES_PROTESTO,
  ...PERMISSOES_PIX
]
