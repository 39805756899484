import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TelefoneComponent } from './telefone/telefone.component';
import { EnderecoComponent } from './endereco/endereco.component';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { EmailComponent } from './email/email.component';
import { DadosBancariosComponent } from './dados-bancarios/dados-bancarios.component';
import { TransferenciaEndpointService } from '@service/transferencia-endpoint.service';
import { environment } from '@project/src/environments/environment.default';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import Swal from 'sweetalert2';
import { BuscaCnpjDTO } from '@project/src/app/model/apionboard/busca-cnpj-dto.model';
import { ChavesPixContatoComponent } from './chaves-pix-contato/chaves-pix-contato.component';
import { BuscaCpfDTO } from '@project/src/app/model/apionboard/busca-cpf-dto.model';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-pessoa-core-editar',
  templateUrl: './pessoa-core-editar.component.html',
  styleUrls: ['./pessoa-core-editar.component.less'],
})
export class PessoaCoreEditarComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public form: FormGroup;
  public processando = false;
  ehAdmin = false;
  ehColaborador = false;

  @Input()
  public id_corporacao: any;
  @Input()
  public data: any = {};
  @Input()
  public tipoCadastro: string;

  @ViewChild(EmailComponent, { static: false })
  private email: EmailComponent;
  @ViewChild(TelefoneComponent, { static: false })
  private telefone: TelefoneComponent;
  @ViewChild(EnderecoComponent, { static: false })
  private endereco: EnderecoComponent;
  @ViewChild(DadosBancariosComponent, { static: false })
  private contas: DadosBancariosComponent;
  @ViewChild(ChavesPixContatoComponent, { static: false })
  private chavePix: ChavesPixContatoComponent;
  dataOriginal: any;
  @Output()
  public numeroDesabilitado = false;
  env = environment;
  jurosDescontos: boolean = false;
  cpfCnpj: string;
  tabIndex: number = 0;
  tabIndexGeral: number = 0;

  @Input()
  ehPix: boolean = false;
  @Input()
  isEdicaoEndereco: boolean = false;

  authEmpresaAtt = false;
  authEmpresaCriar = false;
  possuiCobrancas = false;
  listarPix = false;
  ativaPortabilidade = false;
  areaPix = false;

  constructor(
    private fb: FormBuilder,
    private verifica: ChangeDetectorRef,
    public router: Router,
    private autenticacao: AutenticacaoEndpointService,
    private transferenciaConsulta: TransferenciaEndpointService,
    private cobrancaService: CobrancaEndpointService,
    private caniveteService: UtilCaniveteService
  ) {
    const userData = this.autenticacao.sessao;
    const checkAuthorites = new CheckAuthoritiesService(userData);
    this.ehAdmin = checkAuthorites.isAdministrador;
    this.authEmpresaAtt = checkAuthorites.permissaoGuard(
      'ROLE_EMPRESA_ATUALIZAR'
    );
    this.authEmpresaCriar =
      checkAuthorites.permissaoGuard('ROLE_EMPRESA_CRIAR');
    this.listarPix = checkAuthorites.permissaoGuard('ROLE_PIX_LISTAR');
    this.ehColaborador = checkAuthorites.isColaborador;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.jurosDescontos = this.env.modulos.desconto.opcoesDesconto;
    this.dataOriginal = JSON.parse(JSON.stringify(this.data));
    this.reset();
    if (this.tipoCadastro === 'contato') this.verificaCobrancas();
    this.areaPix = this.autenticacao.regraEmpresa.empresa.configuracao.areaPix;
  }

  ngAfterViewInit() {}

  public reset() {
    // DEBUG window['form'] =
    this.form = this.fb.group({
      geral: this.fb.group({}),
      endereco: this.fb.array([]),
      telefone: this.fb.array([]),
      email: this.fb.array([]),
      dadosBancarios: this.fb.array([]),
      chavePix: this.fb.array([]),
      jurosDesconto: this.fb.group({}),
      operadores: this.fb.array([]),
      autenticacao: this.fb.group({
        telefoneSeguranca: this.fb.array([]),
      }),
      precosTaxas: this.fb.group({}),
      notificacoes: this.fb.group({
        telefoneNotificacao: this.fb.array([]),
        emailNotificacao: this.fb.array([]),
      }),
      personalizacao: this.fb.group({}),
      documentacao: this.fb.array([]),
      configuracao: this.fb.group({}),
    });
  }

  ehPessoaJuridica(documento) {
    if (documento) {
      const documentoInput = documento.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11) {
        return false;
      } else {
        return true;
      }
    }
  }

  public async build(forcar = false): Promise<any> {
    if (this.form && (this.form.valid || forcar)) {
      if (this.form.value.precosTaxas.porcentagemMesFaturaAntecipada) {
        this.form.value.precosTaxas.porcentagemMesFaturaAntecipada =
          this.form.value.precosTaxas.porcentagemMesFaturaAntecipada
            .replace(/,/i, '.')
            .replace(/%/i, '');
      }
      if (this.form.value.precosTaxas.porcentagemMesPagamentoCartao) {
        this.form.value.precosTaxas.porcentagemMesPagamentoCartao =
          this.form.value.precosTaxas.porcentagemMesPagamentoCartao
            .replace(/,/i, '.')
            .replace(/%/i, '');
      }
      if (this.form.value.jurosDesconto.juros) {
        this.form.value.jurosDesconto.juros =
          this.form.value.jurosDesconto.juros
            .replace(/,/i, '.')
            .replace(/%/i, '');
      }
      if (this.form.value.jurosDesconto.multa) {
        this.form.value.jurosDesconto.multa =
          this.form.value.jurosDesconto.multa
            .replace(/,/i, '.')
            .replace(/%/i, '');
      }

      const form = this.form.value || {};
      const geral = form.geral || {};
      const jurosDesconto = form.jurosDesconto || {};
      const operadores = form.operadores || [];
      const autenticacao = form.autenticacao || {};
      const notificacoes = form.notificacoes || {};
      const precosTaxas = form.precosTaxas || {};
      const telefone = form.telefone || [];
      const email = form.email || [];
      const endereco = form.endereco || [];
      const dadosBancarios = form.dadosBancarios || [];
      const configuracao = form.configuracao || {};
      const chavePix = form.chavePix || this.form.get('chavePix').value || [];
      const pessoaData = JSON.parse(JSON.stringify(this.dataOriginal));
      pessoaData.configuracao = pessoaData.configuracao || {};
      pessoaData.configuracao.configuracaoEmail =
        pessoaData.configuracao.configuracaoEmail || {};
      pessoaData.configuracao.configuracaoSms =
        pessoaData.configuracao.configuracaoSms || {};
      pessoaData.configuracao.telefoneSeguranca = [];
      pessoaData.pessoa = pessoaData.pessoa || {};
      pessoaData.pessoa.telefones = [];
      pessoaData.pessoa.emails = [];
      pessoaData.pessoa.enderecos = [];
      pessoaData.pessoa.contas = [];
      pessoaData.pessoa.documento =
        geral.documento || this.form.get('geral').get('documento')
          ? this.form.get('geral').get('documento').value
          : null;
      pessoaData.pessoa.nome =
        geral.nome || this.form.get('geral').get('nome')
          ? this.form.get('geral').get('nome').value
          : null;
      pessoaData.pessoa.dataNascimento =
        geral.dataNascimento || this.form.get('geral').get('dataNascimento')
          ? this.form.get('geral').get('dataNascimento').value
          : null;
      pessoaData.pessoa.site = geral.site;
      pessoaData.pessoa.razaoSocial =
        geral.razaoSocial || this.form.get('geral').get('razaoSocial')
          ? this.form.get('geral').get('razaoSocial').value
          : null;
      pessoaData.faturamento = geral.faturamento;
      pessoaData.tipoEmpresa = geral.tipoEmpresa;

      if (this.tipoCadastro !== 'contato') {
        pessoaData.pessoa.logotipo = {
          ...pessoaData.pessoa.logotipo,
          ...geral.logotipo,
        };

        pessoaData.configuracao = {
          ...pessoaData.configuracao,
          juros: +jurosDesconto.juros,
          multa: +jurosDesconto.multa,
          desconto: +jurosDesconto.desconto,
          operadores: operadores,
          enviarParaProtesto: jurosDesconto.enviarParaProtesto,
          diasDeProtesto:
            jurosDesconto.enviarParaProtesto === true
              ? +jurosDesconto.diasDeProtesto
              : 0,
          habilitarPersonalizacao: true,
          agendarTransferencias: autenticacao.agendarTransferencias,
          alterarContasBancarias: autenticacao.alterarContasBancarias,
          alterarDadosComerciais: autenticacao.alterarDadosComerciais,
          alterarCelularPrincipal: autenticacao.alterarCelularPrincipal,
          diasAntesDoVencimentoParaRelembrarProximasCobrancas: Number(
            notificacoes.diasAntesDoVencimentoParaRelembrarProximasCobrancas
          ),
          diasAposVencimentoParaRelembrarCobrancaVencida: Number(
            notificacoes.diasAposVencimentoParaRelembrarCobrancaVencida
          ),

          diasAntesDoVencimentoParaEnvioLembreteIndividual: Number(
            notificacoes.diasAntesDoVencimentoParaEnvioLembreteIndividual
          ),
          configuracaoEmail: {
            ...pessoaData.configuracao.configuracaoEmail,
            notificarCobradorAoRemoverCobranca:
              notificacoes.emailNotificarCobradorAoRemoverCobranca,
            notificarPagadorAoRemoverCobranca:
              notificacoes.emailNotificarPagadorAoRemoverCobranca,
            notificarCobradorAoVisualizarFatura:
              notificacoes.emailNotificarCobradorAoVisualizarFatura,
            notificarCobradorNovasCobrancas:
              notificacoes.emailNotificarCobradorNovasCobrancas,
            notificarPagadorNovasCobrancas:
              notificacoes.emailNotificarPagadorNovasCobrancas,
            notificarCobradorAlteracaoValorOuDataVencimentoCobrancas:
              notificacoes.emailNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas,
            notificarPagadorAlteracaoValorOuDataVencimentoCobrancas:
              notificacoes.emailNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas,
            notificarCobradorCobrancasAtrasadas:
              notificacoes.emailNotificarCobradorCobrancasAtrasadas,
            notificarPagadorCobrancasAtrasadas:
              notificacoes.emailNotificarPagadorCobrancasAtrasadas,
            notificarCobradorPagamentosConfirmados:
              notificacoes.emailNotificarCobradorPagamentosConfirmados,
            notificarPagadorPagamentosConfirmados:
              notificacoes.emailNotificarPagadorPagamentosConfirmados,
          },
          configuracaoSms: {
            ...pessoaData.configuracao.configuracaoSms,
            notificarCobradorAoRemoverCobranca:
              notificacoes.smsNotificarCobradorAoRemoverCobranca,
            notificarPagadorAoRemoverCobranca:
              notificacoes.smsNotificarPagadorAoRemoverCobranca,
            notificarCobradorAoVisualizarFatura:
              notificacoes.smsNotificarCobradorAoVisualizarFatura,
            notificarCobradorNovasCobrancas:
              notificacoes.smsNotificarCobradorNovasCobrancas,
            notificarPagadorNovasCobrancas:
              notificacoes.smsNotificarPagadorNovasCobrancas,
            notificarCobradorAlteracaoValorOuDataVencimentoCobrancas:
              notificacoes.smsNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas,
            notificarPagadorAlteracaoValorOuDataVencimentoCobrancas:
              notificacoes.smsNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas,
            notificarCobradorCobrancasAtrasadas:
              notificacoes.smsNotificarCobradorCobrancasAtrasadas,
            notificarPagadorCobrancasAtrasadas:
              notificacoes.smsNotificarPagadorCobrancasAtrasadas,
            notificarCobradorPagamentosConfirmados:
              notificacoes.smsNotificarCobradorPagamentosConfirmados,
            notificarPagadorPagamentosConfirmados:
              notificacoes.smsNotificarPagadorPagamentosConfirmados,
          },
          telefoneSeguranca: [],
          telefoneNotificacao: [],
          emailNotificacao: [],
        };

        autenticacao.telefoneSeguranca.forEach((element) => {
          delete element.principal;
          if (element.numero != null && element.numero != '') {
            if (element.ddd == null || element.ddd == '') {
              element.numero = element.numero.toString();
              element.ddd = Number(element.numero.slice(0, 2));
            } else if (element.ddd != element.numero.slice(0, 2)) {
              element.numero = element.numero.toString();
              element.ddd = Number(element.numero.slice(0, 2));
            }
            if (element.numero.length >= 10) {
              element.numero = Number(
                `${element.numero || '   '}`.substring(2)
              ).toString();
            }
          }
          //variável para indicar se o usuário já cadastrou telefone segurança
          pessoaData.configuracao.telefoneSeguranca.temTelefoneSeguranca =
            this.form
              .get('autenticacao')
              .get('telefoneSeguranca')
              .get('0')
              .get('numero').dirty;

          pessoaData.configuracao.telefoneSeguranca.push(element);
        });
        notificacoes.telefoneNotificacao.forEach((element) => {
          delete element.principal;
          if (element.numero != null && element.numero != '') {
            if (element.ddd == null || element.ddd == '') {
              element.numero = element.numero.toString();
              element.ddd = Number(element.numero.slice(0, 2));
            } else if (element.ddd != element.numero.slice(0, 2)) {
              element.numero = element.numero.toString();
              element.ddd = Number(element.numero.slice(0, 2));
            }
            if (element.numero.length >= 10) {
              element.numero = Number(
                `${element.numero || '   '}`.substring(2)
              ).toString();
            }
          }

          pessoaData.configuracao.telefoneNotificacao.push(element);
        });
        notificacoes.emailNotificacao.forEach((element) => {
          pessoaData.configuracao.emailNotificacao.push(element);
        });

        if (this.ehAdmin || this.ehColaborador) {
          pessoaData.configuracao = {
            ...pessoaData.configuracao,
            ...precosTaxas,
          };
        }
      } else {
        pessoaData.configuracao.limiteTransferencia =
          configuracao.limiteTransferencia;
      }

      telefone.forEach((element) => {
        if (!element.ddd || (element.numero && element.numero.length >= 10)) {
          element.ddd = Number(element.numero.slice(0, 2));
          element.numero = element.numero.slice(2);
        }
        element.principal = element.principal || false;
        pessoaData.pessoa.telefones.push(element);
      });

      let emailPrincipalNovo = '';
      email.forEach((element) => {
        element.principal = element.principal || false;
        if (element.principal) emailPrincipalNovo = element.email;
        pessoaData.pessoa.emails.push(element);
      });

      if (this.tipoCadastro === 'contato' && this.data && this.data.pessoa) {
        let emailPrincipalAtual = '';
        this.data.pessoa.emails.forEach((item) => {
          if (item.principal) {
            emailPrincipalAtual = item.email;
          }
        });

        if (
          emailPrincipalNovo != '' &&
          emailPrincipalAtual !== emailPrincipalNovo &&
          this.possuiCobrancas
        ) {
          await swal
            .fire({
              title: 'Atualizar e-mail',
              text: 'Você deseja atualizar o e-mail de suas cobranças em aberto?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Cancelar',
            })
            .then((res) => {
              if (res.isConfirmed) {
                pessoaData.pessoa.atualizarCobrancas = true;
              }
            });
        }
      }

      endereco.forEach((element) => {
        element.principal = element.principal || false;
        pessoaData.pessoa.enderecos.push(element);
      });

      dadosBancarios.forEach((element) => {
        element.principal = element.principal || false;
        element.agencia =
          element.agencia !== null && element.agencia.trim() !== ''
            ? element.agencia.trim()
            : null;
        element.agenciaDigito =
          element.agenciaDigito !== null && element.agenciaDigito.trim() !== ''
            ? element.agenciaDigito.trim()
            : null;
        element.conta =
          element.conta !== null && element.conta.trim() !== ''
            ? element.conta.trim()
            : null;
        element.contaDigito =
          element.contaDigito !== null && element.contaDigito.trim() !== ''
            ? element.contaDigito.trim()
            : null;
        delete element.tipoEnvio;
        delete element.bancoItem;
        pessoaData.pessoa.contas.push(element);
      });

      pessoaData.contatoChavePix = [];

      this.form.get('chavePix').value.forEach((element) => {
        pessoaData.contatoChavePix.push(element);
      });

      pessoaData.configuracao.limiteTransferenciaDiurno =
        configuracao.limiteTransferenciaDiurno;
      pessoaData.configuracao.limiteTransferenciaNoturno =
        configuracao.limiteTransferenciaNoturno;
      pessoaData.configuracao.limiteTransferenciaDiario =
        configuracao.limiteTransferenciaDiario;

      return pessoaData;
    } else {
      swal
        .fire({
          title: 'Atenção',
          text: 'Preencha todos os campos obrigatórios antes de continuar',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
        .then(() => {
          this.form.markAllAsTouched();
        })
        .then(() => {
          this.verifica.detectChanges();
        });
    }
  }

  atualizaTudoBanco(obj) {
    if (obj.documento > 11) this.form.get('geral').disable();
    if (!!obj.emails) {
      if (!(this.form.get('email') as FormArray).length) {
        this.email.add();
      }
      this.form.get('email').patchValue(obj.emails);
    }

    if (!!obj.telefones) {
      for (let i = 0; i < obj.telefones.length; i++) {
        let t = obj.telefones[i];
        const value = '' + t.ddd + t.numero;
        const reg = new RegExp('([0-9]{2})([0-9]+)', 'gim').exec(value);
        if (reg && reg.length) {
          if (!this.form.get(['telefone', i])) {
            this.telefone.add();
          }
          this.form.get(['telefone', i]).patchValue({
            tipo: t.tipo,
            ddd: reg[1],
            numero: reg[1] + reg[2], // reg[2] contem apenas o numero sem ddd
            pais: '+55',
          });
        }
      }
    }

    this.form.get('geral').patchValue({
      documento: obj.documento,
      nome: !!obj.razaoSocial ? obj.razaoSocial : obj.nomeFantasia,
      razaoSocial: !!obj.razaoSocial ? obj.razaoSocial : null,
      faturamento: !!obj.capital ? obj.capital : null,
      // tipoEmpresa: !!obj.tipoEmpresa ? obj.tipoEmpresa : null, //removido pois a informação retornada da API não confere com os Enums utilizados no beckend
    });


    if (!(this.form.get('endereco') as FormArray).length) {
      this.endereco.add();
    }

    this.form.get('endereco').patchValue(obj.enderecos);

    if (!!obj.contas) {
      this.processando = true;
      if (obj.contas.length == 0) {
        this.processando = false;
        this.form.get('geral').enable();
        this.verifica.detectChanges();
      }
      for (let i = 0; i < obj.contas.length; i++) {
        let c = obj.contas[i];
        this.transferenciaConsulta
          .contaPossuiTransferencia(c.id)
          .toPromise()
          .then((response) => {
            if (i == obj.contas.length - 1) {
              this.processando = false;
              this.form.get('geral').enable();
              this.verifica.detectChanges();
            }
            if ((response != null && !response) || c.dataDesativacao == null) {
              (this.form.get('dadosBancarios') as FormArray).push(
                this.fb.group(
                  {
                    favorito: this.fb.control(!!c.favorito),
                    id: this.fb.control(c.id || null),
                    bancoItem: this.fb.control(c.banco || '', [
                      Validators.required,
                    ]),
                    banco: this.fb.control(c.banco || ' '),
                    codigoBanco: this.fb.control(c.codigoBanco || ''),
                    tipoConta: this.fb.control(c.tipoConta, [
                      Validators.required,
                    ]),
                    agencia: this.fb.control(c.agencia, [
                      Validators.required,
                      Validators.pattern(/^[0-9]+$/),
                    ]),
                    agenciaDigito: this.fb.control(c.agenciaDigito || null, [
                      Validators.pattern(/^[0-9]+$/),
                    ]),
                    conta: this.fb.control(c.conta, [
                      Validators.required,
                      Validators.pattern(/^[0-9]+$/),
                    ]),
                    contaDigito: this.fb.control(c.contaDigito || null, [
                      Validators.pattern(/^[0-9]+$/),
                    ]),
                    apelidoConta: this.fb.control(c.apelidoConta, [
                      Validators.required,
                    ]),
                    titular: this.fb.control(c.titular || obj.nome),
                    documento: this.fb.control(
                      (c.documento || obj.documento || '').replace(
                        /[^0-9]/gim,
                        ''
                      ),
                      [Validators.pattern(/^([0-9]{11}|[0-9]{14})$/)]
                    ),
                    dataDesativacao: this.fb.control(c.dataDesativacao || null),
                    celularResponsavel: this.fb.control(
                      c.celularResponsavel || obj.telefones[0] || ''
                    ),
                    emailResponsavel: this.fb.control(
                      c.emailResponsavel || obj.emails[0] || ''
                    ),
                    tipoEnvio: this.fb.control('terceiros', [
                      Validators.required,
                    ]),
                    isAtivo: this.fb.control(c.dataDesativacao ? false : true),
                    isDeletavel: this.fb.control(
                      response != null ? response : true
                    ),
                  },
                  {
                    validator: [
                      this.obrigatoriosTerceiro(
                        'tipoEnvio',
                        'documento',
                        'titular'
                      ),
                    ],
                  }
                )
              );
            }
          });
      }
      (error) => {
        this.processando = false;
      };
    }
  }

  obrigatoriosTerceiro(tipoEnvio, documento, titular) {
    return (group: FormGroup) => {
      const tipoEnvioInput = group.controls[tipoEnvio],
        documentoInput = group.controls[documento],
        titularInput = group.controls[titular];

      let documentoErrors: any = { required: true };
      let titularErrors: any = { required: true };

      if (tipoEnvioInput.value === 'terceiros') {
        if (documentoInput.value !== '' && documentoInput.value !== null) {
          if (!documentoInput.value.match(/^([0-9]{11}|[0-9]{14})$/)) {
            documentoErrors = { pattern: true };
          } else {
            documentoErrors = null;
          }
        } else {
          documentoErrors = { required: true };
        }

        if (titularInput.value !== '' && titularInput.value !== null) {
          titularErrors = null;
        } else {
          titularErrors = { required: true };
        }

        return (
          documentoInput.setErrors(documentoErrors),
          titularInput.setErrors(titularErrors)
        );
      } else if (tipoEnvioInput.value === 'empresa') {
        return documentoInput.setErrors(null), titularInput.setErrors(null);
      }
    };
  }

  atualizaTudoAmazon(obj: BuscaCnpjDTO | BuscaCpfDTO) {
    this.endereco.esvazia();
    this.telefone.esvazia();
    this.email.esvazia();
    this.form.get('endereco').reset();
    this.form.get('email').reset();

    if ('cnpj' in obj) {
      // preenche cnpj

      if (!!obj.email) {
        if (!(this.form.get('email') as FormArray).length) {
          this.email.add();
        }
        this.form.get(['email', 0]).patchValue({
          email: obj.email,
          principal: true,
        });
      }

      if (!!obj.telefone) {
        const split = obj.telefone.split('/');
        for (let i = 0; i < split.length; i++) {
          if (split.hasOwnProperty(i)) {
            const telefone = split[i];
            const value = telefone.replace(/[^0-9]/gim, '');
            const reg = new RegExp('([0-9]{2})([0-9]+)', 'gim').exec(value);
            if (reg && reg.length) {
              if (!this.form.get(['telefone', i])) {
                this.telefone.add();
              }
              this.form.get(['telefone', i]).patchValue({
                tipo: 'comercial',
                ddd: reg[1],
                numero: reg[1] + reg[2], // reg[2] contem apenas o numero sem ddd
                pais: '+55',
              });
            }
          }
        }
      }

      this.form.get('geral').patchValue({
        documento: obj.cnpj,
        nome: !!obj.nomeFantasia ? obj.nomeFantasia : obj.razaoSocial,
        razaoSocial: obj.razaoSocial,
        faturamento: obj.capital,
        tipoEmpresa: obj.tipoEmpresa,
      });

      if (!(this.form.get('endereco') as FormArray).length) {
        this.endereco.add();
      }

      this.form.get(['endereco', 0]).patchValue({
        cep: obj.endereco?.cep,
        tipoLogradouro: '',
        logradouro: obj.endereco?.logradouro,
        numero: obj.endereco?.numero,
        complemento: obj.endereco?.complemento,
        bairro: obj.endereco?.bairro,
        cidade: obj.endereco?.cidade,
        codigo_ibge: '',
        estado: obj.endereco?.estado,
        principal: true,
      });

      this.endereco.buscaEndereco(this.form.get(['endereco', 0]));
    } else if ('cpf' in obj) {
      // preenche cpf

      if (!!obj.emails) {
        let i = 0;
        for (const key in obj.emails) {
          if (!this.form.get(['email', i])) {
            this.email.add();
          }
          if (Object.prototype.hasOwnProperty.call(obj.emails, key)) {
            this.form.get(['email', i]).patchValue({
              email: obj.emails[key].enderecoEmail,
            });
            i++;
          }
        }
        this.form.get(['email', 0]).patchValue({
          principal: true,
        });
      }

      if (!!obj.telefones) {
        let i = 0;
        for (const key in obj.telefones) {
          if (!this.form.get(['telefone', i])) {
            this.telefone.add();
          }
          if (Object.prototype.hasOwnProperty.call(obj.telefones, key)) {
            this.form.get(['telefone', i]).patchValue({
              tipo: obj.telefones[key]?.tipo == 'MOBILE' ? 'celular' : obj.telefones[key].tipo == 'HOME' ? 'residencial' : 'comercial',
              ddd: obj.telefones[key].codigoArea,
              numero: obj.telefones[key].codigoArea + obj.telefones[key].numero,
              pais: obj.telefones[key].codigoPais,
            });
            i++;
          }
        }
        if (!i) {
          this.form.get(['telefone', i]).patchValue({
            tipo: 'celular',
            ddd: '',
            numero: '',
            pais: '',
          });
        }
      }

      this.form.get('geral').patchValue({
        documento: obj.cpf,
        nome: this.caniveteService.capitalizeFirstLetterOfEachWord(
          obj.dadosBasicosPessoa.nomeCompleto
        ),
        razaoSocial: '',
        faturamento: '',
        tipoEmpresa: '',
      });

      if (!!obj.enderecos) {
        let i = 0;
        for (const key in obj.enderecos) {
          if (!this.form.get(['endereco', i])) {
            this.endereco.add();
          }
          if (Object.prototype.hasOwnProperty.call(obj.enderecos, key)) {
            this.form.get(['endereco', i]).patchValue({
              cep: obj.enderecos[key].cep,
              tipoLogradouro: '',
              logradouro: this.caniveteService.capitalizeFirstLetterOfEachWord(
                obj.enderecos[key].tipologia +
                  ' ' +
                  obj.enderecos[key].titulo +
                  ' ' +
                  obj.enderecos[key].enderecoPrincipal
              ),
              numero: obj.enderecos[key].numero,
              complemento: this.caniveteService.capitalizeFirstLetterOfEachWord(
                obj.enderecos[key].complemento
              ),
              bairro: this.caniveteService.capitalizeFirstLetterOfEachWord(
                obj.enderecos[key].bairro
              ),
              cidade: this.caniveteService.capitalizeFirstLetterOfEachWord(
                obj.enderecos[key].cidade
              ),
              codigo_ibge: '',
              estado: obj.enderecos[key].estado,
            });
            i++;
          }
        }
        this.form.get(['endereco', 0]).patchValue({
          principal: true,
        });
      }
    }
    this.form.updateValueAndValidity()
  }

  verificaCobrancas() {
    if (this.data && this.data.id) {
      this.cobrancaService.contatoPossuiCobrancas(this.data.id).subscribe(
        (res) => {
          this.possuiCobrancas = res;
        },
        (error) => {
          Swal.fire({
            title: 'Erro',
            text: error.error,
            icon: 'error',
          });
        }
      );
    }
  }

  capturarCpfCnpj(event: string) {
    this.cpfCnpj = event;
  }
}
