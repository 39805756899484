import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { RootEndpointService } from '../../service/root-endpoint.service';
import { ModalConfirmacaoComponent } from '../modal/modal-confirmacao/modal-confirmacao.component';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfiguracaoEndpointService } from '../../service/configuracao-endpoint.service';
import { ConfiguracoesOnboardingDTO } from '../../model/configuracoes-onboarding-dto.model';

@Component({
  selector: 'app-personalizao-geral',
  templateUrl: './personalizao-geral.component.html',
  styleUrls: ['./personalizao-geral.component.less']
})
export class PersonalizaoGeralComponent implements OnInit {
  env = environment;
  config: any;
  configuracoesOnboarding: ConfiguracoesOnboardingDTO

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PersonalizaoGeralComponent>,
    private rootService: RootEndpointService,
    private dialog: MatDialog,
    private configuracaoService: ConfiguracaoEndpointService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  async ngOnInit() {
    const [sistema, onboarding] = await Promise.all(
      [this.rootService.getConfiguracoesSistema().toPromise(),
        this.configuracaoService.listarConfiguracoesOnboarding().toPromise()])
    this.config = sistema;
    this.configuracoesOnboarding = onboarding;
  }

  fecha(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() { }

  async salvaConfig() {
    const [sistema, _] = await Promise.all(
          [this.rootService.updateConfiguracoesSistema(this.config).toPromise(), 
            this.configuracaoService.atualizarConfiguracoesOnboarding(this.configuracoesOnboarding).toPromise()])
     
    if (this.config.fixaSidebar === false) {
      this.storage.set("fixaSidebar", false)
    } else {
      this.storage.set("fixaSidebar", true)
    }

    this.modalFinal('Configurações foram atualizadas com sucesso!', sistema);
  }

  modalFinal(titulo, response) {
    const dialog = this.dialog.open(ModalConfirmacaoComponent, {
      data: {
        titulo: titulo,
        texto: response,
        subtexto: ``,
        btnTexto: 'Rever configurações',
        whatsapp: false,
        checkout: false
      },
      width: '600px',
      backdropClass: 'modal-alerta'
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        location.reload()
      });
  }
}
