import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class EmailSistemaEndpointService {
  constructor(private client: HttpClient) { }

  cadastrarEmail(email) {
    const url = '/email-sistema/criar';
    return this.client.post(url, email);
  }

  listarTodos() {
    const url = '/email-sistema/listar-todos';
    return this.client.get(url);
  }

  editarEmail(idEmail) {
    const url = '/email-sistema/editar?idEmail=' + idEmail;
    return this.client.post(url, idEmail);
  }

  desativarEmail(idEmail) {
    const url = '/email-sistema/desativar?idEmail=' + idEmail;
    return this.client.put(url, idEmail);
  }

  listarPorTipo(tipo) {
    const url = '/email-sistema/listar-por-tipo?tipo=' + tipo;
    return this.client.get(url);
  }

  ativarEmail(idEmail) {
    const url = '/email-sistema/ativar?idEmail=' + idEmail;
    return this.client.put(url, idEmail);
  }
}