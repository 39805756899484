import { MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import Swal from 'sweetalert2';
import { SensibilizacaoComponent } from '../sensibilizacao.component';
import { UsuarioEndpointService } from '@project/src/app/service/usuario-endpoint.service';
import { TemporizadorService } from '@project/src/app/service/temporizador.service';
import { environment } from '@project/src/environments/environment.default';

@Component({
  selector: 'app-transferencia-sensibilizacao',
  templateUrl: './transferencia-sensibilizacao.component.html',
  styleUrls: ['./transferencia-sensibilizacao.component.less']
})
export class TransferenciaSensibilizacaoComponent {

  public transTextA: string = "debitada";
  public transTextB: string = "creditada";
  public numeroTelefone: string = "";
  public bloqueiaEnviarSmsAte: Date;
  public podeReenviarSms = true;
  public tempoReenvio = 2 //minutos
  yesterdayDate = new Date();
  todayDate = new Date();


  env = environment;



  private smsEnviadoEm: Date;

  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  @Output()
  public isEntrada: EventEmitter<any> = new EventEmitter();

  @Output()
  public validaConta: EventEmitter<any> = new EventEmitter();

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private usuarioEndpointService: UsuarioEndpointService,
    private temporizadorService: TemporizadorService,
    public dialogRef: MatDialogRef<SensibilizacaoComponent>
  ) {
    this.usuarioEndpointService.obterUsuarioLogado().toPromise().then(response => {
      this.numeroTelefone = this.getUltimosNumeros(response.celular);
    }, error => {
      console.error(error);
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
    })

    this.todayDate.setHours(0, 0, 0, 0);
  }


  novoCodigoSms() {
    // this.transferencia.getCodAutenticacao(this.idTransferencia).pipe(untilDestroyed(this))
    if (this.env.modulos.token.sms) {
      this.usuarioEndpointService.solicitarToken('sensibilizacao', 'sms').pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.form.get('token').enable();
          this.smsEnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
          this.bloqueiaEnviarSmsAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarSmsAte, this.smsEnviadoEm, this.tempoReenvio, 'smsEnviadoEm');
          this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarSmsAte, 'smsEnviadoEm');

          this.temporizadorService.podeReenviarSms.pipe(untilDestroyed(this))
            .subscribe((response) => {
              this.podeReenviarSms = response;
            }, error => {
              console.error(error);
            }
            );
        }, error => {
          Swal.fire('Atenção', error.error, 'warning');
        });
    } else {
      Swal.fire('Atenção', 'Não possui permissão para solicitar token por meio de SMS', 'warning');
    }
  }

  private mascaraHoraBloqueio(valor: string, field: string): string {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d{2})(\d)/, "$1:$2");
    valor = valor.replace(/(\d{2})(\d{2})$/, "$1$2");
    document.getElementById(field)['value'] = valor;
    return valor;
  }

  validarHora(valor: string, campoId: string) {
    valor = this.mascaraHoraBloqueio(valor, campoId);
    const horaAtual = new Date();

    // Insere os dois pontos entre horas e minutos
    const valorComPontos = valor.length === 4 ? `${valor.substring(0, 2)}:${valor.substring(2, 4)}` : valor;
    const [hora, minuto] = valorComPontos.split(':').map(Number);

    const horaInserida = new Date(horaAtual.getFullYear(), horaAtual.getMonth(), horaAtual.getDate(), hora, minuto);

    if (horaInserida >= horaAtual) {
      // Ajuste o valor para a hora atual
      const horaFormatada = this.formatarHora(horaAtual.getHours(), horaAtual.getMinutes());
      this.form.get(campoId).setValue(horaFormatada);
    }
  }

  private formatarHora(hora: number, minuto: number): string {
    return `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
  }


  mudaTipo(tipoTrans: string) {
    if (tipoTrans == "entrada") {
      this.transTextA = "debitada"
      this.transTextB = "creditada"
    } else {
      this.transTextA = "creditada"
      this.transTextB = "debitada"
    }
    this.isEntrada.emit(tipoTrans);
  }

  getUltimosNumeros(telefone) {
    if (telefone == undefined) {
      Swal.fire(
        'Cadastro incompleto',
        'Para realizar as operações, por favor verifique o telefone em Empresa-> Configurações -> Autenticação',
        'warning'
      ).then((result) => {
        this.dialogRef.close();
      });
    } else {
      const numero = String(telefone.numero);
      return '(' + telefone.ddd + ')' + ' XXXXX-XX' + numero.substring((numero.length - 2), numero.length);
    }
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, "");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d{2})$/, "$1$2");
    document.getElementById(field)['value'] = data;
  }

  mascaraHora(event: any, field: string) {
    let data = event;
    data = data.replace(/\D/g, "");
    data = data.replace(/(\d{2})(\d)/, "$1:$2");
    data = data.replace(/(\d{2})(\d{2})$/, "$1$2");
    document.getElementById(field)['value'] = data;
  }

  ngOnDestroy() { }


  getErrorMessage(controlName: string): string {
    const validaCpfCnpjIguais = this.form.get('cNPJCPFCliA').value === this.form.get('cNPJCPFCliB').value;

    if (controlName === 'cNPJCPFCliB' && validaCpfCnpjIguais) {
      return 'O CNPJ/CPF da pessoa creditada ou debitada devem ser diferentes.';
    }

    return '';
  }
}
