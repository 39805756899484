import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ArquivoRepresentanteEmpresaDTO } from '@project/src/app/model/pessoa/arquivo-representante-empresa-dto.model';
import moment from 'moment';

@Component({
  selector: 'app-add-representante',
  templateUrl: './add-representante.component.html',
  styleUrls: ['./add-representante.component.less']
})
export class AddRepresentanteComponent {

  @Input()
  form: FormGroup;

  @Input()
  dataMinima = moment().subtract(150, 'years').toDate();

  @Input()
  dataMaxima = moment().subtract(18, 'years').toDate();

  documentos: string[] = [];

  constructor( ) { }

  upload(tipo: string, arquivos: ArquivoRepresentanteEmpresaDTO[], index: number) {
    const form = this.form.get('arquivoRepresentanteEmpresa') as FormArray;
    for (let arquivo of arquivos) {
      arquivo.documento = tipo;
      arquivo.moderacao = 'aguardandoModeracao';
      form.push(new FormControl(arquivo))
    }
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) 
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido' : '';
    }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, "");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d)/, "$1/$2");
    data = data.replace(/(\d{2})(\d{2})$/, "$1$2");
    document.getElementById(field)['value'] = data;
  }

}
