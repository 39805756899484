import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { iconsCarrousel } from './icons-service';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { SuporteContatoEndpointService } from '../../service/suporte-contato-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-suporte-e-contato',
  templateUrl: './suporte-e-contato.component.html',
  styleUrls: ['./suporte-e-contato.component.less'],
})
export class SuporteEContatoComponent implements OnInit, OnDestroy {

  form: FormGroup;
  icons = iconsCarrousel;
  objectContato: any;
  listContato: any;
  selectedItem: any;
  processando: boolean = false;
  startIndex: any;
  isFormValid: boolean = false;
  editando: boolean = false;
  selectedIcon: any;
  iconEdit: any;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(
    public dialogRef: MatDialogRef<SuporteEContatoComponent>,
    public formBuilder: FormBuilder,
    public suporteContato: SuporteContatoEndpointService
  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      descricao: new FormControl('', [Validators.required, Validators.minLength(2)]),
      link: new FormControl(''),
      icone: new FormControl('insert_drive_file', [Validators.required]),
      ordem: new FormControl('')
    });

    this.form.statusChanges.subscribe(status => {
      this.isFormValid = status === 'VALID';
    });

    this.listarItems()
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }

  slideChanged(event: any) {
    this.editando = false;
    let currentSlideId = event.current;
    const activeSlideIndex = this.carousel.slides.toArray().findIndex(slide => slide.id === currentSlideId);

    if (activeSlideIndex !== -1) {
      this.selectedIcon = this.icons[activeSlideIndex];
      this.form.patchValue({ icone: this.selectedIcon });
    }
  }

  slideClicked() {
    this.carousel.pause();

    return true;
  }

  buildIcon() {
    this.objectContato = {
      descricao: this.form.get('descricao').value,
      link: this.form.get('link').value,
      icone: this.form.get('icone').value,
      ordem: this.listContato.length
    }
    return this.objectContato;
  }


  salvar() {
    this.listarItems();
    this.processando = true;
    this.suporteContato.cadastrarItem(this.buildIcon())
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.processando = false;
        this.listarItems();
      }, error => {
        this.processando = false;
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error.error
        });
        console.log(error);
      });
  }

  desativar(descricao) {
    this.processando = true;
    this.suporteContato.desativar(descricao)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.processando = false;
        this.listarItems();
      }, error => {
        this.processando = false;
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error.error
        });
        console.log(error);
      })
  }

  listarItems() {
    this.processando = true;
    this.suporteContato.getAll()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.listContato = res;
        this.updateIndexes();
        this.processando = false;
      })
  }

  atualizaOrdemObjeto() {
    this.suporteContato.atualizaOrdem(this.listContato)
      .subscribe(res => {
      })
  }

  fecha() {
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listContato, event.previousIndex, event.currentIndex);
    this.updateIndexes();
    this.atualizaOrdemObjeto();
  }

  updateIndexes() {
    this.listContato.forEach((item, index) => {
      item.ordem = index;
    });
  }


  editarContato(descricao: string, icone: string, link: string) {
    this.form.get('descricao').setValue(descricao);
    this.form.get('icone').setValue(icone);
    this.form.get('link').setValue(link);
    this.iconEdit = icone;
    this.editando = true;
  }
}

