import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { TransferenciaLoteDTO } from '@project/src/app/model/financeiro/transferencia/transferencia-lote-dto.model';
import { ArquivoDTO } from '@project/src/app/model/sistema/arquivo-dto.model';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import { StepStructure } from '../../../../cnab/cnab-editar/cnab-editar.model';

@Component({
  selector: 'app-transferencia-lote-zeragem-upload',
  templateUrl: './transferencia-lote-zeragem-upload.component.html',
  styleUrls: ['./transferencia-lote-zeragem-upload.component.less']
})
export class TransferenciaLoteZeragemUploadComponent implements OnInit, StepStructure<TransferenciaLoteDTO> {
  form: FormGroup;
  dadosValidacao: TransferenciaLoteDTO;

  @Input()
  data: TransferenciaLoteDTO;

  constructor(
    private transferenciaEndpointService: TransferenciaEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilCaniveteService: UtilCaniveteService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({ });
    this.form.addControl('locale', new FormControl(null, Validators.required));
    this.form.addControl('base64', new FormControl(null, Validators.required));
    this.form.addControl('mimeType', new FormControl(null, Validators.required));
    this.form.addControl('nome', new FormControl(null, Validators.required));
    this.form.addControl('tamanho', new FormControl(null, Validators.required));
    this.form.addControl('validado', new FormControl(null, Validators.required));
    this.downloadFalhas();
  }

  async downloadFalhas() {
    if (this.data && this.data.id >= 0) {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      });
      this.dadosValidacao = await this.transferenciaEndpointService.downloadFalhasZeragem(this.data.id).toPromise();
      this.form.patchValue(this.dadosValidacao.arquivo);
      if (this.dadosValidacao && this.dadosValidacao.status) {
        this.form.get('validado').patchValue(true);
      } else {
        this.form.get('validado').patchValue(null);
      }
      this.changeDetectorRef.detectChanges();
      Swal.close();
    }
  }

  getForm(): AbstractControl {
    return this.form;
  }

  isValid(): boolean {
    return this.form.valid;
  }
  parse(model: TransferenciaLoteDTO): TransferenciaLoteDTO {
    model = model || { };
    model.arquivo = this.form.value;
    return model;
  }

  async upload(arquivos: ArquivoDTO[]) {
    if (arquivos && arquivos.length) {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      });
      const arquivo = arquivos[0];
      this.form.patchValue(arquivo);
      this.dadosValidacao = await this.transferenciaEndpointService.validarLoteZeragem(arquivo).toPromise();
      if (this.dadosValidacao && this.dadosValidacao.status) {
        this.form.get('validado').patchValue(true);
      } else {
        this.form.get('validado').patchValue(null);
      }
      this.changeDetectorRef.detectChanges();
      await Swal.close();
    }
  }

  download() {
    this.utilCaniveteService.downloadFile(this.dadosValidacao.arquivo.base64,
      `v${moment().format('YYYY-MM-DD-HH-mm-SS')}_${this.dadosValidacao.arquivo.nome}`);
  }
}