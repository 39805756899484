export interface INgxLoadingConfig {
    backdropBorderRadius?: string;
    backdropBackgroundColour?: string;
    fullScreenBackdrop?: boolean;
    animationType?: string;
    primaryColour?: string;
    secondaryColour?: string;
    tertiaryColour?: string;
    [key: string]: string | boolean | undefined;
}
export class NgxLoadingConfig implements INgxLoadingConfig {
    backdropBorderRadius?: string;
    backdropBackgroundColour?: string;
    fullScreenBackdrop?: boolean;
    animationType?: string;
    primaryColour?: string;
    secondaryColour?: string;
    tertiaryColour?: string;
    [key: string]: string | boolean | undefined;
/**
 * @param {?=} config
 */
constructor(config: INgxLoadingConfig = {}) {
        this.backdropBorderRadius = config.backdropBorderRadius;
        this.backdropBackgroundColour = config.backdropBackgroundColour;
        this.fullScreenBackdrop = config.fullScreenBackdrop;
        this.animationType = config.animationType;
        this.primaryColour = config.primaryColour;
        this.secondaryColour = config.secondaryColour;
        this.tertiaryColour = config.tertiaryColour;
    }
}

function NgxLoadingConfig_tsickle_Closure_declarations() {
/** @type {?} */
NgxLoadingConfig.prototype.backdropBorderRadius;
/** @type {?} */
NgxLoadingConfig.prototype.backdropBackgroundColour;
/** @type {?} */
NgxLoadingConfig.prototype.fullScreenBackdrop;
/** @type {?} */
NgxLoadingConfig.prototype.animationType;
/** @type {?} */
NgxLoadingConfig.prototype.primaryColour;
/** @type {?} */
NgxLoadingConfig.prototype.secondaryColour;
/** @type {?} */
NgxLoadingConfig.prototype.tertiaryColour;
}


export const /** @type {?} */ ngxLoadingAnimationTypes = {
    chasingDots: 'chasing-dots',
    circle: 'sk-circle',
    circleSwish: 'circleSwish',
    cubeGrid: 'sk-cube-grid',
    doubleBounce: 'double-bounce',
    none: 'none',
    pulse: 'pulse',
    rectangleBounce: 'rectangle-bounce',
    rotatingPlane: 'rotating-plane',
    threeBounce: 'three-bounce',
    wanderingCubes: 'wandering-cubes'
};
