<h2 mat-dialog-title class="titulo">Atenção!</h2>
<div mat-dialog-content class="pad">
  <form [formGroup]="form" class="box-body input-size">
    <div class="row" style="justify-content: center;">
      <p class="pergunta">Tem certeza que deseja remover a
        {{ dataDialog.tipoCobranca === 'assinatura' ? 'assinatura' : 'cobrança' }}?
      </p>
    </div>
    <br>
    <div class="row" style="justify-content: center;">
      <mat-checkbox formControlName="notificarCliente">
        Notificar meu cliente sobre a exclusão desta cobrança.</mat-checkbox>
    </div>
    <div class="row" style="justify-content: center;" *ngIf="dataDialog.tipoCobranca !== 'avulsa'">
      <mat-checkbox formControlName="dms">
        Remover também cobranças pendentes posteriores dessa
        {{ dataDialog.tipoCobranca === 'assinatura' ? 'assinatura' : 'cobrança' }}</mat-checkbox>
    </div>
    <div class="row" style="justify-content: center; margin-top: 6px;"
      *ngIf="dataDialog.tipoCobranca !== 'avulsa' && form.get('dms').value === true">
      <div class="col-12">
        <mat-label class="label-exclusao">Digite <b>EXCLUIR</b> para confirmar a
          exclusão
        </mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="keyExclusao" type="text" />
          <mat-error>{{ getErrorMessage(form, "keyExclusao") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>
<div mat-dialog-actions class="row espacamento">
  <div [hidden]="!processando" class="col-12" style="padding-bottom: 20px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="col-12 col-md-5">
    <button (click)="fecha()" type="button" class="btn btn-default btn-block" style="color: #fff;" mat-raised-button>
      Cancelar
    </button>
  </div>

  <div class="col-12 col-md-5">
    <button id="btn_remover" (click)="excluir()" [disabled]="!form.valid || processando" class="btn btn-block fundoCorPrimaria"
     
      mat-raised-button>
      Remover
    </button>
  </div>
</div>