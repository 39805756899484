export const EspecieCobranca = [
  { codigo: '01', descricao: '01 - CH Cheque' },
  { codigo: '02', descricao: '02 - DM Duplicata Mercantil' },
  { codigo: '03', descricao: '03 - DMI Duplicata Mercantil Indicação' },
  { codigo: '04', descricao: '04 - DS Duplicata de Serviço' },
  { codigo: '05', descricao: '05 - DSI Duplicata de Serviço Indicação' },
  { codigo: '06', descricao: '06 - DR Duplicata Rural' },
  { codigo: '07', descricao: '07- LC Letra de Câmbio' },
  { codigo: '08', descricao: '08 - NCC Nota de Crédito Comercial' },
  { codigo: '09', descricao: '09 - NCE Nota de Crédito Exportação' },
  { codigo: '10', descricao: '10 - NCI Nota de Crédito Industrial' },
  { codigo: '11', descricao: '11 - NCR Nota de Crédito Rural' },
  { codigo: '12', descricao: '12 - NP Nota Promissória' },
  { codigo: '13', descricao: '13 - NPR Nota Promissória Rural' },
  { codigo: '14', descricao: '14 - TM Triplicata Mercantil' },
  { codigo: '15', descricao: '15 - TS Triplicata de Serviço' },
  { codigo: '16', descricao: '16 - NS Nota de Seguro' },
  { codigo: '17', descricao: '17 - RC Recibo' },
  { codigo: '18', descricao: '18 - FAT Bloqueto' },
  { codigo: '19', descricao: '19 - ND Nota de Débito' },
  { codigo: '20', descricao: '20 - AP Apólice de Seguro' },
  { codigo: '21', descricao: '21 - ME Mensalidade Escolar' },
  { codigo: '22', descricao: '22 - PC Parcela de Consórcio' },
  { codigo: '23', descricao: '23 - NF Nota Fiscal' },
  { codigo: '24', descricao: '24 - DD Documento de Dívida' },
  { codigo: '25', descricao: '25 - Cédula de Produto Rural' },
  { codigo: '26', descricao: '26 - Warrant' },
  { codigo: '27', descricao: '27 - Dívida Ativa de Estado' },
  { codigo: '28', descricao: '28 - Dívida Ativa de Município' },
  { codigo: '29', descricao: '29 - Dívida Ativa da União' },
  { codigo: '30', descricao: '30 - Encargos condominiais' },
  { codigo: '31', descricao: '31 - Cartão de Crédito' },
  { codigo: '32', descricao: '32 - Boleto proposta' },
  { codigo: '33', descricao: '33 - Boleto de Depósito e Aporte' },
  { codigo: '99', descricao: '99 - Outros' }
];