import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { UtilCaniveteService } from '@service/util/util-canivete.service';

@Pipe({
	name: 'formErrors',
	pure: false
})
export class FormErrorsPipe implements PipeTransform {

	constructor() { }

	transform(form: AbstractControl) {
		if (form instanceof FormControl) {
			if (form.errors) {
				return form.errors;
			}
		} else if (form instanceof FormArray) {
			if (form.controls.length) {
				return form.controls.map(this.transform.bind(this));
			}
		} else if (form instanceof FormGroup) {
			const response = form.errors ? {
				_: form.errors
			}: {};
			Object.keys(form.controls).forEach(key => {
				const error = this.transform(form.get(key));
				if (error) {
					response[key] = error;
				}
			});
			if (Object.keys(response).length) {
				return response;
			}
		}
	}

}
