import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateBarComponent } from './date-bar.component';
import { IconsModule } from '../../icons.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';



@NgModule({
  declarations: [DateBarComponent],
  exports: [DateBarComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    IconsModule
  ]
})
export class DateBarModule { }
