import { RootEndpointService } from './../../../../service/root-endpoint.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-horario-cadastro',
  templateUrl: './horario-cadastro.component.html',
  styleUrls: ['./horario-cadastro.component.less']
})
export class HorarioCadastroComponent implements OnInit {

  processando: boolean = false;
  inicio: string = '';
  fim: string = '';
  cadastroFds: boolean = false;
  configSistema: any;
  cadastroBloqueado: boolean = false;
  ocultarBotao: boolean = false;

  disabilitaOpcoes: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<HorarioCadastroComponent>,
    public rootEndpointService: RootEndpointService
  ) { }

  ngOnInit(): void {
    this.setarConfiguracao();
    this.configuracaoSistema();
  }

  fecha() {
    this.dialogRef.close();
  }

  configuracaoSistema() {
    this.rootEndpointService.getConfiguracoesSistema().subscribe(res => {
      this.configSistema = res;
    });
  }

  setarConfiguracao() {
    this.rootEndpointService.getHorarioCadastro().subscribe(res => {
      if (res) {
        this.inicio = res.horarioInicioCadastro;
        this.fim = res.horarioFinalCadastro;
        this.cadastroFds = res.cadastroFinalDeSemana;
        this.cadastroBloqueado = res.bloqueioNovosCadastros;
        this.ocultarBotao = res.ocultarBotaoCadastro;

        if (this.cadastroBloqueado) {
          this.disabilitaOpcoes = true;
        }
      }
    });
  }

  setCadastroFds(event: any) {
    if (event.checked) {
      this.cadastroFds = true;
    }
    else {
      this.cadastroFds = false;
    }
  }

  setCadastroBloqueado(event: any) {
    if (event.checked) {
      this.cadastroBloqueado = true;
      this.disabilitaOpcoes = true;
      this.ocultarBotao = true;
    }
    else {
      this.cadastroBloqueado = false;
      this.disabilitaOpcoes = false;
      this.ocultarBotao = false;

    }
  }

  setOcultarBotao(event: any) {
    if (event.checked) {
      this.ocultarBotao = true;
      this.disabilitaOpcoes = true;
    }
    else {
      this.ocultarBotao = false;
      this.disabilitaOpcoes = false;
    }
  }


  cadastrarHorario() {

    this.configSistema.horarioInicioCadastro = this.inicio;
    this.configSistema.horarioFinalCadastro = this.fim;
    this.configSistema.cadastroFinalDeSemana = this.cadastroFds;
    this.configSistema.bloqueioNovosCadastros = this.cadastroBloqueado;
    this.configSistema.ocultarBotaoCadastro = this.ocultarBotao;

    this.processando = true;
    this.rootEndpointService.updateConfiguracoesSistema(this.configSistema).subscribe(res => {
      this.processando = false;
      if (res) {
        Swal.fire({
          icon: 'success',
          title: 'Configurações cadastradas com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.dialogRef.close(true);
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar configurações!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.dialogRef.close(false);
        })
      }
    })
    this.processando = false;
  }

}
