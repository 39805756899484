import { jsPDF } from 'jspdf';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ComprovanteQrCodeComponent } from './comprovante-qr-code/comprovante-qr-code.component';
import html2canvas from 'html2canvas';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';

@Component({
  selector: 'app-pix-modal-cobranca',
  templateUrl: './pix-modal-cobranca.component.html',
  styleUrls: ['./pix-modal-cobranca.component.less']
})
export class PixModalCobrancaComponent implements OnInit {

  processando: boolean = false;
  qrcode: string;
  payload: string;
  dadosQr: any;
  nome: any;
  loading: boolean = false;
  documento: any;
  idDocumento: any;

  constructor(
    private dialogRef: MatDialogRef<PixModalCobrancaComponent>,
    public toastr: ToastrService,
    public matDialog: MatDialog,
    private autenticacaoService: AutenticacaoEndpointService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.qrcode = this.data?.qrcode;
    this.payload = this.data?.payload;
    this.dadosQr = this.data?.dadosQr;
    this.idDocumento = this.data?.idDocumento;
    this.nome = this.autenticacaoService.regraEmpresa.empresa.pessoa.nome;
    this.documento = this.autenticacaoService.regraEmpresa.empresa.pessoa.documento;
  }


  fechar() {
    this.matDialog.closeAll();
  }

  copiarQrCode() {
    const link = this.payload;

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Qr code Copiado!", '', {
      positionClass: "toast-top-center",
      timeOut: 1000
    })
  }

  compartilharQrCode() {
    this.processando = true;
    const dialog = this.matDialog.open(ComprovanteQrCodeComponent, {
      data: {
        qrcode: this.qrcode,
        payload: this.payload,
        dadosQr: this.dadosQr,
        idDocumento: this.idDocumento
      }
    }).afterClosed().subscribe(() => {
      this.processando = false;
    });
  }

  compartilharQrCode2() {
    window.open("#/comprovante-qr-code/pix/" + this.idDocumento, "_blank");
  }


  exportarComoPDF() {
    this.loading = true;
    const data = document.getElementById('baixar-pdf');
    html2canvas(data, { scale: 2, useCORS: true }).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4');

      const pageWidth = 210;  // Largura da página A4
      const pageHeight = 297; // Altura da página A4

      let imgWidth = canvas.width;
      let imgHeight = canvas.height;

      // Calcula a nova altura e largura para preencher a altura da página A4
      let finalImgHeight = pageHeight;
      let finalImgWidth = (imgWidth * finalImgHeight) / imgHeight;

      // Se a largura ajustada for maior que a largura da página, ajuste-a
      if (finalImgWidth > pageWidth) {
        finalImgWidth = pageWidth;
        finalImgHeight = (imgHeight * finalImgWidth) / imgWidth;
      }

      // Centraliza a imagem na página
      const x = (pageWidth - finalImgWidth) / 2;
      const y = (pageHeight - finalImgHeight) / 2;

      pdf.addImage(contentDataURL, 'PNG', x, y, finalImgWidth, finalImgHeight);
      pdf.save('qrcode.pdf');
      this.loading = false;
    });
  }
}
