import { Observable, of } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AutenticacaoEndpointService } from '../autenticacao-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class JaLogadoService implements CanActivate, CanActivateChild, OnDestroy {
  constructor(
    private autenticacaoService: AutenticacaoEndpointService,
    private router: Router
  ) { }

  ngOnDestroy() { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.autenticacaoService.me().pipe(
      map(response => {
        let logado = false;
        if (response) {
          if (response.name) {
            logado = true;
          }
        }
        const usuario = response?.principal?.usuario
        if (logado && (!usuario.multifatorAtivado || (usuario.multifatorAtivado && usuario.multifatorValidado))) {
          this.router.navigate(['/privado/principal']);
          return false;
        }
        return true;
      }),
      catchError(err => {
        console.error('Falha em validar o login: ', err);
        return of(true);
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
