<div class="row">
  <div class="col-12">
    <form [formGroup]="form">
      <div class="row alinha">

        <div class="col-12" style="margin-bottom: 10px">
          <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">PERSONALIZAÇÃO
          </h4>
          <p style="font-size: 13px">Na listagem abaixo, você pode configurar como preferir algumas áreas do sistema.
          </p>
        </div>

        <mat-checkbox formControlName="habilitarPersonalizacao" class="col-12" style="margin-bottom: 13px">
          <span style="color: #6D6D6D">Habilitar personalização</span>
        </mat-checkbox>
      </div>
    </form>
  </div>
</div>