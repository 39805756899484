import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormArray,
  ValidatorFn,
} from '@angular/forms';
import { addMonths, addYears, isSameDay, subYears } from 'date-fns';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import Swal from 'sweetalert2';
import { EmitirCobrancaComponent } from '../emitir-cobranca.component';
import { environment } from '@project/src/environments/environment.default';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { ChavePixDTO } from '@project/src/app/model/financeiro/transferencia/chave-pix-dto-model';
import { EspecieCobranca } from '@project/src/app/model/cobranca/especie.model';
import { AceiteCobranca } from '@project/src/app/model/cobranca/aceite-cobranca.model';
import { AutorizacaoRepasseCobranca } from '@project/src/app/model/cobranca/autorizacao-repasse-cobranca.model';
import { TipoProtesto } from '@project/src/app/model/cobranca/tipo-protesto.model';

@Component({
  selector: 'app-stepper2',
  templateUrl: './stepper2.component.html',
  styleUrls: ['./stepper2.component.less'],
})
export class Stepper2Component implements OnInit, OnDestroy, AfterViewInit {
  public listaEspecie = EspecieCobranca;
  public listaAceite = AceiteCobranca;
  public listaAutorizacaoRepasse = AutorizacaoRepasseCobranca;
  public listaTipoProtesto = TipoProtesto;
  todayDate = new Date();
  isVencida: boolean = false;
  configEmpresaLogada: any = {};
  valorMinimo: number = 15;
  edicaoCobranca = false;
  @Input()
  public component: EmitirCobrancaComponent;
  @Input()
  public form: FormGroup;
  @Input()
  public tipoCobranca: number;
  env = environment;
  @Input()
  public tipoConta: string;
  @Input()
  public isPix: boolean;
  @Input()
  public isProtesto: boolean;

  desabilitaValor = false;

  listaChavesPix: ChavePixDTO[] = [];
  empresaId: number;

  dataMaxima: Date;
  desabilitaTroco = false;
  areaPix = false;

  constructor(
    private autenticacao: AutenticacaoEndpointService,
    private pixService: PixEndpointService
  ) {
    this.configEmpresaLogada =
      this.autenticacao.regraEmpresa.empresa.configuracao;
    this.tipoConta = this.autenticacao.regraEmpresa.empresa.tipoConta;
    this.empresaId = this.autenticacao.regraEmpresa.empresa.id;
    this.areaPix = this.autenticacao.regraEmpresa.empresa.configuracao.areaPix;
  }

  ngOnInit() {
    this.todayDate.setHours(23, 59, 59, 59);

    this.form.addControl(
      'metodoPagamento',
      new FormControl('boleto', [Validators.required])
    );
    this.form.addControl(
      'valorNominal',
      new FormControl('', [this.getValorMinimo.bind(this), Validators.required])
    ); // 1-fixa, 2-assinatura, 3-parcelada
    this.form.addControl(
      'intervaloAssinatura',
      new FormControl('mensal', [Validators.required])
    );
    this.form.addControl(
      'dataVencimento',
      new FormControl(this.todayDate, [Validators.required])
    );
    this.form.addControl(
      'fimAssinatura',
      new FormControl(this.todayDate, [Validators.required])
    );
    this.form.addControl('numeroParcelas', new FormControl(1));
    this.form.addControl(
      'mensagem',
      new FormControl('', [Validators.required])
    );
    this.form.addControl('aplicarMulta', new FormControl(false));
    this.form.addControl('aplicarDesconto', new FormControl(false));
    this.form.addControl('juros', new FormControl(''));
    this.form.addControl('multa', new FormControl(''));
    this.form.addControl('pagamentoAposVencimento', new FormControl(false));
    this.form.addControl(
      'quantidadeDiasBaixa',
      new FormControl(0, [Validators.pattern(/^[0-9]+$/)])
    );
    this.form.addControl('descontos', new FormArray([]));
    this.form.addControl(
      'enviarArquivos',
      new FormControl({ value: false, disabled: true })
    );
    this.form.addControl(
      'emitirNotaFiscal',
      new FormControl({ value: false, disabled: true })
    );
    this.form.addControl(
      'configEmpresaLogada',
      new FormControl(this.configEmpresaLogada)
    );
    this.form.addControl('dataBaixaAutomatica', new FormControl());
    this.form.addControl(
      'incluirNumeroDocumentoEspecie',
      new FormControl(false)
    );
    this.form.addControl('numeroDocumento', new FormControl(''));
    this.form.addControl('especie', new FormControl(''));
    this.form.addControl('incluirProtesto', new FormControl(false));
    this.form.addControl(
      'aceite',
      new FormControl({ value: '', disabled: true }, [Validators.required])
    );
    this.form.addControl(
      'autorizacaoRepasse',
      new FormControl({ value: '', disabled: true }, [Validators.required])
    );
    this.form.addControl(
      'tipoProtesto',
      new FormControl({ value: '', disabled: true }, [Validators.required])
    );
    this.form.addControl(
      'diasDeProtesto',
      new FormControl(
        { value: this.configEmpresaLogada?.diasDeProtesto, disabled: true },
        [Validators.required]
      )
    );

    this.form
      .get('intervaloAssinatura')
      .setValidators([this.obrigatoriosTipoCobranca(this.form, 2)]);
    this.form
      .get('numeroParcelas')
      .setValidators([this.obrigatoriosTipoCobranca(this.form, 3)]);
    this.form
      .get('dataVencimento')
      .setValidators([this.validacaoAplicarDesconto(this.form)]);

    this.setDataFim({ value: 'mensal' });

    //PIX
    this.form.addControl(
      'chavePixCobrador',
      new FormControl('', [Validators.required])
    );
    this.form.addControl(
      'valorPix',
      new FormControl(0, {
        validators: [Validators.required, this.pixDinamicoValorValidator], // exemplo de adição de múltiplos validadores
      })
    );
    this.form.addControl('descricaoPix', new FormControl(''));

    this.form.addControl('expiracaoQR', new FormControl(null));
    this.form.addControl('controleExpiracaoQr', new FormControl(false));

    this.form.addControl('modalidadeAlteracao', new FormControl(0));
    this.form.addControl('controleAlteracaoValor', new FormControl(false));

    this.form.addControl('valorTroco', new FormControl(null));
    this.form.addControl('controleValorTroco', new FormControl(false));
    this.form.addControl('modalidadeAltTroco', new FormControl(null));
    this.form.addControl('controleAltTroco', new FormControl(null));
    this.form.addControl('controleModalidadeAltTroco', new FormControl(false));
    this.form.addControl('trocoPermitirAltValor', new FormControl(0));

    this.form.addControl('controleModalidadeAltSaque', new FormControl(false));
    this.form.addControl('modalidadeAltSaque', new FormControl(null));
    this.form.addControl('valorSaque', new FormControl(null));

    this.form.addControl('configuracoesVencimento', new FormControl(false));
    this.form.addControl('dtVenc', new FormControl(null));
    this.form.addControl('jurosPix', new FormControl(null));
    this.form.addControl('multaPix', new FormControl(null));
    this.form.addControl(
      'descontoPix',
      new FormControl(null, this.pixValueValidator(this.form))
    );
    this.form.addControl(
      'abatimentoPix',
      new FormControl(null, this.pixValueValidator(this.form))
    );

    this.form.addControl('reutilizavel', new FormControl(false));

    this.form.addControl('documentoPagadorPix', new FormControl(null));
    this.form.addControl('nomePagadorPix', new FormControl(null));
    this.form.addControl('controleDocumentoPagadorPix', new FormControl(false));

    this.form.get('descontoPix').updateValueAndValidity();
    this.form.get('abatimentoPix').updateValueAndValidity();

    if (this.env.modulos.pix.gerarQrCode) {
      this.listarChaves();
    }
    this.dataMaxima = addYears(new Date(), 1);

    if (this.isProtesto) {
      this.form.get('valorNominal').disable();
      this.form.get('dataVencimento').disable();
      this.form.get('mensagem').disable();
      this.form.get('aplicarMulta').disable();
      this.form.get('multa').disable();
      this.form.get('juros').disable();
      this.form.get('pagamentoAposVencimento').disable();
      this.form.get('incluirProtesto').setValue(true);
      this.controleProtesto({ checked: true });
    }

    if (!this.isPix) {
      this.form.get('chavePixCobrador').disable();
    }
  }

  ngAfterViewInit() {
    // Atrasar a rolagem para permitir que outros elementos carreguem
    setTimeout(() => {
      const element = document.getElementById('protesto');
      if (this.isProtesto) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  }

  addDesconto(valor?: number, data?: string, desconto?: number) {
    if (valor && data && desconto) this.edicaoCobranca = true;
    let formDesconto = this.form.get('descontos') as FormArray;
    formDesconto.push(
      new FormGroup({
        dataLimiteDesconto: new FormControl(
          { value: data ? new Date(data) : '', disabled: !!data },
          [Validators.required]
        ),
        desconto: new FormControl(
          { value: desconto || '', disabled: !!desconto },
          [Validators.required]
        ),
        valorDesconto: new FormControl({ value: valor || '', disabled: true }, [
          Validators.required,
        ]),
      })
    );
    if (this.edicaoCobranca && !this.isProtesto) {
      (formDesconto.at(formDesconto.length - 1) as FormGroup)
        .get('dataLimiteDesconto')
        ?.markAsTouched();
    }
  }

  verificaVencimento(data?: Date) {
    let dataVencimento = new Date(data);
    if (dataVencimento < this.todayDate) {
      this.isVencida = true;
    } else {
      this.isVencida = false;
    }
  }

  removeDesconto(index: number) {
    let formDesconto = this.form.get('descontos') as FormArray;
    formDesconto.removeAt(index);
    if (formDesconto.length < 1) {
      this.form.get('aplicarDesconto').setValue(false);
    }
  }

  onChangeDesconto(event, index: number) {
    let formDesconto = this.form.controls['descontos'] as FormArray;
    let formPosicao = formDesconto.at(index) as FormGroup;
    let desconto = Number(event.target.value.replace('%', ''));
    let valorNominal = Number(this.form.get('valorNominal').value);
    let valorDesconto = valorNominal * (desconto / 100);
    valorDesconto = Number(valorDesconto.toFixed(2));
    formPosicao.get('valorDesconto').setValue(valorDesconto);
    formPosicao
      .get('desconto')
      .setErrors(this.validacaoValorDesconto(valorDesconto, valorNominal));
    formPosicao
      .get('valorDesconto')
      .setErrors(this.validacaoValorDesconto(valorDesconto, valorNominal));
  }

  troca(e) {
    if (
      e.key == 'Backspace' ||
      e.key == ' ' ||
      e.key == 'ArrowLeft' ||
      e.key == 'ArrowRight'
    ) {
      if (e.target.value == '') {
        e.target.value = e.target.value + '%';
      }
      return;
    }
    e.target.value = e.target.value.replace(/%/i, '');
    e.target.value = e.target.value + '%';
  }

  ngOnDestroy() {}

  obrigatoriosTipoCobranca(form: FormGroup, tipo) {
    return (control: AbstractControl) => {
      const intervaloAssinatura = form.get('intervaloAssinatura').value || null,
        numeroParcelas = form.get('numeroParcelas').value || null;

      if (control.invalid) {
        return;
      }

      if (this.tipoCobranca) {
        if (this.tipoCobranca === 2 && this.tipoCobranca === tipo) {
          if (intervaloAssinatura !== '' && intervaloAssinatura !== null) {
            return null as ValidationErrors;
          } else {
            return { obrigatorio: true } as ValidationErrors;
          }
        } else if (this.tipoCobranca === 3 && this.tipoCobranca === tipo) {
          if (numeroParcelas !== '') {
            return null as ValidationErrors;
          } else {
            return { obrigatorio: true } as ValidationErrors;
          }
        }
      }
    };
  }

  validacaoAplicarDesconto(form: FormGroup) {
    return (control: AbstractControl) => {
      const dataVencimento = new Date(form.get('dataVencimento').value);

      if (control.invalid) return;

      if (
        isSameDay(dataVencimento, this.todayDate) ||
        this.form.get('metodoPagamento').value === 'boletoPix'
      ) {
        form.get('aplicarDesconto').setValue(false);
        form.get('aplicarDesconto').disable();
        this.controleDesconto({ checked: false });
        this.form.get('aplicarDesconto').setErrors({ datadesconto: true });
      } else if (
        !isSameDay(dataVencimento, this.todayDate) &&
        form.get('aplicarDesconto').disabled
      ) {
        if (form.get('metodoPagamento').value !== 'pix') {
          form.get('aplicarDesconto').enable();
        }
        this.form.get('aplicarDesconto').setErrors(null);
      }

      return null as ValidationErrors;
    };
  }

  validacaoValorDesconto(valorDesconto: number, valorNominal: number) {
    if (valorDesconto > 0) {
      if (valorNominal === 15) {
        return { descontominimo: true } as ValidationErrors;
      } else if (valorDesconto >= valorNominal - 15) {
        return { descontomuitoalto: true } as ValidationErrors;
      } else {
        return null as ValidationErrors;
      }
    } else return { valornulo: true } as ValidationErrors;
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required') ||
        form.get(field).hasError('obrigatorio')
        ? 'Campo requerido.'
        : form.get(field).hasError('minlength')
        ? 'Número de celular inválido.'
        : form.get(field).hasError('email')
        ? 'Email inválido.'
        : form.get(field).hasError('min')
        ? 'O valor de cobrança deve ser no minimo R$ 15,00.'
        : form.get(field).hasError('max')
        ? 'O valor da cobrança deve ser no máximo de ' +
          new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'BRL',
          }).format(this.configEmpresaLogada.valorMaximoEmissaoBoleto) +
          '. É preciso solicitar o aumento do valor com seu gerente de conta.'
        : form.get(field).hasError('datadesconto')
        ? 'Não é possivel aplicar desconto em cobranças com vencimento para hoje.'
        : form.get(field).hasError('pix')
        ? 'Não é possível aplicar desconto em cobranças Pix.'
        : form.get(field).hasError('descontominimo')
        ? 'Essa cobrança não pode ter desconto porque o seu valor é igual ao valor mínimo para essa cobrança (R$ 15,00).'
        : form.get(field).hasError('descontomuitoalto')
        ? 'Não é possivel aplicar um desconto com esse valor para essa cobrança.'
        : form.get(field).hasError('valornulo')
        ? 'Desconto deve ser maior que 0.'
        : form.get(field).hasError('matDatepickerMin')
        ? 'A data do desconto deve ser posterior a ' +
          new Intl.DateTimeFormat('pt-BR').format(
            form.get(field).errors['matDatepickerMin'].min
          ) +
          '.'
        : '';
    }
  }

  setFormaPagamento(metodoPagamento) {
    if (this.form.get('metodoPagamento').enabled === true) {
      this.form.get('metodoPagamento').setValue(metodoPagamento);
      this.validacaoAplicarDesconto(this.form);

      if (this.form.get('metodoPagamento').value === 'pixEstatico') {
        this.form.get('controleExpiracaoQr').setValue(false);
        this.form.get('controleAlteracaoValor').setValue(false);
        this.form.get('controleModalidadeAltTroco').setValue(false);
        this.form.get('controleModalidadeAltSaque').setValue(false);
        this.form.get('controleValorTroco').setValue(false);
        this.form.get('configuracoesVencimento').setValue(false);
        this.form.get('reutilizavel').setValue(false);
      }
    } else {
      Swal.fire({
        title: 'Informação',
        text: 'Não é permitido alterar o tipo de pagamento da cobrança.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  setDataFim(event) {
    if (event.value === 'mensal') {
      this.form.get('fimAssinatura').setValue(addMonths(this.todayDate, 1));
    } else if (event.value === 'trimestral') {
      this.form.get('fimAssinatura').setValue(addMonths(this.todayDate, 3));
    } else if (event.value === 'semestral') {
      this.form.get('fimAssinatura').setValue(addMonths(this.todayDate, 6));
    } else if (event.value === 'anual') {
      this.form.get('fimAssinatura').setValue(addMonths(this.todayDate, 12));
    }
  }

  controleMulta(event) {
    const aplicarMulta = event.checked;

    if (aplicarMulta === false) {
      this.form.get('juros').setValue('');
      this.form.get('multa').setValue('');
      this.form.get('pagamentoAposVencimento').setValue(false);
      this.form.get('dataBaixaAutomatica').patchValue(null);
      this.form.get('quantidadeDiasBaixa').setValue(0);
    } else if (aplicarMulta === true) {
      this.form.get('juros').setValue(this.configEmpresaLogada.juros + '%');
      this.form.get('multa').setValue(this.configEmpresaLogada.multa + '%');
    }
  }

  controleDiasDeBaixa(event) {
    const pagamentoAposVencimento = event.checked;

    if (pagamentoAposVencimento === false) {
      this.form.get('quantidadeDiasBaixa').setValue(0);
      this.form.get('dataBaixaAutomatica').setValue(null);
    } else {
      this.form.get('quantidadeDiasBaixa').setValue(1);
      this.component.buscaDataBaixa();
      this.form.get('pagamentoAposVencimento').setValue(true);
      this.form.get('dataBaixaAutomatica').setValue(this.component.dataBaixa);
    }
  }

  controleDesconto(event) {
    const aplicarDesconto = event.checked;
    const formDescontos = this.form.get('descontos') as FormArray;

    if (aplicarDesconto === false) {
      const tamanho = formDescontos.length;
      for (let i = tamanho; i > 0; i--) {
        formDescontos.removeAt(i - 1);
      }
    } else this.addDesconto();
  }

  controleNumeroDocumentoEspecie(event) {
    const incluirNumeroDocumento = event.checked;
    if (incluirNumeroDocumento === false) {
      this.form.get('numeroDocumento').setValue('');
    }
  }

  controleProtesto(event) {
    const incluirProtesto = event.checked;
    if (incluirProtesto === false) {
      this.form.get('aceite').setValue('');
      this.form.get('aceite').disable();
      this.form.get('autorizacaoRepasse').setValue('');
      this.form.get('autorizacaoRepasse').disable();
      this.form.get('tipoProtesto').setValue('');
      this.form.get('tipoProtesto').disable();
      this.form.get('diasDeProtesto').setValue('');
      this.form.get('diasDeProtesto').disable();
      if (this.isProtesto) {
        this.form.get('diasDeProtesto').enable();
        this.form
          .get('diasDeProtesto')
          .setValue(this.configEmpresaLogada?.diasDeProtesto);
      }
    } else {
      if (this.isProtesto) {
        this.form.get('diasDeProtesto').enable();
        this.form
          .get('diasDeProtesto')
          .setValue(this.configEmpresaLogada?.diasDeProtesto);
      }
      this.form.get('aceite').enable();
      this.form.get('aceite').setValue('');
      this.form.get('autorizacaoRepasse').enable();
      const autorizacaoRepasse = this.listaAutorizacaoRepasse.find(
        (autorizacaoRepasse) => autorizacaoRepasse.codigo === 'NAO'
      );
      this.form
        .get('autorizacaoRepasse')
        .setValue(
          this.configEmpresaLogada?.habilitarRepasseCustasProtesto === true
            ? ''
            : autorizacaoRepasse.codigo
        );
      this.form.get('tipoProtesto').enable();
      const tipoProtesto = this.listaTipoProtesto.find(
        (tipoProtesto) => tipoProtesto.codigo === 'COMUM'
      );
      this.form.get('tipoProtesto').setValue(tipoProtesto.codigo);
      this.form.get('diasDeProtesto').enable();
      this.form
        .get('diasDeProtesto')
        .setValue(this.configEmpresaLogada?.diasDeProtesto);
    }
  }

  onChangeDataVencimento(element) {
    if (this.tipoCobranca === 2) {
      let dataFim = new Date(this.form.get('fimAssinatura').value);
      let dataInicio = new Date(element.value);
      let novaDataFim = new Date(
        dataFim.getFullYear(),
        dataFim.getMonth(),
        dataInicio.getDate()
      );
      this.form.get('fimAssinatura').setValue(novaDataFim);
    }
    this.verificaVencimento(new Date(this.form.get('dataVencimento').value));
  }

  maxControl(tipo, valor) {
    switch (tipo) {
      case 'multa':
        if (Number(valor) > 2) {
          this.form.get('multa').setValue(2);
        } else if (parseInt(valor, 10) < 0) {
          this.form.get('multa').setValue(0);
        } else {
          this.form.get('multa').setValue(valor);
        }
        break;
      case 'juros':
        if (Number(valor) > 1) {
          this.form.get('juros').setValue(1);
        } else if (parseInt(valor, 10) < 0) {
          this.form.get('juros').setValue(0);
        } else {
          this.form.get('juros').setValue(valor);
        }
    }
  }

  mascaraData(event: any, field: string) {
    let data = event.target.value;
    data = data.replace(/\D/g, '');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d{2})$/, '$1$2');
    document.getElementById(field)['value'] = data;
  }

  pixDinamicoValorValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const metodoPagamento = group.get('metodoPagamento')?.value;
    const valorPix = group.get('valorPix')?.value;
    // Verifica se o método é pixDinamico e o valorPix é 0
    return metodoPagamento === 'pixDinamico' && valorPix === 0
      ? { valorPixInvalido: true }
      : null;
  };

  getValorMinimo() {
    if (this.form.get('numeroParcelas') && this.form.get('valorNominal')) {
      var numParcelas;
      if (!this.component.dataDialog.idCobranca) {
        numParcelas = this.form.get('numeroParcelas').value;
      } else {
        numParcelas = 1;
      }

      var valor = this.form.get('valorNominal').value;
      if (valor / numParcelas < this.valorMinimo) {
        this.form
          .get('valorNominal')
          .setErrors({ min: true } as ValidationErrors);
        return { min: true } as ValidationErrors;
      } else {
        if (
          valor / numParcelas >
          this.configEmpresaLogada.valorMaximoEmissaoBoleto
        ) {
          this.form
            .get('valorNominal')
            .setErrors({ max: true } as ValidationErrors);
          return { max: true } as ValidationErrors;
        } else {
          this.form.get('valorNominal').setErrors(null as ValidationErrors);
          return null as ValidationErrors;
        }
      }
    }
  }

  alterouParcelas(event: any) {
    this.getValorMinimo();
  }

  listarChaves() {
    this.pixService.listarChaves(this.empresaId).subscribe(
      (res) => {
        this.listaChavesPix = res;
        this.form
          .get('chavePixCobrador')
          .setValue(this.listaChavesPix[0]?.chave);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setChaveValue(chave) {
    this.form.get('chavePixCobrador').setValue(chave);
    console.log(chave);
  }

  transformTipoPix(param) {
    switch (param) {
      case 0:
        return 'CPF';
      case 1:
        return 'CNPJ';
      case 2:
        return 'Email';
      case 3:
        return 'Celular';
      case 4:
        return 'Aleatória';
    }
  }

  controleExpiracaoQr(event) {
    const expiracaoQR = event.checked;
    if (expiracaoQR === false) {
      this.form.get('controleExpiracaoQr').setValue(false);
    }
  }

  controleAlteracaoValor(event) {
    const controleAlteracaoValor = event.checked;
    this.form.get('controleAlteracaoValor').setValue(controleAlteracaoValor);
    if (controleAlteracaoValor === false) {
      this.form.get('modalidadeAlteracao').setValue(0);
      this.form.get('valorTroco').setValue(null);
    }
    if (controleAlteracaoValor === true) {
      this.form.get('modalidadeAlteracao').setValue(1);
    }
  }

  controleConfiguracoesVencimento(event) {
    const controleConfiguracoesVencimento = event.checked;
    this.form
      .get('configuracoesVencimento')
      .setValue(controleConfiguracoesVencimento);
    if (controleConfiguracoesVencimento === true) {
      //controle de alteração de valor
      this.form.get('controleValorTroco').setValue(false);
      this.form.get('controleModalidadeAltTroco').setValue(false);
      this.form.get('controleAlteracaoValor').setValue(false);
      this.form.get('controleDocumentoPagadorPix').setValue(true);

      //reseta valores
      this.form.get('valorSaque').setValue(null);
      this.form.get('modalidadeAltTroco').setValue(null);
      this.form.get('valorTroco').setValue(null);
      this.form.get('modalidadeAltSaque').setValue(null);
    }
  }

  controleReutilizavel(event) {
    const reutilizavel = event.checked;
    this.form.get('reutilizavel').setValue(reutilizavel);
  }

  controleDocumentoPagadorPix(event) {
    const documentoPagadorPix = event.checked;
    this.form.get('controleDocumentoPagadorPix').setValue(documentoPagadorPix);
  }

  controleValorTroco(event) {
    const controleValorTroco = event.checked;
    this.form.get('controleValorTroco').setValue(controleValorTroco);
    if (controleValorTroco === false) {
      this.form.get('valorTroco').setValue(null);
      this.form.get('modalidadeAltTroco').setValue(null);
      this.form.get('controleModalidadeAltTroco').setValue(false);
    }
    if (controleValorTroco === true) {
      //controle de alteração de valor
      this.form.get('controleExpiracaoQr').setValue(false);
      this.form.get('controleAlteracaoValor').setValue(false);

      //reseta valores
      this.form.get('modalidadeAlteracao').setValue(null);
      this.form.get('modalidadeAltTroco').setValue(0);
      this.form.get('dtVenc').setValue(null);
      this.form.get('jurosPix').setValue(null);
      this.form.get('multaPix').setValue(null);
      this.form.get('descontoPix').setValue(null);
      this.form.get('abatimentoPix').setValue(null);
    }
  }

  controleModalidadeAltTroco(event) {
    const controleModalidadeAltTroco = event.checked;
    this.form
      .get('controleModalidadeAltTroco')
      .setValue(controleModalidadeAltTroco);
    if (this.form.get('controleModalidadeAltTroco').value === false) {
      this.form.get('modalidadeAltTroco').setValue(null);
    }
    if (
      controleModalidadeAltTroco === false &&
      this.form.get('controleModalidadeAltTroco').value == true
    ) {
      this.form.get('modalidadeAltTroco').setValue(0);
      this.form.get('dtVenc').setValue(null);
      this.form.get('jurosPix').setValue(null);
      this.form.get('multaPix').setValue(null);
      this.form.get('descontoPix').setValue(null);
      this.form.get('abatimentoPix').setValue(null);
    }
    if (controleModalidadeAltTroco === true) {
      //controle de alteração de valor
      this.form.get('controleExpiracaoQr').setValue(false);
      this.form.get('controleAlteracaoValor').setValue(false);
      this.form.get('controleModalidadeAltSaque').setValue(false);

      this.form.get('modalidadeAltTroco').setValue(1);
      this.form.get('modalidadeAltSaque').setValue(null);
      this.form.get('dtVenc').setValue(null);
      this.form.get('jurosPix').setValue(null);
      this.form.get('multaPix').setValue(null);
      this.form.get('descontoPix').setValue(null);
      this.form.get('abatimentoPix').setValue(null);
    }
  }

  controleCheckboxSaque(event) {
    const controleCheckboxSaque = event.checked;
    if (controleCheckboxSaque === true) {
      this.form.get('modalidadeAltSaque').setValue(1);
    }
  }

  controleModalidadeAltSaque(event) {
    const controleModalidadeAltSaque = event.checked;
    this.form
      .get('controleModalidadeAltSaque')
      .setValue(controleModalidadeAltSaque);
    if (this.form.get('controleModalidadeAltSaque').value === false) {
      this.form.get('modalidadeAltSaque').setValue(null);
      this.form.get('valorSaque').setValue(null);
    }
    if (controleModalidadeAltSaque === true) {
      //controle de alteração de valor
      this.form.get('controleAlteracaoValor').setValue(false);
      this.form.get('controleModalidadeAltTroco').setValue(false);
      this.form.get('controleExpiracaoQr').setValue(false);

      ///
      this.form.get('modalidadeAltSaque').setValue(0);
      this.form.get('modalidadeAltTroco').setValue(null);
      this.form.get('valorTroco').setValue(null);
      this.form.get('dtVenc').setValue(null);
      this.form.get('jurosPix').setValue(null);
      this.form.get('multaPix').setValue(null);
      this.form.get('descontoPix').setValue(null);
      this.form.get('abatimentoPix').setValue(null);
    }
  }

  controleAltSaque(event) {
    const controleAltSaque = event.checked;
    this.form.get('controleAltSaque').setValue(controleAltSaque);
    if (this.form.get('controleAltSaque').value === false) {
      this.form.get('valorSaque').setValue(null);
    }
    if (controleAltSaque === true) {
      this.form.get('modalidadeAltSaque').setValue(0);
      this.form.get('valorSaque').setValue(null);
      this.form.get('controleAlteracaoValor').setValue(false);
      this.form.get('dtVenc').setValue(null);
      this.form.get('jurosPix').setValue(null);
      this.form.get('multaPix').setValue(null);
      this.form.get('descontoPix').setValue(null);
      this.form.get('abatimentoPix').setValue(null);
    }
  }

  desabilitaConfigVencimento() {
    if (
      this.form.get('controleAlteracaoValor').value == true ||
      this.form.get('controleModalidadeAltTroco').value == true ||
      this.form.get('controleModalidadeAltSaque').value == true ||
      this.form.get('controleValorTroco').value == true ||
      this.form.get('controleExpiracaoQr').value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  desabilitaAltTroco() {
    if (
      this.form.get('controleAlteracaoValor').value == true ||
      this.form.get('controleModalidadeAltSaque').value == true ||
      this.form.get('controleExpiracaoQr').value == true ||
      this.form.get('configuracoesVencimento').value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  desabilitaAltSaque() {
    if (
      this.form.get('controleAlteracaoValor').value == true ||
      this.form.get('controleModalidadeAltTroco').value == true ||
      this.form.get('controleValorTroco').value == true ||
      this.form.get('controleExpiracaoQr').value == true ||
      this.form.get('configuracoesVencimento').value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  desablitaQrExpiracao() {
    if (
      this.form.get('controleModalidadeAltTroco').value == true ||
      this.form.get('controleModalidadeAltSaque').value == true ||
      this.form.get('controleValorTroco').value == true ||
      this.form.get('configuracoesVencimento').value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  desabilitaAlteracaoValor() {
    if (
      this.form.get('controleModalidadeAltTroco').value == true ||
      this.form.get('controleModalidadeAltSaque').value == true ||
      this.form.get('controleValorTroco').value == true ||
      this.form.get('configuracoesVencimento').value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  gerarSemValor() {
    // Se desabilitaValor é true, habilita o campo e define desabilitaValor para false
    if (this.desabilitaValor) {
      this.form.get('valorPix').enable();
      this.desabilitaValor = false;
    }
    // Caso contrário, se desabilitaValor é false, desabilita o campo, define o valor para 0 e desabilitaValor para true
    else {
      this.form.get('valorPix').setValue(0);
      this.form.get('valorPix').disable();
      this.desabilitaValor = true;
    }
  }

  pixValueValidator(form: FormGroup) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !form.get('descontoPix') ||
        !form.get('abatimentoPix') ||
        !form.get('valorPix')
      ) {
        // Um ou mais controles não estão presentes
        return null;
      }

      const valorPix = +form.get('valorPix').value || 0;
      const total =
        (+form.get('descontoPix').value || 0) +
        (+form.get('abatimentoPix').value || 0);

      if (total > valorPix) {
        return { maxValorPixExceeded: true };
      }

      return null;
    };
  }
}
