import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NovoDocumentoComponent } from '../../novo-documento/novo-documento.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.less']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  query = new Subject<string>();
  queryValue: string;
  subscription: Subscription;
  key = "";

  @Output()
  public atualizar: EventEmitter<any> = new EventEmitter();
  @Output()
  public buscar: EventEmitter<any> = new EventEmitter();
  @Output()
  public filtrar: EventEmitter<any> = new EventEmitter();
  mostrarBusca: boolean = false;

  @Input()
  public filtro: {
    query?: string;
  };

  @Input()
  public modelo: string;

  constructor(
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.query.pipe(untilDestroyed(this), debounceTime(500)).subscribe(query => {
      this.atualizar.emit({ query: query });
    });
  }

  abreFiltro() {

  }

  atualizaLista(atualizar: boolean) {
    this.atualizar.emit({
      atualizar: atualizar,
    });
  }

  applySearch(filterValue: string) {
    this.buscar.emit({
      filterValue: filterValue,
    });
  }

  filtra(filtro) {
    this.filtro = filtro;

    this.filtrar.emit({
      filtro,
    });
  }

  public limpaBusca() {
    this.key = "";
  }

  novo() {
    const dialog = this._dialog.open(NovoDocumentoComponent, {
      width: "90vw",
      height: "90vh",
      panelClass: "modal-config",
      data: {
        modelo: this.modelo
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.atualizar === true) {
          this.atualizar.emit({ atualizar: true });
        }
      }
    });

  }


  ngOnDestroy() { }
}
