import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@project/src/environments/environment.default';
import Swal from 'sweetalert2';
import { HomeEndpointService } from '../../service/home-endpoint.service';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.less']
})
export class AppsComponent implements OnInit {
  sistemaOp: string;
  qrCode: string;
  env = environment;
  lojaAppAndroid = null;
  lojaAppApple = null;
  constructor(
    public dialogRef: MatDialogRef<AppsComponent>,
    private homeService: HomeEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.sistemaOp = this.data.sistema;
    this.getLinks(this.data.sistema);
    if (this.sistemaOp == "ANDROID") {
      this.qrCode = this.env.url.lojaAppAndroid;
    } else {
      this.qrCode = this.env.url.lojaAppApple;
    }
  }

  getLinks(sistema) {
    this.homeService.getConfiguracoesSistema().pipe().subscribe(
      (response) => {
        response
        this.data = response;
        this.lojaAppAndroid = response.urlAppAndroid
        this.lojaAppApple = response.urlAppApple
        if (sistema == "ANDROID") {
          this.qrCode = this.lojaAppAndroid;
        } else {
          this.qrCode = this.lojaAppApple;
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error,
        })
        console.error(error);
      }
    );
  }

  fecha(): void {
    this.dialogRef.close();
  }

}
