import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-gerenciar-arquivos-lista',
  templateUrl: './gerenciar-arquivos-lista.component.html',
  styleUrls: ['./gerenciar-arquivos-lista.component.less'],
  animations: [
    trigger('detailExpandAnimate', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GerenciarArquivosListaComponent implements OnInit, OnDestroy, OnChanges {
  displayedColumns: string[] = [
    'button',
    'nome',
    'dataCriacao',
    'documento'
  ];
  dataSource;
  expandedElement: any;
  processando = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  public documentos: any;

  @Input()
  public tipoLista: string;

  constructor() {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() { }

  ngOnInit() { }

  ngOnChanges() {
    this.preencheDataSource(this.documentos);
  }

  public preencheDataSource(data) {
    this.dataSource.connect().next(data);
  }

  visualizarArquivo(itemSelecionado) {
    window.open(itemSelecionado.linkDownload,
      '_blank'
    );
  }
}
