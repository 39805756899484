import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TokenAtendimentoEndpointService } from '../../service/token-atendimento-endpoint.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioDTO } from '../../model/usuario/usuario-dto.model';
import { EmpresaDTO } from '../../model/pessoa/empresa-dto.model';
import { CorporacaoDTO } from '../../model/pessoa/corporacao-dto.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-atendimento-cliente',
  templateUrl: './atendimento-cliente.component.html',
  styleUrls: ['./atendimento-cliente.component.less']
})
export class AtendimentoClienteComponent implements OnInit {
  form: FormGroup;
  usuario: UsuarioDTO;
  empresa: EmpresaDTO;
  corporacao: CorporacaoDTO;
  dataToken: any;

  constructor(
    private tokenAtendimentoService: TokenAtendimentoEndpointService,
    public dialogRef: MatDialogRef<AtendimentoClienteComponent>
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      token: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{6}$/)])
    })
  }

  recuperarInformacoes(){
    this.tokenAtendimentoService.recuperarInformacoes(this.form.get('token').value).subscribe(
      res=>{
        this.usuario = res.usuario;
        this.empresa = res.empresa;
        this.corporacao = res.corporacao;
        this.dataToken = res.dataAtualizacao;
      },
      (err)=>{
        Swal.fire('Erro', err.error, 'error')
      }
    )
  }

  fecha(): void {
    this.dialogRef.close();
  }

  getErrorMessage(form: string): string | null {
    if (this.form.get(form).hasError("required")) {
      return "Campo requerido";
    }
    if (this.form.get(form).hasError("pattern")) {
      return "Campo inválido";
    }
  }
}
