<div class="row">
  <div class="col-12">
    <form [formGroup]="form">
      <div class="row alinha">

        <div class="col-12" style="margin-bottom: 10px">
          <h4 class="corPrimaria" style=" font-weight: bolder; margin-top: 12px">JUROS E DESCONTO
          </h4>
          <p style="font-size: 13px">Escolha abaixo os valores que serão sugeridos na criação das suas cobranças para
            serem aplicados na fatura e na geração de boletos.</p>
        </div>

        <div class="col-12 col-md-4 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Juros ao mês (%)</mat-label>
            <input matInput type="text" formControlName="juros" (keyup)="troca($event)" />
            <mat-error>{{ getErrorMessage('juros') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-4 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Multa (%)</mat-label>
            <input matInput type="text" formControlName="multa" (keyup)="troca($event)" />
            <mat-error>{{ getErrorMessage('multa') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-4 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Desconto (R$)</mat-label>
            <input matInput type="text" formControlName="desconto" mask="separator.2" thousandSeparator="."
              prefix="R$ " />
            <mat-error>{{ getErrorMessage('desconto') }}</mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="env.modulos.cobranca.protesto === true && tipoConta !== 'contaDigitalS' && dadosEmpresa.beneficiarioId && dadosEmpresa?.configuracao?.enviarParaProtesto"
          class="col-12 col-md-4 input-size" style="padding-right: 9px;">
          <mat-checkbox formControlName="enviarParaProtesto" class="col-12" style="margin-bottom: 13px"
            [disabled]="dadosEmpresa.beneficiarioId && dadosEmpresa?.configuracao?.enviarParaProtesto"
            (change)="desabilitaInputs($event)">
            <span style="color: #6D6D6D">Enviar para protesto?</span>
          </mat-checkbox>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Será enviado para protesto após quantos dias?</mat-label>
              <input matInput type="text" formControlName="diasDeProtesto" />
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="tipoConta === 'contaDigitalS'" class="col-12" style="margin-top: -15px">
          <span style="font-size: 11px; margin-top: 5px;">
            * O {{env.nomeApp}} não possui ferramenta de protesto automático para este tipo de conta, apenas
            informa na
            fatura e no boleto em quantos
            dias você tomará tais providências.
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row botao-centro">
  <div class="col-12 col-md-4">
    <button class="btn btn-block" style="background-color: #4FA9A9" mat-raised-button>
      Visualizar Fatura
    </button>
  </div>
  <div class="col-12 col-md-4">
    <button class="btn btn-block" style="background-color: #4FA9A9" mat-raised-button>
      Visualizar
    </button>
  </div>
</div>