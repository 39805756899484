<div mat-dialog-title class="titulo-modal" fxLayout="row">
    <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
    </div>
    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">ONBOARDING {{ tipoDocumentacao.toUpperCase() }}</div> 
    <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
        <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="fecha()">FECHAR</p>
        <i style="margin-right: 10px;"(click)="fecha()" class="material-icons btn-fechar" >close</i>
    </div>
</div>

<div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div mat-dialog-content class="mat-dialog-content-responsive" *ngIf="!processando">
    <form [formGroup]="form" class="mudaCor input-size">
        <div class="row">
            <div class="col-12">
                <mat-tab-group>
                    <mat-tab *ngIf="tipoDocumentacao === 'baas'">
                        <ng-template mat-tab-label>
                            BAAS - Bank As A Service
                        </ng-template>
                        <app-listar-documento style="width: 100%;" [form]="form.get('documentosOnboardingRoot')" [modelo]="'onboarding'" [data]="data?.documentosOnboardingRoot" (update)="update()"></app-listar-documento>
                    </mat-tab>
                    <mat-tab *ngIf="tipoDocumentacao === 'caas'">
                        <ng-template mat-tab-label>
                            CAAS - Credit As A Service
                        </ng-template>
                        <app-listar-documento style="width: 100%;" [form]="form.get('documentosEmprestimoRoot')" [modelo]="'emprestimo'" [data]="data?.documentosEmprestimoRoot" (update)="update()"></app-listar-documento>
                    </mat-tab>
                    <mat-tab *ngIf="tipoDocumentacao === 'iaas'">
                        <ng-template mat-tab-label>
                            IAAS - Invest As A Service
                        </ng-template>
                        <app-listar-documento style="width: 100%;" [form]="form.get('documentosInvestidorRoot')" [modelo]="'investidor'" [data]="data?.documentosInvestidorRoot" (update)="update()"></app-listar-documento>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Variáveis
                        </ng-template>
                        <app-variaveis [form]="form.get('variaveis')" [variaveis]="data?.variaveisDocumento" (update)="update()" style="width: 100%;" ></app-variaveis>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Signatários
                        </ng-template>
                        <app-signatario [data]="data" style="width: 100%;" (update)="update()"></app-signatario>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions *ngIf="!processando">
    <div class="row botao-centro">
        <div class="col-12 col-md-4">
            <button type="button"  (click)="fecha()" class="btn btn-default btn-block" style="color: #fff;margin-left: 55%; z-index: +1;"
                mat-raised-button>
                Cancelar
            </button>
        </div>

        <div class="col-12 col-md-4">
            <button type="submit" class="btn btn-block fundoCorPrimaria" (click)="salvar()" style=" color: #fff;margin-left: 70%;"
                mat-raised-button>
                Atualizar
            </button>
        </div>
    </div>
</div>