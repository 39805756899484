<div class="col-12" style="text-align: -webkit-center; padding: 0">
  <a [routerLink]="['/privado/principal']">
    <img src="assets/img/icons/pluga-bank/logos/app_logo_branco.png" class="logo visible-on-sidebar-regular" />
    <img src="assets/img/icons/pluga-bank/icone-original.png" class="logo-side-mini visible-on-sidebar-mini" />
  </a>
</div>

<div class="sidebar-options sidebar-wrapper">
  <div class="text-center">
    <div (click)="usuarioLogado?.moderacao != 'aprovado' ||
        !(ehAdmin || empresaList?.length > 1 || corporacaoList?.length > 1)
          ? null: selecionaCorporacao()" 
        class="photo text-center text-white visible-on-sidebar-mini linha-branca" style="
        cursor: pointer;
        background: none;
        box-shadow: none;
        margin: 16px 0 10px;
      ">
      <i [matTooltip]="
          (empresaLogada && ['reprovada', 'encerrada'].includes(empresaLogada.moderacaoEmpresa)) && (!ehAdmin)
            ? 'Você não pode acessar essa funcionalidade'
            : null
        " [ngClass]="
          (empresaLogada && ['reprovada', 'encerrada'].includes(empresaLogada.moderacaoEmpresa)) && (!ehAdmin)
            ? 'fas fa-building fa-lg disabled-link'
            : 'fas fa-building fa-lg'
        " title="Trocar empresa" style="margin-bottom: 20px"></i>
    </div>
  </div>
  <div class="user visible-on-sidebar-regular" style="width: 100%">
    <div class="photo text-center text-white"
      style="background: none; box-shadow: none; margin: 18px 6px 0 6px">
      <i [ngClass]="
          usuarioLogado?.moderacao != 'aprovado'
            ? 'fas fa-building fa-lg disabled-link'
            : 'fas fa-building fa-lg'
        "></i>
    </div>
    <div class="user-info" style="cursor: pointer">
      <a [matTooltip]="
          (empresaLogada && ['reprovada', 'encerrada'].includes(empresaLogada.moderacaoEmpresa)) && (!ehAdmin)
            ? 'Você não pode acessar essa funcionalidade'
            : null
        " Wdata-toggle="collapse" (click)="
          usuarioLogado?.moderacao != 'aprovado' ||
          !(ehAdmin || empresaList?.length > 1 || corporacaoList?.length > 1)
            ? null
            : selecionaCorporacao()
        " class="collapsed" style="text-align: center">
        <span class="text-responsive" [title]="
            empresaLogada
              ? empresaLogada.pessoa.razaoSocial ||
                empresaLogada.pessoa.nome
              : env.nomeApp.toUpperCase()
          ">
          {{
          empresaLogada
          ? empresaLogada.pessoa.razaoSocial ||
          empresaLogada.pessoa.nome
          : ""
          }}
        </span>
        <b class="caret text-center"></b>
        <span class="text-responsive" style="font-size: 10px">
          {{
          empresaLogada
          ? stringFormat.adicionarPontosDocumento(
            empresaLogada.pessoa.documento
          )
          : ""
          }}
        </span>
        <span class="text-responsive" style="font-size: 11px">
          {{
          corporacaoLogada
          ? corporacaoLogada.corporacao.nome
          : env.nomeApp.toUpperCase()
          }}
        </span>
      </a>
    </div>
  </div>
  <ul *ngIf="empresaLogada" class="nav navbar-nav nav-mobile-menu">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <!--If is not Cartão tab -->
      <a (click)="navegar(menuitem)" [matTooltip]="mudaTexto(menuitem)" *ngIf="
          paginaService.obter(menuitem.info).tipo === 'link' &&
          (paginaService.obter(menuitem.info).nome !== 'Cartão')
        " [ngClass]="[
          !visualiza(menuitem) ? 'nav-link disabled-link' : 'nav-link pointer',
          sidebarOpen ? '' : 'justify-center-flex'
        ]">
        <fa-icon [icon]="paginaService.obter(menuitem.info).icone" [title]="paginaService.obter(menuitem.info).nome"
          class="icone-side-mini visible-on-sidebar-mini">
        </fa-icon>
        <p class="visible-on-sidebar-regular">
          <fa-icon [icon]="paginaService.obter(menuitem.info).icone" class="icone visible-on-sidebar-regular">
          </fa-icon>
          {{ paginaService.obter(menuitem.info).nome }}

          <span class="bordinha"
            *ngIf="paginaService.obter(menuitem.info).nome == 'Pix' && !env.modulos.pix.transferenciaPix">em
            breve</span>
        </p>
      </a>

      <!--If is Cartão tab-->
      <a [matTooltip]="mudaTexto(menuitem)" *ngIf="
          paginaService.obter(menuitem.info).tipo === 'link' &&
          (paginaService.obter(menuitem.info).nome === 'Cartão')
        " [ngClass]="[
          !visualiza(menuitem) ? 'nav-link disabled-link' : 'nav-link',
          sidebarOpen ? '' : 'justify-center-flex'
        ]">
        <fa-icon [icon]="paginaService.obter(menuitem.info).icone" [title]="paginaService.obter(menuitem.info).nome"
          class="icone-side-mini visible-on-sidebar-mini">
        </fa-icon>
        <p class="visible-on-sidebar-regular">
          <fa-icon [icon]="paginaService.obter(menuitem.info).icone" class="icone visible-on-sidebar-regular">
          </fa-icon>
          {{ paginaService.obter(menuitem.info).nome }}
          <span class="bordinha" *ngIf="['Cartão'].includes(paginaService.obter(menuitem.info).nome)">em
            breve</span>
        </p>
      </a>

      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{ menuitem.collapse }}"
        *ngIf="paginaService.obter(menuitem.info).tipo === 'menu'" (click)="updatePS()" class="nav-link">
        <!-- <fa-icon [icon]="paginaService.obter(menuitem.info).icone" class="icone visible-on-sidebar-mini">
                </fa-icon> -->
        <p>
          <fa-icon [icon]="paginaService.obter(menuitem.info).icone" class="icone">
          </fa-icon>
          {{ paginaService.obter(menuitem.info).nome }}<b class="caret"></b>
        </p>
      </a>

      <!--Display the submenu items-->
      <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="paginaService.obter(menuitem.info).tipo === 'menu'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link" [matTooltip]="
                !visualiza(childitem)
                  ? 'Termine o cadastro para acessar essa funcionalidade'
                  : null
              ">
              <span class="sidebar-mini">
                <fa-icon [icon]="paginaService.obter(childitem.info).icone" class="icone">
                </fa-icon>
              </span>
              <span class="sidebar-normal">{{
                paginaService.obter(childitem.info).nome
                }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>