<div class="box-body opcoes" style="text-align: -webkit-center">
  <div *ngIf="dataDialog.tipoModal !== 'documentacao'">
    <p class="super-title">Cliente: {{ itemSelecionado.nomeCliente || '' }}</p>
    <p class="super-title">
      Valor: {{ itemSelecionado.valor | currency : 'BRL' : 'R$' }} - Vencimento:
      {{ itemSelecionado.dataVencimento | date : 'dd/MM/yyyy' }}
    </p>
  </div>
  <div class="row" style="justify-content: center">
    <div
      class="col-8 col-md-8 arquivo-box"
      appDragDrop
      (fileDropped)="onFileDropped($event)"
      style="margin-right: 20px"
    >
      <input
        hidden
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="onSelectFile($event)"
      />
      <i class="fas fa-cloud-arrow-up"></i>
      <p>
        Arraste o documento ou
        <label for="fileDropRef" style="font-weight: bold; cursor: pointer"
          >clique aqui.</label
        >
      </p>
    </div>
    <div class="col-3 col-md-3 arquivo-lista-box">
      <p *ngIf="files.length == 0">Nenhum arquivo adicionado.</p>
      <div *ngIf="files.length > 0">
        <div class="arquivo-row" *ngFor="let file of files; index as i">
          <i class="far fa-file-alt" id="file-icon"></i>
          <p id="arquivo-title" [matTooltip]="file.nome">
            {{ file.nome || '' }}
          </p>
          <i
            (click)="deleteFile(file)"
            class="fas fa-trash"
            id="trash-icon"
          ></i>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5" style="margin-top: 25px">
      <button
        [disabled]="files.length === 0"
        type="button"
        (click)="enviar()"
        class="btn btn-block btn-novocliente"
        mat-raised-button
      >
        Enviar
      </button>
    </div>
  </div>
</div>
