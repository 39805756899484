import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageResponse } from '../model/page-response.model';

@Injectable({
  providedIn: 'root'
})
export class CnabService {

  constructor(private client: HttpClient) { }

  importarCnab(arquivo): Observable<any> {
    const url = '/cnab/importar';
    return this.client.post(url, arquivo);
  }

  validarCnab400(arquivo): Observable<any> {
    const url = '/cnab/validar-arquivo-remessa-cnab-400';
    return this.client.post(url, arquivo);
  }

  importarCnab400(arquivo, token: string, tipoToken: string): Observable<any> {
    const url = '/cnab/importar-cnab-400';
    return this.client.post(url, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  listarImportacoesCnab400(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-importacoes-cnab-400';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  listarExportacoesCnab400(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-exportacoes-cnab-400';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  downloadRelatorioCnab400(importacaoId: number) {
    return this.client.get(`/cnab/exportar-relatorio-cnab-400/${importacaoId}`); 
  }

  downloadArquivoRemessaCnab400(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-remessa-cnab-400/${importacaoId}`); 
  }
  
  downloadArquivoRetornoCnab400(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-cnab-400/${importacaoId}`);
  }

  downloadArquivoRetornoDiarioCnab400(exportacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-diario-cnab-400/${exportacaoId}`);
  }

  validarCnab240Pagamento(arquivo): Observable<any> {
    const url = '/cnab/validar-arquivo-remessa-cnab-240-pagamento';
    return this.client.post(url, arquivo);
  }

  importarCnab240Pagamento(arquivo, token: string, tipoToken: string): Observable<any> {
    const url = '/cnab/importar-cnab-240-pagamento';
    return this.client.post(url, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  listarImportacoesCnab240Pagamento(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-importacoes-cnab-240-pagamento';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  listarExportacoesCnab240Pagamento(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-exportacoes-cnab-240-pagamento';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  downloadRelatorioCnab240Pagamento(importacaoId: number) {
    return this.client.get(`/cnab/exportar-relatorio-cnab-240-pagamento/${importacaoId}`); 
  }

  downloadArquivoRemessaCnab240Pagamento(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-remessa-cnab-240-pagamento/${importacaoId}`); 
  }
  
  downloadArquivoRetornoCnab240Pagamento(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-cnab-240-pagamento/${importacaoId}`);
  }

  downloadArquivoRetornoDiarioCnab240Pagamento(exportacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-diario-cnab-240-pagamento/${exportacaoId}`);
  }

  validarCnab240Transferencia(arquivo): Observable<any> {
    const url = '/cnab/validar-arquivo-remessa-cnab-240-transferencia';
    return this.client.post(url, arquivo);
  }

  importarCnab240Transferencia(arquivo, token: string, tipoToken: string): Observable<any> {
    const url = '/cnab/importar-cnab-240-transferencia';
    return this.client.post(url, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  listarImportacoesCnab240Transferencia(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-importacoes-cnab-240-transferencia';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  listarExportacoesCnab240Transferencia(arg?: {
    order?: string;
    page?: number;
    size?: number;
  }) {
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }
    params = params.append('page', `${arg.page}`);
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }

    const url = '/cnab/listar-exportacoes-cnab-240-transferencia';
    return this.client.get<PageResponse<any>>(url, { params });
  }

  downloadRelatorioCnab240Transferencia(importacaoId: number) {
    return this.client.get(`/cnab/exportar-relatorio-cnab-240-transferencia/${importacaoId}`); 
  }

  downloadArquivoRemessaCnab240Transferencia(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-remessa-cnab-240-transferencia/${importacaoId}`); 
  }
  
  downloadArquivoRetornoCnab240Transferencia(importacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-cnab-240-transferencia/${importacaoId}`);
  }

  downloadArquivoRetornoDiarioCnab240Transferencia(exportacaoId: number) {
    return this.client.get(`/cnab/exportar-arquivo-retorno-diario-cnab-240-transferencia/${exportacaoId}`);
  }
  
}