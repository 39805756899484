<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        IMPORTAÇÕES <i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>

    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div class=" mudaCor input-size row"
            style="background-color: white; margin: 0px 10px; border-radius: 5px;padding: 0 10px 3px;">
            <div style="width: 40%; padding: 15px 15px 0;">
                <p style="margin-left: 40px;"><b>Clique no ícone ao lado para selecionar o arquivo “.csv”:</b> </p>
                <div style="display: flex;">
                    <i>
                        <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.25 0C2.38805 0 1.5614 0.337142 0.951903 0.937258C0.34241 1.53737 0 2.35131 0 3.2V28.8C0 29.6487 0.34241 30.4626 0.951903 31.0627C1.5614 31.6629 2.38805 32 3.25 32H22.75C23.612 32 24.4386 31.6629 25.0481 31.0627C25.6576 30.4626 26 29.6487 26 28.8V9.6L16.25 0M14.625 2.4L23.5625 11.2H14.625M9.83125 14.752L14.43 19.28L17.875 15.888V27.2H6.38625L9.83125 23.808L5.2325 19.28"
                                fill="#4FA9A9" />
                        </svg>
                    </i>
                    <input readonly="true" type="text"
                        style="width: 90%; margin-left: 15px;border-radius: 4px;border: 1px solid #707070;">
                </div>
            </div>
            <div style="width: 60%; padding: 15px 15px 0;">
                <p><b>Descrição:</b> </p>
                <input type="text" style="width: 100%; height: 60%;border: 1px solid #707070;
                border-radius: 4px;">
            </div>
            <hr style="display: flex;
            width: 100%;
            background-color: #d2d2d2;
            margin: 20px 15px;">
            <div style="width: 50%; padding: 0px 35px; font-size: 14px;color: #707070;">
                <h3 style="margin: 0 0 10px -20px;font-size: 20px;"> <b>Informações Importantes:</b></h3>
                <ul class="lista">
                    <li>O arquivo deve ser no formato <b>CSV.</b></li>
                    <br>
                    <li>A primeira linha do arquivo deve ser os títulos dos campos.
                        <b> Não usar acentos gráficos para os títulos.</b>
                        Os títulos p:ermitidos são:
                        <br>
                        <br>
                        <b>Nome | Data de nascimento | Mae | Documento | Logradouro tipo
                            Logradouro | Cidade | Complemento | CEP | Bairro | Numero
                            Estado | Telefone | Email | Tipo de Conta Corrente | Conta Salario
                            Documento Orgao Pagador | Agencia Orgao Pagador | Conta Orgao Pagador</b>
                    </li>
                    <br>
                    <li>
                        Os campos <b>“Nome”, “Data de Nascimento”, “Mae”, “Documento”, “Logradouro tipo”,
                            “Logradouro”, “Cidade”, “CEP”</b> e <b>“Numero”</b> são de preenchimento <b>obrigatório.</b>
                    </li>
                    <br>
                    <li>O campo <b>“Mae”</b> é obrigatório somente para contas do tipo <b>Pessoa Física.</b>
                    </li>
                    <br>
                    <li>O campo <b>“Data”</b> de nascimento deve no formato <b>DD/MM/AAAA.</b>
                    </li>
                    <br>
                    <li>O campo <b>“Documento”</b> deve ser preenchido com os números do <b>CPF</b> ou do <b>CNPJ.</b>
                    </li>
                    <br>
                </ul>
                <div style="display: flex;margin-left: -20px;">
                    <i>
                        <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.8015 0H4.33333C3.18406 0 2.08186 0.421427 1.2692 1.17157C0.456546 1.92172 0 2.93913 0 4V28C0 29.0609 0.456546 30.0783 1.2692 30.8284C2.08186 31.5786 3.18406 32 4.33333 32H21.6667C22.8159 32 23.9181 31.5786 24.7308 30.8284C25.5435 30.0783 26 29.0609 26 28V9.414C25.9999 8.88361 25.7715 8.37499 25.3652 8L17.3333 0.586C16.9271 0.210901 16.3761 0.000113275 15.8015 0ZM16.25 7V3L22.75 9H18.4167C17.842 9 17.2909 8.78929 16.8846 8.41421C16.4783 8.03914 16.25 7.53043 16.25 7ZM2.16667 18H23.8333V20H17.3333V24H23.8333V26H17.3333V30H15.1667V26H8.66667V30H6.5V26H2.16667V24H6.5V20H2.16667V18Z"
                                fill="#4FA9A9" />
                        </svg>
                    </i>
                    <p style="margin-left: 15px;align-self: flex-end;"><b>Clique no ícone ao lado para baixar o ARQUIVO
                            GUIA</b></p>
                </div>

            </div>



            <div style="width: 50%; padding: 0 15px; font-size: 14px;">
                <p>
                    Os campos <b>“Documento” , “CEP”</b> e <b>"Documento Orgao Pagador"</b>
                    devem ser preenchidos <b>somente com números. </b>
                </p>
                <br>
                <p>
                    O campo <b>“Conta Salario”</b> deve ser preenchido somente com os valores <b>"Sim"</b> ou
                    <b>“Nao”.</b>
                    No caso de o campo <b>“Conta Salario”</b> ser preenchido com <b>"Sim"</b>, os campos <b>“Documento
                        Orgao Pagador”, “Agencia Orgao Pagador”</b> e <b>“Conta Orgao Pagador”</b> serão
                    <b>obrigatórios.</b>
                </p>
                <br>
                <p>
                    No campo <b>“Logradouro tipo”</b>, as principais opções são:
                    <br>
                    <b>Área | Avenida | Chacara | Condominio | Residencial | Rua | Rodovia | Setor
                        Travessa | Trecho | Via | Linha | Outro</b>
                </p>
                <br>
                <p>
                    Para efeito de visualização das informações, recomendamos que <b>não repita</b>
                    o tipo de logradouro no campo <b>“Logradouro”.</b>
                </p>
                <br>
                <p> No campo <b>“Tipo Conta Corrente”</b>, preencher somente com as seguintes siglas: <br></p>
                <ul>
                    <p style="margin-left: 20px;">
                        <b>PF </b>(Conta Corrente Pessoa Física) <br>
                        <b>PJ </b>(Conta Corrente Pessoa Jurídica) <br>
                        <b>MD </b>(Conta Gov. Municipal Direta) <br>
                        <b>MI </b>(Conta Gov. Municipal Indireta) <br>
                        <b>ME </b>(Conta Gov. Municipal Ativ. Empresariais) <br>
                        <b>DV </b>(Depósitos Vinculados Pessoa Jurídica) <br>
                        <b>EPF</b> (Conta Corrente Encerrada Pessoa Física) <br>
                        <b>EPJ</b> (Conta Corrente Encerrada Pessoa Jurídica) <br>
                        <b>DM </b>(Conta Digital Livre Movimentação) <br>
                        <b>DB </b>(Conta Cobrança Digitalaldos Bloqueados) <br>
                    </p>
                </ul>





            </div>
        </div>
    </div>

    <div mat-dialog-actions style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;">
        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
        margin: 0;">
            <button (click)="fecha()" class="btn btn-default next">Voltar</button>
        </span>
    </div>
</div>