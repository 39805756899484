import { Injectable } from '@angular/core';
import {
  faHome,
  faMoneyBill,
  faExchangeAlt,
  faBarcode,
  faFileAlt,
  faUserPlus,
  faCopy,
  faUsers,
  faFileArchive,
  faFileInvoiceDollar,
  faChartLine,
  faHandHoldingDollar,
} from '@fortawesome/free-solid-svg-icons';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard, faHandshake } from '@fortawesome/free-regular-svg-icons';

type TipoPagina = 'link' | 'menu' | 'modal';

export interface Permissao {
  tipo: 'sistema' | 'corporacao' | 'empresa';
  nome: string;
}

export class PageInfo {
  private $nome: string;
  private $icone: IconDefinition;
  private $tipo: TipoPagina;
  private $modalId: string;
  private $permissoes: Array<Permissao>;

  constructor(
    $nome: string,
    $icone: IconDefinition,
    ...$permissoes: Array<Permissao>
  ) {
    this.$nome = $nome;
    this.$icone = $icone;
    this.$tipo = 'link';
    this.$modalId = null;
    this.$permissoes = $permissoes;
  }

  public withTipo(tipo: TipoPagina): PageInfo {
    this.$tipo = tipo;
    return this;
  }

  public withModal(modalId: string): PageInfo {
    this.$modalId = modalId;
    return this;
  }

  public get nome(): string {
    return this.$nome;
  }

  public get icone(): IconDefinition {
    return this.$icone;
  }

  public get tipo(): TipoPagina {
    return this.$tipo;
  }

  public get modalId(): string {
    return this.$modalId;
  }

  public get permissoes(): Array<Permissao> {
    return this.$permissoes;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PaginaService {
  private readonly _mapa = new Map<string, PageInfo>();

  constructor() {
    this._mapa.set(
      'dashboard',
      new PageInfo('Painel', faHome, {
        tipo: 'sistema',
        nome: 'ROLE_USUARIO'
      })
    );
    this._mapa.set(
      'contato',
      new PageInfo('Contatos', faUsers, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_AVULSA_VISUALIZAR'
      })
    ); // [routerLink]="'/privado/pessoa/contato'"
    this._mapa.set(
      'cobranca',
      new PageInfo('Cobranças', faCopy, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_AVULSA_VISUALIZAR'
      })
    );
    this._mapa.set(
      'link-pagamento',
      new PageInfo('Link de Pagamentos', faMoneyBill, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_AVULSA_VISUALIZAR'
      })
    );
    this._mapa.set(
      'pix',
      new PageInfo('Pix', faPix, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_PIX_VISUALIZAR'
      })
    );
    this._mapa.set(
      'pague-contas',
      new PageInfo('Pagamentos', faBarcode, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_PAGUECONTAS_VISUALIZAR'
      })
    );
    this._mapa.set(
      'extrato',
      new PageInfo('Extrato', faFileAlt, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'extrato-investidor',
      new PageInfo('Extrato do Investidor', faFileArchive, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'investimentos',
      new PageInfo('Investimentos', faChartLine, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'transferencia',
      new PageInfo('Transferir', faExchangeAlt, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_TRANSFERENCIA_VISUALIZAR'
      })
    );
    this._mapa.set(
      'cartao',
      new PageInfo('Cartão', faCreditCard, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_CARTAO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'credito',
      new PageInfo('Crédito', faHandHoldingDollar, {
        tipo: 'empresa',
        nome: 'ROLE_CREDITO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'antecipacao',
      new PageInfo('Antecipação', faHandshake, {
        tipo: 'empresa',
        nome: 'ROLE_EMPRESA_ANTECIPACAO_VISUALIZAR'
      })
    );
    this._mapa.set(
      'indicar-amigos',
      new PageInfo('Indicação', faUserPlus, {
        tipo: 'sistema',
        nome: 'ROLE_USUARIO'
      })
    );
  }

  public obter(id: string): PageInfo {
    return this._mapa.get(id);
  }
}
