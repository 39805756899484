import { style } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { numberFormat } from 'highcharts';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.less']
})
export class NotificacoesComponent implements OnInit {
  @Input()
  public form: FormGroup;

  @Input()
  public data: any;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.data) {
      this.data.telefoneNotificacao = this.data.telefoneNotificacao;
      this.data.emailNotificacao = this.data.emailNotificacao;
    }
    const configEmail = this.data.configuracaoEmail || {};
    const configSms = this.data.configuracaoSms || {};

    this.form.addControl(
      'diasAntesDoVencimentoParaRelembrarProximasCobrancas',
      new FormControl(
        Number(this.data.diasAntesDoVencimentoParaRelembrarProximasCobrancas || '5')
      )
    );
    this.form.addControl(
      'diasAntesDoVencimentoParaEnvioLembreteIndividual',
      new FormControl(
        Number(this.data.diasAntesDoVencimentoParaEnvioLembreteIndividual || '5')
      )
    );
    this.form.addControl(
      'diasAposVencimentoParaRelembrarCobrancaVencida',
      new FormControl(
        Number(this.data.diasAposVencimentoParaRelembrarCobrancaVencida || '1')
      )
    );

    // ------------------- NOTIFICAR PAGADOR --------------------
    this.form.addControl(
      'emailNotificarPagadorAoRemoverCobranca',
      new FormControl(
        configEmail.notificarPagadorAoRemoverCobranca === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarPagadorAoVisualizarFatura',
      new FormControl(
        configEmail.notificarPagadorAoVisualizarFatura === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarPagadorNovasCobrancas',
      new FormControl(
        configEmail.notificarPagadorNovasCobrancas === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas',
      new FormControl(
        configEmail.notificarPagadorAlteracaoValorOuDataVencimentoCobrancas ===
        false
          ? false
          : true
      )
    );
    this.form.addControl(
      'emailNotificarPagadorCobrancasAtrasadas',
      new FormControl(
        configEmail.notificarPagadorCobrancasAtrasadas === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarPagadorPagamentosConfirmados',
      new FormControl(
        configEmail.notificarPagadorPagamentosConfirmados === false
          ? false
          : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorAoRemoverCobranca',
      new FormControl(
        configSms.notificarPagadorAoRemoverCobranca === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorAoVisualizarFatura',
      new FormControl(
        configSms.notificarPagadorAoVisualizarFatura === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorNovasCobrancas',
      new FormControl(
        configSms.notificarPagadorNovasCobrancas === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorAlteracaoValorOuDataVencimentoCobrancas',
      new FormControl(
        configSms.notificarPagadorAlteracaoValorOuDataVencimentoCobrancas ===
        false
          ? false
          : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorCobrancasAtrasadas',
      new FormControl(
        configSms.notificarPagadorCobrancasAtrasadas === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarPagadorPagamentosConfirmados',
      new FormControl(
        configSms.notificarPagadorPagamentosConfirmados === false ? false : true
      )
    );

    // ------------------- NOTIFICAR COBRADOR --------------------
    this.form.addControl(
      'emailNotificarCobradorAoRemoverCobranca',
      new FormControl(
        configEmail.notificarCobradorAoRemoverCobranca === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarCobradorAoVisualizarFatura',
      new FormControl(
        configEmail.notificarCobradorAoVisualizarFatura === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarCobradorNovasCobrancas',
      new FormControl(
        configEmail.notificarCobradorNovasCobrancas === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas',
      new FormControl(
        configEmail.notificarCobradorAlteracaoValorOuDataVencimentoCobrancas ===
        false
          ? false
          : true
      )
    );
    this.form.addControl(
      'emailNotificarCobradorCobrancasAtrasadas',
      new FormControl(
        configEmail.notificarCobradorCobrancasAtrasadas === false ? false : true
      )
    );
    this.form.addControl(
      'emailNotificarCobradorPagamentosConfirmados',
      new FormControl(
        configEmail.notificarCobradorPagamentosConfirmados === false
          ? false
          : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorAoRemoverCobranca',
      new FormControl(
        configSms.notificarCobradorAoRemoverCobranca === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorAoVisualizarFatura',
      new FormControl(
        configSms.notificarCobradorAoVisualizarFatura === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorNovasCobrancas',
      new FormControl(
        configSms.notificarCobradorNovasCobrancas === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorAlteracaoValorOuDataVencimentoCobrancas',
      new FormControl(
        configSms.notificarCobradorAlteracaoValorOuDataVencimentoCobrancas ===
        false
          ? false
          : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorCobrancasAtrasadas',
      new FormControl(
        configSms.notificarCobradorCobrancasAtrasadas === false ? false : true
      )
    );
    this.form.addControl(
      'smsNotificarCobradorPagamentosConfirmados',
      new FormControl(
        configSms.notificarCobradorPagamentosConfirmados === false
          ? false
          : true
      )
    );
  }

  alternarNotificacao(campo:string){
    
    const value = this.form.get(campo).value;
    this.form.get(campo).patchValue(!value);


  }



  getErrorMessage(field) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError('required')
        ? 'Campo requerido'
        : this.form.get(field).hasError('minlength')
        ? 'Campo inválido'
        : '';
    }
  }

  isValid(form: FormGroup, field) {
    if (form.get(field)) {
      if (form.get(field).value === '' || form.get(field).value === null) {
        return false;
      }
      return form.get(field).valid;
    }
  }  
}
