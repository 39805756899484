import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";
import { RootEndpointService } from "../root-endpoint.service";

@Injectable({
    providedIn: "root",
})
export class BloqueioCadastro implements CanActivate {
    constructor(private autenticacao: AutenticacaoEndpointService,
        private rootService: RootEndpointService,
        private router: Router
    ) { }

    cadastroBloqueado: boolean = false;

    canActivate(route: ActivatedRouteSnapshot):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.rootService.getBloqueioNovosCadastros().toPromise().then(async (res) => {
            this.cadastroBloqueado = res == 'true' ? true : false;

            if (this.cadastroBloqueado) {
                this.router.navigate(['/privado/principal']);
            }
        });
        if (this.cadastroBloqueado) {
            return false;
        } else {
            return true;
        }
    }
}