<div class="box-body">
  <div class="col-12">
    <p style="text-align: center;">Abaixo estão os documentos que você deve nos enviar. <i
        class="fas fa-info-circle info-icon"
        matTooltip="Todas as cópias devem ser coloridas, frente e verso, legíveis, sem desfoque e sem cortes"></i></p>
  </div>

  <!-- SE FOR PJ ABRE ESSE AQUI -->
  <div *ngIf="tipoConta === 'pj'" class="alinha" style="margin-bottom: 1em;">
    <div class="row">
      <div class="col-12">
        <p>Documentação da empresa <i class="fas fa-info-circle info-icon"
            matTooltip="O contrato social só será aceito contendo todas as páginas e o registro na junta comercial"></i>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="inputEmpresa" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Tipo de empresa</p>
      </div>
    </div>
    <div class="row">
      <div class="col-10" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>Tipo de empresa</mat-label>
          <mat-select formControlName="tipoEmpresa" placeholder="Selecione o tipo de empresa">
            <mat-option value="ltda">LTDA - Sociedade limitada</mat-option>
            <mat-option value="mei">MEI - Microempreendedor individual</mat-option>
            <mat-option value="me">ME - Microempresa</mat-option>
            <mat-option value="eireli">EIRELI - Empresa individual de responsabilidade limitada</mat-option>
            <mat-option value="sa">SA - Sociedade anônima</mat-option>
            <mat-option value="outro">Instituição, ONG ou Associação</mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "tipoEmpresa") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Abaixo, selecione o tipo de documento que você fará upload:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-10" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>Tipo de documento</mat-label>
          <mat-select formControlName="tipoDocumento" placeholder="Selecione o tipo de documento">
            <mat-option value="cnpj">Cartão CNPJ</mat-option>
            <mat-option value="contato-social">Contrato Social</mat-option>
            <mat-option value="documento-complementar">Documento complementar</mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "tipoEmpresa") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-10" [formGroup]="form">
        <app-upload-arquivos requiredText="Necessário informar ao menos um comprovante de empresa" (outputFiles)="upload('comprovanteEmpresa', $event)"></app-upload-arquivos>
      </div>
    </div>
  </div>

  <!-- SE FOR PF TEM QUE ABRIR ESSE AQUI -->
  <div *ngIf="tipoConta === 'pf'" class="alinha" style="margin-bottom: 1em;">
    <div class="row">
      <div class="col-12">
        <p class="super-title">Nome / CPF</p>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="inputEmpresa" [disabled]="inputEmpresa === null ? false : true" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="super-title">Qual documento você quer enviar?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-10" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>Tipo de documento</mat-label>
          <mat-select formControlName="tipoEmpresa" placeholder="Selecione o tipo de empresa">
            <mat-option value="cnh">CNH - Carteira de motorista</mat-option>
            <mat-option value="mei">RG - Carteira de identidade</mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "tipoEmpresa") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <app-upload-arquivos requiredText="Necessário informar um documento" (outputFiles)="upload('comprovanteDocumento', $event)"></app-upload-arquivos>
   
    <div class="row" style="margin-bottom: 1em;">
      <div class="col-12">
        <p class="super-title">Comprovante de endereço<i class="fas fa-info-circle info-icon"
          matTooltip="Serão aceitos comprovantes de endereço emitidos nos últimos três meses"></i></p>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="endereco" [disabled]="inputEndereco === null ? false : true" />
        </mat-form-field>
      </div>
    </div>
    <app-upload-arquivos requiredText="Necessário informar comprovante de endereço" (outputFiles)="upload('comprovanteEndereco', $event)"></app-upload-arquivos>
  </div>
</div>

<div *ngIf="arquivoEmpresa.length > 0" class="box-body opcoes" style="text-align: -webkit-center;">
  <p class="super-title">Documentos adicionados: </p>
  <app-gerenciar-arquivos-lista [documentos]="arquivoEmpresa"></app-gerenciar-arquivos-lista>
</div>