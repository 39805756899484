import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QrCodePixDTO } from "../model/qrCode-pix-dto.model";
import { ChavePixDTO } from "../model/financeiro/transferencia/chave-pix-dto-model";
import { PixDTO } from "../model/financeiro/transferencia/pix-dto.model";
import { PageResponse } from "../model/page-response.model";
import { FiltroPixDTO } from "../model/filtro/filtro-pix-transferencia-dto.model";
import moment from "moment";

@Injectable({
    providedIn: 'root'
})

export class PixEndpointService {

    constructor(private client: HttpClient) { }

    criarChave(chaveDTO): Observable<any> {
        const url = '/pix/criar-chave';
        return this.client.post(url, chaveDTO);
    }

    listarChaves(empresaId: number | string): Observable<ChavePixDTO[]> {
        const url = '/pix/listar-chaves-empresa/' + empresaId;
        return this.client.get<ChavePixDTO[]>(url);
    }

    listarChavesBass(empresaId: number | string): Observable<any> {
        const url = '/pix/listar-chaves-empresa-bass/' + empresaId;
        return this.client.get<any>(url);
    }

    consultarChave(chave: string, tpChave?: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('chave', `${chave}`);
        if (tpChave !== undefined) params = params.append('tpChave', `${tpChave}`);

        const url = '/pix/consultar-chave';
        return this.client.get<ChavePixDTO>(url, { params });
    }

    gerarQrCodeCobranca(qrCodePixDTO: QrCodePixDTO): Observable<QrCodePixDTO> {
        const url = '/pix/gerar-qrcode-estatico';
        return this.client.post<QrCodePixDTO>(url, qrCodePixDTO);
    }

    gerarQrCodeDinamico(qrCodePixDTO: QrCodePixDTO): Observable<QrCodePixDTO> {
        const url = '/pix/gerar-qrcode-dinamico';
        return this.client.post<QrCodePixDTO>(url, qrCodePixDTO);
    }

    gerarQrCodeVencimento(qrCodePixDTO: QrCodePixDTO): Observable<any> {
        const url = '/pix/gerar-qrcode-vencimento';
        return this.client.post<QrCodePixDTO>(url, qrCodePixDTO);
    }


    enviarPix(pixDTO: any): Observable<any> {
        const url = '/pix/enviar';
        return this.client.post<any>(url, pixDTO);
    }

    limitesPix(limitesPixDTO: any, idEmpresa?): Observable<any> {
        let params = new HttpParams();
        const url = '/pix/limites';

        if (idEmpresa) {
            params = params.append('idEmpresa', `${idEmpresa}`);
        }

        return this.client.patch<any>(url, limitesPixDTO, { params });
    }

    consultarLimites(): Observable<any> {
        const url = '/pix/limites';
        return this.client.get<any>(url);
    }

    criarRevindicacao(chaveDTO: ChavePixDTO): Observable<any> {
        const url = '/pix/criar-reivindicacao';
        return this.client.post(url, chaveDTO);
    }

    decodificarQrCodeEstatico(qrCode): Observable<any> {
        const url = '/pix/decodificar-qrcode';
        return this.client.post(url, qrCode);
    }

    listarFiltroPix(
        filtro: FiltroPixDTO
    ): Observable<PageResponse<FiltroPixDTO>> {
        filtro = filtro || {};
        let params = new HttpParams();
        const { data } = filtro;
        if (data) {
            const { data_criacao, data_desativacao } = data;

            if (data_criacao && data_criacao.show) {
                const { de, ate } = data_criacao;
                if (de) {
                    params = params.append(
                        "data-criacao-maior-igual",
                        `${moment(de).format("DDMMYYYY")}`
                    );
                }
                if (ate) {
                    params = params.append(
                        "data-criacao-menor-igual",
                        `${moment(ate).format("DDMMYYYY")}`
                    );
                }
            }
            if (data_desativacao && data_desativacao.show) {
                const { de, ate } = data_desativacao;
                if (de) {
                    params = params.append(
                        "data-desativacao-maior-igual",
                        `${moment(de).format("DDMMYYYY")}`
                    );
                }
                if (ate) {
                    params = params.append(
                        "data-desativacao-menor-igual",
                        `${moment(ate).format("DDMMYYYY")}`
                    );
                }
            }
        }
        if (filtro.order) {
            params = params.append("order", `${filtro.order}`);
        }

        params = params.append("page", `${filtro.page}`);

        if (filtro.query) {
            params = params.append("q", `${filtro.query}`);
        }
        if (filtro.size) {
            params = params.append("size", `${filtro.size}`);
        }

        if (filtro.idEmpresa) {
            params = params.append("idEmpresa", `${filtro.idEmpresa}`);
        }

        const url = "/pix/listar-filtro-pix";
        return this.client.get(url, { params });
    }


    listarSemFiltroPix(page?, size?, idEmpresa?, query?, order?): Observable<any> {
        let params = new HttpParams();

        if (order) {
            params = params.append("order", `${order}`);
        }

        params = params.append("page", `${page}`);

        if (query) {
            params = params.append("q", `${query}`);
        }
        if (size) {
            params = params.append("size", `${size}`);
        }

        if (idEmpresa) {
            params = params.append("idEmpresa", `${idEmpresa}`);
        }

        const url = "/pix/listar-filtro-pix";
        return this.client.get(url, { params });
    }

    verComprovantePix(identificador: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('identificador', `${identificador}`);
        const url = '/pix/visualizar-comprovante';
        return this.client.get(url, { params });
    }

    desativarChave(chave: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('chave', `${chave}`);
        const url = '/pix/desativar-chave';
        return this.client.delete(url, { params });
    }

    consultarRevindicacaoLista(cpfCnpj: string, nrConta: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('cpfCnpj', `${cpfCnpj}`);
        params = params.append('nrConta', `${nrConta}`);
        const url = '/pix/consultar-reivindicacao';
        return this.client.get(url, { params });
    }

    consultaReivindicacaoChave(cpfCnpj: string, nrConta: string, chave: string, idReivindicacao: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('cpfCnpj', `${cpfCnpj}`);
        params = params.append('nrConta', `${nrConta}`);
        params = params.append('chave', `${chave}`);
        params = params.append('idReivindicacao', `${idReivindicacao}`);

        const url = '/pix/consultar-reivindicacao-chave';
        return this.client.get(url, { params });
    }

    listarReivindicacaoChave(): Observable<any> {
        const url = '/pix/consultar-reivindicacao-situacao';
        return this.client.put(url, {})
    }

    paginarReivindicacaoChave(page, size, order?, query?): Observable<any> {
        let params = new HttpParams();

        if (order) {
            params = params.append("order", `${order}`);
        }

        params = params.append("page", `${page}`);

        if (query) {
            params = params.append("q", `${query}`);
        }
        if (size) {
            params = params.append("size", `${size}`);
        }
        const url = '/pix/paginar-reivindicacoes';
        return this.client.get(url, { params })
    }

    cancelarReivindicacao(dto: any): Observable<any> {
        const url = '/pix/cancelar-reivindicacao-chave';
        return this.client.put(url, dto);
    }

    concluirReivindicacao(dto: any): Observable<any> {
        const url = '/pix/concluir-reivindicacao-chave';
        return this.client.put(url, dto);
    }

    confirmarReivindicacao(dto: any): Observable<any> {
        const url = '/pix/confirmar-reivindicacao-chave';
        return this.client.put(url, dto);
    }

    notificarInfracao(dto: any): Observable<any> {
        const url = '/pix/notificar-infracao';
        return this.client.post(url, dto);
    }

    consultarInfracao(idRelatoInfracao): Observable<any> {
        const url = '/pix/consultar-notificacao-infracao?idRelatoInfracao=' + idRelatoInfracao;
        return this.client.get(url);
    }


    cancelarNotificacao(idRelatoInfracao): Observable<any> {
        const url = '/pix/cancelar-notificacao-infracao?idRelatoInfracao=' + idRelatoInfracao;
        return this.client.put(url, {});
    }

    listarNotificacao(pagina?: number, tamanhoPagina?: number, ehDebitado?: boolean, ehCreditado?: boolean, ehRelatoSolicitado?: boolean, stRelatoInfracao?: number, incluiDetalhes?: boolean, dtHrModificacaoInicio?: string, dtHrModificacaoFim?: string): Observable<any> {
        const url = '/pix/listar-notificacao-infracao';

        let params = new HttpParams();
        if (pagina !== undefined) params = params.append('pagina', pagina.toString());
        if (tamanhoPagina !== undefined) params = params.append('tamanhoPagina', tamanhoPagina.toString());
        if (ehDebitado !== undefined) params = params.append('ehDebitado', ehDebitado.toString());
        if (ehCreditado !== undefined) params = params.append('ehCreditado', ehCreditado.toString());
        if (ehRelatoSolicitado !== undefined) params = params.append('ehRelatoSolicitado', ehRelatoSolicitado.toString());
        if (stRelatoInfracao !== undefined) params = params.append('stRelatoInfracao', stRelatoInfracao.toString());
        if (incluiDetalhes !== undefined) params = params.append('incluiDetalhes', incluiDetalhes.toString());
        if (dtHrModificacaoInicio !== undefined) params = params.append('dtHrModificacaoInicio', dtHrModificacaoInicio);
        if (dtHrModificacaoFim !== undefined) params = params.append('dtHrModificacaoFim', dtHrModificacaoFim);

        return this.client.get(url, { params });
    }

    solicitarDevolucao(dto: any): Observable<any> {
        const url = '/pix/solicitar-devolucao';
        return this.client.post(url, dto);
    }

    analisarNotificacao(dto: any): Observable<any> {
        const url = '/pix/analisar-notificacao-infracao';
        return this.client.put(url, dto);
    }

    consultarSolicitacaoDevolucao(idSolDevolucao): Observable<any> {
        const url = '/pix/consultar-devolucao?idSolDevolucao=' + idSolDevolucao;
        return this.client.get(url);
    }

    cancelarSolicitacaoDevolucao(idSolDevolucao): Observable<any> {
        const url = '/pix/cancelar-devolucao?idSolDevolucao=' + idSolDevolucao;
        return this.client.patch(url, {});
    }

    listarSolicitacaoDevolucao(tipoPsp?, incluiDetalhes?, dtHrModificacaoInicio?, dtHrModificacaoFim?, pagina?, tamanhoPagina?): Observable<any> {
        const url = '/pix/listar-devolucao';

        let params = new HttpParams();
        if (tipoPsp !== undefined) params = params.append('tipoPsp', tipoPsp);
        if (incluiDetalhes !== undefined) params = params.append('incluiDetalhes', incluiDetalhes);
        if (dtHrModificacaoInicio !== undefined) params = params.append('dtHrModificacaoInicio', dtHrModificacaoInicio);
        if (dtHrModificacaoFim !== undefined) params = params.append('dtHrModificacaoFim', dtHrModificacaoFim);
        if (pagina !== undefined) params = params.append('pagina', pagina);
        if (tamanhoPagina !== undefined) params = params.append('tamanhoPagina', tamanhoPagina);

        return this.client.get(url, { params });
    }

    analisarDevolucao(dto) {
        const url = '/pix/analisar-devolucao';
        return this.client.put(url, dto);
    }

    listarQrCodes(page, size, order, cpfCnpj, vencimento): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        params = params.append('order', order);
        params = params.append('cpfCnpj', cpfCnpj);
        params = params.append('vencimento', vencimento);
        const url = '/pix/listar-qrcodes-documento';
        return this.client.get(url, { params });
    }

    listarQrCodesBd(page, size, order, idEmpresa, vencimento): Observable<any> { 
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        params = params.append('order', order);
        params = params.append('idEmpresa', idEmpresa);
        params = params.append('vencimento', vencimento);
        const url = '/pix/listar-qrcodes-bd';
        return this.client.get(url, { params });

    }

    consultarQrCodeEstatico(dto: QrCodePixDTO): Observable<any> {
        const url = '/pix/consultar-qrCode';
        return this.client.post(url, dto);
    }

    requisitarOrdemDevolucao(dto: any): Observable<any> {
        const url = '/pix/efetivar-ordem-devolucao';
        return this.client.post(url, dto);
    }

    consultarOrdemDevolucao(idSolDevolucao): Observable<any> {
        const url = '/pix/consultar-ordem-devolucao?idSolDevolucao=' + idSolDevolucao;
        return this.client.get(url);
    }

    listarUltimosPixRealizados(): Observable<any> {
        const url = '/pix/lista-ultimos-pix';
        return this.client.get(url);
    }

    cadastrarChaveContato(dto, contatoId): Observable<any> { 
        const url = '/contato/cadastrar-chave-contato?' + 'contatoId=' + contatoId;
        return this.client.post(url, {dto});
    }

    listarChaveContato(contatoId): Observable<any> {
        const url = '/contato/listar-chave-contato?' + 'contatoId=' + contatoId;
        return this.client.get(url);
    }

    listarTodasChaves(page, size, order?, query?): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        if (order) params = params.append('order', order);
        if (query) params = params.append('query', query);

        const url = '/pix/lista-todas-chaves';
        return this.client.get(url, { params });
    }

    listarTodasTransferencias(
        filtro: FiltroPixDTO
    ): Observable<PageResponse<FiltroPixDTO>> {
        filtro = filtro || {};
        let params = new HttpParams();
        const { data } = filtro;
        if (data) {
            const { data_criacao, data_desativacao } = data;

            if (data_criacao && data_criacao.show) {
                const { de, ate } = data_criacao;
                if (de) {
                    params = params.append(
                        "data-criacao-maior-igual",
                        `${moment(de).format("DDMMYYYY")}`
                    );
                }
                if (ate) {
                    params = params.append(
                        "data-criacao-menor-igual",
                        `${moment(ate).format("DDMMYYYY")}`
                    );
                }
            }
            if (data_desativacao && data_desativacao.show) {
                const { de, ate } = data_desativacao;
                if (de) {
                    params = params.append(
                        "data-desativacao-maior-igual",
                        `${moment(de).format("DDMMYYYY")}`
                    );
                }
                if (ate) {
                    params = params.append(
                        "data-desativacao-menor-igual",
                        `${moment(ate).format("DDMMYYYY")}`
                    );
                }
            }
        }
        if (filtro.order) {
            params = params.append("order", `${filtro.order}`);
        }

        params = params.append("page", `${filtro.page}`);

        if (filtro.query) {
            params = params.append("q", `${filtro.query}`);
        }
        if (filtro.size) {
            params = params.append("size", `${filtro.size}`);
        }

        const url = "/pix/listar-todas-transferencias-pix";
        return this.client.get(url, { params });
    }


    listarTodasTransferenciasBass(page, size, idEmpresa, order?, query?): Observable<any> {
        let params = new HttpParams();
        params = params.append("page", `${page}`);

        if (size) {
            params = params.append("size", `${size}`);
        }

        if (order) {
            params = params.append("order", `${order}`);
        }

        if (query) {
            params = params.append("q", `${query}`);
        }

        if (idEmpresa) {
            params = params.append("idEmpresa", `${idEmpresa}`);
        }

        const url = "/pix/listar-pix-bass";
        return this.client.get(url, { params });
    }

    buscarBancoPorIspb(ispb: string): Observable<any> { 
        const url = '/pix/banco-by-ispb/' + ispb;
        return this.client.get(url);
    }

    buscaPorId(id: number): Observable<any> {
        const url = '/pix/by-id?idEntidade=' + id;
        return this.client.get(url);
     }

     limiteDiarioPix(valor): Observable<any> {
        const url = '/pix/limite-diario?valor=' + valor;
        return this.client.get(url);
     }
}
