import { Pipe, PipeTransform } from '@angular/core';
import { DateFnsInputDate } from './types';
import getDate from 'date-fns/getDate';

@Pipe({ name: 'dfnsGetDate' })
export class GetDatePipe implements PipeTransform {
  transform(date: DateFnsInputDate): number {
    return getDate(date);
  }
}
