<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        MÓDULOS HABILITADOS <i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>

    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div class=" mudaCor input-size row" style="background-color: white; margin: 0px 10px; border-radius: 5px;">
            <div class="coluna" style="height: 100%;">
                <div>
                    <p [class]="env?.modulos?.api ? 'habilitado' : 'desabilitado'">API:</p>
                    <ul>
                        <li [class]="env?.modulos?.api.api ? 'habilitado' : 'desabilitado'">API</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.empresa ? 'habilitado' : 'desabilitado'">Conta:</p>
                    <ul>
                        <li [class]="env?.modulos?.empresa.multiEmpresas ? 'habilitado' : 'desabilitado'">Multi-Contas
                        </li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.onboard ? 'habilitado' : 'desabilitado'">Onboarding:</p>
                    <ul>
                        <li [class]="env?.modulos?.onboard.pessoaFisica ? 'habilitado' : 'desabilitado'">Pessoa Física
                        </li>
                        <li [class]="env?.modulos?.onboard.pessoaJuridica ? 'habilitado' : 'desabilitado'">Pessoa
                            Jurídica</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.tipoConta ? 'habilitado' : 'desabilitado'">Tipos de Contas:</p>
                    <ul>
                        <li [class]="env?.modulos?.tipoConta.digital ? 'habilitado' : 'desabilitado'">Digital</li>
                        <li [class]="env?.modulos?.tipoConta.correnteDigital ? 'habilitado' : 'desabilitado'">Corrente
                            Digital</li>
                        <li [class]="env?.modulos?.tipoConta.contaCobrancaDigital ? 'habilitado' : 'desabilitado'">
                            Corrente Digital</li>
                        <li [class]="env?.modulos?.tipoConta.vinculadaDigital ? 'habilitado' : 'desabilitado'">Vinculada
                            Digital</li>
                        <li [class]="env?.modulos?.tipoConta.escrowDigital ? 'habilitado' : 'desabilitado'">Escrow
                            Digital</li>
                        <li [class]="env?.modulos?.tipoConta.bloqueioJudicial ? 'habilitado' : 'desabilitado'">Bloqueio
                            Judicial</li>
                        <li [class]="env?.modulos?.tipoConta.salarioDigital ? 'habilitado' : 'desabilitado'">Salário
                            Digital</li>
                        <li [class]="env?.modulos?.tipoConta.servicoDigital ? 'habilitado' : 'desabilitado'">Serviço
                            Digital</li>
                        <li [class]="env?.modulos?.tipoConta.investidorDigital ? 'habilitado' : 'desabilitado'">
                            Investidor Judicial</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.pagamento ? 'habilitado' : 'desabilitado'">Pagamentos:</p>
                    <ul>
                        <li [class]="env?.modulos?.pagamento.boletoPix ? 'habilitado' : 'desabilitado'">Boleto PIX</li>
                        <li [class]="env?.modulos?.pagamento.boletoBancario ? 'habilitado' : 'desabilitado'">Boleto
                            Bancário</li>
                        <li [class]="env?.modulos?.pagamento.cartaoCredito ? 'habilitado' : 'desabilitado'">Cartão de
                            Credito</li>
                        <li [class]="env?.modulos?.pagamento.importacaoCnab ? 'habilitado' : 'desabilitado'">Cnab (240)
                        </li>
                    </ul>
                </div>

            </div>
            <div style="height: inherit;display: flex;
            align-items: center;">
                <span class="separador"></span>
            </div>
            <div class="coluna" style="height: 100%;">
                <div>
                    <p [class]="env?.modulos?.pagamentoContas ? 'habilitado' : 'desabilitado'">Pagamento de Contas:</p>
                    <ul>
                        <li [class]="env?.modulos?.pagamentoContas.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.pagamentoContas.tributos ? 'habilitado' : 'desabilitado'">Tributos
                        </li>
                        <li [class]="env?.modulos?.pagamentoContas.concessionarias ? 'habilitado' : 'desabilitado'">
                            Concessionárias</li>
                        <li [class]="env?.modulos?.pagamentoContas.boleto ? 'habilitado' : 'desabilitado'">Boletos</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.extrato ? 'habilitado' : 'desabilitado'">Extratos:</p>
                    <ul>
                        <li [class]="env?.modulos?.extrato.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.extrato.ofx ? 'habilitado' : 'desabilitado'">OFX</li>
                        <li [class]="env?.modulos?.extrato.xls ? 'habilitado' : 'desabilitado'">XLS</li>
                        <li [class]="env?.modulos?.extrato.pdf ? 'habilitado' : 'desabilitado'">PDF</li>
                        <li [class]="env?.modulos?.extrato.csv ? 'habilitado' : 'desabilitado'">CSV</li>
                        <li [class]="env?.modulos?.extrato.informeRendimentos ? 'habilitado' : 'desabilitado'">Informe
                            de Rendimentos</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.transferencia ? 'habilitado' : 'desabilitado'">Transferências:</p>
                    <ul>
                        <li [class]="env?.modulos?.transferencia.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.transferencia.ted ? 'habilitado' : 'desabilitado'">TED</li>
                        <li [class]="env?.modulos?.transferencia.pix ? 'habilitado' : 'desabilitado'">PIX</li>
                        <li [class]="env?.modulos?.transferencia.interna ? 'habilitado' : 'desabilitado'">Interna </li>
                        <li [class]="env?.modulos?.transferencia.p2p ? 'habilitado' : 'desabilitado'">P2P</li>
                        <li [class]="env?.modulos?.transferencia.agendamento ? 'habilitado' : 'desabilitado'">
                            Agendamentos </li>
                        <li [class]="env?.modulos?.transferencia.sensibilizacao ? 'habilitado' : 'desabilitado'">
                            Sensibilizações</li>
                        <li [class]="env?.modulos?.transferencia.lote ? 'habilitado' : 'desabilitado'">Lotes </li>
                        <li [class]="env?.modulos?.transferencia.alcada ? 'habilitado' : 'desabilitado'">Alçadas</li>
                    </ul>
                </div>
            </div>
            <div style="height: inherit;display: flex;
            align-items: center;">
                <span class="separador"></span>
            </div>
            <div class="coluna" style="height: 100%;">
                <div>
                    <p [class]="env?.modulos?.cobranca ? 'habilitado' : 'desabilitado'">Cobranças:</p>
                    <ul>
                        <li [class]="env?.modulos?.cobranca.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.cobranca.avulsa ? 'habilitado' : 'desabilitado'">Avulsa</li>
                        <li [class]="env?.modulos?.cobranca.assinatura ? 'habilitado' : 'desabilitado'">Assinatura</li>
                        <li [class]="env?.modulos?.cobranca.parcelada ? 'habilitado' : 'desabilitado'">Parcelada</li>
                        <li [class]="env?.modulos?.cobranca.importacaoCnab ? 'habilitado' : 'desabilitado'">Cnab (400)
                        </li>
                        <li [class]="env?.modulos?.cobranca.protesto ? 'habilitado' : 'desabilitado'">Protesto</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.contabilidade ? 'habilitado' : 'desabilitado'">Contabilidade:</p>
                    <ul>
                        <li [class]="env?.modulos?.contabilidade.tesouraria ? 'habilitado' : 'desabilitado'">Tesouraria
                        </li>
                        <!-- <li [class]="env?.modulos?.contabilidade.arrosse ? 'habilitado' : 'desabilitado'">Arrosse</li> -->
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.antecipacao ? 'habilitado' : 'desabilitado'">Antecipações:</p>
                    <ul>
                        <li [class]="env?.modulos?.antecipacao.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.antecipacao.boleto ? 'habilitado' : 'desabilitado'">Boletos</li>
                        <li [class]="env?.modulos?.antecipacao.faturaCartao ? 'habilitado' : 'desabilitado'">Faturas de
                            Cartão</li>
                        <li [class]="env?.modulos?.antecipacao.xmlRecebiveis ? 'habilitado' : 'desabilitado'">XML
                            Recebíveis</li>
                        <li [class]="env?.modulos?.antecipacao.consignado ? 'habilitado' : 'desabilitado'">Consignados
                        </li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.cartaoCredito ? 'habilitado' : 'desabilitado'">Cartão de Crédito:</p>
                    <ul>
                        <li [class]="env?.modulos?.cartaoCredito.sidebar ? 'habilitado' : 'desabilitado'">Sidebar</li>
                        <li [class]="env?.modulos?.cartaoCredito.prePago ? 'habilitado' : 'desabilitado'">Pré Pago</li>
                        <li [class]="env?.modulos?.cartaoCredito.saldoUnificado ? 'habilitado' : 'desabilitado'">Saldo
                            Unificado</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.operador ? 'habilitado' : 'desabilitado'">Operador:</p>
                    <ul>
                        <li [class]="env?.modulos?.operador.operadores ? 'habilitado' : 'desabilitado'">Operadores</li>
                    </ul>
                </div>
            </div>
            <div style="height: inherit;display: flex;
            align-items: center;">
                <span class="separador"></span>
            </div>
            <div class="coluna" style="height: 100%;">

                <div>
                    <p [class]="env?.modulos?.aumentoLimites ? 'habilitado' : 'desabilitado'">Aumento de Limites:</p>
                    <ul>
                        <li [class]="env?.modulos?.aumentoLimites.cobranca ? 'habilitado' : 'desabilitado'">Cobranças
                        </li>
                        <li [class]="env?.modulos?.aumentoLimites.transferencia ? 'habilitado' : 'desabilitado'">
                            Transferências</li>
                        <li [class]="env?.modulos?.aumentoLimites.antecipacao? 'habilitado' : 'desabilitado'">
                            Antecipações</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.investidor ? 'habilitado' : 'desabilitado'">Investidor:</p>
                    <ul>
                        <li [class]="env?.modulos?.investidor.extratoInvestidor ? 'habilitado' : 'desabilitado'">Extrato
                            do Investidor</li>
                        <li [class]="env?.modulos?.investidor.listaFundos ? 'habilitado' : 'desabilitado'">Lista de
                            Fundos</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.notificacao ? 'habilitado' : 'desabilitado'">Notificações:</p>
                    <ul>
                        <li [class]="env?.modulos?.notificacao.sms ? 'habilitado' : 'desabilitado'">SMS</li>
                        <li [class]="env?.modulos?.notificacao.email ? 'habilitado' : 'desabilitado'">E-mail</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.desconto ? 'habilitado' : 'desabilitado'">Descontos:</p>
                    <ul>
                        <li [class]="env?.modulos?.desconto.opcoesDesconto ? 'habilitado' : 'desabilitado'">Opções de
                            Desconto</li>
                        <li [class]="env?.modulos?.desconto.protesto ? 'habilitado' : 'desabilitado'">Protestos</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.fiscal ? 'habilitado' : 'desabilitado'">Fiscal:</p>
                    <ul>
                        <li [class]="env?.modulos?.fiscal.notaFiscal ? 'habilitado' : 'desabilitado'">Notas Fiscais</li>

                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.auditoria ? 'habilitado' : 'desabilitado'">Auditoria:</p>
                    <ul>
                        <li [class]="env?.modulos?.auditoria.logs ? 'habilitado' : 'desabilitado'">Logs</li>
                    </ul>
                </div>
            </div>
            <div style="height: inherit;display: flex;
            align-items: center;">
                <span class="separador"></span>
            </div>
            <div class="coluna" style="height: 100%;">

                <div>
                    <p [class]="env?.modulos?.fraude ? 'habilitado' : 'desabilitado'">Fraude:</p>
                    <ul>
                        <li [class]="env?.modulos?.fraude.antiFraude ? 'habilitado' : 'desabilitado'">Anti-Fraude</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.token ? 'habilitado' : 'desabilitado'">Tokens:</p>
                    <ul>
                        <li [class]="env?.modulos?.token.email ? 'habilitado' : 'desabilitado'">E-mail</li>
                        <li [class]="env?.modulos?.token.sms ? 'habilitado' : 'desabilitado'">SMS</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.arquivo ? 'habilitado' : 'desabilitado'">Arquivo:</p>
                    <ul>
                        <li [class]="env?.modulos?.arquivo.anexarArquivo ? 'habilitado' : 'desabilitado'">Anexar
                            Arquivos</li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.convite ? 'habilitado' : 'desabilitado'">Convites:</p>
                    <ul>
                        <li [class]="env?.modulos?.convite.indicarAmigos ? 'habilitado' : 'desabilitado'">Indicar Amigos
                        </li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.espacoPublicitario ? 'habilitado' : 'desabilitado'">Espaço Publicitário:
                    </p>
                    <ul>
                        <li
                            [class]="env?.modulos?.espacoPublicitario.publicidadeCarrossel ? 'habilitado' : 'desabilitado'">
                            Publicidade Carrossel </li>
                    </ul>
                </div>
                <div>
                    <p [class]="env?.modulos?.suporteContato ? 'habilitado' : 'desabilitado'">Suporte e Contato:</p>
                    <ul>
                        <li [class]="env?.modulos?.suporteContato.termosContratos ? 'habilitado' : 'desabilitado'">
                            Termos de Contratos</li>
                        <li [class]="env?.modulos?.suporteContato.planosPrecos ? 'habilitado' : 'desabilitado'">Planos e
                            Preços</li>
                        <li [class]="env?.modulos?.suporteContato.centralAjuda ? 'habilitado' : 'desabilitado'">Central
                            de Ajuda</li>
                        <li [class]="env?.modulos?.suporteContato.relacionamento ? 'habilitado' : 'desabilitado'">
                            Relacionamentos</li>
                        <li [class]="env?.modulos?.suporteContato.blog ? 'habilitado' : 'desabilitado'">Blog</li>
                        <li [class]="env?.modulos?.suporteContato.envioOperacoes ? 'habilitado' : 'desabilitado'">Envio
                            de Operações</li>

                    </ul>
                </div>
            </div>


        </div>
    </div>

    <div mat-dialog-actions style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px;">
        <span class="div-interna flex-end" style=" align-items: center; padding: 0px 20px;border-radius: 4px;
        margin: 0;">
            <button (click)="fecha()" class="btn btn-default next">Voltar</button>
        </span>
    </div>
</div>