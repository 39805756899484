import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CpfCnpjPipe } from './cpf-cnpj.pipe';
import { ErrorMessagePipe } from './error-message.pipe';
import { FiltroPipe } from './filtro.pipe';
import { FormErrorsPipe } from './form-errors.pipe';
import { InvertidoPipe } from './invertido.pipe';
import { MarkPipe } from './mark.pipe';
import { OrdenarPipe } from './ordenar.pipe';
import { TelefonePipe } from './telefone.pipe';
import { TipoEmpresaPipe } from './tipoEmpresa.pipe';
import { ModeracaoEmpresaPipe } from './moderacaoEmpresa.pipe';
import { DocumentoPipe } from './documento.pipe';
import { FormatoDocumentoPipe } from './formatoDocumento.pipe';
import { TipoDocumentoPipe } from './tipo-documento.pipe';

const components = [
	InvertidoPipe,
	FiltroPipe,
	OrdenarPipe,
	ErrorMessagePipe,
	CpfCnpjPipe,
	MarkPipe,
	FormErrorsPipe,
	TelefonePipe,
	FormatoDocumentoPipe,
    TipoEmpresaPipe,
    ModeracaoEmpresaPipe,
    DocumentoPipe,
	TipoDocumentoPipe
   ];

@NgModule({
	imports: [
		CommonModule
	],
	exports: components,
	declarations: components
})
export class PipeModule { }
