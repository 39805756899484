<div mat-dialog-title class="titulo-modal" fxLayout="row">
    <div class="flex-align-center" fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
    
    </div>
    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">CONFIGURAÇÃO DE SISTEMA</div> 
    <div class="flex-align-center" fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
        <p style="margin: 0 !important; padding-right: 4px" id="fechar" (click)="fecha()">FECHAR</p>
        <i style="margin-right: 10px;"(click)="fecha()" class="material-icons btn-fechar" >close</i>
    </div>
</div>

<div mat-dialog-actions>
    <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="row botao-centro">
        <div class="col-12 col-md-4">
            <button type="button"  (click)="fecha()" class="btn btn-default btn-block" style="color: #fff;margin-left: 55%; z-index: +1;"
                mat-raised-button>
                Cancelar
            </button>
        </div>

        <div class="col-12 col-md-4">
            <button type="submit" class="btn btn-block fundoCorPrimaria" style=" color: #fff;margin-left: 70%;"
                mat-raised-button>
                Atualizar
            </button>
        </div>
    </div>
</div>
