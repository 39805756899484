<mat-toolbar class="espacamento pl-0" style="margin-top: 2%">
    <div fxLayout="row" class="row-toolbar">
        <div fxFlex="70" class="input-size" style="margin-top: 0px; padding: 0">
            <mat-form-field appearance="outline" class="form-buscar" color="orange">
                <mat-label>Procurar</mat-label>
                <input matInput [(ngModel)]="queryValue"
                class="campo-buscar"
                (ngModelChange)="query.next($event)"/>
            </mat-form-field>
        </div>
        <div fxFlex="30" fxLayoutAlign="center center" style="padding-bottom: 1.38375em !important">
            <button fxFlex="90" class="btn mat-primary btn-filtro" (click)="novo()">
                <i class="fas fa-plus"></i>
                <p>Novo</p>
            </button>
            <!-- <button fxFlex="45" (click)="abreFiltro()" class="btn mat-primary btn-filtro">
                <i class="fas fa-filter"></i>
                <p>Filtros</p>
            </button> -->
        </div>
    </div>
</mat-toolbar>