<form [formGroup]="form" class="espaco-top">
  <div class="alinha" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div [hidden]="!processando" fxFlex="100" style="padding: 5px 5px 10px 5px">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <div fxFlex="49" fxFlex.sm="70" fxFlex.xs="70">
        <p>
          Qual é o
          {{ tipoCadastro === 'primeiros-passos' ? 'CNPJ' : 'CPF ou CNPJ' }}?
        </p>
        <mat-form-field appearance="outline">
          <mat-label>Documento</mat-label>
          <input matInput formControlName="documento" 
                mask="CPF_CNPJ"
                required/>
        </mat-form-field>
      </div>
      <div
        class="buscar-cnpj"
        [ngClass]="{ 'disable-button': processando }"
        fxFlex="49"
        fxFlex.md="29"
        fxFlex.xs="29"
      >
      <span *ngIf="!ehPessoaJuridica(); else pessoaFisica"
      (click)="form.get('documento').hasError('cpfinvalido') || buscaCpf()"
      [ngStyle]="{'cursor': form.get('documento').valid ? 'pointer' : 'default', 'color': form.get('documento').valid ? '' : '#ccc'}"
      class="span-cpf">
      Buscar dados pelo CPF
      </span>
      <ng-template #pessoaFisica>
        <span (click)="form.get('documento').hasError('cnpjinvalido') || buscaCnpj()"
              [ngStyle]="{'cursor': form.get('documento').valid ? 'pointer' : 'default', 'color': form.get('documento').valid ? '' : '#ccc'}"
              class="span-cnpj">
          Buscar dados pelo CNPJ
        </span>
      </ng-template>
      </div>
      
    </div>
    <div *ngIf="tipoCadastro" fxFlex="49" fxFlex.sm="100" fxFlex.xs="100">
      <p>Qual é o nome?</p>
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="nome" required />
        <mat-error>{{ getErrorMessage('nome') }}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="49" fxFlex.sm="100" fxFlex.xs="100" *ngIf="ehPessoaJuridica()">
      <p>Qual é a sua razão social?</p>
      <mat-form-field appearance="outline">
        <mat-label>Razão Social</mat-label>
        <input matInput type="text" formControlName="razaoSocial" required />
        <mat-error>{{ getErrorMessage('razaoSocial') }}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="49" fxFlex.sm="100" fxFlex.xs="100" *ngIf="ehPessoaJuridica()">
      <p>Qual é o site da empresa?</p>
      <mat-form-field appearance="outline">
        <mat-label>Site</mat-label>
        <input matInput formControlName="site" type="text" />
      </mat-form-field>
    </div>
    <div
      fxFlex="49"
      fxFlex.sm="100"
      fxFlex.xs="100"
      *ngIf="ehPessoaJuridica() && tipoCadastro != 'contato'"
    >
      <p>Qual o tipo de empresa?</p>
      <mat-form-field appearance="outline">
        <mat-label>Tipo de empresa</mat-label>
        <mat-select formControlName="tipoEmpresa" required>
          <mat-option value="ltda">LTDA - Sociedade limitada</mat-option>
          <mat-option value="mei"
            >MEI - Microempreendedor individual</mat-option
          >
          <mat-option value="me">ME - Microempresa</mat-option>
          <mat-option value="eireli"
            >EIRELI - Empresa individual de responsabilidade
            limitada</mat-option
          >
          <mat-option value="sa">SA - Sociedade anônima</mat-option>
          <mat-option value="esc">ESC - Empresa Simples de Crédito</mat-option>
          <mat-option value="scd">SCD - Sociedade de Crédito Direto</mat-option>
          <mat-option value="fundo">Fundo de investimento</mat-option>
          <mat-option value="holding">Holding</mat-option>
          <mat-option value="outro">Instituição, ONG ou Associação</mat-option>
        </mat-select>
        <!-- <mat-error>{{ getErrorMessage(form, "tipoEmpresa") }}</mat-error> -->
      </mat-form-field>
    </div>
    <div
      fxFlex="49"
      fxFlex.sm="100"
      fxFlex.xs="100"
      *ngIf="ehPessoaJuridica() && tipoCadastro != 'contato'"
    >
      <p>Faturamento mensal</p>
      <mat-form-field appearance="outline">
        <mat-label>Faturamento</mat-label>
        <input
          matInput
          currencyMask
          onclick="this.setSelectionRange(-1,-1);"
          onfocus="this.setSelectionRange(-1,-1);"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          formControlName="faturamento"
          placeholder="0,00"
          type="text"
          required
        />
      </mat-form-field>
    </div>
    <div
      *ngIf="!ehPessoaJuridica() && tipoCadastro === 'empresa'"
      fxFlex="49"
      fxFlex.sm="100"
      fxFlex.xs="100"
    >
      <p>Qual é a data de nascimento?</p>
      <mat-form-field appearance="outline">
        <mat-label>Data de Nascimento</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          id="dataNascimento"
          (keyup)="mascaraData($event, 'dataNascimento')"
          formControlName="dataNascimento"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div
      fxFlex="100"
      style="text-align: center; padding-top: 4px"
      *ngIf="ehPessoaJuridica() && tipoCadastro === 'empresa'"
    >
      <div
        class="fileinput fileinput-new text-center"
        data-provides="fileinput"
        text-center
      >
        <div class="fileinput-new thumbnail">
          <img *ngIf="logotipo" [src]="logotipo" alt="..." />
        </div>
        <div class="fileinput-preview fileinput-exists thumbnail"></div>
        <div>
          <span
            class="btn btn-primary btn-file"
            style="background-color: #4fa9a9; cursor: pointer"
          >
            <span class="fileinput-new">Fazer upload do logotipo</span>
            <span class="fileinput-exists">Trocar</span>
            <input
              type="file"
              name="..."
              formControlName="logotipoPath"
              (change)="onSelectFile($event)"
            />
          </span>
          <a
            href="#pablo"
            class="btn btn-danger fileinput-exists"
            (click)="logotipoNull()"
            data-dismiss="fileinput"
            ><i class="fas fa-times"></i> Remover</a
          >
        </div>
      </div>
    </div>
  </div>
</form>
