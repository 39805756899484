import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "@src/environments/environment.default";

@Component({
  selector: "app-modulos-habilitados",
  templateUrl: "./modulos-habilitados.component.html",
  styleUrls: ["./modulos-habilitados.component.less"],
})
export class ModulosHabilitadosComponent implements OnInit {
  env = environment;

  constructor(public dialogRef: MatDialogRef<ModulosHabilitadosComponent>) { }

  ngOnInit() { }

  fecha(): void {
    this.dialogRef.close();
  }
}
