<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        REDEFINIÇÃO RECORRENTE
        <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    </h2>
    <div mat-dialog-content class="mat-dialog-content-responsive">
        <div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
            <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="mudaCor input-size row"
                style="background-color: white; margin: 10px 10px; border-radius: 5px;padding: 28px 0px; width: 95%;">
                <div class="card-body">
                    <p style="text-align: center;">
                        Defina a quantidade de dias para redefinição de senha:
                    </p>
                    <div>
                        <mat-slide-toggle (change)="toggleInput('usuariosClientes', $event)"
                            [checked]="inputsEnabled.usuariosClientes">Habilitar
                            Usuários/clientes</mat-slide-toggle>
                        <mat-form-field appearance="outline">
                            <mat-label>Usuários/clientes</mat-label>
                            <input matInput type="number" [(ngModel)]="configValues.usuariosClientes"
                                [disabled]="!inputsEnabled.usuariosClientes" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-slide-toggle (change)="toggleInput('colaboradores', $event)"
                            [checked]="inputsEnabled.colaboradores">Habilitar
                            Colaboradores</mat-slide-toggle>
                        <mat-form-field appearance="outline">
                            <mat-label>Colaboradores</mat-label>
                            <input matInput type="number" [(ngModel)]="configValues.colaboradores"
                                [disabled]="!inputsEnabled.colaboradores" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-slide-toggle (change)="toggleInput('operadores', $event)"
                            [checked]="inputsEnabled.operadores">Habilitar
                            Operadores</mat-slide-toggle>
                        <mat-form-field appearance="outline">
                            <mat-label>Operadores</mat-label>
                            <input matInput type="number" [(ngModel)]="configValues.operadores"
                                [disabled]="!inputsEnabled.operadores" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-slide-toggle (change)="toggleInput('administradores', $event)"
                            [checked]="inputsEnabled.administradores">Habilitar
                            Administradores</mat-slide-toggle>
                        <mat-form-field appearance="outline">
                            <mat-label>Administradores</mat-label>
                            <input matInput type="number" [(ngModel)]="configValues.administradores"
                                [disabled]="!inputsEnabled.administradores" />
                        </mat-form-field>
                    </div>
                    <div mat-dialog-actions
                        style="display: block;overflow: initial; margin: 0; padding: 8px 0 0;border-radius: 4px; margin: auto;">
                        <span class="div-interna flex-end"
                            style=" align-items: center; padding: 0px 20px;border-radius: 4px; margin: 0;">
                            <button (click)="cadastrarConfiguracao()"
                                class="btn btn-default next fundoCorPrimaria">Salvar</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>