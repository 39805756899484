<h2 mat-dialog-title class="titulo-modal pl-4">
  <div id="avatar-icon">
    <i class="fas fa-building fa" style="font-size: 0.8em !important;"></i>
  </div>

  <span id="empresa-logada"> {{ empresaLogada.razaoSocial || '' }}
    <p>{{ empresaLogada.documento || '' }}</p>
  </span>

  Centro Nacional de Automação Bancária (CNAB)
  <i (click)="fecha()" class="material-icons btn-fechar">close</i>
  <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<div mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
  <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
  <div class="row">
    <div class="col-12 horizontal-stepper">
      <div class="box-body opcoes" style="text-align: -webkit-center;">
        <div class="row" style="justify-content: center;">
          <div class="col-8 col-md-8 arquivo-box mt-3" appDragDrop (fileDropped)="onFileDropped($event)"
            style="margin-right: 20px;">
            <input hidden type="file" #fileDropRef id="fileDropRef" (change)="onSelectFile($event)" />
            <i class="fas fa-cloud-arrow-up"></i>
            <p>Arraste o documento ou <label for="fileDropRef" style="font-weight: bold; cursor: pointer;">clique
                aqui.</label></p>
          </div>
          <div class="col-3 col-md-3 arquivo-lista-box  mt-3">
            <p *ngIf="files.length == 0">Nenhum arquivo adicionado.</p>
            <div *ngIf="files.length > 0">
              <div class="arquivo-row" *ngFor="let file of files; index as i">
                <i class="far fa-file-alt" id="file-icon"></i>
                <p id="arquivo-title" [matTooltip]="file.nome">{{ file.nome || '' }}</p>
                <i (click)="deleteFile(file)" class="fas fa-trash" id="trash-icon"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 mb-5" style="margin-top: 25px;">
            <button [disabled]="files.length === 0" type="button" (click)="enviar()"
              class="btn btn-block btn-novocliente" mat-raised-button>
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>