<form [formGroup]="form">
  <div class="row alinha" *ngFor="let email of form.controls; index as i" [formGroup]="email">
    <div class="col-10">
      <h4 class="titulo">EMAIL</h4>
    </div>
    <div class="col-2" style="text-align: end;">
      <i class="fas fa-trash icones" [title]="'Excluir'" (click)="contador > 1 ? del(i) : null"
        [style.color]="contador > 1 ? 'red' : 'grey'" style="padding-top: 12px"></i>
    </div>
    <!-- <div class="col-2" style="margin-top: 14px; text-align: end;">
        <mat-slide-toggle formControlName="principal" (change)="changeContaPrincipal($event)">Principal
        </mat-slide-toggle>
        <mat-error style="font-size: 12px;">{{ getErrorMessage(email, "principal") }}</mat-error>
      </div> -->
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" required />
        <mat-error>{{ getErrorMessage(email, "email") }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="row alinha">
  <!-- #obs: para permitir a adição de emails descomentar essa linha-->
  <!-- <div class="col-6 col-md-6 add">
      <span (click)="add()">+ adicionar novo e-mail</span>
    </div> -->
</div>