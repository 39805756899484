import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RootEndpointService } from '../../service/root-endpoint.service';
import Swal from 'sweetalert2';
import { ConsultasEndpointService } from '../../service/consultas-endpoint.service';

@Component({
  selector: 'app-neo-finance',
  templateUrl: './neo-finance.component.html',
  styleUrls: ['./neo-finance.component.less']
})
export class NeoFinanceComponent implements OnInit {
  celular: string = '';
  mensagem: string = '';
  cpfCnpj: string = '';
  cep: string = '';

  constructor(
    public dialogRef: MatDialogRef<NeoFinanceComponent>,
    public rootService: RootEndpointService,
    public consultaService: ConsultasEndpointService
  ) { }

  ngOnInit(): void {
  }

  fecha(): void {
    this.dialogRef.close();
  }

  testar(): void {
    this.rootService.testeSms(this.mensagem, this.celular).subscribe((res) => {
      Swal.fire({
        title: "Mensagem de texto enviada!",
        confirmButtonText: "OK",
        icon: 'success',
        text: "Confira no seu celular."
      });
      // Limpar os campos após o teste
      this.limparCampos();
    });
  }

  testarCep(): void {
    this.consultaService.buscarCep(this.cep).subscribe((res) => {
      console.log(res);

      let htmlContent = `
        <style type="text/css">
          .custom-text {
            font-weight: bold;
          }
          .custom-modal-content {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
          .text-class {
            text-align: left;
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;
          }
        </style>
        <div class="custom-modal-content">`;

      if (res.enderecoPrincipal) {
        htmlContent += `<div class="text-class"><span class="custom-text">Endereço principal: </span> ${res.enderecoPrincipal}</div>`;
      }
      if (res.bairro) {
        htmlContent += `<div class="text-class"><span class="custom-text">Bairro: </span> ${res.bairro}</div>`;
      }
      if (res.cidade) {
        htmlContent += `<div class="text-class"><span class="custom-text">Cidade: </span> ${res.cidade}</div>`;
      }
      if (res.estado) {
        htmlContent += `<div class="text-class"><span class="custom-text">Estado: </span> ${res.estado}</div>`;
      }
      if (res.pais) {
        htmlContent += `<div class="text-class"><span class="custom-text">País: </span> ${res.pais}</div>`;
      }

      htmlContent += `</div>`;

      Swal.fire({
        title: 'Resultado da busca:',
        html: htmlContent,
        icon: 'info',
        confirmButtonText: 'OK',
      });
      // Limpar os campos após o teste
      this.limparCampos();
    });
  }

  testarCpfCnpj(): void {
    if (this.cpfCnpj.length === 11) {
      this.testarCpf();
    } else if (this.cpfCnpj.length === 14) {
      this.testarCnpj();
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  testarCpf(): void {
    this.consultaService.buscarCpf(this.cpfCnpj).subscribe((res) => {
      const endereco = res.enderecos && res.enderecos.Primary ? `
        ${res.enderecos.Primary.enderecoPrincipal}, ${res.enderecos.Primary.numero}, ${res.enderecos.Primary.complemento}, ${res.enderecos.Primary.bairro}, ${res.enderecos.Primary.cidade} - ${res.enderecos.Primary.estado}, CEP: ${res.enderecos.Primary.cep}
      ` : 'N/A';

      const telefone = res.telefones ? Object.keys(res.telefones).map(key => `
        <div class="text-class">
          <span class="custom-text">Número: </span> ${res.telefones[key].numero}
          <span class="custom-text">Tipo: </span> ${res.telefones[key].tipo}
        </div>
      `).join('') : 'N/A';
  
      const email = res.emails ? Object.keys(res.emails).map(key => `
        <div class="text-class">
          <span class="custom-text">E-mail: </span> ${email.email}
          <span class="custom-text">Tipo: </span>  ${email.tipo}
        </div>
      `).join('') : 'N/A';
  
      const dadosBancarios = res.dadosBasicosPessoa ? `
        <div class="text-class">
          <span class="custom-text">Nome Completo: </span> ${res.dadosBasicosPessoa.nomeCompleto}
          <span class="custom-text">Status CPF: </span> ${res.dadosBasicosPessoa.statusPessoaRF}
          <span class="custom-text">Data Status CPF: </span> ${new Date(res.dadosBasicosPessoa.dataStatusCpf).toLocaleDateString()}
        </div>
      ` : 'N/A';
  
      Swal.fire({
        title: 'Resultado da busca:',
        html: `
          <style type="text/css">
            .custom-text {
              font-weight: bold;
            }
            .custom-modal-content {
              display: flex;
              flex-direction: column;
              text-align: left;
            }
            .text-class {
              text-align: left;
              display: flex;
              flex-direction: column;
            }
          </style>
          <div class="custom-modal-content">
            <div class="text-class"><span class="custom-text">CPF: </span> ${res.cpf}</div>
            <div class="text-class"><span class="custom-text">Dados Bancários: </span> ${dadosBancarios}</div>
            <div class="text-class"><span class="custom-text">E-mails: </span> ${email}</div>
            <div class="text-class"><span class="custom-text">Endereço: </span> ${endereco}</div>
            <div class="text-class"><span class="custom-text">Telefones: </span> ${telefone}</div>
          </div>`,
        icon: 'info',
        confirmButtonText: 'OK',
      });
      // Limpar os campos após o teste
      this.limparCampos();
    });
  }

  testarCnpj(): void {
    this.consultaService.buscarCnpj(this.cpfCnpj).subscribe((res) => {
      const endereco = `${res.endereco.logradouro}, ${res.endereco.numero}, ${res.endereco.complemento}, ${res.endereco.bairro}, ${res.endereco.cidade} - ${res.endereco.estado}, CEP: ${res.endereco.cep}`;
    
      Swal.fire({
        title: 'Resultado da busca:',
        html: `
          <style type="text/css">
            .custom-text {
              font-weight: bold;
            }
            .custom-modal-content {
              display: flex;
              flex-direction: column;
              text-align: left;
            }
            .text-class {
              text-align: left;
              display: flex;
              flex-direction: column;
            }
          </style>
          <div class="custom-modal-content">
            <div class="text-class"><span class="custom-text">Razão Social: </span> ${res.razaoSocial}</div>
            <div class="text-class"><span class="custom-text">Data de Abertura: </span> ${res.dataAberturaFormatada}</div>
            <div class="text-class"><span class="custom-text">Endereço: </span> ${endereco}</div>
            <div class="text-class"><span class="custom-text">Capital: </span> R$ ${Number(res.capital).toFixed(2)}</div>
            <div class="text-class"><span class="custom-text">Telefone: </span> ${res.telefone}</div>
          </div>`,
        icon: 'info',
        confirmButtonText: 'OK',
      });
      // Limpar os campos após o teste
      this.limparCampos();
    });
  }

  // Método para limpar os campos
  limparCampos(): void {
    this.celular = '';
    this.mensagem = '';
    this.cpfCnpj = '';
    this.cep = '';
  }
}
